import React, { useState } from 'react';
import { Tab, Tabs } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import styles from '../style.module.scss';
import { lang, parseMetricsDetails } from '../helper';
import { Instance } from 'mobx-state-tree';
import { Node } from '@/store/nodes';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { MetricsTabContent } from './MetricsTabContent';
import { Typography, Input } from 'antd';

const { Paragraph } = Typography;

interface IProps {
  node: Instance<typeof Node>;
}

export const AvalancheGeneral: React.FC<IProps> = observer(({ node }) => {
  const { t } = useTranslation();
  const [tabId, setTabId] = useState('C-Chain');

  const metricsDetails = parseMetricsDetails(node);

  return (
    <div className={styles.popoverBox}>
      <h2 className={styles.endpointTitle}>{lang(t, 'detailTitle.apiEndpoints')}</h2>
      <div className={styles.detailBoxContent}>
        <Tabs onChange={(tabId: string) => setTabId(tabId)} defaultSelectedTabId={'C-Chain'}>
          <Tab id={'C-Chain'} title={'C-Chain'} />
          <Tab id={'X-Chain'} title={'X-Chain'} />
          <Tab id={'P-Chain'} title={'P-Chain'} />
          <Tab id={'Metrics'} title={'Metrics'} />
        </Tabs>
        <div className={cx({ [styles.hide]: tabId !== 'C-Chain' })}>
          <p className={styles.addressTitle}>RPC Endpoint ETH</p>
          <Input
            size={'large'}
            disabled
            className={styles.apiKeyInput}
            suffix={
              <Paragraph copyable className={styles.copyButton}>
                {node.endpoints?.rpcc}
              </Paragraph>
            }
            defaultValue={node.endpoints?.rpcc ? node.endpoints?.rpcc : ''}
          />
          <p className={styles.addressTitle}>Websocket ETH</p>
          <Input
            size={'large'}
            disabled
            className={styles.apiKeyInput}
            suffix={
              <Paragraph copyable className={styles.copyButton}>
                {node.endpoints?.wsc}
              </Paragraph>
            }
            defaultValue={node.endpoints?.wsc ? node.endpoints.wsc : ''}
          />
          <p className={styles.addressTitle}>RPC Endpoint AVAX</p>
          <Input
            size={'large'}
            disabled
            className={styles.apiKeyInput}
            suffix={
              <Paragraph copyable className={styles.copyButton}>
                {node.endpoints?.rpccavax}
              </Paragraph>
            }
            defaultValue={node.endpoints?.rpccavax ? node.endpoints.rpccavax : ''}
          />
        </div>

        <div className={cx({ [styles.hide]: tabId !== 'X-Chain' })}>
          <p className={styles.addressTitle}>RPC Endpoint</p>
          <Input
            size={'large'}
            disabled
            className={styles.apiKeyInput}
            suffix={
              <Paragraph copyable className={styles.copyButton}>
                {node.endpoints?.rpcx}
              </Paragraph>
            }
            defaultValue={node.endpoints?.rpcx ? node.endpoints?.rpcx : ''}
          />
        </div>

        <div className={cx({ [styles.hide]: tabId !== 'P-Chain' })}>
          <p className={styles.addressTitle}>RPC Endpoint</p>
          <Input
            size={'large'}
            disabled
            className={styles.apiKeyInput}
            suffix={
              <Paragraph copyable className={styles.copyButton}>
                {node.endpoints?.rpcp}
              </Paragraph>
            }
            defaultValue={node.endpoints?.rpcp ? node.endpoints?.rpcp : ''}
          />
        </div>

        <MetricsTabContent
          visible={tabId === 'Metrics'}
          metricsDetails={metricsDetails}
          endpoint={node.endpoints?.metrics}
          endpointType="metrics"
          addressTitle="RPC Endpoint"
        />
      </div>
    </div>
  );
});

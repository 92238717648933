import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import cx from 'classnames';
import { RouteConfigComponentProps } from 'react-router-config';
import { ReactComponent as LogoBar } from './img/LogoInError.svg';
import cantFindImg from './img/cantfind.svg';
import { NavLink } from 'react-router-dom';
import { TFunction } from 'i18next';

interface IProps extends RouteConfigComponentProps {
  gobackPath?: string;
  noLogo?: boolean;
  theErrorText?: string;
}

export const ErrorPage: React.FC<IProps> = observer(({ gobackPath, noLogo = false, theErrorText }) => {
  //@ts-ignore
  const goback: string = gobackPath ? gobackPath : '/';
  const { t } = useTranslation();
  const lang = (t: TFunction, key: string, opt?: any) => t(`pages.error.${key}`, opt);
  const errorText: any = theErrorText ? theErrorText : lang(t, 'cantFindPage');
  return (
    <div className={cx(noLogo ? styles.containerInMain : styles.containerFullScreen)}>
      <div className={cx(styles.centerBox)}>
        <div className={cx(styles.errorLeft)}>
          {noLogo ? '' : <LogoBar className={cx(styles.logo)} />}
          <div className={cx(styles.flexBox)}>
            <p className={cx(styles.oops)}>{lang(t, 'oops')}</p>
            <p className={cx(styles.flexBoxText)}>{errorText}</p>
            <div className={cx(styles.goBack)}>
              <NavLink to={goback}>{lang(t, 'goBack')}</NavLink>
            </div>
          </div>
        </div>
        <div className={cx(styles.errorRight)}>
          <img
            className={cx(noLogo ? styles.CantFindImg1 : styles.CantFindImg)}
            src={cantFindImg}
            alt="Not found img"
          />
        </div>
      </div>
    </div>
  );
});

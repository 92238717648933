import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { RouteConfigComponentProps } from 'react-router-config';
import { useStore } from '@/store';
import qs from 'querystring';
import { useTranslation } from 'react-i18next';
import { SubmitButton, Loader, SuccessToast } from 'components';
import { defaultPage } from '@/configuration';
import Loading from 'react-loading';
import jwt from 'jsonwebtoken';
import { Intent } from '@blueprintjs/core/lib/esm/common/intent';
import styles from './style.module.scss';
import { TFunction } from 'i18next';

const lang = (t: TFunction, key: string, options?: any) => t(`pages.invite.${key}`, options);

export const Invite: React.FC<RouteConfigComponentProps> = observer((props) => {
  const { location, history } = props;
  const { auth } = useStore();
  const [progressing, setProgressing] = useState(true);
  const { t } = useTranslation();
  const [isLogout, setIsLogout] = useState(false);
  const [differentAccount, setDifferentAccount] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      const query = qs.parse(location.search.substr(1));
      if (!query.token) {
        setError('Missing token query');
        setProgressing(false);
        return;
      }
      const decoded = jwt.decode(query.token);

      if (!decoded || decoded.type !== 'wsInvite') {
        setError('Invalid token');
        setProgressing(false);
        return;
      }

      await auth.info();
      if (!auth.isLoggedIn() || !auth.user) {
        auth.setInviteData({
          token: query.token,
          decoded,
        });
        history.push('/signup');
        return;
      }

      // already login

      if (auth.state === 'error') {
        setError(auth.errorMessage);
        setProgressing(false);
        return;
      }

      if (auth.user.email !== decoded.email) {
        setProgressing(false);
        setInviteEmail(decoded.email);
        setDifferentAccount(true);
        return;
      } else if (decoded.workspaceId) {
        await auth.handleInvite(query.token as string);
        auth.setInviteData();
        setTimeout(() => {
          history.push(`/workspaces/${decoded.workspaceId}/${defaultPage}`);
        }, 500);
      } else {
        setError('Less workspace id');
        setProgressing(false);
      }
      setProgressing(false);
      return;
    })();
  }, []);

  if (progressing) {
    return (
      <div className={styles.container}>
        <Loader isLoading />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.container}>
        <div>
          <p>{error}</p>
          <SubmitButton
            intent={Intent.PRIMARY}
            onClick={async () => {
              history.push('/login');
            }}
          >
            {lang(t, 'goback')}
          </SubmitButton>
        </div>
      </div>
    );
  }

  if (differentAccount) {
    return (
      <div className={styles.container}>
        <div>
          <p>{lang(t, 'alreadyLogin', { email: inviteEmail })}</p>
          <SubmitButton
            disabled={isLogout}
            loading={isLogout}
            intent={Intent.PRIMARY}
            onClick={async () => {
              setIsLogout(true);
              await auth.logout();
              setTimeout(() => {
                setIsLogout(false);
                document.location.reload();
              }, 500);
            }}
          >
            {lang(t, `logoutButton`)}
          </SubmitButton>
        </div>
      </div>
    );
  }

  return null;
});

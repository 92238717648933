import { Instance } from 'mobx-state-tree';
import { Sumary } from '@/store/networkSpec';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import { chartDateLabel } from '@/utils/tools';
import { colourBlue, colourGreen, colourYellow } from '@/components/Report';
dayjs.extend(dayjsPluginUTC as any);

export const charOpt = (data: Array<Instance<typeof Sumary>> | undefined | null, duration: string) => {
  let series = [] as any;
  let xAxis = [] as any;
  let dataMap = {} as any;
  const thisDate = new Date().toISOString().split('T')[0];
  data?.forEach((item: any) => {
    const currentName = item.clusterDetail?.region ?? item.cluster;
    if (currentName === 'all') return;
    item.usage.forEach((i: any) => {
      // const tempDate = dayjs(i.time).format(dateForm);
      const tempDate = chartDateLabel(new Date(i.time), duration);
      if (tempDate === thisDate) return;
      if (!dataMap[tempDate]) dataMap[tempDate] = {};
      if (!dataMap[tempDate][currentName]) dataMap[tempDate][currentName] = i.count;
      else dataMap[tempDate][currentName] += i.count;
    });
  });
  let seriesMap = {} as any;

  Object.keys(dataMap).forEach((key) => {
    xAxis.push(key);
    Object.keys(dataMap[key]).forEach((value) => {
      if (!seriesMap[value]) seriesMap[value] = [];
      seriesMap[value].push(dataMap[key][value]);
    });
  });
  Object.keys(seriesMap).forEach((key) => {
    series.push({
      name: key,
      type: key === 'all' ? 'line' : 'bar',
      stack: key === 'all' ? '' : 'total',
      data: seriesMap[key],
    });
  });
  return {
    color: [colourBlue, colourGreen, colourYellow],
    tooltip: {
      trigger: 'axis',
      formatter: (param: any[]) => {
        if (param.length === 0) {
          return `N/A`;
        }
        const total = param.reduce((sum, value) => sum + value.data, 0);
        let tooltip = `<div  class="chart-tooltip chart-median-response">`;
        tooltip += `<table>`;
        tooltip += `<tr><td colspan="3" class="header" style="color: #262626; font-size: 14px">API Responses by Cluster</td></tr>`;
        tooltip += `<tr><td colspan="3" class="date-time" style="color: #8C8C8C; font-size: 10px">${param[0].name}</td></tr>`;
        tooltip += `<tr>`;
        tooltip += `<td colspan = '2' style="color: #262626; font-size: 12px">Total Responses ${total.toLocaleString()}</td>`;
        let value = '';
        for (const series of param) {
          const { data, seriesName, color } = series;
          let innerValue = '';
          innerValue += `<tr>`;
          // Dot
          innerValue += `<td style='width: 12px'><div style="background:${color};width:12px;height:12px;border-radius:2px">&nbsp;</div></td>`;
          // Label
          innerValue += `<td style="color: #262626; font-size: 12px">${seriesName}</td>`;
          // Value
          innerValue += `<td class="figure" style="color: #262626; font-size: 12px">${(
            data || 0
          ).toLocaleString()}</td>`;
          innerValue += `</tr>`;
          value = innerValue + value;
        }
        tooltip += value;
        tooltip += `</table>`;
        tooltip += `</div>`;
        return tooltip;
      },
    },
    legend: {
      left: '0%',
      data: series.map((i: any) => i.name),
      itemWidth: 16,
      itemHeight: 16,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      data: xAxis,
      type: 'category',
      axisLine: {
        lineStyle: {
          color: '#8c8c8c',
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        lineStyle: {
          color: '#8c8c8c',
        },
      },
    },
    series: series,
  };
};

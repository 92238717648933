import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { Row, Col, ErrorHint, UploadLogo, SubmitButton, SubmitResult, SocialMedia } from 'components';
import { PageLayout, DetailBox, SuccessToast, ErrorToast } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useStore } from 'store';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { lang } from './helper';
import { Button, Intent, InputGroup, FormGroup, Radio, RadioGroup, TextArea, Icon } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import { Button as ButtonAnt, Popover } from 'antd';
import styles from './style.module.scss';
import * as yup from 'yup';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
// type IProps = RouteConfigComponentProps<IParams>;

const schema = yup.object().shape({
  key: yup.string().required('Network Spec Key is a required field'),
  subtitle: yup.string().required('Subtitle is a required field'),
  description: yup.string().required('Description is a required field'),
  projectWebsite: yup.string().url().required('Project website is a required field'),
  projectDocumentation: yup.string().url().required('Project Documentation is a required field'),
  icon: yup.string().required(),
  logo: yup.string().required(),
  resourceLinks: yup.array().of(
    yup.object().shape({
      title: yup.string().required(),
      link: yup.string().url().required(),
    }),
  ),
  socialMedia: yup.object().shape({
    twitter: yup.string().url(),
    telegram: yup.string().url(),
    medium: yup.string().url(),
    discord: yup.string().url(),
  }),
});

type IProps = {
  config: any;
  isPublish: boolean;
  id: string;
  isApp?: boolean;
  onCancal: () => void;
  data: any;
  showKey?: boolean;
};

export const MoreDetail: React.FC<IProps> = observer(
  ({ isPublish, id, onCancal, data, isApp = false, config, showKey = false }) => {
    const { t } = useTranslation();
    const { resourceLinks, key, name, metadata, protocolKey, displayName, imageRepository, nodeTypes } = data;
    const extraInfo = data.extraInfo || {};
    const { networks, workspaces, info } = useStore();
    const history = useHistory();
    const [makeSureChanged, setMakeSureChanged] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [pictures, setPictures] = useState({
      large: extraInfo?.pictures?.large,
      small: extraInfo?.pictures?.small,
      icon: extraInfo?.pictures?.icon,
    });
    const [links, setLinks] = useState<number[]>(
      resourceLinks?.length || extraInfo?.resourceLinks?.length
        ? Array.from(Array(resourceLinks?.length || extraInfo?.resourceLinks.length).keys()) //
        : [],
    );

    const mainArea = document.getElementById('mainArea');
    useEffect(() => {
      mainArea && mainArea.scrollTo(0, 0);
    }, []);

    const [isMainNet, setIsMainNet] = useState<boolean>(
      typeof extraInfo?.isMainNet === 'boolean' ? extraInfo?.isMainNet : true,
    );
    const { control, handleSubmit, errors, getValues, setValue, register, clearError, formState } = useForm({
      mode: 'onChange',
      validationSchema: schema,
    });

    const doSubmit = handleSubmit(async (values) => {
      let networkKey = values.key.trim();
      if (networkKey[networkKey.length - 1] === '-') networkKey = networkKey.substring(0, networkKey.length - 1);
      values.key = networkKey;
      const submitData = {
        ...values,
        isMainNet,
        pictures,
      };
      let recommends = [] as any;
      nodeTypes.forEach((i: any) => {
        if (!i?.recommend) return;
        const { imageVersion, nodeSpec, nodeSpecMultiplier, storageSize } = i.recommend || {};
        recommends.push({
          nodeType: i.key,
          nodeSpecKey: nodeSpec || 'unit',
          storageSize: storageSize,
          storageType: 'ssd',
          imageVersion: imageVersion,
          nodeSpecMultiplier: nodeSpecMultiplier,
        });
      });
      setIsloading(true);
      setShowResult(true);
      if (isApp) {
        await networks.publishNetwork(workspaces.current?.id, {
          id: +id,
          recommends: recommends,
          networkSpec: {
            config,
            key: values.key,
            name,
            metadata: {
              ...metadata,
              pictures,
            },
            protocol: protocolKey,
            displayName,
            imageRepository,
          },
          networkSpecExtraInfo: submitData,
        });
      } else if (!isPublish) {
        await networks.publishNetwork(workspaces.current!.id, {
          recommends: recommends,
          networkSpec: {
            config,
            key: values.key,
            name,
            metadata: {
              ...metadata,
              pictures,
            },
            protocol: protocolKey,
            displayName,
            imageRepository,
          },
          networkSpecExtraInfo: submitData,
        });
      } else {
        await networks.updateNetworkSpecs(workspaces.current!.id, id, {
          extraInfo: submitData,
          metadata: {
            ...metadata,
            pictures,
          },
        });
      }
      setIsloading(false);
      if (isPublish) {
        if (networks.state === 'error') ErrorToast(networks?.errorMessage);
        else SuccessToast('Success');
      }
    });

    return (
      <PageLayout>
        <div className={styles.container}>
          <div className={styles.mainContent}>
            <form className={styles.formContainer} onSubmit={doSubmit}>
              <input type="hidden" name="icon" ref={register} value={pictures?.icon} />
              <input type="hidden" name="logo" ref={register} value={pictures?.large} />

              <DetailBox className={styles.moreNetworkDetail}>
                <div className={styles.contentHeader}>
                  <h3>{lang(t, 'moreDetail.defineNetwork')}*</h3>
                  <p className={styles.subTitle}>{lang(t, 'moreDetail.defineNetworkIntroduction')}</p>
                </div>

                <RadioGroup
                  inline
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setIsMainNet(e.currentTarget.value === '1');
                    if (!makeSureChanged) setMakeSureChanged(true);
                  }}
                  className={styles.mainOrTestNet}
                  selectedValue={isMainNet === true ? '1' : '0'}
                >
                  <Radio label={lang(t, 'moreDetail.mainnet')} value="1" />
                  <Radio label={lang(t, 'moreDetail.testnet')} value="0" />
                </RadioGroup>

                <Row gutter>
                  <Col unit={8}>
                    <UploadLogo
                      defaultValue={extraInfo?.pictures?.icon}
                      label={`${lang(t, 'moreDetail.icon')}*`}
                      onUploaded={(url: string) => {
                        setPictures({
                          ...pictures,
                          icon: url,
                        });
                        if (!makeSureChanged) setMakeSureChanged(true);
                        setValue('icon', url);
                        clearError('icon');
                      }}
                    />

                    <ErrorHint message={errors.icon ? errors.icon.message : ''} />
                  </Col>

                  <Col unit={8}>
                    <UploadLogo
                      defaultValue={extraInfo?.pictures?.large}
                      label={`${lang(t, 'moreDetail.logo')}*`}
                      onUploaded={(url: string) => {
                        setPictures({
                          ...pictures,
                          large: url,
                          small: url,
                        });
                        if (!makeSureChanged) setMakeSureChanged(true);
                        setValue('logo', url);
                        clearError('logo');
                      }}
                    />
                    <ErrorHint message={errors.logo ? errors.logo.message : ''} />
                  </Col>
                </Row>

                <Row gutter>
                  <Col unit={12} className={styles.colSvg}>
                    <FormGroup
                      label={
                        <div>
                          {`${lang(t, 'moreDetail.networkSpecKey')}*`}
                          <Popover
                            content={
                              <div className={styles.popOver}>
                                <div>Unique identifier for integrations such as the OnFinality CLI tool.</div>
                                <div>Network Spec Key cannot be changed once set.</div>
                                <br />
                                <div>e.g.</div>
                                <div className={styles.example}>polkadot</div>
                              </div>
                            }
                            trigger="hover"
                            placement="right"
                          >
                            <Icon icon={IconNames.ISSUE} iconSize={12} />
                          </Popover>
                        </div>
                      }
                    >
                      <Controller
                        name="key"
                        control={control}
                        defaultValue={(!isApp && isPublish) || showKey ? key : ''}
                        onChange={([e]) => {
                          e.target.value = e.target.value.toLowerCase().replace(/ /g, '');
                          if (!makeSureChanged) setMakeSureChanged(true);
                          return e;
                        }}
                        as={<InputGroup maxLength={26} disabled={!isApp && isPublish} large fill required />}
                      />
                      <ErrorHint message={errors.key ? errors.key.message : ''} />
                    </FormGroup>
                  </Col>

                  <Col unit={12}>
                    <FormGroup label={`${lang(t, 'moreDetail.subtitle')}*`}>
                      <Controller
                        name="subtitle"
                        control={control}
                        defaultValue={extraInfo?.subtitle}
                        onChange={([e]) => {
                          if (!makeSureChanged) setMakeSureChanged(true);
                          return e;
                        }}
                        as={<InputGroup large fill required />}
                      />
                      <ErrorHint message={errors.subtitle ? errors.subtitle.message : ''} />
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup label={`${lang(t, 'moreDetail.description')}*`}>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue={extraInfo?.description}
                    onChange={([e]) => {
                      if (!makeSureChanged) setMakeSureChanged(true);
                      return e;
                    }}
                    as={<TextArea className={styles.description} placeholder={''} fill required />}
                  />
                  <ErrorHint message={errors.description ? errors.description.message : ''} />
                </FormGroup>

                <Row gutter>
                  <Col unit={12}>
                    <FormGroup label={`${lang(t, 'moreDetail.projectLink')}*`}>
                      <Controller
                        name="projectWebsite"
                        control={control}
                        defaultValue={extraInfo?.projectWebsite}
                        onChange={([e]) => {
                          if (!makeSureChanged) setMakeSureChanged(true);
                          return e;
                        }}
                        as={
                          <InputGroup
                            large
                            fill
                            // maxLength={nodeNameMaxLength}
                            // placeholder={lang(t, 'labelNamePlaceholder')}
                            required
                          />
                        }
                      />
                      <ErrorHint message={errors.projectWebsite ? errors.projectWebsite.message : ''} />
                    </FormGroup>
                  </Col>

                  <Col unit={12}>
                    <FormGroup label={`${lang(t, 'moreDetail.docmentationLink')}*`}>
                      <Controller
                        name="projectDocumentation"
                        control={control}
                        defaultValue={extraInfo?.projectDocumentation}
                        onChange={([e]) => {
                          if (!makeSureChanged) setMakeSureChanged(true);
                          return e;
                        }}
                        as={
                          <InputGroup
                            large
                            fill
                            // maxLength={nodeNameMaxLength}
                            // placeholder={lang(t, 'labelNamePlaceholder')}
                            required
                          />
                        }
                      />
                      <ErrorHint message={errors.projectDocumentation ? errors.projectDocumentation.message : ''} />
                    </FormGroup>
                  </Col>
                </Row>

                <div className={styles.contentHeader}>
                  <h3>{lang(t, 'moreDetail.resourceLinks')}</h3>
                </div>

                {links.map(
                  (link, i) =>
                    link >= 0 && (
                      <Row gutter key={link}>
                        <Col unit={12}>
                          <FormGroup label={`${lang(t, 'moreDetail.linkTitle')}`}>
                            <Controller
                              name={`resourceLinks[${link}].title`}
                              control={control}
                              defaultValue={resourceLinks?.[link]?.title || extraInfo.resourceLinks?.[link]?.title}
                              as={
                                <InputGroup
                                  large
                                  fill
                                  required
                                  //
                                />
                              }
                            />
                            <ErrorHint
                              message={
                                errors.resourceLinks?.[link]?.title ? errors.resourceLinks[link].title.message : ''
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col unit={10}>
                          <FormGroup label={`${lang(t, 'moreDetail.URL')}`}>
                            <Controller
                              name={`resourceLinks[${link}].link`}
                              control={control}
                              defaultValue={resourceLinks?.[link]?.link || extraInfo.resourceLinks?.[link]?.link}
                              as={
                                <InputGroup
                                  large
                                  fill
                                  required
                                  //
                                />
                              }
                            />
                            <ErrorHint message={errors.resourceLinks?.[link]?.link ? 'Please enter a URL' : ''} />
                          </FormGroup>
                        </Col>

                        <Col unit={2}>
                          <div className={styles.rowButton}>
                            <ButtonAnt
                              onClick={() => {
                                if (!makeSureChanged) setMakeSureChanged(true);
                                setLinks(
                                  links.map((ele) => {
                                    return ele === link ? -1 : ele;
                                  }),
                                );
                              }}
                              icon={<DeleteOutlined />}
                              type="link"
                              danger
                            />
                          </div>
                        </Col>
                      </Row>
                    ),
                )}

                <ButtonAnt
                  type="link"
                  icon={<PlusOutlined />}
                  style={{ marginBottom: 20 }}
                  onClick={() => {
                    setLinks([...links, links.length]);
                  }}
                >
                  Add Link
                </ButtonAnt>

                <div className={styles.contentHeader}>
                  <h3>{lang(t, 'moreDetail.socialNetworks')}</h3>
                </div>

                <Row gutter>
                  <SocialMedia media="twitter" control={control} errors={errors} data={data} lang={lang} />
                  <SocialMedia media="medium" control={control} errors={errors} data={data} lang={lang} />
                </Row>

                <Row gutter>
                  <SocialMedia media="telegram" control={control} errors={errors} data={data} lang={lang} />
                  <SocialMedia media="discord" control={control} errors={errors} data={data} lang={lang} />
                </Row>
              </DetailBox>
              <Row>
                <Col unit={12}>
                  <Button large intent={Intent.NONE} onClick={onCancal}>
                    {lang(t, 'cancel')}
                  </Button>
                </Col>
                <Col unit={12} alignRight>
                  <SubmitButton
                    type={'submit'}
                    large
                    loading={isLoading}
                    intent={Intent.PRIMARY}
                    disabled={isLoading ? true : isPublish ? !(makeSureChanged || formState.dirty) : false}
                  >
                    {isPublish ? lang(t, 'save') : lang(t, 'publish')}
                  </SubmitButton>
                </Col>
              </Row>
            </form>
          </div>
        </div>
        {showResult && (
          <SubmitResult
            loadingMainText={'Loading...'}
            successMainText={
              !isPublish ? lang(t, 'moreDetail.publishSuccessTitle') : lang(t, 'moreDetail.updateSuccessTitle')
            }
            successSubText={!isPublish ? lang(t, 'moreDetail.publishSuccessBody') : ''}
            successButton={!isPublish ? lang(t, 'moreDetail.publishButton') : lang(t, 'moreDetail.updateButton')}
            errorMainText={'Error'}
            errorSubText={networks?.errorMessage}
            errorButton={'Back'}
            status={networks.state}
            errorClick={() => {
              setShowResult(false);
            }}
            successClick={() => {
              if (!isPublish) return history.push(`/workspaces/${workspaces.current!.id}/networks`);
              onCancal();
            }}
          />
        )}
      </PageLayout>
    );
  },
);

import {
  Button,
  FormGroup,
  InputGroup,
  NumericInput,
  Radio,
  RadioGroup,
  Tooltip,
  HTMLSelect,
  ButtonGroup,
  Popover,
  PopoverPosition,
  Menu,
  MenuItem,
  Intent,
} from '@blueprintjs/core';
import cx from 'classnames';
import { Popover as AntdPopover, Alert, Input, Switch } from 'antd';
import { Instance } from 'mobx-state-tree';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectCard, Col, Row, DetailBox, ErrorToast, Loader, ErrorHint } from 'components';
import { NodeSpecSelector } from 'modules';
import { useStore } from 'store';
import { ICluster } from '@/store/info';
import { ImageVersionType } from '@/store/images';
import { NetworkSpec, NodeType, Recommend, allowNodeType } from '@/store/networks';
import { imageVersionTags } from '@/configuration';
import { NodeSpec } from '@/store/info';
import { createNodeMinStorage, nodeNameMaxLength, pruningModeNodeTypeMap } from '@/configuration';
import {
  GetHourlyPrice,
  isCloudDisable,
  isRegionDisable,
  isNodeTypeDisable,
  lang,
  providerStyleMap,
  providersFromClusters,
  regionsFromClusters,
} from './helpers';
import { ReactComponent as BackupIcon } from './img/backup.svg';
import styles from './style.module.scss';
import { NodeFormData } from './index';
import * as yup from 'yup';
import { mxConfig } from '@/config';
import { DisabledFeature } from '../../components/DisabledFeature';

interface IProps {
  initData: NodeFormData;
  onBack: () => void;
  onSubmit: (values: any) => void;
}

type NodeTypeMapType = {
  [key: string]: Instance<typeof Recommend | null>;
};
type NetworkType = Instance<typeof NetworkSpec | undefined>;

const findCurrentNodeType = (network: NetworkType, nodeType: string | undefined) => {
  if (!network) return null;
  return network.nodeTypes.find((item: Instance<typeof NodeType>) => item.key === nodeType);
};

const schema = yup.object().shape({
  // key: yup.string(),
  // logoUrl: yup.string(),
  name: yup.string().required(),
  provider: yup.string().required(),
  region: yup.string().required(),
  imageVersion: yup.string().required(),

  // description: yup.string(),
  // subtitle: yup.string().max(100),
  // // network: yup.string().min(2).max(20).required(),
  // gitRepository: yup.string().required('gitRepository must be required'),
  // // .test('oneOfRequired', 'This URL is not valid public GitHib repository', async (value) => {
  // //   const { data } = await checkRepoUrl(value);
  // //   return data?.valid === true;
  // // }),
  // hide: yup.boolean(),
});

export const StepForm: React.FC<IProps> = observer((props) => {
  const { t } = useTranslation();
  const { info, workspaces, networks, images, usageCenter } = useStore();
  const [nextValue, setNextValue] = useState(props.initData.name ?? '');
  const [imageVersion, setImageVersion] = useState<string | undefined>(props.initData.imageVersion);
  const [currentTag, setCurrentTag] = useState<string>(imageVersionTags[0]);
  const { control, handleSubmit, errors, getValues, setValue, register, clearError } = useForm({
    mode: 'onChange',
    validationSchema: schema,
  });
  const getCurrentRecommend = (network: NetworkType, currentNodeType: string | undefined) => {
    const thisNodeType = findCurrentNodeType(network, currentNodeType);
    const recommend = thisNodeType?.recommend || network?.recommend;
    if (recommend) {
      const { nodeSpec, storageSize, nodeSpecMultiplier } = recommend;
      return {
        nodeSpecMultiplier: nodeSpecMultiplier,
        nodeKey: nodeSpec,
        storage: storageSize,
      };
    }
    return {
      nodeKey: props.initData?.nodeSpec?.key ?? info.nodeSpecs[0]?.key,
      storage: props.initData.storage ?? 10,
    };
  };
  const afterDeailNodeSpec = info.getNodeSpec('unit') as Instance<typeof NodeSpec>;
  const [useRecommend, setUseRecommend] = useState(props.initData.useRecommend);

  const [nodeSpec, setNodeSpec] = useState(props.initData.nodeSpec ?? { key: afterDeailNodeSpec!.key, multiplier: 1 });

  const [nodeType, setNodeType] = useState<string | undefined>(props.initData.nodeType);
  let availableClusters: ICluster[] = [];
  let cluster: ICluster | undefined;
  let network: NetworkType;

  useEffect(() => {
    if (images.list.length <= 0) return;
    const currentImageList = images.filterList(currentTag);
    setImageVersion(currentImageList[0]?.version);
    setValue('imageVersion', currentImageList[0]?.version);
  }, [images.list.length]);

  useEffect(() => {
    (async () => {
      if (props.initData.isPublic) {
        await images.fetchPublicList(props.initData.key!);
      } else {
        await images.fetchList(workspaces.current!.id, props.initData.key!);
      }
    })();
    setValue('provider', props.initData?.provider);
    setValue('region', props.initData?.region);
    setValue('name', props.initData?.name);
    return () => {
      images.clearList();
    };
  }, []);

  if (props.initData.networkSpecKey) {
    network = networks.getByKey(props.initData.networkSpecKey);
    cluster = network?.metadata?.cluster ? info.getCluster(network.metadata.cluster) : undefined;
  }
  const nodeTypeMap: NodeTypeMapType = {};
  network?.nodeTypes.length &&
    network?.nodeTypes.forEach((i) => {
      const { key, recommend } = i;
      nodeTypeMap[key] = recommend;
    });

  if (!nodeTypeMap.full && network?.recommend) nodeTypeMap.full = network.recommend;
  const disableRecommendRadio = !(nodeType && nodeTypeMap[nodeType]);
  if (cluster) {
    availableClusters = [cluster];
  } else {
    availableClusters = info.clusters;
  }

  const defaultRecommend = getCurrentRecommend(network, nodeType);
  const [storage, setStorage] = useState(props.initData.storage || defaultRecommend.storage);
  const [provider, setProvider] = useState<string | undefined>(props.initData.provider ?? cluster?.cloud);
  const [region, setRegion] = useState<string | undefined>(props.initData.region ?? cluster?.region);
  const [autoExpand, setAutoExpand] = useState<boolean>(props.initData.autoExpand ?? false);
  const availableProviders: string[] = providersFromClusters(availableClusters);
  const availableRegions: { [key: string]: string }[] = regionsFromClusters(availableClusters);
  const nodeTypes = network?.nodeTypes.length ? network?.nodeTypes.map((i) => i.key) : [props.initData.nodeType];
  const currentNodeSpec = info.getNodeSpec('unit');
  const backups = networks.backupMap[props?.initData?.networkSpecKey || ''] || [];
  const allowShowBackup = allowNodeType(backups);
  let pruningMode = '';
  let allowBackupRegion: string[] = [];
  Object.keys(pruningModeNodeTypeMap).forEach((key: string) => {
    if (pruningModeNodeTypeMap[key].includes(nodeType + '')) pruningMode = key;
  });
  backups.forEach((backup) => {
    if (backup.pruningMode === pruningMode && !allowBackupRegion.includes(backup.clusterHash))
      allowBackupRegion.push(backup.clusterHash);
  });
  const currentRegion = availableRegions.find((i) => {
    return i.region === region;
  });
  const currentBackup =
    backups && backups.find((i) => i.clusterHash === currentRegion?.hash && pruningMode === i.pruningMode);

  const currentClusterIsBackup =
    currentBackup &&
    currentRegion &&
    nodeType &&
    allowShowBackup.includes(nodeType) &&
    allowBackupRegion.includes(currentRegion.hash);
  const useBackup = currentClusterIsBackup && currentBackup?.storageSize && currentBackup.storageSize <= storage;
  const doSubmit = handleSubmit(async (values) => {
    if (values.storage < createNodeMinStorage)
      return ErrorToast(lang(t, 'notice.minStorage', { size: createNodeMinStorage }));
    const data = {
      ...props.initData,
      ...values,
      name: values.name.trim(),
      nodeType,
      useRecommend,
      storage,
      nodeSpec,
      hasBackup: useBackup,
      autoExpand,
    };
    props.onSubmit(data);
  });

  const reSetNodeSpec = (newNodeType?: string) => {
    const recommend = getCurrentRecommend(network, newNodeType || nodeType);
    if (recommend) {
      const { nodeKey, storage, nodeSpecMultiplier } = recommend;
      setNodeSpec({
        key: nodeKey,
        multiplier: nodeSpecMultiplier || 1,
      });
      setStorage(storage);
      setValue('storage', storage);
    }
  };
  const currentImageList = images.filterList(currentTag);
  setValue('name', nextValue);

  const disableStorageSize = () => {
    // uncomment to disable
    // if (region === 'Tokyo' || region === 'Ireland' || region === 'N.Virginia') {
    //   return <DisabledFeature />;
    // }
    return undefined; // uncomment to enable
  };
  return (
    <>
      <form className={styles.formContainer} onSubmit={doSubmit}>
        <input type="hidden" name="provider" ref={register} />
        <input type="hidden" name="region" ref={register} />
        <input type="hidden" name="imageVersion" ref={register} />
        <input type="hidden" name="name" ref={register} />
        <DetailBox className={styles.section}>
          <FormGroup label={lang(t, 'labelName')}>
            <Input
              maxLength={nodeNameMaxLength}
              placeholder={lang(t, 'labelNamePlaceholder')}
              required
              value={nextValue}
              size="large"
              onChange={(e) => {
                setNextValue(e.target.value.trim().replace(/[^\a-\z\A-\Z0-9\_\-\s]/g, ''));
              }}
            />
            <ErrorHint message={errors.name ? errors.name.message : ''} />
          </FormGroup>
        </DetailBox>
        {!!nodeTypes && !!Object.keys(nodeTypeMap).length && (
          <DetailBox className={styles.section}>
            <FormGroup label={lang(t, 'nodeTypes')}>
              {Object.keys(nodeTypeMap).map((key) => (
                <SelectCard
                  className={cx({ [styles.selectcard]: true, [styles.selected]: nodeType === key })}
                  selected={nodeType === key}
                  disable={isNodeTypeDisable(provider, key)}
                  onClick={() => {
                    if (key !== nodeType) {
                      setNodeType(key);
                      reSetNodeSpec(key);
                      setUseRecommend(!!nodeTypeMap[key]);
                      if (key === 'trace') {
                        const recommendTraceImage = currentImageList.find(
                          (e) => e.imageRepository !== network?.imageRepository,
                        );
                        setImageVersion(recommendTraceImage?.version);
                        setValue('imageVersion', recommendTraceImage?.version);
                      } else {
                        const recommendTraceImage = currentImageList.find(
                          (e) => e.imageRepository === network?.imageRepository,
                        );
                        setImageVersion(recommendTraceImage?.version);
                        setValue('imageVersion', recommendTraceImage?.version);
                      }
                      if (key === 'collator' && region === 'Singapore') setRegion(undefined);
                    }
                  }}
                  key={key}
                >
                  <span className={styles.radioBox}>{lang(t, `nodeType.${key}`)}</span>
                  {allowShowBackup.includes(key) &&
                    (isNodeTypeDisable(provider, key) ? (
                      <BackupIcon
                        className={cx({
                          [styles.radioBackupIcon]: true,
                          [styles.disable]: true,
                        })}
                      />
                    ) : (
                      <AntdPopover
                        placement="rightTop"
                        content={
                          <div
                            className={styles.backupPopover}
                            dangerouslySetInnerHTML={{
                              __html: lang(t, `detail.lightningRestoreExplainForNodeType`, {
                                href: mxConfig.lightningRestoreDocument,
                              }),
                            }}
                          ></div>
                        }
                      >
                        <BackupIcon
                          className={cx({
                            [styles.radioBackupIcon]: true,
                          })}
                        />
                      </AntdPopover>
                    ))}
                </SelectCard>
              ))}
              <ErrorHint message={!nodeType ? lang(t, 'notice.nodeTypeCheck') : ''} />
            </FormGroup>
            <FormGroup
              label={nodeType !== 'trace' ? lang(t, 'imageVersion') : 'Trace Image Version'}
              className={styles.selectImageVersion}
            >
              <Loader isLoading={images.state === 'pending'} width={18} height={18} padding={false}>
                <ButtonGroup>
                  <Button text={currentTag} />
                  <Popover
                    className={styles.popover}
                    minimal
                    position={PopoverPosition.BOTTOM}
                    popoverClassName="network-actions"
                  >
                    <Button icon={'caret-down'} large />
                    <Menu>
                      {imageVersionTags.map((i) => (
                        <MenuItem
                          key={i}
                          text={i}
                          onClick={() => {
                            setCurrentTag(i);
                            const list = images.filterList(i);
                            if (list.length) {
                              const cv = list.find((v) => v.version === imageVersion);
                              setImageVersion(cv?.version || list[0]?.version);
                              setValue('imageVersion', cv?.version || list[0]?.version);
                            } else setImageVersion(undefined);
                          }}
                        />
                      ))}
                    </Menu>
                  </Popover>
                </ButtonGroup>
                <HTMLSelect
                  large
                  name={'imageVersion'}
                  value={imageVersion}
                  onChange={(e: any) => {
                    setImageVersion(e.target.value);
                    setValue('imageVersion', e.target.value);
                  }}
                >
                  {!currentImageList.length && <option>----</option>}
                  {!!currentImageList.length &&
                    currentImageList.map((i: Instance<typeof ImageVersionType>) => {
                      if (nodeType !== 'trace' && i.imageRepository === network?.imageRepository) {
                        return (
                          <option key={i.id} value={i.version}>
                            {i.version}
                            {i.recommendedImage !== null && i.recommendedImage && '    (Recommended)'}
                          </option>
                        );
                      } else if (nodeType === 'trace' && i.imageRepository !== network?.imageRepository) {
                        return (
                          <option key={i.id} value={i.version}>
                            {i.version}
                            {i.recommendedImage !== null && i.recommendedImage && '    (Recommended)'}
                          </option>
                        );
                      }
                    })}
                </HTMLSelect>
              </Loader>
              <ErrorHint message={errors.imageVersion ? lang(t, 'notice.imageVersionCheck') : ''} />
            </FormGroup>
          </DetailBox>
        )}
        <DetailBox className={styles.section}>
          <FormGroup label={lang(t, 'labelCloud&region')}>
            {availableProviders.map((p) => {
              const style = providerStyleMap[p];
              return (
                <SelectCard
                  hasIcon={false}
                  className={cx({ [styles.selectcard]: true, [styles.selected]: p === provider })}
                  selected={p === provider}
                  disable={isCloudDisable(availableClusters, p, region, nodeType)}
                  onClick={() => {
                    const providerValue = p === provider ? undefined : p;
                    setProvider(providerValue);
                    setValue('provider', providerValue);
                    providerValue && clearError('provider');
                    setRegion(undefined);
                    setValue('region', undefined);
                  }}
                  key={p}
                >
                  <div className={styles.providerBox}>
                    {React.createElement(style.img)}
                    <p>{style.txt}</p>
                  </div>
                </SelectCard>
              );
            })}
          </FormGroup>
          <FormGroup label={lang(t, 'region')} className={styles.region}>
            {availableRegions.map((r) => (
              <SelectCard
                className={cx({ [styles.selectcard]: true, [styles.selected]: region === r.region })}
                selected={region === r.region && !!provider && mxConfig.clusterHash[provider].includes(r.hash)}
                disable={
                  isRegionDisable(availableClusters, r.region, provider, nodeType) ||
                  (provider ? !mxConfig.clusterHash[provider].includes(r.hash) : false)
                }
                onClick={() => {
                  const regionValue = r.region === region ? undefined : r.region;
                  setRegion(regionValue);
                  setValue('region', regionValue);
                  if (regionValue !== undefined) {
                    const hasProvider =
                      availableProviders.find(
                        (e) => !isCloudDisable(availableClusters, e, regionValue, nodeType, r.hash),
                      ) ?? undefined;
                    hasProvider && setProvider(hasProvider);
                    hasProvider && setValue('provider', hasProvider);
                  } else {
                    setProvider(undefined);
                    setValue('provider', undefined);
                  }
                  regionValue && clearError('region');
                }}
                key={`${r.hash}-${r.region}`}
              >
                <span className={styles.radioBox}>{r.region}</span>
                {nodeType &&
                  allowShowBackup.includes(nodeType) &&
                  allowBackupRegion.includes(r.hash) &&
                  (isRegionDisable(availableClusters, r.region, provider) ? (
                    <BackupIcon className={cx(styles.radioBackupIcon, styles.disable)} />
                  ) : (
                    <AntdPopover
                      placement="rightTop"
                      content={
                        <div
                          className={styles.backupPopover}
                          dangerouslySetInnerHTML={{
                            __html: lang(t, `detail.lightningRestoreOnFrom`, {
                              href: mxConfig.lightningRestoreDocument,
                            }),
                          }}
                        ></div>
                      }
                    >
                      <BackupIcon className={cx({ [styles.radioBackupIcon]: true })} />
                    </AntdPopover>
                  ))}
              </SelectCard>
            ))}
            <ErrorHint message={errors.provider ? lang(t, 'notice.clusterCheck') : ''} />
            <ErrorHint message={errors.region ? lang(t, 'notice.regionCheck') : ''} />
          </FormGroup>
        </DetailBox>
        <DetailBox className={cx(styles.section)}>
          <FormGroup>
            <div className={styles.label}>
              <div>{lang(t, 'labelNodeSpec')}</div>
              <RadioGroup
                inline
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  if (e.currentTarget.value === '1') {
                    reSetNodeSpec();
                  }
                  setUseRecommend(e.currentTarget.value === '1');
                }}
                selectedValue={disableRecommendRadio === true || !useRecommend ? '0' : '1'}
              >
                <Radio label={lang(t, 'defaultSetting')} value="1" disabled={disableRecommendRadio} />
                <Radio label={lang(t, 'advancedSetting')} value="0" />
              </RadioGroup>
            </div>
            <hr className={styles.hr} />
            {/* <StorageSize width={82} height={89}/> */}
            {/* <NodeSize /> */}
            <div className={cx({ [styles.hide]: !useRecommend })}>
              <Row className={styles.sizeOutside}>
                <Col unit={14}>
                  <div className={styles.subLabel}>
                    <label>{lang(t, 'nodeSize')}</label>
                  </div>
                  <div className={styles.defaultSetting}>
                    <span className={styles.nodeSizeIcon}></span>
                    <p className={styles.nodeSizeDetailStyle}>
                      <span>{defaultRecommend.nodeKey === 'unit' && defaultRecommend.nodeSpecMultiplier + ' '}</span>
                      {`${t(`modules.nodeSpecSelector.${defaultRecommend.nodeKey}`)}`}
                    </p>
                    <p className={cx(styles.nodeSizeDetail, styles.tc)}>
                      {`${
                        defaultRecommend.nodeSpecMultiplier &&
                        currentNodeSpec?.cpuSize &&
                        (defaultRecommend.nodeSpecMultiplier * currentNodeSpec?.cpuSize).toFixed(1)
                      }CPUs & ${
                        defaultRecommend.nodeSpecMultiplier &&
                        currentNodeSpec?.memorySize &&
                        (defaultRecommend.nodeSpecMultiplier * currentNodeSpec?.memorySize) / 1024
                      }GB Memory`}
                    </p>
                  </div>
                </Col>
                <Col unit={10}>
                  <div className={styles.subLabel}>
                    <label>{lang(t, 'storageSize')}</label>
                  </div>
                  <div className={styles.defaultSetting}>
                    <span className={styles.storageSizeIcon}></span>
                    <p className={styles.nodeSizeDetailStyle}>
                      <span>{defaultRecommend.storage}</span> GB
                    </p>
                    {currentBackup?.storageSize &&
                      currentClusterIsBackup &&
                      currentBackup?.storageSize > defaultRecommend.storage && (
                        <p className={cx(styles.nodeSizeDetail, styles.tc)}>
                          {lang(t, `detail.minimumBackup`, { storageSize: currentBackup?.storageSize })}
                        </p>
                      )}
                  </div>
                </Col>
              </Row>
            </div>
            <div className={cx({ [styles.hide]: useRecommend })}>
              <Row className={styles.sizeOutside}>
                <Col unit={14}>
                  <div className={styles.subLabel}>
                    <label>{lang(t, 'nodeSize')}</label>
                  </div>
                  <div className={styles.defaultSetting}>
                    <span className={styles.nodeSizeIcon}></span>
                    <NodeSpecSelector
                      nodeSpecs={[afterDeailNodeSpec]}
                      value={nodeSpec}
                      onChange={({ key, multiplier }) => {
                        setNodeSpec({
                          key,
                          multiplier,
                        });
                      }}
                    />
                    <div className={styles.nodeSizeDetail}>
                      <span>Unit: {nodeSpec.multiplier}</span>
                      <span>CPU: {(nodeSpec.multiplier * (currentNodeSpec?.cpuSize || 1)).toFixed(1)}</span>
                      <span>Memory: {(nodeSpec.multiplier / 1024) * (currentNodeSpec?.memorySize || 1)}G</span>
                    </div>
                  </div>
                </Col>
                <Col unit={10}>
                  <div className={styles.subLabel}>
                    <label>{lang(t, 'storageSize')}</label>
                  </div>
                  <div className={styles.defaultSetting}>
                    <span className={styles.storageSizeIcon}></span>
                    <div>
                      <div className={styles.storageSizeOutside}>
                        <NumericInput
                          disabled={!!disableStorageSize()}
                          name="storage"
                          ref={register}
                          intent={
                            currentBackup === undefined ? Intent.NONE : storage < 10 ? Intent.DANGER : Intent.NONE
                          }
                          stepSize={10}
                          defaultValue={props.initData.storage ?? createNodeMinStorage}
                          onBlur={(e) => {
                            if (+e.target.value < 10) return setStorage(defaultRecommend.storage);
                          }}
                          value={storage}
                          fill
                          className={styles.storageSize}
                          onValueChange={(v) => {
                            if (v > usageCenter.limit.createNodeMaxStorage)
                              return setStorage(usageCenter.limit.createNodeMaxStorage);
                            setStorage(v);
                          }}
                        />
                      </div>
                      <span>GB</span>
                    </div>
                    <div className={styles.nodeSizeDetail}>{disableStorageSize()}</div>
                    {(currentBackup === undefined ? false : storage < 10) && (
                      <ErrorHint message={lang(t, 'storageMinLimit', { size: defaultRecommend.storage })} />
                    )}
                    {currentBackup?.storageSize && currentClusterIsBackup && currentBackup?.storageSize > storage && (
                      <div
                        className={cx({
                          [styles.nodeSizeDetail]: true,
                        })}
                      >
                        {lang(t, `detail.minimumBackup`, { storageSize: currentBackup?.storageSize })}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </FormGroup>
          {nodeType !== 'collator' && nodeType !== 'validator' && (
            <>
              <hr className={styles.hr} />
              <div
                className={cx(
                  workspaces.current?.billingType === 'stripe' ? styles.autoExpandBlock : styles.estimatedPrice,
                )}
              >
                <div className={styles.switchTitle}>
                  <Switch onChange={() => setAutoExpand(!autoExpand)} checked={autoExpand} />{' '}
                  {lang(t, 'autoExpandNodeStorage')}
                </div>
                <div className={styles.autoExpandDescription}>
                  {lang(t, 'autoExpandDescription')}{' '}
                  <a href="https://documentation.onfinality.io/support/auto-expand-storage" target={'_blank'}>
                    {lang(t, 'learnMore')} {lang(t, 'here')}
                  </a>
                </div>
              </div>
            </>
          )}
          <>
            <hr className={styles.hr} />
            <div className={styles.estimatedPrice}>
              {lang(t, 'detail.price')}{' '}
              <span>
                USD $
                {GetHourlyPrice(
                  currentNodeSpec,
                  storage,
                  nodeSpec.multiplier,
                  workspaces.current?.billingType !== 'stripe',
                )}
              </span>
            </div>
          </>
        </DetailBox>

        {!!region && currentClusterIsBackup && !useBackup && backups.length > 0 && (
          <Alert className={styles.notBackAlert} message={lang(t, 'storageNotFitBackup')} type="info" />
        )}
        {!!region && nodeType && !allowShowBackup.includes(nodeType) && backups.length > 0 && (
          <Alert
            className={styles.notBackAlert}
            message={lang(t, 'typeNotFitBackup', {
              networkName: network?.displayName,
              nodeType: nodeType.charAt(0).toUpperCase() + nodeType.slice(1),
            })}
            type="info"
          />
        )}
        {!!region &&
          nodeType &&
          currentRegion &&
          !allowBackupRegion.includes(currentRegion.hash) &&
          allowShowBackup.includes(nodeType) &&
          backups.length > 0 && (
            <Alert
              className={styles.notBackAlert}
              message={lang(t, 'regionNotFitBackup', {
                regionName: currentRegion.region,
                networkName: network?.displayName,
                nodeType: nodeType.charAt(0).toUpperCase() + nodeType.slice(1),
              })}
              type="info"
            />
          )}
        <Row>
          <Col unit={12}>
            <Button large onClick={props.onBack} className={styles.bottomBtn}>
              {lang(t, 'buttonBack')}
            </Button>
          </Col>
          <Col unit={12} style={{ textAlign: 'right' }}>
            <Button
              disabled={currentBackup === undefined ? false : storage < 10}
              type="submit"
              className={styles.bottomBtn}
              large
              intent="primary"
            >
              {lang(t, 'buttonNext')}
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
});

import React, { useState } from 'react';
import { Button, ControlGroup, InputGroup, Intent } from '@blueprintjs/core';
import cx from 'classnames';
import { ReactComponent as EditIcon } from './edit.svg';
import styles from './style.module.scss';

interface IProps {
  value: string;
  minLength?: number;
  maxLength?: number;
  onChange: (val: string) => Promise<boolean>;
  disabled?: boolean;
}

export const EditableField: React.FC<IProps> = (props) => {
  const [editing, setEditing] = useState(false);
  const [nextValue, setNextValue] = useState(props.value);
  const [error, setError] = useState(false);

  return (
    <div className={styles.container}>
      <Button
        className={cx({ [styles.editButton]: true, [styles.hide]: editing })}
        minimal={true}
        text={props.value}
        rightIcon={<EditIcon />}
        onClick={() => setEditing(true)}
        disabled={props.disabled}
      />
      <form
        className={cx({ [styles.hide]: !editing })}
        onSubmit={async (e) => {
          e.preventDefault();
          if (props.minLength && nextValue.length < props.minLength) {
            setError(true);
            return;
          }
          if (props.maxLength && nextValue.length > props.maxLength) {
            setError(true);
            return;
          }
          const r = await props.onChange(nextValue);
          if (!r) {
            setError(true);
            return;
          }
          setError(false);
          setEditing(false);
        }}
      >
        <ControlGroup>
          <InputGroup
            value={nextValue}
            intent={error ? Intent.DANGER : Intent.NONE}
            maxLength={props.maxLength}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNextValue(e.currentTarget.value.trim().replace(/[^\a-\z\A-\Z0-9\_\-\s]/g, ''))
            }
          />
          <Button type={'submit'} icon={'tick'} />
          <Button type="button" icon={'cross'} onClick={() => setEditing(false)} />
        </ControlGroup>
      </form>
    </div>
  );
};

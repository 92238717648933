import { loadMxConfig } from '@/config';
import { CheckSessionOptions, WebAuth } from 'auth0-js';
import dayjs from 'dayjs';
import promisify from 'util.promisify';

export interface IAuthPayload {
  accessToken: string;
  idToken: string;
  idTokenPayload: {
    iss: string;
    sub: string;
    aud: string;
    iat: number;
    exp: number;
    atHash: string;
    nonce: string;
  };
  appState: string;
  refreshToken: null;
  state: string;
  expiresIn: number;
  tokenType: 'Bearer';
  scope: null | string;
}

let _authClient: WebAuth;

const getAuthClient = async () => {
  if (!_authClient) {
    const config = await loadMxConfig();
    _authClient = new WebAuth({
      ...config.auth,
      responseType: 'id_token token',
    });
  }
  return _authClient;
};

export async function checkSession(opts: CheckSessionOptions): Promise<IAuthPayload> {
  const authClient = await getAuthClient();
  const p = promisify(authClient.checkSession).bind(authClient);
  return p(opts);
}

export async function signup(name: string, email: string, password: string) {
  const authClient = await getAuthClient();
  const signupAsync = promisify(authClient.signup).bind(authClient);
  return signupAsync({ name, email, password, connection: 'Username-Password-Authentication' } as any);
}

export async function login(email: string, password: string): Promise<IAuthPayload> {
  const authClient = await getAuthClient();
  const loginWithCredentials = promisify(authClient.popup.loginWithCredentials).bind(authClient.popup);
  return loginWithCredentials({ email, password } as any);
}

export function authorize(connection: string) {
  getAuthClient().then((authClient) => authClient.authorize({ connection }));
}

export async function handleLoginCallback(): Promise<IAuthPayload> {
  const authClient = await getAuthClient();
  const parseHash = promisify(authClient.parseHash).bind(authClient);
  return parseHash() as any;
}

export async function changePassword(email: string) {
  const authClient = await getAuthClient();
  const changePasswordAsync = promisify(authClient.changePassword).bind(authClient);
  return changePasswordAsync({ email, connection: 'Username-Password-Authentication' });
}

export async function logout() {
  const authClient = await getAuthClient();
  return new Promise<void>((resolve) => {
    const frame = document.createElement('iframe');
    frame.src = authClient.client.buildLogoutUrl();
    frame.width = '0px';
    frame.height = '0px';
    frame.style.display = 'none';
    frame.onload = () => {
      document.body.removeChild(frame);
      resolve();
    };
    document.body.appendChild(frame);
  });
}

export function extractToken(token: IAuthPayload) {
  const { accessToken, expiresIn } = token;
  if (!accessToken || !expiresIn) {
    throw new Error('Failed to get access token');
  }
  // Add a slight skew to avoid server side expiry while client side has no chance to renew the token
  const expiredAt = dayjs().unix() + Number(expiresIn) - 10;
  return {
    accessToken,
    expiredAt: expiredAt.toString(),
  };
}

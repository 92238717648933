import { TFunction } from 'i18next';
import React from 'react';
import { INode, INodes } from '../../store/nodes';
import { EthPanel } from './dataPanels/EthPanel';
import { SubstratePanel } from './dataPanels/SubstratePanel';
import { AvalanchePanel } from './dataPanels/AvalanchePanel';
import { ParaChain } from './dataPanels/ParaChain';

export const lang = (t: TFunction, key: string, options?: any) => t(`pages.nodeDetail.${key}`, options);

export interface PanelProps {
  nodes?: INodes;
}

export interface ParseMetricsDetails {
  hasPrometheusArg: boolean;
  hasPrometheusRelayArg: boolean;
  needMetrics: boolean;
  needMetricsRelay: boolean;
  needFetchEndpoints: boolean;
}

export const dataPanel: { [key: string]: React.FC<PanelProps> } = {
  polkadot: SubstratePanel,
  'polkadot-parachain': ParaChain,
  substrate: SubstratePanel,
  avalanche: AvalanchePanel,
  ethereum: EthPanel,
};

// Parse the node state to get the metrics endpoint settings/details
// This should be called whenever the node state is updated
export const parseMetricsDetails = (current?: INode): ParseMetricsDetails => {
  let hasPrometheusArg = false;
  let hasPrometheusRelayArg = false;
  let needMetrics = false;
  let needMetricsRelay = false;

  if (current && current.endpoints) {
    const endpoints = current.endpoints;
    const arg = current.metadata?.parameters?.arg;
    const protocol = current.networkSpec?.protocolKey;

    let parachainArgs: string[] = [],
      relaychainArgs: string[] = [];

    // If need to fetch metrics endpoint
    needMetrics = !endpoints?.metrics;

    // If having Prometheus arg or not
    if (protocol === 'polkadot-parachain') {
      const argsDivider: number = arg.indexOf('--');
      if (argsDivider > -1) {
        parachainArgs = arg.slice(0, argsDivider);
        relaychainArgs = arg.slice(argsDivider + 1);
        hasPrometheusArg = parachainArgs.includes('--prometheus-external');
      }
    } else if (protocol === 'avalanche') {
      hasPrometheusArg = true;
      needMetrics = false;
    } else {
      hasPrometheusArg = arg?.includes('--prometheus-external');
    }

    // If having Relay Prometheus arg
    hasPrometheusRelayArg = relaychainArgs.length > 0 && relaychainArgs.includes('--prometheus-external');

    // If need to fetch metrics relay endpoint
    needMetricsRelay = !endpoints?.['metrics-relay'];
  }
  return {
    hasPrometheusArg,
    hasPrometheusRelayArg,
    needMetrics,
    needMetricsRelay,
    needFetchEndpoints: (hasPrometheusArg && needMetrics) || (hasPrometheusRelayArg && needMetricsRelay),
  };
};

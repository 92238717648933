import React from 'react';
import { TFunction } from 'i18next';
import { Instance } from 'mobx-state-tree';
import { Sumary } from '@/store/networkSpec';
import dayjs, { Dayjs } from 'dayjs';
import { nodeSpecsMultiplier } from '@/configuration';
import { UTCDate } from '@/utils/tools';
import { SummaryTypes, numberFormat } from '@/store/usageCenter';
import styles from './style.module.scss';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import { ReactComponent as SettingIcon } from '../../res/img/setting.svg';
import { Button } from 'antd';

dayjs.extend(dayjsPluginUTC as any);

export interface ChartDataType {
  x: string[];
  y: number[];
}

export const lang = (t: TFunction, key: string, options?: any) => t(`pages.usageCenter.${key}`, options);

export const charOpt = (data: Array<Instance<typeof Sumary>> | undefined | null, duration: string) => {
  const dateForm = duration === '24h' ? 'YYYY-MM-DD HH' : 'YYYY-MM-DD';
  let series = [] as any;
  let xAxis = [] as any;
  let dataMap = {} as any;
  const thisDate = new Date().toISOString().split('T')[0];
  data?.forEach((item: any) => {
    const currentName = item.clusterDetail?.region ?? item.cluster;
    if (currentName === 'all') return;
    item.usage.forEach((i: any) => {
      const tempDate = dayjs(i.time).format(dateForm);
      if (tempDate === thisDate) return;
      if (!dataMap[tempDate]) dataMap[tempDate] = {};
      if (!dataMap[tempDate][currentName]) dataMap[tempDate][currentName] = i.count;
      else dataMap[tempDate][currentName] += i.count;
    });
  });
  let seriesMap = {} as any;
  Object.keys(dataMap).forEach((key) => {
    xAxis.push(key);
    Object.keys(dataMap[key]).forEach((value) => {
      if (!seriesMap[value]) seriesMap[value] = [];
      seriesMap[value].push(dataMap[key][value]);
    });
  });
  Object.keys(seriesMap).forEach((key) => {
    series.push({
      name: key,
      type: key === 'all' ? 'line' : 'bar',
      stack: key === 'all' ? '' : 'total',
      data: seriesMap[key],
    });
  });
  return {
    color: ['#5470c6', '#fac858', '#ee6666', '#91cc75', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      x: 'center',
      y: '0px',
      data: series.map((i: any) => i.name),
      textStyle: {
        color: '#000',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      data: xAxis,
      type: 'category',
    },
    yAxis: {
      type: 'value',
    },
    series: series,
  };
};

export const initXY = (st: Dayjs, et: Dayjs): ChartDataType => {
  let x = [] as string[];
  let y = [] as any;
  const overThreeMonths = st && et ? dayjs(et).startOf('day').diff(dayjs(st).startOf('day'), 'day') > 90 : false;
  let day: Dayjs = overThreeMonths ? dayjs(et).startOf('day').subtract(90, 'day') : st.startOf('day');
  // let day: Dayjs = st;
  let i = day.startOf('day').valueOf();
  while (i <= et.startOf('day').valueOf()) {
    x.push(day.format('YYYY-MM-DD'));
    y.push(0);
    day = day.add(1, 'day');
    i = day.valueOf();
  }
  return { x, y };
};

export const usageChartData = (data: any, st: Dayjs, et: Dayjs): ChartDataType => {
  let x: any = [];
  let y = [];
  let currentDate = st;

  while (currentDate.isBefore(et) || currentDate.isSame(et, 'day')) {
    x.push(currentDate.format('YYYY-MM-DD'));
    y.push(0);
    currentDate = currentDate.add(1, 'day');
  }

  data.forEach((item: any) => {
    let endDate = dayjs.utc(item.end).format('YYYY-MM-DD');
    let index = x.indexOf(endDate);
    if (index !== -1) {
      y[index] = item.count;
    }
  });

  return { x, y };
};

export const charOptDaily = (t: any, data: ChartDataType, currentTab: any) => {
  const { x, y } = data;
  return {
    xAxis: {
      data: x,
      type: 'category',
      axisLine: {
        lineStyle: {
          color: '#bfbfbf',
        },
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        // show: true,
        lineStyle: {
          color: '#bfbfbf',
        },
      },
    },
    tooltip: {
      trigger: 'item',
    },
    calculable: true,
    grid: {
      left: '1%',
      right: '1%',
      bottom: '3%',
      top: '6%',
      containLabel: true,
    },
    series: {
      data: y,
      type: 'line',
      lineStyle: {
        width: 1,
      },
      color: '#031FEF99',
      tooltip: {
        position: 'bottom',
        borderColor: '#fff',
        formatter: (param: any) => {
          const { name, data } = param;
          return `
            <div class='tooltip'>
              <h5>${
                currentTab === 'cpu'
                  ? lang(t, `CPU`) + ' ' + lang(t, 'usageSummary')
                  : currentTab === 'storage'
                  ? lang(t, `storage`) + ' ' + lang(t, 'usageSummary')
                  : currentTab === 'backup'
                  ? lang(t, `backup`) + ' ' + lang(t, 'usageSummary')
                  : currentTab === 'dataTraffic'
                  ? lang(t, `apiDataTraffic`) + ' ' + lang(t, 'usageSummary')
                  : currentTab === 'apiRequests'
                  ? lang(t, `apiRequests`) + ' ' + lang(t, 'usageSummary')
                  : ''
              }</h5>
              <div class= 'dailyDate'>${name}</div>
              <div class='dailyFlex'>
                <span class='totalUnit'>Total Usage</span>
                <span class='totalUnit'>
                ${
                  data.toLocaleString() +
                  (currentTab === 'cpu'
                    ? ' Unit Hrs'
                    : currentTab === 'storage'
                    ? ' GB Hrs'
                    : currentTab === 'backup'
                    ? ' GB Hrs'
                    : currentTab === 'dataTraffic'
                    ? ' GB'
                    : currentTab === 'apiRequests'
                    ? ' R. Units'
                    : '')
                }
                </span>
              </div>
            </div>
          `;
        },
      },
    },
  };
};

export const getColumn = (t: any, key: string, actions?: { [key: string]: Function }, isAdmin?: boolean) => {
  if (key === SummaryTypes.CPU) {
    return [
      {
        name: lang(t, `tableHeader.start`),
        field: 'start',
        sort: true,
        renderer: (v: string) => {
          return v ? UTCDate(v) : '--';
        },
      },
      {
        name: lang(t, `tableHeader.end`),
        field: 'end',
        sort: true,
        renderer: (v: string) => {
          return v ? UTCDate(v) : '--';
        },
      },
      {
        name: lang(t, `tableHeader.name`),
        field: 'name',
        sort: true,
      },
      {
        name: lang(t, `tableHeader.spec`),
        field: 'quantity',
        sort: true,
        renderer: (v: string, data: any) => {
          return `${data.kind_param === 'unit' ? v : nodeSpecsMultiplier[data.kind_param]} Unit(s)`;
        },
      },
      {
        name: lang(t, `tableHeader.totalHours`),
        field: 'usage_hours',
        sort: true,
        renderer: (v: string) => {
          return `${numberFormat(+v)} Hrs`;
        },
      },
      {
        name: lang(t, `tableHeader.totalUsage`),
        field: 'total_usage',
        sort: true,
        renderer: (v: string, data: any) => {
          return `${numberFormat(+v)} Unit Hrs`;
        },
      },
    ];
  } else if (key === SummaryTypes.Storage) {
    return [
      {
        name: lang(t, `tableHeader.start`),
        field: 'start',
        sort: true,
        renderer: (v: string) => {
          return v ? UTCDate(v) : '--';
        },
      },
      {
        name: lang(t, `tableHeader.end`),
        field: 'end',
        sort: true,
        renderer: (v: string) => {
          return v ? UTCDate(v) : '--';
        },
      },
      {
        name: lang(t, `tableHeader.name`),
        field: 'name',
        sort: true,
      },
      {
        name: lang(t, `tableHeader.spec`),
        field: 'quantity',
        sort: true,
        renderer: (v: string) => {
          return `${v} GB`;
        },
      },
      {
        name: lang(t, `tableHeader.totalHours`),
        field: 'usage_hours',
        sort: true,
        renderer: (v: string) => {
          return `${numberFormat(+v)} Hrs`;
        },
      },
      {
        name: lang(t, `tableHeader.totalUsage`),
        field: 'total_usage',
        sort: true,
        renderer: (v: string, data: any) => {
          return `${numberFormat(+v)} GB Hrs`;
        },
      },
    ];
  } else if (key === SummaryTypes.Backup) {
    const columns: any[] = [
      {
        name: lang(t, `tableHeader.start`),
        field: 'start',
        sort: true,
        renderer: (v: string) => {
          return v ? UTCDate(v) : '--';
        },
      },
      {
        name: lang(t, `tableHeader.end`),
        field: 'end',
        sort: true,
        renderer: (v: string) => {
          return v ? UTCDate(v) : '--';
        },
      },
      {
        name: lang(t, `tableHeader.networkName`),
        field: 'network_spec',
        sort: true,
      },
      {
        name: lang(t, `tableHeader.cluster`),
        field: 'region',
        sort: true,
        renderer: (v: string, data: any) => {
          return (
            <span>
              <span className={styles.uppercase}>{data.cloud}</span>-{v}
            </span>
          );
        },
      },
      {
        name: lang(t, `tableHeader.pruningMode`),
        field: 'pruning_mode',
        sort: true,
      },
      {
        name: lang(t, `tableHeader.spec`),
        field: 'quantity',
        sort: true,
        renderer: (v: string) => {
          return `${v} GB`;
        },
      },
      {
        name: lang(t, `tableHeader.totalHours`),
        field: 'usage_hours',
        sort: true,
        renderer: (v: string) => {
          return `${numberFormat(+v)} Hrs`;
        },
      },
      {
        name: lang(t, `tableHeader.totalUsage`),
        field: 'total_usage',
        sort: true,
        renderer: (v: string, data: any) => {
          return `${numberFormat(+v)} GB Hrs`;
        },
      },
    ];

    if (isAdmin) {
      columns.push({
        name: '#',
        field: 'resource_id',
        renderer: (id: string) => {
          return <Button icon={<SettingIcon />} onClick={() => actions && actions['backup-action'](id)} />;
        },
      });
    }

    return columns;
  } else if (key === SummaryTypes.DataTraffic) {
    return [
      {
        name: lang(t, `tableHeader.start`),
        field: 'start',
        sort: true,
        renderer: (v: string) => {
          return v ? UTCDate(v) : '--';
        },
      },
      {
        name: lang(t, `tableHeader.end`),
        field: 'end',
        sort: true,
        renderer: (v: string) => {
          return v ? UTCDate(v) : '--';
        },
      },
      {
        name: lang(t, `tableHeader.networkName`),
        field: 'network',
        sort: true,
      },
      {
        name: lang(t, `tableHeader.totalUsage`),
        field: 'size',
        sort: true,
        renderer: (v: string, data: any) => {
          return `${numberFormat(+v, 1024 * 1024 * 1024, 3)} GB`;
        },
      },
    ];
  } else if (key === SummaryTypes.APIRequests) {
    return [
      {
        name: lang(t, `tableHeader.start`),
        field: 'start',
        sort: true,
        renderer: (v: string) => {
          return v ? UTCDate(v) : '--';
        },
      },
      {
        name: lang(t, `tableHeader.end`),
        field: 'end',
        sort: true,
        renderer: (v: string) => {
          return v ? UTCDate(v) : '--';
        },
      },
      {
        name: lang(t, `tableHeader.app`),
        field: 'appName',
        renderer: (v: string) => {
          return `${v}`;
        },
      },
      {
        name: lang(t, `tableHeader.totalUsage`),
        field: 'weighted',
        sort: true,
        renderer: (v: string, data: any) => {
          return `${v}`;
        },
      },
    ];
  }
};

export const initListData = (showMore: boolean) => {
  return showMore
    ? {
        [SummaryTypes.CPU]: {
          totalPage: 0,
          total: 0,
          data: [],
        },
        [SummaryTypes.Storage]: {
          totalPage: 0,
          total: 0,
          data: [],
        },
        [SummaryTypes.Backup]: {
          totalPage: 0,
          total: 0,
          data: [],
        },
        [SummaryTypes.DataTraffic]: {
          totalPage: 0,
          total: 0,
          data: [],
        },
        [SummaryTypes.APIRequests]: {
          totalPage: 0,
          total: 0,
          data: [],
        },
      }
    : {
        [SummaryTypes.CPU]: {
          totalPage: 0,
          total: 0,
          data: [],
        },
        [SummaryTypes.Storage]: {
          totalPage: 0,
          total: 0,
          data: [],
        },
        [SummaryTypes.APIRequests]: {
          totalPage: 0,
          total: 0,
          data: [],
        },
      };
};

import { Button, Tab } from '@blueprintjs/core';
import { createNodeMinStorage } from '@/configuration';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  DetailBox,
  Preview,
  Loader,
  NodeSizeStorageSize,
  UpdateNetworkRules,
  Tabs,
  SubmitButton,
  WarningToast,
} from 'components';
import { useStore } from 'store';
import styles from './style.module.scss';
import { PrivateNetworkFormData, NodeRecommendItem } from './index';
import { launchData, LaunchDataMapType } from '@/components/Preview/help';
import { checkHasError, ConfigErrorCode } from '@/components/UpdateNetworkRules/helpers';
import { Operations, lang, transformArg, transformVar, transformEnv, NetworkFormData } from './helpers';
import { Steps } from './Steps';
import cx from 'classnames';

interface IProps {
  initData: PrivateNetworkFormData;
  isCreating: boolean;
  onBack: () => void;
  onSubmit: (values: any) => void;
}

interface Sections {
  extraArgKey: string;
  index: number;
  title: string;
}

export const StepForm: React.FC<IProps> = observer(({ onBack, onSubmit, initData, isCreating }) => {
  const { t } = useTranslation();
  const { networks } = useStore();

  const [previewDataMap, setPreviewDataMap] = useState<{
    [key: string]: NetworkFormData;
  }>({});
  const [actived, setActived] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [step, setStep] = useState(1);
  const [nodeRecommend, setNodeRecommend] = useState<{ [key: string]: NodeRecommendItem }>({});
  const [selectedTabId, setSelectedTabId] = useState<string>();
  const [protocolRules, setProtocolRules] = useState<any>({});
  const { handleSubmit } = useForm();
  const [hasError, setHasError] = useState(false);
  const mainArea = document.getElementById('mainArea');

  useEffect(() => {
    mainArea && mainArea.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    (async () => {
      setIsloading(true);
      // const network = await networks.getByKey(initData.key + '');
      const { metadata } = await networks.getProtocols(initData.protocol + '');
      setProtocolRules(metadata.rules);
      setIsloading(false);
      // const { argumentSections } = metadata;
      let dataMap = launchData(metadata) as LaunchDataMapType;
      const tempNodeRecommend = {} as { [key: string]: NodeRecommendItem };
      Object.keys(dataMap).map((nodeType: string) => {
        tempNodeRecommend[nodeType] = {
          imageVersion: initData.metadata.imageVersion,
          nodeType: nodeType,
          nodeSpecKey: 'unit',
          storageSize: createNodeMinStorage,
          storageType: 'ssd',
          nodeSpecMultiplier: 1,
        };
      });
      setNodeRecommend(tempNodeRecommend);
      setSelectedTabId(Object.keys(dataMap)[0]);
      setPreviewDataMap(dataMap);
      // setSections(argumentSections.sections);
      setSelectedTabId(Object.keys(dataMap)[0]);
      const activedInit = {} as { [key: string]: boolean };
      Object.keys(dataMap).forEach((key, index) => {
        activedInit[key] = index === 0 ? true : false;
      });
      setActived(activedInit);
    })();
  }, []);

  const additional = Object.keys(previewDataMap);
  const doSubmit = handleSubmit(async (values) => {
    let hasError = false as any;
    additional.forEach((key) => {
      Object.keys(previewDataMap[key].comArgRules).map((section: string) => {
        const errorCode = checkHasError(previewDataMap[key].comArgRules[section].data);
        if (errorCode) {
          hasError = errorCode;
        }
      });
      if (!hasError) {
        const errorCode = checkHasError(previewDataMap[key].envRules);
        if (errorCode) {
          hasError = errorCode;
        }
      }
    });
    setHasError(!!hasError);
    if (hasError) {
      if (ConfigErrorCode.EMPTY === hasError) WarningToast('Key can’t be empty');
      else if (ConfigErrorCode.SAME === hasError) WarningToast('You have same key in a group');
      return;
    }
    const operations = {} as any;
    additional.map((key) => {
      operations[key] = {
        arg: transformArg(previewDataMap[key].comArgRules, protocolRules[key].arg, true),
        var: transformVar(previewDataMap[key].comArgRules, previewDataMap[key].envRules, protocolRules[key].var),
        env: transformEnv(previewDataMap[key].envRules, protocolRules[key].env),
      };
    });

    initData.config = {
      operations: operations,
    };
    initData.metadata.nodeSpec = {
      key: nodeRecommend[additional[0]].nodeSpecKey,
      multiplier: nodeRecommend[additional[0]].nodeSpecMultiplier,
    };
    initData.metadata.storageSize = `${nodeRecommend[additional[0]].storageSize}Gi`;
    initData.nodeRecommends = Object.keys(nodeRecommend).map((nodeType: string) => nodeRecommend[nodeType]);
    onSubmit(initData);
  });

  const nextStep = (key: string) => {
    const index = additional.findIndex((i: string) => i === key);
    setSelectedTabId(key);
    const activedCopy = { ...actived } as any;
    activedCopy[key] = true;
    setActived(activedCopy);
    setStep(index + 1);
  };
  const tabArray = Object.keys(previewDataMap);

  return (
    <>
      <Row gutter>
        <Col unit={18}>
          <form className={styles.formContainer} onSubmit={doSubmit}>
            <h2 className={styles.formTitle}>{lang(t, 'stepTitle')}</h2>
            {/* <p>
              Need help? learn how to create customized Network Spec. <a>Documentation</a>
            </p> */}
            <Loader isLoading={isLoading}>
              <Tabs selectedTabId={selectedTabId} className={styles.tabs} onChange={nextStep}>
                {tabArray.map((nodeTypeKey: string) => {
                  return (
                    <Tab
                      key={nodeTypeKey}
                      id={nodeTypeKey}
                      title={`${nodeTypeKey} ${lang(t, `step2`)}`}
                      panel={
                        <>
                          <div className={styles.preview}>
                            <Preview data={{ ...previewDataMap[nodeTypeKey] }} />
                          </div>
                          <h3>{lang(t, `argumentRulesTitle`)}</h3>
                          <DetailBox className={cx(styles.section, styles.argumentRules)}>
                            {!!previewDataMap[nodeTypeKey].comArgRules &&
                              Object.keys(previewDataMap[nodeTypeKey].comArgRules).map(
                                (section: string, index: number) => {
                                  return (
                                    <div key={index}>
                                      <h5>{previewDataMap[nodeTypeKey].comArgRules[section].title}</h5>
                                      <hr className={styles.hr} />
                                      <UpdateNetworkRules
                                        hasError={hasError}
                                        section={section}
                                        initData={previewDataMap[nodeTypeKey].comArgRules[section].data}
                                        onChange={(data) => {
                                          const previewDataMapCopy = { ...previewDataMap };
                                          previewDataMap[nodeTypeKey].comArgRules[section].data = data;
                                          setPreviewDataMap(previewDataMapCopy);
                                        }}
                                      />
                                    </div>
                                  );
                                },
                              )}
                          </DetailBox>
                          <h3>{lang(t, `environmentRulesTitle`)}</h3>
                          <DetailBox className={styles.section}>
                            <UpdateNetworkRules
                              hasError={hasError}
                              initData={previewDataMap[nodeTypeKey].envRules}
                              onChange={(data) => {
                                const previewDataMapCopy = { ...previewDataMap };
                                previewDataMapCopy[nodeTypeKey].envRules = data;
                                setPreviewDataMap(previewDataMapCopy);
                              }}
                            />
                          </DetailBox>
                          <h3>{lang(t, `configuration`)}</h3>
                          <DetailBox className={styles.configuration}>
                            <p className={styles.configurationIntorduce}>{lang(t, `configurationIntorduce`)}</p>
                            <NodeSizeStorageSize
                              onSubmit={(multiplier, storageSize) => {
                                const nodeRecommendCopy = { ...nodeRecommend[nodeTypeKey] };
                                nodeRecommendCopy.nodeSpecMultiplier = multiplier;
                                nodeRecommendCopy.storageSize = storageSize;
                                setNodeRecommend({
                                  ...nodeRecommend,
                                  [nodeTypeKey]: nodeRecommendCopy,
                                });
                              }}
                              nodeSpecKey={nodeRecommend[nodeTypeKey].nodeSpecKey}
                              multiplier={nodeRecommend[nodeTypeKey].nodeSpecMultiplier}
                              storageSize={nodeRecommend[nodeTypeKey].storageSize}
                            />
                          </DetailBox>
                        </>
                      }
                    />
                  );
                })}
              </Tabs>
            </Loader>
            <Row>
              <Col unit={12}>
                <Button
                  large
                  onClick={() => {
                    if (step === 1) return onBack();
                    const nextStepIndex = additional.findIndex((i) => i === selectedTabId);
                    nextStep(additional[nextStepIndex - 1]);
                  }}
                  className={styles.bottomBtn}
                >
                  {lang(t, 'buttonBack')}
                </Button>
              </Col>
              <Col unit={12} style={{ textAlign: 'right' }}>
                {step < additional.length && (
                  <Button
                    className={styles.bottomBtn}
                    large
                    intent="primary"
                    onClick={() => {
                      const nextStepIndex = additional.findIndex((i) => i === selectedTabId);
                      nextStep(additional[nextStepIndex + 1]);
                    }}
                  >
                    {lang(t, 'buttonNext')}
                  </Button>
                )}
                {step >= additional.length && (
                  <SubmitButton
                    loading={isCreating}
                    disabled={isCreating}
                    type="submit"
                    className={styles.bottomBtn}
                    large
                    intent="primary"
                  >
                    {lang(t, 'buttonCreate')}
                  </SubmitButton>
                )}
              </Col>
            </Row>
          </form>
        </Col>
        <Col unit={6}>
          {!!additional.length && (
            <Steps
              step={step}
              actived={actived}
              onStepChange={(step: number) => {
                nextStep(Object.keys(previewDataMap)[step]);
              }}
              onGoback={onBack}
            />
          )}
        </Col>
      </Row>
    </>
  );
});

import React, { useState } from 'react';
import styles from './style.module.scss';
import { useStore } from '@/store';
import { ErrorToast, SuccessToast } from 'components';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { lang } from './helper';
import { Button, Modal, Form, Row, Col, Input, InputNumber } from 'antd';

const schema = yup.object().shape({
  planName: yup.string().required(),
  company: yup.string(),
  limit: yup.number(),
  message: yup.string(),
});

const schemaPlan = yup.object().shape({
  planName: yup.string().required(),
  company: yup.string(),
  networkName: yup.string(),
  message: yup.string(),
});

const errorMessage = (str: string | null | undefined) => {
  if (!str) return '';
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
};

interface IProps {
  planName: string;
  onClose: () => void;
  planChange?: boolean;
}

export const ContactUs: React.FC<IProps> = ({ onClose, planName, planChange }) => {
  const [progressing, setProgressing] = useState(false);
  const { workspaces, plan } = useStore();
  const { t } = useTranslation();
  const { handleSubmit, register, errors, control } = planChange
    ? useForm({ validationSchema: schemaPlan })
    : useForm({ validationSchema: schema });
  const onSubmit = async (values: any) => {
    setProgressing(true);
    await plan.sendEmailToChangePlan(workspaces.current!.id, values);
    setProgressing(false);
    if (plan.state === 'error') {
      if (plan.errorMessage?.includes('you do not allow to')) {
        ErrorToast('Contact your workspace owner to change plans.');
      } else ErrorToast(plan.errorMessage!);
    } else {
      SuccessToast(lang(t, 'plan.contactUsForm.success'));
      onClose();
    }
  };

  return (
    <Modal
      className={styles.contactUsForm}
      title={lang(t, 'plan.contactUsForm.title')}
      visible={true}
      footer={false}
      centered
      maskClosable={false}
      onCancel={onClose}
    >
      <Form
        name="basic"
        layout={'vertical'}
        className={styles.form}
        onFinish={() => {
          handleSubmit(onSubmit)();
        }}
      >
        <input ref={register} name="planName" type="hidden" value={planName} />
        <Form.Item label={lang(t, 'plan.contactUsForm.company')}>
          <Controller
            name="company"
            control={control}
            defaultValue={''}
            as={<Input type="text" ref={register} size={'large'} placeholder={lang(t, 'plan.contactUsForm.company')} />}
          />
        </Form.Item>
        <Form.Item
          label={planChange ? lang(t, 'plan.contactUsForm.networkName') : lang(t, 'plan.contactUsForm.limit')}
          help={errorMessage(errors?.limit?.message)}
          validateStatus={errors?.limit?.message ? 'error' : ''}
        >
          <Controller
            name={planChange ? 'networkName' : 'limit'}
            control={control}
            defaultValue={planChange ? '' : 0}
            as={
              planChange ? (
                <Input
                  style={{ width: '100%' }}
                  size={'large'}
                  ref={register}
                  placeholder={lang(t, 'plan.contactUsForm.networkName')}
                />
              ) : (
                <InputNumber
                  min={0}
                  style={{ width: '100%' }}
                  size={'large'}
                  ref={register}
                  placeholder={lang(t, 'plan.contactUsForm.limit')}
                />
              )
            }
          />
        </Form.Item>
        <Form.Item label={lang(t, 'plan.contactUsForm.message')}>
          <Controller
            name="message"
            control={control}
            defaultValue={''}
            as={
              <Input.TextArea
                ref={register}
                rows={3}
                placeholder={
                  planChange
                    ? lang(t, 'plan.contactUsForm.messagePlaceHolderPlan')
                    : lang(t, 'plan.contactUsForm.messagePlaceHolder')
                }
              />
            }
          />
        </Form.Item>
        <div className={styles.footer}>
          <Button htmlType="submit" size={'middle'} type={'primary'} loading={progressing}>
            {lang(t, 'plan.contactUsForm.submit')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

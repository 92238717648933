import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, Spin } from 'antd';
import { Instance } from 'mobx-state-tree';
import { ErrorToast, DetailBox, CreditCardNew } from 'components';
import { PaymentMethodDialog } from '@/modules/PaymentMethodDialog';
import { useStore } from '@/store';
import { lang } from './helper';
import { ReactComponent as CreditCardIcon } from './imgs/creditCard.svg';
import { ReactComponent as NoPaymentIcon } from './imgs/noPayment.svg';
import styles from './style.module.scss';
import { PaymentMethod } from '@/store/payment-methods';
import { Workspace } from '@/store/workspaces';

const isInUse = (
  paymentMethod: Instance<typeof PaymentMethod>,
  workspaces: Array<Instance<typeof Workspace>>,
): boolean => {
  return workspaces.findIndex((ws) => ws.paymentMethodId === paymentMethod.id) > -1;
};

export const Payment: React.FC = observer(() => {
  const { workspaces, paymentMethods } = useStore();
  const { t } = useTranslation();
  const [paymentMethodId, setPaymentMethodId] = useState(workspaces.current?.paymentMethodId ?? '');
  const [progressing, setProgressing] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    (async () => {
      if (workspaces.currentWorkspaceMember?.role !== 'owner') return null;
      setProgressing(true);
      await workspaces.fetchPayment(workspaces.current!.id);
      setProgressing(false);
    })();
    return () => {
      workspaces.clearPayment();
    };
  }, [workspaces.currentWorkspaceMember?.role]);

  if (workspaces.currentWorkspaceMember?.role !== 'owner') return null;

  return (
    <DetailBox
      className={styles.radiusBox}
      title={lang(t, 'payment.title')}
      action={
        <Button type={'link'} onClick={() => setShowDialog(true)}>
          <Space>
            <span className="anticon">
              <CreditCardIcon />
            </span>
            {lang(t, 'payment.add')}
          </Space>
        </Button>
      }
    >
      <div>
        <Spin spinning={progressing}>
          {workspaces.paymentList.length === 0 && (
            <div className={styles.noPayment}>
              <NoPaymentIcon />
              <p className={styles.noPaymentTitle}>No payment methods on workspace</p>
              <p className={styles.noPaymentSubTitle}>Payment methods you add will appear here.</p>
            </div>
          )}
          {workspaces.paymentList.length > 0 && (
            <div className={styles.cardList}>
              {workspaces.paymentList.map((p, idx) => (
                <CreditCardNew
                  isDefault={workspaces.current!.paymentMethodId === p.id}
                  key={p.id}
                  card={p.card}
                  isInUse={isInUse(p, workspaces.list)}
                  method={p}
                  showDefault
                />
              ))}
            </div>
          )}
        </Spin>
      </div>
      <PaymentMethodDialog
        isOpen={showDialog}
        postSubmit={async (data) => {
          if (paymentMethods.state === 'error') {
            ErrorToast(workspaces.errorMessage!);
            return;
          }
          if (!workspaces.current!.paymentMethodId) {
            await workspaces.updatePaymentMethod(workspaces.current!, { paymentMethodId: paymentMethods.newMethodId });
          }
          await workspaces.fetchPayment(workspaces.current!.id);
          setShowDialog(false);
        }}
        onClose={() => setShowDialog(false)}
      />
    </DetailBox>
  );
});

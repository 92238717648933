import React from 'react';
import { observer } from 'mobx-react';
import { Info } from './Info';
import { MemberSection } from './MemberSection';

export const SettingsTab: React.FC = observer(() => {
  return (
    <>
      <Info />
      <MemberSection />
    </>
  );
});

import React from 'react';
import styles from './style.module.scss';
import cx from 'classnames';
interface IParams {
  className?: string;
}

// interface IProps extends IParams {}
export const Font38: React.FC<IParams> = ({ children, className }) => {
  return <p className={cx(styles.font38, className)}>{children}</p>;
};

export const Font24: React.FC<IParams> = ({ children, className }) => {
  return <p className={cx(styles.font24, className)}>{children}</p>;
};

export const Font18: React.FC<IParams> = ({ children, className }) => {
  return <p className={cx(styles.font18, className)}>{children}</p>;
};

export const Font16: React.FC<IParams> = ({ children, className }) => {
  return <p className={cx(styles.font16, className)}>{children}</p>;
};

export const Font14: React.FC<IParams> = ({ children, className }) => {
  return <p className={cx(styles.font14, className)}>{children}</p>;
};

export const Font12: React.FC<IParams> = ({ children, className }) => {
  return <p className={cx(styles.font12, className)}>{children}</p>;
};

import React, { useState } from 'react';
import styles from './style.module.scss';
import { IconName, IconNames } from '@blueprintjs/icons';
import { DetailBox } from 'components';
import { INetworkSpec } from '@/store/networks';
import { useTranslation } from 'react-i18next';
import { NetworkIcon } from './NetworkIcon';
import { Tag, Tooltip } from 'antd';

export interface NetworkSpecAction {
  textKey: string;
  icon: IconName;
  handler: (networkSpec: INetworkSpec) => void;
}

interface IProps {
  network: INetworkSpec;
  disable?: boolean;
  onClick: () => void;
  actions: NetworkSpecAction[];
}

export const statusMap = {
  pending: 'processing',
  bootstrapRequired: 'warning',
  generateChainSpecFailure: 'error',
  enabled: 'success',
  disabled: 'error',
  deleted: 'error',
  hidden: 'warning',
};

export const NetworkCard: React.FC<IProps> = ({ network, disable, actions = [], onClick }) => {
  const { t } = useTranslation();
  // network.displayName ?? network.name
  return (
    <div className={styles.cardStyle}>
      <DetailBox hover data-disable={disable && 'disable'} onClick={onClick}>
        <div className={styles.imgbox}>
          <NetworkIcon name={network.displayName ?? network.name} icon={network?.metadata?.pictures?.small} />
        </div>
        <div className={styles.messageBox}>
          {/* <Popover
          className={styles.popover}
          minimal
          position={PopoverPosition.BOTTOM_RIGHT}
          popoverClassName="network-actions"
        >
          <Button icon={IconNames.MORE} minimal />
          <Menu>
            {actions.map((action) => (
              <MenuItem
                key={action.textKey}
                icon={action.icon}
                text={action.textKey}
                onClick={() => action.handler(network)}
              />
            ))}
          </Menu>
        </Popover> */}
          <Tooltip placement="topLeft" title={network.displayName ?? network.name}>
            <h5>{network.displayName ?? network.name}</h5>
          </Tooltip>
          <div className={styles.popover}>
            <Tag className={styles.state} color={statusMap[network.status]}>
              {t(`components.networkStatus.${network.status}`)}
            </Tag>
          </div>
        </div>
      </DetailBox>
    </div>
  );
};

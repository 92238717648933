import { FocusStyleManager } from '@blueprintjs/core';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import React from 'react';
import { render } from 'react-dom';
import { history, StoreProvider } from './store';
import { ConfigProvider } from 'antd';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { routes } from './routes';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import i18nen from './components/Language/locales/en.json';
import i18nzh from './components/Language/locales/zh.json';
import { loadMxConfig, mxConfig } from './config';
import { Layout } from './Layout';

FocusStyleManager.onlyShowFocusOnTabs();

(async () => {
  await loadMxConfig();
  let stripePromise;
  if (mxConfig.stripePublishableKey) {
    stripePromise = loadStripe(mxConfig.stripePublishableKey);
  }

  // Make sure we load 3rd party after loading the env config
  // So they can get the config values as well
  import('./third-party');

  await i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      react: {
        useSuspense: false,
      },
      fallbackLng: {
        en: ['en'],
        'zh-CN': ['zh'],
        zh: ['zh'],
        default: ['en'],
      },
      interpolation: { escapeValue: false },
      resources: {
        en: { translation: i18nen },
        zh: { translation: i18nzh },
      },
    });
  render(
    <StoreProvider>
      <ConfigProvider prefixCls="op">
        <Layout />
        {stripePromise ? (
          <Elements stripe={stripePromise}>
            <Router history={history}>{renderRoutes(routes)}</Router>
          </Elements>
        ) : (
          <Router history={history}>{renderRoutes(routes)}</Router>
        )}
      </ConfigProvider>
    </StoreProvider>,
    document.getElementById('root'),
  );
})();

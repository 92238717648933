import React from 'react';
import { Position, Toaster } from '@blueprintjs/core';
import { Intent } from '@blueprintjs/core/lib/esm/common/intent';
import sanitizeHtml from 'sanitize-html';

export const Toast = Toaster.create({
  position: Position.TOP,
});

export function SuccessToast(message = 'Success', timeout = 2000) {
  Toast.show({
    icon: 'tick-circle',
    intent: Intent.SUCCESS,
    message: <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }}></div>,
    timeout,
  });
}

export function PrimaryToast(message: string, timeout = 2000) {
  Toast.show({
    icon: 'error',
    intent: Intent.PRIMARY,
    message: <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }}></div>,
    timeout,
  });
}

export function WarningToast(message: string, timeout = 5000) {
  Toast.show({
    icon: 'error',
    intent: Intent.WARNING,
    message: <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }}></div>,
    timeout,
  });
}

export function ErrorToast(message = 'Error', timeout = 5000) {
  Toast.show({
    icon: 'error',
    intent: Intent.DANGER,
    message: <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }}></div>,
    timeout,
  });
}

export const defaultPage = 'dashboard';

export const createNodeMinStorage = 10;
export const createNodeMaxStorage = 3000;
export const nodeUnitSizeConfig = 20;
export const apiServiceLimit = 500000;

export const plans = [
  { name: 'developer', permission: ['canBeChooseWhenCreateWorkspace', 'publishNetwork'] },
  { name: 'startup', permission: ['publishNetwork'] },
  { name: 'growth', apiServiceLink: 'usageCenter', permission: [] },
  { name: 'team', permission: ['publishNetwork', 'viewReport', 'showBackupAndAPIDataEgress'] },
  { name: 'ultimate', apiServiceLink: 'usageCenter', permission: ['publishNetwork'] },
  { name: 'network_developer', permission: ['publishNetwork', 'viewReport', 'showBackupAndAPIDataEgress'] },
  { name: 'managed_partner', permission: [] },
  {
    name: 'partner',
    apiServiceLink: 'usageCenter',
    permission: ['publishNetwork', 'viewReport', 'showBackupAndAPIDataEgress', 'publishNetwork'],
  },
];
export const planNames = plans.map((i) => i.name);

export const isPlanHasPermission = (planName: string | undefined, permission: string) => {
  if (!planName) return false;
  return !!plans.find((i) => i.name === planName && i.permission.includes(permission));
};

export const nodeNameMaxLength = 32;

export const pruningModeNodeTypeMap: { [key: string]: string[] } = {
  fast: ['full'],
  none: ['full'],
  archive: ['collator', 'archive', 'validator', 'trace'],
};

export const imageVersionTags = ['stable', 'pre-release'];

export const nodeSpecsMultiplier = {
  small: 1,
  medium: 2,
  large: 3,
} as { [key: string]: number };

// if want block gcp for collator, just set `'collator': ['gcp']`
export const nodeTypeBlockMap = {
  full: [],
  collator: [],
  validator: [],
  archive: [],
} as { [key: string]: string[] };

// if want block Singapore for collator, just set `'collator': ['Singapore']`
export const regionBlockMap = {
  full: [],
  collator: ['Singapore'],
  validator: [],
  archive: [],
} as { [key: string]: string[] };

export const defaultLeftMenu = ({ plan }: any) => {
  return [
    {
      id: 'dashboard',
    },
    {
      id: 'apiServices',
    },
    {
      id: 'nodes',
    },
    {
      id: 'networks',
      childNodes: isPlanHasPermission(plan, 'viewReport')
        ? [
            {
              id: 'networkSpec',
            },
            {
              id: 'report',
            },
          ]
        : [],
    },
    {
      id: 'workspaces',
      childNodes: [
        {
          id: 'settings',
        },
        {
          id: 'usageCenter',
        },
        {
          id: 'planPayment',
        },
      ],
    },
    {
      id: 'divider',
    },
    {
      id: 'documentation',
    },
    {
      id: 'networkStatus',
    },
  ];
};

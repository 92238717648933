import React from 'react';
import { Icon } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as BackIcon } from './img/back.svg';
import styles from './style.module.scss';

interface IParams {
  link: string;
  text?: string;
}

export const Back: React.FC<IParams> = observer(({ link, text }) => {
  const { t } = useTranslation();
  return (
    <Link className={styles.backBtn} to={link}>
      <BackIcon />
      <span>{text || t('pages.nodeDetail.goBack')}</span>
    </Link>
  );
});

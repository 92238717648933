import React from 'react';
import { observer } from 'mobx-react';
import { IApp } from '@/store/apiService';
import { Row, Col, Space, Tag } from 'antd';
import { ReactComponent as AppIcon } from '../imgs/appIcon.svg';
import { ReactComponent as RightArrow } from '../imgs/right.svg';
import dayjs from 'dayjs';
import styles from '../style.module.scss';
import { lang } from '../helpers';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Wrench } from '../imgs/wrench.svg';
import { ReactComponent as Terminal } from '../imgs/terminal.svg';
import { ReactComponent as Srock } from '../imgs/srock.svg';

interface IProps {
  i: IApp;
  planIsMonthPayment: boolean;
  disable?: boolean;
}

export const AppList: React.FC<IProps> = observer(({ i, planIsMonthPayment, disable = false }) => {
  const { t } = useTranslation();
  const IconMap = {
    development: <Wrench className={cx(disable ? styles.disableIcon : styles.activeIcon)} />,
    staging: <Terminal className={cx(disable ? styles.disableIcon : styles.activeIcon)} />,
    production: <Srock className={cx(disable ? styles.disableIcon : styles.activeIcon)} />,
  };

  return (
    <Row>
      <Col span={6} className={styles.colAll}>
        <AppIcon className={cx(styles.marginR8, disable ? styles.disableIcon : styles.activeIcon)} />
        {i.displayName}
      </Col>
      <Col span={5} className={cx(styles.colAll, styles.environment)}>
        {IconMap[i.labels.environment]} {i.labels.environment}
      </Col>
      {!planIsMonthPayment && (
        <Col span={4} className={styles.colAll}>
          <Space>{i.usage ? i.usage.toLocaleString() : 0}</Space>
        </Col>
      )}
      <Col span={4} className={styles.colAll} style={{ width: 200 }}>
        <Space>{i.usageIn24H ? i.usageIn24H.toLocaleString() : 0}</Space>
      </Col>
      {planIsMonthPayment && <Col span={4}></Col>}
      <Col span={4} className={styles.colAll} style={{ width: 200 }}>
        {i.active ? (
          <Tag color="success">{lang(t, 'appList.active')}</Tag>
        ) : (
          <Tag color="error">{lang(t, 'appList.deactive')}</Tag>
        )}
        <span className={styles.appDate}>{dayjs(i.createdAt).format('YYYY-MM-DD')}</span>
      </Col>
      <Col span={1} className={styles.colAll}>
        <div className={cx(styles.colOne)}>
          <RightArrow className={cx(disable ? styles.disableIcon : styles.activeIcon)} />
        </div>
      </Col>
    </Row>
  );
});

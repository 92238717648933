export const title = (pathname: string, wsId?: string) => {
  const pathArray = pathname.split('/');
  if (pathArray[3] === 'dashboard') {
    return 'Dashboard';
  } else if (pathArray[3] === 'apiservices') {
    if (pathArray[5] === 'settings') {
      return 'API Apps Settings';
    } else if (pathArray[4]) {
      if (pathArray[4] === 'all-networks') {
        return 'API Marketplace';
      }
      return 'API Apps Detail';
    } else {
      return 'API Apps';
    }
  } else if (pathArray[3] === 'nodes') {
    if (pathArray[4]) {
      return 'Dedicated Nodes Detail';
    } else {
      return 'Dedicated Nodes';
    }
  } else if (pathArray[3] === 'networks') {
    return 'Network Specs';
  } else if (pathArray[3] === 'report') {
    return 'Network Report';
  } else if (pathArray[3] === 'usageCenter') {
    return 'Usage Center';
  } else if (pathArray[3] === 'settings') {
    return 'Workspace Settings';
  } else if (pathArray[3] === 'accountPage') {
    return 'AccountPage';
  } else {
    return null;
  }
};

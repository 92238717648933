import React from 'react';
import { observer } from 'mobx-react';
import { Button, Dialog, Classes } from '@blueprintjs/core';
import { Loader } from 'components';
import styles from './style.module.scss';
import { ReactComponent as ErrorIcon } from './img/error.svg';
import { ReactComponent as SuccessIcon } from './img/success.svg';

interface IProps {
  status: string;
  returnUrl?: string;
  loadingMainText?: string;
  successMainText?: string;
  successSubText?: string;
  successButton: string;
  errorMainText?: string;
  errorSubText?: string;
  errorButton: string;
  errorClick: () => void;
  successClick: () => void;
}

export const SubmitResult: React.FC<IProps> = observer(
  ({
    status,
    errorClick,
    successClick,
    loadingMainText,
    successMainText,
    successSubText,
    successButton,
    errorMainText,
    errorSubText,
    errorButton,
  }) => {
    return (
      <Dialog isOpen={true} className={styles.resultDialog}>
        <div className={Classes.DIALOG_BODY}>
          {status === 'pending' && (
            <div>
              <div className={styles.creating}>
                <Loader isLoading padding={false} />
                <p className={styles.mainText}>{loadingMainText}</p>
              </div>
            </div>
          )}
          {status === 'done' && (
            <div>
              <div>
                <SuccessIcon className={styles.successImg} />
                <p className={styles.mainText}>{successMainText}</p>
                {successSubText && <p className={styles.subText}>{successSubText}</p>}
                <Button
                  intent="primary"
                  onClick={() => {
                    successClick();
                  }}
                  className={styles.bottomBtn}
                >
                  {successButton}
                </Button>
              </div>
            </div>
          )}
          {status === 'error' && (
            <div>
              <div>
                <ErrorIcon className={styles.errorImg} />
                <p className={styles.mainText}>{errorMainText}</p>
                {errorSubText && <p className={styles.subText}>{errorSubText}</p>}
                <Button large intent="primary" onClick={errorClick} className={styles.bottomBtn}>
                  {errorButton}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    );
  },
);

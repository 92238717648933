import React from 'react';
import cx from 'classnames';
import styles from './style.module.scss';

interface IProps {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ButtonLink: React.FC<IProps> = (props) => {
  const { onClick, className, children } = props;
  return (
    <button className={cx(styles.btn, className)} onClick={onClick}>
      {children}
    </button>
  );
};

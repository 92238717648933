import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@/store';
import styles from './style.module.scss';
import { ReactComponent as NoResult2 } from './imgs/noResult2.svg';

export const MapColumn: React.FC = observer(() => {
  const { networkSpec } = useStore();

  const countryDataArr = () => {
    let resArr;
    if (Object.keys(networkSpec!.requestByCountry!.resultData).length > 6) {
      resArr = Object.keys(networkSpec!.requestByCountry!.resultData).slice(0, 5);
      resArr.push('Other');
    } else {
      resArr = Object.keys(networkSpec!.requestByCountry!.resultData);
    }
    return resArr;
  };

  const getLastElement = () => {
    let total = 0;
    Object.values(networkSpec!.requestByCountry!.resultData)
      .slice(5)
      .forEach((e) => {
        total += Number(e);
      });
    return total;
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.countryBox}>
        <div className={styles.dataBox}>
          <span className={styles.headerBox}>Top Countries</span>
          <span className={styles.headerBox}>Requests</span>
        </div>
        <div className={styles.bottomBorder} />
      </div>
      {networkSpec.requestByCountry && networkSpec.requestByCountry.resultData && countryDataArr().length > 0 ? (
        countryDataArr().map((e) => {
          return (
            <div className={styles.countryBox}>
              <div className={styles.dataBox}>
                <span>{e === '' || e === null ? <span className={styles.italic}>Unknown</span> : e}</span>
                <span>
                  {e !== 'Other'
                    ? networkSpec!.requestByCountry!.resultData[e].toLocaleString()
                    : getLastElement().toLocaleString()}
                </span>
              </div>
              {e !== 'Other' && (
                <>
                  <div
                    className={styles.percentageBorder}
                    style={{
                      width: `${
                        (
                          ((networkSpec.requestByCountry!.resultData as any)[e] /
                            networkSpec.requestByCountry!.totalRequest) *
                          100
                        ).toFixed(2) + '%'
                      }`,
                    }}
                  />
                  <div className={styles.bottomBorder} />
                </>
              )}
            </div>
          );
        })
      ) : (
        <div className={styles.noDataDiv2}>
          <NoResult2 className={styles.noResult} />
        </div>
      )}
    </div>
  );
});

import React from 'react';
import { observer } from 'mobx-react';
import { IProps as ItemProps } from './item';
import { LaunchConfigurationValueType } from './help';
import styles from './style.module.scss';

interface Props {
  args: { [key: string]: string[] };
}

export const ItemRawArg: React.FC<ItemProps> = observer(({ data: { arg } }) => {
  const values = arg.split('=');
  const key = values[0];
  let value = undefined;
  let valueType = LaunchConfigurationValueType.Empty;
  if (values.length >= 2) {
    value = values[1];
    valueType = LaunchConfigurationValueType.String;
  }
  return (
    <>
      {valueType === LaunchConfigurationValueType.Empty && <p>{key}</p>}
      {valueType === LaunchConfigurationValueType.String && (
        <p>
          {key}={value}
        </p>
      )}
    </>
  );
});

const renderArgs = (args: string[]) =>
  args.map((arg: string) => (
    <ItemRawArg
      key={arg}
      data={{
        arg,
      }}
    />
  ));

export const OperationalArgs: React.FC<Props> = observer(({ args }) => {
  return (
    <>
      {Object.keys(args).map((k) => (
        <>
          <p className={styles.operationalArgSection}>{k}</p>
          {renderArgs(args[k])}
        </>
      ))}
    </>
  );
});

import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import _ from 'lodash';
import { InputGroup, Icon, Button, Popover, Position } from '@blueprintjs/core';
import cx from 'classnames';

interface IProps {
  large?: boolean;
  defaultValue?: string;
  state: string;
  items: Array<string>;
  onChange: (value: string) => void;
}

const filterFilm = (query: string, film: string) => {
  return `${film.toLowerCase()} ${film}`.indexOf(query.toLowerCase()) >= 0;
};

const waitTime = 300;

export const SearchSelect: React.FC<IProps> = ({ large = true, state, items, onChange, defaultValue }) => {
  const [currentList, setCurrentList] = useState<Array<string>>([]);
  const [currentValue, setCurrentValue] = useState<string | undefined>(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const filter = (query: string | null) => {
    if (!query) return setCurrentList(items);
    const result = items.filter((i) => filterFilm(query, i));
    setCurrentList(result);
  };

  useEffect(() => {
    !defaultValue && setCurrentValue(items[0]);
    setCurrentList([...items].splice(0, 100));
    setTimeout(() => {
      setCurrentList(items);
    });
  }, [items]);

  const fn = _.debounce((e: any) => {
    const value = e.target.value;
    filter(value);
  }, waitTime);

  useEffect(() => {
    if (!isOpen) setCurrentList(items);
  }, [isOpen]);

  return (
    <div className={cx({ [styles.container]: true, [styles.large]: large })}>
      {state === 'pending' && (
        <span className={styles.status}>
          <Icon icon="refresh" iconSize={16} />
        </span>
      )}
      <Popover
        isOpen={isOpen}
        onInteraction={(state) => {
          setIsOpen(state);
        }}
        content={
          items.length ? (
            <div className={styles.popup}>
              <InputGroup
                large={large}
                className={styles.searchBar}
                leftIcon="search"
                onChange={(e: any) => {
                  e.persist();
                  fn(e);
                }}
              />
              <ul>
                {currentList.map((i) => (
                  <li
                    key={i}
                    onClick={() => {
                      setCurrentValue(i);
                      onChange(i);
                      setIsOpen(false);
                    }}
                  >
                    {i}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            ''
          )
        }
        position={Position.BOTTOM}
      >
        <Button disabled={!currentValue} large={large}>
          {currentValue ?? 'version'}
          <Icon
            className={styles.icon}
            icon={'double-caret-vertical'}
            onClick={() => {
              setIsOpen(true);
            }}
            iconSize={16}
          />
        </Button>
      </Popover>
    </div>
  );
};

import React from 'react';
import styles from './style.module.scss';
import cx from 'classnames';
import { Row, Col } from 'antd';

interface IProps {
  className?: string;
  hover?: boolean;
  title?: string;
  action?: any;
  highlight?: boolean;
  onClick?: () => void;
}

export const DetailBox: React.FC<IProps> = ({
  children,
  title,
  action,
  className,
  hover = false,
  highlight = false,
  ...other
}) => {
  return (
    <div
      className={cx({
        [styles.detailBox]: true,
        [className || '']: !!className,
        [styles.hover]: !!hover,
        [styles.highlight]: !!highlight,
      })}
      {...other}
    >
      {(!!title || !!action) && (
        <Row gutter={24}>
          <Col span={12}>{!!title && <h3>{title}</h3>}</Col>
          <Col span={12} className={styles.action}>
            {!!action && action}
          </Col>
        </Row>
      )}
      {children}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Instance } from 'mobx-state-tree';
import { Invoice } from '../../store/account';
import { Table, Tag } from 'antd';
import { ErrorToast, SuccessToast, DetailBox, ButtonLink, ComponentHeader, Spin } from 'components';
import { DownloadOutlined } from '@ant-design/icons';
import { ReactComponent as Svg } from './img/img.svg';

const lang = (t: TFunction, key: string) => t(`modules.accountBillingHistory.${key}`);

interface InvoiceAmountProps {
  invoice: Instance<typeof Invoice>;
}

const InvoiceAmount: React.FC<InvoiceAmountProps> = ({ invoice: { discount, amount } }) => {
  const { t } = useTranslation();
  if (discount) {
    return (
      <>
        ${amount}{' '}
        <span className={styles.amountDeducted}>
          {discount.coupon.amountOff && `($${discount.coupon.amountOff} ${lang(t, 'discount_deducted')})`}
          {discount.coupon.percentOff && `(${discount.coupon.percentOff}% ${lang(t, 'discount_deducted')})`}
        </span>
      </>
    );
  } else {
    return <>${amount}</>;
  }
};

export const AccountBillingHistoryNew: React.FC = observer(() => {
  const { account, workspaces } = useStore();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (workspaces.state !== 'progressing') await account.getInvoices();
      setIsLoading(false);
    })();
  }, []);

  const columns = [
    {
      title: lang(t, 'tableTh.date'),
      key: 'fff',
      width: 221,
      render: (invoice: any) => {
        return <>{dayjs(invoice.periodEnd).format('DD/MM/YYYY')}</>;
      },
    },
    {
      title: lang(t, 'tableTh.workspace'),
      key: 'fff',
      width: 275,
      render: (v: any) => {
        return <>{workspaces.get(v.workspaceId)?.name}</>;
      },
    },
    {
      title: lang(t, 'tableTh.status'),
      key: 'fff',
      render: (v: any) => {
        if (v.status === 'upcoming') {
          return <Tag color={'processing'}>{lang(t, v.status)}</Tag>;
        } else if (v.status === 'paid') {
          return <Tag color={'success'}>{lang(t, v.status)}</Tag>;
        }
      },
    },
    {
      title: lang(t, 'tableTh.amount'),
      key: 'fff',
      render: (v: any) => {
        return (
          <div className={styles.displayFlex}>
            <InvoiceAmount invoice={v} />

            {v.pdfUrl && v.id && (
              <ButtonLink
                onClick={async () => {
                  const pdf_link = await account.renewInvoice(v.id);
                  window.open(pdf_link);
                }}
              >
                <DownloadOutlined style={{ fontSize: '18' }} />
              </ButtonLink>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <DetailBox className={styles.container}>
      <Spin isLoading={isLoading}>
        {account.invoices.length !== 0 && (
          <ComponentHeader>
            <h3>{lang(t, 'title')}</h3>
          </ComponentHeader>
        )}
        {account.invoices.length ? (
          <Table pagination={{ pageSize: 8 }} dataSource={[...account.invoices]} columns={columns} />
        ) : (
          <div className={styles.svgDiv}>
            <Svg className={styles.svg} />
          </div>
        )}
      </Spin>
    </DetailBox>
  );
});

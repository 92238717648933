import React, { useState, useRef, useEffect } from 'react';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { ErrorToast, SuccessToast } from 'components';
import { ButtonSize } from 'node_modules/antd/lib/button';
import { Modal, Form, Input, Select, Button, Space, Popover } from 'antd';
import { useStore } from '../../store';
import * as yup from 'yup';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as Wrench } from './imgs/wrench.svg';
import { ReactComponent as Terminal } from './imgs/terminal.svg';
import { ReactComponent as Srock } from './imgs/srock.svg';
import styles from './welcome.module.scss';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

interface IProps {
  wsId: string;
  text: string;
  size?: ButtonSize;
  network?: string;
  setIsOpenModal: any;
  processing: boolean;
  setProcessing: any;
  description?: boolean;
}

export const EnvironmentType = [
  {
    label: 'Development',
    value: 'development',
    icon: <Wrench className={styles.createAppIcon} />,
  },
  {
    label: 'Staging',
    value: 'staging',
    icon: <Terminal className={styles.createAppIcon} />,
  },
  {
    label: 'Production',
    value: 'production',
    icon: <Srock className={styles.createAppIcon} />,
  },
];

export const EnvironmentMap = {
  development: {
    label: 'Development',
    value: 'development',
    icon: <Wrench className={styles.createAppIconFillBlue} />,
  },
  staging: {
    label: 'Staging',
    value: 'staging',
    icon: <Terminal className={styles.createAppIconFillBlue} />,
  },
  production: {
    label: 'Production',
    value: 'production',
    icon: <Srock className={styles.createAppIconFillBlue} />,
  },
};

const schema = yup.object().shape({
  displayName: yup.string().required(),
  environment: yup.string().required(),
});

export const CreateAppModal: React.FC<IProps> = observer(
  ({ wsId, text, size = 'large', network, setIsOpenModal, setProcessing, processing, description }) => {
    const { apiService } = useStore();
    const [displayName, setDisplayName] = useState('');
    const displayInput = useRef(null);
    const { t } = useTranslation();
    const history = useHistory();
    const { handleSubmit, register, errors, control, setValue } = useForm({
      validationSchema: schema,
      defaultValues: {
        displayName: '',
        environment: EnvironmentType[0].value,
      },
    });
    const lang = (t: TFunction, key: string, opt?: any) => t(`pages.apiServices.${key}`, opt);

    useEffect(() => {
      if (displayInput.current !== null) {
        //@ts-ignore
        displayInput.current.focus({
          cursor: 'start',
        });
      }
    }, [displayInput]);
    const creatKey = async (displayName: string, environment: string) => {
      setProcessing(true);
      const appId = await apiService.createApp(wsId, displayName, environment);
      if (apiService.state === 'done') {
        if (network) {
          localStorage.setItem('selectedNetwork' + appId ?? '', network);
          history.push(`/workspaces/${wsId}/apiservices/${appId}`);
        } else if (description) {
          history.push(`/workspaces/${wsId}/apiservices`);
        }
        SuccessToast(lang(t, 'welcome.createAppSuccessfully', { appName: displayName }));
        setIsOpenModal(false);
        history.push(`/workspaces/${wsId}/apiservices/${appId}`);
        await apiService.getApps(wsId);
      } else if (apiService.state === 'error') {
        ErrorToast(apiService.errorMessage || t('Error'));
      }
      setProcessing(false);
    };
    const onSubmit = (data: any) => {
      creatKey(data.displayName, data.environment);
    };
    setValue('displayName', displayName);
    return (
      <>
        <Modal
          title={lang(t, 'welcome.createApp')}
          visible={true}
          footer={false}
          width={572}
          centered
          onCancel={() => {
            setIsOpenModal(false);
          }}
        >
          {description && (
            <div className={styles.descriptionText}>
              An API App is a container for your API keys, dashboards, and analytics all organised in one place within
              your OnFinality workspace. Customers commonly create a new API App for each application and for each
              development and production environment.
            </div>
          )}
          <Form
            layout="vertical"
            onFinish={() => {
              handleSubmit(onSubmit)();
            }}
          >
            <input type={'hidden'} name="displayName" ref={register} />
            <Form.Item
              label={lang(t, 'welcome.displayName')}
              help={errors?.displayName?.message}
              validateStatus={errors?.displayName?.message ? 'error' : ''}
            >
              <Input
                type="text"
                ref={displayInput}
                size={'large'}
                placeholder={lang(t, 'welcome.displayName')}
                onChange={(e) => {
                  setDisplayName(e.target.value.trim().replace(/[^\a-\z\A-\Z0-9\_\-\s]/g, ''));
                }}
              />
            </Form.Item>

            <Form.Item
              label={
                <Space>
                  {lang(t, 'welcome.environment')}
                  <Popover
                    content={
                      <div className={styles.createAppInformation}>{lang(t, 'welcome.createAppInformation')}</div>
                    }
                  >
                    <InfoCircleOutlined style={{ color: '#8c8c8c' }} className={styles.infoCircleOutlined} />
                  </Popover>
                </Space>
              }
              help={errors?.environment?.message}
              validateStatus={errors?.environment?.message ? 'error' : ''}
            >
              <Controller
                name="environment"
                control={control}
                ref={register}
                as={
                  <Select placeholder="Enviroment" size={'large'} onChange={() => {}} allowClear>
                    {EnvironmentType.map((i) => (
                      <Option key={i.value} value={i.value} className={styles.option}>
                        <Space>
                          {i.icon}
                          {i.label}
                        </Space>
                      </Option>
                    ))}
                  </Select>
                }
              />
            </Form.Item>
            <div className={styles.textRight}>
              <Button disabled={processing} loading={processing} size={'large'} type="primary" htmlType="submit">
                {lang(t, 'welcome.createApp')}
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  },
);

import React from 'react';
import { ReactComponent as Earth } from './earth.svg';
import { ReactComponent as Group } from './group.svg';

export interface MxIconProps {
  iconSize: number;
}

export const EarthIcon = ({ iconSize }: MxIconProps) => (
  <Earth className="mx-icon" width={iconSize} height={iconSize} />
);
export const GroupIcon = ({ iconSize }: MxIconProps) => (
  <Group className="mx-icon" width={iconSize} height={iconSize} />
);

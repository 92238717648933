import styles from './style.module.scss';
import React from 'react';
import { Button } from 'antd';
import { ExclamationCircleOutlined as WarnIcon } from '@ant-design/icons';
import { Classes, Dialog } from '@blueprintjs/core';
import sanitizeHtml from 'sanitize-html';

export interface ConfirmDialogProps {
  title: string;
  content: string;
  confirm: string;
  onConfirm: () => void;
  cancel: string;
  onCancel: () => void;
  isDanger?: boolean;
  isOpen?: boolean;
  isBusy?: boolean;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  cancel,
  confirm,
  content,
  onCancel,
  onConfirm,
  isDanger,
  isOpen,
  isBusy,
}) => {
  return (
    <Dialog isOpen={isOpen} onClose={onCancel} className={styles.confirmDialog}>
      <div className={Classes.DIALOG_BODY}>
        <div className="icon">
          <WarnIcon className="warn-icon" />
        </div>
        <div className="content">
          <h3>{title}</h3>
          <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} />
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onCancel}>{cancel}</Button>
          <Button type="primary" autoFocus danger={isDanger} loading={isBusy} disabled={isBusy} onClick={onConfirm}>
            {confirm}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

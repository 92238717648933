import { useEffect, useMemo, useState } from 'react';
import { Instance } from 'mobx-state-tree';
import { Iservice } from '@/store/apiService';
import { NetworkType } from '@/store/networkSpec';
import { NetworkListItem } from './';
import orderBy from 'lodash/orderBy';

export interface NetworkSelectionHook {
  pageSize: number;
  networkList: NetworkListItem[];
  selectedNetwork?: string;
  setSelectedNetwork: (network?: string) => void;
  activeNetworks: NetworkListItem[];
  setPersistedNetwork: (network?: string) => void;
}

interface Network extends Instance<typeof Iservice> {}

const getPageItems = (networkList: NetworkListItem[], pageSize: number, pageIndex: number) => {
  // Get items for the given page
  const slice = networkList.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);

  const count = slice.length - 1;
  if (slice.length < pageSize) {
    // Fill missing with empty slice so we still can size the items on the UI evenly
    for (let i = slice.length - 1; i < pageSize - 1; i++) {
      if (i === count) {
        slice.push({
          network: i.toString(),
          isEmpty: true,
          button: true,
        });
      } else {
        slice.push({
          network: i.toString(),
          isEmpty: true,
        });
      }
    }
  }

  return slice;
};

const getPages = (networkList: NetworkListItem[], pageSize: number, showExpanded = true) => {
  let count = (Object.keys(networkList).length - (showExpanded ? 0 : 1)) / pageSize;
  // Partial last page
  count = Math.floor(count) + 1;
  return [...Array(count).keys()];
};

const useNetworkSelection = (
  networks: Network[],
  appId?: string,
  onSelected?: (network: string) => void,
  networkPerPage?: number,
  searchName?: string,
  labelLength?: number,
): NetworkSelectionHook => {
  const [selectedNetwork, setSelectedNetwork] = useState<string>();
  const [persistedNetwork, setPersistedNetwork] = useState<string>();

  const pageSize = useMemo(() => networkPerPage || 7, [networkPerPage]);
  // Roughly for 7 items per row
  // If the number of items per row change
  // or the container width change then we will need to update this value
  const labelCharsCount = labelLength || 20;

  // This is the list of all available networks
  const networkList = orderBy(
    (searchName === ''
      ? networks
      : networks.filter((x) => {
          //@ts-ignore
          return x.displayName.toLowerCase().includes(searchName?.toLowerCase());
        })
    ).map((x) => {
      const { subDomain: network, count, displayName } = x;
      const fullName = displayName?.trim();
      const needTruncated = fullName.length > labelCharsCount;
      return {
        network,
        count: count || 0,
        isSelected: network === selectedNetwork,
        isPersisted: network === persistedNetwork,
        fullName,
        displayName: needTruncated ? `${fullName.substring(0, labelCharsCount)}...` : fullName,
      } as NetworkListItem;
    }),
    [(x) => x.count, (x) => x.displayName?.toLowerCase()],
    ['desc', 'asc'],
  );

  const activeNetworks = useMemo(
    () =>
      orderBy(
        networkList.filter((x) => (x.count || 0) > 0 || x.network === persistedNetwork),
        [(x) => x.isPersisted],
        ['desc'],
      ),
    // {
    //   let activeList: NetworkListItem[] = networkList.filter((x) => (x.count || 0) > 0);
    //   const persisted = networkList.find((x) => x.network === persistedNetwork && x.count === 0);
    //   persisted && activeList.unshift(persisted);
    //   return activeList;
    // }
    [networkList, persistedNetwork],
  );

  // Watch and set default selection
  useEffect(() => {
    // Set default selected network
    const localNetwork = localStorage.getItem('selectedNetwork' + appId ?? '');
    if (
      !selectedNetwork &&
      (!localNetwork || !networkList.find((i) => i.network === localNetwork)) &&
      networkList.length
    ) {
      const defaultNetwork = networkList[0].network;
      setSelectedNetwork(defaultNetwork);
      setPersistedNetwork(defaultNetwork);
      if (onSelected) {
        onSelected(defaultNetwork);
      }
    } else if (localNetwork && !selectedNetwork) {
      setSelectedNetwork(localNetwork);
      setPersistedNetwork(localNetwork);
      if (onSelected) {
        onSelected(localNetwork);
      }
    }
  }, [networkList, selectedNetwork]);

  return {
    pageSize,
    selectedNetwork,
    setSelectedNetwork,
    networkList,
    activeNetworks,
    setPersistedNetwork,
  };
};

export { useNetworkSelection, getPageItems, getPages };

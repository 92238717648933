import { Icon, Tab, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EarthIcon, GroupIcon } from '../../components/Icons';
import { useStore } from '../../store';
import styles from './style.module.scss';
import { mxConfig } from '@/config';

interface UrlMap {
  [type: string]: string;
}

interface IProps {
  workspaceId: string;
}

function getUrls(wsId: string): UrlMap {
  return {
    apiServices: `/workspaces/${wsId}/apiservices`,
    nodes: `/workspaces/${wsId}/nodes`,
    networks: `/workspaces/${wsId}/networks`,
    backups: `/workspaces/${wsId}/backups`,
    settings: `/workspaces/${wsId}/settings`,
  };
}

export const SubHeader: React.FC<IProps> = observer(({ workspaceId }) => {
  let tabKey = 'apiServices';
  const urlMap = getUrls(workspaceId);

  const history = useHistory();
  const { t } = useTranslation();
  const { workspaces, router } = useStore();
  const [currentTab, setCurrentTab] = React.useState(tabKey);

  useEffect(() => {
    for (const key of Object.keys(urlMap)) {
      const regx = new RegExp(`${urlMap[key]}(/.+)?`);
      if (router.location.pathname.match(regx)) {
        tabKey = key;
        break;
      }
    }
    setCurrentTab(tabKey);
  }, [workspaces.current, router.location]);
  const currentMemberRole = workspaces.currentWorkspaceMember?.role;
  return (
    <Tabs className={styles.container} selectedTabId={currentTab} onChange={(tabId) => history.push(urlMap[tabId])}>
      <Tab className={styles.tab} id="apiServices">
        <Icon icon={IconNames.SWAP_VERTICAL} iconSize={22} />
        <span>{t('topNav.menu.apiServices')}</span>
      </Tab>
      <Tab className={styles.tab} id="nodes">
        <GroupIcon iconSize={22} />
        <span>{t('topNav.menu.nodes')}</span>
      </Tab>
      {mxConfig?.featureFlags?.showUserNetworkSpec && (
        <Tab className={styles.tab} id="networks">
          <EarthIcon iconSize={22} />
          <span>{t('topNav.menu.networks')}</span>
        </Tab>
      )}
      {/* <Tab className={styles.tab} id='backups' ><img src={filterIcon} alt='backups' /><span>{t('topNav.menu.backups')}</span></Tab> */}
      {currentMemberRole && ['owner', 'admin'].includes(currentMemberRole) && (
        <Tab className={styles.tab} id="settings">
          <Icon icon={IconNames.SETTINGS} iconSize={22} />
          <span>{t('topNav.menu.settings')}</span>
        </Tab>
      )}
    </Tabs>
  );
});

import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import { Loader } from 'components';
import { Redirect } from 'react-router-dom';
import { useStore } from '../../store';
import { Welcome } from './welcome';
import { List } from './list';

type IProps = RouteConfigComponentProps<{ wsId: string }>;

export const ApiServices: React.FC<IProps> = observer((props) => {
  const { match } = props;
  const wsId = match.params.wsId;
  const { apiService, workspaces } = useStore();
  const [isLoading, setIsloading] = useState(true);
  useEffect(() => {
    (async () => {
      setIsloading(true);
      await apiService.getApps(wsId);
      setIsloading(false);
    })();
  }, [workspaces.current?.id]);

  useEffect(() => {
    return apiService.clearApps();
  }, []);

  if (isLoading) return <Loader isLoading={true} />;
  if (!apiService.apps.length) return <Welcome wsId={wsId} />;

  if (!isLoading && apiService.apps.length) {
    return <List wsId={wsId} />;
  } else return null;
});

import React, { useState } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import { FixedMainHeader, PageLayout, MainBox } from '@/components';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { lang } from './helper';
import { useStore } from '@/store';
import { Row, Col, Tabs } from 'antd';
import { SettingsTab } from './SettingsTab';
import { PlanPayment } from './PlanPayment';
import styles from './style.module.scss';

interface IParams {
  tab: string;
  wsId: string;
}

interface IProps extends RouteConfigComponentProps<IParams> {}

const enum TAB {
  SEETING = 'setting',
  PLAN = 'plan',
}

export const Settings: React.FC<IProps> = observer(({ match, route, history }) => {
  const { t } = useTranslation();
  const { tab, wsId } = match.params;
  const currentTab = tab || TAB.SEETING;

  return (
    <PageLayout>
      <FixedMainHeader>
        {currentTab === TAB.SEETING && <div className={styles.headerSpan}>Settings</div>}
        {currentTab === TAB.PLAN && <div className={styles.headerSpan}>{`Plan & Payment`}</div>}
      </FixedMainHeader>
      <MainBox>
        <div className={styles.container}>
          {currentTab === TAB.SEETING && <SettingsTab />}
          {currentTab === TAB.PLAN && <PlanPayment />}
        </div>
      </MainBox>
    </PageLayout>
  );
});

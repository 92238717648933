import { Button, Classes, Dialog, FormGroup, InputGroup, Intent, Tag, TextArea } from '@blueprintjs/core';
import { Instance } from 'mobx-state-tree';
import { Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ErrorHint, ErrorToast, SuccessToast, SubmitButton } from 'components';
import { useStore } from 'store';
import { Node } from '@/store/nodes';
import { lang } from './helper';
import Loading from 'react-loading';

interface IProps {
  isOpen: boolean;
  id: string;
  metadata?: string;
  onClose: () => void;
}

export const ManageLabelsDialog: React.FC<IProps> = ({ isOpen, onClose, id }) => {
  const { t } = useTranslation();
  const { nodes, workspaces } = useStore();
  const schema = yup.object().shape({
    metadata: yup.string(),
  });
  const { control, errors, handleSubmit } = useForm({ validationSchema: schema });
  const [progressing, setProgressing] = useState(false);
  const [labelMetadata, setLabelMetadata] = useState('');

  useEffect(() => {
    (async () => {
      if (isOpen) {
        setProgressing(true);
        const data = await nodes.fetchNodeDetailForList(workspaces.current!.id, id);
        setLabelMetadata(data.metadata.labels);
        setProgressing(false);
      }
    })();
  }, [isOpen]);

  return (
    <Dialog title={lang(t, 'upgradeNode.titleLabels')} isOpen={isOpen} onClose={onClose}>
      {progressing && <Spin spinning={progressing}></Spin>}
      {!progressing && (
        <form
          onSubmit={handleSubmit(async (val) => {
            setProgressing(true);
            try {
              JSON.parse(val.metadata);
            } catch (error) {
              ErrorToast(lang(t, 'upgradeNode.wrongJson'));
              onClose();
              return;
            }
            try {
              await nodes.updateNodepool(workspaces.current!.id, id, val.metadata);
              SuccessToast('Success');
            } catch (err) {
              ErrorToast('Error');
            }
            onClose();
            await nodes.fetchNode(workspaces.current!.id, nodes.current!.id);
            setProgressing(false);
          })}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label={lang(t, 'upgradeNode.titleLabels')}>
              <Controller
                name="metadata"
                control={control}
                as={<TextArea rows={10} large fill intent={errors.metadata ? Intent.DANGER : Intent.NONE} />}
                defaultValue={JSON.stringify(labelMetadata, null, 4)}
              />
              <ErrorHint message={errors.metadata ? errors.metadata.message : ''} />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <SubmitButton type="submit" intent={Intent.PRIMARY}>
                {lang(t, 'upgradeNode.submit')}
              </SubmitButton>
            </div>
          </div>
        </form>
      )}
    </Dialog>
  );
};

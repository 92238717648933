import { Intent, Tab, Tabs } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteConfigComponentProps } from 'react-router-config';
import { Back, FixedMainHeader, Preview } from 'components';
import {
  Col,
  Loader,
  Spin,
  PageLayout,
  Row,
  Notice,
  LaunchConfiguration,
  ButtonEmpty,
  ErrorToast,
  SuccessToast,
} from 'components';
import { RuleAction } from '@/components/UpdateNetworkRules/helpers';
import { NodeStatus } from '@/store/nodes';
import {
  previewToVars,
  previewToExtraArgs,
  previewToEnvExtraArgs,
  previewToEmpty,
  withoutContext,
  launchData,
  mergeLaunchData,
  KvMapFormat,
  LaunchConfigurationItemFormData,
  LaunchConfigurationValueType,
  isErrorData,
  parameterPerview,
} from '@/components/Preview/help';
import { Popover, Button as AntButton, Skeleton } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { LaunchDataMapBaseNodeTypeType, TabsId } from '@/components/Preview/help';
import { useStore } from 'store';
import { ActionBar } from './ActionBar';
import { dataPanel, lang, parseMetricsDetails } from './helper';
import { EthPanel } from './dataPanels/EthPanel';
import { ResourceIndicator } from './ResourceIndicator';
import styles from './style.module.scss';
import { Summary } from './Summary';
import { ReactComponent as EditIcon } from './img/edit.svg';
import { ActionsList } from './ActionsList';
import cx from 'classnames';
import { operation, RetryOperation } from 'retry';
import { ErrorPage } from '../Error/ErrorPage';
import { GetHourlyPrice } from '../DeployNewNode/helpers';
import { useHistory } from 'react-router-dom';
import { IMember } from '@/store/workspaces';

interface IParams {
  wsId: string;
  id: string;
}

type IProps = RouteConfigComponentProps<IParams>;
let timer: NodeJS.Timer | undefined = undefined;
let statusTimer: NodeJS.Timer | undefined = undefined;
const initSelectTag = 'ResourceInformation';
let reCheckStatusTimes = 4;

let metricsEndpointRetry: RetryOperation;
const createRetryOp = (interval = 1000, forever = true) =>
  operation({
    forever,
    minTimeout: interval,
    maxTimeout: interval,
  });

export const NodeDetail: React.FC<IProps> = observer((props) => {
  const { match } = props;
  const { wsId, id: nodeId } = match.params;
  const { workspaces, nodes, networks, auth } = useStore();
  const [tabId, setTabId] = useState(initSelectTag);
  const [editLaunchConfiguration, setEditLaunchConfiguration] = useState(false);
  const [isUpgrating, setIsUpgrating] = useState(false);
  const [needUpgrade, setNeedUpgrade] = useState<boolean | string>(false);
  const [launchConfigurationDataMap, setLaunchConfigurationDataMap] = useState<LaunchDataMapBaseNodeTypeType>();
  const [previewDataMap, setPreviewDataMap] = useState<LaunchDataMapBaseNodeTypeType>();
  const [estimatePrice, setEstimatePrice] = useState('');
  const [operationalFlags, setOperationalFlags] = useState<number>(0);
  const history = useHistory();
  const isSuperAdmin = auth.user?.isAdmin === true;
  const [loading, setLoading] = useState(false);

  // networkSpecKey
  const node = nodes.current;
  const [redirect, setRedirect] = useState(false);
  const redirectPath = `/workspaces/${wsId}/nodes`;
  const { t } = useTranslation();

  const nodeRestartingRefresh = async () => {
    if (nodes.state === 'error') {
      ErrorToast(nodes.errorMessage!);
    } else {
      SuccessToast('Launch configuration updated successfully, your node is restarting.');
      setLaunchConfigurationDataMap({
        comArgRules: {},
        delimiter: '',
        envRules: [],
        envExtra: [],
        operationalArgs: {},
      });
      setEditLaunchConfiguration(false);
      await nodes.fetchNode(wsId, nodeId);
      await nodes.getNodeStatus(wsId, nodeId);
      initLaunchConfigurationInfo();
    }
  };

  const initLaunchConfigurationInfo = async () => {
    const node = nodes.current;
    const networkSpec = nodes.current?.networkSpec;
    let humpKeyMap = {} as KvMapFormat;
    let keyMap = {} as KvMapFormat;
    // node?.config?.skippedArgs
    (node?.config?.vars || []).map((i: any) => {
      // if (i.value.valueType === LaunchConfigurationValueType.File) {
      humpKeyMap[i.key] = i.value.payload;
      // }
    });
    // node?.config?.skippedArgs
    if (typeof networkSpec?.isPublic === 'boolean') {
      setNeedUpgrade(false);
      const { metadata, config, protocolKey } = await networks.fetchNetworkSpecDetail(
        networkSpec?.isPublic,
        networkSpec?.key,
      );
      const {
        metadata: { rules: protocolRules },
        supportedContext,
      } = await networks.getProtocols(protocolKey);
      // const {
      //   metadata: { rules: protocolRules },
      // } = await networks.getProtocols(networkSpec?.key);
      // let dataMap = launchData(metadata, config?.operations, protocolRules, supportedContext);
      let dataMap: LaunchDataMapBaseNodeTypeType = {
        comArgRules: {},
        delimiter: '',
        envRules: [],
        envExtra: [],
      };
      try {
        dataMap = launchData(metadata, config?.operations, protocolRules, supportedContext, true)[nodes.current!.type];
      } catch (err) {
        history.push(`/workspaces/${workspaces.current!.id}/nodes`);
      }
      (node?.config?.skippedArgs || []).map((i: any) => {
        const dataMapObj = dataMap?.comArgRules?.[i.section]?.data;
        if (dataMapObj) {
          const humpKeyObj = dataMapObj.find((obj: any) => obj.key === i.key);
          humpKeyMap[humpKeyObj?.humpKey + ''] = false;
        }
      });
      if (!dataMap.envRules) dataMap.envRules = [];
      if (!dataMap.comArgRules) dataMap.comArgRules = {};
      if (!dataMap.envExtra) dataMap.envRules = [];
      dataMap = mergeLaunchData(
        dataMap,
        node?.metadata?.parameters?.arg || (node?.flags?.length && node?.flags),
        node?.config?.extraArgs,
        keyMap,
        humpKeyMap,
        node?.config?.skippedArgs,
        node?.config?.vars,
      );
      Object.keys(dataMap?.comArgRules || {}).forEach((section: string) => {
        const extraArgDataMap = [] as any;
        (dataMap?.comArgRules[section]?.extraArgData || []).forEach((extraArgItem: string | undefined) => {
          if (extraArgItem) {
            const temp = extraArgItem?.split('=');
            extraArgDataMap.push(temp[0]);
          }
        });
        (dataMap?.comArgRules[section]?.data || []).forEach((i: LaunchConfigurationItemFormData) => {
          if (!needUpgrade && !i.humpKey && i.action !== RuleAction.REMOVE && !extraArgDataMap.includes(i.key))
            setNeedUpgrade(true);
          if (!needUpgrade && i.disable && i.action !== RuleAction.REMOVE) {
            setNeedUpgrade(true);
          }
        });
      });
      dataMap.envExtra = (node?.config?.extraEnvs || []).map((i: any) => `${i.key}${i.value && '='}${i.value}`);

      const previewDataMap = parameterPerview(
        node?.metadata?.parameters?.arg || (node?.flags?.length && node?.flags),
        dataMap,
      );
      const isShowLaunchError = isErrorData(
        node?.metadata?.parameters?.arg || (node?.flags?.length && node?.flags),
        dataMap,
      );
      !!isShowLaunchError && setNeedUpgrade(isShowLaunchError);
      setPreviewDataMap(previewDataMap);
      setLaunchConfigurationDataMap({ ...dataMap, operationalArgs: node?.config?.operationalArgs });
    }

    if (workspaces.current!.suspendTaskDone) {
      setNeedUpgrade(false);
    }
  };
  useEffect(() => {
    (async () => {
      const data = await nodes.fetchNodeDetailForList(workspaces.current!.id, nodeId);
      setEstimatePrice(
        GetHourlyPrice(
          data,
          Number(data.storage.replace(/\D/g, '')),
          data.nodeSpecMultiplier,
          workspaces.current?.billingType !== 'stripe',
        ),
      );
    })();
  }, [workspaces.current, nodeId]);

  useEffect(() => {
    (async () => {
      await nodes.fetchNode(wsId, nodeId);
      if (nodes.state === 'error') {
        setRedirect(true);
      }
      await initLaunchConfigurationInfo();
    })();
    return () => nodes.clearNode();
  }, [workspaces.current, nodeId]);

  useEffect(() => {
    if (!nodes.current) {
      return;
    }
    if (nodes.current.status === 'running') {
      const intervalCall = async () => {
        timer = setTimeout(intervalCall, 10 * 1000);
        await nodes.getNodeMetrics(wsId, nodeId);
      };
      intervalCall();
    } else {
      timer && clearTimeout(timer);
    }
    const status = ['processing', 'initializing', 'terminating', 'stopping', 'restarting'];
    const intervalCall = async () => {
      if (status.includes(nodes.current?.status ?? '')) {
        statusTimer = setTimeout(intervalCall, 2 * 1000);
        reCheckStatusTimes = 4;
      } else if (reCheckStatusTimes >= 0) {
        --reCheckStatusTimes;
        statusTimer = setTimeout(intervalCall, 2 * 1000);
      } else {
        statusTimer = undefined;
        reCheckStatusTimes = 4;
      }
      await nodes.getNodeStatus(wsId, nodeId);
    };
    intervalCall();
    return () => {
      timer && clearTimeout(timer);
      statusTimer && clearTimeout(statusTimer);
    };
  }, [nodes.current?.status]);

  useEffect(() => {
    // Kill the previous retry instance whenever the state has changed
    if (metricsEndpointRetry) {
      metricsEndpointRetry.stop();
    }
    const { needFetchEndpoints } = parseMetricsDetails(nodes.current);
    if (needFetchEndpoints) {
      // New retry instance
      metricsEndpointRetry = createRetryOp(10 * 1000);
      metricsEndpointRetry.attempt(async () => {
        await nodes.fetchEndpoints(wsId, nodeId);
        // Retry the attempt if couldn't fetch the endpoints
        const { needFetchEndpoints } = parseMetricsDetails(nodes.current);
        if (needFetchEndpoints) {
          metricsEndpointRetry.retry(new Error('retry endpoints fetching'));
        }
      });
    }
  }, [nodes.current?.status, nodes.current?.endpoints]);

  // Node operational flags
  useEffect(() => {
    if (!nodes.current) {
      return;
    }
    setOperationalFlags(nodes.current.operationalArgsFlag || 0);
  }, [nodes.current]);

  const upgradeLaunchConfiguration = async (preview: LaunchDataMapBaseNodeTypeType) => {
    setIsUpgrating(true);
    await nodes.upgradeLaunchConfiguration(wsId, nodeId, {
      config: {
        vars: previewToVars(withoutContext(preview)),
        extraArgs: previewToExtraArgs(preview),
        extraEnvs: previewToEnvExtraArgs(preview?.envExtra),
        // contexts: previewToContexts(preview), //not alow user edit node-key so hide it
        skippedArgs: previewToEmpty(preview),
      },
    });
    setIsUpgrating(false);
    await nodeRestartingRefresh();
  };

  const handleCloneNode = async (wsId: string, nodeId: string, nodeName: string, autoExpand: boolean) => {
    const newNode = await nodes.cloneNode(wsId, nodeId, nodeName, autoExpand);
    await nodes.getNodeStatus(wsId, newNode.id);
    await nodes.fetchList(match.params.wsId);
    return;
  };

  const handleOperationalFlags = async (flag: number) => {
    setIsUpgrating(true);
    await nodes.setOperationalFlags(node?.id || '0', flag);
    setIsUpgrating(false);
    if (nodes.state === 'done') {
      setOperationalFlags(flag);
    }
    await nodeRestartingRefresh();
  };

  const handleChangeOwner = async (member?: IMember) => {
    if (member) {
      setLoading(true);
      await nodes.changeOwner(member, wsId, nodeId);
      if (nodes.state === 'error') {
        ErrorToast(nodes.errorMessage || t('general.error'));
      } else {
        SuccessToast(lang(t, 'buttonGroup.success.changeOwner', { ownerName: member.name }));
      }
      setLoading(false);
    }
  };

  const isTerminated = node && [NodeStatus.Terminated, NodeStatus.Terminating].includes(node.status);
  if (redirect) {
    return (
      <ErrorPage
        noLogo={true}
        {...props}
        gobackPath={redirectPath}
        theErrorText="We can't find the node that you are looking for."
      />
    );
  }
  return (
    <PageLayout>
      <FixedMainHeader>
        <Row className={styles.row}>
          <Col unit={6}>
            <Back link={`/workspaces/${wsId}/nodes`} text={t('pages.nodeList.title')} />
          </Col>
          <Col unit={18} style={{ textAlign: 'right' }}>
            {node && (
              <ActionBar
                loading={loading}
                node={node}
                upgrade={!!needUpgrade}
                estimatePrice={estimatePrice}
                onCloneNode={handleCloneNode}
                onChangeOwner={handleChangeOwner}
              />
            )}
          </Col>
        </Row>
      </FixedMainHeader>
      <Spin isLoading={(!node && nodes.state === 'pending') || isUpgrating}>
        <div className={styles.mainContent}>
          {nodes.current?.image.split(':')[1] !== nodes.current?.recommendVersion && nodes.current?.hasUpgrade && (
            <Notice className={styles.imageNotice} intent={Intent.PRIMARY} needClose={true}>
              {lang(t, 'upgradeNode.imageUpdateNotice')}
            </Notice>
          )}
          <Summary node={node} />
          {!node && <Skeleton paragraph={{ rows: 6 }} />}
          {node && (
            <>
              {node.networkSpec?.protocolKey &&
                (dataPanel[node.networkSpec.protocolKey]
                  ? React.createElement(dataPanel[node.networkSpec.protocolKey], { nodes })
                  : React.createElement(EthPanel, { nodes }))}
              <Tabs onChange={(id: string) => setTabId(id)}>
                <Tab
                  id={initSelectTag}
                  title={lang(t, 'detailTitle.resourceInformation')}
                  panel={<ResourceIndicator node={node} />}
                />
                <Tab
                  className={cx({ [styles.upgradeBubble]: needUpgrade })}
                  title={
                    <>
                      {!isTerminated && needUpgrade && (
                        <Popover
                          content={
                            <div>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: lang(t, 'updateNotice', { name: node?.networkSpec?.displayName }),
                                }}
                              />
                              <Row>
                                <Col alignRight>
                                  {!editLaunchConfiguration && (
                                    <AntButton
                                      loading={isUpgrating}
                                      disabled={workspaces.current!.suspendTaskDone}
                                      type="primary"
                                      onClick={() => {
                                        setEditLaunchConfiguration(true);
                                      }}
                                    >
                                      {lang(t, 'upgradeNode.edit')}
                                    </AntButton>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          }
                          trigger="hover"
                        >
                          <span>
                            {lang(t, 'detailTitle.launchCommand')}
                            <WarningOutlined />
                          </span>
                        </Popover>
                      )}
                      {(isTerminated || !needUpgrade) && lang(t, 'detailTitle.launchCommand')}
                    </>
                  }
                  id="LaunchCommand"
                  panel={
                    <>
                      {!editLaunchConfiguration && (
                        <>
                          <Row className={styles.editButton}>
                            <Col alignRight>
                              {!isTerminated && !workspaces.current!.suspendTaskDone && (
                                <ButtonEmpty
                                  icon={<EditIcon />}
                                  small
                                  onClick={() => {
                                    setEditLaunchConfiguration(true);
                                  }}
                                >
                                  <>
                                    <span>{lang(t, 'upgradeNode.edit')}</span>
                                  </>
                                </ButtonEmpty>
                              )}
                            </Col>
                          </Row>
                          {previewDataMap && (
                            <Preview
                              showDelete
                              data={{
                                delimiter: previewDataMap?.delimiter,
                                comArgRules: previewDataMap?.comArgRules,
                                envRules: previewDataMap?.envRules || [],
                                envExtra: previewDataMap?.envExtra || [],
                                operationalArgs: node.config?.operationalArgs,
                              }}
                              showOperationalArgs={isSuperAdmin}
                            />
                          )}
                        </>
                      )}
                      {editLaunchConfiguration && (
                        <Loader isLoading={!launchConfigurationDataMap}>
                          <LaunchConfiguration
                            extraError={needUpgrade}
                            loading={isUpgrating}
                            disabled={needUpgrade ? false : true}
                            dataMap={launchConfigurationDataMap}
                            submitButtonText={'Save Changes'}
                            cancelButtonText={'Cancel'}
                            onBack={() => {
                              setEditLaunchConfiguration(false);
                            }}
                            onSubmit={upgradeLaunchConfiguration}
                            availableOpsFlags={node.availableOpsFlags}
                            onOperationalFlags={handleOperationalFlags}
                            operationalFlag={operationalFlags}
                            showOperationalSection={isSuperAdmin}
                          />
                        </Loader>
                      )}
                    </>
                  }
                />
                <Tab
                  title={lang(t, 'buttonGroup.actions.actionsDetail')}
                  id="ActivityLogs"
                  panel={tabId === 'ActivityLogs' ? <ActionsList node={node} /> : <></>}
                />
              </Tabs>
            </>
          )}
        </div>
      </Spin>
    </PageLayout>
  );
});

import React from 'react';
import { useStore } from '../../store';
import { observer } from 'mobx-react';
import { Button, Dialog, Classes } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DetailBox, Loader } from 'components';
import styles from './style.module.scss';
import { ReactComponent as ErrorIcon } from './img/error.svg';
import { ReactComponent as SuccessIcon } from './img/success.svg';
import sanitizeHtml from 'sanitize-html';

interface IProps {
  status: string;
  returnUrl?: string;
}

export const CreateNodeResult: React.FC<IProps> = observer(({ status, returnUrl }) => {
  const { t } = useTranslation();
  const store = useStore();
  const history = useHistory();
  return (
    <Dialog isOpen={true} className={styles.container}>
      <div className={Classes.DIALOG_BODY}>
        {status === 'pending' && (
          <div>
            <div className={styles.creating}>
              <Loader isLoading padding={false} />
              <p>{t('modules.createNodeResult.creatingMessage')}</p>
            </div>
          </div>
        )}

        {status === 'done' && (
          <div>
            <div>
              <SuccessIcon className={styles.successImg} />
              <p>{t('modules.createNodeResult.successMessage')}</p>
              <Button
                intent="primary"
                onClick={() => {
                  history.replace(returnUrl ?? `/workspaces/${store.workspaces.current!.id}/nodes`);
                }}
                className={styles.bottomBtn}
              >
                {t('modules.createNodeResult.button')}
              </Button>
            </div>
          </div>
        )}

        {status === 'error' && (
          <div>
            <div>
              <ErrorIcon className={styles.errorImg} />
              {/* <p>{t('modules.createNodeResult.errorMessage')}</p> */}
              <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(store.nodes.errorMessage ?? '') }} />
              <Button
                intent="primary"
                onClick={() => {
                  history.replace(returnUrl ?? `/workspaces/${store.workspaces.current!.id}/nodes`);
                }}
                className={styles.bottomBtn}
              >
                {t('modules.createNodeResult.button')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
});

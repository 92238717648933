import React, { useState } from 'react';
import { useStore } from '../../store';
import { observer } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorToast, SuccessToast, DetailBox, FormError, ComponentHeader } from 'components';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal } from 'antd';
import { EditOutlined, LockOutlined } from '@ant-design/icons';
import cx from 'classnames';
import * as yup from 'yup';

interface IProps {
  className?: string;
}

export const AccountPasswordNew: React.FC<IProps> = observer(({ className }) => {
  const { t } = useTranslation();
  const store = useStore();

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t('modules.accountPassword.error.password'))
      .min(6, t('modules.accountPassword.error.passwordLength')),
    newPassword: yup
      .string()
      .required(t('modules.accountPassword.error.password'))
      .min(6, t('modules.accountPassword.error.passwordLength')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], t('modules.accountPassword.error.passwordConfirmDifferent'))
      .required(t('modules.accountPassword.error.newPassword'))
      .min(6, t('modules.accountPassword.error.passwordLength')),
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [isShowDialog, setShowDialog] = React.useState(false);
  const { control, handleSubmit, errors } = useForm({ validationSchema: schema });

  const doSubmit = handleSubmit(async (val) => {
    const { password, newPassword } = val;
    setSubmitLoading(true);
    await store.auth.changePasswordByApi(password, newPassword);
    if (store.auth.state === 'done') {
      SuccessToast(t('modules.accountPassword.changePasswordSuccess'));
      setShowDialog(false);
      setSubmitLoading(false);
      await store.auth.logout();
    } else if (store.auth.state === 'error') {
      ErrorToast(store.auth.errorMessage || t('modules.accountPassword.changePasswordError'));
      setSubmitLoading(false);
    }
  });
  return (
    <DetailBox className={cx(className)}>
      <ComponentHeader>
        <h3>{t('modules.accountPassword.title')}</h3>
        <Button
          type="link"
          onClick={() => {
            setShowDialog(true);
          }}
        >
          <EditOutlined />
          {t('modules.accountPassword.resetButton')}
        </Button>
      </ComponentHeader>
      <Input size="large" prefix={<LockOutlined />} disabled value={'•••••••••••••'} />

      {isShowDialog && (
        <Modal
          visible={true}
          title={t('modules.accountPassword.dialogTitle')}
          onCancel={() => {
            setShowDialog(false);
          }}
          footer={[
            <Button
              key={'reset Button'}
              type={'primary'}
              onClick={() => {
                doSubmit();
              }}
              loading={submitLoading}
            >
              {t('modules.accountPassword.resetButton')}
            </Button>,
          ]}
          centered
        >
          <p className={styles.fontSize16}>{t('modules.accountPassword.labelOldPW')}</p>
          <Controller
            name="password"
            control={control}
            as={<Input type="password" placeholder={t('modules.accountPassword.labelOldPW')} size="large" />}
          />
          <FormError>{errors.password?.message}</FormError>
          <br />
          <br />
          <p className={styles.fontSize16}>{t('modules.accountPassword.labelNewPW')}</p>
          <Controller
            name="newPassword"
            control={control}
            as={<Input type="password" placeholder={t('modules.accountPassword.labelNewPW')} size="large" />}
          />
          <FormError>{errors.newPassword?.message}</FormError>
          <br />
          <br />
          <p className={styles.fontSize16}>{t('modules.accountPassword.labelConfirmPW')}</p>
          <Controller
            name="confirmPassword"
            control={control}
            as={<Input type="password" placeholder={t('modules.accountPassword.labelConfirmPW')} size="large" />}
          />
          <FormError>{errors.confirmPassword?.message}</FormError>
        </Modal>
      )}
    </DetailBox>
  );
});

import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import cx from 'classnames';
import { Instance } from 'mobx-state-tree';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorToast, SuccessToast, SubmitButton } from 'components';
import { NodeSpecSelector } from '../../modules';
import { useStore } from '../../store';
import { INodeSimple } from '@/store/nodes';
import { lang } from './helper';
import styles from './style.module.scss';

interface IProps {
  isOpen: boolean;
  id: string;
  nodeSpec: string;
  multiplier: number;
  onClose: () => void;
}

export const UpgradeNodeSpecDialog: React.FC<IProps> = ({ isOpen, onClose, id, nodeSpec, multiplier }) => {
  const { t } = useTranslation();
  const { info, nodes, workspaces } = useStore();
  const [progressing, setProgressing] = useState(false);
  const [thisNodeSpec, setThisNodeSpec] = useState({
    key: nodeSpec,
    multiplier: multiplier,
  });
  const onSubmit = async () => {
    setProgressing(true);
    await nodes.updateNodeSpec(workspaces.current!.id, id, { nodeSpec: thisNodeSpec });
    if (nodes.state === 'error') {
      ErrorToast(nodes.errorMessage!);
    } else {
      SuccessToast('Success');
      onClose();
    }
    setProgressing(false);
  };
  return (
    <Dialog title={lang(t, 'upgradeNode.titleNodeSpec')} isOpen={isOpen} onClose={onClose}>
      <div className={cx(Classes.DIALOG_BODY, styles.nodeSpecDialogBody)}>
        <FormGroup className={styles.fromGroupMargin}>
          <NodeSpecSelector
            nodeSpecs={info.nodeSpecs}
            value={thisNodeSpec}
            onChange={(value) => {
              setThisNodeSpec(value);
              return value.key;
            }}
          />
        </FormGroup>

        {nodes?.current?.metadata?.labels?.nodePool !== undefined && (
          <p className={styles.rpcAndUserWarning}>
            This node is used for the OnFinality Public API. Changing these settings will restart the node, so please
            perform a rolling update of only one node at a time
          </p>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <SubmitButton
            type="submit"
            onClick={onSubmit}
            intent={Intent.PRIMARY}
            disabled={nodes.state === 'pending'}
            loading={progressing}
          >
            {lang(t, 'upgradeNode.submit')}
          </SubmitButton>
        </div>
      </div>
    </Dialog>
  );
};

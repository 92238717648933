import React, { useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AntdRow, AntdCol } from 'components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ErrorToast } from 'components';
import { observer } from 'mobx-react';
import { Button, Form, Input } from 'antd';
import { checkPasswordFormat } from '@/components/Util';
import { useStore } from '@/store';
import { TFunction } from 'i18next';
import { IdentityProvider } from '@/store/auth';
import { ReactComponent as GoogleIcon } from '@/pages/Login/img/google-icon.svg';
import { ReactComponent as VectorIcon } from '@/pages/Login/img/vector.svg';
import styles from '@/pages/Login/style.module.scss';
import { mxConfig } from '@/config';
import ReCAPTCHA from 'react-google-recaptcha';
import sanitizeHtml from 'sanitize-html';
const ReCAPTCHAX: React.FC<any> = ReCAPTCHA as any;

const lang = (t: TFunction, key: string, options?: any) => t(`pages.login.${key}`, options);

interface ICallback {
  gotoLogin: () => void;
  onSubmit: () => void;
}

type IProps = ICallback;

export const SignUpForm: React.FC<IProps> = observer(({ gotoLogin, onSubmit }) => {
  const { auth } = useStore();
  const history = useHistory();
  const [t] = useTranslation();
  const [pwError, setPWError] = useState(false);
  const { control, handleSubmit, errors, register, setError, getValues } = useForm();
  const [captchaValue, setCaptchaValue] = useState('');
  const [captchaIsDone, setCaptchaIsDone] = useState(false);
  const captcha = useRef(null);

  const isNotEmail = (v: string) => {
    return !v ? lang(t, `error.email`) : undefined;
  };
  const isNotPassword = (v: string) => {
    return !v ? lang(t, `error.password`) : undefined;
  };
  const isNotName = (v: string) => {
    return !v ? lang(t, `error.name`) : undefined;
  };

  const doSubmit = async (val: any) => {
    const { name, email, password } = val;
    setPWError(false);
    if (checkPasswordFormat(password)) {
      return setPWError(true);
    } else {
      if (sanitizeHtml(name).length === name.length) {
        await auth.userSignUp(name, email, password, captchaValue);
      } else {
        ErrorToast('Invalid username.');
        return;
      }
      if (auth.state === 'error') {
        ErrorToast(auth.errorMessage!);
        return;
      }
      if (!auth.inviteData) {
        //@ts-ignore
        captcha.current.reset();
        onSubmit();
        return;
      }
      //@ts-ignore
      captcha.current.reset();
      await auth.userLogin(email, password);
      history.push(`/invite?token=${auth.inviteData.token}`);
    }
  };

  return (
    <>
      <AntdRow className={styles.formTitle}>
        <AntdCol span={12}>
          <h2 className={styles.title}>{t('pages.login.createAccount')}</h2>
        </AntdCol>
        <AntdCol span={12} alignRight className={styles.subFormTitle}>
          {`${lang(t, `or`)} `}
          <span className={styles.signUp} onClick={gotoLogin}>
            {lang(t, `login`)}
          </span>
        </AntdCol>
      </AntdRow>

      <Button
        icon={<GoogleIcon />}
        block
        size={'large'}
        className={styles.loginButton}
        onClick={() => auth.authorize(IdentityProvider.Google)}
      >
        <span>{lang(t, `signUpWithGoogle`)}</span>
      </Button>

      <Button
        icon={<VectorIcon />}
        block
        size={'large'}
        className={styles.loginButton}
        onClick={() => auth.authorize(IdentityProvider.Github)}
      >
        <span>{lang(t, `signUpWithGitHub`)}</span>
      </Button>

      {!auth.inviteData && (
        <div className={styles.moreLogin}>
          <span className={styles.dividing}>
            <span>{lang(t, `otherCreate`)}</span>
          </span>
        </div>
      )}

      <Form onFinish={() => handleSubmit(doSubmit)()} layout="vertical" autoComplete="off" scrollToFirstError>
        <Form.Item
          name="name"
          label={lang(t, `name`)}
          // validateStatus={errors.name ? "error" : ''}
          // help={errors.name ? errors.name.message : ''}
        >
          <Controller
            name="name"
            defaultValue=""
            rules={{ validate: { inputTelRequired: isNotName } }}
            control={control}
            ref={register}
            as={<Input size={'large'} placeholder={lang(t, `namePlaceholder`)} />}
          />
          <span className={styles.errorNotice}>{errors.name ? errors.name.message : ''}</span>
        </Form.Item>
        <Form.Item
          name="email"
          label={lang(t, `email`)}
          // validateStatus={errors.email ? "error" : ''}
          // help={errors.email ? errors.email.message : ''}
        >
          <Controller
            name="email"
            defaultValue={auth.inviteData?.decoded.email}
            rules={{ validate: { inputTelRequired: isNotEmail } }}
            control={control}
            ref={register}
            as={
              <Input type="email" size={'large'} placeholder={lang(t, `emailAddress`)} disabled={!!auth.inviteData} />
            }
          />
          <span className={styles.errorNotice}>{errors.email ? errors.email.message : ''}</span>
        </Form.Item>

        <Form.Item
          name="password"
          label={lang(t, `password`)}
          validateStatus={pwError ? 'error' : ''}
          help={t('pages.login.error.passwordComplex')}
        >
          <Controller
            name="password"
            defaultValue=""
            rules={{ validate: { inputTelRequired: isNotPassword } }}
            control={control}
            ref={register}
            as={<Input.Password size={'large'} placeholder={lang(t, `passwordPlaceholder`)} />}
          />
          <span></span>
        </Form.Item>
        <span
          dangerouslySetInnerHTML={{
            __html: `${lang(t, `agree`, {
              termsHref: `${mxConfig.homePage}terms`,
              policyHref: `${mxConfig.homePage}privacy`,
            })}`,
          }}
        />
        <ReCAPTCHAX
          sitekey={mxConfig.captchaPublicSiteKey}
          onChange={(e: any) => {
            setCaptchaValue(e);
            setCaptchaIsDone(true);
          }}
          ref={captcha}
        />
        <Button
          htmlType="submit"
          size={'large'}
          type={'primary'}
          disabled={auth.state === 'pending' || !captchaIsDone}
          className={styles.submitButton}
        >
          {t('pages.login.signup')}
        </Button>
      </Form>
    </>
  );
});

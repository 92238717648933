import React, { useEffect, useState } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import Loading from 'react-loading';
import { useStore } from 'store';
import {
  Row,
  Col,
  DetailBox,
  SearchTable,
  PrimaryToast,
  SuccessToast,
  ErrorToast,
  Loader,
  SubmitButton,
  MainBox,
  DatePicker,
  PageLayout,
  FixedMainHeader,
  AntdRow,
  AntdCol,
} from 'components';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Intent, Checkbox, Tabs, Tab, Tag, Dialog, Classes } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { Spin, Radio } from 'antd';
import { SummaryTypes, UsageDetailKindMap } from '@/store/usageCenter';
import { lang, getColumn, initListData, usageChartData, ChartDataType, charOptDaily, initXY } from './helper';
import { NoData } from './noData';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import dayjs, { Dayjs } from 'dayjs';
import { isPlanHasPermission } from '@/configuration';
import ReactEcharts from 'echarts-for-react';
import cx from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import { RangeValue } from 'rc-picker/lib/interface';

dayjs.extend(dayjsPluginUTC as any, { parseToLocal: true });

const dateForm = 'YYYY-MM-DD';
const apiDataForm = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;

type IProps = RouteConfigComponentProps;

const defaultOrder = { key: 'start', way: 'DESC' };

const getNumberValue = (value: number | undefined) => {
  return value ?? 0;
};

const parseUsageKind = (val: string, fallback = SummaryTypes.CPU): SummaryTypes => {
  const availValues = Object.values(SummaryTypes);
  if (availValues.includes(val as SummaryTypes)) {
    return val as SummaryTypes;
  }
  return fallback;
};

export const UsageCenter: React.FC<IProps> = observer(() => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const { push } = useHistory();
  const yesterday = dayjs.utc().subtract(1, 'day');
  const weekBefore = yesterday.subtract(1, 'week');
  const monthBefore = yesterday.subtract(1, 'month');
  const threeMonthBefore = yesterday.subtract(3, 'month');
  const [dataRange, setDataRange] = useState<[Dayjs, Dayjs]>([monthBefore, yesterday]);
  const [currentTab, setCurrentTab] = React.useState(parseUsageKind(hash.replace('#', '')));
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [hasData, setHasData] = useState('progressing');
  const [dates, setDates] = useState<RangeValue<dayjs.Dayjs>>(null);
  const [hackValue, setHackValue] = useState<any>(null);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [hasApiRequest, setHasApiRequest] = useState<boolean>(false);
  const [isLoadingDailyData, setIsLoadingDailyData] = useState(false);
  const [chartData, setChartData] = useState<ChartDataType>({
    x: [],
    y: [],
  });
  const { usageCenter, workspaces } = useStore();

  const showBackupAndAPIDataEgress = isPlanHasPermission(workspaces.current?.plan, 'showBackupAndAPIDataEgress');

  const gotoTab = (id: SummaryTypes) => {
    setCurrentTab(id);
    push(`#${id}`);
  };

  const [exportOption, setExportOption] = useState<{ [key: string]: boolean }>(
    showBackupAndAPIDataEgress
      ? {
          all: true,
          [SummaryTypes.CPU]: false,
          [SummaryTypes.Storage]: false,
          [SummaryTypes.Backup]: false,
          [SummaryTypes.DataTraffic]: false,
          [SummaryTypes.APIRequests]: false,
        }
      : {
          all: true,
          [SummaryTypes.CPU]: false,
          [SummaryTypes.Storage]: false,
          [SummaryTypes.APIRequests]: false,
        },
  );
  const [list, setList] = useState<{ [key: string]: any }>(initListData(showBackupAndAPIDataEgress));

  const records = showBackupAndAPIDataEgress
    ? {
        [SummaryTypes.CPU]: getNumberValue(usageCenter.summary.cpu.records),
        [SummaryTypes.Storage]: getNumberValue(usageCenter.summary.storage.records),
        [SummaryTypes.Backup]: getNumberValue(usageCenter.summary.backup.records),
        [SummaryTypes.DataTraffic]: getNumberValue(usageCenter.summary.dataTraffic.records),
        [SummaryTypes.APIRequests]: getNumberValue(usageCenter.summary.apiRequests.records),
      }
    : ({
        [SummaryTypes.CPU]: getNumberValue(usageCenter.summary.cpu.records),
        [SummaryTypes.Storage]: getNumberValue(usageCenter.summary.storage.records),
        [SummaryTypes.APIRequests]: getNumberValue(usageCenter.summary.apiRequests.records),
      } as {
        [key: string]: number;
      });

  useEffect(() => {
    showBackupAndAPIDataEgress
      ? setExportOption({
          all: true,
          [SummaryTypes.CPU]: false,
          [SummaryTypes.Storage]: false,
          [SummaryTypes.Backup]: false,
          [SummaryTypes.DataTraffic]: false,
          [SummaryTypes.APIRequests]: false,
        })
      : setExportOption({
          all: true,
          [SummaryTypes.CPU]: false,
          [SummaryTypes.Storage]: false,
          [SummaryTypes.APIRequests]: false,
        });
  }, [
    usageCenter.summary.cpu,
    usageCenter.summary.storage,
    usageCenter.summary.backup,
    usageCenter.summary.dataTraffic,
    usageCenter.summary.apiRequests,
  ]);

  const [page, setPage] = useState(1);
  useEffect(() => {
    (async () => {
      const { networks, nodes } = await usageCenter.checkHasData(workspaces.current!.id);
      setHasData(networks.length <= 0 && nodes.length <= 0 && hasApiRequest ? 'noData' : 'hasData');
    })();
  }, []);

  const resetSummary = async () => {
    if (!dataRange[0] || !dataRange[1]) return;
    setIsLoadingSummary(true);
    await usageCenter.fetchSummary(
      workspaces.current!.id,
      `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
      `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
    );
    setHasApiRequest(
      usageCenter.summary.apiRequests.total !== undefined && parseInt(usageCenter.summary.apiRequests.total) > 0,
    );
    if (usageCenter.state === 'error') ErrorToast(usageCenter?.errorMessage);
    setIsLoadingSummary(false);
  };

  const resetData = async (page = 1, where?: any, order?: any) => {
    if (!dataRange[0] || !dataRange[1]) return;
    setIsLoading(true);
    const { data, totalPage, total } = (await usageCenter.fetchUsageDetail(
      workspaces.current!.id,
      UsageDetailKindMap[currentTab],
      `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
      `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
      page,
      order,
    )) || {
      data: [] as any,
      totalPage: 0,
    };

    setIsLoading(false);
    setList({
      ...list,
      [currentTab]: {
        data: data || [],
        totalPage,
        total,
      },
    });
  };

  useEffect(() => {
    (async () => {
      setIsLoadingDailyData(true);
      await resetSummary();
      setPage(1);
      resetData(1, null, defaultOrder);
      const data = await usageCenter.fetchUsageDetailDaily(
        workspaces.current!.id,
        UsageDetailKindMap[currentTab],
        `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
        `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
      );
      setIsLoadingDailyData(false);
      if (data) {
        const { x, y } = usageChartData(data, dayjs(dataRange[0]), dayjs(dataRange[1]));
        if (x && y) setChartData({ x, y });
      } else {
        const { x, y } = initXY(dayjs(dataRange[0]), dayjs(dataRange[1]));
        if (x && y) setChartData({ x, y });
      }
    })();
  }, [dataRange, currentTab]);

  const columns = showBackupAndAPIDataEgress
    ? {
        [SummaryTypes.CPU]: getColumn(t, SummaryTypes.CPU),
        [SummaryTypes.Storage]: getColumn(t, SummaryTypes.Storage),
        [SummaryTypes.Backup]: getColumn(t, SummaryTypes.Backup),
        [SummaryTypes.DataTraffic]: getColumn(t, SummaryTypes.DataTraffic),
        [SummaryTypes.APIRequests]: getColumn(t, SummaryTypes.APIRequests),
      }
    : {
        [SummaryTypes.CPU]: getColumn(t, SummaryTypes.CPU),
        [SummaryTypes.Storage]: getColumn(t, SummaryTypes.Storage),
        [SummaryTypes.APIRequests]: getColumn(t, SummaryTypes.APIRequests),
      };

  const exportFile = async () => {
    let kinds = [] as any;
    (showBackupAndAPIDataEgress
      ? [
          SummaryTypes.CPU,
          SummaryTypes.Storage,
          SummaryTypes.Backup,
          SummaryTypes.DataTraffic,
          SummaryTypes.APIRequests,
        ]
      : [SummaryTypes.CPU, SummaryTypes.Storage, SummaryTypes.APIRequests]
    ).forEach((i: SummaryTypes, index: number) => {
      if (exportOption[i]) {
        kinds.push(UsageDetailKindMap[i]);
      } else if (exportOption.all && records[i]) {
        kinds.push(UsageDetailKindMap[i]);
      }
    });
    setIsExporting(true);
    await usageCenter.exportUsage(
      workspaces.current!.id,
      kinds,
      `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
      `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
    );
    if (usageCenter.state === 'error') ErrorToast(usageCenter.errorMessage);
    else SuccessToast(lang(t, `exportSuccess`));
    setIsExporting(false);
  };

  if (hasData === 'progressing') return <Loader isLoading />;
  if (hasData === 'noData') {
    return (
      <PageLayout>
        <FixedMainHeader>
          <Row className={styles.row}>
            <Col unit={6}>
              <h3>{lang(t, 'title')}</h3>
            </Col>
          </Row>
        </FixedMainHeader>
        <MainBox>
          <div className={styles.container}>
            <NoData />
          </div>
        </MainBox>
      </PageLayout>
    );
  }
  return (
    <PageLayout>
      <FixedMainHeader>
        <AntdRow className={styles.row}>
          <AntdCol span={12}>
            <h3>{lang(t, 'title')}</h3>
          </AntdCol>
          <AntdCol span={12} alignRight>
            <Button
              disabled={isLoadingSummary}
              className={styles.exportButton}
              type={'primary'}
              icon={<DownloadOutlined />}
              onClick={() => {
                if (
                  !records[SummaryTypes.CPU] &&
                  !records[SummaryTypes.Storage] &&
                  !records[SummaryTypes.Backup] &&
                  !records[SummaryTypes.DataTraffic] &&
                  !records[SummaryTypes.APIRequests]
                ) {
                  return PrimaryToast(lang(t, `noDataCanExport`));
                }
                setShowExportDialog(true);
              }}
            >
              {lang(t, `export`)}
            </Button>
          </AntdCol>
        </AntdRow>
      </FixedMainHeader>
      <MainBox>
        <div className={styles.container}>
          {hasData === 'progressing' && <Loader isLoading />}
          {hasData === 'noData' && <NoData />}
          {hasData === 'hasData' && (
            <>
              <Row className={styles.dataRange}>
                <Col unit={8}>
                  <h3 className={styles.subTitle}>{lang(t, `usageSummary`)}</h3>
                </Col>
                <Col unit={16} alignRight>
                  <span className={styles.dateRangeTitle}>{lang(t, `dateRange`)}</span>
                  <RangePicker
                    defaultValue={dataRange}
                    value={hackValue || dataRange}
                    format={dateForm}
                    onChange={(data: any) => {
                      data && setDataRange(data);
                    }}
                    disabledDate={(current) => {
                      if (!dates) {
                        return false;
                      }
                      const tooLate = dates[0] && current.diff(dates[0], 'days') > 93;
                      const tooEarly = dates[1] && dates[1].diff(current, 'days') > 93;
                      return !!tooEarly || !!tooLate || current.diff(yesterday, 'day') > 1;
                    }}
                    onCalendarChange={(val) => setDates(val)}
                    onOpenChange={(open) => {
                      if (open) {
                        setHackValue([null, null]);
                        setDates([null, null]);
                      } else {
                        setHackValue(null);
                      }
                    }}
                    ranges={{
                      [lang(t, `dateRangeExtar.pastWeek`)]: [weekBefore, yesterday],
                      [lang(t, `dateRangeExtar.pastMonth`)]: [monthBefore, yesterday],
                      [lang(t, `dateRangeExtar.past3Month`)]: [threeMonthBefore, yesterday],
                    }}
                  />
                  <div className={styles.directions}>
                    Data is shown in UTC time and is processed at the end of each day
                  </div>
                </Col>
              </Row>
              <div className={styles.summaryGroup}>
                <DetailBox
                  className={cx(styles.summary, styles.summaryCPU)}
                  highlight={currentTab === SummaryTypes.CPU}
                  hover
                  onClick={() => {
                    gotoTab(SummaryTypes.CPU);
                  }}
                >
                  <Radio className={styles.radioBtn} checked={currentTab === SummaryTypes.CPU}></Radio>
                  <Tag>{lang(t, `CPU`)}</Tag>
                  <p className={styles.subTitleForSummary}>{lang(t, `subTitleForSummaryForNode`)}</p>
                  <div className={styles.detail}>
                    <span>
                      {isLoadingSummary ? (
                        <Loading
                          className={styles.summaryLoading}
                          color={'#6879f5'}
                          type={'balls'}
                          width={18}
                          height={18}
                        />
                      ) : (
                        usageCenter.summary.cpu.total
                      )}
                    </span>{' '}
                    Unit Hrs
                  </div>
                </DetailBox>
                <DetailBox
                  className={cx(styles.summary, styles.summaryStorage)}
                  hover
                  highlight={currentTab === SummaryTypes.Storage}
                  onClick={() => {
                    gotoTab(SummaryTypes.Storage);
                  }}
                >
                  <Radio className={styles.radioBtn} checked={currentTab === SummaryTypes.Storage}></Radio>
                  <Tag>{lang(t, `storage`)}</Tag>
                  <p className={styles.subTitleForSummary}>{lang(t, `subTitleForSummaryForNode`)}</p>
                  <div className={styles.detail}>
                    <span>
                      {isLoadingSummary ? (
                        <Loading
                          className={styles.summaryLoading}
                          color={'#82ca9d'}
                          type={'balls'}
                          width={18}
                          height={18}
                        />
                      ) : (
                        usageCenter.summary.storage.total
                      )}
                    </span>{' '}
                    GB Hrs
                  </div>
                </DetailBox>
                <DetailBox
                  className={cx(styles.summary, styles.summaryApiRequest)}
                  highlight={currentTab === SummaryTypes.APIRequests}
                  hover
                  onClick={() => {
                    gotoTab(SummaryTypes.APIRequests);
                  }}
                >
                  <Radio className={styles.radioBtn} checked={currentTab === SummaryTypes.APIRequests}></Radio>
                  <Tag>{lang(t, `apiRequests`)}</Tag>
                  <p className={styles.subTitleForSummary}>{lang(t, `subTitleForSummaryForApiRequests`)}</p>
                  <div className={styles.detail}>
                    <span>
                      {isLoadingSummary ? (
                        <Loading
                          className={styles.summaryLoading}
                          color={'#f384c0'}
                          type={'balls'}
                          width={18}
                          height={18}
                        />
                      ) : (
                        usageCenter.summary.apiRequests.total
                      )}
                    </span>{' '}
                    {lang(t, `requests`)}
                  </div>
                </DetailBox>
                {showBackupAndAPIDataEgress && (
                  <DetailBox
                    className={cx(styles.summary, styles.summaryBackup)}
                    highlight={currentTab === SummaryTypes.Backup}
                    hover
                    onClick={() => {
                      gotoTab(SummaryTypes.Backup);
                    }}
                  >
                    <Radio className={styles.radioBtn} checked={currentTab === SummaryTypes.Backup}></Radio>
                    <Tag>{lang(t, `backup`)}</Tag>
                    <p className={styles.subTitleForSummary}>{lang(t, `subTitleForSummaryForNetwork`)}</p>
                    <div className={styles.detail}>
                      <span>
                        {isLoadingSummary ? (
                          <Loading
                            className={styles.summaryLoading}
                            color={'#8884d8'}
                            type={'balls'}
                            width={18}
                            height={18}
                          />
                        ) : (
                          usageCenter.summary.backup.total
                        )}
                      </span>{' '}
                      GB Hrs
                    </div>
                  </DetailBox>
                )}
                {showBackupAndAPIDataEgress && (
                  <DetailBox
                    className={cx(styles.summary, styles.summaryTraffic)}
                    highlight={currentTab === SummaryTypes.DataTraffic}
                    hover
                    onClick={() => {
                      gotoTab(SummaryTypes.DataTraffic);
                    }}
                  >
                    <Radio className={styles.radioBtn} checked={currentTab === SummaryTypes.DataTraffic}></Radio>
                    <Tag>{lang(t, `apiDataTraffic`)}</Tag>
                    <p className={styles.subTitleForSummary}>{lang(t, `subTitleForSummaryForNetwork`)}</p>
                    <div className={styles.detail}>
                      <span>
                        {isLoadingSummary ? (
                          <Loading
                            className={styles.summaryLoading}
                            color={'#facb72'}
                            type={'balls'}
                            width={18}
                            height={18}
                          />
                        ) : (
                          usageCenter.summary.dataTraffic.total
                        )}
                      </span>{' '}
                      GB
                    </div>
                  </DetailBox>
                )}
              </div>
              <Tabs
                className={styles.tabs}
                selectedTabId={currentTab}
                onChange={(key: SummaryTypes) => {
                  gotoTab(key);
                }}
              >
                <Tab id={SummaryTypes.CPU} title={lang(t, `CPU`)}></Tab>
                <Tab id={SummaryTypes.Storage} title={lang(t, `storage`)}></Tab>
                <Tab id={SummaryTypes.APIRequests} title={lang(t, `apiRequests`)}></Tab>
                {showBackupAndAPIDataEgress && <Tab id={SummaryTypes.Backup} title={lang(t, `backup`)}></Tab>}
                {showBackupAndAPIDataEgress && (
                  <Tab id={SummaryTypes.DataTraffic} title={lang(t, `apiDataTraffic`)}></Tab>
                )}
              </Tabs>
              <DetailBox>
                <Row className={styles.subTitle}>
                  <Col unit={12}>
                    <h4>
                      {currentTab === SummaryTypes.CPU && `${lang(t, `CPU`)} ${lang(t, 'usageSummary')}`}
                      {currentTab === SummaryTypes.Storage && `${lang(t, `storage`)} ${lang(t, 'usageSummary')}`}
                      {currentTab === SummaryTypes.APIRequests && `${lang(t, `apiRequests`)} ${lang(t, 'summary')}`}
                      {currentTab === SummaryTypes.Backup && `${lang(t, `backup`)} ${lang(t, 'usageSummary')}`}
                      {currentTab === SummaryTypes.DataTraffic &&
                        `${lang(t, `apiDataTraffic`)} ${lang(t, 'usageSummary')}`}
                    </h4>
                  </Col>
                  <Col unit={12} alignRight></Col>
                </Row>
                <Spin spinning={isLoadingDailyData}>
                  {!isLoadingDailyData && (
                    <ReactEcharts className={styles.chartBox} option={charOptDaily(t, chartData, currentTab)} />
                  )}
                </Spin>
              </DetailBox>

              <DetailBox>
                <Row className={styles.subTitle}>
                  <Col unit={12}>
                    <h4>
                      {currentTab === SummaryTypes.CPU && `${lang(t, `CPU`)} ${lang(t, 'usageDetails')}`}
                      {currentTab === SummaryTypes.Storage && `${lang(t, `storage`)} ${lang(t, 'usageDetails')}`}
                      {currentTab === SummaryTypes.APIRequests && `${lang(t, `apiRequests`)} ${lang(t, 'details')}`}
                      {currentTab === SummaryTypes.Backup && `${lang(t, `backup`)} ${lang(t, 'usageDetails')}`}
                      {currentTab === SummaryTypes.DataTraffic &&
                        `${lang(t, `apiDataTraffic`)} ${lang(t, 'usageDetails')}`}
                    </h4>
                  </Col>
                  <Col unit={12} alignRight></Col>
                </Row>
                <SearchTable
                  noDataMsg={lang(t, `noData`)}
                  isLoading={isLoading}
                  page={page}
                  defaultOrder={defaultOrder}
                  columns={columns[currentTab]}
                  {...list[currentTab]}
                  onCallback={(v) => {
                    const { page, where, order } = v;
                    setPage(page);
                    resetData(page, where, order);
                  }}
                />
              </DetailBox>
            </>
          )}
        </div>
        <Dialog
          isOpen={showExportDialog}
          // isOpen={true}
          title={lang(t, 'dialogTitle')}
          isCloseButtonShown={true}
          onClose={() => {
            setShowExportDialog(false);
          }}
        >
          <div className={cx(Classes.DIALOG_BODY, styles.dialogBody)}>
            <p>{lang(t, 'dialogSubTitle')}</p>
            <Checkbox
              checked={exportOption.all}
              label={lang(t, 'exportSelectAll')}
              onClick={() => {
                setExportOption({
                  all: !exportOption.all,
                  [SummaryTypes.CPU]: false,
                  [SummaryTypes.Storage]: false,
                  [SummaryTypes.APIRequests]: false,
                  [SummaryTypes.Backup]: false,
                  [SummaryTypes.DataTraffic]: false,
                });
              }}
            />
            <Checkbox
              checked={exportOption[SummaryTypes.CPU]}
              label={lang(t, 'exportSelectCPU')}
              disabled={!records[SummaryTypes.CPU]}
              onClick={() => {
                setExportOption({
                  ...exportOption,
                  all: false,
                  [SummaryTypes.CPU]: !exportOption[SummaryTypes.CPU],
                });
              }}
            />
            <Checkbox
              checked={exportOption[SummaryTypes.Storage]}
              label={lang(t, 'exportSelectStorage')}
              disabled={!records[SummaryTypes.Storage]}
              onClick={() => {
                setExportOption({
                  ...exportOption,
                  all: false,
                  [SummaryTypes.Storage]: !exportOption[SummaryTypes.Storage],
                });
              }}
            />
            <Checkbox
              checked={exportOption[SummaryTypes.APIRequests]}
              label={lang(t, 'exportSelectApiRequests')}
              disabled={!records[SummaryTypes.APIRequests]}
              onClick={() => {
                setExportOption({
                  ...exportOption,
                  all: false,
                  [SummaryTypes.APIRequests]: !exportOption[SummaryTypes.APIRequests],
                });
              }}
            />
            {showBackupAndAPIDataEgress && (
              <Checkbox
                checked={exportOption[SummaryTypes.Backup]}
                label={lang(t, 'exportSelectBackup')}
                disabled={!records[SummaryTypes.Backup]}
                onClick={() => {
                  setExportOption({
                    ...exportOption,
                    all: false,
                    [SummaryTypes.Backup]: !exportOption[SummaryTypes.Backup],
                  });
                }}
              />
            )}
            {showBackupAndAPIDataEgress && (
              <Checkbox
                checked={exportOption[SummaryTypes.DataTraffic]}
                label={lang(t, 'exportSelectDataTraffic')}
                disabled={!records[SummaryTypes.DataTraffic]}
                onClick={() => {
                  setExportOption({
                    ...exportOption,
                    all: false,
                    [SummaryTypes.DataTraffic]: !exportOption[SummaryTypes.DataTraffic],
                  });
                }}
              />
            )}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <SubmitButton
                loading={isExporting}
                disabled={
                  isExporting ||
                  (!exportOption.all &&
                    !exportOption[SummaryTypes.CPU] &&
                    !exportOption[SummaryTypes.Storage] &&
                    !exportOption[SummaryTypes.Backup] &&
                    !exportOption[SummaryTypes.DataTraffic] &&
                    !exportOption[SummaryTypes.APIRequests])
                }
                intent={Intent.PRIMARY}
                onClick={exportFile}
              >
                {lang(t, `exportButton`)}
              </SubmitButton>
            </div>
          </div>
        </Dialog>
      </MainBox>
    </PageLayout>
  );
});

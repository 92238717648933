import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './style.module.scss';
import { FormGroup } from '@blueprintjs/core';
import { ComponentHeader, DetailBox, FormError } from 'components';
import { useStore } from 'store';
import { useTranslation } from 'react-i18next';
import { lang } from './helper';
import { EditableField } from '@/components/EditableField';
import { ErrorToast, SuccessToast } from '@/components/Toast';
import { plans } from '@/configuration';
import { Typography, Input, Button } from 'antd';
import { EditOutlined, CopyOutlined } from '@ant-design/icons';
import cx from 'classnames';

const { Paragraph } = Typography;

export const Info: React.FC = observer(() => {
  const { workspaces } = useStore();
  const [inputOnfocus, setInputOnfocus] = useState(false);
  const [inputOnfocusTeam, setInputOnfocusTeam] = useState(false);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageTeam, setErrorMessageTeam] = useState('');
  const [newWsname, setNewWsname] = useState('');
  const [newTeamname, setNewTeamname] = useState('');

  useEffect(() => {
    setNewWsname(workspaces.current!.name);
    setNewTeamname(workspaces.current?.teamName !== null ? workspaces.current!.teamName : '');
  }, [workspaces.current]);

  const changeWsName = async () => {
    await workspaces.update(workspaces.current!, { name: newWsname.trim() });
    if (workspaces.state === 'error') {
      ErrorToast(workspaces.errorMessage!);
      return false;
    } else {
      SuccessToast(lang(t, 'information.updateNameSuccess'));
      setInputOnfocus(false);
      return true;
    }
  };

  const changeTeamName = async () => {
    await workspaces.updateTeamName(workspaces.current!, { teamName: newTeamname.trim() });
    if (workspaces.state === 'error') {
      ErrorToast(workspaces.errorMessage!);
      return false;
    } else {
      SuccessToast(lang(t, 'information.updateTeanNameSuccess'));
      setInputOnfocusTeam(false);
      return true;
    }
  };

  return (
    <DetailBox className={styles.radiusBox}>
      <ComponentHeader>
        <h3 className={styles.subTitle}>{lang(t, 'information.title')}</h3>
      </ComponentHeader>
      <h4>{lang(t, 'information.workspaceName')}</h4>
      <Input
        size="large"
        className={inputOnfocus ? styles.appNameInput : ''}
        value={inputOnfocus ? newWsname : workspaces.current!.name}
        suffix={<EditOutlined className={cx(styles.iconBlue)} style={{ color: '#031FEF' }} />}
        onChange={(e) => {
          setErrorMessage('');
          setNewWsname(e.target.value);
          if (e.target.value.trim() === '') {
            setErrorMessage('App Name required');
          }
        }}
        onFocus={() => {
          setInputOnfocus(true);
        }}
      />

      {inputOnfocus && (
        <div className={styles.changeNameBtn}>
          <Button
            onClick={() => {
              setErrorMessage('');
              setInputOnfocus(false);
              setNewWsname(workspaces.current!.name);
            }}
            size="large"
          >
            Cancel
          </Button>
          <Button onClick={changeWsName} type="primary" size="large">
            Save
          </Button>
        </div>
      )}
      <FormError>{errorMessage}</FormError>
      <br />
      <br />

      <h4>{lang(t, 'information.workspaceTeamName')}</h4>
      <Input
        size="large"
        placeholder={lang(t, 'information.UpdateTeamName')}
        className={inputOnfocusTeam ? styles.appNameInput : ''}
        value={
          inputOnfocusTeam ? newTeamname : workspaces.current?.teamName !== null ? workspaces.current?.teamName : ''
        }
        suffix={<EditOutlined className={cx(styles.iconBlue)} style={{ color: '#031FEF' }} />}
        onChange={(e) => {
          setErrorMessageTeam('');
          setNewTeamname(e.target.value);
          if (e.target.value.trim() === '') {
            setErrorMessageTeam('App Team Name required');
          }
        }}
        onFocus={() => {
          setInputOnfocusTeam(true);
        }}
      />

      {inputOnfocusTeam && (
        <div className={styles.changeNameBtn}>
          <Button
            onClick={() => {
              setErrorMessageTeam('');
              setInputOnfocusTeam(false);
              setNewTeamname(workspaces.current?.teamName !== null ? workspaces.current!.teamName : '');
            }}
            size="large"
          >
            Cancel
          </Button>
          <Button onClick={changeTeamName} type="primary" size="large">
            Save
          </Button>
        </div>
      )}
      <FormError>{errorMessageTeam}</FormError>
      <br />
      <br />

      <h4>{lang(t, 'information.workspaceId')}</h4>
      <Input
        size="large"
        value={workspaces.current!.id}
        disabled
        suffix={
          <Button
            className={styles.inputBtn}
            type="link"
            onClick={() => {
              navigator.clipboard.writeText(workspaces.current!.id);
              SuccessToast('Workspace ID Copied');
            }}
          >
            <CopyOutlined />
          </Button>
        }
      />
    </DetailBox>
  );
});

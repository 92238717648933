import React from 'react';
import { ArcGauge } from '@progress/kendo-react-gauges';
import styles from './style.module.scss';

interface IProp {
  start: string;
  end: string;
  value: number | undefined;
}

const colors = [
  {
    to: 50,
    color: '#47D764',
  },
  {
    from: 50,
    to: 75,
    color: '#FF952B',
  },
  {
    from: 75,
    color: '#FF4756',
  },
];

export const Metric: React.FC<IProp> = ({ start, end, value }) => {
  const val = value ? +value.toFixed(2) : 0;
  return (
    <div className={styles.container}>
      <ArcGauge
        style={{
          width: '200px',
          height: '120px',
        }}
        value={val}
        colors={colors}
        scale={{
          rangePlaceholderColor: '#F0F0F0',
          rangeSize: 14,
        }}
      />
      <div>
        <span className={styles.start}>{start}</span>
        <span className={styles.value}>{value?.toFixed(2)}%</span>
        <span className={styles.end}>{end}</span>
      </div>
    </div>
  );
};

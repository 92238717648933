import React, { useState } from 'react';
import { Tab, Tabs } from '@blueprintjs/core';
import { Typography, Input } from 'antd';
import { observer } from 'mobx-react';
import styles from '../style.module.scss';
import { lang, parseMetricsDetails } from '../helper';
import { Instance } from 'mobx-state-tree';
import { Node } from '@/store/nodes';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { MetricsTabContent } from './MetricsTabContent';
import { SubstrateRpcTabContent } from './SubstrateRpcTabContent';

const { Paragraph } = Typography;

interface IProps {
  node: Instance<typeof Node>;
}

export const ParachainApiSection: React.FC<IProps> = observer(({ node }) => {
  const RPCId = 'RPC';
  const { t } = useTranslation();
  const [tabId, setTabId] = useState(RPCId);

  const metricsDetails = parseMetricsDetails(node);

  return (
    <div className={styles.popoverBox}>
      <h2 className={styles.endpointTitle}>{lang(t, 'detailTitle.apiEndpoints')}</h2>
      <div className={styles.detailBoxContent}>
        <Tabs onChange={(tabId: string) => setTabId(tabId)} defaultSelectedTabId={RPCId}>
          <Tab id="RPC" title="RPC" />
          <Tab id="RPC-Relay" title="RPC Relay" />
          {(node.endpoints!['p2p-internal'] || node.endpoints!['p2p']) && <Tab title="P2P" id="P2P" />}
          {(node.endpoints!['p2p-relay-internal'] || node.endpoints!['p2p-relay']) && (
            <Tab title="P2P Relay" id="P2P-Relay" />
          )}
          <Tab id={'Metrics'} title={'Metrics'} />
          <Tab id={'Metrics-Relay'} title={'Metrics Relay'} />
        </Tabs>
        <SubstrateRpcTabContent
          wsEndpoint={node.endpoints?.ws}
          httpEndpoint={node.endpoints?.rpc}
          active={tabId === 'RPC'}
        />
        <SubstrateRpcTabContent
          wsEndpoint={node.endpoints?.['ws-relay']}
          httpEndpoint={node.endpoints?.['rpc-relay']}
          active={tabId === 'RPC-Relay'}
        />
        <div className={cx({ [styles.hide]: tabId !== 'P2P' })}>
          {node.endpoints!['p2p-internal'] && (
            <>
              <p className={styles.addressTitle}>{lang(t, 'detailTitle.p2p-internal')}</p>
              <Input
                size={'large'}
                disabled
                className={styles.apiKeyInput}
                suffix={
                  <Paragraph copyable className={styles.copyButton}>
                    {node.endpoints!['p2p-internal']}
                  </Paragraph>
                }
                defaultValue={node.endpoints!['p2p-internal']}
              />
            </>
          )}
          {node.endpoints!['p2p'] && (
            <>
              <p className={styles.addressTitle}>{lang(t, 'detailTitle.p2p')}</p>
              <Input
                size={'large'}
                disabled
                className={styles.apiKeyInput}
                suffix={
                  <Paragraph copyable className={styles.copyButton}>
                    {node.endpoints!['p2p']}
                  </Paragraph>
                }
                defaultValue={node.endpoints!['p2p']}
              />
            </>
          )}
        </div>
        <div className={cx({ [styles.hide]: tabId !== 'P2P-Relay' })}>
          <>
            {node.endpoints?.['p2p-relay-internal'] && (
              <>
                <p className={styles.addressTitle}>{lang(t, 'detailTitle.p2p-relay-internal')}</p>
                <Input
                  size={'large'}
                  disabled
                  className={styles.apiKeyInput}
                  suffix={
                    <Paragraph copyable className={styles.copyButton}>
                      {node.endpoints?.['p2p-relay-internal']}
                    </Paragraph>
                  }
                  defaultValue={node.endpoints?.['p2p-relay-internal']}
                />
              </>
            )}
            {node.endpoints?.['p2p-relay'] && (
              <>
                <p className={styles.addressTitle}>{lang(t, 'detailTitle.p2p-relay')}</p>
                <Input
                  size={'large'}
                  disabled
                  className={styles.apiKeyInput}
                  suffix={
                    <Paragraph copyable className={styles.copyButton}>
                      {node.endpoints?.['p2p-relay']}
                    </Paragraph>
                  }
                  defaultValue={node.endpoints?.['p2p-relay']}
                />
              </>
            )}
          </>
        </div>
        <MetricsTabContent
          visible={tabId === 'Metrics'}
          metricsDetails={metricsDetails}
          endpoint={node.endpoints?.['metrics']}
          endpointType="metrics-para"
        />
        <MetricsTabContent
          visible={tabId === 'Metrics-Relay'}
          metricsDetails={metricsDetails}
          endpoint={node.endpoints?.['metrics-relay']}
          endpointType="metrics-relay"
        />
      </div>
    </div>
  );
});

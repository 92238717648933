import { observer } from 'mobx-react';
import React from 'react';
import ReactEcharts from 'echarts-for-react';
import {
  ChartPropsNew,
  colourBlue,
  colourGreen,
  colourYellow,
  defaultChartOptions,
  generateTimeSeriesTimestampCustom,
} from '.';
import { chartDateLabelNew, nanoToMillisecondString } from '@/utils/tools';
import dayjs from 'dayjs';
import styles from './style.module.scss';
import { ReactComponent as NoResult } from './imgs/noResult.svg';
import { ReactComponent as NoResult2 } from './imgs/noResult2.svg';
import { times } from 'lodash';

type dataMap = { [key: number]: any };

type dataMapNew = { [key: string]: any };

const ResponseTimeChartNew: React.FC<ChartPropsNew> = observer(
  ({ chartData, loading, startTime, endTime, duration, twentyFourH }) => {
    if (!chartData) {
      return <></>;
    }

    let statsTimeMapOrigin: dataMap = chartData[Object.keys(chartData)[0]];

    let statsTimeMap: any = {};

    if (statsTimeMapOrigin) {
      Object.keys(statsTimeMapOrigin).forEach((e: string) => {
        statsTimeMap[
          dayjs(parseInt(e) * 1000)
            .utc()
            .format('YY-MM-DD HH:mm')
        ] = statsTimeMapOrigin[parseInt(e)];
      });
    } else {
      statsTimeMap = statsTimeMapOrigin;
    }

    const timeSeries = generateTimeSeriesTimestampCustom(startTime, endTime, true, duration);
    let dataMap: any = {};
    timeSeries.map((e) => {
      dataMap[dayjs(e * 1000).format('HH:mm')] = dayjs(e * 1000).format('DD/MM/YYYY HH:mm');
    });
    let series: any[] = [];
    const legendData: string[] = [];

    const colours: { [key: string]: string } = {
      average: colourBlue,
      response50th: colourGreen,
      response95th: colourYellow,
    };

    const methodSeries: { [key: string]: any[] } = { response50th: [], response95th: [], average: [] };
    if (statsTimeMap) {
      for (const time of timeSeries) {
        if (statsTimeMap) {
          let found =
            statsTimeMap[dayjs(time * 1000).format('YY-MM-DD HH:mm')] ||
            statsTimeMap[dayjs(time * 1000).format('YY-MM-DD')] ||
            {};
          Object.keys(methodSeries).forEach((x) => {
            // Convert to milliseconds
            methodSeries[x].push(found[x] ? nanoToMillisecondString(found[x]) : undefined);
          });
        }
      }
    }

    Object.keys(methodSeries).forEach((x) => {
      // Customise series name
      let name = `${x.replace(/response/g, '')}${x.includes('response') ? ' Percentile' : ''}`;
      name = name.charAt(0).toUpperCase() + name.slice(1);
      if (name === 'Average') {
        legendData.unshift(name);
        series.unshift({
          name,
          type: 'line',
          data: methodSeries[x],
          color: colours[x],
        });
      } else {
        legendData.push(name);
        series.push({
          name,
          type: 'line',
          data: methodSeries[x],
          color: colours[x],
        });
      }
    });

    const chartOptions = {
      tooltip: {
        trigger: 'axis',
        formatter: (param: any[]) => {
          let tooltip = `<div  class="chart-tooltip chart-median-response">`;
          tooltip += `<table>`;
          tooltip += `<tr><td colspan="3" class="header"style="color: #262626; font-size: 14px">Response Time Summary</td></tr>`;
          tooltip += `<tr><td colspan="3" class="date-time" style="color: #8C8C8C; font-size: 10px">${param[0].name}</td></tr>`;
          for (const node of param) {
            const { seriesName, value, color } = node;
            tooltip += `<tr>`;
            // Dot
            tooltip += `<td style='width: 12px'><div style="background:${color};width:12px;height:12px;border-radius:2px">&nbsp;</div></td>`;
            // Label
            tooltip += `<td style="color: #262626; font-size: 12px; width: 150px;">${
              seriesName === 'Average' ? 'Average' : seriesName
            }</td>`;
            // Value
            tooltip += `<td class="figure" style="color: #262626; font-size: 12px">${
              value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '--'
            } ms</td>`;
            tooltip += `</tr>`;
          }
          tooltip += `</table>`;
          tooltip += `</div>`;
          return tooltip;
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      legend: {
        data: legendData,
        left: '0%',
      },
      calculable: true,
      xAxis: {
        data: timeSeries.map((x) => chartDateLabelNew(x * 1000, '24h', true)),
        axisLine: {
          lineStyle: {
            color: '#8c8c8c',
          },
          show: true,
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#8c8c8c',
          },
        },
        axisLabel: {
          formatter: '{value}ms',
        },
      },
      series,
    };

    return (
      <div className={styles.positionR}>
        {!statsTimeMap ? (
          twentyFourH ? (
            <div className={styles.noDataDiv}>
              <NoResult className={styles.noResult} />
            </div>
          ) : (
            <div className={styles.noDataDiv}>
              <NoResult2 className={styles.noResult} />
            </div>
          )
        ) : (
          ''
        )}
        <ReactEcharts showLoading={loading} option={chartOptions} {...defaultChartOptions} />
      </div>
    );
  },
);

export default ResponseTimeChartNew;

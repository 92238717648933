import dayjs from 'dayjs';

export const UTCDate = (utc_datetime: string) => {
  return utc_datetime.split('T')[0] + ' ' + utc_datetime.split('T')[1].split('.')[0];
};

export const pickOutError = (err: any) => {
  if (err.response?.data?.error) {
    const errorObj = err.response?.data?.message;
    if (errorObj instanceof Array) {
      return errorObj.join(',');
    } else if (typeof errorObj === 'string') return errorObj;
  } else if (err.response?.data?.message) {
    return err.response.data.message;
  } else if (err.description) {
    return err.description;
  } else if (err.error_description) {
    return err.error_description;
  } else {
    return err.message;
  }
};

export const durationToHourCount = (duration?: string) => {
  switch (duration) {
    case '24h':
      return 24;
    case '7d':
      return 7 * 24;
    case '30d':
      return 30 * 24;
    default:
      return 0;
  }
};

export const chartDateLabel = (date: Date, duration?: string) => {
  const hourCount = durationToHourCount(duration);
  return hourCount > 24
    ? //date.toLocaleDateString([], { hour12: false } as any)
      dayjs(date).utc().format('DD/MM/YYYY')
    : //date.toLocaleTimeString([], { timeStyle: 'short', hour12: false } as any);
      dayjs(date).utc().format('HH:mm');
};

export const chartDateLabelNew = (date: number, duration?: string, responseTime?: boolean) => {
  const hourCount = durationToHourCount(duration);
  return hourCount > 24
    ? //date.toLocaleDateString([], { hour12: false } as any)
      dayjs(date).format('DD/MM/YYYY')
    : responseTime
    ? dayjs(date).format('DD/MM HH:mm')
    : dayjs(date).format('HH:mm');
};

export const chartLoadingOptions = {
  text: '',
  color: '#3f57f5',
  lineWidth: 3,
  spinnerRadius: 15,
  zlevel: 0,
};

export const nanoToMillisecondString = (nano: number) => (nano / 1000000).toFixed(2);

export const numberAndUnit = (value: number | undefined | null) => {
  if (!value) return '0';
  let unit = 'M';
  let times = 1000 * 1000;
  if (value < 1000) {
    times = 1;
    unit = '';
  } else if (value < 1000 * 1000) {
    times = 1000;
    unit = 'K';
  } else if (value > 1000 * 1000 * 1000) {
    times = 1000 * 1000 * 1000;
    unit = 'B';
  }
  return `${Math.round((value / times) * 10) / 10}${unit}`;
};

export const isValidIP = (ip: string) => {
  var reg =
    /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
  return reg.test(ip);
};

import { countries } from 'country-data';
import React, { useState } from 'react';
import { Select, Divider, Space, Input, Row, Col, Menu, Dropdown, Button } from 'antd';
import { ItemPredicate, ItemRenderer } from '@blueprintjs/select';
import styles from './style.module.scss';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import cx from 'classnames';

const waitTime = 300;

interface Country {
  key: number;
  code: string;
  name: string;
}

const data = countries.all.map((c, idx) => ({
  key: idx,
  code: c.alpha2,
  name: c.name,
}));

const filterObj = (obj: string, query?: string) => {
  if (!query) return true;
  return `${obj.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const CountrySelect: React.FC<IProps> = ({ value, onChange }) => {
  const val = data.find((c) => c.code === value);
  const [selected, setSelected] = useState(val);
  const [filterData, setFilterData] = useState(data);
  const changeValue = _.debounce((v: string | undefined) => {
    setFilterData(data.filter((i: Country) => filterObj(i.name, v)));
  }, waitTime);

  return (
    <Dropdown
      overlay={
        <Menu>
          <div className={styles.searchInput}>
            <Input
              placeholder="Search by key words"
              onChange={(e) => {
                changeValue(e.target.value);
              }}
              suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
            />
          </div>

          <Menu.Divider />
          <div className={styles.countryMenuLimit}>
            {filterData.map((i: Country) => (
              <Menu.Item
                key={i.key}
                onClick={() => {
                  setSelected(i);
                  onChange && onChange(i.code);
                }}
              >
                <Row>
                  <Col flex="auto">{i.name}</Col>
                  <Col flex="30px" className={styles.alignRight}>
                    {i.code}
                  </Col>
                </Row>
              </Menu.Item>
            ))}
          </div>
        </Menu>
      }
      trigger={['click']}
      placement={'topCenter'}
    >
      <Button size={'large'} className={styles.button} block onClick={(e) => e.preventDefault()}>
        <Row>
          <Col flex="auto" className={styles.alignLeft}>
            {selected?.name}
          </Col>
          <Col className={cx(styles.verticalMiddle, styles.alignRight)}>
            <DownOutlined />
          </Col>
        </Row>
      </Button>
    </Dropdown>
  );
};

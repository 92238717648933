import React, { useEffect, useState } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import { useStore } from '@/store';
import { observer } from 'mobx-react';
import { AnonymousLayout, ErrorToast } from '@/components';
import { RegisteredForm } from './forms/RegisteredForm';
import styles from '@/pages/Login/style.module.scss';
import { SignUpForm } from './forms/SignupForm';
interface IProps extends RouteConfigComponentProps {}

export const SignUp: React.FC<IProps> = observer(({ history }) => {
  const { auth } = useStore();
  const [view, setView] = useState('signup');
  useEffect(() => {
    if (auth.state === 'error' && auth.errorMessage) {
      ErrorToast(auth.errorMessage!);
    }
  }, [auth.state]);

  return (
    <AnonymousLayout>
      <div className={styles.loginBox}>
        {view === 'signup' && (
          <SignUpForm onSubmit={() => setView('registered')} gotoLogin={() => history.push('/login')} />
        )}
        {view === 'registered' && <RegisteredForm onBack={() => history.push('/login')} />}
      </div>
    </AnonymousLayout>
  );
});

import { TFunction } from 'i18next';
import { observer } from 'mobx-react';
import { RouteConfigComponentProps } from 'react-router-config';
import React, { useEffect, useState } from 'react';
import { useStore } from '@/store';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Row, Col, MainBox, ButtonLink } from 'components';
import { getUrls } from 'modules';
import { Link } from 'react-router-dom';
import { Card, Button, Progress, Row as AntRow, Col as AntCol, Tooltip, Alert, Collapse } from 'antd';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ApiIcon } from './imgs/ApiIcon.svg';
import { ReactComponent as UpgradeIcon } from './imgs/UpgradeIcon.svg';
import { ReactComponent as WarningIcon } from './imgs/ExclamationCircle.svg';
import { ReactComponent as DedicatedNodeIcon } from './imgs/DedicatedNodeIcon.svg';
import { ReactComponent as NetworkIcon } from './imgs/NetworksIcon.svg';
import { ReactComponent as UsageCenterIcon } from './imgs/UsageCentreIcon.svg';
import { ReactComponent as ExternalLink } from './imgs/externalLink.svg';
import styles from './style.module.scss';
import { numberAndUnit } from '@/utils/tools';
import { Colors, percentage } from './helpers';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import { CreateApp } from '../ApiServices/createApp';

const { Panel } = Collapse;

dayjs.extend(dayjsPluginUTC as any, { parseToLocal: true });

type IProps = RouteConfigComponentProps<{ wsId: string }>;

const lastDay = (billingDay: number | null) => {
  if (!billingDay) return '--';
  const hasNextMonth = billingDay < +dayjs().format('DD');
  let endDate;
  if (hasNextMonth) {
    const endOfNextMonth = +dayjs().add(1, 'month').endOf('month').format('DD');
    endDate = dayjs()
      .add(1, 'month')
      .startOf('month')
      .add((endOfNextMonth < billingDay ? endOfNextMonth : billingDay) - 1, 'day');
  } else {
    const endOfThisMonth = +dayjs().endOf('month').format('DD');
    endDate = dayjs()
      .startOf('month')
      .add((endOfThisMonth < billingDay ? endOfThisMonth : billingDay) - 1, 'day');
  }
  return dayjs(endDate).diff(dayjs(), 'day') + 1;
};

const gridStyle: React.CSSProperties = {
  width: '100%',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
};

const gridStyleVideo: React.CSSProperties = {
  width: '100%',
  padding: '2px 8px 0px 8px',
};

export const Dashboard: React.FC<IProps> = observer(({ match }) => {
  const { t } = useTranslation();
  const wsId = match.params.wsId;
  const lang = (t: TFunction, key: string, opt?: any) => t(`pages.welcome.${key}`, opt);
  const { workspaces, apiService, usageCenter, plan, nodes } = useStore();
  const [isLoadinglimit, setIsLoadinglimit] = useState(true);
  const history = useHistory();
  const planIsMonthPayment = plan.currentPlan?.price?.month === true;
  const progressValue = planIsMonthPayment
    ? percentage(apiService.totalUsage, usageCenter.limit?.apiServiceLimit)
    : percentage(apiService.limit?.weightedUsage, usageCenter.limit?.apiServiceLimit);

  const nextBillingDay = planIsMonthPayment
    ? lastDay(workspaces!.current!.billingDay) + ' days'
    : (dayjs(dayjs().utc().add(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')).diff(
        dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss')),
        'hours',
      ) %
        24) +
      1 +
      ' Hours';

  const progressColor = () => {
    if (progressValue >= 100) {
      return !planIsMonthPayment ? Colors.ERROR : Colors.WARNING;
    } else if (!planIsMonthPayment && progressValue >= 90) return Colors.WARNING;
    else return Colors.PRIMARY;
  };

  useEffect(() => {
    (async () => {
      setIsLoadinglimit(true);
      await Promise.all([
        apiService.getLimit(wsId),
        plan.fetch(),
        apiService.getApps(wsId),
        workspaces.fetchDetail(wsId),
      ]);
      setIsLoadinglimit(false);
      await nodes.fetchList(match.params.wsId);
    })();
  }, [workspaces.current?.id]);

  //@ts-ignore
  useEffect(() => {
    return async () => {
      await apiService.clearApps();
      await nodes.clearCache();
    };
  }, []);

  return (
    <MainBox className={styles.relativePosition}>
      {workspaces.cutoffs.length > 0 && workspaces.current?.plan === 'developer' && (
        <Alert
          className={styles.warningBanner}
          showIcon
          type="error"
          closable
          message={
            <span>
              You have exceeded your free plan limit and your API key has been blocked. Please{' '}
              <Link to={`/workspaces/${wsId}/settings/plan`}>{lang(t, 'upgradeASAP')}</Link> to restore service.
            </span>
          }
        />
      )}
      <h2>👋 {lang(t, 'welcomeTitle')} </h2>
      <div>
        <Card
          className={styles.mb24}
          title={
            <div className={styles.cardTitle}>
              <ApiIcon /> {lang(t, 'apiService')}
            </div>
          }
          loading={isLoadinglimit}
          extra={
            apiService.apps.length > 0 ? (
              <Button
                onClick={() => {
                  history.push(`/workspaces/${wsId}/apiservices`);
                }}
                size="middle"
                type="primary"
              >
                {lang(t, 'viewApps')}
              </Button>
            ) : (
              ''
            )
          }
        >
          {apiService.apps.length === 0 ? (
            <div>
              <Row className={styles.mb16}>
                <Col>{lang(t, 'apiSubtitle')}</Col>
              </Row>
              <Row>
                <CreateApp description={true} size="middle" wsId={wsId} text={lang(t, 'connectToAPIService')} />
                <Button
                  className={styles.defaultBtn}
                  onClick={() => {
                    history.push(`/workspaces/${wsId}/apiservices/all-networks`);
                  }}
                >
                  {lang(t, 'exploreAllNetworks')}
                </Button>
              </Row>
            </div>
          ) : (
            <div>
              {workspaces.current?.plan === 'developer' ? (
                <div className={styles.flexBetween}>
                  <div className={styles.planName}>{workspaces.current?.plan} Plan</div>
                  <ButtonLink
                    onClick={() => {
                      history.push(`/workspaces/${wsId}/settings/plan`);
                    }}
                  >
                    <UpgradeIcon className={styles.mr8} />
                    {lang(t, 'upgradePlan')}
                  </ButtonLink>
                </div>
              ) : (
                <div className={styles.flexBetween}>
                  <div className={styles.planName}>{workspaces.current?.plan} Plan</div>
                </div>
              )}

              <div>
                <span className={cx(styles.responseUnitNumber, styles.normalBlue)}>
                  {planIsMonthPayment
                    ? numberAndUnit(apiService.totalUsage)
                    : apiService.limit?.weightedUsage.toLocaleString()}
                </span>
                <span className={styles.responseUnitText}>
                  {planIsMonthPayment ? lang(t, 'monthlyResponseUnits') : lang(t, 'todayResponseUnits')}
                </span>
              </div>
              <div>
                <AntRow>
                  <AntCol span={progressValue >= 100 ? 23 : 24}>
                    <Progress percent={progressValue} strokeColor={progressColor()} showInfo={false} />
                  </AntCol>
                  {progressValue > 100 && (
                    <AntCol span={1} className={styles.warningIcon}>
                      <Tooltip
                        placement="topRight"
                        title={
                          planIsMonthPayment
                            ? 'Plan’s included responses units have been used, overage rates now apply.'
                            : 'You have exceeded your free plan’s daily Response Unit limit. Upgrade plan to avoid disruption.'
                        }
                      >
                        <WarningIcon className={cx(planIsMonthPayment ? styles.warningColor : styles.errorColor)} />
                      </Tooltip>
                    </AntCol>
                  )}
                </AntRow>
              </div>
              <div className={styles.limitAndBillingDay}>
                {!usageCenter.limit?.apiServiceLimit
                  ? lang(t, 'unlimitedResponseUnits')
                  : `${numberAndUnit(
                      usageCenter.limit?.apiServiceLimit,
                    )} Responses units | Responses limit resets in ${nextBillingDay}`}
              </div>
            </div>
          )}
        </Card>

        <div className={styles.threeCard}>
          <Card
            className={styles.typeCard}
            title={
              <div className={styles.cardTitle}>
                <DedicatedNodeIcon /> {lang(t, 'dedicatedNode')}
              </div>
            }
          >
            <div className={styles.typeCardText}>{lang(t, 'dedicatedSubtitle')}</div>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                history.push(`/workspaces/${wsId}/nodes`);
              }}
              className={styles.typeButton}
            >
              {nodes.list.length > 0 ? lang(t, 'viewNodesDetails') : lang(t, 'deployNode')}
            </Button>
          </Card>
          <Card
            className={styles.typeCard}
            title={
              <div className={styles.cardTitle}>
                <NetworkIcon /> {lang(t, 'networkManagement')}
              </div>
            }
          >
            <div className={styles.typeCardText}>{lang(t, 'networkSubtitle')}</div>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                history.push(`/workspaces/${wsId}/networks`);
              }}
              className={styles.typeButton}
            >
              {lang(t, 'viewNetworkSpecs')}
            </Button>
          </Card>
          <Card
            className={styles.typeCard}
            title={
              <div className={styles.cardTitle}>
                <UsageCenterIcon /> {lang(t, 'usageCentre')}
              </div>
            }
          >
            <div className={styles.typeCardText}>{lang(t, 'usageSubtitle')}</div>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                history.push(`/workspaces/${wsId}/usageCenter`);
              }}
              className={styles.typeButton}
            >
              {lang(t, 'viewUsage')}
            </Button>
          </Card>
        </div>

        <div className={styles.introduction}>
          <Card
            title={<div className={styles.cardTitle}>{lang(t, 'needHelp')} </div>}
            extra={
              <a href="https://documentation.onfinality.io/support/" target={'_blank'}>
                {lang(t, 'viewMore')}
              </a>
            }
          >
            <Card.Grid hoverable={false} style={gridStyleVideo}>
              <Collapse bordered={false} expandIconPosition={'right'} ghost>
                <Panel key={1} header={'How to create a Private API Endpoint'}>
                  <p className={styles.introSubtitle}>
                    This video teaches you how to create a private API endpoint for your app, and connect to your
                    favorite networks like Astar or Moonbeam in a few clicks!
                  </p>
                  <iframe
                    width="100%"
                    height="600"
                    src="https://www.youtube.com/embed/-bBQcM7JVEY?vq=hd720p"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Panel>
              </Collapse>
            </Card.Grid>
            <Card.Grid hoverable={false} style={gridStyle}>
              <div className={styles.publicWeb}>
                <a href="https://documentation.onfinality.io/support/deploy-a-dedicated-node" target={'_blank'}>
                  How to deploy a Dedicated Node
                </a>
                <ExternalLink />
              </div>
            </Card.Grid>
            <Card.Grid hoverable={false} style={gridStyle}>
              <div className={styles.publicWeb}>
                <a href="https://documentation.onfinality.io/support/create-a-network-on-onfinality" target={'_blank'}>
                  How to create a Custom Network
                </a>
                <ExternalLink />
              </div>
            </Card.Grid>
            <Card.Grid hoverable={false} style={gridStyle}>
              <div className={styles.publicWeb}>
                <a
                  href="https://documentation.onfinality.io/support/integrate-onfinality-into-your-ci-cd-pipeline-subs"
                  target={'_blank'}
                >
                  How to integrate OnFinality into your CI/CD Pipeline
                </a>
                <ExternalLink />
              </div>
            </Card.Grid>
          </Card>
        </div>
      </div>
    </MainBox>
  );
});

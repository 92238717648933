import React from 'react';
import styles from './style.module.scss';

interface IProps {
  message?: string;
}

export const ErrorHint: React.FC<IProps> = ({ message }) => {
  return message ? <p className={styles.error}>{message}</p> : null;
};

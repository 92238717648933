import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import styles from './style.module.scss';
import cx from 'classnames';
import { Button } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface IProps {
  className?: string;
  onClick?: () => void;
  size?: SizeType;
  disabled?: boolean;
}
export const ButtonNoBorder: React.FC<IProps> = observer(
  ({ className, onClick, children, size = 'middle', disabled = false }) => {
    return (
      <Button
        className={cx(styles.btn, className ? className : '')}
        onClick={onClick}
        size={size}
        type="text"
        disabled={disabled}
      >
        {children}
      </Button>
    );
  },
);

import { TFunction } from 'i18next';
import { DataMapItem, RuleAction } from '@/components/UpdateNetworkRules/helpers';
import {
  LaunchConfigurationComArgRules,
  LaunchConfigurationValueType,
  LaunchDataMapType,
} from '@/components/Preview/help';
import { tempSection } from '@/components/Preview/help';

export interface ArgDataFormat {
  action?: string;
  payload: {
    key: string;
    value: {
      payload: string;
      inputType: string;
    };
    options: {
      overwritable: boolean;
    };
    category: string;
  };
}

export interface NetworkFormData {
  comArgRules: {
    [key: string]: {
      data: Array<DataMapItem>;
      extraArgData: Array<string | undefined>;
      extraArgKey: string;
      index: number;
      title: string;
    };
  };
  delimiter: string;
  envRules: Array<any>;
  envExtra: Array<any>;
}

export interface Operations {
  arg: Array<ArgDataFormat>;
  var: {
    action?: RuleAction;
    payload: {
      key: string;
      value: {
        payload: {
          source: string;
          destination: string;
        };
        valueType: string;
      };
      options: {
        overwritable: boolean;
      };
      category: string;
    };
  }[];
}

export const createNewHumpKey = (key: string | null, sectionKey?: string) => {
  if (!key) return '';
  return `${key.replace('--', '')}_${sectionKey || ''}`.toUpperCase();
};

export const transformArg = (data: LaunchConfigurationComArgRules, protocolRulesArg?: any, create?: boolean) => {
  const result = [] as any;
  Object.keys(data).forEach((sectionKey: string) => {
    data[sectionKey].data.forEach((item: DataMapItem) => {
      const { action, humpKey, key, overwritable, payloadKey, originalOverwritable, valueType } = item;
      if (originalOverwritable === undefined && !overwritable) return;
      if (valueType === 'context' && action !== RuleAction.REMOVE && create !== true) return;
      if (!key) return;
      const newHumpKey = createNewHumpKey(key, sectionKey || tempSection + '');
      result.push({
        action: action || RuleAction.UPSERT,
        payload: {
          key: key,
          value: {
            payload: payloadKey || `${valueType === 'context' ? 'context' : 'var'}.${humpKey || newHumpKey}`,
            inputType: 'variable',
          },
          options: {
            overwritable: overwritable,
          },
          category: 'arg',
          section: +sectionKey === tempSection ? undefined : +sectionKey,
        },
      });
    });
  });
  const lastResult = [] as any;
  if (protocolRulesArg) {
    result.forEach((i: any) => {
      const isExit = protocolRulesArg.find((item: any) => item.value.payload === i.payload.value.payload);
      const alreadyDeleted = protocolRulesArg.find(
        (item: any) => item.action === RuleAction.REMOVE && item.value.payload === i.payload.value.payload,
      );
      // if (isExit && !isExit.options.overwritable) return;
      if (alreadyDeleted) return;
      if (i.action === RuleAction.REMOVE) {
        isExit && lastResult.push(i);
      } else {
        lastResult.push(i);
      }
    });
  }
  return protocolRulesArg ? lastResult : result;
};

export const transformVar = (comArgRules: any, envRules: any, protocolRulesVar?: any, create?: boolean) => {
  const result = [] as any;
  Object.keys(comArgRules).forEach((sectionKey: string) => {
    comArgRules[sectionKey].data.forEach((dataItem: any) => {
      const { action, humpKey, key, overwritable, payload, section, valueType, originalOverwritable } = dataItem;
      if (originalOverwritable === undefined && !overwritable) return;
      if (valueType === LaunchConfigurationValueType.SystemContext && action !== RuleAction.REMOVE && create !== true)
        return;
      if (!key && !humpKey) return;
      const newHumpKey = createNewHumpKey(key, sectionKey || tempSection + '');
      result.push({
        action: action || RuleAction.UPSERT,
        payload: {
          key: humpKey || newHumpKey || key,
          value: {
            payload: payload,
            valueType: valueType,
          },
          options: {
            overwritable: overwritable,
          },
          category: 'var',
          section: +sectionKey === tempSection ? undefined : +sectionKey,
        },
      });
    });
  });
  envRules.forEach((i: any) => {
    const { action, humpKey, key, overwritable, payloadKey, payload, valueType } = i;
    if (!key) return;
    const newHumpKey = createNewHumpKey(key);
    result.push({
      action: action || RuleAction.UPSERT,
      payload: {
        key: humpKey || newHumpKey,
        value: {
          payload: payload,
          valueType: valueType,
        },
        options: {
          overwritable: overwritable,
        },
        category: 'var',
      },
    });
  });
  const lastResult = [] as any;
  if (protocolRulesVar) {
    result.forEach((i: any) => {
      const isExit = protocolRulesVar.find((item: any) => item.key === i.payload.key);
      // if (isExit && !isExit.options.overwritable) return;
      const alreadyDeleted = protocolRulesVar.find(
        (item: any) => item.action === RuleAction.REMOVE && item.value.payload === i.payload.value.payload,
      );
      if (alreadyDeleted) return;
      if (i.action === RuleAction.REMOVE) {
        isExit && lastResult.push(i);
      } else {
        lastResult.push(i);
      }
    });
  }
  return protocolRulesVar ? lastResult : result;
};

export const transformEnv = (data: any, protocolRulesEnv?: any, create?: boolean) => {
  const result = [] as any;
  data.forEach((i: any) => {
    const { action, humpKey, key, overwritable, payloadKey, valueType } = i;
    const newHumpKey = createNewHumpKey(key);
    // if (!overwritable) return;
    if (!key && !humpKey && !newHumpKey) return;
    result.push({
      action: action || RuleAction.UPSERT,
      payload: {
        key: key || humpKey || newHumpKey,
        value: {
          payload: payloadKey || `${valueType === 'context' ? 'context' : 'var'}.${humpKey || newHumpKey}`,
          inputType: 'variable',
        },
        options: {
          overwritable: overwritable,
        },
        category: 'env',
      },
    });
  });

  const lastResult = [] as any;
  if (protocolRulesEnv) {
    // if hase protocolRules, when the rules not has this item, don't put it in the result
    result.forEach((i: any) => {
      const isExit = protocolRulesEnv.find((item: any) => item.key === i.payload.value.payload.split('.')[1]);
      // if (isExit && !isExit.options.overwritable) return;
      const alreadyDeleted = protocolRulesEnv.find(
        (item: any) => item.action === RuleAction.REMOVE && item.value.payload === i.payload.value.payload,
      );
      if (alreadyDeleted) return;
      if (i.action === RuleAction.REMOVE) {
        isExit && lastResult.push(i);
      } else {
        lastResult.push(i);
      }
    });
  } else {
    // if not hase protocolRules, don't put any remove item in the result
    result.forEach((i: any) => {
      if (i.action !== RuleAction.REMOVE) {
        lastResult.push(i);
      }
    });
  }
  return lastResult;
};

export const lang = (t: TFunction, key: string, options?: any) => t(`pages.createNetwork.${key}`, options);

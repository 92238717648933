import { cast, flow, Instance, types } from 'mobx-state-tree';
import { api } from '../utils/api';
import { NetworkSpecStatus, Recommend, NodeType } from './networks';
import { pickOutError } from '@/utils/tools';
import { mxConfig } from '@/config';

export const Recommand = types.model({
  client: types.maybeNull(types.string),
  createdAt: types.string,
  id: types.number,
  imageVersion: types.string,
  networkKey: types.string,
  nodeSpecKey: types.string,
  nodeType: types.string,
  storageSize: types.number,
  storageType: types.string,
  updatedAt: types.string,
});

export const Image = types.model({
  active: types.boolean,
  client: types.maybeNull(types.string),
  createdAt: types.string,
  id: types.number,
  image: types.string,
  imageRepository: types.string,
  protocolKey: types.maybe(types.string),
  status: types.string,
  supportedPublicNetworks: types.array(types.string),
  updatedAt: types.string,
  version: types.string,
});

export const Usage = types.model({
  count: types.number,
  time: types.string,
});

export const oneChartData = types.model({
  TotalCount: types.number,
  cluster: types.string,
  usage: types.array(Usage),
  clusterDetail: types.maybe(
    types.model({
      name: types.string,
      region: types.string,
    }),
  ),
});

export const Sumary = types.model({
  TotalCount: types.number,
  cluster: types.string,
  usage: types.array(Usage),
  clusterDetail: types.maybe(
    types.model({
      name: types.string,
      region: types.string,
    }),
  ),
});

export const NetworkRecommand = types.model({
  imageVersion: types.string,
  nodeSpecKey: types.string,
  nodeSpecMultiplier: types.maybeNull(types.number),
  nodeType: types.string,
  storageSize: types.maybe(types.number),
  storageType: types.string,
});

export const NetworkLogo = types.model({
  icon: types.maybe(types.string),
  large: types.maybe(types.string),
  small: types.maybe(types.string),
});

export const BootnodeType = types.model({
  address: types.maybe(types.string),
  nodeId: types.maybe(types.string),
});

export const NetworkMetadata = types.model({
  command: types.maybe(types.string),
  description: types.maybe(types.string),
  pictures: types.optional(NetworkLogo, {}),
  chainspec: types.maybe(types.string),
  imageVersion: types.maybe(types.string),
  bootnodes: types.maybe(types.frozen()),
  extraArgs: types.maybe(types.optional(types.array(types.string), [])),
});

export const NetworkSpecType = types.model({
  displayName: types.maybe(types.string),
  imageRepository: types.maybe(types.string),
  key: types.maybe(types.string),
  metadata: types.maybe(NetworkMetadata),
  name: types.maybe(types.string),
  protocol: types.maybe(types.string),
});

export const NetworkForm = types.model({
  networkSpecExtraInfo: types.frozen(),
  recommends: types.maybe(types.optional(types.array(NetworkRecommand), [])),
  networkSpec: NetworkSpecType,
  createApiService: types.maybe(types.boolean),
  // networkSpecExtraInfo: any
});

export const PromoteNetworks = types.model({
  accountName: types.string,
  createdAt: types.string,
  form: NetworkForm,
  id: types.number,
  operateAccountName: types.string,
  reason: types.maybeNull(types.string),
  status: types.string,
  updatedAt: types.string,
});
export const ReportNetworkType = types.model({
  displayName: types.string,
  key: types.string,
  subDomain: types.string,
  metadata: types.model({
    pictures: types.model({
      icon: types.string,
      large: types.string,
      small: types.string,
    }),
  }),
});

const NetworkRequestByCountry = types.model({
  resultData: types.frozen(),
  totalRequest: types.number,
});

export type INetworkRequestByCountry = Instance<typeof NetworkRequestByCountry>;

export const NetworkType = types.model({
  // displayName: types.string,
  // imageRepository: types.string,
  // key: types.string,
  // metadata: NetworkMetadata,
  // name: types.string,
  // nodeTypes: types.array(types.string),
  // protocolKey: types.string,
  // status: types.string,
  // workspaceId: types.maybeNull(types.string),
  key: types.string,
  name: types.string,
  displayName: types.string,
  protocolKey: types.string,
  isPublic: types.boolean,
  imageRepository: types.string,
  workspaceId: types.maybeNull(types.string),
  status: types.enumeration(Object.values(NetworkSpecStatus)),
  versions: types.optional(types.array(types.string), []),
  metadata: types.frozen(),
  recommend: types.maybeNull(Recommend),
  nodeTypes: types.optional(types.array(NodeType), []),
});

export const PrivateNetworkSpecType = types.model({
  displayName: types.string,
  imageRepository: types.string,
  key: types.string,
  name: types.string,
  metadata: types.model({
    chainspec: types.maybe(types.string),
    imageVersion: types.maybe(types.string),
  }),
  nodeTypes: types.maybe(types.array(types.string)),
  protocolKey: types.string,
  status: types.string,
  workspaceId: types.maybeNull(types.string),
});

export const NetworkSpec = types
  .model({
    state: types.optional(types.enumeration(['pending', 'done', 'error']), 'pending'),
    error: types.maybeNull(types.string),
    images: types.optional(types.array(Image), []),
    recommands: types.optional(types.array(Recommand), []),
    networks: types.optional(types.array(ReportNetworkType), []),
    list: types.optional(types.array(NetworkType), []),
    promoteNetworks: types.optional(types.array(PromoteNetworks), []),
    privateNetworks: types.optional(types.array(PrivateNetworkSpecType), []),
    current: types.maybeNull(types.string),
    summary: types.maybeNull(types.array(Sumary)),
    errorMessage: types.maybe(types.string),
    requestByCountry: types.maybeNull(NetworkRequestByCountry),
  })
  .views((self) => ({
    get userNetworks() {
      return self.list.filter((n) => !n.isPublic);
    },
    get enabledNetworks() {
      const networkList: Instance<typeof NetworkType>[] = [];
      self.list.forEach((item) => {
        const {
          featureFlags: { showUserNetworkSpec },
          networkWhiteList,
        } = mxConfig;
        if (
          item.status === 'enabled' &&
          (showUserNetworkSpec || item.isPublic) &&
          (!networkWhiteList || networkWhiteList.includes(item.key))
        ) {
          networkList.push(item);
        }
      });
      return networkList;
    },
  }))
  .actions((self) => ({
    fetchNetworks: flow(function* (pId) {
      if (!pId) return;
      self.state = 'pending';
      try {
        self.networks = yield api.get(`/partners/${pId}/report/networks`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    upsertImages: flow(function* (pId, data: any) {
      self.state = 'pending';
      try {
        yield api.post(`/partners/${pId}/images`, data);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    clearRecommands: () => {
      self.recommands = cast([]);
    },
    fetchRecommands: flow(function* (pId, network) {
      self.state = 'pending';
      try {
        self.recommands = yield api.get(`/partners/${pId}/recommends/${network}`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    clearImages: () => {
      self.images = cast([]);
    },
    fetchImages: flow(function* (pId: string | null | undefined) {
      if (!pId) return null;
      self.state = 'pending';
      try {
        self.images = yield api.get(`/partners/${pId}/images`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    fetchSummary: flow(function* (pId, network = '', startTime?: string, endTime?: string) {
      self.state = 'pending';
      self.summary = null;
      try {
        const { data } = yield api.get(
          `/partners/${pId}/report/summary?startTime=${startTime}&endTime=${endTime}&network=${network}`,
        );
        self.summary = data && data[0]?.data;
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        const msg = pickOutError(err);
      }
    }),
    fetchRequestByCountry: flow(function* (network, startTime: string, endTime: string, wsId: string, appId?: string) {
      self.state = 'pending';
      try {
        const url = appId
          ? `${mxConfig.apiUrlV2}/workspaces/${wsId}/apps/${appId}/networks/${network}/country-request?startTime=${startTime}&endTime=${endTime}&network=${network}`
          : `/partners/${wsId}/report/country-request?startTime=${startTime}&endTime=${endTime}&network=${network}`;
        self.requestByCountry = yield api.get(url);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        const msg = pickOutError(err);
      }
    }),
    setCurrent(network: string) {
      self.current = network;
    },

    checkImage: flow(function* (imageRepository: string, version: string) {
      self.state = 'pending';
      try {
        const result = yield api.post(`/images/check`, {
          imageRepository,
          version,
        });
        self.state = 'done';
        return result;
      } catch (err) {
        self.state = 'error';
        console.log(err);
        self.errorMessage = pickOutError(err);
      }
    }),
    uploadFile: flow(function* (data: any) {
      return yield api.post(`/file/upload`, data);
    }),
    clearPromoteNetworks: () => {
      self.promoteNetworks = [] as any;
    },
    getPromoteNetworks: flow(function* (pId) {
      self.state = 'pending';
      try {
        const data = yield api.get(`${mxConfig.apiUrlV2}/partners/${pId}/networks-promote`);
        const result = data.map((i: any) => {
          if (i.form.networkSpec?.metadata?.bootnodes) {
            i.form.networkSpec.metadata.bootnodes = i.form.networkSpec.metadata.bootnodes.map((item: any) => {
              return typeof item === 'string' ? { address: item } : item;
            });
          }
          return i;
        });
        self.promoteNetworks = result;
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        console.log(err);
        self.errorMessage = pickOutError(err);
      }
    }),
    emptyPromoteNetworks: flow(function* () {
      self.promoteNetworks = [] as any;
    }),
    emptyNetworks: flow(function* () {
      self.list = [] as any;
    }),
    upsertNetworkRecommand: flow(function* (pId, id, data) {
      self.state = 'pending';
      try {
        yield api.post(`/partners/${pId}/networks/${id}/recommends`, data);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        console.log(err);
        const errorMessage = pickOutError(err);
        Array.isArray(errorMessage);
        self.errorMessage = Array.isArray(errorMessage) ? errorMessage.join(',') : errorMessage;
      }
    }),
    upsertNetworkMetadata: flow(function* (pId, key, data) {
      self.state = 'pending';
      try {
        yield api.post(`/partners/${pId}/networks/${key}/metadata`, data);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        console.log(err);
        const errorMessage = pickOutError(err);
        Array.isArray(errorMessage);
        self.errorMessage = Array.isArray(errorMessage) ? errorMessage.join(',') : errorMessage;
      }
    }),
    getRecommand: flow(function* (pId, key) {
      self.state = 'pending';
      try {
        const data: any = yield api.get(`/partners/${pId}/networks/${key}/recommends`);
        self.state = 'done';
        return data;
      } catch (err) {
        self.state = 'error';
        console.log(err);
        const errorMessage = pickOutError(err);
        Array.isArray(errorMessage);
        self.errorMessage = Array.isArray(errorMessage) ? errorMessage.join(',') : errorMessage;
      }
    }),
  }));

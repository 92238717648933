// eslint-disable-next-line import/named
import { flow, getParent, getSnapshot, Instance, types } from 'mobx-state-tree';
import cloneDeep from 'lodash/cloneDeep';

import { api, filterErrorMessage } from '../utils/api';
import { INetworkSpec, NetworkSpec } from './networks';
import { IMember } from './workspaces';
import { Store } from './index';
import { pickOutError } from '@/utils/tools';
import { sendEvent } from '@/utils/googleAnalytics';
import { mxConfig } from '@/config';

const activitiesPageSize = 20;

export enum UsageDataType {
  'mem' = 'mem_usage_pct',
  'cpu' = 'cpu_usage_pct',
  'pvc' = 'pvc_usage_pct',
}

export const Endpoints = types.model({
  rpc: types.frozen<string>(),
  ws: types.frozen<string>(),
  'rpc-relay': types.frozen<string>(),
  'ws-relay': types.frozen<string>(),
  'p2p-internal': types.frozen<string>(),
  p2p: types.frozen<string>(),
  'p2p-relay-internal': types.frozen<string>(),
  'p2p-relay': types.frozen<string>(),
  metrics: types.frozen<string>(),
  'metrics-relay': types.frozen<string>(),
  rpcc: types.frozen<string>(),
  wsc: types.frozen<string>(),
  rpccavax: types.frozen<string>(),
  rpcx: types.frozen<string>(),
  rpcp: types.frozen<string>(),
});

export enum NodeStatus {
  Pending = 'pending',
  Processing = 'processing',
  Initializing = 'initializing',
  Running = 'running',
  Stopping = 'stopping',
  Stopped = 'stopped',
  Restarting = 'restarting',
  Terminating = 'terminating',
  Terminated = 'terminated',
  Error = 'error',
}

export interface OperationalFlags {
  cluster_args: number;
  reserved_peers: number;
  relay_chain: number;
}

const stateSort = [
  'error',
  'pending',
  'processing',
  'initializing',
  'running',
  'stopping',
  'restarting',
  'terminating',
  'stopped',
  'terminated',
];

export const StatusMap = (status: string | null | undefined) => {
  let result = 'processing';
  switch (status) {
    case 'pending':
      result = 'processing';
      break;
    case 'enabled':
      result = 'success';
      break;
    case 'processing':
      result = 'processing';
      break;
    case 'initializing':
      result = 'warning';
      break;
    case 'running':
      result = 'success';
      break;
    case 'stopping':
      result = 'error';
      break;
    case 'stopped':
      result = 'error';
      break;
    case 'restarting':
      result = 'processing';
      break;
    case 'terminating':
      result = 'error';
      break;
    case 'terminated':
      result = 'error';
      break;
    case 'error':
      result = 'error';
      break;
    default:
      result = 'processing';
  }
  return result;
};

export const Node = types
  .model({
    id: types.identifier,
    name: types.string,
    networkSpecKey: types.string,
    config: types.frozen(),
    networkSpec: types.maybe(NetworkSpec),
    createdAt: types.string,
    updatedAt: types.string,
    workspaceId: types.string,
    ownerId: types.string,
    type: types.string,
    cpu: types.string,
    ram: types.string,
    nodeSpec: types.string,
    storage: types.string,
    image: types.string,
    clusterHash: types.string,
    nodeSpecMultiplier: types.number,
    status: types.enumeration(Object.values(NodeStatus)),
    endpoints: types.maybe(Endpoints),
    metadata: types.frozen(),
    availableVersions: types.maybe(types.array(types.string)),
    recommendVersion: types.maybeNull(types.string),
    hasUpgrade: types.maybe(types.boolean),
    flags: types.maybeNull(types.array(types.string)),
    ownerName: types.maybe(types.string),
    autoExpand: types.maybeNull(types.boolean),
    operationalArgsFlag: types.maybeNull(types.maybe(types.number)),
    availableOpsFlags: types.maybe(types.frozen<OperationalFlags>()),
    isFetchingEndpoints: types.maybe(types.boolean),
  })
  .views((self) => ({
    get isRpcAvailable() {
      const endpoints: any = self?.endpoints && cloneDeep(getSnapshot(self.endpoints));
      if (!endpoints) {
        return false;
      }

      // Clean up irrelevant endpoints, for the current node protocol, which will break the check
      Object.keys(endpoints).forEach((key) => !endpoints[key] && delete endpoints[key]);
      if (!Object.keys(endpoints).length) {
        return false;
      }

      for (let k of Object.keys(endpoints)) {
        if (k.indexOf('ws') < 0 && k.indexOf('rpc') < 0) {
          continue;
        }
        // If any of the RPC endpoint is not available = not available
        if (endpoints[k]) {
          return true;
        }
      }

      return false;
    },
  }))
  .actions((self) => ({
    fetchEndpoints: flow(function* () {
      if (self.isFetchingEndpoints) {
        return;
      }
      self.isFetchingEndpoints = true;
      self.endpoints = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${self.workspaceId}/nodes/${self.id}/endpoints`);
      self.isFetchingEndpoints = false;
    }),
  }));

export const NodeSimple = types.model({
  id: types.identifier,
  name: types.string,
  networkSpecKey: types.string,
  networkSpec: types.maybe(NetworkSpec),
  clusterDetail: types.maybe(
    types.model({
      name: types.maybe(types.string),
      region: types.maybe(types.string),
    }),
  ),
  createdAt: types.string,
  updatedAt: types.string,
  ownerId: types.string,
  storage: types.maybe(types.string),
  type: types.string,
  clusterHash: types.string,
  status: types.enumeration(Object.values(NodeStatus)),
  nodeSpec: types.maybe(types.string),
  nodeSpecMultiplier: types.maybe(types.number),
  image: types.maybe(types.string),
  metadata: types.frozen(),
  autoExpand: types.maybe(types.boolean),
  flag: types.optional(types.string, ''),
});

export type INode = Instance<typeof Node>;
export type INodes = Instance<typeof Nodes>;
export type INodeSimple = Instance<typeof NodeSimple>;

const Metrics = types.model({
  metric: types.string,
  ts: types.string,
  value: types.string,
});

const MetricItem = types.model({
  ts: types.string,
  value: types.maybeNull(types.number),
});

type NodeMap = {
  [key: string]: Array<INodeSimple>;
};

type NodeKeyNameArray = {
  key: string;
  displayName: string;
}[];

export const Nodes = types
  .model({
    state: types.optional(types.enumeration(['pending', 'done', 'error']), 'pending'),
    _list: types.optional(types.array(NodeSimple), []),
    current: types.maybe(Node),
    metrics: types.maybe(types.array(Metrics)),
    mem: types.maybe(types.array(MetricItem)),
    cpu: types.maybe(types.array(MetricItem)),
    pvc: types.maybe(types.array(MetricItem)),
    errorMessage: types.maybeNull(types.string),
  })
  .views((self) => ({
    get list() {
      const networkMap: { [key: string]: INetworkSpec } = getParent<Instance<typeof Store>>(self).networks.list.reduce(
        (acc, n) => {
          acc[n.key] = n;
          return acc;
        },
        {} as { [key: string]: INetworkSpec },
      );

      return self._list.map((node) => ({
        ...node,
        networkSpec: networkMap[node.networkSpecKey],
      }));
    },
    get mapData() {
      //const afterSortByType = this.list.sort((a, b) => (a.type < b.type ? -1 : 1));
      // const afterSortByStatus = afterSortByType.sort(
      //   (a, b) => stateSort.indexOf(a.status) - stateSort.indexOf(b.status),
      // );
      const afterReduce = this.list.reduce<NodeMap>((result, i) => {
        result[i.networkSpecKey] = result[i.networkSpecKey] ?? [];
        result[i.networkSpecKey].push(i);
        return result;
      }, {});
      let sortable: NodeKeyNameArray = [];
      for (let key in afterReduce) {
        sortable.push({
          key,
          displayName: getParent<Instance<typeof Store>>(self).networks.getByKey(key)?.displayName as string,
        });
      }
      if (self.state === 'done' && this.list.length > 0) {
        const afterSort: NodeKeyNameArray = sortable.sort((a, b) => {
          return a['displayName']?.localeCompare(b['displayName']);
        });
        const sortedNodes: any = afterSort.reduce((result: NodeMap, i: any) => {
          afterReduce[i.key].sort((a: any, b: any) => {
            const sameRegion = a.clusterDetail?.name?.localeCompare(b.clusterDetail?.name);
            return sameRegion === 0 ? a.name?.localeCompare(b.name) : sameRegion;
          });
          result[i.key] = afterReduce[i.key];
          return result;
        }, {});
        return sortedNodes;
      }
      return afterReduce;
    },

    getById(id: string) {
      return self._list.find((i) => i.id === id);
    },

    getMetric(key: string, showPercentage = false) {
      const m = self.metrics?.find((m) => m.metric === key);
      if (!m) return;
      if (!showPercentage) {
        return m ? Number(m.value) : 0;
      }
      return m ? Number(m.value) * 100 : 0;
    },

    getMetricItem(dataType: UsageDataType) {
      let data;
      if (dataType === UsageDataType.cpu) {
        data = self.cpu;
      } else if (dataType === UsageDataType.mem) {
        data = self.mem;
      } else {
        data = self.pvc;
      }
      return data
        ? data.map((i) => {
            return {
              ts: i.ts,
              value: Number(i.value),
            };
          })
        : [];
    },
  }))
  .actions((self) => ({
    deployNodeV2: flow(function* (option: any) {
      const wsId: string = getParent<Instance<typeof Store>>(self).workspaces.current!.id;
      self.state = 'pending';
      try {
        yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes`, option);
        sendEvent('deploy_node');
        if (mxConfig.twitterId?.length) {
          //@ts-ignore
          window.twq('event', `tw-${mxConfig.twitterId}-oe2n7`);
        }
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        // const message = err.response?.data?.message.split(' ');
        // const rate = message[message.length - 1];
        // self.errorMessage = rate
        //   ? 'Your current plan has a limit of ' +
        //     rate +
        //     ' dedicated nodes at a time. Contact <a href=mailto:sales@onfinality.io>sales@onfinality.io</a> to increase your limit'
        //   : err.message;
        self.errorMessage = filterErrorMessage(err);
      }
    }),

    fetchList: flow(function* (wsId: string) {
      self.state = 'pending';
      try {
        self._list = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    getNodeMetrics: flow(function* (wsId: string, id: string) {
      try {
        self.metrics = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/metrics`);
      } catch (err) {}
    }),

    getNodeStatus: flow(function* (wsId: string, id: string) {
      try {
        const result = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/status`);
        self.current!.status = result.status;
        return result.status;
      } catch (err) {}
    }),

    fetchNodeDetailForList: flow(function* (wsId: string, id: string) {
      try {
        const data = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}`);
        return data;
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    fetchNode: flow(function* (wsId: string, id: string) {
      self.state = 'pending';
      try {
        const data = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}`);
        self.current = data;
        const endpoints = data.endpoints;
        if (
          [
            endpoints['p2p'],
            endpoints['p2p-internal'],
            endpoints['p2p-relay'],
            endpoints['p2p-relay-internal'],
          ].includes(null)
        ) {
          const endpoints = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/endpoints`);
          self.current!.endpoints = endpoints;
        }
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    fetchEndpoints: flow(function* (wsId: string, id: string) {
      try {
        const endpoints = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/endpoints`);
        self.current!.endpoints = endpoints;
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    clearCache: flow(function* () {
      self.state = 'pending';
      self._list = [] as any;
      self.state = 'done';
    }),

    clearNode() {
      self.current = undefined;
      self.metrics = undefined;
      self.mem = undefined;
      self.cpu = undefined;
      self.pvc = undefined;
    },

    deleteNode: flow(function* (wsId: string, id: string) {
      self.state = 'pending';
      try {
        yield api.delete(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}`);
        self.current!.status = NodeStatus.Terminating;
        self.metrics = undefined;
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    stopNode: flow(function* (wsId: string, id: string) {
      self.state = 'pending';
      try {
        yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/stop`);
        self.current!.status = NodeStatus.Stopping;
        self.metrics = undefined;
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    resumeNode: flow(function* (wsId: string, id: string) {
      self.state = 'pending';
      try {
        yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/resume`);
        self.current!.status = NodeStatus.Restarting;
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    updateNodeName: flow(function* (wsId: string, id: string, data: any) {
      self.state = 'pending';
      try {
        const result = yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/update-name`, data);
        self.current = { ...self.current, ...result };
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    updateNodeSpec: flow(function* (wsId: string, id: string, data: any) {
      self.state = 'pending';
      try {
        const result = yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/update-spec`, data);
        if (self.current) self.current = { ...self.current, ...result, status: 'restarting' };
        else if (self._list) {
          let index = self._list.findIndex((i) => i.id === id);
          self._list[index] = {
            ...self._list[index],
            status: result.status,
            nodeSpec: result.nodeSpec,
            nodeSpecMultiplier: result.nodeSpecMultiplier,
          };
        }
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    updateNodeImage: flow(function* (wsId: string, id: string, data: any) {
      self.state = 'pending';
      try {
        const result = yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/update-image`, data);
        if (self.current) self.current = { ...self.current, ...result };
        else if (self._list) {
          let index = self._list.findIndex((i) => i.id === id);
          self._list[index] = { ...self._list[index], status: result.status, image: result.image };
        }
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    expandStorage: flow(function* (wsId: string, id: string, data: any) {
      self.state = 'pending';
      try {
        const result = yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/expand-storage`, data);
        if (self.current) self.current = { ...self.current, ...result };
        else if (self._list) {
          let index = self._list.findIndex((i) => i.id === id);
          self._list[index] = { ...self._list[index], status: result.status, storage: result.storage };
        }
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    setAutoExpand: flow(function* (wsId: string, id: string, data: any) {
      self.state = 'pending';
      try {
        const result = yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/set-auto-expand`, data);
        if (self.current) self.current = { ...self.current, ...result };
        else if (self._list) {
          let index = self._list.findIndex((i) => i.id === id);
          self._list[index] = { ...self._list[index], status: result.status, autoExpand: result.autoExpand };
        }
        self.state = 'done';
      } catch (err) {}
    }),
    updateNodepool: flow(function* (wsId: string, id: string, data: string) {
      self.state = 'pending';
      try {
        const body = {
          metadata: {
            labels: JSON.parse(data),
          },
        };
        const result = yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/update`, body);
        self.state = 'done';
        return result;
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    restartNode: flow(function* (wsId: string, id: string) {
      self.state = 'pending';
      try {
        yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/restart`);
        self.current!.status = NodeStatus.Restarting;
        self.metrics = undefined;
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    cloneNode: flow(function* (wsId: string, id: string, nodeName: string, autoExpand: boolean) {
      self.state = 'pending';
      try {
        const body = {
          nodeName: nodeName,
          nodeId: id,
          autoExpand,
        };
        const newNode = yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/clone`, body);
        self.state = 'done';
        return newNode;
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getUsage: flow(function* (wsId: string, id: string, dataType: UsageDataType, duration = '24h') {
      self.state = 'pending';
      try {
        const { data } = yield api.get(
          `${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/metrics/${dataType}?duration=${duration}`,
        );
        if (dataType === UsageDataType.cpu) {
          self.cpu = data;
        } else if (dataType === UsageDataType.mem) {
          self.mem = data;
        } else {
          self.pvc = data;
        }
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    fetchActivities: flow(function* (wsId: string, id: string, page = 1) {
      self.state = 'pending';
      try {
        const data = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/activities`, {
          params: {
            page,
            pageSize: activitiesPageSize,
          },
        });
        self.state = 'done';
        return data;
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    creatingPreview: flow(function* (wsId: string, option: any) {
      self.state = 'pending';
      try {
        const data = yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/preview`, option);
        self.state = 'done';
        return data;
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    upgradeLaunchConfiguration: flow(function* (wsId: string, id: string, option: any) {
      self.state = 'pending';
      try {
        yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${id}/update`, option);
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    setOperationalFlags: flow(function* (nodeId: string, flags: number) {
      const wsId: string = getParent<Instance<typeof Store>>(self).workspaces.current!.id;
      self.state = 'pending';
      try {
        const result = yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${nodeId}/set-operational-flags`, {
          flags,
        });
        self.state = 'done';
        self.current = { ...self.current, ...result };
      } catch (err) {
        self.state = 'error';
        self.errorMessage = filterErrorMessage(err);
      }
    }),

    createLightningBackup: flow(function* (nodeId: string, { schedule }: { schedule?: string }) {
      const wsId: string = getParent<Instance<typeof Store>>(self).workspaces.current!.id;
      self.state = 'pending';
      try {
        const result = yield api.post(`${mxConfig.apiUrl}/backups/create-from-node`, {
          schedule,
          nodeId,
          wsId,
        });
        self.state = 'done';
        return result;
      } catch (err) {
        console.error(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    changeOwner: flow(function* (newOwner: IMember, wsId?: string, nodeId?: string) {
      self.state = 'pending';
      try {
        if (!wsId || !nodeId) {
          throw new Error(`Node '${nodeId}' does not exist in Workspace '${wsId}'`);
        }
        const { name, id } = newOwner;

        // Call api
        yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${nodeId}/update-owner`, newOwner);

        // Patch the state
        if (self.current) {
          self.current.ownerId = id;
          self.current.ownerName = name;
        }
        self.state = 'done';
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),
  }));

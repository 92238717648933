import React, { useState } from 'react';
import { Intent } from '@blueprintjs/core';
import { DetailBox } from 'components';
import { PromoteNetworks, NetworkType } from '@/store/networkSpec';
import { Instance } from 'mobx-state-tree';
import defaultIcon from '@/themes/default/networkList.svg';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { Tag } from 'antd';

interface IProps extends Instance<typeof PromoteNetworks> {
  onClick: () => void;
}

export const AppCard: React.FC<IProps> = (props) => {
  const {
    accountName,
    status,
    form: {
      networkSpecExtraInfo,
      networkSpec: { metadata, displayName },
    },
    onClick,
  } = props;
  const { t } = useTranslation();
  const pictures = networkSpecExtraInfo?.pictures;
  const [logo, setLogo] = useState(pictures?.icon || defaultIcon);
  return (
    <>
      <DetailBox hover className={styles.container} onClick={onClick}>
        <div>
          <div className={styles.mainMsg}>
            <div className={styles.imgbox}>
              <img
                onError={() => {
                  setLogo(defaultIcon);
                }}
                src={logo}
              />
            </div>
            <h5>{displayName}</h5>
            <Tag className={styles.status} color={'processing'}>
              {t(`components.networkStatus.pending`)}
            </Tag>
            {/* <div className={styles.logoBox} style={{ backgroundImage: `url(${pictures.large})` }} /> */}
          </div>
        </div>
      </DetailBox>
    </>
  );
};

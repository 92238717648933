import { observer } from 'mobx-react';
import React from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import { NetworkReport } from './networkReport';

type IProps = RouteConfigComponentProps<{ wsId: string; appId: string }>;

export const Report: React.FC<IProps> = observer((props) => {
  return <NetworkReport {...props} />;
});

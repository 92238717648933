import { Slider } from '@blueprintjs/core';
import { TFunction } from 'i18next';
import { Instance } from 'mobx-state-tree';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import { NodeSpec } from '../../store/info';
import styles from './style.module.scss';

const lang = (t: TFunction, key: string) => t(`modules.nodeSpecSelector.${key}`);

interface INodeSpec {
  key: string;
  multiplier: number;
}

interface IProps {
  nodeSpecs: Instance<typeof NodeSpec>[];
  value?: INodeSpec;
  onChange?: (value: INodeSpec) => void;
}

export const NodeSpecSelector: React.FC<IProps> = ({ nodeSpecs, value, onChange }) => {
  const { t } = useTranslation();
  const { usageCenter } = useStore();
  const defaultNode = () => {
    if (!nodeSpecs.length) return undefined;
    if (!value) {
      return {
        key: nodeSpecs[0].key,
        multiplier: 1,
      };
    } else {
      const currentSpec = nodeSpecs.find((i) => i.key === value.key) || nodeSpecs[0];
      return {
        key: currentSpec?.key,
        multiplier: usageCenter.limit.nodeUnitSize || 1,
      };
    }
  };
  const [nodeSpec, setNodeSpec] = useState(defaultNode());
  // useEffect(() => {
  //   setNodeSpec(defaultNode());
  // }, [value]);
  const currentNodeSpec = nodeSpecs.find((i) => i.key === nodeSpec?.key);

  const onSelect = (v: INodeSpec) => {
    setNodeSpec(v);
    onChange && onChange(v);
  };
  const labelStepSize = (multiplier: number) => {
    if (multiplier <= 6) return 1;
    if ([7, 10, 13].includes(multiplier)) return 3;
    else if ([9, 13].includes(multiplier)) return 4;
    else if ([11, 15].includes(multiplier)) return 2;
    else if (multiplier > 16) return 2;
    return 1;
  };
  return (
    <div className={styles.container}>
      {currentNodeSpec && usageCenter.limit.nodeUnitSize > 1 && (
        <Slider
          min={usageCenter.limit.nodeUnitSize % 2}
          max={usageCenter.limit.nodeUnitSize}
          labelStepSize={labelStepSize(usageCenter.limit.nodeUnitSize)}
          onChange={(multiplier: number) => {
            if (multiplier < 1) multiplier = 1;
            onSelect({
              key: currentNodeSpec.key,
              multiplier,
            });
          }}
          value={value?.multiplier ?? 1}
        />
      )}
    </div>
  );
};

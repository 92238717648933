import { flow, types, Instance } from 'mobx-state-tree';
import { api, filterErrorMessage } from '../utils/api';
import { pickOutError } from '@/utils/tools';

export const ImageVersionType = types.model({
  id: types.number,
  image: types.string,
  tags: types.optional(types.array(types.string), []),
  imageRepository: types.string,
  networkSpecKey: types.string,
  active: types.boolean,
  version: types.string,
  protocolKey: types.maybe(types.string),
  recommendedImage: types.maybeNull(types.boolean),
});

export type IimageVersionType = Instance<typeof ImageVersionType>;

export const Images = types
  .model({
    state: types.optional(types.enumeration(['pending', 'done', 'error']), 'pending'),
    list: types.optional(types.array(ImageVersionType), []),
    errorMessage: types.maybe(types.string),
  })
  .views((self) => ({
    filterList(tag: string) {
      return self.list.reduce<IimageVersionType[]>((res, i) => {
        if ((i.active && i.tags.includes(tag)) || (tag === 'stable' && i.tags.length === 0)) {
          if (i.recommendedImage) {
            res.unshift(i);
          } else {
            res.push(i);
          }
        }
        return res;
      }, []);
    },
  }))
  .actions((self) => ({
    clearData: flow(function* () {
      self.list = [] as any;
    }),
    fetchList: flow(function* (wsId: string, networkKey: string) {
      self.state = 'pending';
      try {
        self.list = yield api.get(`/partners/${wsId}/networks/${networkKey}/images`);
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    }),
    fetchPublicList: flow(function* (networkKey: string) {
      self.state = 'pending';
      try {
        self.list = yield api.get(`/info/network/${networkKey}/images`);
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    }),
    clearList: () => {
      self.list = [] as any;
    },
    deactivate: flow(function* (wsId: string, networkKey: string, id: number) {
      self.state = 'pending';
      try {
        yield api.put(`/partners/${wsId}/networks/${networkKey}/images/${id}/deactivate`);
        const image = self.list.find((i) => i.id === id);
        image!.active = false;
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    changeRecommendedImage: flow(function* (wsId: string, networkKey: string, id: number) {
      self.state = 'pending';
      try {
        yield api.put(`/partners/${wsId}/networks/${networkKey}/images/${id}/changeRecommend`);
        const oldRecommendedImage = self.list.find((i) => i.recommendedImage === true);
        const newRecommendedImage = self.list.find((i) => i.id === id);
        if (oldRecommendedImage !== undefined) oldRecommendedImage!.recommendedImage = false;
        newRecommendedImage!.recommendedImage = true;
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    activate: flow(function* (wsId: string, networkKey: string, id: number) {
      self.state = 'pending';
      try {
        yield api.put(`/partners/${wsId}/networks/${networkKey}/images/${id}/activate`);
        const image = self.list.find((i) => i.id === id);
        image!.active = true;
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    delete: flow(function* (wsId: string, networkKey: string, id: number) {
      self.state = 'pending';
      try {
        yield api.delete(`/partners/${wsId}/networks/${networkKey}/images/${id}`);
        self.list = self.list.filter((i) => i.id !== id) as any;
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    deleteArray: flow(function* (wsId: string, networkKey: string, ids: Array<number>) {
      self.state = 'pending';
      try {
        yield api.delete(`/partners/${wsId}/networks/${networkKey}/images`, { data: { ids } });
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    upsert: flow(function* (wsId: string, networkKey: string, data) {
      self.state = 'pending';
      try {
        const newId = yield api.post(`/partners/${wsId}/networks/${networkKey}/images`, data);
        self.state = 'done';
        return newId;
      } catch (err) {
        console.log(err);
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
  }));

import React, { useState } from 'react';
import styles from './style.module.scss';
import { DetailBox } from 'components';
import { Tag } from 'antd';
import { Instance } from 'mobx-state-tree';
import { useTranslation } from 'react-i18next';
import { INetworkSpec } from '@/store/networks';
import { StatusMap } from '@/store/nodes';
import { TFunction } from 'i18next';
import { allowNodeType, Backup } from '@/store/networks';
import defaultIcon from '@/themes/default/networkDefaultIcon.svg';
import { ReactComponent as BackupIcon } from './imgs/backup.svg';
import { Popover } from 'antd';
import { mxConfig } from '@/config';
const lang = (t: TFunction, key: string, options?: any) => t(`components.${key}`, options);

interface IProps {
  backup?: Instance<typeof Backup>[];
  network: INetworkSpec;
  showStatus?: boolean;
  onClick: () => void;
}

export const NetworkSpecCard: React.FC<IProps> = ({ network, onClick, showStatus = false, backup = [] }) => {
  const { t } = useTranslation();
  const allowShowBackup = allowNodeType(backup);
  const [logo, setLogo] = useState(network?.metadata?.pictures?.icon || defaultIcon);
  const networkNodeTypes = Array.from(
    network.nodeTypes.reduce((acc, nodeType) => {
      acc.add(nodeType.key);
      return acc;
    }, new Set<string>()),
  );
  const backupMessage =
    !!allowShowBackup.length &&
    allowShowBackup
      .map((i) => {
        if (networkNodeTypes.includes(i)) return `<span>- ${i} Node</span>`;
      })
      .join('');

  return (
    <div className={styles.container}>
      {!!backupMessage && (
        <Popover
          placement="rightTop"
          content={
            <div
              className={styles.backupPopover}
              dangerouslySetInnerHTML={{
                __html: `${lang(t, `networkSpec.lightningRestoreOnList`, {
                  href: mxConfig.lightningRestoreDocument,
                })}${backupMessage}`,
              }}
            ></div>
          }
        >
          <BackupIcon className={styles.backupIcon} />
        </Popover>
      )}
      <DetailBox hover onClick={onClick}>
        <div>
          <div className={styles.mainMsg}>
            <div className={styles.imgbox}>
              <img
                onError={() => {
                  setLogo(defaultIcon);
                }}
                src={logo}
              />
            </div>
            <h5>{network.displayName ?? network.name}</h5>
            {showStatus && (
              <>
                <Tag className={styles.status} color={StatusMap(network.status)}>
                  {lang(t, `networkStatus.${network.status}`, { href: mxConfig.lightningRestoreDocument })}
                </Tag>
                {network.status !== network.cacheStatus && (
                  <div className={styles.processing}>{lang(t, 'networkStatus.processing')}</div>
                )}
              </>
            )}
          </div>
        </div>
      </DetailBox>
    </div>
  );
};

import React, { useState } from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { DownOutlined, ShareAltOutlined } from '@ant-design/icons';
import { apiSection } from './apiSection/index';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import { Logs } from './Logs';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import { Node, NodeStatus } from '@/store/nodes';
import { lang } from './helper';
import styles from './style.module.scss';
import { UpgradeImageDialog } from '@/components/NodeCard/UpgradeImageDialog';
import { UpgradeStorageDialog } from '@/components/NodeCard/UpgradeStorageDialog';
import { UpgradeNodeSpecDialog } from '@/components/NodeCard/UpgradeNodeSpecDialog';
import { NodeActionsAlert } from '@/pages/NodeDetail/NodeActionsAlert';
import { GeneralApiSection } from './apiSection/General';
import { AvalancheGeneral } from './apiSection/AvalancheGeneral';
import { WarningToast } from 'components';
import { ManageLabelsDialog } from '@/components/NodeCard/ManageLabelsDialog';
import { CloneNodeDialog } from '@/components/NodeCard/CloneNodeDialog';
import { AutoExpandDialog } from '@/components/NodeCard/AutoExpandDialog';
import { useFetchEndpoints } from './hooks';
import { CreateLightningBackupDialog } from '@/components/NodeCard/CreateLightningBackupDialog';
import { WorkspaceMemberDialog } from '@/components/WorkspaceMemberDialog';
import { IMember } from '@/store/workspaces';

interface IProps {
  upgrade?: boolean;
  node: Instance<typeof Node>;
  estimatePrice: string;
  onCloneNode: (wsId: string, nodeId: string, nodeName: string, autoExpand: boolean) => Promise<void>;
  onChangeOwner: (member?: IMember) => Promise<void>;
  loading?: boolean;
}

export const ActionBar: React.FC<IProps> = observer(
  ({
    node, //
    upgrade = false,
    estimatePrice,
    onCloneNode,
    onChangeOwner,
    loading,
  }) => {
    const { workspaces, nodes, auth } = useStore();
    const { t } = useTranslation();
    const [actionKind, setActionKind] = useState<string | undefined>();
    const [dialog, setDialog] = useState<string | undefined>();
    const isSuperAdmin = auth.user?.isAdmin === true;
    const isOwnerOrSuperAdmin = nodes.current?.ownerId === auth.user?.id || isSuperAdmin;
    const workspaceMembers: IMember[] = [...workspaces.members];
    // Insert the current admin to the list
    if (isSuperAdmin) {
      workspaceMembers.push({
        ...auth.user,
      } as IMember);
    }
    const actionAvailable =
      (node.status !== NodeStatus.Terminated && isOwnerOrSuperAdmin) ||
      ['owner', 'admin'].includes(workspaces.currentWorkspaceMember?.role as string);
    const isTerminated = [NodeStatus.Terminated, NodeStatus.Terminating].includes(node.status);
    useFetchEndpoints(node);
    return (
      <div className={styles.actionsBar}>
        <Dropdown
          arrow
          trigger={['click']}
          disabled={workspaces.current!.suspendTaskDone}
          overlay={
            <Menu>
              {node.networkSpec?.protocolKey &&
                React.createElement(
                  apiSection[node.networkSpec.protocolKey]
                    ? apiSection[node.networkSpec.protocolKey]
                    : node.networkSpec.protocolKey === 'avalanche'
                    ? AvalancheGeneral
                    : GeneralApiSection,
                  { node },
                )}
            </Menu>
          }
        >
          <Button
            className={styles.createNew}
            icon={<ShareAltOutlined />}
            disabled={workspaces.current!.suspendTaskDone}
          >
            {lang(t, 'detailTitle.apiEndpoints')}
          </Button>
        </Dropdown>

        <Button className={styles.cliButton} onClick={() => setDialog('showActons')}>
          {lang(t, 'buttonGroup.logs')}
        </Button>

        <Dropdown
          arrow
          trigger={['click']}
          disabled={(workspaces.current!.suspendTaskDone && actionAvailable) || isTerminated}
          overlay={
            <Menu className={styles.workspaceMenu}>
              {isSuperAdmin && (
                <>
                  <Menu.SubMenu key="admin" title={lang(t, 'buttonGroup.admin.menu')}>
                    <Menu.Item onClick={() => setDialog('manageLabels')} key="admin.manageLabels">
                      {lang(t, 'buttonGroup.admin.manageLabels')}
                    </Menu.Item>
                    <Menu.Item onClick={() => setDialog('createBackup')} key="admin.createBackup">
                      {lang(t, 'buttonGroup.admin.createBackup')}
                    </Menu.Item>
                  </Menu.SubMenu>
                  <Menu.Divider key="divider-3" />
                </>
              )}
              {isOwnerOrSuperAdmin && (
                <Menu.Item key="changeOwner" onClick={() => setDialog('changeOwner')}>
                  {lang(t, 'buttonGroup.changeOwner')}
                </Menu.Item>
              )}
              <Menu.Item key="upgradeNodeSpec" onClick={() => setDialog('upgradeNodeSpec')}>
                {lang(t, 'buttonGroup.upgradeNodeSpec')}
              </Menu.Item>
              <Menu.Item key="upgradeStorage" onClick={() => setDialog('upgradeStorage')}>
                {lang(t, 'buttonGroup.upgradeStorage')}
              </Menu.Item>
              <Menu.Item key="upgradeImage" onClick={() => setDialog('upgradeImage')}>
                {lang(t, 'buttonGroup.upgradeImage')}
              </Menu.Item>
              {!node.metadata?.labels?.nodePool && node.type !== 'validator' && node.type !== 'collator' && (
                <Menu.Item key="autoExpand" onClick={() => setDialog('autoExpand')}>
                  {node.autoExpand
                    ? lang(t, 'buttonGroup.autoExpandButton.disableAutoExpand')
                    : lang(t, 'buttonGroup.autoExpandButton.enableAutoExpand')}
                </Menu.Item>
              )}
              <Menu.Divider key="divider-1" />
              <Menu.Item key="clone" onClick={() => setDialog('cloneNode')}>
                {lang(t, 'buttonGroup.clone')}
              </Menu.Item>
              {node?.status !== NodeStatus.Stopped && (
                <Menu.Item
                  key="restart"
                  disabled={node.status === NodeStatus.Restarting}
                  onClick={() => setActionKind(upgrade ? 'needUpgrade' : 'restart')}
                >
                  {lang(t, 'buttonGroup.restart')}
                </Menu.Item>
              )}
              {node?.status === NodeStatus.Stopped && (
                <Menu.Item
                  key="resume"
                  onClick={() => {
                    if (upgrade) return WarningToast('Launch Configuration must be updated before node can be resumed');
                    setActionKind('resume');
                  }}
                >
                  {lang(t, 'buttonGroup.resume')}
                </Menu.Item>
              )}
              {node?.status !== NodeStatus.Stopped && (
                <Menu.Item
                  key="stop"
                  disabled={![NodeStatus.Running, NodeStatus.Error].includes(node?.status)}
                  onClick={() => setActionKind('stop')}
                >
                  {lang(t, 'buttonGroup.stop')}
                </Menu.Item>
              )}
              <Menu.Divider key="divider-2" />
              <Menu.Item key="terminate" danger onClick={() => setActionKind('delete')}>
                {lang(t, 'buttonGroup.terminate')}
              </Menu.Item>
            </Menu>
          }
        >
          <Button
            disabled={(workspaces.current!.suspendTaskDone && actionAvailable) || isTerminated}
            type="primary"
            className={styles.workspaceButton}
          >
            {lang(t, 'buttonGroup.actions.actions')}
            <DownOutlined />
          </Button>
        </Dropdown>
        <UpgradeNodeSpecDialog
          id={node.id}
          nodeSpec={node.nodeSpec!}
          multiplier={node.nodeSpecMultiplier!}
          isOpen={dialog === 'upgradeNodeSpec'}
          onClose={() => setDialog(undefined)}
        />
        <UpgradeStorageDialog
          id={node.id}
          storage={node.storage}
          isOpen={dialog === 'upgradeStorage'}
          onClose={() => setDialog(undefined)}
        />
        <UpgradeImageDialog
          id={node.id}
          image={node.image}
          availableVersions={node.availableVersions}
          recommendedVersion={node.recommendVersion}
          isOpen={dialog === 'upgradeImage'}
          onClose={() => setDialog(undefined)}
        />
        <NodeActionsAlert
          nodeId={node.id}
          nodeName={node.name}
          onClose={() => setActionKind(undefined)}
          actionKind={actionKind}
        />
        <ManageLabelsDialog
          id={node.id}
          metadata={node.metadata}
          isOpen={dialog === 'manageLabels'}
          onClose={() => setDialog(undefined)}
        />
        <CreateLightningBackupDialog
          id={node.id}
          isOpen={dialog === 'createBackup'}
          onClose={() => setDialog(undefined)}
        />
        <CloneNodeDialog
          nodeId={node.id}
          isOpen={dialog === 'cloneNode'}
          estimatePrice={estimatePrice}
          onClose={() => setDialog(undefined)}
          onCloneNode={onCloneNode}
        />
        {dialog === 'autoExpand' && (
          <AutoExpandDialog currentStatus={!!node.autoExpand} nodeId={node.id} onClose={() => setDialog(undefined)} />
        )}
        {dialog === 'changeOwner' && (
          <WorkspaceMemberDialog
            title={lang(t, 'buttonGroup.changeOwner')}
            memberLabel={lang(t, 'buttonGroup.changeOwnerLabel')}
            members={workspaceMembers}
            excludeMembers={[node.ownerId]}
            isOpen={dialog === 'changeOwner'}
            onClose={() => setDialog(undefined)}
            onSelect={async (member) => {
              await onChangeOwner(member);
              setDialog(undefined);
            }}
            loading={loading}
          />
        )}
        {dialog === 'showActons' && <Logs nodeId={node.id} onClose={() => setDialog(undefined)} />}
      </div>
    );
  },
);

import cx from 'classnames';
import React from 'react';
import { Instance } from 'mobx-state-tree';
import styles from './style.module.scss';
import { Card } from '../../store/payment-methods';

interface IParams {
  card: Instance<typeof Card>;
}

export const CreditCard: React.FC<IParams> = props => {
  const { card } = props;
  return (
    <div className={styles.card}>
      <span className={styles.brand}>{card.brand}</span>
      <span className={styles.cardNumber}>{card.cardNumber}</span>
      <span className={styles.expiry}>
        Expiry date:
        <strong className={cx({ [styles.alert]: card.expired })}>
          {card.expMonth}/{card.expYear}
        </strong>
      </span>
    </div>
  );
};

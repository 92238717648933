import styles from '../style.module.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../store';
import { useTranslation } from 'react-i18next';
import { lang, PanelProps } from '../helper';
import cx from 'classnames';

export const AvalanchePanel: React.FC<PanelProps> = observer(({ nodes }) => {
  const { t } = useTranslation();
  const is_syncing = nodes?.getMetric('node_is_syncing');
  return (
    <ul className={cx(styles.capsule)}>
      <li>
        <div className={styles.dashChain}>
          <span className={styles.subscript}>{'C-Chain'}</span>
          <div className={styles.th}>{lang(t, 'detailTitle.lastBlock')}</div>
          <div className={styles.para}>{nodes?.getMetric('c_chain_block_number', false)?.toLocaleString() ?? '--'}</div>
        </div>
      </li>
      <li>
        <div className={styles.dashChain}>
          <div className={styles.th}>{lang(t, 'detailTitle.peerCount')}</div>
          <div className={styles.para}>{nodes?.getMetric('connected_peers', false)?.toLocaleString() ?? '--'}</div>
        </div>
      </li>
      <li>
        <div className={styles.dashChain}>
          <div className={styles.th}>{lang(t, 'detailTitle.pendingTxCount')}</div>
          <div className={styles.para}>
            {nodes?.getMetric('c_chain_pending_block_transactions', false)?.toLocaleString() ?? '--'}
          </div>
        </div>
      </li>
      <li>
        <div className={styles.dashChain}>
          <div className={styles.th}>{lang(t, 'detailTitle.syncing')}</div>
          <div className={styles.para}>
            {is_syncing === undefined ? 'Syncing' : is_syncing > 0 ? 'Syncing' : 'Synced'}
          </div>
        </div>
      </li>
    </ul>
  );
});

import React from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { ErrorToast, PaymentMethodForm, SuccessToast } from 'components';
import { Modal } from 'antd';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import { TFunction } from 'i18next';

interface IParams {
  hasDialog?: boolean;
  isOpen: boolean;
  postSubmit?: (data: any) => void;
  onClose: () => void;
}

const lang = (t: TFunction, key: string) => t(`modules.paymentMethod.${key}`);

export const PaymentMethodDialog: React.FC<IParams> = ({ hasDialog = true, isOpen, postSubmit, onClose }) => {
  const { t } = useTranslation();
  const { paymentMethods, workspaces } = useStore();

  const doSubmit = async (data: any) => {
    await paymentMethods.create(data);
    if (paymentMethods.state === 'error') {
      ErrorToast(paymentMethods.errorMessage!, 2000);
      return;
    }
    SuccessToast(lang(t, 'successToast'));
    await paymentMethods.fetchList();

    // if have payment method, and no default payment id, set the first one as default
    if (paymentMethods.list.length > 0 && !workspaces.current!.paymentMethodId) {
      await workspaces.updatePaymentMethod(workspaces.current!, { paymentMethodId: paymentMethods.list[0].id });
    }

    if (postSubmit) {
      await postSubmit(data);
    }
  };
  if (!hasDialog && isOpen)
    return (
      <>
        <PaymentMethodForm onSubmit={doSubmit} />
        <Button large intent={Intent.NONE} className={styles.backButton} onClick={onClose}>
          Back to Choose
        </Button>
      </>
    );
  if (!isOpen) return null;
  return (
    <Modal
      className={styles.dialogBox}
      title={lang(t, 'createDialogTitle')}
      visible={true}
      footer={false}
      centered
      maskClosable={false}
      onCancel={onClose}
    >
      <PaymentMethodForm onSubmit={doSubmit} />
    </Modal>
  );
};

import { Button, HTMLSelect, HTMLTable, Intent, Popover, Position, MenuItem, Menu } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { showConfirm } from '../../components/Confirm';
import { Col, Row, DetailBox, ErrorToast, SuccessToast } from 'components';
import { useStore } from '../../store';
import { lang } from './helper';
import { InviteDialog } from './InviteDialog';
import styles from './style.module.scss';
import { Modal } from 'antd';

const countTime = 60;
interface CountDown {
  [key: string]: number;
}

export const MemberSection: React.FC = observer(() => {
  const { workspaces } = useStore();
  const match = useRouteMatch<{ wsId: string }>();
  const { t } = useTranslation();
  const [countDown, setCountDown] = useState<CountDown>({});
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    let timerID = setTimeout(() => startCountDown(), 1000);
    return () => clearTimeout(timerID);
  }, [countDown]);

  const reInvite = async (wid: string, recordId: string) => {
    await workspaces.reInvitation(wid, recordId);
    if (workspaces.state === 'done') {
      SuccessToast(lang(t, 'members.success'));
      setCountDown({
        ...countDown,
        [recordId]: countTime,
      });
    } else if (workspaces.state === 'error') {
      ErrorToast(workspaces.errorMessage || '');
    }
  };

  const startCountDown = () => {
    let num = 0;
    countDown &&
      Object.keys(countDown).map((key) => {
        num += (countDown[key] > 0 && countDown[key]--) || countDown[key];
      });
    if (num > 0)
      setCountDown({
        ...countDown,
      });
  };

  const emailCopy = () => {
    navigator.clipboard.writeText('sales@onfinality.io');
    SuccessToast('Copied');
  };

  const memberLimit = (memberNumber: number) => {
    Modal.warning({
      title: 'Workspace Member Limit Reached',
      content: (
        <div>
          Your plan is limited to {memberNumber} workspace members. Upgrade your plan or contact{' '}
          <span onClick={emailCopy} className={styles.limitSpan}>
            sales@onfinality.io
          </span>{' '}
          if you need more
        </div>
      ),
      centered: true,
      width: 468,
    });
  };

  return (
    <DetailBox className={styles.memberRadiusBox}>
      <Row className={styles.memberTitle}>
        <Col unit={12}>
          <h3 className={styles.subTitle}>{lang(t, 'members.title')}</h3>
        </Col>
        <Col unit={12} className={styles.alignRight}>
          <Button
            text={lang(t, 'members.invite')}
            onClick={() => {
              workspaces.current?.plan === 'developer'
                ? workspaces.invitations.length + workspaces.members.length >= 3
                  ? memberLimit(3)
                  : setShowDialog(true)
                : setShowDialog(true);
            }}
          />
        </Col>
      </Row>
      <HTMLTable>
        <thead>
          <tr>
            <th>{lang(t, 'members.tableHeader.name')}</th>
            <th>{lang(t, 'members.tableHeader.email')}</th>
            <th>{lang(t, 'members.tableHeader.role')}</th>
            <th>{lang(t, 'members.tableHeader.action')}</th>
          </tr>
        </thead>
        <tbody>
          {workspaces.members.map((member, n) => (
            <tr key={n}>
              <td>{member.name}</td>
              <td className={styles.breakWord}>{member.email}</td>
              <td>
                {member.role !== 'owner' ? (
                  <HTMLSelect
                    value={member.role}
                    onChange={async (e) => {
                      await workspaces.updateMemberRole(
                        workspaces.current!.id,
                        member.id,
                        e.currentTarget.value as any,
                      );
                      if (workspaces.state === 'error') {
                        ErrorToast(workspaces.errorMessage!);
                        return;
                      }
                      SuccessToast(lang(t, `members.roleUpdateSuccessMsg`));
                    }}
                  >
                    <option value="admin">Admin</option>
                    <option value="member">Member</option>
                  </HTMLSelect>
                ) : (
                  member.role
                )}
              </td>
              <td>
                {member.role !== 'owner' && (
                  <Button
                    text={t('dict.remove')}
                    onClick={() => {
                      showConfirm({
                        icon: 'issue',
                        message: lang(t, 'deleteMemberAlert.msg', { name: member.name }),
                        intent: Intent.DANGER,
                        cancelButtonText: lang(t, 'deleteMemberAlert.cancel'),
                        confirmButtonText: lang(t, 'deleteMemberAlert.confirm'),
                        onConfirm: async () => {
                          await workspaces.removeMember(workspaces.current!.id, member.id);
                          if (workspaces.state === 'error') {
                            ErrorToast(workspaces.errorMessage!);
                            return;
                          }
                          SuccessToast(lang(t, `deleteMemberAlert.successMsg`));
                        },
                      });
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
          {workspaces.invitations.map((record, n) => (
            <tr key={n}>
              <td />
              <td className={styles.breakWord}>
                {record.email} <span className={styles.smallText}>({lang(t, 'members.pending')})</span>
              </td>
              <td>{record.role}</td>
              <td>
                {
                  <Popover
                    content={
                      <Menu>
                        <MenuItem
                          icon="redo"
                          disabled={!!countDown![record.id]}
                          text={
                            <>
                              {lang(t, 'members.reInvite')}{' '}
                              <span>{countDown![record.id] > 0 && `(${countDown![record.id]})`}</span>
                            </>
                          }
                          onClick={() => {
                            reInvite(workspaces.current!.id, record.id);
                          }}
                        />
                        <MenuItem
                          icon="delete"
                          text={lang(t, 'cancelInvitation.cancel')}
                          onClick={() => {
                            showConfirm({
                              icon: 'issue',
                              message: lang(t, 'cancelInvitation.msg', { email: record.email }),
                              intent: Intent.DANGER,
                              cancelButtonText: lang(t, 'cancelInvitation.cancel'),
                              confirmButtonText: lang(t, 'cancelInvitation.confirm'),
                              onConfirm: async () => {
                                await workspaces.cancelInvitation(workspaces.current!.id, record.id);
                                if (workspaces.state === 'error') {
                                  ErrorToast(workspaces.errorMessage!);
                                  return;
                                }
                                SuccessToast(lang(t, `cancelInvitation.successMsg`));
                              },
                            });
                          }}
                        />
                      </Menu>
                    }
                    position={Position.TOP_RIGHT}
                    minimal
                  >
                    <Button icon="cog" text={`${lang(t, 'members.more')}...`} />
                  </Popover>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>

      <InviteDialog
        onClose={() => setShowDialog(false)}
        afterSubmit={async () => {
          await workspaces.fetchMembers(match.params.wsId);
          setShowDialog(false);
        }}
        isOpen={showDialog}
      />
    </DetailBox>
  );
});

import React, { useState } from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import i18n from 'i18next';
import styles from './style.module.scss';

interface IProps {
  primary?: boolean;
}

export const Language: React.FC<IProps> = ({ primary = true }) => {
  const myStorage = window.localStorage;
  const currentLanguage = myStorage.getItem('i18nextLng') || 'en';
  const [language, setLanguage] = useState(currentLanguage);
  const { t } = useTranslation();
  const changeLanguage = async (lng: string) => {
    await i18n.changeLanguage(lng);
    myStorage.setItem('i18nextLng', lng);
    setLanguage(lng);
  };

  return (
    <Dropdown
      arrow
      placement="bottomRight"
      trigger={['click']}
      overlay={
        <Menu className={styles.workspaceMenu}>
          <Menu.Item key="EN" onClick={() => changeLanguage('en')}>
            {t('topNav.english')}
          </Menu.Item>
          <Menu.Item key="ZH" onClick={() => changeLanguage('zh-CN')}>
            {t('topNav.chinese')}
          </Menu.Item>
        </Menu>
      }
    >
      <Button type={primary ? 'primary' : 'default'}>
        {t('topNav.language')}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

import styles from './style.module.scss';
import React, { useEffect, useState } from 'react';
import { DetailBox, AntdRow, AntdCol, ErrorToast, SuccessToast } from 'components';
import { useStore } from '@/store';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Button, Input, Form, Modal } from 'antd';
import cx from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { WhitelistType } from '@/store/apiService';
import { isValidIP } from '@/utils/tools';

type Props = { wsId: string; appId: string };
interface FormProps {
  value?: string;
  errorMessage?: string;
  isLoading?: boolean;
}

export const AccessRestrictions: React.FC<Props> = observer(({ wsId, appId }) => {
  const [modal, contextHolder] = Modal.useModal();
  const [ipData, setIpData] = useState<FormProps>({
    value: '',
    errorMessage: '',
    isLoading: false,
  });
  const [originData, setOriginData] = useState<FormProps>({
    value: '',
    errorMessage: '',
    isLoading: false,
  });
  const { apiService } = useStore();
  const { t } = useTranslation();
  const lang = (key: string, opt?: any) => t(`pages.apiServices.settingsPage.accessRestrictions.${key}`, opt);

  const removeConfirm = (id: string) => {
    const whiteList = apiService.whiteList.find((i) => i.id === id);
    if (!whiteList) return;
    const confirmMessage =
      whiteList?.type === WhitelistType.IP
        ? {
            title: lang('removeIPTitle'),
            content: lang('removeIPContent', { v: whiteList.value }),
          }
        : {
            title: lang('removeOriginTitle'),
            content: lang('removeOriginContent', { v: whiteList.value }),
          };
    modal.confirm({
      title: confirmMessage.title,
      icon: <ExclamationCircleOutlined style={{ color: '#FF952B' }} />,
      content: confirmMessage.content,
      okText: lang('remove'),
      okButtonProps: { type: 'primary' },
      okType: 'danger',
      cancelText: lang('cancel'),
      onOk: async () => {
        await apiService.removeWhiteList(wsId, appId, id);
        if (apiService.state === 'error') {
          ErrorToast(apiService.errorMessage!);
        } else {
          apiService.getWhiteList(wsId, appId);
          SuccessToast(lang('removeSuccess'));
        }
      },
      centered: true,
    });
  };

  const setState = (value: FormProps, type: WhitelistType) => {
    if (type === WhitelistType.IP) {
      setIpData({
        ...ipData,
        ...value,
      });
    } else {
      setOriginData({
        ...originData,
        ...value,
      });
    }
  };

  const submit = async (value: string | undefined, type: WhitelistType) => {
    if (!value) return;
    value = value.trim();
    if (apiService.whiteList.find((i) => i.value === value))
      return setState(
        {
          errorMessage: lang('alreadyExist'),
        },
        type,
      );
    if (type === WhitelistType.IP) {
      if (!isValidIP(value))
        return setState(
          {
            errorMessage: 'Not a valid IP address',
          },
          type,
        );
    }
    setState(
      {
        isLoading: true,
      },
      type,
    );
    await apiService.addWhiteList(wsId, appId, value, type);
    if (apiService.state === 'error') {
      setState(
        {
          isLoading: false,
        },
        type,
      );
      ErrorToast(apiService.errorMessage!);
    } else {
      setState(
        {
          value: '',
          errorMessage: '',
          isLoading: false,
        },
        type,
      );
      apiService.getWhiteList(wsId, appId);
      SuccessToast(lang('addSuccess'));
    }
  };

  useEffect(() => {
    apiService.getWhiteList(wsId, appId);
    return () => {
      apiService.clearWhiteList();
    };
  }, []);

  return (
    <>
      <DetailBox className={cx(styles.detailBox, styles.accessRestrictions)}>
        <h3>{lang('title')}</h3>
        <p className={styles.subTitle}>{lang('subTitlel')}</p>
        <Form
          layout="vertical"
          onSubmitCapture={() => {
            submit(originData.value, WhitelistType.ORIGIN);
          }}
        >
          <Form.Item
            label={lang('originsLabel')}
            validateStatus={!!originData.errorMessage ? 'error' : 'success'}
            help={originData.errorMessage}
            tooltip={<div dangerouslySetInnerHTML={{ __html: lang('originQuestion') }}></div>}
          >
            <AntdRow gutter={8}>
              <AntdCol flex="auto">
                <Input
                  size="large"
                  placeholder="https://example.com"
                  value={originData.value}
                  onChange={(e) => {
                    setOriginData({ ...originData, value: e.target.value, errorMessage: '' });
                  }}
                  disabled={!apiService.editApp}
                />
              </AntdCol>
              <AntdCol alignRight>
                {apiService.editApp && (
                  <Button loading={originData.isLoading} type="primary" htmlType="submit" size="large">
                    {lang('add')}
                  </Button>
                )}
              </AntdCol>
            </AntdRow>
          </Form.Item>
          <div className={styles.whiteList}>
            {apiService.whiteList
              .filter((whiteList) => whiteList.type === WhitelistType.ORIGIN)
              .map((whiteList) => (
                <AntdRow key={whiteList.id}>
                  <AntdCol flex="auto">{whiteList.value}</AntdCol>
                  <AntdCol alignRight>
                    {apiService.editApp && (
                      <Button
                        type="link"
                        onClick={() => {
                          removeConfirm(whiteList.id);
                        }}
                      >
                        {lang('remove')}
                      </Button>
                    )}
                  </AntdCol>
                </AntdRow>
              ))}
          </div>
        </Form>
        <Form
          layout="vertical"
          onSubmitCapture={() => {
            submit(ipData.value, WhitelistType.IP);
          }}
        >
          <Form.Item
            label={lang('ipLabel')}
            help={ipData.errorMessage}
            validateStatus={!!ipData.errorMessage ? 'error' : 'success'}
          >
            <AntdRow gutter={8}>
              <AntdCol flex="auto">
                <Input
                  size="large"
                  placeholder="8.8.8.8"
                  value={ipData.value}
                  onChange={(e) => {
                    setIpData({ ...ipData, value: e.target.value, errorMessage: '' });
                  }}
                  disabled={!apiService.editApp}
                />
              </AntdCol>
              <AntdCol alignRight>
                {apiService.editApp && (
                  <Button loading={ipData.isLoading} type="primary" size="large" htmlType="submit">
                    {lang('add')}
                  </Button>
                )}
              </AntdCol>
            </AntdRow>
          </Form.Item>
          <div className={styles.whiteList}>
            {apiService.whiteList
              .filter((whiteList) => whiteList.type === WhitelistType.IP)
              .map((whiteList) => (
                <AntdRow key={whiteList.id}>
                  <AntdCol flex="auto">{whiteList.value}</AntdCol>
                  <AntdCol alignRight>
                    {apiService.editApp && (
                      <Button
                        type="link"
                        onClick={() => {
                          removeConfirm(whiteList.id);
                        }}
                      >
                        {lang('remove')}
                      </Button>
                    )}
                  </AntdCol>
                </AntdRow>
              ))}
          </div>
        </Form>
      </DetailBox>
      {contextHolder}
    </>
  );
});

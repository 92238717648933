import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { ButtonSize } from 'node_modules/antd/lib/button';
import { Button } from 'antd';
import { useStore } from '../../store';
import * as yup from 'yup';
import { ReactComponent as Wrench } from './imgs/wrench.svg';
import { ReactComponent as Terminal } from './imgs/terminal.svg';
import { ReactComponent as Srock } from './imgs/srock.svg';
import styles from './welcome.module.scss';
import { CreateAppModal } from './createAppModal';

interface IProps {
  wsId: string;
  text: string;
  size?: ButtonSize;
  network?: string;
  description?: boolean;
}

export const EnvironmentType = [
  {
    label: 'Development',
    value: 'development',
    icon: <Wrench className={styles.createAppIcon} />,
  },
  {
    label: 'Staging',
    value: 'staging',
    icon: <Terminal className={styles.createAppIcon} />,
  },
  {
    label: 'Production',
    value: 'production',
    icon: <Srock className={styles.createAppIcon} />,
  },
];

export const EnvironmentMap = {
  development: {
    label: 'Development',
    value: 'development',
    icon: <Wrench className={styles.createAppIconFillBlue} />,
  },
  staging: {
    label: 'Staging',
    value: 'staging',
    icon: <Terminal className={styles.createAppIconFillBlue} />,
  },
  production: {
    label: 'Production',
    value: 'production',
    icon: <Srock className={styles.createAppIconFillBlue} />,
  },
};

const schema = yup.object().shape({
  displayName: yup.string().required(),
  environment: yup.string().required(),
});

export const CreateApp: React.FC<IProps> = observer(({ wsId, text, size = 'large', network, description }) => {
  const { workspaces } = useStore();
  const [processing, setProcessing] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <Button
        disabled={processing || workspaces.current!.suspendTaskDone}
        type={'primary'}
        size={size}
        onClick={() => setIsOpenModal(true)}
      >
        {text}
      </Button>
      {isOpenModal && (
        <CreateAppModal
          wsId={wsId}
          text={text}
          network={network}
          size={size}
          setIsOpenModal={setIsOpenModal}
          processing={processing}
          setProcessing={setProcessing}
          description={description}
        />
      )}
    </>
  );
});

import { Classes, HTMLTable } from '@blueprintjs/core';
import { Instance } from 'mobx-state-tree';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Loader, DetailBox, Paginate } from 'components';
import { useStore } from '../../store';
import { Node } from '../../store/nodes';
import { lang } from './helper';
import dayjs from 'dayjs';
import ReactJson from 'react-json-view-ts';
import styles from './style.module.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface IProps {
  node: Instance<typeof Node>;
}

const actionMap = (key: string, request: any) => {
  const name = key.replace(/_/gi, ' ');
  switch (key) {
    case 'update_spec':
      return {
        name,
        value: `${JSON.stringify(request.data.nodeSpec)}`,
      };
    case 'update_name':
      return {
        name,
        value: `${request.data.nodeName}`,
      };
    case 'update_image_version':
      return {
        name: 'update image',
        value: `${request.data.imageVersion}`,
      };
    case 'expand_storage':
      return {
        name,
        value: `${request.data.storage}`,
      };
    case 'set_auto_expand':
      return {
        name: 'set auto expand storage',
        value: request.data.autoExpand ? 'Enabled' : 'Disabled',
      };
    case 'auto_expand_storage':
      return {
        name,
        value: `${request.data.storage}`,
      };
    case 'set_operational_flags':
      return {
        name,
        value: request.data.flags,
      };
    default:
      // Remove senstive data
      if (request?.data) {
        delete request.data.id;
        delete request.data.email;
      }
      return {
        name,
        value: `${request.data ? JSON.stringify(request.data) : ''}`,
      };
  }
};

const isJSON = (str: any) => {
  if (typeof str !== 'string') {
    return false;
  }
  try {
    JSON.parse(str);
    return true;
  } catch {
    return false;
  }
};

export const ActionsList: React.FC<IProps> = observer(({ node }) => {
  const { t } = useTranslation();
  const [list, setList] = useState<Array<any>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const { nodes, workspaces } = useStore();

  const resetData = async (page = 1) => {
    const { data, totalPage } = (await nodes.fetchActivities(workspaces.current!.id, node.id, page)) || {
      data: [] as any,
      totalPage: 0,
    };
    setList(data);
    setTotalPage(totalPage);
  };

  return (
    <DetailBox>
      <Loader isLoading={!list.length && nodes.state === 'pending'}>
        <HTMLTable className={styles.actionsList}>
          <thead>
            <tr>
              <th>{lang(t, 'detailTitle.logTitleTime')}</th>
              <th>{lang(t, 'detailTitle.logTitleAction')}</th>
              <th>{lang(t, 'detailTitle.logTitleValue')}</th>
              <th>{lang(t, 'detailTitle.logTitleOperator')}</th>
            </tr>
          </thead>
          <tbody>
            {list.map((i) => {
              const { name, value } = actionMap(i.action, i.record.request);
              return (
                <tr key={i?.id}>
                  <td>{dayjs.utc(i?.crreatedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                  <td>{name}</td>
                  <td>
                    {isJSON(value) ? (
                      <ReactJson
                        displayDataTypes={false}
                        enableClipboard={true}
                        name={null}
                        src={JSON.parse(value)}
                        indentWidth={2}
                        collapsed={10}
                        shouldCollapse={(field) => {
                          if (field.name === null) return true;
                          return false;
                        }}
                      />
                    ) : (
                      value
                    )}
                  </td>
                  <td>
                    {(i.action === 'auto_expand_storage' || i.action === 'stop' || i.action === 'terminate') &&
                    i.id === 1 ? (
                      <>
                        {i?.accountName}&nbsp;&nbsp;
                        <Tooltip title={lang(t, 'detailTitle.automatedServiceWorker')}>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </>
                    ) : (
                      i?.accountName
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </HTMLTable>
      </Loader>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Paginate
          pageCount={totalPage || 0}
          pageRangeDisplayed={2}
          marginPagesDisplayed={6}
          previousLabel={'<'}
          nextLabel={'>'}
          onPageChange={(selectedItem) => {
            resetData(selectedItem.selected + 1);
          }}
          initialPage={0}
          disableInitialCallback={false}
          containerClassName={'container'}
          activeClassName={'active'}
          activeLinkClassName={'active'}
        />
      </div>
    </DetailBox>
  );
});

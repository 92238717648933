import React from 'react';
import { useTranslation } from 'react-i18next';
import { ParseMetricsDetails } from '../helper';
import styles from '../style.module.scss';
import cx from 'classnames';
import { lang } from '../helper';
import { SyncOutlined } from '@ant-design/icons';
import { Typography, Input } from 'antd';

const { Paragraph } = Typography;

export type MetricsEndpointType = 'metrics' | 'metrics-para' | 'metrics-relay';

interface Props {
  metricsDetails: ParseMetricsDetails;
  endpoint?: string | null;
  endpointType: MetricsEndpointType;
  visible: boolean;
  addressTitle?: string;
}

export const MetricsTabContent: React.FC<Props> = ({
  metricsDetails,
  endpoint,
  visible,
  endpointType,
  addressTitle,
}) => {
  const { hasPrometheusArg, hasPrometheusRelayArg, needMetrics, needMetricsRelay } = metricsDetails;
  const { t } = useTranslation();
  // Dynamic variables
  let metricsEnabled = false;
  let isLoading = true;
  switch (endpointType) {
    case 'metrics':
      metricsEnabled = hasPrometheusArg;
      isLoading = needMetrics;
      break;
    case 'metrics-para':
      metricsEnabled = hasPrometheusArg;
      isLoading = needMetrics;
      break;
    case 'metrics-relay':
      metricsEnabled = hasPrometheusRelayArg;
      isLoading = needMetricsRelay;
      break;
  }

  const disabledText = lang(t, 'metricsEndpoint.disabledText', {
    typeToDesc: lang(t, `metricsEndpoint.typeToDesc.${endpointType}`),
    typeToArg: lang(t, `metricsEndpoint.typeToArg.${endpointType}`),
  });

  return (
    <div className={cx({ [styles.hide]: !visible, [styles.metricsTabContent]: true })}>
      {metricsEnabled ? (
        isLoading ? (
          <div className="loading-container">
            <>
              <SyncOutlined spin={true} />
              {lang(t, `metricsEndpoint.loadingText`) || 'Initialising endpoint'}
            </>
          </div>
        ) : (
          <>
            <p className={styles.addressTitle}>{addressTitle ? addressTitle : lang(t, 'detailTitle.http')}</p>
            <Input
              size={'large'}
              disabled
              className={styles.apiKeyInput}
              suffix={
                <Paragraph copyable className={styles.copyButton}>
                  {endpoint || 'N/A'}
                </Paragraph>
              }
              defaultValue={endpoint || 'N/A'}
            />
          </>
        )
      ) : (
        <div className="hint-container disabled-metrics-text">
          <p dangerouslySetInnerHTML={{ __html: disabledText }}></p>
          <Input
            size={'large'}
            disabled
            className={styles.apiKeyInput}
            suffix={
              <Paragraph copyable className={styles.copyButton}>
                {'--prometheus-external'}
              </Paragraph>
            }
            defaultValue={'--prometheus-external'}
          />
        </div>
      )}
    </div>
  );
};

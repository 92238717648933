import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import styles from './style.module.scss';
import { Metric, DetailBox } from 'components';
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';
import { lang } from './helper';
import { Instance } from 'mobx-state-tree';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { Node } from '../../store/nodes';
import { UsageDataType } from '@/store/nodes';
import dayjs from 'dayjs';
import { Spin } from 'antd';

interface IProps {
  node: Instance<typeof Node>;
}

enum Duration {
  Day = '24h',
  Week = '7d',
}

const charOpt = (data: any, max = false) => {
  const dateForm = 'MM-DD HH:mm';
  const xAxis: any = [];
  const yAxis: any = [];
  data.forEach((i: any) => {
    xAxis.push(dayjs(i.ts).format(dateForm));
    yAxis.push(!!i.value ? (i.value * 100).toFixed(2) : null);
  });

  return {
    xAxis: {
      data: xAxis,
      type: 'category',
      splitLine: {
        show: true,
        lineStyle: {
          color: '#f0f0f0',
        },
      },
      axisLine: {
        lineStyle: {
          color: '#bfbfbf',
        },
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: '#f0f0f0',
        },
      },
      max: max ? 100 : null,
      axisLine: {
        show: true,
        lineStyle: {
          color: '#bfbfbf',
        },
      },
      axisLabel: {
        formatter: '{value}%',
      },
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        if (params[0].value !== undefined) return `${params[0].name}<br />${params[0].value}%`;
      },
    },
    grid: {
      left: '3%',
      right: '0',
      bottom: '3%',
      top: '14%',
      containLabel: true,
    },
    series: {
      data: yAxis,
      type: 'line',
      lineStyle: {
        width: 1.5,
      },
      showSymbol: false,
      color: '#5D6EEB',
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgba(94, 111, 235, 0.8)',
          },
          {
            offset: 1,
            color: 'rgba(255, 255, 255, 0.5)',
          },
        ]),
      },
    },
  };
};

export const ResourceIndicator: React.FC<IProps> = observer(({ node }) => {
  const { nodes, workspaces } = useStore();
  const [cpuDuration, setCpuDuration] = useState(Duration.Day);
  const [cpuLoading, setCpuLoading] = useState(false);

  const [memDuration, setMemDuration] = useState(Duration.Day);
  const [memLoading, setMemLoading] = useState(false);

  const [pvcDuration, setPvcDuration] = useState(Duration.Day);
  const [pvcLoading, setPvcLoading] = useState(false);

  const { t } = useTranslation();
  const wsId = workspaces.current!.id;

  useEffect(() => {
    setCpuLoading(true);
    var cpuState = nodes.getUsage(wsId, node.id, UsageDataType.cpu, cpuDuration);
    cpuState.then(() => {
      setCpuLoading(false);
    });
  }, [cpuDuration]);
  useEffect(() => {
    setMemLoading(true);
    var memState = nodes.getUsage(wsId, node.id, UsageDataType.mem, memDuration);
    memState.then(() => {
      setMemLoading(false);
    });
  }, [memDuration]);
  useEffect(() => {
    setPvcLoading(true);
    var pvcState = nodes.getUsage(wsId, node.id, UsageDataType.pvc, pvcDuration);
    pvcState.then(() => {
      setPvcLoading(false);
    });
  }, [pvcDuration]);

  return (
    <>
      <DetailBox className={styles.detailBox}>
        <div className={styles.gauge}>
          <h5 className={styles.indicatorTitle}>{lang(t, 'detailTitle.cpu')}</h5>
          <Metric start="0M" end={`${parseInt(node.cpu)}M`} value={nodes.getMetric('cpu_usage_pct', true)} />
        </div>
        <div className={styles.chart}>
          <ButtonGroup className={styles.duration}>
            <Button
              small
              intent={cpuDuration === Duration.Day ? Intent.PRIMARY : Intent.NONE}
              onClick={() => {
                setCpuDuration(Duration.Day);
              }}
              disabled={cpuLoading}
            >
              {Duration.Day}
            </Button>
            <Button
              small
              intent={cpuDuration === Duration.Week ? Intent.PRIMARY : Intent.NONE}
              onClick={() => {
                setCpuDuration(Duration.Week);
              }}
              disabled={cpuLoading}
            >
              {Duration.Week}
            </Button>
          </ButtonGroup>
          {cpuLoading && <Spin className={styles.echarts} style={{ height: 160, width: '100%', marginTop: 100 }} />}
          {!cpuLoading && (
            <ReactEcharts
              option={charOpt(nodes.getMetricItem(UsageDataType.cpu))}
              className={styles.echarts}
              style={{ height: 260, width: '100%' }}
            />
          )}
        </div>
      </DetailBox>
      <DetailBox className={styles.detailBox}>
        <div className={styles.gauge}>
          <h5 className={styles.indicatorTitle}>{lang(t, 'detailTitle.memory')}</h5>
          <Metric start="0M" end={`${parseInt(node.ram)}M`} value={nodes.getMetric('mem_usage_pct', true)} />
        </div>
        <div className={styles.chart}>
          <ButtonGroup className={styles.duration}>
            <Button
              small
              intent={memDuration === Duration.Day ? Intent.PRIMARY : Intent.NONE}
              onClick={() => {
                setMemDuration(Duration.Day);
              }}
              disabled={memLoading}
            >
              {Duration.Day}
            </Button>
            <Button
              small
              intent={memDuration === Duration.Week ? Intent.PRIMARY : Intent.NONE}
              onClick={() => {
                setMemDuration(Duration.Week);
              }}
              disabled={memLoading}
            >
              {Duration.Week}
            </Button>
          </ButtonGroup>
          {memLoading && <Spin className={styles.echarts} style={{ height: 160, width: '100%', marginTop: 100 }} />}
          {!memLoading && (
            <ReactEcharts
              option={charOpt(nodes.getMetricItem(UsageDataType.mem))}
              className={styles.echarts}
              style={{ height: 260, width: '100%' }}
            />
          )}
        </div>
      </DetailBox>
      <DetailBox className={styles.detailBox}>
        <div className={styles.gauge}>
          <h5 className={styles.indicatorTitle}>{lang(t, 'detailTitle.storage')}</h5>
          <Metric start="0G" end={`${parseInt(node.storage)}G`} value={nodes.getMetric('pvc_usage_pct', true)} />
        </div>
        <div className={styles.chart}>
          <ButtonGroup className={styles.duration}>
            <Button
              small
              intent={pvcDuration === Duration.Day ? Intent.PRIMARY : Intent.NONE}
              onClick={() => {
                setPvcDuration(Duration.Day);
              }}
              disabled={pvcLoading}
            >
              {Duration.Day}
            </Button>
            <Button
              small
              intent={pvcDuration === Duration.Week ? Intent.PRIMARY : Intent.NONE}
              onClick={() => {
                setPvcDuration(Duration.Week);
              }}
              disabled={pvcLoading}
            >
              {Duration.Week}
            </Button>
          </ButtonGroup>
          {pvcLoading && <Spin className={styles.echarts} style={{ height: 160, width: '100%', marginTop: 100 }} />}
          {!pvcLoading && (
            <ReactEcharts
              option={charOpt(nodes.getMetricItem(UsageDataType.pvc), true)}
              className={styles.echarts}
              style={{ height: 260, width: '100%' }}
            />
          )}
        </div>
      </DetailBox>
    </>
  );
});

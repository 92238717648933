import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { useStore } from '../../store';
import { RouteConfigComponentProps } from 'react-router-config';
import {
  PageLayout,
  FixedMainHeader,
  AntdRow,
  AntdCol,
  Back,
  MainBox,
  ButtonTab,
  NetworkSpecCardNew,
  Loader,
} from 'components';
import _ from 'lodash';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { INetworkSpec } from '../../store/networks';

type IProps = RouteConfigComponentProps<{ wsId: string }>;

const enum TAB {
  PUBLIC = 'public',
  CUSTOM = 'custom',
}

const filterFilm = (query: string, film: string) => {
  if (!query) return true;
  return `${film.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};

interface tabOPtion {
  [key: string]: string;
}

const tabOptions: tabOPtion = {
  All: 'none',
  Polkadot: 'key',
  Ethereum: 'key',
  Avalanche: 'key',
  Cosmos: 'key',
  Trace: 'type',
  Archive: 'type',
};

const waitTime = 300;

export const ApiServicesMarketPlace: React.FC<IProps> = observer((props) => {
  const { match, history } = props;
  const { networks } = useStore();
  const { wsId } = match.params;
  const searchBar = useRef(null);
  const [filterWord, setFilterWord] = useState<string | undefined>('');
  const { t } = useTranslation();
  const [selectedProtocolKey, setSelectedProtocolKey] = useState<string>('');
  const [selectedNetworkType, setSelectedNetworkType] = useState<string>('');
  const lang = (t: TFunction, key: string, opt?: any) => t(`pages.apiServices.${key}`, opt);

  const tabOnChange = (key: string) => {
    if (key === 'All') {
      setSelectedNetworkType('');
      setSelectedProtocolKey('');
    } else if (tabOptions[key] === 'key') {
      setSelectedProtocolKey(key);
      setSelectedNetworkType('');
    } else if (tabOptions[key] === 'type') {
      setSelectedNetworkType(key);
      setSelectedProtocolKey('');
    }
  };

  useEffect(() => {
    (async () => {
      await networks.fetchAllNetworkForApiService();
    })();
  }, []);

  const changeValue = _.debounce((v: string | undefined) => {
    setFilterWord(v);
  }, waitTime);

  const getNetwork = () => {
    let networkList: INetworkSpec[] = networks.list;
    networkList = networkList
      .filter((i: INetworkSpec) => {
        if (selectedProtocolKey.toLowerCase().includes('polkadot')) {
          return (
            i.protocolKey.toLowerCase().includes(selectedProtocolKey.toLowerCase()) ||
            i.protocolKey.toLowerCase().includes('substrate')
          );
        }
        return i.protocolKey.toLowerCase().includes(selectedProtocolKey.toLowerCase());
      })
      .filter((i) => {
        if (selectedNetworkType === '') return true;
        let keyArray: string[] = [];
        i.nodeTypes.forEach((e) => keyArray.push(e));
        return keyArray.indexOf(selectedNetworkType.toLowerCase()) !== -1;
      })
      .filter((i: INetworkSpec) => filterFilm(filterWord + '', i.displayName ?? i.name))
      .filter((i: INetworkSpec) => i.services.includes('api'));
    return networkList;
  };

  const avaliableNetworks = getNetwork();
  if (networks.state === 'pending') {
    return <Loader isLoading={true}></Loader>;
  }
  return (
    <PageLayout>
      <FixedMainHeader>
        <AntdRow>
          <AntdCol span={6}>
            <Back link={`/workspaces/${wsId}/apiservices`} text={lang(t, 'title')} />
          </AntdCol>
        </AntdRow>
      </FixedMainHeader>
      <MainBox>
        <div className={styles.chooseNetwork}>
          <h2 className={styles.header}>{lang(t, 'apiMarketplace')}</h2>
          <div className={styles.searchAndInputBar}>
            <ButtonTab itemList={Object.keys(tabOptions)} onChange={tabOnChange} className={styles.tabsLength} />
            <Input
              name={'searchWord'}
              ref={searchBar}
              className={styles.searchBar}
              placeholder={'Search'}
              onChange={(e: any) => {
                changeValue(e.target.value);
              }}
              suffix={<SearchOutlined className={styles.suffixIcon} />}
              size={'large'}
            />
          </div>
          <div className={styles.networkCardDiv}>
            {avaliableNetworks.map((network: INetworkSpec, index: number) => {
              return (
                <NetworkSpecCardNew
                  backup={networks.backupMap[network.key]}
                  key={`${network.displayName}_${index}`}
                  network={network}
                  onClick={() => {}}
                  isApi={true}
                  wsId={wsId}
                />
              );
            })}
          </div>
        </div>
      </MainBox>
    </PageLayout>
  );
});

import React from 'react';
import Loading from 'react-loading';
import styles from './style.module.scss';
import { Spin } from 'components';
import cx from 'classnames';

interface IProps {
  isLoading: boolean;
  width?: number;
  height?: number;
  padding?: boolean;
}

export const Loader: React.FC<IProps> = ({ isLoading, children, width, height, padding = true }) => {
  return isLoading ? (
    <div className={cx({ [styles.container]: true, [styles.padding]: padding })}>
      <Spin isLoading />
    </div>
  ) : (
    <>{children}</>
  );
};

import React from 'react';
import { useStore } from '../../store';
import { observer } from 'mobx-react';
import { Button, Dialog } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DetailBox, Loader } from 'components';
import styles from './style.module.scss';
import { ReactComponent as ErrorIcon } from './img/error.svg';
import { ReactComponent as SuccessIcon } from './img/success.svg';

interface IProps {
  status: 'pending' | 'done' | 'error';
  returnUrl?: string;
}

export const CreateNetworkResult: React.FC<IProps> = observer(({ status, returnUrl }) => {
  const { t } = useTranslation();
  const store = useStore();
  const history = useHistory();
  return (
    <Dialog isOpen={true} className={styles.container}>
      {status === 'pending' && (
        <div>
          <div className={styles.creating}>
            <Loader isLoading padding={false} />
            <p>{t('modules.createNetworkResult.creatingMessage')}</p>
          </div>
        </div>
      )}

      {status === 'done' && (
        <div>
          <div>
            <SuccessIcon className={styles.successImg} />
            <p>{t('modules.createNetworkResult.successMessage')}</p>
            <Button
              intent="primary"
              onClick={() => {
                history.replace(returnUrl ?? `/workspaces/${store.workspaces.current!.id}/networks`);
              }}
              className={styles.bottomBtn}
            >
              {t('modules.createNetworkResult.button')}
            </Button>
          </div>
        </div>
      )}

      {status === 'error' && (
        <div>
          <div>
            <ErrorIcon className={styles.errorImg} />
            <p>{t('modules.createNetworkResult.errorMessage')}</p>
            <p>{store.nodes.errorMessage}</p>
            <Button
              intent="primary"
              onClick={() => {
                history.replace(returnUrl ?? `/workspaces/${store.workspaces.current!.id}/networks`);
              }}
              className={styles.bottomBtn}
            >
              {t('modules.createNetworkResult.button')}
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
});

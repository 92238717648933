import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import styles from '../style.module.scss';
import { AntdRow, AntdCol } from 'components';
import { useStore } from '@/store';
import { IdentityProvider } from '@/store/auth';
import { RecendConfirmationButton } from '@/modules';
import { Button, Checkbox, Form, Input } from 'antd';
import { ReactComponent as GoogleIcon } from '@/pages/Login/img/google-icon.svg';
import { ReactComponent as VectorIcon } from '@/pages/Login/img/vector.svg';
import { observer } from 'mobx-react';
import { mxConfig } from '@/config';

const localStorageEmailName = 'mx_email';

interface ICallback {
  gotoForget: () => void;
}

type IProps = ICallback;

export const LoginForm: React.FC<IProps> = observer(({ gotoForget }) => {
  const { auth } = useStore();
  const history = useHistory();
  const [t] = useTranslation();
  const [progressing, setProgressing] = useState(false);
  const [loginEmail, setLoginEmail] = useState(localStorage.getItem(localStorageEmailName) || '');
  const [rememberMe, setRememberMe] = useState(!!localStorage.getItem(localStorageEmailName));
  const [showRecendEmail, setShowRecendEmail] = useState(false);
  const { control, handleSubmit, register, errors, getValues } = useForm();

  if (auth.isLoggedIn()) {
    if (rememberMe) {
      const values = getValues();
      if (values.email) {
        localStorage.setItem(localStorageEmailName, values.email);
      }
    } else localStorage.setItem(localStorageEmailName, '');
    return (
      <Redirect
        to={
          auth.inviteData
            ? `/invite?token=${auth.inviteData.token}`
            : new URLSearchParams(history.location.search).get('redirectUrl') ?? '/'
        }
      />
    );
  }

  const doSubmit = async (val: any) => {
    const { email, password } = val;
    setProgressing(true);
    setLoginEmail(email);
    localStorage.setItem(localStorageEmailName, email);
    await auth.userLogin(email, password);
    if (auth.errorCode === 'unauthorized') setShowRecendEmail(true);
    if (auth.inviteData) {
      history.push(`/invite?token=${auth.inviteData.token}`);
    }
    setProgressing(false);
  };

  const isNotEmail = (v: string) => {
    return !v ? t('pages.login.error.email') : undefined;
  };
  const isNotPassword = (v: string) => {
    return !v ? t('pages.login.error.password') : undefined;
  };

  return (
    <>
      <AntdRow className={styles.formTitle}>
        <AntdCol span={12}>
          <h2 className={styles.title}>{t('pages.login.loginTitle')}</h2>
        </AntdCol>
        <AntdCol span={12} alignRight className={styles.subFormTitle}>
          {!mxConfig.featureFlags.isWhiteLabelSite && (
            <>
              {`${t('pages.login.or')} `}
              <span className={styles.signUp} onClick={() => history.push('/signup')}>
                {t('pages.login.signup')}
              </span>
            </>
          )}
        </AntdCol>
      </AntdRow>
      <Button
        icon={<GoogleIcon />}
        block
        size={'large'}
        className={styles.loginButton}
        onClick={() => auth.authorize(IdentityProvider.Google)}
      >
        {t('pages.login.loginWithGoogle')}
      </Button>
      <Button
        icon={<VectorIcon />}
        block
        size={'large'}
        className={styles.loginButton}
        onClick={() => auth.authorize(IdentityProvider.Github)}
      >
        {t('pages.login.loginWithGitHub')}
      </Button>

      {!mxConfig.featureFlags.isWhiteLabelSite && (
        <div className={styles.moreLogin}>
          <span className={styles.dividing}>
            <span>{t('pages.login.otherLogin')}</span>
          </span>
        </div>
      )}

      <Form onFinish={() => handleSubmit(doSubmit)()} layout="vertical" autoComplete="off" scrollToFirstError>
        <Form.Item name="email" label={t('pages.login.email')}>
          <Controller
            name="email"
            defaultValue={loginEmail}
            rules={{ validate: { inputTelRequired: isNotEmail } }}
            control={control}
            ref={register}
            as={<Input type="email" size={'large'} placeholder={t('pages.login.emailPlaceholder')} />}
          />
          <span className={styles.errorNotice}>{errors.email ? errors.email.message : ''}</span>
        </Form.Item>

        <Form.Item name="password" label={t('pages.login.password')} hasFeedback>
          <Controller
            name="password"
            rules={{ validate: { inputTelRequired: isNotPassword } }}
            control={control}
            ref={register}
            as={<Input.Password type="password" size={'large'} placeholder={t('pages.login.passwordPlaceholder')} />}
          />
          <span className={styles.errorNotice}>{errors.password ? errors.password.message : ''}</span>
        </Form.Item>

        <AntdRow>
          <AntdCol span={12}>
            <Checkbox
              checked={rememberMe}
              onChange={() => {
                setRememberMe(!rememberMe);
              }}
            >
              {t('pages.login.rememberMe')}
            </Checkbox>
          </AntdCol>
          <AntdCol span={12} alignRight>
            <span className={styles.forgetPW} onClick={gotoForget}>
              {t('pages.login.forgetPassword')}{' '}
            </span>
          </AntdCol>
        </AntdRow>
        {showRecendEmail && <RecendConfirmationButton />}
        <Button
          htmlType="submit"
          size={'large'}
          type={'primary'}
          disabled={progressing}
          className={styles.submitButton}
        >
          {t('pages.login.login')}
        </Button>
      </Form>
    </>
  );
});

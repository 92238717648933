import { InputGroup, Switch, HTMLSelect, Intent } from '@blueprintjs/core';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { DataMapItem, RuleAction } from './helpers';
import { LaunchConfigurationValueType } from '@/components/Preview/help';
import { ArrayComponent, UploadFile, ButtonEmpty } from 'components';
import styles from './style.module.scss';
import _ from 'lodash';
import { ReactComponent as TrushIcon } from './imgs/trush.svg';
import React, { useState, useEffect } from 'react';
import { createNewHumpKey } from '@/pages/NetworkCreate/helpers';

interface IProps {
  showError?: boolean;
  data: DataMapItem;
  section?: string;
  onCallback: (data: DataMapItem, deleteData?: DataMapItem) => void;
}

export const Item: React.FC<IProps> = observer(({ data, onCallback, section, showError = false }) => {
  const disabled = !(data.originalOverwritable !== undefined ? data.originalOverwritable : data.overwritable);
  const { key, overwritable } = data;
  const [originalHasChange, setOriginalHasChange] = useState(showError);
  const [canShowError, setCanShowError] = useState(false);
  const originalKey = data.key;
  const originalHumpKey = data.humpKey;

  useEffect(() => {
    if (originalHasChange !== showError) setCanShowError(true);
  }, [showError]);

  const whenOnChange = (value: DataMapItem) => {
    if (data.action !== RuleAction.ADD) {
      data.action = RuleAction.UPSERT;
    }
    if (data.key !== originalKey) {
      // if (!data.key) {
      //   data.key = originalKey;
      //   data.action = RuleAction.REMOVE;
      // } else {
      data.humpKey = createNewHumpKey(data.key, section);
      data.action = RuleAction.ADD;
      // }
    }
    onCallback(
      value,
      data.key !== originalKey
        ? {
            ...data,
            key: originalKey,
            humpKey: originalHumpKey,
            action: RuleAction.REMOVE,
          }
        : undefined,
    );
  };
  const onChange = _.debounce(whenOnChange, 300);

  return (
    <tr>
      <td>
        <InputGroup
          name={'key'}
          intent={showError && canShowError && !data['key'] ? Intent.DANGER : Intent.NONE}
          disabled={disabled || data.valueType === LaunchConfigurationValueType.SystemContext}
          defaultValue={key + ''}
          onBlur={(e) => {
            data['key'] = e.target.value.trim();
            whenOnChange(data);
          }}
        />
      </td>
      <td>
        <HTMLSelect
          disabled={disabled || data.valueType === LaunchConfigurationValueType.SystemContext}
          name={'valueType'}
          value={data.valueType}
          onChange={(e: any) => {
            data.valueType = e.target.value;
            if (data.valueType === LaunchConfigurationValueType.Array) data.payload = [];
            if (data.valueType === LaunchConfigurationValueType.String) data.payload = '';
            if (data.valueType === LaunchConfigurationValueType.Empty) data.payload = '';
            if (data.valueType === LaunchConfigurationValueType.File)
              data.payload = {
                source: '',
                destination: '',
              };
            onChange(data);
          }}
        >
          <option value={LaunchConfigurationValueType.String}>{LaunchConfigurationValueType.String}</option>
          <option value={LaunchConfigurationValueType.Array}>{LaunchConfigurationValueType.Array}</option>
          <option value={LaunchConfigurationValueType.File}>{LaunchConfigurationValueType.File}</option>
          <option value={LaunchConfigurationValueType.Empty}>{LaunchConfigurationValueType.Empty}</option>
          {data.valueType === LaunchConfigurationValueType.SystemContext && (
            <option value={LaunchConfigurationValueType.SystemContext}>
              {LaunchConfigurationValueType.SystemContext}
            </option>
          )}
        </HTMLSelect>
      </td>
      <td>
        {data.valueType === LaunchConfigurationValueType.SystemContext && (
          <InputGroup
            name={'payload'}
            disabled={disabled}
            defaultValue={data.payload}
            onChange={(e) => {
              data['payload'] = e.target.value.trim();
              onChange(data);
            }}
          />
        )}
        {/* {data.valueType === LaunchConfigurationValueType.Empty && (
          <Switch
            className={styles.switch}
            large
            // checked={!data.overwritable || data.valueType === LaunchConfigurationValueType.SystemContext}
            // disabled={disabled || data.valueType === LaunchConfigurationValueType.SystemContext}
            onChange={(e: any) => {
              // if (data.originalOverwritable === undefined) data.originalOverwritable = data.overwritable;
              // data.overwritable = !e.target?.checked;
              // onChange(data);
            }}
          />
        )} */}
        {data.valueType === LaunchConfigurationValueType.String && (
          <InputGroup
            name={'payload'}
            disabled={disabled}
            defaultValue={data.payload}
            onChange={(e) => {
              data['payload'] = e.target.value.trim();
              onChange(data);
            }}
          />
        )}
        {data.valueType === LaunchConfigurationValueType.Array && (
          <ArrayComponent
            disabled={disabled}
            onSubmit={(value) => {
              data['payload'] = value;
              onChange(data);
            }}
            data={data.payload}
          />
        )}
        {data.valueType === LaunchConfigurationValueType.File && (
          <UploadFile
            showInput={true}
            disabled={disabled}
            defaultValue={data.payload?.destination}
            onUploaded={(url: string, fileName: string) => {
              data['payload'] = {
                source: url,
                destination: fileName,
              };
              onChange(data);
            }}
          />
        )}
      </td>
      <td className={styles.lock}>
        <Switch
          className={styles.switch}
          large
          checked={!data.overwritable || data.valueType === LaunchConfigurationValueType.SystemContext}
          disabled={disabled || data.valueType === LaunchConfigurationValueType.SystemContext}
          onChange={(e: any) => {
            if (data.originalOverwritable === undefined) data.originalOverwritable = data.overwritable;
            data.overwritable = !e.target?.checked;
            onChange(data);
          }}
        />
      </td>
      <td className={styles.delete}>
        <ButtonEmpty
          disabled={disabled || data.valueType === LaunchConfigurationValueType.SystemContext || data.key === '--name'}
          intent={Intent.DANGER}
          icon={<TrushIcon />}
          onClick={() => {
            data.action = RuleAction.REMOVE;
            onCallback(data);
          }}
        />
      </td>
    </tr>
  );
});

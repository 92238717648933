import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import cx from 'classnames';
import { Button, Intent, FormGroup, InputGroup, TextArea, ControlGroup, Switch } from '@blueprintjs/core';
import { Controller, useForm } from 'react-hook-form';
import { Col, Row, Preview, ArrayComponent, UploadFile, DetailBox, SubmitButton } from 'components';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { useStore } from 'store';
import { LaunchConfigurationValueType, TabsId } from '@/components/Preview/help';
import { Popover } from 'antd';
import { StopOutlined } from '@ant-design/icons';

export const initNodeFormData = {
  initFromBackup: true,
  useApiKey: true,
  metadata: {},
  nodeType: 'full',
  useRecommend: true,
  config: {
    vars: [],
    extraArgs: {},
    extraEnvs: [],
  },
};
interface IProps {
  item: any;
  basePath: string | undefined | null;
  section: string;
  sections?: Array<string>;
  setTabId: (key: TabsId) => void;
  changeValue: (key: string, value: any, section: string, sections?: Array<string>) => void;
}

export const label = (
  key: any,
  disable: boolean,
  valueType: LaunchConfigurationValueType,
  nodeDisplayName: string | undefined,
) => {
  return (
    <span className={styles.formItemWarning}>
      {valueType === LaunchConfigurationValueType.Deleted && (
        <Popover
          content={
            <div>
              <p>
                This rule has been removed by <b>{nodeDisplayName || 'network'}</b>,
                <br /> it won’t impact performance of the node.
              </p>
            </div>
          }
          trigger="hover"
        >
          <StopOutlined />
        </Popover>
      )}

      {!!disable && (
        <Popover
          content={
            <div>
              <p>
                <b>{nodeDisplayName || 'network'}</b> has updated this rule.
              </p>
            </div>
          }
          trigger="hover"
        >
          <span className={styles.changeIcon} />
        </Popover>
      )}
      {key}
    </span>
  );
};

export const LaunchConfigurationItem: React.FC<IProps> = observer(
  ({ item, changeValue, section, sections, setTabId, basePath }) => {
    const { nodes } = useStore();
    const [alreadyShowContextsMap, setAlreadyShowContextsMap] = useState<{
      [key: string]: string | null | undefined;
    }>({});
    const { control } = useForm();

    let alreadyShowContexts = [] as any;
    const { overwritable, action, humpKey, disable, valueType, payload, key } = item;
    if ((overwritable || (!overwritable && disable !== undefined)) && action !== 'remove') {
      if (humpKey) {
        return (
          <FormGroup
            inline
            key={humpKey}
            label={label(key, disable, valueType, nodes?.current?.networkSpec?.displayName)}
          >
            {valueType === LaunchConfigurationValueType.Empty && (
              <>
                <Switch
                  large
                  disabled={!overwritable}
                  checked={payload !== false}
                  onChange={(e: any) => {
                    changeValue(
                      key,
                      payload === undefined || payload === true || payload === '' ? false : true,
                      section,
                    );
                  }}
                />
              </>
            )}
            {valueType === LaunchConfigurationValueType.Array && (
              <ArrayComponent
                disabled={!overwritable}
                onSubmit={(data) => {
                  setTabId(TabsId.ARG);
                  changeValue(key, data, section);
                }}
                data={payload}
              />
            )}
            {valueType === LaunchConfigurationValueType.File && (
              <UploadFile
                basePath={basePath}
                disabled={!overwritable}
                defaultValue={payload.destination && basePath ? `${basePath}/${payload.destination}` : ''}
                onUploaded={(url: string, fileName: string) => {
                  setTabId(TabsId.ARG);
                  changeValue(
                    key,
                    {
                      destination: fileName,
                      source: url,
                    },
                    section,
                  );
                }}
              />
            )}
            {valueType === LaunchConfigurationValueType.String && (
              <Controller
                name={humpKey}
                large
                defaultValue={payload}
                control={control}
                onChange={([e]) => {
                  changeValue(key, e.currentTarget.value, section);
                }}
                as={
                  <InputGroup
                    fill
                    required
                    disabled={!overwritable}
                    onFocus={() => {
                      setTabId(TabsId.ARG);
                    }}
                  />
                }
              />
            )}
            {valueType === LaunchConfigurationValueType.SystemContext && (
              <InputGroup
                name={humpKey}
                large
                fill
                placeholder={'<system generated>'}
                value={alreadyShowContextsMap[humpKey] ? alreadyShowContextsMap[humpKey] : payload}
                required
                onFocus={() => {
                  setTabId(TabsId.ARG);
                }}
                onChange={(e) => {
                  setAlreadyShowContextsMap({
                    ...alreadyShowContextsMap,
                    [humpKey]: e.currentTarget.value,
                  });
                  changeValue(key, e.currentTarget.value, section, sections);
                }}
              />
            )}
            {valueType === LaunchConfigurationValueType.SystemContext && alreadyShowContexts.push(humpKey) && null}
          </FormGroup>
        );
      } else {
        return (
          <FormGroup
            inline
            key={key}
            label={label(key, disable, valueType, nodes?.current?.networkSpec?.displayName)}
            className={styles.alreadyDeleted}
          >
            {Array.isArray(payload) && <ArrayComponent disabled onSubmit={(data) => {}} data={payload} />}
            {typeof payload === 'string' && (
              <Controller
                name={key}
                large
                defaultValue={payload}
                control={control}
                as={
                  <InputGroup
                    disabled
                    fill
                    required
                    onFocus={() => {
                      setTabId(TabsId.ARG);
                    }}
                  />
                }
              />
            )}
          </FormGroup>
        );
      }
    }
    return null;
  },
);

import React, { useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  ErrorToast,
  SuccessToast,
  DetailBox,
  Row,
  Col,
  MainBox,
  Font16,
  NetworkSpecCardNew,
  ButtonNoBorder,
  Loader,
} from 'components';
import { Modal, Form, Input, Select, Button, Space, Popover } from 'antd';
import { CreateApp } from './createApp';
import { useStore } from '../../store';
import * as yup from 'yup';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as NonameImg } from './imgs/img.svg';
import { EnvironmentType } from '../ApiServices/createApp';
import { INetworkSpec } from '@/store/networks';
import styles from './welcome.module.scss';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

interface IProps {
  wsId: string;
}

const schema = yup.object().shape({
  displayName: yup.string().required(),
  environment: yup.string().required(),
});

export const Welcome: React.FC<IProps> = observer(({ wsId }) => {
  const { apiService, networks } = useStore();
  const history = useHistory();
  const [processing, setProcessing] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [popularNetworksLoading, setPopularNetworksLoading] = useState(true);
  const [popularNetworks, setPopularNetworks] = useState<INetworkSpec[]>([]);
  const { t } = useTranslation();
  const { handleSubmit, register, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      displayName: '',
      environment: EnvironmentType[0].value,
    },
  });
  const lang = (t: TFunction, key: string, opt?: any) => t(`pages.apiServices.${key}`, opt);

  const creatKey = async (displayName: string, environment: string) => {
    setProcessing(true);
    await apiService.createApp(wsId, displayName, environment);
    if (apiService.state === 'done') {
      SuccessToast(t('Success'));
    } else if (apiService.state === 'error') {
      ErrorToast(apiService.errorMessage || t('Error'));
    }
    setProcessing(false);
  };

  const onSubmit = (data: any) => {
    creatKey(data.displayName, data.environment);
  };

  useEffect(() => {
    (async () => {
      setPopularNetworksLoading(true);
      const a = await apiService.getPopularNetworkDetail();
      setPopularNetworks(a);
      setPopularNetworksLoading(false);
    })();
  }, []);

  return (
    <MainBox>
      <div className={styles.welcomeContainer}>
        <DetailBox>
          <Row>
            <Col unit={16}>
              <h2>{lang(t, 'welcome.title')}</h2>
              <Font16 className={styles.welcomeWords}>
                <span dangerouslySetInnerHTML={{ __html: lang(t, 'welcome.subTitle') }}></span>
              </Font16>
              <CreateApp wsId={wsId} text={lang(t, 'welcome.createApp')} />
            </Col>
            <Col unit={8} alignRight>
              <NonameImg width={300} height={160} />
            </Col>
          </Row>
        </DetailBox>
        <Loader isLoading={popularNetworksLoading}>
          <Row>
            <Col unit={24}>
              <div className={styles.popularTitle}>
                {lang(t, 'welcome.mostPopularNetworks')}
                <ButtonNoBorder
                  className={styles.showAllNerworksBtn}
                  size="large"
                  onClick={() => {
                    history.push(`/workspaces/${wsId}/apiservices/all-networks`);
                  }}
                >
                  {lang(t, 'welcome.exploreAllNetworks')}
                </ButtonNoBorder>
              </div>
            </Col>
          </Row>
          <Row>
            <Col unit={24}>
              <div className={styles.networkCardDiv}>
                {popularNetworks.map((network, index) => {
                  return (
                    <NetworkSpecCardNew
                      backup={networks.backupMap[network.key]}
                      key={`${network.displayName}_${index}`}
                      network={network}
                      onClick={() => {}}
                      isApi={true}
                      wsId={wsId}
                    />
                  );
                })}
              </div>
            </Col>
          </Row>
        </Loader>
      </div>
      <Modal
        title="Create App"
        visible={isOpenModal}
        footer={false}
        onCancel={() => {
          setIsOpenModal(false);
        }}
      >
        <Form
          layout="vertical"
          onFinish={() => {
            handleSubmit(onSubmit)();
          }}
        >
          <Form.Item
            label={lang(t, 'welcome.displayName')}
            help={errors?.displayName?.message}
            validateStatus={errors?.displayName?.message ? 'error' : ''}
          >
            <Controller
              name="displayName"
              control={control}
              ref={register}
              as={<Input type="text" size={'large'} placeholder={lang(t, 'welcome.displayName')} />}
            />
          </Form.Item>

          <Form.Item
            label={
              <Space>
                {lang(t, 'welcome.environment')}
                <Popover
                  content={<div className={styles.createAppInformation}>{lang(t, 'welcome.createAppInformation')}</div>}
                >
                  <InfoCircleOutlined />
                </Popover>
              </Space>
            }
            help={errors?.environment?.message}
            validateStatus={errors?.environment?.message ? 'error' : ''}
          >
            <Controller
              name="environment"
              control={control}
              ref={register}
              as={
                <Select size={'large'} onChange={() => {}} allowClear>
                  {EnvironmentType.map((i) => (
                    <Option key={i.value} value={i.value}>
                      <Space>
                        {i.icon}
                        {i.label}
                      </Space>
                    </Option>
                  ))}
                </Select>
              }
            />
          </Form.Item>
          <div className={styles.textRight}>
            <Button loading={processing} size={'large'} type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </MainBox>
  );
});

import { Spin } from 'antd';
import React from 'react';

interface Props {
  spinning?: boolean;
}

export const PopupSpinner: React.FC<Props> = ({ spinning }) => {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <Spin spinning={spinning}></Spin>
    </div>
  );
};

import cx from 'classnames';
import React from 'react';
import { TFunction } from 'i18next';
import { Instance } from 'mobx-state-tree';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { Card } from '@/store/payment-methods';
import { ReactComponent as Visa } from './img/visa.svg';
import { Col, Row, ErrorToast, SuccessToast } from 'components';
import { useStore } from 'store';
import { lang } from './helper';
import { Button, Modal, Space, Radio } from 'antd';
import { ExclamationCircleOutlined, CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';

interface IParams {
  card: Instance<typeof Card>;
  isInUse: boolean;
  method: any;
  isDefault?: boolean;
  showDefault?: boolean;
}

export const CreditCardNew: React.FC<IParams> = ({ isDefault, showDefault, card, isInUse, method }) => {
  const { t } = useTranslation();
  const { paymentMethods, workspaces } = useStore();
  const [modal, contextHolder] = Modal.useModal();

  const confirm = () => {
    modal.confirm({
      title: lang(t, 'deleteTitle'),
      icon: <ExclamationCircleOutlined style={{ color: '#FF4756' }} />,
      content: lang(t, 'alert.msg', { last4Digits: card.cardNumber }),
      okText: lang(t, 'alert.confirm'),
      okType: 'danger',
      cancelText: lang(t, 'alert.cancel'),
      onOk: async () => {
        await paymentMethods.remove(method);
        if (paymentMethods.state === 'error') {
          ErrorToast(paymentMethods.errorMessage!);
        } else {
          await workspaces.fetchPayment(workspaces.current!.id);
          await paymentMethods.fetchList();
          SuccessToast(lang(t, 'alert.success'));
        }
      },
      centered: true,
    });
  };

  const setDefault = () => {
    modal.confirm({
      title: lang(t, 'setDefault.title'),
      icon: <InfoCircleOutlined className={styles.confirmIcon} />,
      content: lang(t, 'setDefault.msg', { last4Digits: card.cardNumber }),
      okText: lang(t, 'setDefault.confirm'),
      cancelText: lang(t, 'setDefault.cancel'),
      onOk: async () => {
        await workspaces.updatePaymentMethod(workspaces.current!, { paymentMethodId: method.id });
        if (workspaces.state === 'error') {
          ErrorToast(workspaces.errorMessage!);
        } else {
          await workspaces.fetchPayment(workspaces.current!.id);
          SuccessToast(lang(t, 'setDefault.success'));
        }
      },
      centered: true,
    });
  };

  return (
    <>
      <div className={styles.card}>
        <Visa className={styles.mb24} />
        <p className={styles.cardNumber}>{card.cardNumber + ' | Expires ' + card.expMonth + '/' + card.expYear}</p>
        <Row>
          <Col unit={16}>
            {!showDefault && isInUse && (
              <Space className={styles.inUse}>
                <CheckCircleFilled className={styles.circle} />
                {lang(t, 'inUse')}
              </Space>
            )}

            {showDefault && isDefault && (
              <Space className={styles.inUse}>
                <CheckCircleFilled className={styles.circle} />
                {lang(t, 'default')}
              </Space>
            )}
            {showDefault && !isDefault && (
              <Space className={styles.inUse}>
                <Radio checked={false} onClick={setDefault}>
                  {lang(t, 'setAsDefault')}
                </Radio>
              </Space>
            )}
          </Col>
          <Col unit={8} alignRight>
            {!isInUse && (
              <Button
                className={styles.cancelBtn}
                type="link"
                onClick={() => {
                  confirm();
                }}
              >
                {lang(t, 'removeBtn')}
              </Button>
            )}
          </Col>
        </Row>
      </div>
      {contextHolder}
    </>
  );
};

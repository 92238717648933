import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import styles from './style.module.scss';
import cx from 'classnames';
import { LaunchConfigurationValueType } from './help';
import { useTranslation } from 'react-i18next';

export interface IProps {
  showDelete?: boolean;
  notPreview?: boolean;
  data: any;
  basePath?: {
    payload?: string;
  };
}

const showDeleteTypeData = (data: any) => {
  const { t } = useTranslation();
  if (typeof data.payload === 'object') {
    return data.payload.map((i: string) => (
      <p className={cx(styles.deleted)}>
        {/* <Tooltip trigger="hover" title={t('components.preview.alreadyDeletedRule')} placement="topLeft"> */}
        {data.key}
        {i && '='}
        {i}
        {/* </Tooltip> */}
      </p>
    ));
  } else {
    return (
      <p className={cx(styles.deleted)}>
        {/* <Tooltip trigger="hover" title={t('components.preview.alreadyDeletedRule')} placement="topLeft"> */}
        {data.key}
        {data.payload && '='}
        {data.payload}
        {/* </Tooltip> */}
      </p>
    );
  }
};

export const Item: React.FC<IProps> = observer(
  ({ showDelete = false, notPreview = true, data, basePath = { payload: '' } }) => {
    // if (data.disable) return null;
    return (
      <>
        {showDelete && data.valueType === LaunchConfigurationValueType.Deleted && showDeleteTypeData(data)}
        {data.valueType === LaunchConfigurationValueType.Array && (
          <>
            {/* {data.payload.length === 0 && (
              <p>
                {data.key}
                <>
                  =<span className={cx({ [styles.overwritable]: data.overwritable })}></span>
                </>
              </p>
            )} */}
            {data.payload.map((arrayItem: string, index: number) => {
              if (!arrayItem) return <p key={index}>{data.key}=</p>;
              return (
                <p key={index}>
                  {data.key}
                  {arrayItem && (
                    <>
                      =<span className={cx({ [styles.overwritable]: data.overwritable })}>{arrayItem}</span>
                    </>
                  )}
                </p>
              );
            })}
          </>
        )}
        {data.valueType === LaunchConfigurationValueType.SystemContext && (
          <p>
            {data.key}=
            <span
              className={cx({
                [styles.generate]: !data.overwritable || !data?.payload,
                [styles.overwritable]: data.overwritable,
              })}
            >
              {data?.payload ? data.payload : `<system generated>`}
            </span>
          </p>
        )}
        {data.valueType === LaunchConfigurationValueType.Empty && data.payload !== false && (
          <p className={cx({ [styles.overwritable]: data.overwritable })}>{data.key}</p>
        )}
        {data.valueType === LaunchConfigurationValueType.String && (
          <p>
            {data.key}=
            {data.payload && (
              <>
                <span className={cx({ [styles.overwritable]: data.overwritable })}>{data.payload}</span>
              </>
            )}
          </p>
        )}
        {data.valueType === LaunchConfigurationValueType.File && (
          <p>
            {data.key}=
            <span className={cx({ [styles.overwritable]: data.overwritable })}>
              {typeof data.payload === 'string' ? data.payload : `${basePath?.payload}/${data.payload.destination}`}
            </span>
          </p>
        )}
      </>
    );
  },
);

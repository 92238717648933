import React, { CSSProperties } from 'react';
import cx from 'classnames';
import { Row, Col, RowProps, ColProps } from 'antd';
import './style.scss';

interface IColProps extends ColProps {
  alignRight?: boolean;
}

export const AntdRow: React.FC<RowProps> = ({ children, ...other }) => <Row {...other}>{children}</Row>;

export const AntdCol: React.FC<IColProps> = ({ children, alignRight, className, ...other }) => {
  return (
    <Col className={cx(className, alignRight ? 'alignRight' : '')} {...other}>
      {children}
    </Col>
  );
};

import { observer } from 'mobx-react';
import { App as AppType } from '@/store/apiService';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
dayjs.extend(dayjsPluginUTC as any, { parseToLocal: true });

export const lang = (t: TFunction, key: string, options?: any) => t(`pages.apiServices.${key}`, options);

const oneHour = 60 * 60 * 1000;

export enum Colors {
  PRIMARY = '#47D764',
  LIGHT_PRIMARY = '#6879f5',
  ERROR = '#FF4756',
  WARNING = '#FF952B',
}

export const defaultChartOptions = {
  notMerge: true,
  loadingOption: {
    text: '',
    color: '#3f57f5',
    lineWidth: 3,
    spinnerRadius: 15,
    zlevel: 0,
  },
};
export interface AppKV {
  [key: string]: {
    displayName: string;
    active: boolean;
  };
}
export interface ChartDataType {
  x: string[];
  y: number[];
  dateMap: {
    [time: string]: {
      [appName: string]: number;
    };
  };
  appKV: AppKV;
}

const tooltipValue = (t: any, name: string, duration: string) => {
  if (duration === '24h') {
    return `${lang(t, 'appList.chartFormatter.time')}: ${name}`;
  } else {
    return `${lang(t, 'appList.chartFormatter.date')}: ${name}`;
  }
};

const initXY = (duration: string) => {
  let x = [] as string[];
  let y = [] as any;
  const dateMap = {} as {
    [time: string]: {
      [appName: string]: number;
    };
  };
  if (duration === '24h') {
    let i = 1;
    while (i < 25) {
      // @ts-ignore
      const thisDay = dayjs().utc().add(-i, 'hours').format('MM-DD HH:00');
      x.unshift(thisDay);
      y.unshift(0);
      dateMap[thisDay] = {};
      i++;
    }
  } else {
    const end = duration === '30d' ? 30 : 7;
    let i = 1;
    while (i < end + 1) {
      // @ts-ignore
      const thisDay = dayjs().utc().subtract(i, 'day').format('YYYY-MM-DD');
      x.unshift(thisDay);
      y.unshift(0);
      dateMap[thisDay] = {};
      i++;
    }
  }
  return { x, y, dateMap };
};

export const everyAppUsageChartData = (data: Instance<typeof AppType>[], duration: string): ChartDataType => {
  const appKV = {} as AppKV;
  const { x, y, dateMap } = initXY(duration);
  const dataTemplate = {} as { [key: string]: number };
  data.forEach((i) => {
    appKV[i.appId] = {
      displayName: i.displayName,
      active: i.active,
    };
    dataTemplate[i.appId] = 0;
  });

  data.forEach((app) => {
    app.data.forEach((item) => {
      const theDay = dayjs(item.time)
        // @ts-ignore
        .utc()
        .format(duration === '24h' ? 'MM-DD HH:00' : 'YYYY-MM-DD');
      // if (!dateMap[theDay]) dateMap[theDay] = { ...dataTemplate };
      if (dateMap[theDay] && !dateMap[theDay][app.appId]) dateMap[theDay][app.appId] = 0;
      if (dateMap[theDay]) dateMap[theDay][app.appId] += item.count;
      // if (!x.includes(theDay)) {
      //   x.push(theDay);
      //   y.push(0);
      // }
      const dayIndexInArray = x.indexOf(theDay);
      y[dayIndexInArray] += item.count;
    });
  });
  return { x, y, dateMap, appKV };
};

const progressColor = (data: number, limit: number | null) => {
  if (limit === null)
    return {
      value: 0,
      className: '',
    };
  if (limit !== 0 && data / limit >= 1)
    return {
      value: 100,
      className: 'over100',
    };
  else
    return {
      value: 0,
      className: '',
    };
};

export const charOpt = (t: any, data: ChartDataType, duration: string, limit: number | null) => {
  const { x, y, dateMap, appKV } = data;
  let pieces = [] as any;
  y.forEach((i, index) => {
    const tempPices = {
      lte: index,
      color: limit === null ? Colors.LIGHT_PRIMARY : i >= limit && limit !== 0 ? Colors.ERROR : Colors.LIGHT_PRIMARY,
    } as any;
    if (index > 0) {
      tempPices.gt = index - 1;
    }
    pieces.push(tempPices);
  });
  return {
    xAxis: {
      data: x,
      type: 'category',
      axisLine: {
        lineStyle: {
          color: '#bfbfbf',
        },
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        // show: true,
        lineStyle: {
          color: '#bfbfbf',
        },
      },
    },
    tooltip: {
      trigger: 'item',
    },
    calculable: true,
    visualMap: {
      show: false,
      dimension: 0,
      pieces: pieces,
    },
    grid: {
      left: '1%',
      right: '1%',
      bottom: '3%',
      top: '6%',
      containLabel: true,
    },
    series: {
      data: y,
      // markPoint: {
      //   data: [{ symbol: "rect", value: -1, xAxis: 1, yAxis: 429067, color: 'red' }]
      // },
      symbolSize: 5,
      itemStyle: {
        normal: {
          borderWidth: 1,
        },
      },
      lineStyle: {
        width: 1,
      },
      type: 'line',
      color: Colors.LIGHT_PRIMARY,
      tooltip: {
        position: 'left',
        formatter: (param: any) => {
          const { name, data } = param;

          return `<div class='chartTotal'>
          <h5>${lang(t, 'appList.chartFormatter.title')}<h5>
          <div class='date'>${tooltipValue(t, name, duration)}</div>
          <h5>${lang(t, 'appList.chartFormatter.totalRequests')} <span class='${
            progressColor(data, limit).className
          }'}>${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></h5>
          <ul>${Object.keys(dateMap[name])
            .map((appId: string) => {
              if (dateMap[name][appId] <= 0) return;
              return `<li class='${!appKV[appId].active ? lang(t, 'appList.chartFormatter.deleted') : ''}'><span>${
                appKV[appId].displayName
              }</span><span>${dateMap[name][appId].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${
                !appKV[appId].active ? ` (${lang(t, 'appList.chartFormatter.deleted')})` : ''
              }</span></li>`;
            })
            .join('')}</ul>
            ${
              progressColor(data, limit).value >= 100
                ? `<div class='overLimit'>${lang(t, 'appList.chartFormatter.overLimit')}</div>`
                : ''
            }
          </div>`;
        },
      },
    },
  };
};

const format = (v: number | undefined | null) => {
  if (!v) return 0;
  return (v + '').replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
};

export const percentage = (v: number | undefined, limit: number | undefined) => {
  if (!v || !limit) return 0;
  return Math.floor((v / limit) * 1000) / 10;
};

export const PercentageTag: React.FC<{ value: number }> = observer(({ value }) => {
  if (value < 70) {
    return <Tag color={'success'}>{value}%</Tag>;
  } else if (value < 90) {
    return <Tag color={'warning'}>{value}%</Tag>;
  } else {
    return <Tag color={'error'}>{value}%</Tag>;
  }
});

import { observer } from 'mobx-react';
import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { ChartPropsNew, colourBlue, colourRed, defaultChartOptions, generateTimeSeriesTimestampCustom } from '.';
import { chartDateLabelNew } from '@/utils/tools';
import dayjs from 'dayjs';
import { ReactComponent as NoResult } from './imgs/noResult.svg';
import { ReactComponent as NoResult2 } from './imgs/noResult2.svg';
import styles from './style.module.scss';

type dataMap = { [key: number]: any };

interface errorObject {
  list: {
    cluster: string;
    count: number;
  }[];
  count: number;
}

interface errorSetObject {
  [key: string]: errorObject;
}

const ErrorTypeMap = {
  '0': 'NoError',
  '1': 'Unknown Error',
  '2': 'Service Error',
  '3': 'Node Error',
  '4': 'Client Error',
  '5': 'Too Many Requests',
  '6': 'Too Many Connections',
} as { [key: string]: string };

const squashToDaily = (hourlyMap: dataMap, timeSeries: number[]): dataMap => {
  const result: dataMap = {};
  // Each day
  for (const timestamp of timeSeries) {
    if (!result[timestamp]) {
      result[timestamp] = { count: 0, countFailed: 0 };
    }
    const day = dayjs(timestamp * 1000).format('YY-MM-DD');
    // Each hour in the source data
    Object.keys(hourlyMap).map((x) => {
      const hourTimestamp = parseInt(x, 10);
      const hourTimestampString = dayjs(parseInt(x, 10) * 1000)
        .utc()
        .format('YY-MM-DD');
      if (day === hourTimestampString) {
        result[timestamp].count += hourlyMap[hourTimestamp].count;
        result[timestamp].countFailed += hourlyMap[hourTimestamp].countFailed;
      }
    });
  }
  return result;
};

const mergeError = (currentError: errorSetObject, comingError: errorSetObject) => {
  Object.keys(comingError).forEach((e: string) => {
    if (currentError[e]) {
      currentError[e].count += comingError[e].count;
    } else {
      currentError[e] = comingError[e];
    }
  });
  return currentError;
};

const ApiRequestChartNew: React.FC<ChartPropsNew> = observer(
  ({ chartData, errorData, loading, startTime, endTime, transFn, duration, twentyFourH }) => {
    if (!chartData) {
      return <></>;
    }
    let statsTimeMapOrigin: dataMap = chartData[Object.keys(chartData)[0]];

    let statsErrorMapOrigin: dataMap = errorData ? errorData[Object.keys(errorData)[0]] : {};
    let statsErrorMap: { [key: string]: { [key: string]: any } } = {};

    if (statsErrorMapOrigin && duration) {
      Object.keys(statsErrorMapOrigin).forEach((e) => {
        statsErrorMap[
          dayjs(+e * 1000)
            .utc()
            .format('DD/MM/YYYY HH:mm')
        ] = statsErrorMapOrigin[+e];
      });
    } else if (statsErrorMapOrigin && !duration) {
      Object.keys(statsErrorMapOrigin).forEach((e: any) => {
        if (
          statsErrorMap[
            dayjs(+e * 1000)
              .utc()
              .format('DD/MM/YYYY')
          ] === undefined
        ) {
          statsErrorMap[
            dayjs(+e * 1000)
              .utc()
              .format('DD/MM/YYYY')
          ] = statsErrorMapOrigin[+e];
        } else {
          statsErrorMap[
            dayjs(+e * 1000)
              .utc()
              .format('DD/MM/YYYY')
          ] = mergeError(
            statsErrorMapOrigin[+e],
            statsErrorMap[
              dayjs(+e * 1000)
                .utc()
                .format('DD/MM/YYYY')
            ],
          );
        }
      });
    }

    let statsTimeMap: any = {};

    if (statsTimeMapOrigin && duration) {
      Object.keys(statsTimeMapOrigin).forEach((e: string) => {
        statsTimeMap[
          dayjs(parseInt(e) * 1000)
            .utc()
            .format('YY-MM-DD HH:mm')
        ] = statsTimeMapOrigin[parseInt(e)];
      });
    } else {
      statsTimeMap = statsTimeMapOrigin;
    }

    let dataMap: any = {};
    let fullDataMap: any = {};
    const timeSeries = generateTimeSeriesTimestampCustom(startTime, endTime, false, duration);

    timeSeries.forEach((e) => {
      fullDataMap[dayjs(e * 1000).format('HH:mm')] = dayjs(e * 1000).format('YYYY-MM-DD HH');
      dataMap[dayjs(e * 1000).format('HH:mm')] = dayjs(e * 1000).format('DD/MM/YYYY HH:mm');
    });
    const successLegend = transFn ? transFn('apiRequestsCountSuccess') : 'Success';
    const errorLegend = transFn ? transFn('apiRequestsCountError') : 'Error';

    if (!duration) {
      if (statsTimeMap) statsTimeMap = squashToDaily(statsTimeMap, timeSeries);
    }
    const series: any[] = [
      {
        data: [],
        type: 'bar',
        stack: 'x',
        name: errorLegend,
        color: colourRed,
      },
      {
        data: [],
        type: 'bar',
        stack: 'x',
        name: successLegend,
        color: colourBlue,
      },
    ];
    for (const time of timeSeries) {
      let found = undefined;
      if (statsTimeMap) {
        found = statsTimeMap[dayjs(time * 1000).format('YY-MM-DD HH:mm')] || statsTimeMap[time];
      }
      if (found) {
        series[0].data.push(found.countFailed);
        series[1].data.push(found.count - found.countFailed);
      } else {
        series[0].data.push(0);
        series[1].data.push(0);
      }
    }

    const cData = !statsTimeMap ? [] : series;
    const lName = !statsTimeMap ? [] : [successLegend, errorLegend];
    const chartOptions = {
      notMerge: true,
      loadingOption: {
        text: '',
        color: '#3f57f5',
        lineWidth: 3,
        spinnerRadius: 15,
        zlevel: 0,
      },
      tooltip: {
        trigger: 'axis',
        formatter: (param: any[]) => {
          if (param.length === 0) {
            return `N/A`;
          }
          const dateName = dataMap[param[0].name] ?? param[0].name;
          const selectErrorData = statsErrorMap[dateName];
          let tooltip = `<div  class="chart-tooltip chart-median-response">`;
          tooltip += `<table>`;
          tooltip += `<tr><td colspan="3" class="header" style="color: #262626; font-size: 14px">API Responses</td></tr>`;
          tooltip += `<tr><td colspan="3" class="date-time" style="color: #8C8C8C; font-size: 10px">${dateName}</td></tr>`;
          tooltip += `<tr>`;
          tooltip += `<td colspan = '2' style="color: #262626; font-size: 12px">Total Responses</td>`;
          tooltip += `<td class="figure" style="color: #262626; font-size: 12px">${param
            .reduce((total, series) => total + series.data, 0)
            .toLocaleString()}</td>`;
          tooltip += `</tr>`;
          let value = '';
          for (const series of param) {
            const { data, seriesName, color } = series;
            let innerValue = '';
            innerValue += `<tr>`;
            // Dot
            innerValue += `<td style='width: 12px'><div style="background:${color};width:12px;height:12px;border-radius:2px"></div></td>`;
            // Label
            innerValue += `<td style="color: #262626; font-size: 12px">${seriesName}</td>`;
            // Value
            innerValue += `<td class="figure" style="color: #262626; font-size: 12px">${(
              data || 0
            ).toLocaleString()}</td>`;
            innerValue += `</tr>`;
            value = innerValue + value;
          }
          tooltip += value;
          if (selectErrorData) {
            Object.keys(selectErrorData).forEach((errorCode: string) => {
              if (errorCode === '0') return;
              tooltip += `<tr>`;
              tooltip += `<td style='width: 12px'><div style="width:12px;height:12px;"></div></td>`;
              tooltip += `<td style="color: #8C8C8C; font-size: 12px;">${ErrorTypeMap[errorCode]}</td>`;
              tooltip += `<td class="figure" style="color: #8C8C8C; font-size: 12px">${selectErrorData[
                errorCode
              ].count.toLocaleString()}</td>`;
              tooltip += `</tr>`;
            });
          }
          tooltip += `</table>`;
          tooltip += `</div>`;
          return tooltip;
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      legend: {
        data: lName,
        left: '0%',
        itemWidth: 16,
        itemHeight: 16,
      },
      calculable: true,
      xAxis: {
        data: timeSeries.map((x) => chartDateLabelNew(x * 1000, duration ? '24h' : '7d')),
        axisLine: {
          lineStyle: {
            color: '#8c8c8c',
          },
          show: true,
        },
      },
      yAxis: {
        axisLine: {
          lineStyle: {
            color: '#8c8c8c',
          },
        },
      },
      series: cData,
    };

    return (
      <div className={styles.positionR}>
        {!statsTimeMap ? (
          twentyFourH ? (
            <div className={styles.noDataDiv}>
              <NoResult className={styles.noResult} />
            </div>
          ) : (
            <div className={styles.noDataDiv}>
              <NoResult2 className={styles.noResult} />
            </div>
          )
        ) : (
          ''
        )}
        <ReactEcharts showLoading={loading} option={chartOptions} {...defaultChartOptions} />
      </div>
    );
  },
);

export default ApiRequestChartNew;

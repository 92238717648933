import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Progress } from 'antd';
import { useStore } from '@/store';
import styles from '../style.module.scss';
import dayjs from 'dayjs';
import { numberAndUnit } from '@/utils/tools';
import { percentage, lang, Colors } from '../helpers';
import dayjsPluginUTC from 'dayjs-plugin-utc';
dayjs.extend(dayjsPluginUTC as any, { parseToLocal: true });

const lastDay = (billingDay: number | null) => {
  if (!billingDay) return '--';
  const hasNextMonth = billingDay < +dayjs().format('DD');
  let endDate;
  if (hasNextMonth) {
    const endOfNextMonth = +dayjs().add(1, 'month').endOf('month').format('DD');
    endDate = dayjs()
      .add(1, 'month')
      .startOf('month')
      .add((endOfNextMonth < billingDay ? endOfNextMonth : billingDay) - 1, 'day');
  } else {
    const endOfThisMonth = +dayjs().endOf('month').format('DD');
    endDate = dayjs()
      .startOf('month')
      .add((endOfThisMonth < billingDay ? endOfThisMonth : billingDay) - 1, 'day');
  }
  return dayjs(endDate).diff(dayjs(), 'day') + 1;
};

export const Monthly: React.FC = observer(() => {
  const { apiService, workspaces, usageCenter, plan } = useStore();
  const { t } = useTranslation();
  const progressValue = percentage(apiService.totalUsage, usageCenter.limit?.apiServiceLimit);
  const lastDayThisMonth = lastDay(workspaces!.current!.billingDay);

  const progressColor = () => {
    if (progressValue >= 100) return Colors.WARNING;
    else return Colors.PRIMARY;
  };

  return (
    <>
      <Progress
        className={styles.progressCycle}
        width={150}
        type="circle"
        strokeColor={progressColor()}
        percent={progressValue}
        format={() => (
          <>
            <div className={styles.usage}>{numberAndUnit(apiService.totalUsage)}</div>
            <div className={styles.title}>{lang(t, 'appList.monthlyProgressSubtitle')}</div>
            {progressValue >= 100 && <div className={styles.overageRates}>{lang(t, 'appList.overageRates')}</div>}
          </>
        )}
      />
      {usageCenter.limit?.apiServiceLimit === 0 && (
        <p className={styles.limitNumber}>{lang(t, 'appList.unlimitedRequests')}</p>
      )}
      {usageCenter.limit?.apiServiceLimit !== 0 && (
        <>
          <p className={styles.limitNumber}>{`${lang(t, 'appList.requestLimitMonth', {
            num: numberAndUnit(usageCenter.limit?.apiServiceLimit),
          })}`}</p>
          <p className={styles.limitNumberAdditional}>
            {lang(t, 'appList.requestLimitTimeUnitDay', {
              day: lastDayThisMonth,
            })}
          </p>
        </>
      )}
    </>
  );
});

import React, { useEffect, useState } from 'react';
import { Button, Classes, Dialog, FormGroup, Intent, InputGroup } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { FormSelect, RadioSelect, SubmitButton, FormError } from 'components';
import { observer } from 'mobx-react';
import { plans } from '@/configuration';
import { useStore } from '../../store';
import * as yup from 'yup';

const lang = (t: TFunction, key: string) => t(`modules.createWorkspaceModal.${key}`);

interface IProps {
  isOpen: boolean;
  onClose: (event?: React.SyntheticEvent<HTMLElement>) => void;
  onSubmit: (data: any) => void;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  plan: yup.string().required(),
  paymentMethodId: yup.string(),
});

export const CreateWorkspaceModal: React.FC<IProps> = observer(({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const { paymentMethods } = useStore();
  const [cards, setCards] = useState<Array<{ label: string; value: any }>>([]);
  const [progressing, setProgressing] = useState(false);
  const { control, register, handleSubmit, errors } = useForm({ validationSchema: schema });

  useEffect(() => {
    (async () => {
      await paymentMethods.fetchList();
      setCards(paymentMethods.list.map((p) => ({ label: `${p.card.cardNumber} (${p.card.expiryDate})`, value: p.id })));
    })();
  }, []);

  const doSubmit = handleSubmit(async (val) => {
    setProgressing(true);
    await onSubmit(val);
    setProgressing(false);
  });

  return (
    <Dialog
      title={lang(t, 'title')}
      usePortal={true}
      isCloseButtonShown={true}
      isOpen={isOpen}
      onClose={onClose}
      canOutsideClickClose={false}
    >
      <form onSubmit={doSubmit}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={lang(t, 'name')}>
            <Controller name="name" large control={control} as={<InputGroup intent={Intent.NONE} />} />
            <FormError>{errors.name && errors.name.message}</FormError>
          </FormGroup>

          <FormGroup label={lang(t, 'subscriptionPlan')}>
            <Controller
              name="plan"
              as={
                <RadioSelect
                  options={plans
                    .filter((p) => p.permission.includes('canBeChooseWhenCreateWorkspace'))
                    .map((p) => ({ label: t(`pages.apiServices.plan.${p.name}`), value: p.name }))}
                />
              }
              defaultValue={'developer'}
              control={control}
            />
          </FormGroup>
          {!!cards.length && (
            <FormGroup label={lang(t, 'paymentMethod')}>
              <Controller
                name="paymentMethodId"
                as={<FormSelect options={cards} />}
                control={control}
                defaultValue={cards[0].value}
              />
            </FormGroup>
          )}
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button type="button" text={lang(t, 'button.close')} onClick={onClose} />
            <SubmitButton loading={progressing} disabled={progressing} type="submit" intent={Intent.PRIMARY}>
              {lang(t, 'button.create')}
            </SubmitButton>
          </div>
        </div>
      </form>
    </Dialog>
  );
});

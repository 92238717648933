import { Alert, Button, Intent } from '@blueprintjs/core';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'react-loading';
import { DetailBox, Col, CreditCardNew, Row, Toast, ComponentHeader } from 'components';
import { useStore } from '@/store';
import { PaymentMethod } from '@/store/payment-methods';
import { Workspace } from '@/store/workspaces';
import { PaymentMethodDialog } from '../PaymentMethodDialog';
import styles from './style.module.scss';

interface IProps {
  className?: string;
}

const lang = (t: TFunction, key: string) => t(`modules.accountPayMethod.${key}`);

const isInUse = (
  paymentMethod: Instance<typeof PaymentMethod>,
  workspaces: Array<Instance<typeof Workspace>>,
): boolean => {
  return workspaces.findIndex((ws) => ws.paymentMethodId === paymentMethod.id) > -1;
};

export const AccountPayMethodNew: React.FC<IProps> = observer(({ className }) => {
  const { paymentMethods, workspaces } = useStore();
  const { t } = useTranslation();
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    (async () => {
      await paymentMethods.fetchList();
    })();
  }, []);

  if (paymentMethods.list.length === 0) {
    return null;
  }
  return (
    <DetailBox className={className}>
      <ComponentHeader>
        <h3>{lang(t, 'title')}</h3>
      </ComponentHeader>

      <div className={styles.cardDiv}>
        {paymentMethods.list.map((method) => {
          if (isInUse(method, workspaces.list)) {
            return (
              <CreditCardNew
                key={method.id}
                card={method.card}
                isInUse={isInUse(method, workspaces.list)}
                method={method}
              />
            );
          }
        })}
        {paymentMethods.list.map((method) => {
          if (!isInUse(method, workspaces.list)) {
            return (
              <CreditCardNew
                key={method.id}
                card={method.card}
                isInUse={isInUse(method, workspaces.list)}
                method={method}
              />
            );
          }
        })}
      </div>
    </DetailBox>
  );
});

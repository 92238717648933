import React, { useState } from 'react';
import { useStore } from 'store';
import { useForm } from 'react-hook-form';
import styles from './style.module.scss';
import { FormError } from 'components';
import { FormGroup } from '@blueprintjs/core';

interface UploadInput {
  file: File;
}

interface IProps {
  defaultValue?: string;
  label: string;
  onUploaded: (url: string) => void;
}

export const UploadLogo: React.FC<IProps> = ({ label, onUploaded, defaultValue }) => {
  const [logo, setLogo] = useState<string | ArrayBuffer>('');
  const [uploading, setLoading] = useState(false);
  const [fileMsg, setFileMsg] = useState({
    name: '',
    msg: '',
  });
  const { networks } = useStore();

  const { register, errors, setError, getValues, clearError } = useForm();
  const onChange = async () => {
    clearError();
    const file = getValues('file')[0];
    const { name, size, type } = file || {
      name: '',
      size: 0,
      type: '',
    };
    setFileMsg({ name, msg: `Size ${Math.round(size / 10) / 100}k Format: ${type}` });
    if (!file || networks.state === 'pending') return;
    if (file.size > 1000 * 1000) {
      return setError('file', 'message', `Image file size must not exceed 1M!`);
    }
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    setLoading(true);
    const { key } = await networks.uploadFile(bodyFormData);
    setLoading(false);
    if (key && reader.result) {
      setLogo(reader.result);
      onUploaded(key);
    }
  };

  return (
    <div className={styles.uploadForm}>
      <form>
        <FormGroup label={label}>
          <div className={styles.flexBox}>
            <div className={styles.formContainer} style={{ backgroundImage: `url(${logo || defaultValue})` }}>
              {((!defaultValue && !fileMsg.name) || errors.file?.message) && (
                <div className={styles.fileName}>
                  <span>+</span>
                  <span>Upload</span>
                </div>
              )}
              {uploading && 'loading'}
              <input name="file" type="file" ref={register} onChange={onChange} accept="image/*" />
            </div>
            <div className={styles.notice}>
              Recommend resolution is 640*640 with file size less than 2MB, keep visual elements centered
            </div>
          </div>
          <p className={styles.fileMsg}>
            {fileMsg.name}
            <br />
            {fileMsg.msg}
          </p>
        </FormGroup>
        <FormError>{errors.file && errors.file?.message}</FormError>
      </form>
    </div>
  );
};

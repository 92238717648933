import React from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ZoomIcon } from './imgs/zoom.svg';

export const NoData: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.nodata}>
      <ZoomIcon />
      <div>{t('components.noData')}</div>
    </div>
  );
};

import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import { INetworkSpec } from '@/store/networks';
import { allowNodeType, Backup } from '@/store/networks';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import defaultIcon from '@/themes/default/networkDefaultIcon.svg';
import { ReactComponent as BackupIcon } from './imgs/backup.svg';
import { Popover, Tag, Button } from 'antd';
import { ButtonNoBorder } from '../ButtonNoBorder';
import { useStore } from '@/store';
import styles from './style.module.scss';
import { CreateApp } from '@/pages/ApiServices/createApp';
import cx from 'classnames';
import { mxConfig } from '@/config';

interface IProps {
  backup?: Instance<typeof Backup>[];
  network: INetworkSpec;
  showStatus?: boolean;
  onClick: () => void;
  isApi?: boolean;
  wsId?: string;
}

const lang = (t: TFunction, key: string, options?: any) => t(`components.${key}`, options);

export const NetworkSpecCardNew: React.FC<IProps> = observer(({ network, onClick, backup = [], isApi, wsId }) => {
  const { t } = useTranslation();
  const { workspaces } = useStore();
  const allowShowBackup = allowNodeType(backup);
  const [logo, setLogo] = useState(network?.metadata?.pictures?.icon || network?.pictures?.icon || defaultIcon);

  const networkNodeTypes = Array.from(
    network.nodeTypes.reduce((acc, nodeType) => {
      acc.add(nodeType.key);
      return acc;
    }, new Set<string>()),
  );

  const backupMessage =
    !!allowShowBackup.length &&
    allowShowBackup
      .map((i) => {
        if (networkNodeTypes.includes(i)) return `<span>- ${i} Node</span>`;
      })
      .join('');

  return (
    <div className={cx(styles.container, !isApi ? styles.boxHeight : styles.boxHeightApi)}>
      <div className={styles.iconBox}>
        <img
          onError={() => {
            setLogo(defaultIcon);
          }}
          src={logo}
        />
      </div>
      <div className={styles.networkName}>
        <span className={styles.displayName}>{network.displayName}</span>
      </div>
      <div className={cx(!isApi ? styles.subtitle : styles.subtitleApi)}>{network.subtitle}</div>
      {/* <Text ellipsis>{network.subtitle}</Text> */}
      {!isApi && (
        <div className={styles.tags}>
          {network.nodeTypes.map((e) => {
            return (
              <Tag key={e.key} className={styles.capitalize}>
                {e.key}
              </Tag>
            );
          })}
        </div>
      )}
      <div className={!isApi ? styles.btnLine : styles.btnLineApi}>
        <ButtonNoBorder className={styles.opcity} size="large">
          Info
        </ButtonNoBorder>
        {isApi && wsId ? (
          <CreateApp wsId={wsId} text={lang(t, `networkSpec.connect`)} network={network.key} />
        ) : (
          <Button type="primary" disabled={workspaces.current?.suspendTaskDone} size="large" onClick={onClick}>
            {isApi ? lang(t, `networkSpec.connect`) : lang(t, `networkSpec.deployNode`)}
          </Button>
        )}
      </div>
      {(!network.isMainNet || !!backupMessage) && (
        <div className={styles.topRight}>
          {!network.isMainNet && network.isPublic ? <Tag color="processing">TestNet</Tag> : ''}
          {!!backupMessage && !isApi && (
            <Popover
              placement="rightTop"
              content={
                <div
                  className={styles.backupPopover}
                  dangerouslySetInnerHTML={{
                    __html: `${lang(t, `networkSpec.lightningRestoreOnList`, {
                      href: mxConfig.lightningRestoreDocument,
                    })}${backupMessage}`,
                  }}
                ></div>
              }
            >
              <BackupIcon className={styles.backupIcon} />
            </Popover>
          )}
        </div>
      )}
    </div>
  );
});

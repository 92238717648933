import { flow, types } from 'mobx-state-tree';
import { api } from '../utils/api';
import { pickOutError } from '@/utils/tools';
import { Instance } from 'mobx-state-tree';

export enum InvoiceStatus {
  Pending = 'pending',
  Paid = 'paid',
  Failed = 'failed',
  Upcoming = 'upcoming',
}

export const Coupon = types.model({
  id: types.string,
  amount_off: types.maybeNull(types.number),
  created: types.number,
  currency: types.maybeNull(types.string),
  duration: types.string,
  duration_in_months: types.maybeNull(types.number),
  livemode: types.boolean,
  max_redemptions: types.maybeNull(types.number),
  name: types.string,
  percent_off: types.maybeNull(types.number),
  redeem_by: types.maybeNull(types.number),
  times_redeemed: types.number,
  valid: types.boolean,
  promotion_code: types.maybeNull(types.string),
  promotion_code_name: types.maybe(types.string),
});

export const InvoiceCoupon = types.model({
  code: types.string,
  amountOff: types.maybe(types.number),
  currency: types.maybeNull(types.string),
  percentOff: types.maybeNull(types.number),
  name: types.string,
});

export const Discount = types.model({
  coupon: InvoiceCoupon,
  start: types.string,
});

export const AccessKey = types.model({
  accessKey: types.string,
  accountId: types.string,
  createdAt: types.string,
  updatedAt: types.string,
});

const NewAccessKey = types.model({
  accessKey: types.string,
  secretKey: types.string,
});

export const Invoice = types.model({
  id: types.maybe(types.string),
  workspaceId: types.string,
  currency: types.string,
  periodStart: types.string,
  periodEnd: types.string,
  amount: types.number,
  pdfUrl: types.maybeNull(types.string),
  status: types.enumeration(Object.values(InvoiceStatus)),
  discount: types.maybeNull(Discount),
});

export const Account = types
  .model({
    coupons: types.optional(types.array(Coupon), []),
    stripeCoupons: types.optional(types.array(Coupon), []),
    invoices: types.optional(types.array(Invoice), []),
    accessKeys: types.optional(types.array(AccessKey), []),
    newAccessKey: types.maybe(NewAccessKey),
    // upcomingInvoices: types.optional(types.array(Invoice), []),
    state: types.optional(types.enumeration(['pending', 'done', 'error']), 'done'),
    errorMessage: types.maybe(types.string),
  })
  .actions((self: any) => ({
    getCoupons: flow(function* () {
      self.state = 'pending';
      try {
        const { customer, promotionCode } = yield api.get(`/accounts/coupons`);
        let coupon: Instance<typeof Coupon>;
        if (customer.discount?.coupon && promotionCode?.code) {
          coupon = customer.discount.coupon;
          coupon.promotion_code = customer.discount.promotion_code;
          coupon.promotion_code_name = promotionCode.code;
          self.coupons = [coupon] as any;
        } else {
          self.coupons = [] as any;
        }

        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        console.log(err);
        self.errorMessage = pickOutError(err);
      }
    }),
    getStripeCoupons: flow(function* () {
      self.state = 'pending';
      try {
        const { data } = yield api.get(`/accounts/stripe/coupons`);
        const now = Math.round(new Date().valueOf() / 1000);
        self.stripeCoupons = data.filter((i: Instance<typeof Coupon>) => {
          return (!i.redeem_by || i.redeem_by >= now) && !self.coupons.find((c: any) => c.id === i.id);
        });
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        console.log(err);
        self.errorMessage = pickOutError(err);
      }
    }),
    getInvoices: flow(function* () {
      self.state = 'pending';
      try {
        const [invoices, upcomingInvoices] = yield Promise.all([
          api.get(`/accounts/invoices`),
          api.get(`/accounts/invoices/upcoming`),
        ]);
        self.invoices = [...upcomingInvoices, ...invoices] as any;
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        console.log(err);
        self.errorMessage = pickOutError(err);
      }
    }),
    renewInvoice: flow(function* (invoiceId: string) {
      self.state = 'pending';
      try {
        const invoice_pdf_url = yield api.get(`accounts/invoices/renew_invoices/${invoiceId}`);
        return invoice_pdf_url;
      } catch (err) {
        self.state = 'error';
        console.log(err);
        self.errorMessage = pickOutError(err);
      }
    }),
    getAccessKey: flow(function* () {
      self.state = 'pending';
      try {
        self.accessKeys = yield api.get(`/access-keys`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        console.log(err);
        self.errorMessage = pickOutError(err);
      }
    }),

    addAccessKey: flow(function* () {
      self.state = 'pending';
      try {
        self.newAccessKey = yield api.post(`/access-keys`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    deleteAccessKey: flow(function* (key: string) {
      self.state = 'pending';
      try {
        yield api.delete(`/access-keys/${key}`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
  }));

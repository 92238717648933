import { TFunction } from 'i18next';
import { Instance } from 'mobx-state-tree';
import { Cluster, ICluster, NodeSpec } from '../../store/info';
import { nodeTypeBlockMap, regionBlockMap } from '@/configuration';
import { ReactComponent as AWSIcon } from './img/aws.svg';
import { ReactComponent as AzureIcon } from './img/azure.svg';
import { ReactComponent as GcpIcon } from './img/gcp.svg';
import { ReactComponent as OnfIcon } from './img/Onfinality-Icon.svg';
import { ReactComponent as AlibabacloudIcon } from './img/alibabacloud.svg';
import { mxConfig } from '@/config';

interface ProviderStyleMapType {
  [key: string]: {
    img: any;
    txt: string;
  };
}

export const providerStyleMap: ProviderStyleMapType = {
  aws: {
    img: AWSIcon,
    txt: 'Amazon Web Services',
  },
  gcp: {
    img: GcpIcon,
    txt: 'Google Cloud Platform',
  },
  onf: {
    img: OnfIcon,
    txt: 'OnFinality Cloud',
  },
  ma: {
    img: AzureIcon,
    txt: 'Microsoft Azure',
  },
  ali: {
    img: AlibabacloudIcon,
    txt: 'Ali Cloud',
  },
};

export const lang = (t: TFunction, key: string, options?: any) => t(`pages.joinNetwork.${key}`, options);

export const isNodeTypeDisable = (provider: string | undefined, nodeType: string): boolean => {
  if (!!provider && nodeTypeBlockMap[nodeType]?.includes(provider)) return true;
  return false;
};

export const isRegionDisable = (
  clusters: Instance<typeof Cluster>[],
  region: string,
  provider?: string,
  nodeType?: string,
): boolean => {
  if (!!nodeType && !!region && regionBlockMap[nodeType]?.includes(region)) return true;
  if (!provider) {
    return false;
  }
  return clusters.findIndex((c) => c.region === region && c.cloud === provider) < 0;
};

export const isCloudDisable = (
  clusters: Instance<typeof Cluster>[],
  provider: string,
  region?: string,
  nodeType?: string,
  hash?: string,
): boolean => {
  if (!!provider && !!nodeType && nodeTypeBlockMap[nodeType]?.includes(provider)) return true;
  if (!region) {
    return false;
  }
  return (
    clusters.findIndex((c) => {
      if (hash) return c.hash === hash && c.region === region && c.cloud === provider;
      return c.region === region && c.cloud === provider;
    }) < 0
  );
};

const unitPrice = 0.095;
const storagePrice = 0.0004;

export function GetHourlyPrice(
  spec: Instance<typeof NodeSpec> | undefined,
  storage = 10,
  multiplier = 1,
  isPartner = false,
) {
  if (!spec) {
    return 'N/A';
  }

  if (isPartner && spec.partnerNodePrice && spec.partnerStoragePrice) {
    return (multiplier * spec.partnerNodePrice + storage * spec.partnerStoragePrice).toFixed(2) + ' per hour';
  }
  return (multiplier * unitPrice + storagePrice * storage).toFixed(2) + ' per hour';
}

export function providersFromClusters(clusters: ICluster[]) {
  return Array.from(
    clusters.reduce((acc, cluster) => {
      acc.add(cluster.cloud);
      return acc;
    }, new Set<string>()),
  );
}

export function regionsFromClusters(clusters: ICluster[]) {
  return Array.from(
    clusters.reduce((acc, cluster) => {
      acc.add({
        hash: cluster.hash,
        region: cluster.region,
      });
      return acc;
    }, new Set<{ [key: string]: string }>()),
  );
}

import { number } from 'echarts';
import { boolean, string } from 'mobx-state-tree/dist/internal';
import { RuleAction } from '@/components/UpdateNetworkRules/helpers';
import { action } from 'mobx';
import { ActionBar } from '@/pages/NodeDetail/ActionBar';

export enum TabsId {
  ARG = 'arg',
  ENV = 'env',
  OPS = 'ops',
}

export interface ArgDataFormat {
  action?: RuleAction;
  payload: {
    key: string;
    value: {
      payload: string;
      inputType: string;
    };
    options: {
      overwritable: boolean;
    };
    category: string;
  };
}

export const tempSection = 9999;

interface ParameterFormat {
  arg: Array<string>;
  env: Array<string>;
}

interface ExtraArgsFormat {
  [key: string]: Array<string>;
}

interface ExtraEnvFormat {
  key: string;
  value: string;
}

export interface KvMapFormat {
  [key: string]: any;
}

export enum LaunchConfigurationValueType {
  String = 'string',
  Array = 'array',
  File = 'file',
  SystemContext = 'context',
  Empty = 'empty',
  Deleted = 'deleted',
}

export interface LaunchConfigurationItemFormData {
  disable?: boolean; // has some key less or more then network
  // isError?: boolean; // has some same key
  isInParameter?: boolean;
  action: string | undefined;
  humpKey: string | null;
  key: string | null;
  payloadKey?: string;
  overwritable: boolean;
  payload: any;
  valueType: LaunchConfigurationValueType;
}

export interface LaunchConfigurationComArgRules {
  [key: string]: {
    action?: RuleAction;
    data: Array<LaunchConfigurationItemFormData>;
    extraArgData: Array<string | undefined>;
    extraArgKey: string;
    index: number;
    title: string;
  };
}

export interface LaunchDataMapBaseNodeTypeType {
  comArgRules: LaunchConfigurationComArgRules;
  delimiter: string;
  envRules: Array<LaunchConfigurationItemFormData>;
  envExtra: Array<string | undefined>;
  operationalArgs?: any;
}

// export interface LaunchDataMapDataItemType {
//   humpKey?: string;
//   key?: string;
//   overwritable: boolean;
//   payload?: string;
//   valueType: string;
// }

// export interface LaunchDataMapComArgRulesType {
//   data: Array<LaunchDataMapDataItemType>;
//   extraArgData: ExtraArgsFormat;
//   extraArgKey?: string;
//   index?: number;
//   title?: string;
// }

export interface LaunchDataMapType {
  [key: string]: LaunchDataMapBaseNodeTypeType;
}

/**
 * @description: use to filter no context type data
 * @param {LaunchDataMapBaseNodeTypeType}
 * @return {LaunchDataMapBaseNodeTypeType}
 */
export const withoutContext = (data: LaunchDataMapBaseNodeTypeType) => {
  const result = { ...data };
  const comArgRules = {} as any;
  Object.keys(data.comArgRules).forEach((section: string) => {
    const thisSection = data.comArgRules[section];
    comArgRules[section] = {
      data: [],
      extraArgData: thisSection.extraArgData,
      extraArgKey: thisSection.extraArgKey,
      index: thisSection.index,
      title: thisSection.title,
    };
    thisSection.data.forEach((i: any) => {
      if (i.valueType !== LaunchConfigurationValueType.SystemContext) comArgRules[section].data.push(i);
    });
  });
  result.comArgRules = comArgRules;
  return result;
};

const valueTypeValueMap = (data: any, value: any, basePath: string | undefined | null) => {
  if (data.valueType === LaunchConfigurationValueType.Array) return typeof value === 'string' ? [value] : value;
  else if (data.valueType === LaunchConfigurationValueType.File) {
    if (typeof value === 'object') value = JSON.stringify(value);
    return {
      source: data?.payload?.source ?? '',
      destination: value && basePath ? value.replace(`${basePath}/`, '') : value,
    };
  }
  return value;
};

export const isErrorData = (parameter: Array<string> | null, dataMap: LaunchDataMapBaseNodeTypeType) => {
  const parameterMap = {} as any;
  const parameterKeyMap = {} as any;
  let sectionIndex = 0;
  let result = false as boolean | string;
  if (!dataMap) return false;
  const sectionArray = Object.keys(dataMap.comArgRules);
  if (parameter?.length) {
    parameter.forEach((item: string) => {
      const temp = item.split('=');
      if (dataMap.comArgRules[sectionArray[sectionIndex]]?.extraArgData?.includes(item)) return;
      if (temp[0] === dataMap.delimiter) return (sectionIndex += 1);
      if (!parameterMap[sectionArray[sectionIndex]]) {
        parameterMap[sectionArray[sectionIndex]] = {};
        parameterKeyMap[sectionArray[sectionIndex]] = [];
      }
      parameterKeyMap[sectionArray[sectionIndex]].push(temp[0]);
      if (temp[1]) {
        const existData = parameterMap[sectionArray[sectionIndex]][temp[0]];
        if (existData) {
          if (Array.isArray(existData)) {
            parameterMap[sectionArray[sectionIndex]][temp[0]].push(temp[1]);
          } else parameterMap[sectionArray[sectionIndex]][temp[0]] = [existData, temp[1]];
        } else parameterMap[sectionArray[sectionIndex]][temp[0]] = temp[1];
      } else {
        parameterMap[sectionArray[sectionIndex]][temp[0]] = '';
      }
    });
  }

  sectionArray.find((section: string) => {
    const comArgRules = dataMap.comArgRules[section];
    const extraArgDataKeys = comArgRules.extraArgData.map((i: string | undefined) => {
      return i?.split('=')[0];
    });

    return comArgRules.data.find((previewItem: LaunchConfigurationItemFormData) => {
      if (!!result) return;
      if (extraArgDataKeys.includes(previewItem.key + '')) return (result = section);
      if (previewItem.valueType === LaunchConfigurationValueType.File && !previewItem.payload?.source)
        return (result = true);
      if (
        previewItem.valueType === LaunchConfigurationValueType.File &&
        (typeof parameterMap[section][previewItem.key + ''] !== 'string' ||
          parameterMap[section][previewItem.key + ''] === '')
      )
        return true;
      if (previewItem.action === RuleAction.REMOVE) return (result = false);
    });
  });

  return result;
};

export const parameterPerview = (parameter: Array<string> | null, dataMap: LaunchDataMapBaseNodeTypeType) => {
  let sectionIndex = 0;
  const sectionArray = Object.keys(dataMap.comArgRules);
  const result = {} as any;
  const parameterMap = {} as any;
  const parameterKeyMap = {} as any;
  const alreadyUseKey = {} as any;
  if (parameter?.length) {
    parameter.forEach((item: string) => {
      const temp = item.split('=');
      if (dataMap.comArgRules[sectionArray[sectionIndex]]?.extraArgData?.includes(item)) return;
      if (temp[0] === dataMap.delimiter) return (sectionIndex += 1);
      if (!parameterMap[sectionArray[sectionIndex]]) {
        parameterMap[sectionArray[sectionIndex]] = {};
        parameterKeyMap[sectionArray[sectionIndex]] = [];
      }
      parameterKeyMap[sectionArray[sectionIndex]].push(temp[0]);
      if (temp[1]) {
        const existData = parameterMap[sectionArray[sectionIndex]][temp[0]];
        if (existData) {
          if (Array.isArray(existData)) {
            parameterMap[sectionArray[sectionIndex]][temp[0]].push(temp[1]);
          } else parameterMap[sectionArray[sectionIndex]][temp[0]] = [existData, temp[1]];
        } else parameterMap[sectionArray[sectionIndex]][temp[0]] = temp[1];
      } else {
        parameterMap[sectionArray[sectionIndex]][temp[0]] = '';
      }
    });
  }

  sectionArray.forEach((section: string) => {
    if (!result[section])
      result[section] = {
        ...dataMap.comArgRules[section],
        data: [],
      };
    Object.keys(parameterMap[section]).forEach((key: string) => {
      if (!alreadyUseKey[section]) alreadyUseKey[section] = [];
      if (alreadyUseKey[section].includes(key)) return;
      const finddata = dataMap.comArgRules[section].data.find(
        (data: LaunchConfigurationItemFormData) => data.key === key,
      );
      if (finddata?.key) {
        alreadyUseKey[section].push(finddata.key);
        const isArrayDifferent =
          Array.isArray(parameterMap[section][key]) && finddata.valueType !== LaunchConfigurationValueType.Array;
        const isSystemContext = finddata.valueType === LaunchConfigurationValueType.SystemContext;
        const isStringDifferent =
          typeof parameterMap[section][key] === 'string' &&
          parameterMap[section][key] !== '' &&
          finddata.valueType !== LaunchConfigurationValueType.String;
        const valueType =
          finddata.action === RuleAction.REMOVE || finddata.valueType === LaunchConfigurationValueType.Deleted
            ? LaunchConfigurationValueType.Deleted
            : isArrayDifferent
            ? LaunchConfigurationValueType.Array
            : isSystemContext
            ? LaunchConfigurationValueType.SystemContext
            : isStringDifferent
            ? LaunchConfigurationValueType.String
            : finddata.valueType;
        let payload = parameterMap[section][key];
        if (valueType === LaunchConfigurationValueType.Array && !Array.isArray(payload)) {
          payload = [];
        }
        result[section].data.push({
          ...finddata,
          action: undefined,
          valueType: valueType,
          payload: payload,
        });
      } else {
        result[section].data.push({
          disable: false,
          action: undefined,
          humpKey: null,
          key,
          overwritable: true,
          payload: parameterMap[section][key],
          valueType: Array.isArray(parameterMap[section][key])
            ? LaunchConfigurationValueType.Array
            : LaunchConfigurationValueType.String,
        });
      }
    });
  });

  return {
    ...dataMap,
    comArgRules: result,
  };
};

const changePaylodAdCurrentType = (valueType: LaunchConfigurationValueType, payload: any) => {
  if (!Array.isArray(payload) && valueType === LaunchConfigurationValueType.Array) {
    return [];
  } else if (!payload.source && valueType === LaunchConfigurationValueType.File) {
    return {
      destination: '',
      source: '',
    };
  } else if (
    typeof payload !== 'string' &&
    typeof payload !== undefined &&
    valueType === LaunchConfigurationValueType.String
  ) {
    return payload?.source || '';
  } else {
    return payload;
  }
};

/**
 * @description: unit parameters, flags, extraArgs, and alow use kv map to replase some data
 * @param {*}
 * @return {LaunchDataMapBaseNodeTypeType}
 */
export const mergeLaunchData = (
  dataMap: LaunchDataMapBaseNodeTypeType,
  parameter: Array<string> | null,
  extraArgs: ExtraArgsFormat | null,
  kvMap: KvMapFormat,
  humpKeyMap?: KvMapFormat,
  skippedArgs?: Array<KvMapFormat>,
  nodeConfigVars?: Array<any>,
) => {
  let sectionIndex = 0;
  const parameterMap = {} as any;
  const parameterKeyMap = {} as any;
  if (!nodeConfigVars || !Array.isArray(nodeConfigVars)) nodeConfigVars = [] as Array<any>;
  if (!dataMap) return dataMap;
  const sectionArray = dataMap.comArgRules ? Object.keys(dataMap.comArgRules) : [];

  if (parameter?.length) {
    parameter.forEach((item: string) => {
      const temp = item.split('=');
      if (temp[0] === dataMap.delimiter) return (sectionIndex += 1);
      if (!parameterMap[sectionArray[sectionIndex]]) {
        parameterMap[sectionArray[sectionIndex]] = {};
        parameterKeyMap[sectionArray[sectionIndex]] = [];
      }

      parameterKeyMap[sectionArray[sectionIndex]].push(temp[0]);
      if (temp[1]) {
        const existData = parameterMap[sectionArray[sectionIndex]][temp[0]];
        if (existData) {
          if (Array.isArray(existData)) parameterMap[sectionArray[sectionIndex]][temp[0]].push(temp[1]);
          else parameterMap[sectionArray[sectionIndex]][temp[0]] = [existData, temp[1]];
        } else parameterMap[sectionArray[sectionIndex]][temp[0]] = temp[1];
      } else {
        parameterMap[sectionArray[sectionIndex]][temp[0]] = '';
      }
    });
  }
  let basePath = '' as string | undefined | null;

  sectionArray.forEach((section: string) => {
    const comArgRules = dataMap.comArgRules[section];
    if (extraArgs && extraArgs[comArgRules.extraArgKey]) comArgRules.extraArgData = extraArgs[comArgRules.extraArgKey];

    const basePathObj = comArgRules.data.find((previewItem: any) => previewItem.humpKey === 'basePath');
    if (basePathObj) basePath = basePathObj?.payload;
    dataMap.comArgRules[section].data = comArgRules.data.map((previewItem: any) => {
      if (parameterMap[section] && previewItem.overwritable === false) {
        if (
          previewItem.valueType === LaunchConfigurationValueType.File &&
          ((Object.keys(parameterMap[section]).includes(previewItem.key) &&
            parameterMap[section][previewItem.key].indexOf(previewItem.payload?.destination) < 0) ||
            !Object.keys(parameterMap[section]).includes(previewItem.key))
        ) {
          // when file type, and value different
          previewItem.disable = true;
        } else if (LaunchConfigurationValueType.Empty === previewItem.valueType) {
          if (parameterMap[section][previewItem.key] === undefined) {
            // when empty type in node was disable, but network was locked
            previewItem.disable = true;
          }
        } else if (LaunchConfigurationValueType.String === previewItem.valueType) {
          if (parameterMap[section][previewItem.key] !== previewItem.payload) {
            previewItem.disable = true;
          }
        } else if (LaunchConfigurationValueType.Array === previewItem.valueType) {
          if (
            Array.isArray(parameterMap[section][previewItem.key]) &&
            JSON.stringify(previewItem.payload) !== JSON.stringify(parameterMap[section][previewItem.key])
          ) {
            previewItem.disable = true;
          } else if (
            !Array.isArray(parameterMap[section][previewItem.key]) &&
            parameterMap[section][previewItem.key] !== previewItem.payload[0]
          ) {
            previewItem.disable = true;
          }
        }
      }
      if (parameterMap[section]) {
        if (previewItem.valueType === LaunchConfigurationValueType.SystemContext) {
          if (parameterMap[section][previewItem.key]) {
            previewItem.payload = valueTypeValueMap(previewItem, parameterMap[section][previewItem.key], basePath);
          }
        } else {
          if (previewItem.valueType === LaunchConfigurationValueType.String) {
            if (parameterMap[section][previewItem.key] === undefined && previewItem) {
              // add a new string
              previewItem.disable = true;
            }
            if (Array.isArray(parameterMap[section][previewItem.key])) {
              // if valueType changed from array -> string
              previewItem.disable = true;
            }
          }
          if (previewItem.overwritable && parameterMap[section][previewItem.key]) {
            // when deleted in network
            previewItem.payload = valueTypeValueMap(previewItem, parameterMap[section][previewItem.key], basePath);
          } else if (
            previewItem.overwritable &&
            !parameterKeyMap[section].includes(previewItem.key) &&
            previewItem.valueType !== LaunchConfigurationValueType.Empty
          ) {
            // when added in network
            if (previewItem.payload.length > 0) previewItem.disable = true;
          } else if (
            previewItem.overwritable &&
            !parameterKeyMap[section].includes(previewItem.key) &&
            previewItem.valueType === LaunchConfigurationValueType.Empty
          ) {
            const existInConfig = nodeConfigVars!.find((i) => i.key === previewItem.humpKey);
            if (!existInConfig) previewItem.disable = true;
          }
        }
      }
      if (kvMap[previewItem.key] !== undefined) previewItem.payload = kvMap[previewItem.key];
      if (humpKeyMap && ![null, undefined].includes(humpKeyMap[previewItem.humpKey])) {
        if (
          (!!humpKeyMap[previewItem.humpKey] &&
            typeof humpKeyMap[previewItem.humpKey] !== 'boolean' &&
            LaunchConfigurationValueType.Empty === previewItem.valueType) ||
          (!!humpKeyMap[previewItem.humpKey] && LaunchConfigurationValueType.SystemContext === previewItem.valueType) ||
          (!Array.isArray(humpKeyMap[previewItem.humpKey]) &&
            previewItem.valueType === LaunchConfigurationValueType.Array)
        ) {
          previewItem.disable = true;
        }
        if (previewItem.overwritable)
          previewItem.payload = changePaylodAdCurrentType(previewItem.valueType, humpKeyMap[previewItem.humpKey]);
      }
      if (parameterMap?.[section]) {
        if (parameterMap?.[section]?.[previewItem.key] !== null && previewItem.action !== 'remove') {
          delete parameterMap[section][previewItem.key];
        }
      }
      return previewItem;
    });

    const extraArgDataKeyArray = [] as Array<string>;
    (comArgRules.extraArgData || []).forEach((extraArgDataItem: string | undefined) => {
      if (extraArgDataItem) {
        const temp = extraArgDataItem.split('=');
        extraArgDataKeyArray.push(temp[0]);
      }
    });
    Object.keys(parameterMap[section] || {}).forEach((parameterMapKey: string) => {
      if (extraArgDataKeyArray.includes(parameterMapKey)) return;
      dataMap.comArgRules[section].data.push({
        action: undefined,
        humpKey: '',
        key: parameterMapKey,
        overwritable: true,
        payload: parameterMap[section][parameterMapKey],
        valueType: LaunchConfigurationValueType.Deleted,
      });
    });
  });
  const envKeys = Object.keys(humpKeyMap || {});
  dataMap.envRules = dataMap.envRules.map((i: any) => {
    if (envKeys.includes(i.humpKey)) {
      return {
        ...i,
        payload: humpKeyMap![i.humpKey],
      };
    } else {
      return i;
    }
  });
  return dataMap;
};

/**
 * @description: unit metadata, operations, protocolRules, supportedContext to orgnize a data map
 * @param {isUpdate: if isUpdate = true, set context data overwritable = false}
 * @return {LaunchDataMapBaseNodeTypeType}
 */
export const launchData = (
  metadata: any,
  configOperations?: any,
  protocolRules?: {
    [key: string]: any;
  },
  context?: {
    [key: string]: {
      overwritable?: boolean;
      value: string | undefined;
    };
  },
  isUpdate?: boolean,
): LaunchDataMapType => {
  const { argumentSections, rules } = metadata;
  if (!argumentSections || !rules || Object.keys(rules).length === 0) return {};
  const operations = {} as any;
  let envRules = [] as any;
  let previewDataMap = {} as any;
  let overwriteRules = { ...rules };
  if (configOperations) {
    Object.keys(configOperations).map((key: string) => {
      overwriteRules[key] = {};
      Object.keys(configOperations[key]).map((keyInside: string) => {
        if (!rules[key][keyInside]) rules[key][keyInside] = [];
        // overwriteRules[key][keyInside] = rules[key][keyInside].filter(
        //   (val: any) => val?.options?.overwritable === false,
        // );
        overwriteRules[key][keyInside] = rules[key][keyInside];
        configOperations[key][keyInside].forEach((val: any) => {
          const isExit = !overwriteRules[key][keyInside].find((isExit: any) => isExit?.key === val?.payload?.key);

          if (isExit || val.action === 'remove') {
            overwriteRules[key][keyInside].push({
              ...val.payload,
              action: val.action,
            });
          } else {
          }
        });
      });
    });
  }
  if (!rules) return previewDataMap;
  Object.keys(overwriteRules).map((key: string) => {
    operations[key] = {};
    envRules = [];
    const sortSections = argumentSections.sections.sort((a: any, b: any) => {
      return a.index - b.index;
    });
    sortSections.forEach((item: any) => {
      operations[key][item.index || tempSection] = {
        extraArgKey: item.extraArgKey,
        extraArgData: [],
        title: item.title,
        index: item.index || tempSection,
        data: [],
      };
    });
    overwriteRules[key].arg.map((i: any) => {
      const payload = i.value.payload.split('.') as Array<string>;
      const humpKey = payload[1] || ('' as string);
      const protocolRulesOverwritable = (protocolRules?.[key]?.var || []).find(
        (protocolRulesItem: any) => protocolRulesItem.key === humpKey,
      );
      const originalOverwritable = true;
      // protocolRulesOverwritable === undefined
      //   ? true
      //   : protocolRulesOverwritable
      //   ? protocolRulesOverwritable?.options?.overwritable
      //   : i.options.overwritable;
      if (!operations[key][i.section || tempSection]) return;
      if (payload[0] === LaunchConfigurationValueType.SystemContext) {
        const contextOverwritable = !!context?.[humpKey]?.overwritable;
        operations[key][i.section || tempSection].data.push({
          action: i.action === RuleAction.ADD ? RuleAction.UPSERT : i.action,
          key: i.key,
          humpKey: humpKey,
          overwritable: isUpdate ? false : contextOverwritable,
          originalOverwritable: isUpdate ? false : contextOverwritable,
          valueType: LaunchConfigurationValueType.SystemContext,
          payload: context?.[humpKey]?.value ?? '',
        });
      } else {
        const itemVar = overwriteRules[key].var.find((varItem: any) => varItem.key === humpKey);
        if (itemVar) {
          operations[key][i.section || tempSection].data.push({
            action: i.action === RuleAction.ADD ? RuleAction.UPSERT : i.action,
            key: i.key,
            humpKey: humpKey,
            overwritable: i.options.overwritable,
            originalOverwritable: originalOverwritable,
            valueType: itemVar.value.valueType,
            payload: itemVar.value.payload,
          });
        }
      }
    });

    overwriteRules[key].env &&
      overwriteRules[key].env.map((i: any) => {
        const payload = i.value.payload.split('.');
        const humpKey = payload[1] || '';
        if (payload[0] === LaunchConfigurationValueType.SystemContext) {
          const contextOverwritable = !!context?.[humpKey]?.overwritable;
          envRules.push({
            action: i.action === RuleAction.ADD ? RuleAction.UPSERT : i.action,
            key: i.key,
            humpKey: humpKey,
            overwritable: contextOverwritable,
            valueType: LaunchConfigurationValueType.SystemContext,
            payload: '',
          });
        } else {
          const itemVar = rules[key].var.find((varItem: any) => varItem.key === humpKey);
          if (itemVar) {
            const payload =
              itemVar.value.valueType !== LaunchConfigurationValueType.File || itemVar.value.payload?.source
                ? itemVar.value.payload
                : {
                    source: '',
                    destination: '',
                  };
            envRules.push({
              action: i.action === RuleAction.ADD ? RuleAction.UPSERT : i.action,
              key: i.key,
              humpKey: humpKey,
              overwritable: i.options.overwritable,
              valueType: itemVar.value.valueType,
              payload: payload,
            });
          }
        }
      });

    previewDataMap[key] = {
      delimiter: argumentSections.delimiter,
      comArgRules: operations[key],
      envRules: envRules,
      envExtra: [],
    };
  });
  return previewDataMap;
};

export const previewToVars = (previewMap: any) => {
  const sectionArray = Object.keys(previewMap.comArgRules);
  let vars = [] as any;
  sectionArray.forEach((section: string) => {
    previewMap.comArgRules[section].data.forEach((previewItem: any) => {
      if (previewItem.overwritable && previewItem.humpKey && previewItem.action !== RuleAction.REMOVE) {
        if (previewItem.valueType === LaunchConfigurationValueType.Array && previewItem.payload.join('') === '') {
          return;
        }
        vars.push({
          key: previewItem.humpKey,
          value: {
            valueType: previewItem.valueType,
            payload: previewItem.payload,
          },
        });
      }
    });
  });
  previewMap.envRules.forEach((item: LaunchConfigurationItemFormData) => {
    vars.push({
      key: item.humpKey,
      value: {
        valueType: item.valueType,
        payload: item.payload,
      },
    });
  });
  return vars;
};

export const previewToExtraArgs = (previewMap: any) => {
  const sectionArray = Object.keys(previewMap.comArgRules);
  let extraArgs = {} as any;
  sectionArray.forEach((section: string) => {
    const extraArgKey = previewMap.comArgRules[section].extraArgKey;
    extraArgs[extraArgKey] = [] as Array<string>;
    previewMap.comArgRules[section].extraArgData.forEach((previewItem: any) => {
      extraArgs[extraArgKey].push(previewItem);
    });
  });
  return extraArgs;
};

export const previewToEnvExtraArgs = (envExtra: Array<string | undefined> | undefined | null) => {
  if (!envExtra) return [];
  const result = envExtra.map((i: string | undefined) => {
    const arg = i!.split('=');
    return {
      key: arg[0] && arg[0].trim(),
      value: arg[1] ? arg[1].trim() : '',
    };
  });
  return result;
};

export const previewToContexts = (data: any) => {
  const result = [] as any;
  Object.keys(data.comArgRules).forEach((section: string) => {
    const thisSection = data.comArgRules[section];
    (thisSection?.data || []).forEach((i: any) => {
      if (
        i.valueType === LaunchConfigurationValueType.SystemContext &&
        i.originalOverwritable &&
        !result.find((resultItem: any) => resultItem.key === i.humpKey)
      ) {
        if (i.key === '--node-key' && !i.payload) return;
        result.push({
          key: i.humpKey,
          value: i.payload,
        });
      }
    });
  });
  return result;
};

export const previewToEmpty = (data: any) => {
  const result = [] as any;
  Object.keys(data.comArgRules).forEach((section: string) => {
    const thisSection = data.comArgRules[section];
    (thisSection?.data || []).forEach((i: any) => {
      if (i.valueType === LaunchConfigurationValueType.Empty && i.originalOverwritable && i.payload === false)
        result.push({
          key: i.key,
          section: +section === tempSection ? undefined : +section,
        });
    });
  });
  return result;
};

import React from 'react';
import { useStore } from '@/store';
import { observer } from 'mobx-react';
import { AntdRow, AntdCol } from 'components';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { ErrorToast, SuccessToast } from '@/components';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import styles from '../style.module.scss';
import { mxConfig } from '@/config';

interface ICallback {
  onBack: () => void;
}

interface IProps extends ICallback {}

export const ForgetPasswordForm: React.FC<IProps> = observer(({ onBack }) => {
  const store = useStore();
  const history = useHistory();
  const [t] = useTranslation();
  const { control, handleSubmit, register, errors } = useForm();
  const doSubmit = async (val: any) => {
    const { email } = val;
    await store.auth.changePasswordByMail(email);
    if (store.auth.state === 'done') {
      SuccessToast(t('pages.login.changePasswordSuccess'));
    } else if (store.auth.state === 'error') {
      ErrorToast(store.auth.errorMessage || 'something error');
    }
  };

  const isNotEmail = (v: string) => {
    return !v ? t('pages.login.error.email') : undefined;
  };
  return (
    <>
      <AntdRow className={styles.formTitle}>
        <AntdCol span={12}>
          <h2 className={styles.title}>{t('pages.login.resetPw')}</h2>
        </AntdCol>
        <AntdCol span={12} alignRight className={styles.subFormTitle}>
          {!mxConfig.featureFlags.isWhiteLabelSite && (
            <>
              {`${t('pages.login.or')} `}
              <span className={styles.signUp} onClick={onBack}>
                {t('pages.login.backToLogin')}
              </span>
            </>
          )}
        </AntdCol>
      </AntdRow>
      <h3 className={styles.subTitle}>{t('pages.login.resetPasswrodIntroduce')}</h3>
      <Form onFinish={() => handleSubmit(doSubmit)()} layout="vertical" autoComplete="off" scrollToFirstError>
        <Form.Item name="email" label={t('pages.login.email')}>
          <Controller
            name="email"
            defaultValue=""
            rules={{ validate: { inputTelRequired: isNotEmail } }}
            control={control}
            ref={register}
            as={<Input type="email" size={'large'} placeholder={t('pages.login.emailPlaceholder')} />}
          />
          <span className={styles.errorNotice}>{errors.email ? errors.email.message : ''}</span>
        </Form.Item>
        <Button block htmlType="submit" size={'large'} type={'primary'} className={styles.submitButton}>
          {t('pages.login.sendMail')}
        </Button>
      </Form>
    </>
  );
});

import React, { useEffect, useState } from 'react';
import { useStore } from '../../store';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { DetailBox, FormError } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { ComponentHeader } from 'components';
import { ErrorToast, SuccessToast } from '../../components/Toast';
import { EditOutlined } from '@ant-design/icons';
import { Input, Button } from 'antd';
import cx from 'classnames';
import * as yup from 'yup';

interface IProps {
  className?: string;
}

export const AccountProfileNew: React.FC<IProps> = observer(({ className }) => {
  const { auth } = useStore();
  const { t } = useTranslation();
  const [newUsername, setNewUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [inputOnfocus, setInputOnfocus] = useState(false);

  useEffect(() => {
    setNewUsername(auth.user!.name);
  }, [auth.user]);

  const changeUserName = async () => {
    if (newUsername !== '') {
      await auth.changeName(newUsername.trim());
      if (auth.state === 'error') {
        ErrorToast(auth.errorMessage);
      } else {
        SuccessToast(t('modules.accountProfile.updateNameSuccess'));
        setInputOnfocus(false);
        setNewUsername(auth.user!.name);
      }
    }
  };

  return (
    <DetailBox className={cx(className)}>
      <ComponentHeader>
        <h3>{t('modules.accountProfile.title')}</h3>
      </ComponentHeader>
      <h4>{t('modules.accountProfile.name')}</h4>
      <Input
        className={inputOnfocus ? styles.appNameInput : ''}
        onChange={(e) => {
          setErrorMessage('');
          setNewUsername(e.target.value);

          if (e.target.value.trim() === '') {
            setErrorMessage('');
            setErrorMessage(t('modules.accountProfile.emptyUsername'));
          }

          if (e.target.value.trim().length < 4 && e.target.value.trim().length > 0) {
            setErrorMessage(t('modules.accountProfile.usernameLessFour'));
          }
        }}
        onFocus={() => {
          setInputOnfocus(true);
        }}
        size="large"
        value={inputOnfocus ? newUsername : auth.user!.name}
        suffix={<EditOutlined className={cx(styles.iconBlue)} style={{ color: '#031FEF' }} />}
      />
      {inputOnfocus && (
        <div className={styles.changeNameBtn}>
          <Button
            className={styles.btn}
            onClick={() => {
              setErrorMessage('');
              setInputOnfocus(false);
              setNewUsername(auth.user!.name);
            }}
            size="large"
          >
            Cancel
          </Button>
          <Button
            disabled={newUsername.trim() === auth.user!.name}
            className={cx(styles.btn, styles.btnSave)}
            onClick={changeUserName}
            type="primary"
            size="large"
          >
            Save
          </Button>
        </div>
      )}
      <FormError>{errorMessage}</FormError>
      <br />
      <br />
      <h4>{t('modules.accountProfile.emailAddress')}</h4>
      <Input size="large" value={auth.user!.email} disabled />
    </DetailBox>
  );
});

import { applySnapshot, getSnapshot, types } from 'mobx-state-tree';
import { createBrowserHistory } from 'history';
// @ts-ignore
import makeInspectable from 'mobx-devtools-mst';
import { RouterModel, syncHistoryWithStore } from 'mst-react-router';
import React, { createContext, useContext } from 'react';
import { useLocalStore } from 'mobx-react';
import { Workspaces } from './workspaces';
import { Auth } from './auth';
import { Nodes } from './nodes';
import { Networks } from './networks';
import { NetworkSpec } from './networkSpec';
import { ApiService } from './apiService';
import { Account } from './account';
import { Plan } from './plan';
import { Images } from './images';
import { Info } from './info';
import { UsageCenter } from './usageCenter';
import { PaymentMethods } from './payment-methods';

const browserHistory = createBrowserHistory();
const routerStore = RouterModel.create();

export const history = syncHistoryWithStore(browserHistory, routerStore);

export const Store = types.model({
  router: RouterModel,
  auth: types.optional(Auth, {}),
  workspaces: types.optional(Workspaces, {
    list: [],
  }),
  networks: types.optional(Networks, {}),
  networkSpec: types.optional(NetworkSpec, {}),
  nodes: types.optional(Nodes, {}),
  info: types.optional(Info, {}),
  account: types.optional(Account, {}),
  paymentMethods: types.optional(PaymentMethods, {}),
  images: types.optional(Images, {}),
  apiService: types.optional(ApiService, {}),
  usageCenter: types.optional(UsageCenter, {}),
  plan: types.optional(Plan, {}),
});

const store = Store.create({
  router: routerStore,
});

const initSnapshot = getSnapshot(store);

export function resetStore() {
  applySnapshot(store, initSnapshot);
}

const storeContext = createContext(store);

export function useStore() {
  const store = useContext(storeContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return store;
}

export const StoreProvider: React.FC = (props) => {
  const s = useLocalStore(() => store);
  return <storeContext.Provider value={s}>{props.children}</storeContext.Provider>;
};

if (process.env.NODE_ENV !== 'production') {
  makeInspectable(store);
}

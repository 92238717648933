import React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from '@/components';
import { Button, Classes } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import styles from '@/pages/Login/style.module.scss';
import cx from 'classnames';
import { AntdRow, AntdCol } from 'components';
import { RecendConfirmationButton } from '@/modules';

interface ICallback {
  onBack: () => void;
}

interface IProps extends ICallback {}

export const RegisteredForm: React.FC<IProps> = observer(({ onBack }) => {
  const [t] = useTranslation();

  return (
    <>
      <h2 className={styles.title}>{t('pages.login.createAccount')}</h2>
      <h2 className={styles.title}>{t('pages.login.confirmEmail')}</h2>
      <h3 className={styles.subTitle}>{t('pages.login.confirmEmailSubTitle')}</h3>
      <Row gutter={true}>
        <Col unit={8}>
          <Button large text={t('pages.login.back')} className={styles.submitButton} onClick={onBack} />
        </Col>
        <Col unit={16}>
          <RecendConfirmationButton />
        </Col>
      </Row>
    </>
  );
});

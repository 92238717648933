import React, { useEffect } from 'react';
import { Button, Classes, Dialog, Intent, InputGroup, Alert, Icon, FormGroup, HTMLSelect } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStore } from 'store';
import { capitalize } from 'lodash';
import { SuccessToast, ErrorToast, Row, Col, SubmitButton, Loader } from 'components';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { imageVersionTags } from '@/configuration';
import styles from './style.module.scss';
import { useState } from 'react';
import cx from 'classnames';

const reCheckTimes = 18;

interface IProps {
  isOpen: boolean;
  networkKey: string;
  imageRepository: string;
  onClose: (event?: React.SyntheticEvent<HTMLElement>) => void;
}
const schema = yup.object().shape({
  imageRepository: yup.string().required(),
  newVersion: yup.string().required(),
  tags: yup.string(),
});

export const ImageVersionListModal: React.FC<IProps> = observer(({ isOpen, onClose, networkKey, imageRepository }) => {
  const { t } = useTranslation();
  const { workspaces, images, networkSpec } = useStore();
  const [checking, setChecking] = useState(false);
  const [deleteID, setDeleteId] = useState<undefined | number>(undefined);
  const { control, handleSubmit, errors, setValue, register } = useForm({ validationSchema: schema });
  const checkImageVersion = async (imageRepository: string, imageVersion: string, reCheckTime = 1): Promise<any> => {
    const data = await networkSpec.checkImage(imageRepository, imageVersion);
    if (networkSpec.state === 'error') {
      return networkSpec?.errorMessage || 'Request error';
    }
    const { status } = data;
    if (status === 'fail') {
      return {
        state: 'fail',
        message: t('modules.createNetworkSpecForm.checkFail'),
      };
    } else if ((reCheckTime >= reCheckTimes && status !== 'success') || status === 'timeout') {
      return {
        state: 'fail',
        message: t('modules.createNetworkSpecForm.checkTimeout'),
      };
    } else if (status === 'success') {
      return {
        state: 'success',
        data: data,
      };
    } else {
      let check = false;
      let promise = new Promise((resolve) => {
        setTimeout(async () => {
          check = await checkImageVersion(imageRepository, imageVersion, ++reCheckTime);
          resolve(check);
        }, 5000);
      });
      return promise;
    }
  };

  const updateVersionList = handleSubmit(async (val) => {
    if (!val.newVersion) return;
    setChecking(true);
    const result = await checkImageVersion(imageRepository, val.newVersion);
    if (result.state === 'fail') {
      ErrorToast(result.message);
      return setChecking(false);
    }
    await images.upsert(workspaces.current!.id, networkKey, {
      imageRepository: imageRepository,
      version: val.newVersion,
      tags: val.tags ? [val.tags] : [],
    });
    setChecking(false);
    if (images.state === 'error') {
      ErrorToast(images?.errorMessage);
    } else {
      SuccessToast();
      images.fetchList(workspaces.current!.id, networkKey);
    }
  });
  useEffect(() => {
    if (!isOpen) return;
    images.fetchList(workspaces.current!.id, networkKey);
  }, [isOpen]);

  useEffect(() => {
    return () => {
      images.clearData();
    };
  }, []);

  return (
    <>
      <Dialog
        className={styles.container}
        title={t('modules.imageVersionListModal.title')}
        isOpen={isOpen}
        onClose={onClose}
        canOutsideClickClose={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className={styles.tagGroup}>
            <h3>{t('modules.imageVersionListModal.addImage')}</h3>
            <form onSubmit={updateVersionList}>
              <input type="hidden" name="tags" ref={register} defaultValue={imageVersionTags[0]} />
              <FormGroup inline>
                <Row gutter={true}>
                  <Col unit={9}>
                    <Controller
                      name="imageRepository"
                      control={control}
                      defaultValue={imageRepository}
                      as={<InputGroup disabled value={imageRepository} />}
                    />
                  </Col>
                  <Col unit={5} className={styles.versionsSelect}>
                    <Controller
                      name="newVersion"
                      disabled={checking}
                      control={control}
                      as={
                        <InputGroup intent={errors.newVersion ? Intent.DANGER : Intent.NONE} placeholder={'version'} />
                      }
                    />
                  </Col>
                  <Col unit={6}>
                    <HTMLSelect
                      fill
                      disabled={checking}
                      onChange={(e) => {
                        setValue('tags', e.target.value);
                      }}
                    >
                      {imageVersionTags.map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </HTMLSelect>
                  </Col>
                  <Col unit={4} className={styles.versionsSelect}>
                    <SubmitButton disabled={checking} loading={checking} intent={Intent.PRIMARY} type="submit">
                      {t('modules.imageVersionListModal.addImageButton')}
                    </SubmitButton>
                  </Col>
                </Row>
              </FormGroup>
            </form>

            <h3>{t('modules.imageVersionListModal.imageList')}</h3>
            <Row className={styles.tableHeader}>
              <Col unit={9}>{t('modules.imageVersionListModal.version')}</Col>
              <Col unit={3}>{t('modules.imageVersionListModal.tag')}</Col>
              <Col unit={6}>{t('modules.imageVersionListModal.state')}</Col>
              <Col unit={6} alignRight>
                {t('modules.imageVersionListModal.action')}
              </Col>
            </Row>
            <div className={styles.tableBody}>
              <Loader isLoading={!images.list && images.state === 'pending'} width={20} height={20} padding={false}>
                {images.list.map((i) => (
                  <Row key={i.id} className={cx({ [styles.deactivate]: !i.active })}>
                    <Col unit={9}>{i.version}</Col>
                    <Col unit={3}>{i.tags.join(',')}</Col>
                    <Col unit={6}>
                      {i.active
                        ? t('modules.imageVersionListModal.active')
                        : t('modules.imageVersionListModal.deactivate')}
                    </Col>
                    <Col unit={6} alignRight>
                      {i.active && (
                        <Button
                          small
                          icon={'stop'}
                          onClick={async () => {
                            await images.deactivate(workspaces.current!.id, networkKey, i.id);
                            if (images.state === 'error') ErrorToast(images.errorMessage);
                            else SuccessToast();
                          }}
                        />
                      )}
                      {!i.active && (
                        <Button
                          small
                          icon={'play'}
                          onClick={async () => {
                            await images.activate(workspaces.current!.id, networkKey, i.id);
                            if (images.state === 'error') ErrorToast(images.errorMessage);
                            else SuccessToast();
                          }}
                        />
                      )}
                      <Button
                        small
                        icon={<Icon icon={'trash'} intent={Intent.DANGER} />}
                        onClick={() => setDeleteId(i.id)}
                      />
                    </Col>
                  </Row>
                ))}
              </Loader>
            </div>
          </div>
        </div>
      </Dialog>
      <Alert
        cancelButtonText={capitalize(t('dict.cancel'))}
        confirmButtonText={capitalize(t('dict.confirm'))}
        intent={Intent.DANGER}
        isOpen={!!deleteID}
        onCancel={() => setDeleteId(undefined)}
        onConfirm={async () => {
          await images.delete(workspaces.current!.id, networkKey, deleteID!);
          if (images.state === 'error') ErrorToast(images.errorMessage);
          else {
            SuccessToast();
            setDeleteId(undefined);
          }
        }}
      >
        <p>{t('modules.imageVersionListModal.deleteConfirm')}</p>
      </Alert>
    </>
  );
});

import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
dayjs.extend(dayjsPluginUTC as any, { parseToLocal: true });

export enum Colors {
  PRIMARY = '#47D764',
  LIGHT_PRIMARY = '#6879f5',
  ERROR = '#FF4756',
  WARNING = '#FF952B',
}

export const percentage = (v: number | undefined, limit: number | undefined) => {
  if (!v || !limit) return 0;
  return Math.floor((v / limit) * 1000) / 10;
};

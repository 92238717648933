import React from 'react';
import cx from 'classnames';
import { Button, Intent, IButtonProps } from '@blueprintjs/core';
import styles from './style.module.scss';

interface IProps extends IButtonProps {
  className?: string;
}

export const ButtonEmpty: React.FC<IProps> = (props) => {
  const { loading, className, children, intent, disabled, icon, ...other } = props;
  return (
    <Button
      icon={icon}
      className={cx({
        [styles.btn]: true,
        [styles.hasIcon]: !!icon,
        [styles[intent || '']]: !!intent,
        className: !!className,
      })}
      disabled={!!disabled}
      {...other}
      intent={Intent.NONE}
    >
      {children}
    </Button>
  );
};

import { App, Workspace } from './modules';
import { RouteConfig } from 'react-router-config';
import * as pages from './pages';
import { ErrorPage } from './pages/Error/ErrorPage';
import { Path } from '@progress/kendo-drawing';

export const routes: RouteConfig[] = [
  {
    path: '/login',
    exact: true,
    component: pages.Login,
  },
  {
    path: '/signup',
    exact: true,
    component: pages.SignUp,
  },
  {
    path: '/invite',
    exact: true,
    component: pages.Invite,
  },
  {
    path: '/callback',
    exact: true,
    component: pages.Callback,
  },
  {
    component: App,
    routes: [
      {
        path: '/',
        exact: true,
        component: pages.Home,
      },
      {
        path: '/workspaces/:wsId',
        component: Workspace,
        routes: [
          {
            path: '/workspaces/:wsId',
            exact: true,
            component: pages.Dashboard,
          },
          {
            path: '/workspaces/:wsId/accountPage',
            exact: true,
            component: pages.AccountPage,
          },
          {
            path: '/workspaces/:wsId/dashboard',
            exact: true,
            component: pages.Dashboard,
          },
          {
            path: '/workspaces/:wsId/nodes',
            exact: true,
            component: pages.NodeList,
          },
          {
            path: '/workspaces/:wsId/nodes/join-network',
            component: pages.DeployNewNode,
          },
          {
            path: '/workspaces/:wsId/nodes/:id',
            component: pages.NodeDetail,
          },
          {
            path: '/workspaces/:wsId/networks/create',
            exact: true,
            component: pages.NetworkCreate,
          },
          {
            path: '/workspaces/:wsId/networks',
            exact: true,
            component: pages.Networks,
          },
          {
            path: '/workspaces/:wsId/networks/:id',
            exact: true,
            component: pages.NetworkDetail,
          },
          {
            path: '/workspaces/:wsId/network/:id',
            exact: true,
            component: pages.NetworkDetail,
          },
          {
            path: '/workspaces/:wsId/networkPromote/:id',
            exact: true,
            component: pages.PromoteNetworkDetail,
          },
          {
            path: '/workspaces/:wsId/settings/',
            exact: true,
            component: pages.Settings,
          },
          {
            path: '/workspaces/:wsId/settings/:tab',
            exact: true,
            component: pages.Settings,
          },
          {
            path: '/workspaces/:wsId/apiservices',
            exact: true,
            component: pages.ApiServices,
          },
          {
            path: '/workspaces/:wsId/apiservices/all-networks',
            exact: true,
            component: pages.ApiServicesMarketPlace,
          },
          {
            path: '/workspaces/:wsId/apiservices/app',
            exact: true,
            component: pages.ApiServicesDetail,
          },
          {
            path: '/workspaces/:wsId/apiservices/:appId',
            exact: true,
            component: pages.ApiServicesDetail,
          },
          {
            path: '/workspaces/:wsId/apiservices/:appId/settings',
            exact: true,
            component: pages.ApiServiceAppSettings,
          },
          {
            path: '/workspaces/:wsId/report',
            exact: true,
            component: pages.Report,
          },
          {
            path: '/workspaces/:wsId/usageCenter',
            exact: true,
            component: pages.UsageCenter,
          },
          {
            path: '/workspaces/:wsId/*',
            exact: true,
            component: pages.Error,
          },
        ],
      },
      {
        path: '*',
        component: ErrorPage,
      },
    ],
  },
];

import React, { useRef, useEffect, useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import styles from './style.module.scss';
import { Loader } from 'components';
import { useStore } from '../../store';
import { useTranslation } from 'react-i18next';
import Loading from 'react-loading';
import { ReactComponent as CloseIcon } from './img/close.svg';
import { ReactComponent as FullScreenIcon } from './img/fullscreen.svg';
import { ReactComponent as FullScreenExitIcon } from './img/fullscreenExit.svg';
import cx from 'classnames';

const reloadTimes = 3000;
let reloadLogs: any;
interface IProps {
  nodeId: string;
  onClose: () => void;
}

export const Logs: React.FC<IProps> = observer(({ nodeId, onClose }) => {
  const { workspaces } = useStore();
  const { t } = useTranslation();
  const [isFull, setIsFull] = useState(false);

  const logEndRef = useRef(null);

  const scrollToBottom = () => {
    if (logEndRef.current) {
      // @ts-ignore
      logEndRef.current.scrollTop = logEndRef.current.scrollHeight;
    }
  };

  const loadLogs = async () => {
    clearTimeout(reloadLogs);
    reloadLogs = setTimeout(async () => {
      if (workspaces.currentLogSort) {
        const logEndRefCurrent = logEndRef.current;
        const needScroll =
          (logEndRefCurrent &&
            // @ts-ignore
            logEndRefCurrent.scrollHeight - logEndRefCurrent.scrollTop - logEndRefCurrent.offsetHeight < 30) ||
          false;
        await workspaces.fetchNewestLogs(workspaces.current!.id, nodeId, workspaces.currentLogSort);
        needScroll && scrollToBottom();
      }
      loadLogs();
    }, reloadTimes);
  };

  useEffect(() => {
    (async () => {
      await workspaces.fetchLogs(workspaces.current!.id, nodeId);
      scrollToBottom();
    })();
    loadLogs();
    return () => {
      clearTimeout(reloadLogs);
      workspaces.cleanLogs();
    };
  }, []);
  return (
    <Dialog
      className={cx({ [styles.logsDialog]: true, [styles.logsFull]: isFull })}
      title={t('pages.nodeDetail.buttonGroup.logs')}
      isOpen
      isCloseButtonShown={false}
    >
      <div className={styles.buttons}>
        <button
          onClick={() => {
            setIsFull(!isFull);
          }}
        >
          {isFull ? <FullScreenExitIcon /> : <FullScreenIcon />}
        </button>
        <button onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className={styles.logs} ref={logEndRef}>
        <Loader isLoading={workspaces.logs.length === 0 && workspaces.state === 'progressing'}>
          {workspaces.logs.map((i) => (
            <p key={i.timestamp} className={styles.logItem}>
              {i.message}
            </p>
          ))}
          {workspaces.state === 'progressing' && (
            <div className={styles.loadingSpace}>
              <Loading color={'grey'} type={'spin'} width={24} height={24} />{' '}
            </div>
          )}
        </Loader>
      </div>
    </Dialog>
  );
});

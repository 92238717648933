import React from 'react';
import cx from 'classnames';
import { useStore } from '@/store';
import styles from './style.module.scss';

interface IProps {
  className?: string;
}

export const PageLayout: React.FC<IProps> = ({ children, className }) => {
  const { workspaces } = useStore();
  return (
    <div
      className={cx(styles.page, workspaces.current!.suspendTaskDone && styles.suspendTaskDone, className && className)}
    >
      {children}
    </div>
  );
};

import React from 'react';
import { Spin as AntSpin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface IProps {
  isLoading: boolean;
}

export const Spin: React.FC<IProps> = ({ isLoading, children }) => {
  return (
    <AntSpin indicator={<LoadingOutlined style={{ fontSize: 36 }} />} spinning={isLoading}>
      {children}
    </AntSpin>
  );
};

import { flow, types, getSnapshot, Instance } from 'mobx-state-tree';
import { api } from '../utils/api';
import dayjs from 'dayjs';
import { chartDateLabel, pickOutError } from '@/utils/tools';
import cloneDeep from 'lodash/cloneDeep';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import { AxiosResponse } from 'axios';
import { sendEvent } from '@/utils/googleAnalytics';
import { mxConfig } from '@/config';
dayjs.extend(dayjsPluginUTC as any, { parseToLocal: true });

const IusageData = types.model({
  time: types.string,
  count: types.number,
});

export enum WhitelistType {
  IP = 'ip',
  ORIGIN = 'origin',
}

const WhiteList = types.model({
  id: types.string,
  value: types.string,
  appId: types.string,
  type: types.enumeration(Object.values(WhitelistType)),
});

export const App = types.model({
  apiKey: types.string,
  workspaceId: types.string,
  appId: types.string,
  displayName: types.string,
  active: types.boolean,
  createdAt: types.string,
  usage: types.maybe(types.number),
  usageIn24H: types.maybe(types.number),
  weightedUsage: types.maybe(types.number),
  weightedUsageIn24H: types.maybe(types.number),
  data: types.optional(types.array(IusageData), []),
  dataIn24H: types.optional(types.array(IusageData), []),
  labels: types.model({
    environment: types.enumeration(['development', 'staging', 'production']),
  }),
  networkCount: types.map(types.number),
});

export type IApp = Instance<typeof App>;

export const Iservice = types.model({
  displayName: types.string,
  key: types.string,
  subDomain: types.string,
  protocolKey: types.string,
  metadata: types.model({
    pictures: types.maybe(
      types.model({
        icon: types.maybe(types.string),
        large: types.maybe(types.string),
        small: types.maybe(types.string),
      }),
    ),
  }),
  count: types.maybe(types.integer),
  status: types.string,
});
const ImethodData = types.model({
  method: types.string,
  count: types.number,
  size: types.number,
});
const Ilimit = types.model({
  usage: types.number,
  limit: types.number,
  refreshTimestamp: types.number,
  weightedUsage: types.number,
});
const Iusage = types.model({
  data: types.optional(types.array(IusageData), []),
  duration: types.string,
});
const Imethod = types.model({
  data: types.optional(types.array(ImethodData), []),
  duration: types.string,
});

export const MethodStatsError = types.map(
  types.map(
    types.map(
      types.map(
        types.maybeNull(
          types.model({
            list: types.optional(
              types.array(
                types.model({
                  cluster: types.string,
                  count: types.number,
                }),
              ),
              [],
            ),
            count: types.number,
          }),
        ),
      ),
    ),
  ),
);

const MethodStats = types.model({
  savedMethods: types.map(types.number),
  stats: types.map(types.map(types.map(types.maybeNull(types.number)))),
  errors: MethodStatsError,
});

const NetworStats = types.model({
  weighted: types.map(types.maybeNull(types.number)),
  stats: types.map(types.map(types.map(types.maybeNull(types.number)))),
  errors: types.map(
    types.map(
      types.map(
        types.maybeNull(
          types.model({
            list: types.optional(
              types.array(
                types.model({
                  cluster: types.string,
                  count: types.number,
                }),
              ),
              [],
            ),
            count: types.number,
          }),
        ),
      ),
    ),
  ),
});

export enum methodDiagramType {
  Count = 'count',
  Size = 'size',
}

export const ApiService = types
  .model({
    state: types.optional(types.enumeration(['pending', 'done', 'error']), 'done'),
    key: types.maybeNull(types.string),
    services: types.optional(types.array(Iservice), []),
    apps: types.optional(types.array(App), []),
    app: types.maybeNull(App),
    usage: types.maybeNull(Iusage),
    methodDiagram: types.maybeNull(Imethod),
    limit: types.maybeNull(Ilimit),
    errorMessage: types.maybeNull(types.string),
    methodStats: types.maybeNull(MethodStats),
    methodSummary: types.maybeNull(MethodStats),
    networkStats: types.maybeNull(NetworStats),
    whiteList: types.optional(types.array(WhiteList), []),
    editApp: types.maybeNull(types.boolean),
    creatorName: types.maybeNull(types.string),
  })
  .views((self) => ({
    get usageChartData() {
      let x = [] as string[];
      let y = [] as any;
      self.usage?.data.forEach((item) => {
        const date = new Date(item.time);
        const theDay = chartDateLabel(date, self.usage?.duration);
        if (!x.includes(theDay)) {
          x.push(theDay);
          y.push(0);
        }
        const dayIndexInArray = x.indexOf(theDay);
        y[dayIndexInArray] += item.count;
      });
      return { x, y };
    },

    get totalUsage() {
      let totalUsage: number = 0;
      self.apps.forEach((e) => {
        totalUsage += e?.weightedUsage || 0;
      });
      return totalUsage;
    },

    methodDiagramData(type: methodDiagramType) {
      if (!self.methodDiagram?.data) return [];
      return self.methodDiagram.data.map((i) => {
        return {
          name: i.method,
          value: i[type],
        };
      });
    },
    servicesWithCount() {
      if (!self.services || !self.app?.networkCount) {
        return [];
      }
      // Need a copy of the services state
      const result = cloneDeep(getSnapshot(self.services));
      // Apply the count from the app state
      for (const service of result) {
        self.app.networkCount.forEach((c, k) => {
          // Case-insensitive
          if (k.toLocaleLowerCase() === service.subDomain.toLocaleLowerCase()) {
            service.count = c;
            return;
          }
        });
      }
      return result;
    },
    mostCountMethod() {
      if (!self.methodStats || self.methodStats.savedMethods.size === 0) {
        return '';
      }
      const snapshot = getSnapshot(self.methodStats);
      if (!snapshot) {
        return '';
      }
      let result = '';
      let currentCount = -1;
      Object.keys(snapshot.savedMethods).forEach((method) => {
        if (snapshot.savedMethods[method] > currentCount) {
          result = method;
        }
      });
      return result;
    },
    methodStatsMap() {
      if (!self.methodStats || self.methodStats.stats.size === 0) {
        return {};
      }
      return getSnapshot(self.methodStats.stats);
    },
    methodErrorMap() {
      if (!self.methodStats || self.methodStats.stats.size === 0) {
        return {};
      }
      return getSnapshot(self.methodStats.errors);
    },
    savedMethods() {
      if (!self.methodStats || self.methodStats.savedMethods.size === 0) {
        return [];
      }
      const snapshot = getSnapshot(self.methodStats.savedMethods);
      return Object.keys(snapshot);
    },
    methodSummaryMap() {
      if (!self.methodSummary || self.methodSummary.stats.size === 0) {
        return {};
      }
      return getSnapshot(self.methodSummary.stats);
    },
    networkStatsMap() {
      if (!self.networkStats || self.networkStats.stats.size === 0) {
        return {};
      }
      return getSnapshot(self.networkStats.stats);
    },
    networkErrorsMap() {
      if (!self.networkStats || !self.networkStats.errors) {
        return {};
      }
      return cloneDeep(getSnapshot(self.networkStats.errors));
    },
    networkSummary() {
      if (!self.networkStats || !self.networkStats.weighted) {
        return {};
      }
      return getSnapshot(self.networkStats.weighted);
    },
  }))
  .actions((self) => ({
    getKey: flow(function* (wsId: string) {
      self.state = 'pending';
      self.key = null;
      try {
        const res = yield api.get(`/workspaces/${wsId}/api-service/api-keys`);
        if (res) self.key = res[0]?.apiKey || '';
        self.state = 'done';
      } catch (err) {
        self.key = '';
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getKeyByAppId: flow(function* (wsId: string, appId: string) {
      self.state = 'pending';
      self.key = null;
      try {
        const res = yield api.get(`/workspaces/${wsId}/api-service/${appId}/api-keys`);
        if (res) self.key = res[0]?.apiKey || '';
        self.state = 'done';
      } catch (err) {
        self.key = '';
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    createApp: flow(function* (wsId: string, displayName: string, environment: string) {
      self.state = 'pending';
      try {
        const { apiKey, appId } = yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/apps`, {
          displayName,
          environment,
        });
        self.key = apiKey;
        if (mxConfig.twitterId?.length) {
          //@ts-ignore
          window.twq('event', `tw-${mxConfig.twitterId}-oe2n9`);
        }
        sendEvent('create_api_key', apiKey);
        self.state = 'done';
        return appId;
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    updateAppName: flow(function* (wsId: string, appId: string, displayName: string) {
      self.state = 'pending';
      try {
        yield api.put(`${mxConfig.apiUrlV2}/workspaces/${wsId}/apps/${appId}`, {
          displayName,
        });
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    deleteApp: flow(function* (wsId: string, appId: string) {
      self.state = 'pending';
      try {
        yield api.delete(`${mxConfig.apiUrlV2}/workspaces/${wsId}/apps/${appId}`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    createKey: flow(function* (wsId: string, displayName: string, environment: string) {
      self.state = 'pending';
      try {
        const { apiKey } = yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/apps`, {
          displayName,
          environment,
        });
        self.key = apiKey;
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    regenerate: flow(function* (wsId: string, appId: string) {
      self.state = 'pending';
      try {
        const res = yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/apps/${appId}/api-key/regenerate`);
        self.key = res.apiKey;
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getLimit: flow(function* (wsId: string) {
      self.state = 'pending';
      try {
        self.limit = yield api.get(`/workspaces/${wsId}/api-service/limit`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getApiServices: flow(function* (wsId: string) {
      self.state = 'pending';
      try {
        self.services = yield api.get(`/workspaces/${wsId}/api-service`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getAppsById: flow(function* (wsId: string, appId: string) {
      self.app?.networkCount.clear();
      self.state = 'pending';
      try {
        const app = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/apps/${appId}`);
        if (app) {
          self.app = app;
          self.key = app.apiKey;
          self.editApp = app.edit;
          self.creatorName = app.creatorName;
        }
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    clearApps: () => {
      self.apps = [] as any;
    },
    getApps: flow(function* (wsId: string) {
      self.state = 'pending';
      try {
        const apps = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/apps`);
        self.apps = apps.filter((i: Instance<typeof App>) => i.active === true);
        if (self.apps.length) self.key = self.apps[0].apiKey;
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    clearUsage: flow(function* () {
      self.usage = null;
    }),
    getUsageByWsId: flow(function* (wsId: string, duration: string) {
      self.state = 'pending';
      try {
        const data = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/apps/diagram?duration=${duration}`);
        self.state = 'done';
        return data;
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getUsage: flow(function* (wsId: string, appId: string, duration: string, subDomain: string | null) {
      self.state = 'pending';
      try {
        self.usage = yield api.get(
          `${mxConfig.apiUrlV2}/workspaces/${wsId}/apps/${appId}/diagram?duration=${duration}&subDomain=${subDomain}`,
        );
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getMethod: flow(function* (wsId: string, duration: string, network: string | null) {
      self.state = 'pending';
      try {
        self.methodDiagram = yield api.get(
          `/workspaces/${wsId}/api-service/method-diagram?duration=${duration}&network=${network}`,
        );
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getMethodByApp: flow(function* (wsId: string, appId: string, duration: string, subDomain: string | null) {
      self.state = 'pending';
      try {
        self.methodDiagram = yield api.get(
          `${mxConfig.apiUrlV2}/workspaces/${wsId}/apps/${appId}/method-diagram?duration=${duration}&subDomain=${subDomain}`,
        );
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getMethodStatsHourly: flow(function* (
      wsId: string,
      appId: string,
      network: string,
      duration: string,
      method?: string[],
      startTime?: string,
      endTime?: string,
    ) {
      self.state = 'pending';
      try {
        const queryParams = new URLSearchParams();
        queryParams.append('duration', duration);
        if (duration !== '') queryParams.append('duration', duration);
        if (startTime && endTime) {
          queryParams.append('startTime', startTime);
          queryParams.append('endTime', endTime);
        }
        if (method) {
          for (const m of method) {
            queryParams.append('method', m);
          }
        }
        self.methodStats = yield api.get(
          `${
            mxConfig.apiUrlV2
          }/workspaces/${wsId}/apps/${appId}/networks/${network}/method-stats?${queryParams.toString()}`,
        );
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getMethodStatsSummary: flow(function* (
      wsId: string,
      appId: string,
      network: string,
      duration: string,
      startTime?: string,
      endTime?: string,
    ) {
      self.state = 'pending';
      try {
        const queryParams = new URLSearchParams();
        if (duration !== '') queryParams.append('duration', duration);
        if (startTime && endTime) {
          queryParams.append('startTime', startTime);
          queryParams.append('endTime', endTime);
        }

        // NOTE: TODO: This using the weighted version for now, until we have daily rolls on the aggregator
        queryParams.append('weighted', 'true');
        self.methodSummary = yield api.get(
          `${
            mxConfig.apiUrlV2
          }/workspaces/${wsId}/apps/${appId}/networks/${network}/method-stats?${queryParams.toString()}`,
        );
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getNetworkStats: flow(function* (
      wsId: string,
      appId: string,
      network: string,
      duration: string,
      startTime?: string,
      endTime?: string,
    ) {
      self.state = 'pending';
      try {
        const queryParams = new URLSearchParams();
        if (duration !== '') queryParams.append('duration', duration);
        if (startTime && endTime) {
          queryParams.append('startTime', startTime);
          queryParams.append('endTime', endTime);
        }
        self.networkStats = yield api.get(
          `${mxConfig.apiUrlV2}/workspaces/${wsId}/apps/${appId}/networks/${network}/stats?${queryParams.toString()}`,
        );
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    clearWhiteList: () => {
      self.whiteList.clear();
    },
    getWhiteList: flow(function* (wsId: string, appId: string) {
      self.state = 'pending';
      try {
        self.whiteList = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/apps/${appId}/whitelist`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    addWhiteList: flow(function* (wsId: string, appId: string, value: string, type: WhitelistType) {
      self.state = 'pending';
      try {
        yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/apps/${appId}/whitelist/create`, {
          value,
          type,
        });
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    removeWhiteList: flow(function* (wsId: string, appId: string, id: string) {
      self.state = 'pending';
      try {
        yield api.delete(`${mxConfig.apiUrlV2}/workspaces/${wsId}/apps/${appId}/whitelist/${id}`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getPopularNetworkDetail: flow(function* () {
      self.state = 'pending';
      try {
        const networkList: string[] = mxConfig.popularNetworkKey || [];
        const promiseList: Promise<AxiosResponse>[] = [];
        networkList.forEach((networkName: string) => {
          promiseList.push(api.get(`${mxConfig.apiUrlV2}/network-specs/${networkName}`));
        });
        const networksDetail = yield Promise.all(promiseList.map((p) => p.catch((e) => e)));
        self.state = 'done';
        return networksDetail.filter((result: any) => !(result instanceof Error));
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getPopularNetworkDetailForNodes: flow(function* () {
      self.state = 'pending';
      try {
        const networkList: string[] = mxConfig.popularNetworkKeyForNodes || [];
        const promiseList: Promise<AxiosResponse>[] = [];
        networkList.forEach((networkName: string) => {
          promiseList.push(api.get(`${mxConfig.apiUrlV2}/network-specs/${networkName}`));
        });
        const networksDetail = yield Promise.all(promiseList.map((p) => p.catch((e) => e)));
        self.state = 'done';
        return networksDetail.filter((result: any) => !(result instanceof Error));
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
  }));

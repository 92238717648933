import React, { useEffect } from 'react';
import {
  Button,
  Classes,
  Dialog,
  Intent,
  InputGroup,
  Alert,
  Switch,
  FormGroup,
  HTMLSelect,
  Checkbox,
} from '@blueprintjs/core';
import { sortBy, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStore } from 'store';
import { Instance } from 'mobx-state-tree';
import {
  SuccessToast,
  ErrorToast,
  WarningToast,
  Row,
  Col,
  SubmitButton,
  Loader,
  ButtonEmpty,
  NoData,
  SearchSelect,
} from 'components';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { imageVersionTags } from '@/configuration';
import { ReactComponent as AddIcon } from './img/add.svg';
import { ReactComponent as TrushIcon } from './img/trush.svg';
import { ImageVersionType } from '@/store/images';
import styles from './style.module.scss';
import { lang } from './helper';
import { useState } from 'react';
import cx from 'classnames';
import { Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox as CheckBoxAnt, Radio } from 'antd';

const { confirm } = Modal;

const reCheckTimes = 18;

interface IProps {
  networkKey: string;
  protocolKey: string;
  imageRepository: string;
  traceNetwork: boolean;
  traceRepo: string;
}
const schema = yup.object().shape({
  imageRepository: yup.string().required(),
  protocolKey: yup.string().required(),
  newVersion: yup.string().required(),
  tags: yup.string().required(),
  setRecommended: yup.number().required(),
});

interface ImageItem extends Instance<typeof ImageVersionType> {
  selected?: boolean;
}

const formatVersion = (v: string) => {
  const vs = v.split('.');
  return vs
    .map((i: string) => {
      if (i.length === 1) i = '0' + i;
      return i;
    })
    .join('.')
    .toLowerCase();
};

const imageDropList = (imageList: Array<ImageItem>, dropDownList: Array<string>, repo: string) => {
  const imageListStringArray = imageList.map((i: ImageItem) => {
    if (i.imageRepository === repo) {
      return i.version;
    }
  });
  const resultAfterFilter = dropDownList.filter((i: string) => !imageListStringArray.includes(i));
  return resultAfterFilter;
};

export const ImagesManagement: React.FC<IProps> = observer(
  ({ networkKey, protocolKey, imageRepository, traceNetwork, traceRepo }) => {
    const { t } = useTranslation();
    const { workspaces, images, networkSpec, networks, info } = useStore();
    const [checking, setChecking] = useState(false);
    const [showAddNewImage, setShowAddNewImage] = useState(false);
    const [showDeleteImgs, setShowDeleteImgs] = useState(false);
    const [imageVersions, setImageVersions] = useState([]);
    const [selectedRepo, setSelectedRepo] = useState<string>(imageRepository);
    const [selectAll, setSelectAll] = useState(false);
    const [imageList, setImageList] = useState<Array<ImageItem>>([]);
    const [imageListForSelect, setImageListForSelect] = useState<Array<string>>([]);
    const [recommendImage, setRecommendImage] = useState<string>('');
    const { control, handleSubmit, getValues, errors, setValue, register } = useForm({ validationSchema: schema });
    const loadImageList = async () => {
      await images.fetchList(workspaces.current!.id, networkKey);
      let afterSortImages = orderBy(images.list, [(n) => formatVersion(n.version)], 'desc');
      afterSortImages = orderBy(afterSortImages, [(n) => n.imageRepository], 'asc');
      setImageList(afterSortImages);
    };
    const checkImageVersion = async (
      imageRepo: string,
      imageVersion: string,
      protocolKey: string,
      reCheckTime = 1,
    ): Promise<any> => {
      const data = await networks.checkImage(imageRepo, imageVersion);
      if (networkSpec.state === 'error') {
        return networkSpec?.errorMessage || 'Request error';
      }
      const { status } = data;
      if (status === 'fail') {
        return {
          state: 'fail',
          message: t('modules.createNetworkSpecForm.checkFail'),
        };
      } else if ((reCheckTime >= reCheckTimes && status !== 'success') || status === 'timeout') {
        return {
          state: 'fail',
          message: t('modules.createNetworkSpecForm.checkTimeout'),
        };
      } else if (status === 'success') {
        return {
          state: 'success',
          data: data,
        };
      } else {
        let check = false;
        let promise = new Promise((resolve) => {
          setTimeout(async () => {
            check = await checkImageVersion(imageRepo, imageVersion, protocolKey, ++reCheckTime);
            resolve(check);
          }, 5000);
        });
        return promise;
      }
    };

    const updateVersionList = handleSubmit(async (val) => {
      if (!val.newVersion) return;
      const alreadyV = imageList.find((i) => i.version === val.newVersion);
      if (alreadyV) return WarningToast('This version already exist');
      setChecking(true);
      const result = await checkImageVersion(selectedRepo, val.newVersion, val.protocolKey);
      if (result.state === 'fail') {
        ErrorToast(result.message);
        return setChecking(false);
      }
      const newId = await images.upsert(workspaces.current!.id, networkKey, {
        imageRepository: selectedRepo,
        version: val.newVersion,
        protocolKey,
        tags: val.tags ? [val.tags] : [],
      });
      setChecking(false);
      if (images.state === 'error') {
        ErrorToast(images?.errorMessage);
      } else {
        if (val.setRecommended) await images.changeRecommendedImage(workspaces.current!.id, networkKey, newId);
        SuccessToast(lang(t, 'imageManagement.addImageSuccess', { id: val.newVersion }));
        loadImageList();
        setShowAddNewImage(false);
      }
    });
    useEffect(() => {
      if (imageList.length <= 0) {
        (async () => {
          await loadImageList();
          const findImage = images.list.find((i) => i.recommendedImage === true);
          if (findImage !== undefined) {
            setRecommendImage(findImage.version);
          }
        })();
      }
      return () => {
        images.clearData();
      };
    }, []);

    useEffect(() => {
      (async () => {
        if (showAddNewImage) {
          if (imageVersions.length <= 0) {
            const result = await info.fetchImageVersions(imageRepository);
            const resultAfterFilter = imageDropList(imageList, result, imageRepository);
            setImageVersions(result);
            setImageListForSelect(imageDropList(imageList, result, imageRepository));
            setValue('newVersion', resultAfterFilter[0]);
          } else {
            const resultAfterFilter = imageDropList(imageList, imageVersions, imageRepository);
            setValue('newVersion', resultAfterFilter[0]);
            setImageListForSelect(imageDropList(imageList, resultAfterFilter, imageRepository));
          }
        }
      })();
    }, [showAddNewImage]);

    const getNewRepoVersion = async (repo: string) => {
      const result = await info.fetchImageVersions(repo);
      const resultAfterFilter = imageDropList(imageList, result, repo);
      setImageVersions(result);
      setImageListForSelect(resultAfterFilter);
      setValue('newVersion', resultAfterFilter[0]);
    };

    const isSelectDeleteImage = imageList.find((i) => i.selected === true);

    const deleteImage = (i: ImageItem) => {
      confirm({
        title: 'Delete Image',
        content: `Are you sure you want to delete docker image ${i.version}? This action can’t be undone. `,
        onOk() {
          (async () => {
            await images.delete(workspaces.current!.id, networkKey, i.id);
            if (images.state === 'error') ErrorToast(images.errorMessage);
            else {
              SuccessToast(lang(t, 'imageManagement.deleteImageSuccess', { id: i?.version }));
              images.fetchList(workspaces.current!.id, networkKey);
              setImageList(images.list);
            }
          })();
        },
        onCancel() {},
        centered: true,
        width: 468,
        okText: 'Delete',
      });
    };

    const deleteSeletedImage = () => {
      confirm({
        okText: 'Delete',
        onCancel() {},
        title: lang(t, 'imageManagement.bulkDeleteTitle'),
        content: lang(t, 'imageManagement.bulkDeleteMsg'),
        onOk() {
          (async () => {
            const ids = [] as Array<number>;
            imageList.find((i) => {
              if (!!i.selected && !i.recommendedImage) ids.push(i.id);
            });
            await images.deleteArray(workspaces.current!.id, networkKey, ids);
            if (images.state === 'error') ErrorToast(images.errorMessage);
            else {
              SuccessToast(lang(t, 'imageManagement.deleteMultipleImageSuccess', { num: ids.length }));
              setSelectAll(false);
              images.fetchList(workspaces.current!.id, networkKey);
              setImageList(images.list);
            }
          })();
        },
        centered: true,
        width: 468,
      });
    };

    const imageInfo = () => {
      Modal.info({
        title: lang(t, 'imageManagement.deleteRecommendTitle'),
        content: lang(t, 'imageManagement.deleteRecommendSubmessage'),
        onOk() {},
        centered: true,
        width: 468,
        okType: 'primary',
      });
    };
    setValue('imageRepository', selectedRepo);

    return (
      <Loader isLoading={false}>
        <div className={styles.imagesManagementContent}>
          <Row className={styles.contentHeader}>
            <Col unit={12}>
              <h3>{lang(t, 'imageManagement.title')}</h3>
            </Col>
            <Col unit={12} alignRight>
              <ButtonEmpty
                disabled={!isSelectDeleteImage || workspaces.current!.suspendTaskDone}
                intent={Intent.DANGER}
                icon={<TrushIcon />}
                onClick={() => {
                  isSelectDeleteImage && deleteSeletedImage();
                }}
              >
                {lang(t, 'imageManagement.delete')}
              </ButtonEmpty>
              <ButtonEmpty
                intent={Intent.PRIMARY}
                icon={<AddIcon />}
                onClick={async () => {
                  setShowAddNewImage(true);
                }}
                disabled={workspaces.current!.suspendTaskDone}
              >
                {lang(t, 'imageManagement.addImage')}
              </ButtonEmpty>
            </Col>
          </Row>
          <div className={styles.table_wrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      checked={selectAll}
                      onChange={() => {
                        setSelectAll(!selectAll);
                        const tempList = imageList.map((i: ImageItem) => {
                          i.selected = !selectAll;
                          return i;
                        });
                        setImageList(tempList);
                      }}
                    />
                  </th>
                  <th>Repository</th>
                  <th>{lang(t, 'imageManagement.dockerTag')}</th>
                  <th>{lang(t, 'imageManagement.category')}</th>
                  <th>{lang(t, 'imageManagement.state')}</th>
                  <th>
                    {lang(t, 'imageManagement.recommend')}{' '}
                    <Tooltip
                      title={
                        recommendImage != ''
                          ? lang(t, 'imageManagement.toolTipTitle', { recommendImage: recommendImage })
                          : lang(t, 'imageManagement.setRecommend')
                      }
                      color="#031fef"
                      placement="right"
                    >
                      <ExclamationCircleOutlined className={styles.iconRecommend} />
                    </Tooltip>
                  </th>
                  <th>{lang(t, 'imageManagement.action')}</th>
                </tr>
              </thead>
              <tbody>
                <Loader isLoading={!imageList && images.state === 'pending'} width={20} height={20} padding={false}>
                  {!imageList.length && (
                    <tr>
                      <td colSpan={5}>
                        <NoData />
                      </td>
                    </tr>
                  )}
                  {!!imageList.length &&
                    imageList.map((i, index) => (
                      <tr key={i.version}>
                        <td>
                          {i.recommendedImage ? (
                            <Checkbox disabled={true} checked={false} />
                          ) : (
                            <Checkbox
                              checked={!!i.selected}
                              onClick={() => {
                                const tempList = [...imageList];
                                tempList[index].selected = !tempList[index].selected;
                                setImageList(tempList);
                              }}
                            />
                          )}
                        </td>
                        <td>{i.imageRepository}</td>
                        <td>{i.version}</td>
                        <td>{i.tags.join(',')}</td>
                        <td>
                          <Switch
                            disabled={workspaces.current!.suspendTaskDone || i.recommendedImage === true}
                            checked={i.active}
                            onChange={async (e: any) => {
                              const checked = e.target?.checked;
                              !e.target?.checked && (await images.deactivate(workspaces.current!.id, networkKey, i.id));
                              e.target?.checked && (await images.activate(workspaces.current!.id, networkKey, i.id));
                              if (images.state === 'error') ErrorToast(images.errorMessage);
                              else {
                                SuccessToast(
                                  checked
                                    ? lang(t, 'imageManagement.activated', { id: i.version })
                                    : lang(t, 'imageManagement.deactivated', { id: i.version }),
                                );
                              }
                            }}
                          />
                          {i.active ? (
                            <span className={styles.active}>{lang(t, 'imageManagement.active')}</span>
                          ) : (
                            <span className={styles.deactivate}>{lang(t, 'imageManagement.deactivate')}</span>
                          )}
                        </td>
                        <td>
                          <Radio
                            disabled={
                              workspaces.current!.suspendTaskDone ||
                              i.active === false ||
                              i.imageRepository === traceRepo
                            }
                            checked={i.recommendedImage === true}
                            onClick={async (e: any) => {
                              if (i.recommendedImage) return;
                              await images.changeRecommendedImage(workspaces.current!.id, networkKey, i.id);
                              if (images.state === 'error') ErrorToast(images.errorMessage);
                              else {
                                SuccessToast('Change Recommended Image Successful');
                                setRecommendImage(i.version);
                              }
                            }}
                          />
                          {i.recommendedImage && (
                            <span className={styles.active}>{lang(t, 'imageManagement.recommend')}</span>
                          )}
                        </td>
                        <td>
                          <ButtonEmpty
                            small
                            intent={Intent.DANGER}
                            icon={<TrushIcon />}
                            onClick={() => (i.recommendedImage ? imageInfo() : deleteImage(i))}
                            disabled={workspaces.current!.suspendTaskDone}
                          />
                        </td>
                      </tr>
                    ))}
                </Loader>
              </tbody>
            </table>
          </div>
        </div>
        <Dialog
          isOpen={showAddNewImage}
          onClose={() => {
            setShowAddNewImage(false);
            setImageVersions([]);
          }}
          title={lang(t, 'imageManagement.addImage')}
          className={styles.dialogContainer}
        >
          <form onSubmit={updateVersionList}>
            <div className={Classes.DIALOG_BODY}>
              <input type="hidden" name="protocolKey" ref={register} value={protocolKey} />
              <input type="hidden" name="imageRepository" ref={register} defaultValue={imageRepository} />
              <input type="hidden" name="newVersion" ref={register} />
              <input type="hidden" name="tags" ref={register} defaultValue={imageVersionTags[0]} />
              <input type="hidden" name="setRecommended" ref={register} defaultValue={0} />
              <FormGroup>
                <Row gutter={true} className={styles.addImageForm}>
                  <Col unit={8}>
                    {/* <Controller
                    name="imageRepository"
                    control={control}
                    defaultValue={imageRepository}
                    as={<InputGroup disabled value={imageRepository} />}
                  /> */}
                    {traceNetwork ? (
                      <SearchSelect
                        large={false}
                        state={info.state}
                        items={[imageRepository, traceRepo]}
                        onChange={(v: string) => {
                          getNewRepoVersion(v);
                          setSelectedRepo(v);
                        }}
                      />
                    ) : (
                      <InputGroup disabled value={imageRepository} />
                    )}
                  </Col>
                  <Col unit={10} className={styles.versionsSelect}>
                    <div>
                      <SearchSelect
                        large={false}
                        state={info.state}
                        items={imageListForSelect}
                        onChange={(v: string) => {
                          setValue('newVersion', v);
                        }}
                      />
                    </div>
                  </Col>
                  <Col unit={6}>
                    <HTMLSelect
                      fill
                      disabled={checking}
                      onChange={(e) => {
                        setValue('tags', e.currentTarget.value);
                      }}
                    >
                      {imageVersionTags.map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </HTMLSelect>
                  </Col>
                  <Col unit={4} className={styles.versionsSelect}></Col>
                </Row>
                <Row>
                  <Col unit={8}>
                    <CheckBoxAnt
                      defaultChecked={false}
                      className={styles.antCheckBox}
                      disabled={selectedRepo !== imageRepository}
                      onChange={(e) => {
                        e.target.checked ? setValue('setRecommended', 1) : setValue('setRecommended', 0);
                      }}
                    />
                    <span>{lang(t, 'imageManagement.setAsRecommend')}</span>
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <SubmitButton disabled={checking} loading={checking} intent={Intent.PRIMARY} type="submit">
                  {lang(t, 'imageManagement.addImageButton')}
                </SubmitButton>
              </div>
            </div>
          </form>
        </Dialog>
      </Loader>
    );
  },
);

import { Classes, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { Button, Row, Spin, Switch } from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { ErrorHint, SubmitButton, SuccessToast } from 'components';
import { useStore } from 'store';
import { lang } from './helper';
import styles from './style.module.scss';
import { nodeNameMaxLength } from '@/configuration';

interface IProps {
  isOpen: boolean;
  nodeId: string;
  estimatePrice: string;
  onClose: () => void;
  onCloneNode: (wsId: string, nodeId: string, nodeName: string, autoExpand: boolean) => Promise<void>;
}

export const CloneNodeDialog: React.FC<IProps> = ({ isOpen, onClose, nodeId, estimatePrice, onCloneNode }) => {
  const { t } = useTranslation();
  const { nodes, workspaces } = useStore();
  const schema = yup.object().shape({
    nodeNameInput: yup
      .string()
      .matches(/^[a-zA-Z0-9\_\-]+$/, 'No special characters, e.g. spaces, %, ^, &')
      .required('Display Name must be a string, cannot be empty'),
  });
  const { control, errors, handleSubmit } = useForm({ validationSchema: schema });
  const [progressing, setProgressing] = useState(false);
  const [autoExpand, setAutoExpand] = useState<boolean>(false);
  const history = useHistory();

  return (
    <Dialog title={lang(t, 'upgradeNode.titleClone')} isOpen={isOpen} onClose={onClose}>
      {progressing && <Spin spinning={progressing}></Spin>}
      {!progressing && (
        <form
          onSubmit={handleSubmit(async (val) => {
            setProgressing(true);
            await onCloneNode(workspaces.current!.id, nodeId, val.nodeNameInput, autoExpand);
            SuccessToast('Success');
            onClose();
            setProgressing(false);
            history.push(`/workspaces/${workspaces.current!.id}/nodes`);
          })}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup>
              <Row className={styles.cloneNodeSpec}>{lang(t, 'upgradeNode.cloneInstructions')}</Row>
              <Row className={styles.cloneNodeName}>{lang(t, 'upgradeNode.nameClone')}</Row>
              <Controller
                name="nodeNameInput"
                control={control}
                minLength={4}
                maxLength={nodeNameMaxLength}
                as={<InputGroup large fill intent={errors.nodeNameInput ? Intent.DANGER : Intent.NONE} />}
              />
              <ErrorHint message={errors.nodeNameInput ? errors.nodeNameInput.message : ''} />
            </FormGroup>
            <div>
              <div className={styles.autoExpandSwitchClone}>
                <Switch
                  checked={autoExpand}
                  onChange={() => {
                    setAutoExpand(!autoExpand);
                  }}
                />
                <span>{lang(t, 'upgradeNode.autoExpandName')}</span>
              </div>
              <div className={styles.autoExpandDescription}>{lang(t, 'setAutoExpand.autoExpandDescription')}</div>
            </div>
            <div className={styles.estimatedPrice}>
              {lang(t, 'upgradeNode.estimatedPriceLabel')}
              <span>USD ${estimatePrice}</span>
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                {lang(t, 'upgradeNode.cancel')}
              </Button>
              <SubmitButton type="submit" intent={Intent.PRIMARY}>
                {lang(t, 'upgradeNode.cloneSave')}
              </SubmitButton>
            </div>
          </div>
        </form>
      )}
    </Dialog>
  );
};

import dayjs from 'dayjs';
import { Tag } from 'antd';
import { capitalize } from 'lodash';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { DetailBox, Logo, EditableField, ErrorToast, SuccessToast } from 'components';
import { nodeNameMaxLength } from '@/configuration';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store';
import { Node, StatusMap } from '../../store/nodes';
import { lang } from './helper';
import { Skeleton } from 'antd';
import styles from './style.module.scss';
import { SyncOutlined } from '@ant-design/icons';

export const StatusIconMap = (status: string | null | undefined) => {
  let result;
  switch (status) {
    case 'pending':
      result = null;
      break;
    case 'enabled':
      result = null;
      break;
    case 'processing':
      result = <SyncOutlined spin />;
      break;
    case 'initializing':
      result = <SyncOutlined spin />;
      break;
    case 'running':
      result = null;
      break;
    case 'stopping':
      result = <SyncOutlined spin />;
      break;
    case 'stopped':
      result = null;
      break;
    case 'restarting':
      result = <SyncOutlined spin />;
      break;
    case 'terminating':
      result = <SyncOutlined spin />;
      break;
    case 'terminated':
      result = null;
      break;
    case 'error':
      result = null;
      break;
    default:
      result = null;
  }
  return result;
};

interface IProps {
  node?: Instance<typeof Node>;
}

const unitMap = {
  small: 1,
  medium: 2,
  large: 3,
};

const multiplier = (nodeSpec: string, value: number) => {
  if (nodeSpec === 'unit') return `× ${value}`;
  return '';
};

export const Summary: React.FC<IProps> = observer(({ node }) => {
  const { nodes, info, workspaces } = useStore();
  const { t } = useTranslation();
  const cluster = node?.clusterHash ? info.getCluster(node.clusterHash) : { cloud: '', region: '' };
  return (
    <DetailBox className={styles.message}>
      <div className={styles.logo}>
        <Logo size={56} className={styles.logoImage} src={node?.networkSpec?.metadata?.pictures?.icon} />
      </div>
      <div className={styles.mainMessage}>
        {node && (
          <span data-status={node?.status} className={styles.status}>
            <Tag color={StatusMap(node?.status)} icon={StatusIconMap(node?.status)}>
              {t(`components.nodeStatus.${node?.status}`)}
            </Tag>
          </span>
        )}
        {!node && <Skeleton paragraph={{ rows: 3 }} />}
        {node && (
          <>
            {node?.name && (
              <span className={styles.editable}>
                <EditableField
                  disabled={workspaces.current!.suspendTaskDone}
                  value={node?.name}
                  minLength={4}
                  maxLength={nodeNameMaxLength}
                  onChange={async (val) => {
                    await nodes.updateNodeName(node.workspaceId, node.id, { nodeName: val });
                    if (nodes.state === 'error') {
                      ErrorToast(nodes.errorMessage!);
                      return false;
                    }
                    SuccessToast(t('modules.accountProfile.updateNameSuccess'));
                    return true;
                  }}
                />
              </span>
            )}
            <p className={styles.createDate}>
              {node?.ownerName} | {dayjs(node?.createdAt).format('YYYY-MM-DD HH:mm:ss')} UTC
            </p>
            <ul className={styles.summary}>
              <li>
                {lang(t, 'detailTitle.network')}
                <span>{capitalize(node?.networkSpec?.displayName)}</span>
              </li>
              <li>
                {lang(t, 'detailTitle.nodeType')}
                <span>{capitalize(node.type)}</span>
              </li>
              <li>
                {lang(t, 'detailTitle.region')}
                <span>
                  <span className={styles.capital}>{cluster?.cloud}</span>-{cluster?.region}
                </span>
              </li>
              <li>
                {lang(t, 'detailTitle.nodeSize')}
                <span>
                  {capitalize(node.nodeSpec)} {multiplier(node.nodeSpec, node.nodeSpecMultiplier)}
                </span>
              </li>
              <li>
                {lang(t, 'detailTitle.storage')}
                <span>{node.storage}</span>
              </li>
              <li>
                {lang(t, 'detailTitle.image')}
                <span>{node?.image}</span>
              </li>
              {node.metadata?.labels?.nodePool && (
                <li>
                  {lang(t, 'detailTitle.nodePoolName')}
                  <span>{node.metadata?.labels?.nodePool}</span>
                </li>
              )}
              <li>
                {lang(t, 'detailTitle.autoExpandName')}
                <span>
                  {node.type !== 'validator' &&
                  node.type !== 'collator' &&
                  (!!node.autoExpand || node.metadata?.labels?.nodePool)
                    ? 'Enabled'
                    : 'Disabled'}
                </span>
              </li>
            </ul>
          </>
        )}
      </div>
    </DetailBox>
  );
});

import React, { useState } from 'react';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import { observer } from 'mobx-react';
import { lang } from './helper';
import { ErrorToast, SuccessToast, SubmitButton } from 'components';
import styles from './style.module.scss';
import { sendEvent } from '@/utils/googleAnalytics';

interface IProps {
  nodeId: string;
  onClose: () => void;
  currentStatus: boolean;
}

export const AutoExpandDialog: React.FC<IProps> = observer(({ nodeId, onClose, currentStatus }) => {
  const { t } = useTranslation();
  const { nodes, workspaces } = useStore();
  const [progressing, setProgressing] = useState(false);

  const enableAutoExpand = () => {
    SuccessToast('Auto expand storage enabled successfully');
    sendEvent('enable_auto_expand');
  };

  const disableAutoExpand = () => {
    SuccessToast('Auto expand storage disabled successfully');
    sendEvent('disable_auto_expand');
  };

  return (
    <Dialog title={lang(t, 'setAutoExpand.title')} isOpen={true} onClose={onClose}>
      <div className={Classes.DIALOG_BODY}>
        <div className={styles.autoExpandDescription}>
          <span>
            {currentStatus ? (
              lang(t, 'setAutoExpand.disableAutoExpand')
            ) : (
              <>
                {lang(t, 'setAutoExpand.enableAutoExpand')}
                <br />
                <br />
                {lang(t, 'setAutoExpand.autoExpandDescription')}
              </>
            )}
          </span>
          {!currentStatus && (
            <a
              className={styles.autoExpandLearnMore}
              href="https://documentation.onfinality.io/support/auto-expand-storage"
              target={'_blank'}
            >
              {lang(t, 'setAutoExpand.learnMore')}
            </a>
          )}
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            {lang(t, 'upgradeNode.cancel')}
          </Button>
          <SubmitButton
            onClick={async () => {
              setProgressing(true);
              await nodes.setAutoExpand(workspaces.current!.id, nodeId, { autoExpand: !currentStatus });
              if (nodes.state === 'error') {
                ErrorToast(nodes.errorMessage!);
              } else {
                currentStatus ? disableAutoExpand() : enableAutoExpand();
                onClose();
              }
              setProgressing(false);
            }}
            intent={Intent.PRIMARY}
            loading={progressing}
          >
            {lang(t, 'upgradeNode.submit')}
          </SubmitButton>
        </div>
      </div>
    </Dialog>
  );
});

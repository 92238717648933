import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { DetailBox, PlanCard } from '@/components';
import { lang } from './helper';
import { observer } from 'mobx-react';
import { Payment } from './Payment';
import { useStore } from '@/store';
import { useTranslation } from 'react-i18next';
import { Spin, Button } from 'antd';
import { ReactComponent as ExternalLinkIcon } from './imgs/external-link-line.svg';
import cx from 'classnames';

const enum TAB {
  API = 'api',
  NETWORK = 'network',
}

export const PlanPayment: React.FC = observer(() => {
  const { t } = useTranslation();
  const { plan, workspaces } = useStore();
  const [tabPage, setTabPage] = useState<TAB>(TAB.API);
  const [isLoadingPlan, setIsLoadingPlan] = useState(false);
  useEffect(() => {
    (async () => {
      setIsLoadingPlan(true);
      await plan.fetch();
      if (plan.listFilter('network').find((i) => i.key === workspaces.current!.plan)) {
        setTabPage(TAB.NETWORK);
      }
      setIsLoadingPlan(false);
    })();
  }, []);

  const reLoadList = async () => {
    setIsLoadingPlan(true);
    await workspaces.fetchList();
    setIsLoadingPlan(false);
  };

  return (
    <>
      <DetailBox className={styles.planContainer} title={lang(t, 'plan.title')}>
        <div className={styles.textCenter}>
          <div className={styles.planTab}>
            <div
              className={cx({ [styles.current]: tabPage === TAB.API })}
              onClick={() => {
                setTabPage(TAB.API);
              }}
            >
              {lang(t, 'plan.tab.apiService')}
            </div>
            <div
              className={cx({ [styles.current]: tabPage === TAB.NETWORK })}
              onClick={() => {
                setTabPage(TAB.NETWORK);
              }}
            >
              {lang(t, 'plan.tab.networkDevelopment')}
            </div>
          </div>
        </div>
        <Spin spinning={isLoadingPlan}>
          <div className={styles.planBox}>
            {TAB.API === tabPage &&
              plan
                .listFilter('api')
                .map((i) => (
                  <PlanCard
                    {...i}
                    keyValue={i.key}
                    current={workspaces.current?.plan === i.key}
                    onChangeSuccess={reLoadList}
                    planChange={false}
                    currentLevel={plan.currentPlan?.price.level}
                  />
                ))}

            {TAB.NETWORK === tabPage &&
              plan
                .listFilter('network')
                .map((i) => (
                  <PlanCard
                    {...i}
                    keyValue={i.key}
                    current={workspaces.current?.plan === i.key}
                    onChangeSuccess={reLoadList}
                    planChange={true}
                    currentLevel={plan.currentPlan?.price.level}
                  />
                ))}
          </div>
          <div className={styles.marketPlaceBtn}>
            <Button
              className={styles.linkBtn}
              type="link"
              size={'large'}
              onClick={() => {
                window.open('https://onfinality.io/pricing#comparePlans', '_blank');
              }}
            >
              Compare Plans <ExternalLinkIcon />
            </Button>
          </div>
        </Spin>
      </DetailBox>
      {workspaces.current?.billingType === 'stripe' && <Payment />}
    </>
  );
});

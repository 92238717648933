import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Col, Row } from 'components';
import { InputGroup } from '@blueprintjs/core';
import styles from './style.module.scss';
import { ReactComponent as MinusCircle } from './img/minusCircle.svg';
import { ReactComponent as PlusCircle } from './img/plusCircle.svg';
import _ from 'lodash';

interface IProps {
  data: Array<string | undefined>;
  disabled?: boolean;
  onSubmit: (vars: Array<string | undefined | null>) => void;
}
const waitTime = 300;

export const ArrayComponent: React.FC<IProps> = observer(({ data, onSubmit, disabled = false }) => {
  if (data.length === 0) data = [''];
  const [list, setList] = useState<Array<string | undefined | null>>(data);
  const changeValue = _.debounce((v: string | null, index: number) => {
    const temp = [...list];
    temp[index] = v;
    setList(temp);
    const result = temp.filter((i: any) => i !== null && i !== '' && i !== undefined);
    onSubmit(result);
  }, waitTime);
  return (
    <div className={styles.arrayComponent}>
      {list.map((i: string | undefined | null, index) => {
        if (i !== null) {
          return (
            <Row key={index}>
              <Col>
                <InputGroup
                  defaultValue={i}
                  disabled={disabled}
                  onChange={(e: any) => {
                    changeValue(e.target?.value.trim(), index);
                  }}
                />
              </Col>
              <Col>
                {index === 0 ? (
                  <PlusCircle
                    onClick={() => {
                      if (disabled) return;
                      const temp = [...list];
                      temp.push(undefined);
                      setList(temp);
                    }}
                  />
                ) : (
                  <MinusCircle
                    onClick={() => {
                      if (disabled) return;
                      changeValue(null, index);
                    }}
                  />
                )}
              </Col>
            </Row>
          );
        }
      })}
    </div>
  );
});

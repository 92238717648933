import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import styles from './style.module.scss';
import { NetworkSelectionProps } from './';
import { getPageItems, getPages } from './hook';
// import { ReactComponent as Nodata } from '../imgs/nodata.svg';
import cx from 'classnames';

const NetworkSelectionCarousel: React.FC<NetworkSelectionProps> = observer(
  ({ networkSelection, renderCardTile, showExpanded }) => {
    const { networkList, pageSize } = networkSelection;
    const state = useMemo(() => [...networkList], [networkList]);
    const pages = useMemo(() => getPages(state, pageSize, showExpanded), [state, pageSize]);

    return (
      <div className={cx(styles.networkSelectionTiles, state.length === 0 && styles.nodata)}>
        {
          state.length > 0
            ? pages.map((pageIndex) => (
                <div className="tiles-page network-page" key={pageIndex}>
                  {getPageItems(state, pageSize, pageIndex).map(renderCardTile)}
                </div>
              ))
            : null
          // <Nodata className={styles.noDataSvg} />
        }
      </div>
    );
  },
);

export default NetworkSelectionCarousel;

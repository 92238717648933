import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { Alert, IAlertProps, AbstractPureComponent2 } from '@blueprintjs/core';

export interface IConfirmOpt extends Omit<IAlertProps, 'isOpen'>{
  message: React.ReactNode;
}

export interface IConfirm {
  show(opt: IConfirmOpt): void;
}

export interface IConfirmProps {
}

export interface IConfirmState {
  confirm?: IConfirmOpt;
}

export const CREATE_NULL =
  ` Confirm.create() is not supported inside React lifecycle methods in React 16.` +
  ` See usage example on the docs site.`;

class Confirm extends AbstractPureComponent2<IConfirmProps, IConfirmState> implements IConfirm {
  public static displayName = 'mx.Confirm';

  public static defaultProps: IConfirmProps = {
  };

  public state: IConfirmState = {};

  /**
   * Create a new `Toaster` instance that can be shared around your application.
   * The `Toaster` will be rendered into a new element appended to the given container.
   */
  public static create(props?: IConfirmProps, container = document.body): IConfirm {
    const containerElement = document.createElement("div");
    container.appendChild(containerElement);
    const confirm = ReactDOM.render<IConfirmProps>(
      <Confirm {...props} />,
      containerElement,
    ) as Confirm;
    if (confirm == null) {
      throw new Error(CREATE_NULL);
    }
    return confirm;
  }

  public show(opt: IConfirmOpt) {
    this.setState({confirm: opt});
  }

  public render() {
    const {confirm} = this.state;
    return (
      confirm ?
      <Alert
        {...confirm}
        isOpen={true}
        onClose={(confirmed, evt) => {
          this.setState({confirm: undefined});
          if (confirm.onClose) {
            confirm?.onClose(confirmed, evt);
          }
        }}
      >
        {this.state.confirm?.message}
      </Alert> : null
    );
  }

  protected validateProps(props: IConfirmProps) {

  }

}

const confirm = Confirm.create();

export function showConfirm(opt: IConfirmOpt) {
  confirm.show(opt);
}

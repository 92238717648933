import React from 'react';
import { Col, Row } from '../Grid';
import { useTranslation } from 'react-i18next';
import { Language } from '../../components';
import { ReactComponent as Illustration } from './img/illustration.svg';
import { ReactComponent as Logo } from './img/login_logo.svg';
import { mxConfig } from '@/config';
import styles from './default.module.scss';

export const AnonymousLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row className={styles.loginPageHeader}>
        <Col className={styles.leftBox} unit={12}>
          <div>
            <a className={styles.loginLogo} href={mxConfig.homePage}>
              <Logo className={styles.logo} />
            </a>
          </div>
        </Col>
        <Col className={styles.rightBox} unit={12}>
          <div className={styles.languageBox}>
            <Language primary={false} />
          </div>
        </Col>
      </Row>
      <div className={styles.container}>
        <Row className={styles.loginMainBox}>
          <Col className={styles.leftBox} unit={12}>
            <div>
              <Illustration className={styles.illustration} />
              <div>
                <h1>{t(`pages.login.introduce-title`)}</h1>
                <p>{t(`pages.login.introduce`)}</p>
              </div>
            </div>
          </Col>
          <Col className={styles.rightBox} unit={12}>
            {children}
          </Col>
        </Row>
      </div>
    </>
  );
};

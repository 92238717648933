import { destroy, flow, Instance, types } from 'mobx-state-tree';
import { api, filterErrorMessage } from '../utils/api';
import dayjs from 'dayjs';
import { pickOutError } from '@/utils/tools';
import { sendEvent } from '@/utils/googleAnalytics';
import { mxConfig } from '@/config';

export const Card = types
  .model({
    brand: types.string,
    country: types.string,
    expMonth: types.number,
    expYear: types.number,
    last4Digits: types.string,
  })
  .views((self) => ({
    get expired() {
      const exp = dayjs()
        .year(self.expYear)
        .month(self.expMonth - 1)
        .endOf('month');
      return exp.isBefore(dayjs());
    },

    get cardNumber() {
      return `****${self.last4Digits}`;
    },

    get expiryDate() {
      return self.expMonth + '/' + self.expYear;
    },
  }));

export const Address = types.model({
  line1: types.maybeNull(types.string),
  line2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  country: types.string,
});

export const BillingDetail = types.model({
  email: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  address: Address,
});

export const PaymentMethod = types.model({
  id: types.identifier,
  accountId: types.string,
  card: Card,
  billingDetail: BillingDetail,
});

export const PaymentMethods = types
  .model({
    list: types.optional(types.array(PaymentMethod), []),
    state: types.optional(types.enumeration(['pending', 'progressing', 'done', 'error']), 'done'),
    errorMessage: types.maybe(types.string),
    newMethodId: types.maybe(types.string),
  })
  .views((self) => ({
    get(id?: string | null) {
      if (!id) {
        return null;
      }
      return self.list.find((p) => p.id === id);
    },
  }))
  .actions((self) => ({
    fetchList: flow(function* () {
      self.state = 'pending';
      try {
        const data = yield api.get(`/accounts/payment-methods`);
        self.list = data as any;
        self.state = 'done';
      } catch (err) {
        console.error(err);
        pickOutError(err);
        self.state = 'error';
      }
    }),

    create: flow(function* (data: any) {
      self.state = 'progressing';
      try {
        const { id } = yield api.post(`/accounts/payment-methods`, data);
        self.newMethodId = id;
        if (mxConfig.twitterId?.length) {
          //@ts-ignore
          window.twq('event', `tw-${mxConfig.twitterId}-oe2n8`);
        }
        sendEvent('enter_credit_card');
        self.state = 'done';
      } catch (err) {
        console.error(err);
        pickOutError(err);
        self.state = 'error';
      }
    }),

    update: flow(function* (id: string, data: any) {
      self.state = 'progressing';
      try {
        yield api.put(`/accounts/payment-methods/${id}`, data);
        if (mxConfig.twitterId?.length) {
          //@ts-ignore
          window.twq('event', `tw-${mxConfig.twitterId}-oe2n8`);
        }
        sendEvent('enter_credit_card');
        self.state = 'done';
      } catch (err) {
        console.error(err);
        pickOutError(err);
        self.state = 'error';
      }
    }),

    remove: flow(function* (item: Instance<typeof PaymentMethod>) {
      self.state = 'progressing';
      try {
        yield api.delete(`/accounts/payment-methods/${item.id}`);
        // destroy(item); it trigger error
        self.state = 'done';
      } catch (err) {
        console.error(err);
        pickOutError(err);
        self.state = 'error';
      }
    }),
  }));

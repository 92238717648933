import React, { useEffect, useState } from 'react';
import { Button, Radio, RadioGroup } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import { Col, CreditCard, ErrorToast, Row, DetailBox } from 'components';
import { PaymentMethodDialog } from '../PaymentMethodDialog';
import styles from './style.module.scss';
import { Intent } from '@blueprintjs/core/lib/esm/common/intent';
import { lang } from './helper';

interface IParams {
  onSubmit: () => void;
  onBack: () => void;
  amount?: number | string;
}

interface IProps extends IParams {}

export const BillingDetail: React.FC<IProps> = observer(({ onSubmit, onBack, amount }) => {
  const { paymentMethods, workspaces } = useStore();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [methodId, setMethodId] = useState<string | undefined>();
  const [progressing, setProgressing] = useState(false);

  useEffect(() => {
    (async () => {
      await paymentMethods.fetchList();
      setMethodId(paymentMethods.list[0]?.id);
    })();
  }, []);

  const onNext = async () => {
    await workspaces.updatePaymentMethod(workspaces.current!, { paymentMethodId: methodId });
    if (workspaces.state === 'error') {
      ErrorToast(workspaces.errorMessage!);
      return;
    }
    workspaces.markPaymentValid(workspaces.current!.id, true);
    onSubmit();
  };

  const onCreate = async (data: any) => {
    setShow(false);
  };

  return (
    <>
      <DetailBox className={styles.container}>
        {paymentMethods.list.length === 0 && (
          <>
            <h2 className={styles.title}>{t('modules.billingDetail.addPaymentMethod')}</h2>
            <div className={styles.addNewCard} onClick={() => setShow(true)}>
              <span className={styles.creditCard}></span>
              <p>{t('modules.billingDetail.addNewCreditCard')}</p>
            </div>
          </>
        )}
        {show && (
          <div className={styles.paymentOutsite}>
            <h2 className={styles.title}>{t('modules.billingDetail.enterYourPaymentDetails')}</h2>
            {amount && <p className={styles.subTitle}>{lang(t, 'amount', { num: amount })}</p>}
            <PaymentMethodDialog hasDialog={true} isOpen={show} postSubmit={onCreate} onClose={() => setShow(false)} />
          </div>
        )}
        {paymentMethods.list.length !== 0 && !show && (
          <div className={styles.paymentOutsite}>
            <h2 className={styles.title}>{t('modules.billingDetail.title')}</h2>
            {amount && <p className={styles.subTitle}>{lang(t, 'amount', { num: amount })}</p>}
            <p>{t('modules.billingDetail.selectPayment')}</p>
            <RadioGroup onChange={(e) => setMethodId(e.currentTarget.value)} selectedValue={methodId}>
              {paymentMethods.list.map((method) => (
                <Radio
                  className={styles.cardItem}
                  key={method.id}
                  labelElement={<CreditCard card={method.card} />}
                  value={method.id}
                />
              ))}
            </RadioGroup>
            <div className={styles.addNewCardButton} onClick={() => setShow(true)}>
              <span className={styles.creditCard}></span>
              <span>{t('modules.billingDetail.addNewCreditCard')}</span>
            </div>
          </div>
        )}
      </DetailBox>
      <Row style={{ marginTop: 40 }}>
        <Col unit={12}>
          <Button large onClick={onBack} className={styles.bottomBtn}>
            {t('pages.joinNetwork.buttonBack')}
          </Button>
        </Col>
        <Col unit={12} style={{ textAlign: 'right' }}>
          <Button
            large
            intent={Intent.PRIMARY}
            onClick={async () => {
              setProgressing(true);
              await onNext();
              setProgressing(false);
            }}
            className={styles.bottomBtn}
            loading={progressing}
            disabled={!methodId || show}
          >
            {t('pages.joinNetwork.buttonCreate')}
          </Button>
        </Col>
      </Row>
    </>
  );
});

import { Button, Intent, Classes } from '@blueprintjs/core';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteConfigComponentProps } from 'react-router-config';
import { NetworkCard } from '@/components/NetworkCard';
import { AntdCol, AntdRow, PageLayout, Loader, FixedMainHeader, NetworkSpecCard, NoData } from 'components';
import { plans } from '@/configuration';
import { useStore } from '@/store';
import { AppCard } from '@/components/NetworkSpecCard/appCard';
import { ReactComponent as SyncIcon } from './imgs/syncIcon.svg';
import { ReactComponent as WelcomeIcon } from './imgs/welcome.svg';
import { ReactComponent as NetworkIcon } from './imgs/networkIcon.svg';
import { NetworkSpecStatus } from '@/store/networks';
import styles from './style.module.scss';
import { Welcome } from './welcome';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

type IProps = RouteConfigComponentProps<{ wsId: string }>;

const lang = (t: TFunction, key: string) => t(`pages.networkList.${key}`);

export const Networks: React.FC<IProps> = observer(() => {
  const { networks, workspaces, nodes, auth, networkSpec } = useStore();
  const history = useHistory();
  const { t } = useTranslation();
  const wsId = workspaces.current!.id;
  const isPartner = (plans.find((i) => i.name === workspaces.current?.plan)?.permission || []).includes(
    'publishNetwork',
  );

  useEffect(() => {
    networks.fetchPrivateList(wsId);
    networkSpec.getPromoteNetworks(wsId);
    return () => {
      networks.clearPrivateList();
      networkSpec.clearPromoteNetworks();
    };
  }, []);
  const publicNetworks = networks.privateList.filter((i) => i.isPublic === true);
  const privateNetworks = networks.privateList.filter((i) => i.isPublic === false);
  const promoteNetworks = networkSpec.promoteNetworks.filter((i) => i.status === 'pending');
  let showHeader = false;
  if (!isPartner && networks.state !== 'pending' && privateNetworks.length > 0) showHeader = true;
  else if (isPartner && networks.state !== 'pending') showHeader = true;

  if (!isPartner && networks.state !== 'pending' && privateNetworks.length <= 0) {
    return <Welcome />;
  }

  return (
    <>
      {showHeader && (
        <FixedMainHeader>
          <AntdRow>
            <AntdCol span={12}>
              <h3 className={Classes.HEADING}>{lang(t, 'title')}</h3>
            </AntdCol>
            <AntdCol span={12} alignRight>
              <Button
                className={styles.refresh}
                intent={Intent.NONE}
                icon={<SyncIcon />}
                onClick={() => networks.fetchAllNetowrks(wsId)}
              />
              <Button
                className={styles.createNew}
                intent={Intent.PRIMARY}
                text={lang(t, 'createNewButton')}
                onClick={() => history.push(`/workspaces/${wsId}/networks/create`)}
                disabled={workspaces.current!.suspendTaskDone}
              />
            </AntdCol>
          </AntdRow>
        </FixedMainHeader>
      )}
      <PageLayout>
        <div className={styles.container}>
          {isPartner && (
            <>
              {(publicNetworks.length > 0 || promoteNetworks.length > 0) && (
                <h1 className={styles.sectionTitle}>
                  <span className={cx(styles.iconOutsideBlue, styles.iconOutside)}>
                    <NetworkIcon fill={'#fff'} />
                  </span>
                  <span>{lang(t, 'publicNetworkSpec')}</span>
                </h1>
              )}

              <div className={styles.NetworkContainer}>
                <Loader isLoading={!publicNetworks.length && networks.state === 'pending'}>
                  {!publicNetworks.length && !promoteNetworks.length && (
                    <div className={styles.emptyPromote}>
                      <WelcomeIcon />
                      <h3>{lang(t, 'noPublicTitle')}</h3>
                      <p>{lang(t, 'noPublicSubTitle')}</p>
                    </div>
                  )}
                  <div className={styles.networkList}>
                    {!!publicNetworks.length &&
                      publicNetworks.map(
                        (i) =>
                          i.status !== NetworkSpecStatus.Disabled &&
                          i.isPublic && (
                            <NetworkSpecCard
                              key={i.key}
                              showStatus={true}
                              network={i}
                              onClick={() =>
                                history.push(`/workspaces/${wsId}/networks/${i.key}?isPublic=${i.isPublic}`)
                              }
                            />
                          ),
                      )}
                    {promoteNetworks.map(
                      (i) =>
                        i.status !== 'approved' && (
                          <AppCard
                            key={i.id}
                            {...i}
                            onClick={() => {
                              history.push(`/workspaces/${wsId}/networkPromote/${i.id}`);
                            }}
                          />
                        ),
                    )}
                  </div>
                </Loader>
              </div>
              {isPartner && <hr className={styles.hr} />}
            </>
          )}
          {networks.state === 'done' && (isPartner || privateNetworks.length > 0) && (
            <h1 className={styles.sectionTitle}>
              <span className={styles.iconOutside}>
                <NetworkIcon fill={'#fff'} />
              </span>
              <span>{lang(t, 'customNetworkSpec')}</span>
            </h1>
          )}

          {isPartner && privateNetworks.length <= 0 && networks.state === 'done' && <NoData />}
          <Loader isLoading={privateNetworks.length <= 0 && networks.state === 'pending'}>
            {privateNetworks.length > 0 && (
              <>
                <div className={styles.networkList}>
                  {privateNetworks.map((network) => (
                    <NetworkCard
                      key={network.key}
                      network={network}
                      actions={[]}
                      onClick={() => {
                        history.push(
                          `/workspaces/${workspaces.current!.id}/networks/${network.key}?isPublic=${network.isPublic}`,
                        );
                      }}
                    />
                  ))}
                </div>
              </>
            )}
          </Loader>
        </div>
      </PageLayout>
    </>
  );
});

import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import cx from 'classnames';
import { Instance } from 'mobx-state-tree';
import { PlanItem } from '@/store/plan';
import { useStore } from '@/store';
import { Button, Modal } from 'antd';
import { ContactUs } from './form';
import { ConfirmModal } from './confirmModal';
import { CheckCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { lang } from './helper';
import sanitizeHtml from 'sanitize-html';

const { confirm } = Modal;
interface IProps extends Instance<typeof PlanItem> {
  current?: boolean;
  keyValue: string;
  onChangeSuccess?: () => void;
  planChange?: boolean;
  currentLevel?: number;
}

// it use to set black style
const blackList = ['managed_partner', 'partner'];

export const descriptionFormat = (value = '') => {
  if (value.trim() === '') return null;
  const list = value.split(/\n/g);
  return (
    <>
      {list.map((i, index) => (
        <li key={index} dangerouslySetInnerHTML={{ __html: sanitizeHtml(i) }}></li>
      ))}
    </>
  );
};

export const showPriceValue = (kind: undefined | string, value: number | undefined) => {
  if (['free', 'custom'].includes(kind + '')) return kind;
  else if (!value) return 'Custom';
  else return `$${value}`;
};

export const PlanCard: React.FC<IProps> = ({
  keyValue,
  displayName,
  available,
  price,
  description,
  type,
  selfSelect,
  current,
  onChangeSuccess,
  planChange,
  currentLevel,
}) => {
  const { t } = useTranslation();
  const { plan, workspaces, auth } = useStore();
  const { value, month, usage, kind } = price;
  const [showForm, setShowForm] = useState(false);
  const [showSelfChangePlanModal, setShowSelfChangePlanModal] = useState(false);
  const [isChangingPlan, setIsChangingPlan] = useState(false);
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const changePlan = async () => {
    setShowSelfChangePlanModal(true);
  };

  const notOwner = () => {
    Modal.warning({
      title: 'Contact Workspace Owner',
      className: styles.confirmModal,
      content: 'You don’t have permission to select plans, please contact your workspace owner.',
      centered: true,
      width: 468,
    });
  };

  useEffect(() => {
    setIsOwner(auth.user?.id === workspaces.current?.ownerId);
  }, []);

  const showConfirm = () => {
    confirm({
      title: 'Downgrade Plan',
      width: '468px',
      className: styles.confirmModal,
      content: <div>If you would like to downgrade your plan, please contact Onfinality to change it.</div>,
      onOk() {
        setShowForm(true);
      },
      okText: 'Contact us',
      centered: true,
    });
  };

  const suspended = workspaces.current!.suspendTaskDone;
  const currentPlan = plan.planList.find((i: Instance<typeof PlanItem>) => i.key === workspaces.current?.plan);
  const currentPlanCanSelfSelect = currentPlan ? currentPlan.selfSelect : false;
  return (
    <div
      className={cx({
        [styles.planCard]: true,
        [styles[`plan_${keyValue}`]]: true,
        [styles.current]: !!current,
        [styles.black]: blackList.includes(keyValue),
      })}
    >
      {!!current && <CheckCircleFilled className={styles.selectedIcon} />}
      <h5>{displayName}</h5>
      <div className={styles.price}>
        <span className={styles.priceValue}>{showPriceValue(kind, value)}</span>
        {value !== 0 && month && <span className={styles.priceSub}>/{lang(t, 'plan.month')}</span>}
      </div>
      {usage && <div className={styles.usage}>+{lang(t, 'plan.usageCost')}</div>}
      <ul className={styles.description}>{descriptionFormat(description)}</ul>
      {current && (
        <Button type={'text'} size={'large'} disabled>
          {lang(t, 'plan.currentPlan')}
        </Button>
      )}

      {!current && (suspended || !currentPlanCanSelfSelect || !selfSelect) && (
        <Button
          className={styles.button}
          size={'large'}
          onClick={() => {
            if (isOwner) {
              setShowForm(true);
            } else notOwner();
          }}
        >
          {suspended
            ? lang(t, 'plan.contactUs')
            : //@ts-ignore
            currentLevel < price.level
            ? lang(t, 'plan.contactUs')
            : lang(t, 'plan.downgrade')}
        </Button>
      )}
      {!current && !suspended && currentPlanCanSelfSelect && selfSelect && (
        <Button
          disabled={isChangingPlan}
          loading={isChangingPlan}
          className={styles.button}
          size={'large'}
          onClick={() => {
            if (isOwner) {
              //@ts-ignore
              currentLevel == 3 ? (currentLevel > price.level ? showConfirm() : changePlan()) : changePlan();
            } else notOwner();
          }}
        >
          {
            //@ts-ignore
            currentLevel < price.level ? lang(t, 'plan.select') : lang(t, 'plan.downgrade')
          }
        </Button>
      )}
      {showForm && <ContactUs planName={displayName + ''} onClose={() => setShowForm(false)} planChange={planChange} />}
      {showSelfChangePlanModal && (
        <ConfirmModal
          price={price}
          keyValue={keyValue}
          planName={displayName + ''}
          description={description}
          onClose={() => {
            setShowSelfChangePlanModal(false);
          }}
        />
      )}
    </div>
  );
};

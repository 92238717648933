import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import styles from '../../pages/Login/style.module.scss';
import { useStore } from '../../store';
import { ErrorToast, SuccessToast } from '../../components/Toast';

const defaultCounter = 15;

export const RecendConfirmationButton: React.FC = observer(() => {
  const [t] = useTranslation();
  const { auth } = useStore();
  const [disableBtn, setDisableBtn] = useState(false);
  const [counter, setCounter] = useState(defaultCounter);

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (disableBtn) {
      let val = defaultCounter;
      timer = setInterval(() => {
        setCounter(--val);
        if (val === 0) {
          clearInterval(timer);
          setCounter(defaultCounter);
          setDisableBtn(false);
        }
      }, 1000);
    }
  }, [disableBtn]);

  return (
    <Button
      large
      text={`${t('pages.login.reSendEmail')} ${disableBtn ? counter : ''}`}
      intent={Intent.PRIMARY}
      disabled={disableBtn}
      className={styles.submitButton}
      onClick={async () => {
        auth.registerEmail && (await auth.sendEmailVerification(auth.registerEmail));
        if (auth.state === 'error') ErrorToast(auth.errorMessage!);
        else SuccessToast(`${auth.registerEmail} ${t('pages.login.sentSuccess')}`);
        setDisableBtn(true);
      }}
    />
  );
});

import React, { useState } from 'react';
import { useStore } from 'store';
import { useForm } from 'react-hook-form';
import styles from './style.module.scss';
import { FormError, Row, Col } from 'components';
import { FormGroup, InputGroup, Button, Intent } from '@blueprintjs/core';
import { ReactComponent as Upload } from './img/upload.svg';
import cx from 'classnames';

interface UploadInput {
  file: File;
}

const fileLimitSize = 50 * 1000 * 1000;

interface IProps {
  disabled?: boolean;
  defaultValue?: string;
  showInput?: boolean;
  basePath?: string | null;
  onUploaded: (url: string, fileName: string) => void;
}

export const UploadFile: React.FC<IProps> = ({
  onUploaded,
  showInput = true,
  defaultValue,
  basePath = '',
  disabled = false,
}) => {
  const [fileName, setFileName] = useState<string | undefined>('');
  const [uploading, setUploading] = useState(false);
  const [fileMsg, setFileMsg] = useState({
    name: '',
    msg: '',
    url: '',
  });
  const { networks, workspaces } = useStore();

  const { register, errors, setError, getValues, clearError } = useForm();
  const onChange = async () => {
    clearError();
    const file = getValues('file')[0];
    const { name, size, type } = file || {
      name: '',
      size: 0,
      type: '',
    };
    if (!file) return;
    if (file.size > fileLimitSize) {
      return setError('file', 'message', `File size must not exceed 50M!`);
    }
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    setUploading(true);
    const { key } = await networks.uploadFile(bodyFormData);
    setUploading(false);
    setFileMsg({ name, msg: `Size ${Math.round(size / 10) / 100}k Format: ${type}`, url: key });
    if (key && reader.result) {
      setFileName(name);
      onUploaded(key, name);
    }
  };

  return (
    <div className={styles.uploadForm}>
      <form>
        {showInput && (
          <Row>
            <Col className={styles.inputCol}>
              <InputGroup
                value={fileMsg.name ? `${basePath}${basePath ? '/' : ''}${fileMsg.name}` : defaultValue}
                disabled={uploading || disabled}
              />
            </Col>
            <Col className={styles.buttonCol}>
              <div className={styles.uploadInput}>
                <Upload />
                <input disabled={uploading || disabled} name="file" type="file" ref={register} onChange={onChange} />
              </div>
            </Col>
          </Row>
        )}
        {!showInput && (
          <div className={cx(styles.uploadInput, styles.small)}>
            <Upload />
            <span>{fileName ? fileName : 'Upload'}</span>
            <input disabled={uploading || disabled} name="file" type="file" ref={register} onChange={onChange} />
          </div>
        )}
        <FormError>{errors.file && errors.file?.message}</FormError>
      </form>
    </div>
  );
};

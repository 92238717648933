import { HTMLSelect } from '@blueprintjs/core';
import React from 'react';

interface IProps {
  options: Array<{ label: string; value: any }>;
  value?: any;
  onChange?: (val: any) => void;
  recommendedVersion?: string;
}

export const FormSelect: React.FC<IProps> = ({ options, value, onChange, recommendedVersion }) => {
  return (
    <HTMLSelect
      name="paymentMethodId"
      fill
      large
      defaultValue={value}
      onChange={(e) => onChange && onChange(e.currentTarget.value)}
    >
      {options.map((op, idx) => (
        <option key={idx} value={op.value}>
          {op.label} {recommendedVersion !== undefined && recommendedVersion === op.value && '(Recommended)'}
        </option>
      ))}
    </HTMLSelect>
  );
};

import React from 'react';
import { observer } from 'mobx-react';
import styles from './style.module.scss';
import cx from 'classnames';
import { Logo } from '@/components';
import NetworkSelectionCarousel from './NetworkSelectionCarousel';
import NetworkSelectionTiles from './NetworkSelectionTiles';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Instance } from 'mobx-state-tree';
import { Iservice } from '@/store/apiService';
import { NetworkSelectionHook, useNetworkSelection } from './hook';
import { ReactComponent as PrivateIcon } from './imgs/private.svg';
import { Tooltip } from 'antd';
import { FullscreenOutlined as ExpandIcon, FullscreenExitOutlined as CollapseIcon } from '@ant-design/icons';
import { NetworkSpecStatus } from '@/store/networks';

interface Network extends Instance<typeof Iservice> {}
export interface NetworkListItem {
  network: string;
  count?: number;
  isEmpty?: boolean;
  isSelected?: boolean;
  fullName?: string;
  displayName?: string;
  isPersisted?: boolean;
  button?: boolean;
}

export interface NetworkSelectionProps {
  networkSelection: NetworkSelectionHook;
  renderCardTile: (item: NetworkListItem, index: number) => JSX.Element;
  noDataHeading?: string;
  noDataSubheading?: string;
  showExpanded?: boolean;
  onToggleExpand: any;
}

interface Props {
  allNetworks: Network[];
  networkPerPage?: number;
  onSelected?: (network: string) => void;
  onToggleExpand?: (expand: boolean) => void;
  expanded?: boolean;
  searchName: string;
  showExpanded?: boolean;
  appId?: string;
  setCount?: (num: number) => void;
}

export const NetworkSelection: React.FC<Props> = observer(
  ({
    onSelected,
    allNetworks,
    networkPerPage,
    expanded,
    onToggleExpand,
    searchName,
    appId,
    showExpanded = true,
    setCount,
  }) => {
    const { t } = useTranslation();
    const lang = (t: TFunction, key: string, opt?: any) => t(`components.networkSelection.${key}`, opt);
    // Share state across the children components
    const networkSelection = useNetworkSelection(allNetworks, appId, onSelected, networkPerPage, searchName);
    const { setSelectedNetwork, setPersistedNetwork } = networkSelection;
    const renderCardTile = (item: NetworkListItem, index: number) => {
      const { displayName, network, count, isEmpty, isSelected, fullName, button } = item;
      // Get the display data from the service list
      const service = allNetworks.find((x) => x.subDomain === network);
      const iconUrl = service?.metadata?.pictures?.icon || service?.metadata?.pictures?.small;
      const isPrivate = service?.status === NetworkSpecStatus.Hidden;

      const mainArea = document.getElementById('mainArea');
      if (button && !showExpanded)
        return (
          <div
            key={network}
            className={cx({
              'network-tile': true,
              'is-placeholder': true,
            })}
          ></div>
        );
      return (
        <div
          onClick={() => {
            isEmpty && button && onToggleExpand && (expanded ? onToggleExpand(false) : onToggleExpand(true));

            button && mainArea && mainArea.scrollTo(0, 0);

            if (isEmpty) {
              return;
            }
            setSelectedNetwork(network);
            localStorage.setItem('selectedNetwork' + appId ?? '', network);
            if (expanded) {
              setPersistedNetwork(network);
              // Collapse the list on select/click
              if (onToggleExpand) {
                onToggleExpand(false);
                mainArea && mainArea.scrollTo(0, 0);
              }
            }

            if (onSelected) {
              if (setCount && count !== undefined) setCount(count);
              onSelected(network);
            }
          }}
          key={network}
          className={cx({
            'network-tile': true,
            'is-placeholder': isEmpty && !button,
            'is-button': button,
            first: index === 0,
            active: isSelected,
            [styles.selectedCard]: isSelected,
          })}
        >
          {isPrivate && (
            <Tooltip title="Private Dedicated API">
              <PrivateIcon className={styles.privateIcon} />
            </Tooltip>
          )}
          {button &&
            (expanded ? (
              <>
                <CollapseIcon className={styles.expandIcon} />
                <span className={styles.showIcon}>{lang(t, 'collapse')}</span>
              </>
            ) : (
              <>
                <ExpandIcon className={styles.expandIcon} />
                <span className={styles.showIcon}>{lang(t, 'expand')}</span>
              </>
            ))}
          {!isEmpty && (
            <>
              <span className="selection-indicator" />
              <Logo size={40} src={iconUrl} />
              <Tooltip title={fullName} placement="top">
                <div className="network-label">{displayName}</div>
              </Tooltip>
              <div className="request-label">
                <span>{lang(t, 'countDuration')}</span>
                <span className="count">{count?.toLocaleString()}</span>
              </div>
            </>
          )}
        </div>
      );
    };

    const selectionProps: NetworkSelectionProps = {
      networkSelection,
      renderCardTile,
      noDataHeading: lang(t, 'noDataHeading'),
      noDataSubheading: lang(t, 'noDataSubheading'),
      onToggleExpand,
    };

    return (
      <div className={styles.networkSelectionContainer}>
        {!expanded && <NetworkSelectionCarousel {...selectionProps} />}
        {expanded && <NetworkSelectionTiles {...selectionProps} showExpanded={showExpanded} />}
      </div>
    );
  },
);

import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Dialog, Classes, Intent, Button } from '@blueprintjs/core';
import { Row, Col, DetailBox, ButtonEmpty, SubmitButton, NodeSizeStorageSize } from 'components';
import { useStore } from 'store';
import { capitalize } from 'lodash';
import { lang } from './helper';
import { ReactComponent as EditIcon } from './img/edit.svg';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { createNodeMinStorage } from '@/configuration';

interface IProps {
  nodeTypeKey: string;
  nodeTypes: Array<any>;
  imageVersion: any;
  editMode?: boolean;
  onCallback: (value: any) => void;
  backupSize?: number | null;
}

export const Recommended: React.FC<IProps> = observer(
  ({ nodeTypeKey, nodeTypes, imageVersion, editMode, onCallback, backupSize }) => {
    const { t } = useTranslation();
    const { info } = useStore();
    const nodeTypeForRecommend: any = nodeTypes.find((i: any) => i.key === nodeTypeKey);
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { nodeSpecMultiplier, storageSize } = nodeTypeForRecommend?.recommend || {
      nodeSpecMultiplier: 1,
      storageSize: 10,
    };
    const [data, setData] = useState({ nodeSpecMultiplier, storageSize });
    const [tempData, setTempData] = useState({ nodeSpecMultiplier, storageSize });
    const unitMemory = info.nodeSpecs.find((i) => i.key === 'unit');
    const minStorageSize = createNodeMinStorage;
    return (
      <>
        <Row>
          <Col unit={12}>
            <h2>{lang(t, 'recommendTitle')}</h2>
          </Col>
          <Col unit={12} alignRight>
            <ButtonEmpty
              icon={<EditIcon />}
              onClick={() => {
                setShowDialog(true);
              }}
            >
              {nodeTypeForRecommend?.recommend ? lang(t, 'edit') : lang(t, 'add')}
            </ButtonEmpty>
          </Col>
        </Row>

        {nodeTypeForRecommend?.recommend && (
          <DetailBox className={styles.networkDetail}>
            <table className={styles.table}>
              <tbody>
                <tr>
                  <th>
                    {nodeTypeKey} {lang(t, 'node')}
                  </th>
                  <td>
                    <p>
                      {lang(t, 'nodeSize')}: {data.nodeSpecMultiplier} Units (CPU:{' '}
                      {(data.nodeSpecMultiplier * unitMemory!.cpuSize).toFixed(1)}, Mem:{' '}
                      {((data.nodeSpecMultiplier * unitMemory!.memorySize) / 1000).toFixed(1)}
                      GB)
                    </p>
                    <p>
                      {lang(t, 'storageSize')}: {backupSize ?? data.storageSize}GB
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </DetailBox>
        )}
        <Dialog
          title={lang(t, 'recommendDialogTitle', {
            nodeType: capitalize(nodeTypeKey),
            action: nodeTypeForRecommend?.recommend ? lang(t, 'edit') : lang(t, 'add'),
          })}
          isOpen={showDialog}
          style={{ width: '700px' }}
          onClose={() => {
            setShowDialog(false);
          }}
        >
          <div className={cx(Classes.DIALOG_BODY, styles.nodeSpecDialogBody)}>
            <h4>{lang(t, 'recommendTitle')}</h4>
            <div className={styles.sectionContainer}>
              <p>{lang(t, 'recommendTitleIntroduction')}</p>
              <NodeSizeStorageSize
                showPrice
                onSubmit={(nodeSpecMultiplier, comStorageSize) => {
                  setTempData({
                    nodeSpecMultiplier,
                    storageSize: comStorageSize,
                  });
                }}
                multiplier={nodeSpecMultiplier}
                storageSize={backupSize ?? storageSize}
              />
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                onClick={() => {
                  setShowDialog(false);
                }}
              >
                {lang(t, 'cancel')}
              </Button>
              <SubmitButton
                type="submit"
                loading={isLoading}
                disabled={isLoading || tempData.storageSize < minStorageSize}
                onClick={async () => {
                  setData(tempData);
                  const callbackData = nodeTypes.map((i) => {
                    const recommend = i.recommend;
                    if (!recommend) {
                      if (!editMode || i.key == nodeTypeForRecommend.key) {
                        return {
                          imageVersion: imageVersion,
                          nodeType: i.key,
                          nodeSpecKey: recommend?.nodeSpec || 'unit',
                          storageSize: tempData.storageSize,
                          storageType: recommend?.storageType || 'ssd',
                          nodeSpecMultiplier: tempData.nodeSpecMultiplier,
                        };
                      }
                      return null;
                    }
                    if (i.key === nodeTypeKey)
                      return {
                        imageVersion: recommend.imageVersion,
                        nodeType: i.key,
                        nodeSpecKey: recommend.nodeSpec || 'unit',
                        storageSize: tempData.storageSize,
                        storageType: recommend.storageType || 'ssd',
                        nodeSpecMultiplier: tempData.nodeSpecMultiplier,
                      };
                    return {
                      imageVersion: recommend.imageVersion,
                      nodeType: i.key,
                      nodeSpecKey: recommend.nodeSpec || 'unit',
                      storageSize: recommend.storageSize,
                      storageType: recommend.storageType || 'ssd',
                      nodeSpecMultiplier: recommend.nodeSpecMultiplier,
                    };
                  });
                  setIsLoading(true);
                  await onCallback(
                    callbackData.filter((element) => {
                      return element !== null;
                    }),
                  );
                  setIsLoading(false);
                }}
                intent={Intent.PRIMARY}
              >
                {lang(t, 'save')}
              </SubmitButton>
            </div>
          </div>
        </Dialog>
      </>
    );
  },
);

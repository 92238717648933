import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { useStore } from '@/store';
import React, { useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Spin } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { ReactComponent as Logo } from '../../themes/default/logo.svg';
import { ReactComponent as LogoIcon } from '../../themes/default/logoIcon.svg';
import { observer } from 'mobx-react';
import { Header } from '../Header';
import { Link, useHistory } from 'react-router-dom';
import { LeftMenu } from 'modules';
import cx from 'classnames';
import { SuspendCallout } from 'components';
import { AcceptTermsDialog } from './AcceptTermsDialog';
import { ErrorPage } from '../../pages/Error/ErrorPage';
import styles from '@/modules/App/style.module.scss';
import { mxConfig } from '@/config';

const { Content, Sider } = Layout;

interface IParams {
  wsId: string;
}

interface IProps extends RouteConfigComponentProps<IParams> {}

export const Workspace: React.FC<IProps> = observer((props) => {
  const { workspaces, usageCenter, auth } = useStore();
  const [collapsed, setCollapsed] = useState(false);
  const [dialog, setDialog] = useState<string | undefined>('showDialog');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { match, route } = props;
  const { currentTosVer, currentPpVer } = mxConfig;

  const containerRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    if (containerRef.current) containerRef.current.scrollTo(0, 0);
  }, [history.location]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await workspaces.setCurrent(match.params.wsId);
      usageCenter.checkHasData(match.params.wsId);
      setIsLoading(false);
      // await store.networks.fetchAllNetowrks(match.params.wsId);
    })();
  }, [match.params.wsId]);

  if (isLoading) {
    return <Spin />;
  }

  if (!workspaces.current) {
    return <ErrorPage {...props} theErrorText="This workspace doesn't exist." />;
  }

  const tosComparison = new Date(auth.user?.acceptedTosVer as string) < new Date(currentTosVer as string);
  const ppComparison = new Date(auth.user?.acceptedPpVer as string) < new Date(currentPpVer as string);
  const showAcceptTermsDialog = tosComparison || ppComparison;
  return (
    <>
      <Header>
        <Row>
          <Col>
            <Link className={cx({ [styles.logo]: true, [styles.collapsed]: collapsed })} to={`/`}>
              {collapsed ? <LogoIcon /> : <Logo />}
            </Link>
          </Col>
          <Col className={cx({ [styles.margin15]: !collapsed })}>
            <Button
              className={styles.switchSiderButton}
              type="primary"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            />
          </Col>
        </Row>
      </Header>
      <Layout className={styles.container}>
        <Sider
          collapsedWidth={80}
          width={250}
          // collapsible
          collapsed={collapsed}
          // onCollapse={setCollapsed}
          // trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        >
          <LeftMenu collapsed={collapsed} />
        </Sider>

        <Content>
          <div
            id={'mainArea'}
            className={cx({ [styles.mainArea]: true, [styles.collapsed]: collapsed })}
            ref={containerRef}
          >
            {workspaces!.current && (
              <>
                {workspaces.current!.suspendTaskDone && (
                  <div className={styles.callout}>
                    <SuspendCallout />
                  </div>
                )}
                <div className={styles.maxWidth}>{renderRoutes(route!.routes)}</div>
              </>
            )}
            {workspaces.current === undefined && workspaces.state !== 'pending' ? (
              <div className={styles.maxWidth}>
                <ErrorPage {...props} noLogo={true} theErrorText="This workspace doesn't exist." />
              </div>
            ) : (
              ''
            )}
          </div>
        </Content>
      </Layout>
      <AcceptTermsDialog
        isOpen={showAcceptTermsDialog && dialog === 'showDialog'}
        onClose={() => setDialog(undefined)}
      />
    </>
  );
});

import { Instance } from 'mobx-state-tree';
import { useInterval } from 'usehooks-ts';
import { useEffect, useMemo } from 'react';
import { Node, NodeStatus } from '@/store/nodes';

export const useFetchEndpoints = (node: Instance<typeof Node>, fetchInterval = 5000) => {
  // Keep fetching until we can have all relevant RPC endpoints
  const shouldFetch = useMemo(
    () => node.status === NodeStatus.Running && !node.isRpcAvailable,
    [node.isRpcAvailable, node.status],
  );

  // Fetching timer
  useInterval(
    () => {
      node.fetchEndpoints();
    },
    shouldFetch ? fetchInterval : null,
  );

  // Initial fetch
  useEffect(() => {
    if (shouldFetch) {
      node.fetchEndpoints();
    }
  }, [shouldFetch]);
};

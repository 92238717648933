import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Dialog, Classes, Intent, Button } from '@blueprintjs/core';
import { RuleAction, checkHasError, ConfigErrorCode } from '@/components/UpdateNetworkRules/helpers';
import { capitalize } from 'lodash';
import { Row, Col, ButtonEmpty, SubmitButton, UpdateNetworkRules, Preview, WarningToast } from 'components';
import { LaunchDataMapBaseNodeTypeType, TabsId } from '@/components/Preview/help';
import { ReactComponent as EditIcon } from './img/edit.svg';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import cx from 'classnames';
import { lang } from './helper';

interface IProps {
  nodeType: string;
  data: LaunchDataMapBaseNodeTypeType;
  onCallback: (val: any) => void;
}

export const Argument: React.FC<IProps> = observer(({ data, onCallback, nodeType }) => {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState<boolean | TabsId>(false);
  const [tabId, setTabId] = useState<TabsId>(TabsId.ARG);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const copyData = JSON.parse(JSON.stringify(data));
  const [argument, setArgument] = useState<LaunchDataMapBaseNodeTypeType>(copyData);

  return (
    <>
      <Row className={styles.contentHeader}>
        <Col unit={12}></Col>
        <Col unit={12} alignRight>
          <ButtonEmpty
            icon={<EditIcon />}
            onClick={() => {
              setShowDialog(tabId);
            }}
          >
            {lang(t, 'edit')}
          </ButtonEmpty>
        </Col>
      </Row>
      <div className={styles.networkDetail}>
        <Preview
          currentId={tabId}
          data={data}
          onTabChange={(id: TabsId) => {
            setTabId(id);
          }}
        />
      </div>
      {showDialog === TabsId.ARG && (
        <Dialog
          title={lang(t, 'argumentDialogTitle', { nodeType: capitalize(nodeType) })}
          isOpen={showDialog === TabsId.ARG}
          canOutsideClickClose={false}
          style={{ width: '700px' }}
          onClose={() => {
            setShowDialog(false);
          }}
        >
          <div className={cx(Classes.DIALOG_BODY, styles.nodeSpecDialogBody)}>
            <h4>{lang(t, 'argumentTitle')}</h4>
            <div className={styles.sectionContainer}>
              {copyData &&
                Object.keys(copyData.comArgRules).map((section: string) => {
                  const argItem = copyData.comArgRules[section];
                  if (argItem.action === RuleAction.REMOVE) return;
                  return (
                    <div key={section} className={styles.section}>
                      <h5>{argItem?.title}</h5>
                      <UpdateNetworkRules
                        hasError={hasError}
                        section={section + ''}
                        initData={argItem.data}
                        onChange={(v) => {
                          const comArgRulesCopy = { ...argument };
                          const comArgRulesCopyData = { ...argItem };
                          comArgRulesCopyData.data = v;
                          comArgRulesCopy.comArgRules[section] = comArgRulesCopyData;
                          setArgument(comArgRulesCopy);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                onClick={() => {
                  setShowDialog(false);
                }}
              >
                {lang(t, 'cancel')}
              </Button>
              <SubmitButton
                type="submit"
                onClick={async () => {
                  let hasError = false as any;
                  Object.keys(argument.comArgRules).map((section: string) => {
                    const errorCode = checkHasError(argument.comArgRules[section].data);
                    if (errorCode) hasError = errorCode;
                  });
                  setHasError(hasError);
                  setIsLoading(true);
                  if (hasError) {
                    if (ConfigErrorCode.EMPTY === hasError) WarningToast('Key can’t be empty');
                    else if (ConfigErrorCode.SAME === hasError) WarningToast('You have same key in a group');
                  }
                  if (!hasError) await onCallback(argument);
                  setIsLoading(false);
                }}
                intent={Intent.PRIMARY}
                disabled={isLoading}
                loading={isLoading}
              >
                {lang(t, 'save')}
              </SubmitButton>
            </div>
          </div>
        </Dialog>
      )}
      {showDialog === TabsId.ENV && (
        <Dialog
          title={lang(t, 'argumentDialogTitle', { nodeType: capitalize(nodeType) })}
          isOpen={showDialog === TabsId.ENV}
          style={{ width: '700px' }}
          onClose={() => {
            setShowDialog(false);
          }}
        >
          <div className={cx(Classes.DIALOG_BODY, styles.nodeSpecDialogBody)}>
            <h4>{lang(t, 'envTitle')}</h4>
            <div className={styles.sectionContainer}>
              <UpdateNetworkRules
                hasError={hasError}
                initData={JSON.parse(JSON.stringify(data.envRules))}
                onChange={(data) => {
                  const comArgRulesCopy = { ...argument };
                  comArgRulesCopy.envRules = data;
                  setArgument(comArgRulesCopy);
                }}
              />
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                onClick={() => {
                  setShowDialog(false);
                }}
              >
                {lang(t, 'cancel')}
              </Button>
              <SubmitButton
                type="submit"
                onClick={async () => {
                  let hasError = false as any;
                  const errorCode = checkHasError(argument.envRules);
                  if (errorCode) {
                    hasError = errorCode;
                  }
                  setHasError(hasError);
                  setIsLoading(true);
                  if (hasError) {
                    if (ConfigErrorCode.EMPTY === hasError) WarningToast('Key can’t be empty');
                    else if (ConfigErrorCode.SAME === hasError) WarningToast('You have same key in a group');
                  }
                  if (!hasError) await onCallback(argument);
                  setIsLoading(false);
                }}
                intent={Intent.PRIMARY}
                disabled={isLoading}
                loading={isLoading}
              >
                {lang(t, 'save')}
              </SubmitButton>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
});

export interface MxConfig {
  hotjarId?: number;
  stripePublishableKey?: string;
  domain: string;
  homePage: string;
  apiUrl: string;
  apiUrlV2: string;
  lightningRestoreDocument: string;
  networkEditOnOff: {
    versionList: boolean;
    bootnodes: boolean;
    recommend: boolean;
  };
  auth: {
    domain: string;
    clientID: string;
    redirectUri: string;
  };
  recommendNetworks: string[];
  featureFlags: {
    showUserNetworkSpec?: boolean;
    showNetworkAPIReport?: boolean;
    isWhiteLabelSite?: boolean;
  };
  currentTosVer: string;
  currentPpVer: string;
  popularNetworkKey?: string[];
  popularNetworkKeyForNodes?: string[];
  gaMeasurementId?: string;
  networkWhiteList?: string[];
  captchaPublicSiteKey: string;
  httpOnlyNetworks?: string[];
  twitterId?: string;
  linkedinId?: string;
  clusterHash: { [key: string]: string[] };
}

let mxConfig: MxConfig;

// This must be called before everything else
export const loadMxConfig = async () => {
  if (!mxConfig) {
    const { config } = await import(`./config.${process.env.REACT_APP_ENV}.ts`);
    mxConfig = Object.freeze(config);
  }
  return mxConfig;
};

export { mxConfig };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { DataMapItem, RuleAction, lang } from './helpers';
import { LaunchConfigurationValueType } from '@/components/Preview/help';
import { Item } from './item';

interface IProps {
  hasError?: boolean;
  initData: Array<DataMapItem>;
  section?: string;
  onChange: (values: any) => void;
}

export const UpdateNetworkRules: React.FC<IProps> = ({ onChange, initData, section, hasError = false }) => {
  const { t } = useTranslation();
  const [rulesData, setRulesData] = useState<Array<DataMapItem>>(Object.assign([], initData));

  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{lang(t, 'keyName')}</th>
            <th>{lang(t, 'type')}</th>
            <th>{lang(t, 'value')}</th>
            <th>{lang(t, 'lock')}</th>
            <th>{lang(t, 'action')}</th>
          </tr>
        </thead>
        <tbody>
          {rulesData.map((data: DataMapItem, previewDataMapIndex: number) => {
            if (data.action === RuleAction.REMOVE) return;
            return (
              <Item
                section={section}
                showError={hasError}
                key={previewDataMapIndex}
                data={{ ...data }}
                onCallback={(data: DataMapItem, deleteData?: DataMapItem) => {
                  let isExitKey = false;
                  rulesData.forEach((itemData: DataMapItem, checkDataIndex: number) => {
                    if (itemData.key === data.key && checkDataIndex !== previewDataMapIndex) {
                      isExitKey = true;
                      rulesData[checkDataIndex].action = RuleAction.UPSERT;
                      rulesData[checkDataIndex].payload = data.payload;
                      rulesData[checkDataIndex].valueType = data.valueType;
                    }
                  });
                  let thisPreviewDataMapCopy = [] as Array<DataMapItem>;
                  if (isExitKey) {
                    thisPreviewDataMapCopy = rulesData.filter(
                      (itemData: DataMapItem, checkDataIndex: number) => checkDataIndex !== previewDataMapIndex,
                    );
                  } else {
                    thisPreviewDataMapCopy = [...rulesData];
                    thisPreviewDataMapCopy[previewDataMapIndex] = data;
                  }
                  if (deleteData) {
                    const isExitData = rulesData.find(
                      (i) => i.humpKey === deleteData.humpKey && i.action !== RuleAction.REMOVE,
                    );
                    if (isExitData) thisPreviewDataMapCopy.push(deleteData);
                  }
                  setRulesData(thisPreviewDataMapCopy);
                  onChange(thisPreviewDataMapCopy);
                }}
              />
            );
          })}
        </tbody>
      </table>
      <div
        className={styles.addRule}
        onClick={() => {
          const thisPreviewDataMapCopy = [...rulesData];
          thisPreviewDataMapCopy.push({
            action: RuleAction.NULL,
            humpKey: '',
            key: '',
            originalOverwritable: true,
            overwritable: true,
            payload: '',
            valueType: LaunchConfigurationValueType.String,
          });
          setRulesData(thisPreviewDataMapCopy);
          onChange(thisPreviewDataMapCopy);
        }}
      >
        + {lang(t, 'addRule')}
      </div>
    </>
  );
};

import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { lang } from './helpers';
import { useStore } from 'store';
import styles from './style.module.scss';
import cx from 'classnames';

interface IProps {
  step?: number;
  icon?: string;
  networkDisplayName?: string;
}

export const Steps: React.FC<IProps> = observer(({ step = 1, icon, networkDisplayName }) => {
  const { workspaces, auth } = useStore();
  const { t } = useTranslation();

  const stepGroup =
    !workspaces.current!.validPayment && auth.user?.id === workspaces.current?.ownerId ? [1, 2, 3, 4, 5] : [1, 2, 3, 4];

  return (
    <div className={styles.steps}>
      {networkDisplayName && (
        <h1>
          {icon && (
            <div>
              <span className={styles.icon}>
                <img src={icon} />
              </span>
            </div>
          )}
          <span>{lang(t, 'title', { networkName: networkDisplayName })}</span>
        </h1>
      )}
      {!networkDisplayName && <h1>{lang(t, 'stepTitle')}</h1>}

      <ol>
        {stepGroup.map((i, index) => {
          if (i !== 1) {
            return (
              <li key={i} className={cx({ [styles.current]: step === i, [styles.arrive]: step >= i })}>
                <span>{index}</span>
                <div>{lang(t, `step${i}`)}</div>
              </li>
            );
          }
        })}
      </ol>
    </div>
  );
});

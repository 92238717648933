import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Input } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { lang } from '../helper';

import styles from '../style.module.scss';

const { Paragraph } = Typography;

interface Props {
  wsEndpoint?: string;
  httpEndpoint?: string;
  active?: boolean;
}

const notAvailablePanel = (html: string, spin = true) => (
  <div className={styles.hintContainer}>
    {spin && <SyncOutlined spin={true} />}
    &nbsp;
    <span dangerouslySetInnerHTML={{ __html: html }}></span>
  </div>
);

export const SubstrateRpcTabContent: React.FC<Props> = ({ wsEndpoint, httpEndpoint, active }) => {
  const { t } = useTranslation();
  const canShowEndpoint = useMemo(() => !!wsEndpoint || !!httpEndpoint, [wsEndpoint, httpEndpoint]);
  return (
    <div className={cx({ [styles.hide]: !active })}>
      {!canShowEndpoint && notAvailablePanel(lang(t, 'detailTitle.fetchingRpcEndpoints'))}
      {canShowEndpoint && (
        <>
          <p className={styles.addressTitle}>{lang(t, 'detailTitle.http')}</p>
          {!httpEndpoint && notAvailablePanel(lang(t, 'detailTitle.rpcEndpointUnavailable'), false)}
          {httpEndpoint && (
            <Input
              size={'large'}
              disabled
              className={styles.apiKeyInput}
              suffix={
                <Paragraph copyable className={styles.copyButton}>
                  {httpEndpoint}
                </Paragraph>
              }
              value={httpEndpoint}
            />
          )}
          <p className={styles.addressTitle}>{lang(t, 'detailTitle.websocket')}</p>
          {!wsEndpoint && notAvailablePanel(lang(t, 'detailTitle.rpcEndpointUnavailable'), false)}
          {wsEndpoint && (
            <Input
              size={'large'}
              disabled
              className={styles.apiKeyInput}
              suffix={
                <Paragraph copyable className={styles.copyButton}>
                  {wsEndpoint}
                </Paragraph>
              }
              value={wsEndpoint}
            />
          )}
        </>
      )}
    </div>
  );
};

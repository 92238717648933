import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import cx from 'classnames';
import { Button, Intent, FormGroup, InputGroup, TextArea } from '@blueprintjs/core';
import { Switch } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { Col, Row, Preview, DetailBox, SubmitButton } from 'components';
import { LaunchConfigurationItem } from './item';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { label } from './item';
import { Modal } from 'antd';
import {
  LaunchConfigurationValueType,
  LaunchConfigurationItemFormData,
  LaunchDataMapBaseNodeTypeType,
  TabsId,
} from '@/components/Preview/help';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useStore } from '@/store';
import { OperationalFlags } from '@/store/nodes';

const { confirm } = Modal;
const initLaunchConfigurationMapData = {
  comArgRules: {},
  delimiter: '',
  envRules: [],
  envExtra: [],
};

export const initNodeFormData = {
  initFromBackup: true,
  useApiKey: true,
  metadata: {},
  nodeType: 'full',
  useRecommend: true,
  config: {
    vars: [],
    extraArgs: {},
    extraEnvs: [],
  },
  autoExpand: true,
};

interface IProps {
  notPreview?: boolean;
  extraError?: boolean | string | number;
  onBack: () => void;
  submitButtonText: string;
  cancelButtonText: string;
  dataMap?: LaunchDataMapBaseNodeTypeType;
  loading?: boolean;
  disabled?: boolean;
  onSubmit: (dataMap: any) => void;
  onOperationalFlags?: (flag: number) => void;
  availableOpsFlags?: OperationalFlags;
  operationalFlag?: number | null;
  showOperationalSection?: boolean;
}
const waitTime = 300;

export const LaunchConfiguration: React.FC<IProps> = observer((props) => {
  const {
    onSubmit,
    onBack,
    submitButtonText,
    cancelButtonText,
    loading,
    notPreview,
    extraError = false,
    onOperationalFlags,
    availableOpsFlags,
    operationalFlag,
    showOperationalSection,
  } = props;
  const initSataMap = JSON.parse(JSON.stringify(props.dataMap)) || initLaunchConfigurationMapData;
  const [disabled, setDisabled] = useState(props.disabled || false);
  const [tabId, setTabId] = useState<TabsId>(TabsId.ARG);
  const [dataMap, setDataMap] = useState<LaunchDataMapBaseNodeTypeType>(initSataMap);
  const [reservedPeers, setReservedPeers] = useState<boolean>(false);
  const { control } = useForm();
  const { nodes } = useStore();

  // Reserved peers control
  useEffect(() => {
    if (!dataMap.operationalArgs || !availableOpsFlags) {
      return;
    }
    const flag = operationalFlag || 0;
    setReservedPeers(
      flag === (availableOpsFlags.cluster_args | availableOpsFlags.relay_chain | availableOpsFlags.reserved_peers),
    );
  }, [operationalFlag, dataMap.operationalArgs, availableOpsFlags]);

  const changeValue = _.debounce((key: string, value: any, section: string, sections?: Array<string>) => {
    if (disabled) setDisabled(false);
    const objectCopy = [...dataMap.comArgRules[section].data];
    const objectIndex = objectCopy.findIndex((item: any) => item.key === key);
    if (objectIndex >= 0) {
      objectCopy[objectIndex].payload = value;
      const dataMapClone = { ...dataMap };
      if (sections && sections.length) {
        sections.map((section: string) => {
          dataMapClone.comArgRules[section].data = objectCopy;
        });
      } else dataMapClone.comArgRules[section].data = objectCopy;
      setDataMap(dataMapClone);
    }
  }, waitTime);

  const changeEnv = _.debounce((key: string, value: any) => {
    if (disabled) setDisabled(false);
    const dataMapClone = { ...dataMap };
    dataMapClone.envRules = dataMapClone.envRules.map((i: LaunchConfigurationItemFormData) => {
      if (i.key === key)
        return {
          ...i,
          payload: value,
        };
      return i;
    });
    setDataMap(dataMapClone);
  }, waitTime);

  const changeExtraArgs = _.debounce((value: string, section: string) => {
    if (disabled) setDisabled(false);
    const dataMapClone = { ...dataMap };
    let values = value.split('\n');
    values = values.filter((v: string) => {
      v = v.trim();
      return !!v;
    });
    dataMapClone.comArgRules[section].extraArgData = values;
    setDataMap(dataMapClone);
  }, waitTime);

  const changeEnvExtraArgs = _.debounce((value: string) => {
    if (disabled) setDisabled(false);
    const dataMapClone = { ...dataMap };
    if (!dataMapClone.envExtra) dataMapClone.envExtra = [];
    let values = value.split('\n');
    values = values.filter((v: string) => {
      v = v.trim();
      return !!v;
    });
    dataMapClone.envExtra = values;
    if (dataMapClone.envExtra.join('') === '') dataMapClone.envExtra = [];
    setDataMap(dataMapClone);
  }, waitTime);

  const findBasePath = (data: Array<any>) => {
    const result = data.find((pIten: any) => pIten.humpKey === 'basePath');
    return result ? result.payload : '';
  };
  let basePath = '' as string | undefined | null;
  const sections = Object.keys(dataMap.comArgRules);

  const showConfirm = (dataMap: LaunchDataMapBaseNodeTypeType) => {
    confirm({
      title: 'Do you want to apply this change?',
      icon: <ExclamationCircleOutlined />,
      content:
        'This node is used for the OnFinality Public API.  By changing the arguments you may take the Public API offline, so please confirm with the OnFinality team first so they can ensure that this will not impact your customers.',
      onOk() {
        Object.keys(dataMap.comArgRules).map((section: string) => {
          dataMap.comArgRules[section].data = dataMap.comArgRules[section].data.filter(
            (i: LaunchConfigurationItemFormData) => i.action !== 'remove',
          );
        });
        onSubmit(dataMap);
      },
      onCancel() {},
    });
  };

  return (
    <div className={styles.launchContainer}>
      <DetailBox className={styles.section}>
        <div className={cx(styles.section, styles.launchConfiguration)}>
          <Row gutter>
            <Col unit={14}>
              <div>
                {!!dataMap.comArgRules &&
                  !!sections.length &&
                  sections.map((section: string) => {
                    const dataItem = dataMap.comArgRules[section];
                    if (!basePath) basePath = findBasePath(dataItem.data);
                    return (
                      <div key={section} className={styles.section}>
                        <div className={styles.chainStyle}>
                          <span>{dataItem.title}</span>
                        </div>
                        {dataItem.data.map((i: any) => (
                          <LaunchConfigurationItem
                            basePath={basePath}
                            item={i}
                            section={section}
                            sections={sections}
                            setTabId={setTabId}
                            changeValue={changeValue}
                          />
                        ))}
                        <FormGroup
                          inline
                          label={label(`Extra Args`, extraError === section, LaunchConfigurationValueType.String, '')}
                        >
                          <Controller
                            name={dataItem.extraArgKey}
                            large
                            defaultValue={dataItem.extraArgData.join('\n')}
                            control={control}
                            onChange={([e]) => {
                              changeExtraArgs(e.currentTarget.value, section);
                            }}
                            as={
                              <TextArea
                                placeholder={'--argname=config'}
                                fill
                                onFocus={() => {
                                  setTabId(TabsId.ARG);
                                }}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                    );
                  })}
              </div>
              <div className={styles.chainStyle}>
                <span>Environment</span>
              </div>
              {!!dataMap.envRules.length &&
                dataMap.envRules.map(
                  (i: LaunchConfigurationItemFormData) =>
                    i.overwritable && (
                      <FormGroup inline key={i.humpKey} label={`${i.key}`}>
                        {/* {i.valueType === LaunchConfigurationValueType.Array && (
                          <ArrayComponent
                            onSubmit={(data) => {
                              changeValue(i.key, data, section);
                            }}
                            data={i.payload}
                          />
                        )} */}
                        {/* {i.valueType === LaunchConfigurationValueType.File && (
                          <UploadFile
                            basePath={basePath}
                            defaultValue={
                              i.payload.destination && basePath ? `${basePath}/${i.payload.destination}` : ''
                            }
                            onUploaded={(url: string, fileName: string) => {
                              changeValue(
                                i.key,
                                {
                                  destination: fileName,
                                  source: url,
                                },
                                section,
                              );
                            }}
                          />
                        )} */}
                        {i.valueType === LaunchConfigurationValueType.String && i.humpKey && i.key && (
                          <Controller
                            name={i.humpKey}
                            large
                            defaultValue={i.payload}
                            control={control}
                            onChange={([e]) => {
                              changeEnv(i.key + '', e.currentTarget.value);
                            }}
                            as={
                              <InputGroup
                                fill
                                required
                                onFocus={() => {
                                  setTabId(TabsId.ENV);
                                }}
                              />
                            }
                          />
                        )}
                      </FormGroup>
                    ),
                )}
              <FormGroup inline label={`Extra Args`}>
                <Controller
                  name={'envRules'}
                  large
                  defaultValue={dataMap.envExtra?.join('\n')}
                  control={control}
                  onChange={([e]) => {
                    changeEnvExtraArgs(e.currentTarget.value);
                  }}
                  as={
                    <TextArea
                      placeholder={'--argname=config'}
                      fill
                      onFocus={() => {
                        setTabId(TabsId.ENV);
                      }}
                    />
                  }
                />
              </FormGroup>
              {showOperationalSection && (
                <>
                  <div className={styles.chainStyle}>
                    <span>Operational</span>
                  </div>
                  {availableOpsFlags && (
                    <FormGroup inline key="Reserved Peers" label="Reserved Peers">
                      <div className={styles.antFormItem}>
                        <Switch
                          disabled={loading}
                          checked={reservedPeers}
                          onChange={(checked: boolean) =>
                            onOperationalFlags &&
                            onOperationalFlags(
                              checked && availableOpsFlags
                                ? // Hard code for now
                                  availableOpsFlags.cluster_args |
                                    availableOpsFlags.relay_chain |
                                    availableOpsFlags.reserved_peers
                                : 0,
                            )
                          }
                        />
                        <span className="hint">Toggle this will save the changes immediately, node will restart</span>
                        {loading && <SyncOutlined spin />}
                      </div>
                    </FormGroup>
                  )}
                </>
              )}
            </Col>
            <Col unit={10}>
              <div className={styles.previewOutside}>
                <div className={styles.preview}>
                  <Preview
                    notPreview={notPreview}
                    currentId={tabId}
                    data={dataMap}
                    onTabChange={(id: TabsId) => {
                      setTabId(id);
                    }}
                    showOperationalArgs={showOperationalSection}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </DetailBox>
      <Row>
        <Col unit={12}>
          <Button large onClick={onBack} className={styles.bottomBtn}>
            {cancelButtonText}
          </Button>
        </Col>
        <Col unit={12} style={{ textAlign: 'right' }}>
          <SubmitButton
            className={styles.bottomBtn}
            large
            disabled={disabled}
            loading={!!loading}
            intent={Intent.PRIMARY}
            onClick={() => {
              if (nodes?.current?.metadata?.labels?.nodePool !== undefined) {
                showConfirm(dataMap);
              } else {
                Object.keys(dataMap.comArgRules).map((section: string) => {
                  dataMap.comArgRules[section].data = dataMap.comArgRules[section].data.filter(
                    (i: LaunchConfigurationItemFormData) => i.action !== 'remove',
                  );
                });
                onSubmit(dataMap);
              }
            }}
          >
            {submitButtonText}
          </SubmitButton>
        </Col>
      </Row>
    </div>
  );
});

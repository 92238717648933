import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteConfigComponentProps } from 'react-router-config';
import { Loader, FixedMainHeader, Row, Col, EditableField, ErrorToast, SuccessToast, Tabs, Back } from 'components';
import { ImagesManagement } from './ImagesManagement';
import { PageLayout, DetailBox } from 'components';
import { NetworkIcon } from '@/components/NetworkCard/NetworkIcon';
import { ReactComponent as BackIcon } from '@/components/Back/img/back.svg';
import { ReactComponent as UpgradeIcon } from './img/upgrade.svg';
import { Popover, Button as AntButton, Modal, Checkbox } from 'antd';
import { Recommended } from './Recommended';
import { Argument } from './Argument';
import { MoreDetail } from './moreDetail';
import queryString from 'query-string';
import { transformArg, transformVar, transformEnv } from '@/pages/NetworkCreate/helpers';
import { launchData, LaunchDataMapType, LaunchDataMapBaseNodeTypeType } from '@/components/Preview/help';
import { useStore } from 'store';
import { lang } from './helper';
import { Button, Intent, Alert, Classes, Tab } from '@blueprintjs/core';
import { Tag } from 'antd';
import { ReactComponent as ProtocolIcon } from './img/protocol.svg';
import { ReactComponent as TraceIcon } from './img/tracingIcon.svg';
import styles from './style.module.scss';
import { plans, isPlanHasPermission } from '@/configuration';
import dayjs from 'dayjs';
import { ErrorPage } from '../Error/ErrorPage';
import cx from 'classnames';
import { mxConfig } from '@/config';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

interface IParams {
  wsId: string;
  id: string;
}

type IProps = RouteConfigComponentProps<IParams>;

export const NetworkDetail: React.FC<IProps> = observer((props) => {
  const { match, history } = props;
  const { t } = useTranslation();
  const { networks, workspaces, auth, info } = useStore();
  const { wsId, id } = match.params;
  const [selectedTabId, setSelectedTabId] = useState<string>('full');
  const { isPublic } = queryString.parse(history.location.search);
  const [makeSureDelete, setMakeSureDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showMoreDetail, setShowMoreDetail] = useState(false);
  const [networkDetail, setNetworkDetail] = useState<any>({});
  const [protocolRules, setProtocolRules] = useState<any>({});
  const [config, setConfig] = useState<any>({});
  const [previewDataMap, setPreviewDataMap] = useState<LaunchDataMapType>({});
  const [redirect, setRedirect] = useState(false);
  const redirectPath = `/workspaces/${wsId}/networks`;
  const [regionModal, setRegionModal] = useState<boolean>(false);
  const [regionList, setRegionList] = useState<string[]>([]);
  const [clusterList, setClusterList] = useState<string[]>([]);
  const reloadNetworkData = async () => {
    setIsLoading(true);
    const data = await networks.fetchNetworkSpecDetail(isPublic === 'true', id);
    if (!data) {
      return setRedirect(true);
    }
    const {
      metadata: { rules: fetchedProtocolRules },
      supportedContext,
    } = await networks.getProtocols(data.protocolKey, data.key);
    setProtocolRules(fetchedProtocolRules);
    setNetworkDetail(data);
    const { metadata, config, regions } = data;
    let dataMap = launchData(metadata, config?.operations, protocolRules, supportedContext);
    setConfig(config);
    regions !== null ? setRegionList(regions) : setRegionList([]);
    setPreviewDataMap(dataMap);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      reloadNetworkData();
      const clusters = await info.fetchClusterRegions();
      setClusterList(clusters);
      setIsLoading(false);
    })();
  }, []);
  const updataDetail = async (nodeTypeRules: LaunchDataMapBaseNodeTypeType) => {
    const operations = {} as any;
    Object.keys(previewDataMap).map((nodeType: string) => {
      if (nodeType === selectedTabId) {
        operations[nodeType] = {
          arg: transformArg(nodeTypeRules.comArgRules, networkDetail.metadata.rules[nodeType].arg),
          var: transformVar(
            nodeTypeRules.comArgRules,
            nodeTypeRules.envRules,
            networkDetail.metadata.rules[nodeType].var,
          ),
          env: transformEnv(nodeTypeRules.envRules, networkDetail.metadata.rules[nodeType].env),
        };
      } else {
        operations[nodeType] = {
          arg: transformArg(previewDataMap[nodeType].comArgRules, networkDetail.metadata.rules[nodeType].arg),
          var: transformVar(
            previewDataMap[nodeType].comArgRules,
            previewDataMap[nodeType].envRules,
            networkDetail.metadata.rules[nodeType].var,
          ),
          env: transformEnv(previewDataMap[nodeType].envRules, networkDetail.metadata.rules[nodeType].env),
        };
      }
    });
    await networks.updateNetworkSpecs(wsId, id, {
      config: {
        operations: operations,
      },
    });
    if (networks.state === 'error') {
      ErrorToast(networks.errorMessage!);
      return false;
    }
    reloadNetworkData();
  };
  const logo = networkDetail?.extraInfo?.pictures?.icon || networkDetail?.metadata?.pictures?.icon;
  const traceNetwork: boolean = networkDetail?.nodeTypes?.find((e: any) => e.key === 'trace') ? true : false;
  const statusMap = {
    pending: 'processing',
    bootstrapRequired: 'warning',
    generateChainSpecFailure: 'error',
    enabled: 'success',
    disabled: 'error',
    deleted: 'error',
  };
  const currentPlanCanPublish = isPlanHasPermission(workspaces.current?.plan, 'publishNetwork');
  if (redirect) {
    return (
      <ErrorPage
        noLogo={true}
        {...props}
        gobackPath={redirectPath}
        theErrorText="We can't find the network that you are looking for."
      />
    );
  }

  const regionOnChange = (checkedValues: CheckboxValueType[]) => {
    const newValues: string[] = checkedValues.map((e) => e.toString());
    setRegionList(newValues);
  };
  return (
    <PageLayout>
      <div className={styles.container}>
        <FixedMainHeader>
          <Row>
            <Col unit={12}>
              {!showMoreDetail && <Back link={`/workspaces/${wsId}/networks`} text={lang(t, 'backToList')} />}
              {showMoreDetail && (
                <div
                  className={styles.backBtn}
                  onClick={() => {
                    setShowMoreDetail(false);
                  }}
                >
                  <BackIcon />
                  <span>{lang(t, 'backToDetail')}</span>
                </div>
              )}
            </Col>
            <Col unit={12} className={styles.alignRight}>
              {!showMoreDetail && (
                <>
                  {currentPlanCanPublish && isPublic === 'false' && (
                    <AntButton
                      type="primary"
                      className={styles.createNew}
                      onClick={() => setShowMoreDetail(true)}
                      disabled={workspaces.current!.suspendTaskDone}
                    >
                      {lang(t, 'publish')}
                    </AntButton>
                  )}
                  {plans.find((i) => i.name === workspaces.current?.plan && !i.permission.includes('publishNetwork')) &&
                    isPublic === 'false' && (
                      <Popover
                        placement="bottomRight"
                        content={
                          <div className={styles.blockPublishNotice}>
                            <h3>
                              <UpgradeIcon />
                              <span>{lang(t, 'publishSpecification.title')}</span>
                            </h3>
                            <div dangerouslySetInnerHTML={{ __html: lang(t, 'publishSpecification.detail') }} />
                          </div>
                        }
                        className={Classes.POPOVER_CONTENT_SIZING}
                      >
                        <AntButton
                          className={styles.createNew}
                          type="primary"
                          disabled
                          onClick={() => setShowMoreDetail(true)}
                        >
                          {lang(t, 'publish')}
                        </AntButton>
                      </Popover>
                    )}
                  {currentPlanCanPublish &&
                    isPublic &&
                    auth.user?.isAdmin &&
                    networkDetail.imageRepository === 'EXTERNAL' && (
                      <Button style={{ marginRight: 8 }} onClick={() => setRegionModal(true)}>
                        Regions
                      </Button>
                    )}
                  {currentPlanCanPublish && isPublic === 'true' && (
                    <Button
                      intent={Intent.NONE}
                      text={lang(t, 'moreDetail.title')}
                      onClick={() => setShowMoreDetail(true)}
                      disabled={workspaces.current!.suspendTaskDone}
                    />
                  )}
                  {isPublic !== 'true' && (
                    <Button
                      className={styles.createNew}
                      intent={Intent.DANGER}
                      text={lang(t, 'delete')}
                      onClick={() => setMakeSureDelete(true)}
                      disabled={workspaces.current!.suspendTaskDone}
                    />
                  )}
                </>
              )}
            </Col>
          </Row>
        </FixedMainHeader>
        {showMoreDetail && (
          <Loader isLoading={isLoading}>
            {!isLoading && (
              <MoreDetail
                config={config}
                data={networkDetail}
                isPublish={isPublic === 'true'}
                id={id}
                // extraInfo={networkDetail.extraInfo}
                onCancal={() => {
                  setShowMoreDetail(false);
                  reloadNetworkData();
                }}
              />
            )}
          </Loader>
        )}
        {!showMoreDetail && (
          <Loader isLoading={isLoading}>
            {networkDetail.displayName && (
              <div className={styles.mainContent}>
                <DetailBox className={styles.networkDetail}>
                  <Row className={styles.summary}>
                    <div className={styles.summaryIcon}>
                      <NetworkIcon name={networkDetail?.displayName} icon={logo} />
                    </div>
                    <div className={styles.summaryName}>
                      <EditableField
                        disabled={workspaces.current!.suspendTaskDone}
                        value={networkDetail.displayName}
                        minLength={4}
                        maxLength={50}
                        onChange={async (val) => {
                          await networks.updateNetworkSpecs(wsId, id, {
                            displayName: val,
                          });
                          if (networks.state === 'error') {
                            ErrorToast(networks.errorMessage!);
                            return false;
                          }
                          SuccessToast();
                          reloadNetworkData();
                          return true;
                        }}
                      />
                      <div className={styles.updateDate}>{`${lang(t, 'created')} ${dayjs(
                        networkDetail?.createdAt,
                      ).format('YYYY-MM-DD')}`}</div>
                    </div>

                    <div className={cx(styles.summaryProtocal, !traceNetwork ? styles.flex : '')}>
                      <div className={styles.protocol}>
                        <ProtocolIcon />
                        <span>{networkDetail.protocolKey}</span>
                      </div>
                      <div className={styles.imageRepository}>{networkDetail?.imageRepository}</div>
                      {!traceNetwork && (
                        <Tag
                          className={styles.state}
                          color={
                            // @ts-ignore
                            statusMap[networkDetail.status]
                          }
                        >
                          {t(`components.networkStatus.${networkDetail.status}`)}
                        </Tag>
                      )}
                    </div>
                    {traceNetwork && (
                      <div className={cx(styles.summaryProtocal, traceNetwork ? styles.flex : '')}>
                        <div className={styles.protocol}>
                          <TraceIcon />
                          <span>Trace Node</span>
                        </div>
                        <div className={styles.imageRepository}>{networkDetail?.traceImgRepo}</div>

                        {traceNetwork && (
                          <Tag
                            className={styles.state}
                            color={
                              // @ts-ignore
                              statusMap[networkDetail.status]
                            }
                          >
                            {t(`components.networkStatus.${networkDetail.status}`)}
                          </Tag>
                        )}
                      </div>
                    )}
                  </Row>
                </DetailBox>
                <DetailBox className={styles.networkDetail}>
                  <ImagesManagement
                    imageRepository={networkDetail!.imageRepository}
                    networkKey={networkDetail!.key}
                    protocolKey={networkDetail?.protocolKey}
                    traceNetwork={traceNetwork}
                    traceRepo={networkDetail?.traceImgRepo ?? ''}
                  />
                </DetailBox>
                <Tabs
                  selectedTabId={selectedTabId}
                  className={styles.tabs}
                  onChange={(key: string) => {
                    setSelectedTabId(key);
                  }}
                >
                  {Object.keys(previewDataMap).map((key: string) => {
                    return (
                      <Tab
                        key={key}
                        id={key}
                        title={`${key} ${lang(t, 'node')}`}
                        panel={
                          <>
                            <Argument
                              nodeType={key}
                              data={previewDataMap[key]}
                              onCallback={async (nodeTypeRules: LaunchDataMapBaseNodeTypeType) => {
                                await updataDetail(nodeTypeRules);
                                if (networks.state === 'done') {
                                  SuccessToast(lang(t, 'updateArgument'));
                                }
                              }}
                            />
                            <Recommended
                              nodeTypeKey={key}
                              nodeTypes={networkDetail.nodeTypes}
                              imageVersion={networkDetail.metadata.imageVersion}
                              editMode={true}
                              onCallback={async (data) => {
                                await networks.updateNetworkSpecs(wsId, id, {
                                  nodeRecommends: data,
                                });
                                if (networks.state === 'error') {
                                  ErrorToast(networks.errorMessage!);
                                  return false;
                                }
                                SuccessToast(lang(t, 'updateRecommended'));
                                reloadNetworkData();
                              }}
                            />
                          </>
                        }
                      />
                    );
                  })}
                </Tabs>
              </div>
            )}
          </Loader>
        )}
      </div>
      <Alert
        cancelButtonText={lang(t, 'cancel')}
        confirmButtonText={lang(t, 'delete')}
        icon="issue"
        intent={Intent.DANGER}
        isOpen={makeSureDelete}
        onCancel={() => setMakeSureDelete(false)}
        onConfirm={async () => {
          await networks.delete(wsId, id);
          if (networks.state === 'error') {
            ErrorToast(networks.errorMessage!);
          } else {
            SuccessToast('success');
            history.replace(`/workspaces/${workspaces.current!.id}/networks`);
          }
        }}
      >
        <p>{lang(t, 'deleteNetwork')}</p>
        <p
          className={styles.alertSubMessage}
          dangerouslySetInnerHTML={{
            __html: lang(t, 'deleteNetworkSub', { networkSpecName: networkDetail.displayName }),
          }}
        />
      </Alert>

      <Modal
        visible={regionModal}
        title={'Set available regions for POKT network'}
        onCancel={() => setRegionModal(false)}
        onOk={async () => {
          await networks.updateNetworkSpecsRegions(workspaces.current?.id, networkDetail.key, { regions: regionList });
          if (networks.state === 'done') {
            SuccessToast('Regions change successfully');
            setRegionModal(false);
          } else {
            ErrorToast(networks.errorMessage);
          }
        }}
      >
        <Checkbox.Group onChange={regionOnChange} options={clusterList} defaultValue={regionList} />
      </Modal>
    </PageLayout>
  );
});

import React, { useMemo } from 'react';
import { Table, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';
import { durationToHourCount, nanoToMillisecondString } from '@/utils/tools';
import styles from './style.module.scss';
import { NetworkStats } from '.';
import { ReactComponent as NoResult } from './imgs/noResult.svg';
import { ReactComponent as NoResult2 } from './imgs/noResult2.svg';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface Props {
  data: any;
  loading?: boolean;
  duration?: string;
  twentyFourH?: boolean;
}

const MethodStatsTable: React.FC<Props> = observer(({ data, loading, duration, twentyFourH }) => {
  const { t } = useTranslation();
  const lang = (t: TFunction, key: string, opt?: any) => t(`components.report.${key}`, opt);
  if (!data) {
    return <></>;
  }

  const columns = useMemo<ColumnType<NetworkStats>[]>(() => {
    const result: ColumnType<NetworkStats>[] = [
      {
        title: lang(t, 'methodStatsTableColumns.methods'),
        dataIndex: 'key',
        key: 'key',
        render: (_, { key }) => {
          return {
            children: <div className={key || styles.italicText}>{key || 'UNKNOWN'}</div>,
          };
        },
      },
      {
        title: lang(t, 'methodStatsTableColumns.count'),
        dataIndex: 'count',
        key: 'count',
        defaultSortOrder: 'descend',
        render: (_, { count }) => {
          return count.toLocaleString();
        },
        sorter: (a, b) => a.count - b.count,
      },
      {
        title: (
          <>
            {lang(t, 'methodStatsTableColumns.responseUnits')}
            <Tooltip
              title={
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${lang(t, `methodStatsTableColumns.tooltipResponse`, {
                      responseHref: 'https://documentation.onfinality.io/support/response-units',
                      rateLimitHref: 'https://documentation.onfinality.io/support/public-rate-limits',
                    })}`,
                  }}
                />
              }
            >
              <QuestionCircleOutlined className={styles.tooltip} />
            </Tooltip>
          </>
        ),
        dataIndex: 'weighted',
        key: 'weighted',
        render: (_, { weighted }) => {
          return weighted.toLocaleString();
        },
        sorter: (a, b) => a.count - b.count,
      },
      {
        title: lang(t, 'methodStatsTableColumns.size'),
        dataIndex: 'size',
        key: 'size',
        render: (_, { size }) => {
          return prettyBytes(size || 0);
        },
        sorter: (a, b) => a.size - b.size,
      },
    ];

    if (durationToHourCount(duration) <= 24 || twentyFourH) {
      result.push({
        title: lang(t, 'methodStatsTableColumns.response50th'),
        dataIndex: 'response50th',
        key: 'response50th',
        render: (_, { response50th }) => {
          return response50th
            ? `${nanoToMillisecondString(response50th).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ms`
            : '--';
        },
        sorter: (a, b) => (a.response50th || 0) - (b.response50th || 0),
      });
      result.push({
        title: lang(t, 'methodStatsTableColumns.response95th'),
        dataIndex: 'response95th',
        key: 'response95th',
        render: (_, { response95th }) => {
          return response95th
            ? `${nanoToMillisecondString(response95th).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ms`
            : '--';
        },
        sorter: (a, b) => (a.response95th || 0) - (b.response95th || 0),
      });
    }

    result.push({
      title: lang(t, 'methodStatsTableColumns.errorRate'),
      dataIndex: 'errorRate',
      key: 'errorRate',
      render: (_, { errorRate }) => {
        return `${errorRate.toFixed(2)}%`;
      },
      sorter: (a, b) => a.errorRate - b.errorRate,
    });

    return result;
  }, [duration]);

  const dataSource = useMemo<NetworkStats[]>(
    () =>
      Object.keys(data)
        .map((method) => {
          const timestamps = Object.keys(data[method]);
          // This is the weighted result so it should only contains single timestamp
          const stats = data[method][timestamps[0]];
          return {
            ...stats,
            key: method,
            errorRate: (stats.count !== 0 ? stats.countFailed / stats.count : 0) * 100,
          } as NetworkStats;
        })
        .sort((a, b) => (a.count === b.count ? 0 : a.count > b.count ? -1 : 1)),
    [data],
  );

  return (
    <div className={styles.positionR}>
      {dataSource.length === 0 ? (
        twentyFourH ? (
          <div className={styles.noDataDiv2}>
            <NoResult className={styles.noResult2} />
          </div>
        ) : (
          <div className={styles.noDataDiv2}>
            <NoResult2 className={styles.noResult} />
          </div>
        )
      ) : (
        ''
      )}
      <Table
        pagination={{ pageSize: 10 }}
        className={styles.methodStatsTable}
        dataSource={dataSource}
        loading={loading}
        columns={columns}
      />
    </div>
  );
});

export default MethodStatsTable;

import React, { CSSProperties } from 'react';
import cx from 'classnames';
import './_grids.scss';
import 'purecss-sass/vendor/assets/stylesheets/purecss/_grids-responsive.scss';
import './style.scss';

interface IRowProps {
  className?: string;
  style?: CSSProperties;
  gutter?: boolean;
}

export const Row: React.FC<IRowProps> = ({ className, gutter, style, children }) => (
  <div className={cx('pure-g', className, { gutter })} style={style}>
    {children}
  </div>
);

const maxUnit = 24;
const prefix = 'pure-u';

type SizeUnit = {
  default?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

interface IColProps {
  unit?: SizeUnit | number;
  className?: string;
  alignRight?: boolean;
  style?: CSSProperties;
}

export const Col: React.FC<IColProps> = ({ className, style, unit, children, alignRight }) => {
  let unitClass: string[];

  if (typeof unit === 'number') {
    if (unit > maxUnit) {
      unit = maxUnit;
    }
    unitClass = [[prefix, unit, maxUnit].join('-')];
  } else {
    const keys = Object.keys(unit as SizeUnit) as Array<keyof SizeUnit>;
    unitClass = keys.map((size) => {
      let val = (unit as SizeUnit)[size] as number;
      if (val > maxUnit) {
        val = maxUnit;
      }
      if (size === 'default') {
        return [prefix, val, maxUnit].join('-');
      }
      return [prefix, size, val, maxUnit].join('-');
    });
  }

  return (
    <div className={cx(className, ...unitClass, alignRight ? 'alignRight' : '')} style={style}>
      {children}
    </div>
  );
};

Col.defaultProps = {
  unit: 24,
};

import { TFunction } from 'i18next';
import React from 'react';
import { INode } from '../../../store/nodes';
import { GeneralApiSection } from './General';
import { ParachainApiSection } from './Parachain';

export const lang = (t: TFunction, key: string, options?: any) => t(`pages.nodeDetail.${key}`, options);

export interface PanelProps {
  node: INode;
}

export const apiSection: { [key: string]: React.FC<PanelProps> } = {
  polkadot: GeneralApiSection,
  'polkadot-parachain': ParachainApiSection,
  substrate: GeneralApiSection,
  ethereum: GeneralApiSection,
};

import styles from './style.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { Back, DetailBox, FixedMainHeader, Loader, MainBox, PageLayout, FormError } from '@/components';
import { useStore } from '@/store';
import { RouteConfigComponentProps } from 'react-router-config';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Button, Col, Input, Row, Typography, Form, Modal } from 'antd';
import { SyncOutlined as RegenIcon, EditOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { useAppSettings } from './hook';
import { AccessRestrictions } from './accessRestrictions';
import { ErrorToast, SuccessToast } from '@/components/Toast';
import dayjs from 'dayjs';

const { Paragraph, Title } = Typography;

type Props = RouteConfigComponentProps<{ wsId: string; appId: string }>;

export const ApiServiceAppSettings: React.FC<Props> = observer(({ match, history }) => {
  const [modal, contextHolder] = Modal.useModal();
  const { wsId, appId } = match.params;
  const { tryDeleteApp, tryRegenApiKey, confirmDialogProps, apiKey } = useAppSettings(wsId, appId);
  const { apiService, workspaces } = useStore();
  const { t } = useTranslation();
  const lang = (key: string, opt?: any) => t(`pages.apiServices.settingsPage.${key}`, opt);
  const [newAppname, setNewAppname] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [inputOnfocus, setInputOnfocus] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!apiService.app) {
      setLoading(true);
      apiService.getAppsById(wsId, appId).then(() => setLoading(false));
    } else setNewAppname(apiService.app!.displayName);
  }, [apiService.app]);

  const changeAppName = async () => {
    if (newAppname !== '') {
      await apiService.updateAppName(wsId, appId, newAppname.trim());
      if (apiService.state === 'error') {
        ErrorToast(apiService.errorMessage || 'error');
      } else {
        SuccessToast(t('Updated App Successfully'));
        setLoading(true);
        setInputOnfocus(false);
        apiService.getAppsById(wsId, appId).then(() => setLoading(false));
      }
    }
  };

  if (workspaces.current?.suspendTaskDone) {
    history.push(`/workspaces/${wsId}/apiservices`);
  }

  return (
    <PageLayout>
      <FixedMainHeader>
        <Back link={`/workspaces/${wsId}/apiservices/${appId}`} text={apiService.app?.displayName} />
      </FixedMainHeader>
      <MainBox>
        <div className={cx({ [styles.main]: true, [styles.makeDistance]: true })}>
          {loading ? (
            <Loader isLoading={true} />
          ) : (
            <div className={styles.apiServiceAppSettings}>
              <Title level={3} className={styles.title}>
                {lang('title')}
              </Title>
              <DetailBox className={styles.detailBox}>
                <Row className="header-row">
                  <Col span={12}>
                    <h3>{t('pages.apiServices.ApiName')}</h3>
                  </Col>
                </Row>
                <Input
                  size={'large'}
                  className={inputOnfocus ? styles.appNameInput : ''}
                  value={inputOnfocus ? newAppname : apiService.app?.displayName}
                  suffix={
                    apiService.editApp ?? <EditOutlined className={cx(styles.iconBlue)} style={{ color: '#031FEF' }} />
                  }
                  onChange={(e) => {
                    setErrorMessage('');
                    setNewAppname(e.target.value);
                    if (e.target.value.trim() === '') {
                      setErrorMessage('App Name required');
                    }
                  }}
                  // onPressEnter={changeAppName}
                  onFocus={() => {
                    setInputOnfocus(true);
                  }}
                  disabled={!apiService.editApp}
                />
                {inputOnfocus && apiService.editApp && (
                  <div className={styles.changeNameBtn}>
                    <Button
                      onClick={() => {
                        setInputOnfocus(false);
                        setNewAppname(apiService.app!.displayName);
                        setErrorMessage('');
                      }}
                      size="large"
                    >
                      Cancel
                    </Button>
                    <Button onClick={changeAppName} type="primary" size="large">
                      Save
                    </Button>
                  </div>
                )}

                <FormError>{errorMessage}</FormError>
              </DetailBox>
              <DetailBox className={styles.detailBox}>
                <Row className="header-row">
                  <Col span={12}>
                    <h3>{t('pages.apiServices.ApiKey')}</h3>
                  </Col>
                  <Col span={12} className={styles.alignRight}>
                    {apiService.editApp && (
                      <Button size="large" type="link" icon={<RegenIcon />} onClick={tryRegenApiKey}>
                        {lang('btnRegenerate')}
                      </Button>
                    )}
                  </Col>
                </Row>
                <Input
                  size={'large'}
                  disabled
                  className={styles.apiKeyInput}
                  suffix={
                    <Paragraph copyable className={styles.copyButton}>
                      {apiKey}
                    </Paragraph>
                  }
                  value={apiKey}
                />
              </DetailBox>
              {/* API Access Restrictions */}
              <AccessRestrictions wsId={wsId} appId={appId} />
              {apiService.editApp && (
                <DetailBox className={styles.detailBox}>
                  <div className="delete-app-wrapper">
                    <div className="delete-app-instruction">
                      <Row className="header-row">
                        <Col>
                          <h3>{lang('deleteApp')}</h3>
                        </Col>
                      </Row>
                      <p>{lang('deleteAppNote')}</p>
                    </div>
                    <div>
                      <Button type="primary" danger size="large" onClick={tryDeleteApp}>
                        {lang('btnDelete')}
                      </Button>
                    </div>
                  </div>
                </DetailBox>
              )}
              <div className={styles.alignRight}>
                {apiService.creatorName ? (
                  <span className={styles.appDateRight}>
                    Created by {apiService.creatorName} on {dayjs(apiService.app?.createdAt).format('YYYY-MM-DD')}
                  </span>
                ) : (
                  <span className={styles.appDateRight}>
                    Created on {dayjs(apiService.app?.createdAt).format('YYYY-MM-DD')}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </MainBox>
      <ConfirmDialog {...confirmDialogProps} />
      {contextHolder}
    </PageLayout>
  );
});

import { Modal, Form, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { ErrorToast, SuccessToast } from 'components';
import { useStore } from 'store';
import { PopupSpinner } from '../PopupSpinner';

const DEFAULT_SCHEDULE = '0 * * * *';

interface Props {
  isOpen: boolean;
  id: string;
  onClose?: () => void;
}

interface CreateBackupForm {
  schedule: string;
}

export const CreateLightningBackupDialog: React.FC<Props> = ({ isOpen, onClose, id }) => {
  const { nodes } = useStore();

  const onFinish = async (values: CreateBackupForm) => {
    setProgressing(true);
    await nodes.createLightningBackup(id, {
      ...values,
    });

    // Errors
    if (nodes.state === 'error') {
      setProgressing(false);
      return ErrorToast(nodes.errorMessage || 'Unknown error', 30000);
    }

    setProgressing(false);
    SuccessToast(
      `New backup from this node has been queued successfully. Check the Usage Center's Backup for further details.`,
      10000,
    );
    if (onClose) {
      onClose();
    }
  };

  const [progressing, setProgressing] = useState(false);
  const [form] = Form.useForm<CreateBackupForm>();

  useEffect(() => {
    if (isOpen && !form.getFieldValue('schedule')) {
      form.setFieldsValue({
        schedule: DEFAULT_SCHEDULE,
      });
    }
  }, [isOpen]);

  return (
    <Modal
      title="Create LR Backup"
      visible={isOpen}
      centered
      onCancel={onClose}
      onOk={form.submit}
      confirmLoading={progressing}
      maskClosable={!progressing}
      cancelButtonProps={{ hidden: progressing }}
      closable={!progressing}
    >
      <Form form={form} onFinish={onFinish}>
        {progressing && <PopupSpinner spinning={progressing} />}
        {!progressing && (
          <Form.Item label="Schedule" name="schedule" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

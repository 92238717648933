import { flow, types } from 'mobx-state-tree';
import { api, filterErrorMessage } from '../utils/api';
import { createNodeMaxStorage, apiServiceLimit as apiServiceLimitConfig, nodeUnitSizeConfig } from '@/configuration';
import { pickOutError } from '@/utils/tools';

export const numberFormat = (v: number | undefined | null, times = 1, dp = 1) => {
  let result = '0';
  if (v && +v !== 0 && times) {
    result = (v / times).toFixed(dp);
    const resultSplit = result.split('.');
    resultSplit[0] = (+resultSplit[0]).toLocaleString();
    result = resultSplit.join('.');
    if (!result.split('.')[1]) {
      result = `${result}${dp === 0 ? '' : '.0'}`;
    }
  }
  return result;
};

export const usagePageSize = 20;

export enum SummaryTypes {
  CPU = 'cpu',
  Storage = 'storage',
  Backup = 'backup',
  DataTraffic = 'dataTraffic',
  APIRequests = 'apiRequests',
}

export const UsageDetailKindMap = {
  [SummaryTypes.CPU]: 'node',
  [SummaryTypes.Storage]: 'storage',
  [SummaryTypes.Backup]: 'partner-backup-storage',
  [SummaryTypes.DataTraffic]: 'network-throughput-transmit',
  [SummaryTypes.APIRequests]: 'api-requests',
};

const Summary = types.model({
  cpu: types.model({
    total: types.maybe(types.string),
    records: types.maybe(types.number),
  }),
  storage: types.model({
    total: types.maybe(types.string),
    records: types.maybe(types.number),
  }),
  backup: types.model({
    total: types.maybe(types.string),
    records: types.maybe(types.number),
  }),
  dataTraffic: types.model({
    total: types.maybe(types.string),
    records: types.maybe(types.number),
  }),
  apiRequests: types.model({
    total: types.maybe(types.string),
    records: types.maybe(types.number),
  }),
});

const Limit = types.model({
  createNodeMaxStorage: types.number,
  nodeUnitSize: types.number,
  apiServiceLimit: types.number,
});

export const UsageCenter = types
  .model({
    state: types.optional(types.enumeration(['pending', 'progressing', 'done', 'error']), 'pending'),
    limit: types.optional(Limit, {
      createNodeMaxStorage: createNodeMaxStorage,
      nodeUnitSize: nodeUnitSizeConfig,
      apiServiceLimit: apiServiceLimitConfig,
    }),
    summary: types.optional(Summary, {
      cpu: {
        total: '0',
        records: 0,
      },
      storage: {
        total: '0',
        records: 0,
      },
      backup: {
        total: '0',
        records: 0,
      },
      dataTraffic: {
        total: '0',
        records: 0,
      },
      apiRequests: {
        total: '0',
        records: 0,
      },
    }),
    errorMessage: types.maybe(types.string),
  })
  .views((self) => ({
    // get lastSelected() {
    //   const lastWsId = localStorage.getItem(WS_LOCAL_STORAGE_KEY);
    //   if (lastWsId) {
    //     const ws = this.get(lastWsId);
    //     if (ws) {
    //       return ws;
    //     }
    //     return self.list[0];
    //   }
    //   return self.list[0];
    // },
    // get(id: string) {
    //   return self.list.find((ws) => ws.id === id);
    // },
    // get currentWorkspaceMember() {
    //   const userId = getParent<Instance<typeof Store>>(self).auth.user!.id as string;
    //   return self.members.find((m) => m.id === userId);
    // },
  }))
  .actions((self) => ({
    fetchNodeUsageQuery: flow(function* (
      wsId: string,
      st: string,
      et: string,
      page: number,
      order?: {
        key: string | undefined;
        way: string | undefined;
      },
    ) {
      self.state = 'progressing';
      const { key, way } = order || {};
      const params = {
        page: page,
        pageSize: usagePageSize,
        st: st,
        et: et,
      } as any;
      if (key) params.order = { [key]: way };
      try {
        const data = yield api.get(`/workspaces/${wsId}/usages/nodes`, {
          params,
        });
        // self.nodeUsageQuery = data;
        self.state = 'done';
        return data;
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    checkHasData: flow(function* (wsId: string) {
      self.state = 'progressing';
      try {
        const data = yield api.get(`/workspaces/${wsId}/summary`);
        const storageLimit = (data.quotas || []).find((i: any) => i?.key === 'workspace.storage.capacity.per-node');
        const apiServiceLimit = (data.quotas || []).find((i: any) => i?.key === 'workspace.request');
        const nodeUnitSize = (data.quotas || []).find((i: any) => i?.key === 'workspace.node.unit-size');
        self.limit.nodeUnitSize = nodeUnitSize?.value || nodeUnitSizeConfig;
        self.limit.createNodeMaxStorage = storageLimit?.value || createNodeMaxStorage;
        self.limit.apiServiceLimit = !isNaN(apiServiceLimit?.value) ? apiServiceLimit?.value : apiServiceLimitConfig;
        self.state = 'done';
        return data;
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    fetchUsageDetail: flow(function* (
      wsId: string,
      kind: string,
      start: string,
      end: string,
      page: number,
      order?: {
        key: string | undefined;
        way: string | undefined;
      },
    ) {
      self.state = 'progressing';
      const { key, way } = order || {};
      const params = {
        kind,
        page: page,
        pageSize: usagePageSize,
        start,
        end,
      } as any;
      if (key) params.order = { [key]: way };
      try {
        const data: any = yield api.get(`/workspaces/${wsId}/usages/detail`, {
          params,
        });
        self.state = 'done';
        return data;
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    fetchUsageDetailDaily: flow(function* (wsId: string, kind: string, start: string, end: string) {
      self.state = 'progressing';
      const params = {
        kind,
        start,
        end,
      } as any;
      try {
        const data = yield api.get(`/workspaces/${wsId}/usages/detail-daily`, {
          params,
        });
        self.state = 'done';
        return data;
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    fetchSummary: flow(function* (wsId: string, start: string, end: string) {
      self.state = 'progressing';
      try {
        const { data } = yield api.get(`/workspaces/${wsId}/usages/summary`, {
          params: {
            start,
            end,
          },
        });
        const cpuData = data.find((i: any) => i.kind === 'node');
        const storageData = data.find((i: any) => i.kind === 'storage');
        const backupData = data.find((i: any) => i.kind === 'partner-backup-storage');
        const dataTrafficData = data.find((i: any) => i.kind === 'network-throughput-transmit');
        const apiRequestsData = data.find((i: any) => i.kind === 'api-requests');

        const result = {
          cpu: {
            total: numberFormat(cpuData?.total_usage, 1),
            records: +(cpuData?.records || 0),
          },
          storage: {
            total: numberFormat(storageData?.total_usage, 1),
            records: +(storageData?.records || 0),
          },
          backup: {
            total: numberFormat(backupData?.total_usage, 1),
            records: +(backupData?.records || 0),
          },
          dataTraffic: {
            total: numberFormat(dataTrafficData?.total_usage, 1),
            records: +(dataTrafficData?.records || 0),
          },
          apiRequests: {
            total: numberFormat(apiRequestsData?.total_weighted, 1, 0),
            records: +(apiRequestsData?.records || 0),
          },
        };
        self.summary = result;
        self.state = 'done';
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    exportUsage: flow(function* (wsId: string, kinds: Array<string | undefined>, st: string, et: string) {
      self.state = 'progressing';
      try {
        const data = yield api.get(`/workspaces/${wsId}/usages/export`, {
          params: {
            kinds: kinds,
            start: st,
            end: et,
          },
          responseType: 'blob',
        });
        const blob = new Blob([data]);
        let dom = document.createElement('a');
        let url = window.URL.createObjectURL(blob);
        dom.href = url;
        dom.download = decodeURI(`${st}-${et}.csv`);
        dom.style.display = 'none';
        document.body.appendChild(dom);
        dom.click();
        dom.parentNode!.removeChild(dom);
        window.URL.revokeObjectURL(url);
        self.state = 'done';
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    exportNodeUsageQuery: flow(function* (wsId: string, st: string, et: string) {
      try {
        const data = yield api.get(`/workspaces/${wsId}/usages/nodes/export`, {
          params: {
            st: st,
            et: et,
          },
          responseType: 'blob',
        });
        const blob = new Blob([data]);
        let dom = document.createElement('a');
        let url = window.URL.createObjectURL(blob);
        dom.href = url;
        dom.download = decodeURI(`${st}-${et}.csv`);
        dom.style.display = 'none';
        document.body.appendChild(dom);
        dom.click();
        dom.parentNode!.removeChild(dom);
        window.URL.revokeObjectURL(url);
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),
  }));

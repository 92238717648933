import React, { useState, useEffect } from 'react';
import { HTMLTable, InputGroup } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Paginate, Row, Col, Spin, NoData } from 'components';
import { usagePageSize } from '@/store/workspaces';
import styles from './style.module.scss';
import { ReactComponent as ZoomIcon } from './imgs/zoom.svg';
import { ReactComponent as UpIcon } from './imgs/up.svg';
import { ReactComponent as DownIcon } from './imgs/down.svg';
import { ReactComponent as SortIcon } from './imgs/sorter.svg';

const waitTime = 500;

enum orderType {
  ASC = 'ASC',
  DESC = 'DESC',
}

type Column = {
  name: string;
  field: string;
  sort?: boolean;
  filter?: boolean;
  renderer?: (v: string, data: any) => void;
};

type IProps = {
  isLoading?: boolean;
  noDataMsg?: string;
  page: number;
  total: number;
  totalPage: number;
  data: Array<any>;
  columns: Array<Column>;
  defaultOrder?: { key: string; way: string };
  onCallback: (v: any) => void;
};

export const SearchTable: React.FC<IProps> = observer(
  ({ page, total, totalPage, columns, data, onCallback, isLoading = false, noDataMsg, defaultOrder }) => {
    const [searchOp, setSearchOp] = useState({
      page: page,
      order: defaultOrder ?? { key: '', way: '' },
      where: {},
    });
    const pageRange = () => {
      const start = usagePageSize * (page - 1) + 1;
      const end = page === totalPage ? total : usagePageSize * page;
      return `${start}-${end}`;
    };

    useEffect(() => {
      onCallback(searchOp);
    }, [searchOp]);

    const fn = _.debounce((key: string, value: string) => {
      const where = searchOp.where;
      setSearchOp({
        ...searchOp,
        page: 1,
        where: {
          ...where,
          [key]: value,
        },
      });
    }, waitTime);

    const renderSort = (column: Column) => {
      const onClick = () => {
        if (searchOp.order.key === column.field) {
          setSearchOp({
            ...searchOp,
            page: 1,
            order: {
              key: column.field,
              way: searchOp.order.way === orderType.DESC ? orderType.ASC : orderType.DESC,
            },
          });
        } else {
          setSearchOp({
            ...searchOp,
            page: 1,
            order: {
              key: column.field,
              way: orderType.DESC,
            },
          });
        }
      };

      return (
        <span className={styles.thEvent} onClick={onClick}>
          <span>{column.name}</span>
          {searchOp.order.key !== column.field && <SortIcon />}
          {searchOp.order.key === column.field && searchOp.order.way === orderType.DESC && <DownIcon />}
          {searchOp.order.key === column.field && searchOp.order.way === orderType.ASC && <UpIcon />}
        </span>
      );
    };

    const renderFilter = (key: string) => {
      return (
        <InputGroup
          className={styles.searchBar}
          leftIcon="search"
          // value={filterValue || ''}
          onChange={(e: any) => {
            e.persist();
            const value = e.target.value.trim();
            fn(key, value);
          }}
        />
      );
    };

    return (
      <div className={styles.container}>
        <div>
          <Spin isLoading={isLoading}>
            <HTMLTable>
              <thead>
                <tr>
                  {columns.map((i) => (
                    <th key={i.field}>
                      {!i.sort ? <span>{i.name}</span> : renderSort(i)}
                      {!!i.filter && renderFilter(i.field)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {!isLoading &&
                  data.map((i, index) => {
                    return (
                      <tr key={index}>
                        {columns.map(({ field, renderer }) => (
                          <td key={field}>{renderer ? renderer(i[field], i) : i[field]}</td>
                        ))}
                      </tr>
                    );
                  })}
                {!isLoading && data.length === 0 && (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className={styles.noData}>
                        <ZoomIcon />
                        {noDataMsg || ''}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </HTMLTable>
            {!isLoading && data.length > 0 && (
              <Row>
                <Col unit={4}>
                  <span className={styles.pageMessage}>{total > 0 && `${pageRange()} of ${totalPage} pages`}</span>
                </Col>
                <Col unit={20} alignRight>
                  <Paginate
                    pageCount={totalPage || 0}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={6}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    forcePage={page - 1}
                    onPageChange={({ selected }) => {
                      setSearchOp({
                        ...searchOp,
                        page: selected + 1,
                      });
                    }}
                    initialPage={0}
                    disableInitialCallback={true}
                    containerClassName={'container'}
                    activeClassName={'active'}
                    activeLinkClassName={'active'}
                  />
                </Col>
              </Row>
            )}
          </Spin>
        </div>
      </div>
    );
  },
);

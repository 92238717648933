import { Callout, Icon, Intent } from '@blueprintjs/core';
import React, { useState } from 'react';
import styles from './style.module.scss';
import cx from 'classnames';

interface IProps {
  title?: string;
  intent: Intent;
  needClose: boolean;
  className?: string;
}

export const Notice: React.FC<IProps> = ({ title, intent = Intent.PRIMARY, needClose, children, className }) => {
  const [show, setShow] = useState(true);
  const onCloseClick = () => {
    setShow(false);
  };
  if (!show) return null;

  return (
    <Callout intent={intent} title={title} className={cx(className)}>
      {needClose && <Icon className={styles.closs} icon={'cross'} intent={intent} onClick={onCloseClick} />}
      {children}
    </Callout>
  );
};

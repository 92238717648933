import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Instance } from 'mobx-state-tree';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { CreateApp } from './createApp';
import { PageLayout, FixedMainHeader, MainBox, DetailBox } from 'components';
import { Row, Col, Tag, Button, Space, Select, Spin, Alert } from 'antd';
import ReactEcharts from 'echarts-for-react';
import { useStore } from '@/store';
import { App as AppType } from '@/store/apiService';
import styles from './style.module.scss';
import dayjs from 'dayjs';
import { Monthly } from './requestRange/Monthly';
import { Dayly } from './requestRange/Dayly';
import { ReactComponent as Plan } from './imgs/plan.svg';
import { ReactComponent as Upmark } from './imgs/upMark.svg';
import { ReactComponent as UsageCenter } from './imgs/usageCenter.svg';
import { plans } from '@/configuration';
import { charOpt, everyAppUsageChartData, ChartDataType, lang, defaultChartOptions, percentage } from './helpers';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import cx from 'classnames';
import { AppList } from './AppList/AppList';
dayjs.extend(dayjsPluginUTC as any, { parseToLocal: true });

const { Option } = Select;

interface IProps {
  wsId: string;
}

export const List: React.FC<IProps> = observer((props) => {
  const history = useHistory();
  const { wsId } = props;
  const { apiService, workspaces, usageCenter, plan } = useStore();
  const [isLoadingTotalRequest, setIsloadingTotalRequest] = useState(true);
  const [isLoadinglimit, setIsLoadinglimit] = useState(true);
  const [duration, setDuration] = useState('7d');
  const [chartData, setChartData] = useState<ChartDataType>({
    dateMap: {},
    x: [],
    y: [],
    appKV: {},
  });
  const { t } = useTranslation();
  const planIsMonthPayment = plan.currentPlan?.price?.month === true;
  const apiServiceLimit = usageCenter.limit?.apiServiceLimit ?? 0;
  const progressValue = planIsMonthPayment
    ? percentage(apiService.totalUsage, usageCenter.limit?.apiServiceLimit)
    : percentage(apiService.limit?.weightedUsage, usageCenter.limit?.apiServiceLimit);

  useEffect(() => {
    (async () => {
      setIsloadingTotalRequest(true);
      const data: Instance<typeof AppType>[] = await apiService.getUsageByWsId(wsId, duration);
      setIsloadingTotalRequest(false);
      if (data) {
        const { x, y, dateMap, appKV } = everyAppUsageChartData(data, duration);
        if (x && y && dateMap) setChartData({ x, y, dateMap, appKV });
      }
    })();
  }, [duration]);

  useEffect(() => {
    (async () => {
      setIsLoadinglimit(true);
      await Promise.all([apiService.getLimit(wsId), plan.fetch(), workspaces.fetchDetail(wsId)]);
      setIsLoadinglimit(false);
    })();
    return () => {
      apiService.clearUsage();
    };
  }, [workspaces.current?.id]);

  const currentPlan = plans.find((i) => i.name === workspaces.current?.plan);

  return (
    <PageLayout>
      <FixedMainHeader>
        <Row className={styles.row}>
          <Col span={6}>
            <h3>{lang(t, 'title')}</h3>
          </Col>
          <Col span={18} className={styles.textRight}>
            <Button
              className={cx(styles.limitButton, styles.marginR16)}
              type={'default'}
              onClick={() => {
                history.push(`/workspaces/${wsId}/apiservices/all-networks`);
              }}
            >
              {lang(t, 'appList.exploreSupportedNetworks')}
            </Button>
            <CreateApp wsId={wsId} text={lang(t, 'welcome.createApp')} size={'middle'} />
          </Col>
        </Row>
      </FixedMainHeader>
      {workspaces.cutoffs.length > 0 && workspaces.current?.plan === 'developer' && (
        <Alert
          className={styles.warningBanner}
          showIcon
          type="error"
          closable
          message={
            <span>
              You have exceeded your free plan limit and your API key has been blocked. Please{' '}
              <Link to={`/workspaces/${wsId}/settings/plan`}>{lang(t, 'upgradeASAP')}</Link> to restore service.
            </span>
          }
        />
      )}
      <MainBox className={styles.mt20}>
        <Row gutter={30} className={styles.mainData}>
          <Col span={6}>
            <DetailBox className={styles.detailBox}>
              <h4>
                <Space>
                  <Plan
                    className={cx({ [styles[`plan_${workspaces.current?.plan}`]]: true, [styles.planIcon]: true })}
                  />
                  <span>{`${lang(t, `plan.${workspaces.current?.plan}`)}${lang(t, 'plan.title')}`}</span>
                </Space>
              </h4>
              <div className={styles.planDetail}>
                <Spin className={styles.limitSpin} spinning={isLoadinglimit} />
                {!isLoadinglimit && (
                  <>
                    {React.createElement(planIsMonthPayment ? Monthly : Dayly)}

                    {currentPlan?.apiServiceLink === 'usageCenter' ? (
                      <Button
                        icon={<UsageCenter fill="#3f57f5" />}
                        className={cx(styles.limitButton, styles.unlimitButton)}
                        type={'default'}
                        onClick={() => {
                          history.push(`/workspaces/${workspaces.current!.id}/usageCenter`);
                        }}
                      >
                        {lang(t, 'appList.upgradeUnLimitButton')}
                      </Button>
                    ) : (
                      <Button
                        icon={<Upmark fill="#3f57f5" />}
                        className={styles.limitButton}
                        type={'default'}
                        onClick={() => {
                          history.push(`/workspaces/${workspaces.current!.id}/settings/plan`);
                        }}
                      >
                        {lang(t, 'appList.upgradeLimitButton')}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </DetailBox>
          </Col>
          <Col span={18}>
            <DetailBox className={styles.detailBox}>
              <Row>
                <Col span={12}>
                  <h4>{lang(t, 'appList.totalRequests')}</h4>
                </Col>
                <Col span={12} className={styles.textRight}>
                  <Select
                    className={styles.width173}
                    disabled={isLoadingTotalRequest}
                    defaultValue={duration}
                    onChange={(key) => {
                      setDuration(key);
                    }}
                  >
                    <Option value="24h">{lang(t, 'appList.24h')}</Option>
                    <Option value="7d">{lang(t, 'appList.7d')}</Option>
                    <Option value="30d">{lang(t, 'appList.30d')}</Option>
                  </Select>
                  <div className={styles.utcTime}>{lang(t, 'appList.timeInUTC')}</div>
                </Col>
              </Row>
              <div className={styles.chartBox}>
                <Spin spinning={isLoadingTotalRequest}>
                  {!isLoadingTotalRequest && (
                    <ReactEcharts
                      option={charOpt(t, chartData, duration, planIsMonthPayment ? null : apiServiceLimit)}
                      {...defaultChartOptions}
                      className={styles.echarts}
                      style={{ height: 270, width: '100%' }}
                    />
                  )}
                </Spin>
              </div>
            </DetailBox>
          </Col>
        </Row>
        <Row className={styles.tableHeader}>
          <Col span={6}>{lang(t, 'appList.thApp')}</Col>
          <Col span={5}>{lang(t, 'appList.thEnv')}</Col>
          {!planIsMonthPayment && <Col span={4}>{lang(t, 'appList.thRqToday')}</Col>}
          <Col span={4}>{lang(t, 'appList.thRq24H')}</Col>
          {planIsMonthPayment && <Col span={4}></Col>}
          <Col span={5} style={{ width: 200 }}>
            {lang(t, 'appList.thStatus')}
          </Col>
        </Row>
        {apiService.apps.map((i) => {
          {
            if (workspaces.current?.suspendTaskDone) {
              return (
                <div key={i.appId} className={cx(styles.appItem, styles.disableCursor)}>
                  <DetailBox className={styles.appList}>
                    <AppList disable={true} i={i} planIsMonthPayment={planIsMonthPayment} />
                  </DetailBox>
                </div>
              );
            }
          }
          return (
            <Link key={i.appId} to={`/workspaces/${wsId}/apiservices/${i.appId}`} className={styles.appItem}>
              <DetailBox hover className={styles.appList}>
                <AppList i={i} planIsMonthPayment={planIsMonthPayment} />
              </DetailBox>
            </Link>
          );
        })}
      </MainBox>
    </PageLayout>
  );
});

import { when } from 'mobx';
import React, { useState } from 'react';
import styles from './style.module.scss';

import defaultIcon01 from './imgs/01.svg';
import defaultIcon02 from './imgs/02.svg';
import defaultIcon03 from './imgs/03.svg';
import defaultIcon04 from './imgs/04.svg';
import defaultIcon05 from './imgs/05.svg';
import defaultIcon06 from './imgs/06.svg';
import defaultIcon07 from './imgs/07.svg';
import defaultIcon08 from './imgs/08.svg';

interface IProps {
  name: string;
  icon?: string;
}

const iconMap = [
  defaultIcon01,
  defaultIcon02,
  defaultIcon03,
  defaultIcon04,
  defaultIcon05,
  defaultIcon06,
  defaultIcon07,
  defaultIcon08,
];

const toASCII = (name: string) => {
  let num = 0;
  let i = 0;
  while (i < name.length) {
    num += name[i].charCodeAt(0);
    i++;
  }
  const iconIndex = num % 8;
  return iconMap[iconIndex];
};

export const NetworkIcon: React.FC<IProps> = ({ name, icon }) => {
  const defaultIcon = toASCII(name);
  const [logo, setLogo] = useState(icon || defaultIcon);

  return (
    <img
      className={styles.iconStyle}
      onError={() => {
        setLogo(defaultIcon);
      }}
      src={logo}
    />
  );
};

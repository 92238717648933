import { Button, Classes, Intent, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteConfigComponentProps } from 'react-router-config';
import { AntdCol, Loader, PageLayout, AntdRow, FixedMainHeader, NodeRegion } from 'components';
import { ReactComponent as SyncIcon } from './imgs/syncIcon.svg';
import { useStore } from '../../store';
import styles from './style.module.scss';
import cx from 'classnames';

type IProps = RouteConfigComponentProps<{ wsId: string }>;
interface hiddingMapType {
  [key: string]: boolean;
}

export const NodeList: React.FC<IProps> = observer((props) => {
  const { history, match } = props;
  const { workspaces, nodes, networks } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [hiddingMap, setHiddingMap] = useState<hiddingMapType>({});
  const { t } = useTranslation();

  //@ts-ignore
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      // await networks.fetchAllNetowrks();
      if (workspaces.current) {
        await nodes.fetchList(match.params.wsId);
        if (nodes.list.length > 0) await networks.fetchAllNetowrks();
        const hideMap = {} as hiddingMapType;
        Object.keys(nodes.mapData).map((i) => {
          hideMap[i] = false;
        });
        setHiddingMap(hideMap);
      }
      setIsLoading(false);
    })();
    return async () => {
      await nodes.clearCache();
    };
  }, [workspaces.current]);

  if (nodes.state === 'done' && !isLoading && !Object.keys(nodes.mapData).length)
    history.push(`/workspaces/${workspaces.current?.id}/nodes/join-network?step=1`);

  return (
    <Loader isLoading={isLoading}>
      <FixedMainHeader>
        <AntdRow>
          <AntdCol span={12}>
            <h3 className={Classes.HEADING}>{t('pages.nodeList.title')}</h3>
          </AntdCol>
          <AntdCol span={12} alignRight>
            <Button
              className={styles.refresh}
              intent={Intent.NONE}
              icon={<SyncIcon />}
              onClick={async () => {
                setIsLoading(true);
                await nodes.fetchList(match.params.wsId);
                setIsLoading(false);
              }}
            />
            <Button
              className={styles.createNew}
              intent={Intent.PRIMARY}
              text={t('pages.nodeList.createNew')}
              disabled={workspaces.current!.suspendTaskDone}
              onClick={() => {
                history.push(`/workspaces/${workspaces.current!.id}/nodes/join-network`);
              }}
            />
          </AntdCol>
        </AntdRow>
      </FixedMainHeader>
      <PageLayout>
        <div className={styles.bodyContent}>
          {!!networks.list.length &&
            Object.keys(nodes.mapData).map((networkKey: string) => (
              <div key={networkKey} className={cx(styles.nodeListOutside, hiddingMap[networkKey] ? styles.hide : '')}>
                <div
                  className={styles.nodeListTitle}
                  onClick={() => {
                    setHiddingMap({
                      ...hiddingMap,
                      [networkKey]: !hiddingMap[networkKey],
                    });
                  }}
                >
                  {hiddingMap[networkKey] ? (
                    <Icon icon={IconNames.CHEVRON_RIGHT} />
                  ) : (
                    <Icon icon={IconNames.CHEVRON_DOWN} />
                  )}
                  <span>{networks.getByKey(networkKey)?.displayName}</span>
                  <span className={styles.count}>({nodes.mapData[networkKey].length})</span>
                </div>
                <div className={cx(hiddingMap[networkKey] ? styles.displayNone : '')}>
                  <NodeRegion workspaceId={workspaces.current!.id} nodes={nodes.mapData[networkKey]} />
                </div>
              </div>
            ))}
        </div>
      </PageLayout>
    </Loader>
  );
});

import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useStore } from '../../store';
import { Controller, useForm } from 'react-hook-form';
import { DetailBox, ComponentHeader } from 'components';
import { ErrorToast, SuccessToast, WarningToast } from '../../components/Toast';
import dayjs from 'dayjs';
import styles from './styles.module.scss';
import { Instance } from 'mobx-state-tree';
import { Workspace } from '../../store/workspaces';
import { ReactComponent as Ticket } from './img/ticket.svg';
import { Modal, Button, Input, Select, Table, Alert } from 'antd';
import { Coupon as CouponType } from '@/store/account';
import * as yup from 'yup';

const lang = (t: TFunction, key: string, options?: any) => t(`modules.accountCoupon.${key}`, options);

type Ws = Instance<typeof Workspace>;
type Coupon = Instance<typeof CouponType>;

const couponTerm = (data: Coupon) => {
  let term = '';
  if (data.percent_off) {
    term += `${data.percent_off}%`;
  } else if (data.amount_off) {
    term += `$${(data.amount_off / 100).toFixed(2)}`;
  }
  term += ` off ${data.duration_in_months ? `for ${data.duration_in_months} month` : data.duration}`;
  return term;
};

const couponExpire = (redeem_by: number | null) => {
  return redeem_by ? dayjs(+`${redeem_by}000`).format('DD/MM/YYYY') : '----';
};

const schema = yup.object().shape({
  coupon: yup.string().required(),
});

const columns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'Code',
    key: 'promotion_code_name',
    dataIndex: 'promotion_code_name',
  },
  {
    title: 'Term',
    key: 'couponAmount',
    render: (v: Coupon) => {
      return <>{couponTerm(v)}</>;
    },
  },
  {
    title: 'Expire Date',
    key: 'expireDate',
    render: (v: Coupon) => {
      return <>{couponExpire(v.redeem_by)}</>;
    },
  },
];

export const AccountCouponsNew: React.FC = observer(() => {
  const [modal, contextHolder] = Modal.useModal();
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isLoadingAccountCoupon, setIsLoadingAccountCoupon] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { workspaces, account, paymentMethods } = useStore();
  const { control, handleSubmit } = useForm({ validationSchema: schema });
  const [coupon, setCoupon] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (workspaces.state !== 'progressing') {
        setIsLoadingAccountCoupon(true);
        await account.getCoupons();
        setIsLoadingAccountCoupon(false);
      }
      if (account.state === 'error') {
        ErrorToast(account.errorMessage!);
      }
    })();
  }, [workspaces.state]);

  const closeDialog = () => setShowDialog(false);

  const submitCoupon = async (coupons: string) => {
    setSubmitLoading(true);
    await workspaces.redeemCoupon(coupons);
    if (workspaces.state === 'error') {
      ErrorToast(workspaces.errorMessage!);
      setSubmitLoading(false);
      return;
    }
    await account.getCoupons();
    setSubmitLoading(false);
    SuccessToast(lang(t, 'successMsg'));
    closeDialog();
  };

  const onSubmit = handleSubmit(async (values) => {
    if (account.coupons[0] && values.coupon === account.coupons[0].id)
      return WarningToast(lang(t, 'alreadyApplied', { coupon: values.coupon }));
    if (account.coupons.length === 0) return submitCoupon(values.coupon);
    modal.confirm({
      title: lang(t, 'confirm.title'),
      content: lang(t, 'confirm.contant'),
      okText: lang(t, 'confirm.ok'),
      okButtonProps: { type: 'primary' },
      cancelText: lang(t, 'confirm.cancel'),
      onOk: async () => {
        submitCoupon(values.coupon);
      },
      centered: true,
    });
  });
  return (
    <DetailBox className={styles.container}>
      <ComponentHeader>
        <h3>{lang(t, 'title')}</h3>
        <Button
          type="link"
          onClick={() => {
            setShowDialog(true);
          }}
        >
          <Ticket className={styles.mr9} />
          {lang(t, 'redeem')}
        </Button>
      </ComponentHeader>
      {!isLoadingAccountCoupon && account.coupons.length ? (
        <Table
          loading={isLoadingAccountCoupon}
          columns={columns}
          dataSource={[...account.coupons]}
          pagination={false}
          scroll={{ y: 345 }}
        />
      ) : (
        <p className={styles.emptyText}>You have no active Coupon</p>
      )}
      {showDialog && (
        <Modal
          title={lang(t, 'dialogTitle')}
          visible={true}
          centered
          onCancel={() => {
            setShowDialog(false);
          }}
          footer={
            paymentMethods.list.length
              ? [
                  <Button
                    key={'redeem coupon'}
                    type="primary"
                    onClick={() => {
                      onSubmit();
                    }}
                    loading={submitLoading}
                    disabled={coupon === null}
                  >
                    {lang(t, 'redeem')}
                  </Button>,
                ]
              : []
          }
        >
          {paymentMethods.list.length === 0 ? (
            <Alert className={styles.mb16} message={lang(t, 'setup-payment')} type="info" showIcon />
          ) : (
            ''
          )}
          <p className={styles.fontSize16}>{lang(t, 'code')}</p>
          <Controller
            name="coupon"
            control={control}
            onChange={([e]) => {
              setCoupon(e.target.value);
              return e.target.value;
            }}
            as={<Input placeholder="Coupon Code" allowClear size="large" />}
            rules={{ required: true }}
          />
        </Modal>
      )}
      {contextHolder}
    </DetailBox>
  );
});

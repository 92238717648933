import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteConfigComponentProps } from 'react-router-config';
import { showConfirm } from '@/components/Confirm';
import { PageLayout, Loader, LaunchConfiguration, FixedMainHeader } from 'components';
import { BillingDetail, CreateNodeResult } from 'modules';
import { useStore } from 'store';
import { INetworkSpec } from '@/store/networks';
import { StepChooseNetwork } from './StepChooseNetwork';
import { useHistory } from 'react-router-dom';
import { StepConfirm } from './StepConfirm';
import { GetHourlyPrice } from './helpers';
import { StepForm } from './StepForm';
import { Steps } from './Steps';
import {
  previewToVars,
  previewToExtraArgs,
  previewToEnvExtraArgs,
  previewToContexts,
  previewToEmpty,
  withoutContext,
  launchData,
  mergeLaunchData,
} from '@/components/Preview/help';
import { initNodeFormData } from '@/components/LaunchConfiguration/index';
import styles from './style.module.scss';
import { LaunchDataMapBaseNodeTypeType } from '@/components/Preview/help';
import { lang } from './helpers';
import { Tabs } from 'antd';
import cx from 'classnames';
import { Welcome } from './welcome';

type IProps = RouteConfigComponentProps<{ wsId: string }>;

export interface NodeFormData {
  key?: string;
  isPublic?: boolean;
  networkSpecKey?: string;
  nodeSpecKey?: string;
  nodeSpec?: {
    key: string;
    multiplier: number;
  };
  publicPort?: boolean;
  storage?: number;
  name?: string;
  initFromBackup: boolean;
  useApiKey: boolean;
  metadata: any;
  nodeType: string;
  autoExpand: boolean;
  imageVersion?: string;
  useRecommend: boolean;
  provider?: string;
  region?: string;
  multiplier?: number;
  config?: {
    vars?: Array<any>;
    extraArgs?: any;
    extraEnvs?: Array<string | undefined>;
  };
  hasBackup?: boolean;
}

export const enum TAB {
  PUBLIC = 'public',
  CUSTOM = 'custom',
}
export const DeployNewNode: React.FC<IProps> = observer((props) => {
  const store = useStore();
  const { networks, workspaces, nodes } = useStore();
  const { info } = useStore();
  const { t } = useTranslation();
  const { match } = props;
  const wsId = match.params.wsId;
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState<undefined | string>();
  const [initialized, setInitialized] = useState(false);
  const [isPublicNetowk, setIsPublicNetowk] = useState(false);
  const [networkMetadata, setNetworkMetadata] = useState<any>();
  const [displayName, setDisplayName] = useState<undefined | string>();
  const [launchConfigurationDataMap, setLaunchConfigurationDataMap] = useState<LaunchDataMapBaseNodeTypeType>({
    comArgRules: {},
    delimiter: '',
    envRules: [],
    envExtra: [],
  });
  const [networkType, setNetworkType] = useState<string>(TAB.PUBLIC);

  const [data, setData] = useState<NodeFormData>(initNodeFormData);
  const getFormDefault = async (network: INetworkSpec): Promise<Partial<NodeFormData>> => {
    setDisplayName(network.displayName);
    return {
      nodeSpec: network.recommend
        ? {
            key: network.recommend!.nodeSpec,
            multiplier: network.recommend?.nodeSpecMultiplier || 1,
          }
        : undefined,
      storage: network.recommend?.storageSize,
      imageVersion: network.recommend?.imageVersion ?? network.metadata?.imageVersion,
      networkSpecKey: network.key,
      nodeType: network.nodeTypes[0]?.key,
      // networkDisplayName: network.displayName,
      useRecommend: !!network.recommend,
    };
  };

  useEffect(() => {
    (async () => {
      // await store.networks.fetchPublicList();
      const networkKey = props.location.hash?.replace('#', '');
      await Promise.all([networks.fetchAllNetowrks(workspaces.current!.id), networks.fetchBackup()]);
      await nodes.fetchList(match.params.wsId);
      if (Object.keys(nodes.mapData).length) {
        setStep(1);
      }
      if (networkKey) {
        const network = store.networks.getByKey(networkKey);
        if (!network) {
          setStep(0);
          return;
        }
        const initalData = await getFormDefault(network);
        setData({
          ...data,
          ...initalData,
        });
        setStep(2);
      }

      setInitialized(true);
    })();
  }, []);

  useEffect(() => {
    history.push(`${history.location.pathname}?step=${step}`);
    setNetworkType(TAB.PUBLIC);
  }, [step]);

  const onSubmit = async () => {
    if (!store.workspaces.current!.validPayment) {
      // if owner
      if (store.auth.user?.id === store.workspaces.current?.ownerId) {
        setStep(5);
      } else {
        showConfirm({
          message: t('common.requireOwnerMessage'),
        });
      }
      return;
    }
    const { name, provider, region, storage, autoExpand, ...payload } = data;
    const cluster = store.info.clusters.find((c) => c.cloud === provider && c.region === region);
    await store.nodes.deployNodeV2({
      ...payload,
      config: {
        vars: previewToVars(withoutContext(launchConfigurationDataMap)),
        extraArgs: previewToExtraArgs(launchConfigurationDataMap),
        extraEnvs: previewToEnvExtraArgs(launchConfigurationDataMap?.envExtra),
        contexts: previewToContexts(launchConfigurationDataMap),
        skippedArgs: previewToEmpty(launchConfigurationDataMap),
      },
      nodeName: name,
      storage: storage + 'Gi',
      clusterKey: cluster!.hash,
      autoExpand,
    });
    setShowResult(true);
  };

  if (!initialized) {
    return <Loader isLoading={true} />;
  }

  return (
    <PageLayout>
      {step === 0 && (
        <Welcome
          wsId={wsId}
          setStep={setStep}
          onSelect={async (network, isPublicNetowk) => {
            const initalData = await getFormDefault(network);
            setSelectedIcon(network?.metadata?.pictures?.icon);
            setData({
              ...initNodeFormData,
              ...initalData,
              isPublic: network.isPublic,
              key: network.key,
            });
            setIsPublicNetowk(isPublicNetowk);
            setStep(2);
          }}
        />
      )}
      {step === 1 && (
        <FixedMainHeader>
          <Tabs
            defaultActiveKey={TAB.PUBLIC}
            onChange={(key: string) => {
              setNetworkType(key);
            }}
          >
            <Tabs.TabPane key={TAB.PUBLIC} tab={lang(t, 'publicNetworks')} />
            <Tabs.TabPane key={TAB.CUSTOM} tab={lang(t, 'customNetworks')} />
          </Tabs>
        </FixedMainHeader>
      )}
      <div className={cx(styles.container, step === 1 && styles.pt40)}>
        {step > 1 && <Steps step={step} icon={selectedIcon} networkDisplayName={displayName} />}
        {step === 1 && (
          <StepChooseNetwork
            networkTypeProp={networkType}
            onSelect={async (network, isPublicNetowk) => {
              const initalData = await getFormDefault(network);
              setSelectedIcon(network?.metadata?.pictures?.icon);
              setData({
                ...initNodeFormData,
                ...initalData,
                isPublic: network.isPublic,
                key: network.key,
              });
              setIsPublicNetowk(isPublicNetowk);
              setStep(2);
            }}
          />
        )}

        {step === 2 && (
          <StepForm
            initData={data}
            onSubmit={async (values) => {
              setData(values);
              const { metadata, protocolKey, config } = await store.networks.fetchNetworkSpecDetail(
                isPublicNetowk,
                values.key!,
              );
              setNetworkMetadata(metadata);
              const {
                metadata: { rules: protocolRules },
                supportedContext,
              } = await store.networks.getProtocols(protocolKey);
              let dataMap = launchData(metadata, config?.operations, protocolRules, supportedContext)[values.nodeType];
              dataMap = mergeLaunchData(dataMap, null, null, { '--name': values.name });
              if (dataMap) setLaunchConfigurationDataMap(dataMap);
              if (metadata.inputParameters && metadata.inputParameters[values.nodeType]) setStep(3);
              else setStep(4);
            }}
            onBack={() => setStep(step - 1)}
          />
        )}

        {step === 3 && (
          <LaunchConfiguration
            notPreview={false}
            dataMap={launchConfigurationDataMap}
            submitButtonText={lang(t, 'buttonNext')}
            cancelButtonText={lang(t, 'buttonBack')}
            onBack={() => setStep(step - 1)}
            onSubmit={(dataMap: any) => {
              setLaunchConfigurationDataMap(dataMap);
              setStep(4);
            }}
          />
        )}

        {step === 4 && (
          <StepConfirm
            data={data}
            displayName={displayName}
            preview={launchConfigurationDataMap}
            onBack={() => {
              if (networkMetadata?.inputParameters && networkMetadata?.inputParameters[data.nodeType])
                setStep(step - 1);
              else setStep(2);
            }}
            onSubmit={onSubmit}
          />
        )}

        {step === 5 && (
          <BillingDetail
            amount={
              data?.nodeSpec?.key
                ? GetHourlyPrice(info.getNodeSpec(data?.nodeSpec!.key), data.storage, data.nodeSpec?.multiplier)
                : undefined
            }
            onSubmit={onSubmit}
            onBack={() => setStep(step - 1)}
          />
        )}
        {showResult && <CreateNodeResult status={store.nodes.state} />}
      </div>
    </PageLayout>
  );
});

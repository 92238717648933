import { Alert, Classes, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import ReactEcharts from 'echarts-for-react';
import { charOpt } from './helper';
import { FilterOutlined as FilterIcon } from '@ant-design/icons';
import cx from 'classnames';
import {
  PageLayout,
  FixedMainHeader,
  Loader,
  MainBox,
  DetailBox,
  AntdRow,
  AntdCol,
  NetworkSelection,
  DatePicker,
} from 'components';
import { Tabs, message, Select, Input, Spin } from 'antd';
import { useStore } from '@/store';
import styles from './style.module.scss';
import { Iservice } from '@/store/apiService';
import ResponseTimeChartNew from '@/components/Report/ResponseTimeChartNew';
import MethodStatsTable from '@/components/Report/MethodStatsTable';
import ApiRequestChartNew from '@/components/Report/ApiRequestChartNew';
import { Instance } from 'mobx-state-tree';
import cloneDeep from 'lodash/cloneDeep';
import { DefaultOptionType } from 'antd/lib/select';
import _ from 'lodash';
import { ReactComponent as NoDataIcon } from '../UsageCenter/imgs/noData.svg';
import dayjs, { Dayjs } from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import { RangeValue } from 'rc-picker/lib/interface';
import { ReactComponent as NoData } from './imgs/nodataicon.svg';
import { GeolocationMap, MapColumn } from '@/modules';

dayjs.extend(dayjsPluginUTC as any, { parseToLocal: true });
const { Option } = Select;

const { RangePicker } = DatePicker;

interface Network extends Instance<typeof Iservice> {}

type IProps = RouteConfigComponentProps<{ wsId: string; appId: string }>;

const { TabPane } = Tabs;

const reportDuration = ['24h', '7d', '30d'];

const dateForm = 'YYYY-MM-DD';
const apiDataForm = 'YYYY-MM-DD';

export const NetworkReport: React.FC<IProps> = observer((props) => {
  const { t } = useTranslation();
  const lang = (t: TFunction, key: string, opt?: any) => t(`pages.report.${key}`, opt);
  const { match } = props;
  const { wsId, appId } = match.params;
  const { workspaces, networkSpec, networks } = useStore();
  const [loadingMethodStats, setLoadingMethodStats] = useState(false);
  const [loadingMethodSummary, setLoadingMethodSummary] = useState(false);
  const [loadingNetworkSummary, setLoadingNetworkSummary] = useState(false);
  const [pageLoading, setPageloading] = useState(true);
  const [country, setCountry] = useState('');
  const [showMap, setShowMap] = useState(false);

  const [services, setServices] = useState<Array<Network>>([]);
  const [loading, setLoading] = useState(false);

  const endDate = dayjs.utc().format(apiDataForm);
  const weekBefore = dayjs(endDate).subtract(8, 'day');
  const monthBefore = dayjs(endDate).subtract(30, 'day');
  const today = dayjs(endDate);
  const yesterday = dayjs(today).subtract(1, 'day');

  const endDayjs = dayjs.utc().startOf('h');
  const hoursAgo = endDayjs.subtract(24, 'hour');

  const [dataRange, setDataRange] = useState<[Dayjs, Dayjs]>([hoursAgo, endDayjs]);
  const [dates, setDates] = useState<RangeValue<dayjs.Dayjs>>(null);
  const [hackValue, setHackValue] = useState<any>(null);

  const twentyFourthHoursAgo: boolean =
    dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf();
  const [usageOpt, setUsageOpt] = useState<{
    duration: string;
    displayName: string;
    subDomain: string;
    icon: string | undefined;
    method?: string[];
    protocolKey: string;
  }>({
    duration: reportDuration[0],
    displayName: '',
    subDomain: '',
    icon: '',
    protocolKey: '',
  });

  const savedMethods = networks.savedMethods();
  const methodSelectOptions = useMemo<DefaultOptionType[]>(
    () =>
      savedMethods.map((x) => ({
        label: x || 'UNKNOWN',
        value: x,
      })),
    [savedMethods],
  );
  const methodSelectOptionElements = useMemo(
    () =>
      methodSelectOptions.map((x) => (
        <Option key={x.value} className={x.value || styles.italicText}>
          {x.label}
        </Option>
      )),
    [methodSelectOptions],
  );
  const hasMethodSelected = useMemo(
    () => usageOpt.method && usageOpt.method.filter((x) => x !== undefined).length > 0,
    [usageOpt.method],
  );
  useEffect(() => {
    (async () => {
      setShowMap(false);
      const { subDomain } = usageOpt;
      if (!subDomain || !dataRange[0] || !dataRange[1]) return;
      setLoadingMethodSummary(true);
      setLoadingNetworkSummary(true);
      networkSpec.fetchSummary(
        workspaces.current!.id,
        subDomain,
        dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
          ? `${dayjs(dataRange[0]).format('YYYY-MM-DD HH')}:00:00`
          : `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
        dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
          ? `${dayjs(dataRange[1]).format('YYYY-MM-DD HH')}:00:00`
          : `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
      );

      networkSpec
        .fetchRequestByCountry(
          subDomain,
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[0]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[1]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
          workspaces.current!.id,
        )
        .finally(() => setShowMap(true));
      networks
        .getMethodStatsSummary(
          wsId,
          subDomain,
          '',
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[0]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[1]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
        )
        .finally(() => setLoadingMethodSummary(false));
      networks
        .getNetworkStats(
          wsId,
          subDomain,
          '',
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[0]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[1]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
        )
        .finally(() => setLoadingNetworkSummary(false));
    })();
  }, [usageOpt.subDomain, dataRange]);

  useEffect(() => {
    (async () => {
      const { subDomain, duration, method } = usageOpt;
      if (!subDomain || !duration) return;
      setLoadingMethodStats(true);
      const promises = [
        networks.getMethodStatsHourly(
          wsId,
          subDomain,
          '',
          method,
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[0]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[1]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
        ),
      ];
      if (!hasMethodSelected) {
        promises.push(
          networks.getNetworkStats(
            wsId,
            subDomain,
            '',
            dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
              ? `${dayjs(dataRange[0]).format('YYYY-MM-DD HH')}:00:00`
              : `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
            dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
              ? `${dayjs(dataRange[1]).format('YYYY-MM-DD HH')}:00:00`
              : `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
          ),
        );
      }
      Promise.all(promises).finally(() => setLoadingMethodStats(false));
    })();
  }, [usageOpt.subDomain, usageOpt.method, dataRange]);

  useEffect(() => {
    (async () => {
      setPageloading(true);
      setLoading(true);
      await networks.getApiServices(wsId, reportDuration[0]);
      setServices(cloneDeep(networks.services));
      setLoading(false);
      setPageloading(false);
    })();
    return () => {
      (async () => {
        await networks.cleanApiServices(wsId);
      })();
    };
  }, [wsId]);

  const handleNetworkSelected = (key: string) => {
    const currentValue = services.find((i) => i.subDomain === key) as Network;
    if (!currentValue) {
      return;
    }
    setUsageOpt({
      ...usageOpt,
      subDomain: currentValue.subDomain,
      displayName: currentValue.displayName,
      icon: currentValue.metadata?.pictures?.icon,
      protocolKey: currentValue.protocolKey,
    });
  };
  if (pageLoading) return <Loader isLoading={true} />;

  if (!services.length)
    return (
      <PageLayout>
        <FixedMainHeader>
          <AntdRow className={styles.row}>
            <AntdCol span={6}>
              <h3>{lang(t, 'title')}</h3>
            </AntdCol>
            <AntdCol span={18} alignRight></AntdCol>
          </AntdRow>
        </FixedMainHeader>
        <DetailBox className={styles.nodataContainer}>
          <NoDataIcon />
          <h2>{lang(t, `title`)}</h2>
          <p>{lang(t, `noDataDescription`)}</p>
        </DetailBox>
      </PageLayout>
    );

  return (
    <PageLayout>
      <FixedMainHeader>
        <AntdRow className={styles.row}>
          <AntdCol span={6}>
            <h3>{lang(t, 'title')}</h3>
          </AntdCol>
          <AntdCol span={18} alignRight></AntdCol>
        </AntdRow>
      </FixedMainHeader>
      <MainBox>
        <div>
          {/* Network selections */}
          <div className={styles.networks}>
            <h3 className={styles.networkHeader}>{lang(t, 'networkSelection.heading')}</h3>
            {loading ? (
              <div className={styles.networkSelectionLoader}>
                <Loader isLoading={true} padding={false} />
              </div>
            ) : (
              <NetworkSelection
                allNetworks={services}
                networkPerPage={7}
                searchName={''}
                showExpanded={false}
                onSelected={handleNetworkSelected}
                expanded={true}
                onToggleExpand={(expand) => {}}
                appId={appId}
              />
            )}
          </div>

          <AntdRow className={styles.row}>
            <AntdCol span={6}>
              <h3 className={Classes.HEADING}>{usageOpt.displayName}</h3>
            </AntdCol>
            <AntdCol span={18} alignRight>
              <RangePicker
                defaultValue={dataRange}
                value={hackValue || dataRange}
                format={dateForm}
                onChange={(data: any) => {
                  data && setDataRange(data);
                }}
                disabledDate={(current) => {
                  if (!dates) {
                    return false;
                  }
                  const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
                  const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
                  return !!tooEarly || !!tooLate || current.diff(today.startOf('day'), 'day') > 0;
                }}
                onCalendarChange={(val) => setDates(val)}
                onOpenChange={(open) => {
                  if (open) {
                    setHackValue([null, null]);
                    setDates([null, null]);
                  } else {
                    setHackValue(null);
                  }
                }}
                ranges={{
                  [lang(t, `dateRangeExtar.pass24Hours`)]: [hoursAgo, endDayjs],
                  [lang(t, `dateRangeExtar.pastWeek`)]: [weekBefore, yesterday],
                  [lang(t, `dateRangeExtar.pastMonth`)]: [monthBefore, yesterday],
                }}
              />
              <p className={styles.utcTime}>{twentyFourthHoursAgo ? lang(t, 'utcTime24H') : lang(t, 'utcTime')}</p>
            </AntdCol>
          </AntdRow>
          <DetailBox className={styles.detailBox}>
            <h4>
              {lang(t, 'apiRequestsDiagram')}{' '}
              {twentyFourthHoursAgo && (
                <span className={styles.duration}>({lang(t, `dateRangeExtar.pass24Hours`)})</span>
              )}
            </h4>
            {workspaces.current?.id && usageOpt && (
              <>
                {networkSpec.summary && (
                  <ReactEcharts
                    option={charOpt(
                      networkSpec.summary,
                      dataRange[0].valueOf() === dataRange[1].valueOf() ||
                        (dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf())
                        ? '24h'
                        : '7d',
                    )}
                    className={styles.echarts}
                    style={{ height: 350, width: '100%' }}
                  />
                )}
                {!networkSpec.summary && networkSpec.state === 'pending' && <Spin />}
                {!networkSpec.summary && networkSpec.state === 'done' && (
                  <ApiRequestChartNew
                    chartData={networks.networkStatsMap()}
                    errorData={networks.networkErrorsMap()}
                    loading={loadingNetworkSummary}
                    duration={
                      dataRange[0].valueOf() === dataRange[1].valueOf() ||
                      (dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf())
                    }
                    transFn={(key) => lang(t, key)}
                    startTime={dataRange[0]}
                    endTime={dataRange[1]}
                  />
                )}
              </>
            )}
          </DetailBox>

          <DetailBox className={styles.detailBox}>
            <AntdRow className={styles.chartHeader}>
              <AntdCol span={16}>
                <h4>
                  {lang(t, 'apiResponsesDiagram')}{' '}
                  {twentyFourthHoursAgo && (
                    <span className={styles.duration}>({lang(t, `dateRangeExtar.pass24Hours`)})</span>
                  )}
                </h4>
              </AntdCol>
              {dataRange[0].diff(dataRange[1], 'day') > -3 && (
                <AntdCol span={8}>
                  <Select
                    showSearch
                    allowClear
                    suffixIcon={<FilterIcon />}
                    value={usageOpt.method}
                    placeholder={lang(t, 'apiResponseFilter')}
                    onChange={(method) => {
                      setUsageOpt({
                        ...usageOpt,
                        method: Array.isArray(method) ? method : [method],
                      });
                    }}
                    className={cx(usageOpt.method?.at(0) || styles.italicText, styles.inputWidth)}
                  >
                    {methodSelectOptionElements}
                  </Select>
                </AntdCol>
              )}
            </AntdRow>
            <ApiRequestChartNew
              chartData={hasMethodSelected ? networks.methodStatsMap() : networks.networkStatsMap()}
              errorData={hasMethodSelected ? networks.methodErrorsMap() : networks.networkErrorsMap()}
              loading={loadingNetworkSummary}
              duration={
                dataRange[0].valueOf() === dataRange[1].valueOf() ||
                (dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf())
              }
              transFn={(key) => lang(t, key)}
              startTime={dataRange[0]}
              endTime={dataRange[1]}
              twentyFourH={twentyFourthHoursAgo}
            />
          </DetailBox>

          <DetailBox className={styles.detailBox}>
            <AntdRow className={styles.chartHeader}>
              <AntdCol span={16}>
                <h4>
                  {lang(t, 'responseTime')}{' '}
                  {twentyFourthHoursAgo && (
                    <span className={styles.duration}>({lang(t, `dateRangeExtar.pass24Hours`)})</span>
                  )}
                </h4>
              </AntdCol>

              {dataRange[0].diff(dataRange[1], 'day') > -3 && (
                <AntdCol span={8}>
                  <Select
                    showSearch
                    allowClear
                    suffixIcon={<FilterIcon />}
                    value={usageOpt.method}
                    placeholder={lang(t, 'apiResponseFilter')}
                    onChange={(method) => {
                      setUsageOpt({
                        ...usageOpt,
                        method: Array.isArray(method) ? method : [method],
                      });
                    }}
                    className={cx(usageOpt.method?.at(0) || styles.italicText, styles.inputWidth)}
                  >
                    {methodSelectOptionElements}
                  </Select>
                </AntdCol>
              )}
            </AntdRow>
            {dataRange[0].diff(dataRange[1], 'day') > -3 ? (
              <ResponseTimeChartNew
                chartData={hasMethodSelected ? networks.methodStatsMap() : networks.networkStatsMap()}
                loading={loadingMethodStats}
                duration={
                  dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
                }
                startTime={dataRange[0]}
                endTime={dataRange[1]}
                twentyFourH={twentyFourthHoursAgo}
              />
            ) : (
              <div className={styles.noResponseTimes}>
                <NoData className={styles.noDataPic} />
                Filter to 3 days or fewer to view Response Times
              </div>
            )}
          </DetailBox>
          <DetailBox className={cx(styles.detailBox, styles.mapBox)}>
            <h4>
              Requests by Country{' '}
              {twentyFourthHoursAgo && (
                <span className={styles.duration}>({lang(t, `dateRangeExtar.pass24Hours`)})</span>
              )}
            </h4>
            <div className={styles.mapDiv}>
              <div className={styles.mapColumn}>
                <MapColumn />
              </div>
              {showMap && (
                <GeolocationMap
                  className={styles.map}
                  setCountry={setCountry}
                  tooltipWord={
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>{country}</span>
                      <span style={{ fontSize: 12 }}>
                        Total Responses:{' '}
                        {networkSpec.requestByCountry && networkSpec.requestByCountry.resultData[country]
                          ? (networkSpec.requestByCountry.resultData as any)[country].toLocaleString()
                          : 0}{' '}
                        (
                        {networkSpec.requestByCountry &&
                        networkSpec.requestByCountry.resultData[country] &&
                        networkSpec.requestByCountry.totalRequest
                          ? (
                              ((networkSpec.requestByCountry.resultData as any)[country] /
                                networkSpec.requestByCountry.totalRequest) *
                              100
                            ).toFixed(2) + '%'
                          : '0%'}
                        )
                      </span>
                    </div>
                  }
                />
              )}
            </div>
          </DetailBox>

          <DetailBox className={styles.detailBox}>
            <h4>
              {lang(t, 'methodsSummary')}{' '}
              {twentyFourthHoursAgo && (
                <span className={styles.duration}>({lang(t, `dateRangeExtar.pass24Hours`)})</span>
              )}
            </h4>
            <MethodStatsTable
              data={networks.methodSummaryMap()}
              twentyFourH={twentyFourthHoursAgo}
              loading={loadingMethodSummary}
              duration={dataRange[0].valueOf() === dataRange[1].valueOf() || twentyFourthHoursAgo ? '24h' : '7d'}
            />
          </DetailBox>
        </div>
      </MainBox>
    </PageLayout>
  );
});

import React from 'react';
import cx from 'classnames';
import styles from './style.module.scss';

interface IProps {
  className?: string;
}

export const ComponentHeader: React.FC<IProps> = ({ children }) => {
  return <div className={cx(styles.container)}>{children}</div>;
};

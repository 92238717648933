// eslint-disable-next-line import/named
import { flow, types, Instance } from 'mobx-state-tree';
import { api, filterErrorMessage } from '../utils/api';
import { pickOutError } from '@/utils/tools';

export const NodeSpec = types
  .model({
    key: types.identifier,
    name: types.string,
    cpu: types.string,
    memory: types.string,
    active: types.boolean,
    createdAt: types.string,
    updatedAt: types.string,
    maxMultiplier: types.number,
    partnerNodePrice: types.maybeNull(types.number),
    partnerStoragePrice: types.maybeNull(types.number),
  })
  .views((self: any) => ({
    get cpuSize() {
      return Number(self.cpu.replace(/m$/, '')) / 1000;
    },
    get memorySize() {
      return Number(self.memory.replace(/Mi$/, ''));
    },
  }));

export const Cluster = types.model({
  hash: types.identifier,
  cloud: types.string,
  region: types.string,
  index: types.number,
  active: types.boolean,
  name: types.string,
});

export type ICluster = Instance<typeof Cluster>;

export const Protocol = types.model({
  key: types.identifier,
  name: types.string,
  derivable: types.boolean,
  imageRepositories: types.array(types.string),
  createdAt: types.string,
  updatedAt: types.string,
});

export const Info = types
  .model({
    state: types.optional(types.enumeration(['pending', 'done', 'error']), 'pending'),
    clusters: types.optional(types.array(Cluster), []),
    nodeSpecs: types.optional(types.array(NodeSpec), []),
    protocols: types.optional(types.array(Protocol), []),
    errorMessage: types.maybe(types.string),
  })
  .views((self: any) => ({
    get providers() {
      return Array.from(
        self.clusters.reduce((acc: any, cluster: any) => {
          acc.add(cluster.cloud);
          return acc;
        }, new Set<string>()),
      );
    },
    get regions() {
      return Array.from(
        self.clusters.reduce((acc: any, cluster: any) => {
          acc.add(cluster.region);
          return acc;
        }, new Set<string>()),
      );
    },

    get nodeSpecsNoStandard() {
      return self.nodeSpecs.filter((i: any) => {
        return i.maxMultiplier === 1;
      });
    },

    getCluster(hash: string) {
      return self.clusters.find((c: ICluster) => c.hash === hash);
    },

    getNodeSpec(key: string) {
      return self.nodeSpecs.find((s: any) => s.key === key);
    },

    getUnitMaxMultiplier() {
      const unit = self.nodeSpecs.filter((i: any) => i.key === 'unit');
      return (unit && unit[0]?.maxMultiplier) || 1;
    },
  }))
  .actions((self: any) => ({
    fetchData: flow(function* () {
      self.state = 'pending';
      try {
        const data = yield api.get('/info');
        self.clusters = data.clusters;
        self.nodeSpecs = data.nodeSpecs;
        self.protocols = data.protocols;
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    }),
    fetchClusterRegions: flow(function* () {
      self.state = 'pending';
      try {
        const regions = yield api.get('/info/clusterRegions');
        return regions;
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    }),
    fetchImageVersions: flow(function* (repo: string) {
      self.state = 'pending';
      try {
        const versions = yield api.get(`/info/images/${encodeURIComponent(repo)}/versions`);
        self.state = 'done';
        return versions;
      } catch (err) {
        self.state = 'error';
        console.log(err);
        self.errorMessage = pickOutError(err);
      }
    }),
  }));

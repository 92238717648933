import { Menu } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import styles from './style.module.scss';
import { ReactComponent as APIIcon } from './img/API-icon.svg';
import { ReactComponent as BlockchainIcon } from './img/Blockchain-icon.svg';
import { ReactComponent as NetowrkIcon } from './img/Network-icon.svg';
import { ReactComponent as DashboardIcon } from './img/dashboard.svg';
import { ReactComponent as SettingIcon } from './img/setting.svg';
import { ReactComponent as DocumentIcon } from './img/document-icon.svg';
import { ReactComponent as BillingUsage } from './img/billingUsage.svg';
import { ReactComponent as RadioWave } from './img/radioWaves.svg';
import { ReactComponent as HomeIcon } from './img/Home.svg';
import { ReactComponent as LinkIcon } from './img/LinkIcon.svg';
import { isPlanHasPermission } from '@/configuration';
import cx from 'classnames';

import { defaultLeftMenu, defaultPage } from '@/configuration';

interface UrlMap {
  [type: string]: string;
}

interface IProps {
  collapsed: boolean;
}
const { SubMenu } = Menu;

export const getUrls = (wsId: string | undefined, canViewReport: boolean): UrlMap => {
  let result = {
    dashboard: `/workspaces/${wsId}/dashboard`,
    apiServices: `/workspaces/${wsId}/apiservices`,
    nodes: `/workspaces/${wsId}/nodes`,
    backups: `/workspaces/${wsId}/backups`,
    planPayment: `/workspaces/${wsId}/settings/plan`,
    settings: `/workspaces/${wsId}/settings`,
    report: `/workspaces/${wsId}/report`,
    usageCenter: `/workspaces/${wsId}/usageCenter`,
    documentation: `https://documentation.onfinality.io/support/`,
    networkStatus: 'https://status.onfinality.io/',
  } as { [key: string]: string };
  result[canViewReport ? 'networkSpec' : 'networks'] = `/workspaces/${wsId}/networks`;
  return result;
};

const buildMenuConfig: any = (item: Array<any>, idMap: any, currentUrlId: string | null) => {
  return item.map((i) => {
    if (i.childNodes) i.childNodes = buildMenuConfig(i.childNodes, idMap);
    if (i.id === currentUrlId) i.isSelected = true;
    else if (!currentUrlId && i.id === defaultPage) i.isSelected = true;
    return { ...i, ...idMap[i.id] };
  });
};

const findCurrentMenuId = (urlMap: UrlMap, pathname: string) => {
  return Object.keys(urlMap).find((key) => pathname.indexOf(urlMap[key]) >= 0);
};

export const LeftMenu: React.FC<IProps> = observer(({ collapsed }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { router, workspaces } = useStore();
  const currentPlanCanPublish = isPlanHasPermission(workspaces.current?.plan, 'viewReport');

  const menuIdMap = {
    dashboard: {
      label: t('leftMenue.Dashboard'),
      icon: (
        <span className="anticon">
          <HomeIcon />
        </span>
      ),
    },
    apiServices: {
      label: t('leftMenue.APIServices'),
      icon: (
        <span className="anticon">
          <APIIcon />
        </span>
      ),
    },
    nodes: {
      label: t('leftMenue.DedicatedNodes'),
      icon: (
        <span className="anticon">
          <BlockchainIcon />
        </span>
      ),
    },
    networks: {
      label: t('leftMenue.Networks'),
      icon: (
        <span className="anticon">
          <NetowrkIcon />
        </span>
      ),
    },
    report: {
      label: t('leftMenue.Report'),
    },
    networkSpec: {
      label: t('leftMenue.NetworkSpecifications'),
    },
    usageCenter: {
      label: t('leftMenue.usageCenter'),
      icon: (
        <span className="anticon">
          <BillingUsage />
        </span>
      ),
    },
    settings: {
      label: t('leftMenue.Settings'),
      icon: (
        <span className="anticon">
          <SettingIcon />
        </span>
      ),
    },
    workspaces: {
      label: t('leftMenue.Workspaces'),
      icon: (
        <span className="anticon">
          <SettingIcon />
        </span>
      ),
    },
    planPayment: {
      label: t('leftMenue.PlanPayment'),
    },
    documentation: {
      label: t('leftMenue.Documentation'),
      icon: (
        <span className="anticon">
          <DocumentIcon />
        </span>
      ),
    },
    networkStatus: {
      label: t('leftMenue.NetworkStatus'),
      icon: (
        <span className="anticon">
          <RadioWave />
        </span>
      ),
    },
  };
  const urlMap = getUrls(workspaces.current?.id, currentPlanCanPublish);
  const leftMenu = defaultLeftMenu({ plan: workspaces.current?.plan });
  const currentUrlId = Object.keys(urlMap).find((id: string) => router.location.pathname.indexOf(urlMap[id]) >= 0);
  const INITIAL_TREE = buildMenuConfig(leftMenu, menuIdMap, currentUrlId);
  const currentNodeId = findCurrentMenuId(urlMap, history.location.pathname);
  const openKeys = !collapsed ? ['networks'] : undefined;

  return (
    <div className={styles.treeBox}>
      <Menu
        className={styles.tree}
        selectedKeys={[currentNodeId || '']}
        // defaultOpenKeys={['networks']}
        mode="inline"
        selectable
      >
        {INITIAL_TREE.map((firstLevel: any) => {
          if (firstLevel.childNodes?.length > 0) {
            return (
              <SubMenu
                className={cx({ [styles.subMenuSelected]: currentNodeId === firstLevel.id })}
                key={firstLevel.id}
                icon={firstLevel.icon}
                title={firstLevel.label}
                // onTitleClick={() => {
                //   history.push(urlMap[firstLevel.id]);
                // }}
              >
                {firstLevel.childNodes.map((secondLevel: any) => (
                  <Menu.Item
                    key={secondLevel.id}
                    onClick={() => {
                      history.push(urlMap[secondLevel.id]);
                    }}
                  >
                    {secondLevel.label}
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          } else {
            if (firstLevel.id === 'divider')
              return <Menu.Divider key={firstLevel.id} className={styles.topBorder}></Menu.Divider>;
            else
              return (
                <Menu.Item
                  key={firstLevel.id}
                  icon={firstLevel.icon}
                  onClick={() => {
                    if (firstLevel.id === 'documentation' || firstLevel.id === 'networkStatus')
                      return window.open(urlMap[firstLevel.id]);

                    history.push(urlMap[firstLevel.id]);
                  }}
                >
                  {firstLevel.label}
                  {firstLevel.id === 'networkStatus' && <div className={styles.greenDot}></div>}
                  {(firstLevel.id === 'documentation' || firstLevel.id === 'networkStatus') && (
                    <span className={styles.linkIcon}>
                      <LinkIcon />
                    </span>
                  )}
                </Menu.Item>
              );
          }
        })}
      </Menu>
    </div>
  );
});

import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { useStore } from '@/store';
import map from './mapJson.json';
import { Tooltip } from 'antd';
interface IProps {
  setCountry: (value: string) => void;
  className?: string;
  tooltipWord: ReactNode;
}

export const GeolocationMap: React.FC<IProps> = observer(({ setCountry, className, tooltipWord }) => {
  const mapRef = useRef(null);
  const { networkSpec } = useStore();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const element: any = mapRef.current;
    const updateSize = () => {
      if (element) {
        setWidth(element!.clientWidth);
        setHeight(element!.clientHeight);
      }
    };
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);
  const countryColor = (name: string) => {
    if (networkSpec.requestByCountry) {
      if (networkSpec.requestByCountry.resultData[name] && networkSpec.requestByCountry.totalRequest) {
        const reqArr = Object.values(networkSpec.requestByCountry.resultData).map((e) => Number(e));
        const maxRequest = Math.max(...reqArr);
        const weight = (networkSpec.requestByCountry.resultData as any)[name] / maxRequest;
        if (weight === 1) {
          return '#031fef';
        } else if (weight > 0.9) {
          return '#354CF2';
        } else if (weight > 0.8) {
          return '#495EF3';
        } else if (weight > 0.7) {
          return '#5D70F5';
        } else if (weight > 0.6) {
          return '#7282F6';
        } else if (weight > 0.5) {
          return '#8694F7';
        } else if (weight > 0.4) {
          return '#9AA6F9';
        } else if (weight > 0.3) {
          return '#AEB7FA';
        } else if (weight > 0.2) {
          return '#C2C9FB';
        } else if (weight > 0.1) {
          return '#D7DBFC';
        } else if (weight > 0) {
          return '#EBEDFE';
        }
      }
    }
  };
  return (
    <ComposableMap
      projectionConfig={{
        center: [15, 7],
        scale: 160,
      }}
      height={height || 10}
      width={width}
      className={className ? className : ''}
      ref={mapRef}
    >
      <Geographies geography={map} stroke={'#595959'} strokeWidth={0.2}>
        {
          //@ts-ignore
          ({ geographies }) =>
            geographies.map((geo: any) => (
              <Tooltip title={tooltipWord}>
                <Geography
                  onMouseEnter={() => {
                    setCountry(`${geo.properties.name}`);
                  }}
                  onMouseLeave={() => {
                    setCountry('');
                  }}
                  className={'mapCountries'}
                  style={{
                    default: {
                      outline: 'none',
                      fill: countryColor(geo.properties.name) ? countryColor(geo.properties.name) : '#f5f5f5',
                    },
                    hover: {
                      outline: 'none',
                      fill: countryColor(geo.properties.name) ? countryColor(geo.properties.name) : '#f5f5f5',
                    },
                    pressed: {
                      outline: 'none',
                      fill: countryColor(geo.properties.name) ? countryColor(geo.properties.name) : '#f5f5f5',
                    },
                  }}
                  key={geo.rsmKey}
                  geography={geo}
                />
              </Tooltip>
            ))
        }
      </Geographies>
    </ComposableMap>
  );
});

import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteConfigComponentProps } from 'react-router-config';
import { PageLayout, FixedMainHeader, MainBox } from 'components';
import { Row, Col, Tabs } from 'antd';
import {
  AccountCouponsNew,
  AccountProfileNew,
  AccountAccessKeyNew,
  AccountPasswordNew,
  AccountPayMethodNew,
  AccountBillingHistoryNew,
} from '@/modules';

import styles from './style.module.scss';

const { TabPane } = Tabs;
type IProps = RouteConfigComponentProps;

export const AccountPage: React.FC<IProps> = observer((props) => {
  //@ts-ignore
  const [tabPage, setTabPage] = useState<string>(props.history.location.state?.detail || '1');
  const onChangeTabPage = (key: string) => {
    setTabPage(key);
  };
  return (
    <PageLayout className={styles.box}>
      <FixedMainHeader>
        <Tabs activeKey={tabPage} onChange={onChangeTabPage}>
          <TabPane tab="Account Settings" key="1"></TabPane>
          <TabPane tab="Billing History" key="2"></TabPane>
        </Tabs>
      </FixedMainHeader>
      {tabPage === '1' ? (
        <MainBox className={styles.mt20}>
          <AccountProfileNew className={styles.mb24} />
          <AccountPasswordNew className={styles.mb24} />
          <AccountAccessKeyNew className={styles.mb24} />
          <AccountPayMethodNew className={styles.mb24} />
          <AccountCouponsNew />
        </MainBox>
      ) : (
        <MainBox className={styles.mt20}>
          <AccountBillingHistoryNew />
        </MainBox>
      )}
    </PageLayout>
  );
});

import { cast, flow, Instance, types, getParent } from 'mobx-state-tree';
import { api } from '../utils/api';
import dayjs from 'dayjs';
import { planNames } from '@/configuration';
import { Store } from './index';
import { sortBy } from 'lodash';
import { pickOutError } from '@/utils/tools';
import { PaymentMethod } from './payment-methods';
import { mxConfig } from '@/config';

const WS_LOCAL_STORAGE_KEY = 'mx_ws';
const maxLogLength = 300;
export const usagePageSize = 20;

const Cutoff = types.model({
  workspaceId: types.string,
  usedResponses: types.string,
});

export const Workspace = types
  .model({
    id: types.identifier,
    name: types.string,
    ownerId: types.string,
    plan: types.enumeration(planNames),
    billingType: types.enumeration(['contract', 'stripe']),
    paymentMethodId: types.maybeNull(types.string),
    validPayment: types.maybe(types.boolean),
    active: types.boolean,
    billingDay: types.maybeNull(types.number),
    suspendAt: types.maybeNull(types.string),
    suspendTaskDone: types.boolean,
    teamName: types.maybeNull(types.string),
  })
  .views((self) => ({
    get suspended() {
      if (!self.suspendAt) {
        return false;
      }
      return dayjs().isAfter(self.suspendAt);
    },
  }));

export type IWorkspace = Instance<typeof Workspace>;

export const Member = types.model({
  id: types.string,
  name: types.string,
  email: types.string,
  role: types.string,
});

export type IMember = Instance<typeof Member>;

export const Invitations = types.model({
  id: types.string,
  email: types.string,
  role: types.string,
});

export const Logs = types.model({
  timestamp: types.string,
  message: types.string,
});

export const NodeUsageQueryItem = types.model({
  created_at: types.string,
  end: types.maybeNull(types.string),
  id: types.string,
  kind: types.string,
  kind_param: types.string,
  name: types.maybeNull(types.string),
  quantity: types.number,
  region: types.maybeNull(types.string),
  resource_id: types.string,
  start: types.string,
  total_usage: types.string,
  updated_at: types.string,
  usage_hours: types.string,
  workspace_id: types.string,
});

export const NodeUsageQuery = types.model({
  data: types.optional(types.array(NodeUsageQueryItem), []),
  total: types.optional(types.number, 0),
  totalPage: types.optional(types.number, 0),
});

export const Workspaces = types
  .model({
    state: types.optional(types.enumeration(['pending', 'progressing', 'done', 'error']), 'pending'),
    list: types.optional(types.array(Workspace), []),
    paymentList: types.optional(types.array(PaymentMethod), []),
    members: types.optional(types.array(Member), []),
    invitations: types.optional(types.array(Invitations), []),
    logs: types.optional(types.array(Logs), []),
    currentLogSort: types.maybe(types.number),
    current: types.safeReference(Workspace),
    nodeUsageQuery: types.optional(NodeUsageQuery, {
      data: [],
      total: 0,
      totalPage: 0,
    }),
    errorMessage: types.maybe(types.string),
    cutoffs: types.array(Cutoff),
  })
  .views((self) => ({
    get lastSelected() {
      const lastWsId = localStorage.getItem(WS_LOCAL_STORAGE_KEY);
      if (lastWsId) {
        const ws = this.get(lastWsId);
        if (ws) {
          return ws;
        }
        return self.list[0];
      }
      return self.list[0];
    },
    get(id: string) {
      return self.list.find((ws) => ws.id === id);
    },
    get currentWorkspaceMember() {
      const userId = getParent<Instance<typeof Store>>(self).auth.user!.id as string;
      return self.members.find((m) => m.id === userId);
    },
  }))
  .actions((self) => ({
    markPaymentValid(id: string, valid: boolean) {
      const ws = this.get(id);
      if (ws) {
        ws.validPayment = valid;
      }
    },
    fetchPayment: flow(function* (wsId: string) {
      self.state = 'pending';
      try {
        self.paymentList = yield api.get(`/workspaces/${wsId}/payment`);
        self.state = 'done';
      } catch (err) {
        console.error(err);
        pickOutError(err);
        self.state = 'error';
      }
    }),
    clearPayment: flow(function* () {
      self.paymentList = [] as any;
    }),

    fetchList: flow(function* () {
      self.state = 'pending';
      try {
        const list: IWorkspace[] = yield api.get('/workspaces');
        if (list.length === 0) {
          alert('The current workspace has been deactivated. If you need help, please contact us');
          getParent<Instance<typeof Store>>(self)
            .auth.logout()
            .then(() => {
              location.reload();
            });
        } else {
          self.list = cast(sortBy(list, (ws) => ws.id));
          self.state = 'done';
        }
      } catch (err) {
        console.error(err);
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    fetchMembers: flow(function* (wsId: string) {
      self.state = 'pending';
      try {
        [self.members, self.invitations] = yield Promise.all([
          api.get(`/workspaces/${wsId}/members`),
          api.get(`/workspaces/${wsId}/invitations`),
        ]);
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    get(id: string) {
      return self.list.find((ws) => ws.id === id);
    },

    setCurrent: flow(function* (ws: Instance<typeof Workspace> | string) {
      let nextWs = typeof ws === 'string' ? self.get(ws) : ws;
      try {
        if (!nextWs) {
          const currentWorkspace = yield api.get(`/workspaces/${ws}`) as any;
          if (currentWorkspace && !self.list.find((i) => i.id === ws)) self.list.push(currentWorkspace);
          nextWs = typeof ws === 'string' ? self.get(ws) : ws;
          self.current = nextWs;
        } else {
          self.current = nextWs;
          localStorage.setItem(WS_LOCAL_STORAGE_KEY, nextWs.id);
        }
      } catch (err) {
        console.error(err);
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    create: flow(function* (data: any) {
      self.state = 'pending';
      try {
        const ws = yield api.post(`/workspaces`, data);
        self.state = 'done';
        return ws;
      } catch (err) {
        console.error(err);
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    update: flow(function* (ws: Instance<typeof Workspace>, data: any) {
      self.state = 'progressing';
      try {
        yield api.put(`/workspaces/${ws.id}`, data);
        ws.name = data.name;
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    updateTeamName: flow(function* (ws: Instance<typeof Workspace>, data: any) {
      self.state = 'progressing';
      try {
        yield api.put(`/workspaces/${ws.id}/teamName`, data);
        ws.teamName = data.teamName;
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    updatePaymentMethod: flow(function* (ws: Instance<typeof Workspace>, data: any) {
      self.state = 'progressing';
      try {
        yield api.put(`/workspaces/${ws.id}/payment-method`, data);
        ws.paymentMethodId = data.paymentMethodId;
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    upgrade: flow(function* () {}),

    redeemCoupon: flow(function* (code: string) {
      self.state = 'progressing';
      try {
        yield api.post(`/workspaces/discount/${code}`);
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    inviteMember: flow(function* (wsId: string, email: string, role: 'admin' | 'member') {
      self.state = 'progressing';
      try {
        const plan = self.current?.plan;
        yield api.post(`/workspaces/${wsId}/invite`, { email, role, plan });
        self.state = 'done';
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    updateMemberRole: flow(function* (wsId: string, memberId: string, role: 'admin' | 'member') {
      self.state = 'progressing';
      try {
        yield api.put(`/workspaces/${wsId}/members/${memberId}/role`, { role });
        const member = self.members.find((m) => m.id === memberId);
        member!.role = role;
        self.state = 'done';
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    removeMember: flow(function* (wsId: string, memberId: string) {
      self.state = 'progressing';
      try {
        yield api.delete(`/workspaces/${wsId}/members/${memberId}`);
        const idx = self.members.findIndex((m) => m.id === memberId);
        self.members.splice(idx, 1);
        self.state = 'done';
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    cancelInvitation: flow(function* (wsId: string, invitationId: string) {
      self.state = 'progressing';
      try {
        yield api.delete(`/workspaces/${wsId}/invitations/${invitationId}`);
        const idx = self.invitations.findIndex((m) => m.id === invitationId);
        self.invitations.splice(idx, 1);
        self.state = 'done';
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    reInvitation: flow(function* (wsId: string, invitationId: string) {
      self.state = 'progressing';
      try {
        yield api.post(`/workspaces/${wsId}/invitations/${invitationId}/resend`);
        self.state = 'done';
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    updateValidPayment: flow(function* (wsId: string, pmId: string) {
      let result = self.list.find((m) => m.id === wsId);
      if (result) {
        result.validPayment = true;
        result.paymentMethodId = pmId;
      }
    }),

    cleanLogs: flow(function* () {
      self.logs = [] as any;
      self.currentLogSort = undefined;
    }),

    fetchLogs: flow(function* (wsId: string, nodeId: string) {
      self.state = 'progressing';
      try {
        const { result } = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${nodeId}/logs`, {
          params: {
            size: 100,
          },
        });
        if (result.length) {
          self.logs = result.map((i: any) => i.data);
          self.currentLogSort = result.pop().sort[0];
        }
        self.state = 'done';
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    fetchNewestLogs: flow(function* (wsId: string, nodeId: string, cursor: number) {
      self.state = 'progressing';
      try {
        const { result } = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/nodes/${nodeId}/logs`, {
          params: {
            size: 100,
            cursor,
          },
        });
        if (result.length) {
          const newLogs = result.map((i: any) => i.data);
          const currentLogs = [...self.logs, ...newLogs];
          self.logs = currentLogs.filter((item, index) => index > currentLogs.length - maxLogLength) as any;
          if (newLogs.length) self.currentLogSort = result[result.length - 1]?.sort[0] || undefined;
        }
        self.state = 'done';
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),

    fetchDetail: flow(function* (wsId: string) {
      self.state = 'pending';
      try {
        const { cutoffs } = yield api.get(`/workspaces/${wsId}`);
        self.cutoffs = cutoffs;
        self.state = 'done';
      } catch (err) {
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),
  }));

import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteConfigComponentProps } from 'react-router-config';
import { showConfirm } from '@/components/Confirm';
import { PageLayout } from '@/components/PageLayout';
import { ErrorToast } from '@/components/Toast';
import { BillingDetail, CreateNetworkSpecForm, CreateNetworkResult } from 'modules';
import { SubmitResult } from 'components';
import { useStore } from 'store';
import { NodeFormData } from '../DeployNewNode';
import { StepConfirm } from '../DeployNewNode/StepConfirm';
import { lang, Operations } from './helpers';
import { StepForm } from './StepForm';

export interface NodeRecommendItem {
  imageVersion: string;
  nodeType: string;
  nodeSpecKey: string;
  storageSize: number;
  storageType: string;
  nodeSpecMultiplier: number;
}

export interface PrivateNetworkFormData {
  name: string;
  displayName?: string;
  protocol: string;
  imageRepository: string;
  metadata: {
    chainspec: string;
    imageVersion: string;
    nodeSpec: {
      key: string;
      multiplier: number;
    };
    storageSize: string;
  };
  config: {
    operations: {
      [key: string]: Operations;
    };
  };
  tags: Array<string | undefined>;
  nodeRecommends: Array<NodeRecommendItem>;
}

type IProps = RouteConfigComponentProps;

export const NetworkCreate: React.FC<IProps> = observer((props) => {
  const { history } = props;
  const { t } = useTranslation();
  const { workspaces, auth, info, networks } = useStore();
  const [step, setStep] = React.useState(1);
  const [isCreating, setIsCreating] = React.useState(false);
  const [showResult, setShowResult] = useState(false);

  const [data, setData] = useState<PrivateNetworkFormData>({
    name: '',
    displayName: '',
    protocol: '',
    imageRepository: '',
    metadata: {
      chainspec: '',
      imageVersion: '',
      nodeSpec: {
        key: 'unit',
        multiplier: 1,
      },
      storageSize: '',
    },
    config: {
      operations: {},
    },
    tags: [],
    nodeRecommends: [],
  });

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const onCreate = async () => {
    setIsCreating(true);
    await networks.createNetworkSpecs(workspaces.current!.id, data);
    setIsCreating(false);
    if (networks.state === 'error') {
      ErrorToast(networks.errorMessage!);
    } else {
      setShowResult(true);
    }
  };

  return (
    <PageLayout>
      {step === 1 && (
        <CreateNetworkSpecForm
          initData={{
            networkDisplayName: data.displayName,
            protocol: data.protocol,
            imageRepository: data.imageRepository,
            tags: data.tags[0] || '',
            imageVersion: data.metadata.imageVersion,
          }}
          onSubmit={(networkData) => {
            const { networkDisplayName, protocol, imageRepository, tags, imageVersion } = networkData;
            setData({
              ...data,
              name: networkDisplayName,
              displayName: networkDisplayName,
              protocol: protocol,
              imageRepository: imageRepository,
              metadata: {
                ...data.metadata,
                chainspec: networkDisplayName,
                imageVersion: imageVersion,
              },
              config: {
                operations: {},
              },
              tags: [tags],
            });
            nextStep();
          }}
          onBack={() => history.goBack()}
        />
      )}

      {step === 2 && (
        <StepForm
          initData={data}
          isCreating={isCreating}
          onSubmit={(nodeData) => {
            setData({ ...data, ...nodeData });
            onCreate();
          }}
          onBack={prevStep}
        />
      )}
      {showResult && (
        <SubmitResult
          loadingMainText={'Loading...'}
          successMainText={lang(t, `createSuccessTitle`)}
          successSubText={lang(t, `createSuccessSubtitle`)}
          successButton={lang(t, `createSuccessButton`)}
          errorMainText={lang(t, `createErrorTitle`)}
          errorSubText={networks?.errorMessage}
          errorButton={lang(t, `createErrorButton`)}
          status={networks.state}
          errorClick={() => {
            setShowResult(false);
          }}
          successClick={() => {
            history.replace(`/workspaces/${workspaces.current!.id}/networks`);
          }}
        />
      )}
    </PageLayout>
  );
});

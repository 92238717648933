import { Form, Modal, Select } from 'antd';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { IMember } from '@/store/workspaces';
import { PopupSpinner } from '../PopupSpinner';
import { useTranslation } from 'react-i18next';

interface IProps {
  title?: string;
  isOpen: boolean;
  members?: IMember[];
  excludeMembers?: string[];
  onClose: () => void;
  onSelect?: (member?: IMember) => void;
  loading?: boolean;
  memberLabel?: string;
}

interface ChangeOwnerForm {
  id: string;
}

export const WorkspaceMemberDialog: React.FC<IProps> = observer(
  ({
    title, //
    isOpen,
    members,
    excludeMembers,
    onClose,
    onSelect,
    loading,
    memberLabel,
  }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm<ChangeOwnerForm>();

    const memberList = useMemo(() => {
      return (excludeMembers ? members?.filter((x) => !excludeMembers.includes(x.id)) : members)
        ?.map((x) => ({
          value: x.id,
          label: `${x.name} <${x.email}>`,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    }, [members, excludeMembers]);

    const onFilter = (input: string, option?: { label: string; value: string }) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onFinish = async (values: ChangeOwnerForm) => {
      if (onSelect) {
        onSelect(members?.find((x) => x.id === values.id));
      }
    };

    return (
      <Modal
        centered
        title={title || 'Workspace Members'}
        visible={isOpen}
        onCancel={onClose}
        onOk={form.submit}
        confirmLoading={loading}
        maskClosable={!loading}
        cancelButtonProps={{ hidden: loading }}
        closable={!loading}
      >
        <Form form={form} onFinish={onFinish}>
          {loading && <PopupSpinner spinning={loading} />}
          {!loading && (
            <Form.Item label={memberLabel || t('pages.setting.members.title')} name="id" rules={[{ required: true }]}>
              <Select options={memberList} showSearch filterOption={onFilter} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  },
);

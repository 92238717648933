import React from 'react';
import { Callout, Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

export const SuspendCallout = () => {
  const { t } = useTranslation();
  return (
    <Callout intent={Intent.WARNING} icon={'issue'}>
      <span
        dangerouslySetInnerHTML={{
          __html: t('common.workspaceSuspendedMessage'),
        }}
      />
    </Callout>
  );
};

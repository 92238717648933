import { Button } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Loader, NetworkSpecCardNew, ButtonTab, DetailBox } from 'components';
import { useStore } from 'store';
import { INetworkSpec } from '@/store/networks';
import { Input } from 'antd';
import { ReactComponent as NoData } from './img/NoData.svg';
import { lang } from './helpers';
import styles from './style.module.scss';
import _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import { TAB } from './index';

interface IProps {
  onSelect: (network: INetworkSpec, isPublicNetowk: boolean) => void;
  networkTypeProp: string;
}
const waitTime = 300;

const filterFilm = (query: string, film: string) => {
  if (!query) return true;
  return `${film.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};

interface tabOPtion {
  [key: string]: string;
}

export const StepChooseNetwork: React.FC<IProps> = observer(({ onSelect, networkTypeProp }) => {
  const { t } = useTranslation();
  const [filterWord, setFilterWord] = useState<string | undefined>('');
  const history = useHistory();
  // const searchBar = useRef(null);
  const { networkSpec, workspaces, networks } = useStore();
  const [selectedProtocolKey, setSelectedProtocolKey] = useState<string>('');
  const [selectedNetworkType, setSelectedNetworkType] = useState<string>('');

  const tabOptions: tabOPtion = {
    All: 'none',
    Polkadot: 'key',
    Avalanche: 'key',
    Full: 'type',
    Trace: 'type',
    Archive: 'type',
    Collator: 'type',
    Validator: 'type',
  };

  const tabOnChange = (key: string) => {
    if (key === 'All') {
      setSelectedNetworkType('');
      setSelectedProtocolKey('');
    } else if (tabOptions[key] === 'key') {
      setSelectedProtocolKey(key);
      setSelectedNetworkType('');
    } else if (tabOptions[key] === 'type') {
      setSelectedNetworkType(key);
      setSelectedProtocolKey('');
    }
  };

  // useEffect(() => {
  //   Promise.all([networks.fetchAllNetowrks(workspaces.current!.id), networks.fetchBackup()]);
  // }, []);

  // useEffect(() => {
  //   console.log(searchBar.current)
  //   if (searchBar.current !== null) {
  //     //@ts-ignore
  //     searchBar.current.focus();
  //   }
  // }, [searchBar.current]);

  const getNetwork = (tabWord: string) => {
    let networkList = tabWord === TAB.PUBLIC ? networks.getEnabledNetworks() : networks.getEnabledNetworks(false);
    networkList = networkList
      .filter((i) => {
        if (selectedProtocolKey.toLowerCase().includes('polkadot')) {
          return (
            i.protocolKey.toLowerCase().includes(selectedProtocolKey.toLowerCase()) ||
            i.protocolKey.toLowerCase().includes('substrate')
          );
        }
        return i.protocolKey.toLowerCase().includes(selectedProtocolKey.toLowerCase());
      })
      .filter((i) => {
        if (selectedNetworkType === '') return true;
        let keyArray: string[] = [];
        i.nodeTypes.forEach((e) => keyArray.push(e.key));
        return keyArray.indexOf(selectedNetworkType.toLowerCase()) !== -1;
      })
      .filter((i) => filterFilm(filterWord + '', i.displayName ?? i.name));
    return networkList;
  };

  const avaliableNetworks = getNetwork(networkTypeProp);

  const changeValue = _.debounce((v: string | undefined) => {
    setFilterWord(v);
  }, waitTime);

  if (networks.state === 'pending') {
    return <Loader isLoading={true}></Loader>;
  }
  return (
    <div className={styles.chooseNetwork}>
      {(avaliableNetworks.length > 0 || networkTypeProp === TAB.PUBLIC) && (
        <h2 className={styles.header}>{lang(t, 'chooseNetworkTitle')}</h2>
      )}
      {(avaliableNetworks.length > 0 || networkTypeProp === TAB.PUBLIC) && (
        <div className={styles.searchAndInputBar}>
          <ButtonTab itemList={Object.keys(tabOptions)} onChange={tabOnChange} className={styles.tabsLength} />
          <Input
            autoFocus={true}
            name={'searchWord'}
            // ref={searchBar}
            className={styles.searchBar}
            placeholder={lang(t, 'searchBarPleaseHoder')}
            onChange={(e: any) => {
              changeValue(e.target.value);
            }}
            suffix={<SearchOutlined className={styles.suffixIcon} />}
            size={'large'}
          />
        </div>
      )}
      {avaliableNetworks.length > 0 && (
        <div className={styles.networkCardDiv}>
          {avaliableNetworks.map((network, index) => {
            return (
              <NetworkSpecCardNew
                backup={networks.backupMap[network.key]}
                key={`${network.displayName}_${index}`}
                network={network}
                onClick={() => {
                  if (networkTypeProp === TAB.PUBLIC) {
                    onSelect(network, true);
                  } else {
                    onSelect(network, false);
                  }
                }}
              />
            );
          })}
        </div>
      )}
      {avaliableNetworks.length === 0 && networkTypeProp === TAB.CUSTOM && (
        <DetailBox className={styles.noCustom}>
          <NoData />
          <div className={styles.noCustomTitle}>{lang(t, 'customNetworks')}</div>
          <div className={styles.noCustomSubtext}>{lang(t, 'noCustom')}</div>
          <div className={styles.learnMore}>
            {lang(t, 'learnMore')}{' '}
            <a
              className={styles.font16a}
              href="https://documentation.onfinality.io/support/create-a-network-on-onfinality"
              target={'_target'}
            >
              {lang(t, 'here')}
            </a>
          </div>
          <Button
            className={styles.font16}
            type="primary"
            onClick={() => {
              history.push(`/workspaces/${workspaces.current?.id}/networks/create`);
            }}
          >
            {lang(t, 'createCustom')}
          </Button>
        </DetailBox>
      )}
    </div>
  );
});

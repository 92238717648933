import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import cx from 'classnames';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorToast, SubmitButton } from 'components';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router-dom';

import { useStore } from '../../store';
import styles from './style.module.scss';
import { mxConfig } from '@/config';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

let reloadLogs: any;
const lang = (t: TFunction, key: string, options?: any) => t(`pages.${key}`, options);

export const AcceptTermsDialog: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { auth } = useStore();
  const history = useHistory();
  const { t } = useTranslation();
  const [agreePolicy, setAgreePolicy] = useState(false);
  const [progressing, setProgressing] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(reloadLogs);
    };
  }, []);

  const checkAuth = async () => {
    clearTimeout(reloadLogs);
    reloadLogs = setTimeout(async () => {
      if (!auth?.user) {
        history.push('/login');
      } else {
        checkAuth();
      }
    }, 500);
  };

  const onSubmit = async () => {
    setProgressing(true);
    await auth.updateAcceptedTermsVer(auth.user?.acceptedTosVer as string, auth.user?.acceptedPpVer as string);
    if (auth.state === 'error') {
      ErrorToast(auth.errorMessage!);
    } else {
      onClose();
    }
    setProgressing(false);
  };

  return (
    <Dialog
      title={lang(t, 'login.acceptTerms.title')}
      isOpen={isOpen}
      onClose={onClose}
      isCloseButtonShown={false}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      className={styles.dialog}
      backdropClassName={styles.dialogBackDrop}
    >
      <div className={cx(Classes.DIALOG_BODY, styles.dialogBody)}>
        {lang(t, 'login.acceptTerms.body')}
        <FormGroup className={cx(styles.formGroupMargin)}>
          <label className={cx(Classes.CONTROL, Classes.CHECKBOX)}>
            <input type="checkbox" onChange={() => setAgreePolicy(!agreePolicy)} />
            <span className={Classes.CONTROL_INDICATOR} />
            <span
              dangerouslySetInnerHTML={{
                __html: `${lang(t, `login.agree`, {
                  termsHref: `${mxConfig.homePage}terms`,
                  policyHref: `${mxConfig.homePage}privacy`,
                })}`,
              }}
            />
          </label>
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            type="button"
            text={t('topNav.logout')}
            onClick={async () => {
              await auth.logout();
              checkAuth();
            }}
          />
          <SubmitButton
            type="submit"
            onClick={onSubmit}
            intent={Intent.PRIMARY}
            disabled={!agreePolicy || auth.state === 'pending'}
            loading={progressing}
          >
            {lang(t, 'login.acceptTerms.agreeButton')}
          </SubmitButton>
        </div>
      </div>
    </Dialog>
  );
};

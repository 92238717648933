import React from 'react';
import { PageLayout, DetailBox } from 'components';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { ReactComponent as NoDataIcon } from './imgs/noData.svg';
import { observer } from 'mobx-react';
import { lang } from './helper';
import cx from 'classnames';

export const NoData: React.FC = observer((props) => {
  const { t } = useTranslation();

  return (
    <DetailBox>
      <div className={styles.hasNoSummary}>
        <NoDataIcon />
        <h2>{lang(t, `title`)}</h2>
        <p>{lang(t, `noDataDescription`)}</p>
      </div>
    </DetailBox>
  );
});

/**
 * @example '' -> false | a/b -> true | a/b:version -> false
 * @param imageName string or null
 * @returns true or false
 */
export const DockerImageNameCheck = (imageName = '') => {
  const nameAndVersion = imageName.substring(imageName.lastIndexOf('/'), imageName.length) || '';
  return nameAndVersion.indexOf(':') < 0;
};

/**
 * @example At least 8 characters in length, contain at least 3 or 4 of the following types of characters: lower & upper case letters, numbers, special characters
 * @param imageName string
 * @returns true or false
 */
export const checkPasswordFormat = (pw: string) => {
  return !/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![a-z0-9]+$)[a-zA-Z0-9!@#$%^&*\(\)\_\-\+\=\{\}\[\]\<\>\?\<\,\.\/\|\:\;\"\']{8,}$/.test(
    pw,
  );
};

import React, { useState } from 'react';
import { Tab, Tabs } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import styles from '../style.module.scss';
import { lang, parseMetricsDetails } from '../helper';
import { Instance } from 'mobx-state-tree';
import { Node } from '@/store/nodes';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { MetricsTabContent } from './MetricsTabContent';
import { Typography, Input } from 'antd';
import { SubstrateRpcTabContent } from './SubstrateRpcTabContent';

const { Paragraph } = Typography;

interface IProps {
  node: Instance<typeof Node>;
}

export const GeneralApiSection: React.FC<IProps> = observer(({ node }) => {
  const RPCId = 'RPC';
  const { t } = useTranslation();
  const [tabId, setTabId] = useState(RPCId);

  const metricsDetails = parseMetricsDetails(node);

  return (
    <div className={styles.popoverBox}>
      <h2 className={styles.endpointTitle}>{lang(t, 'detailTitle.apiEndpoints')}</h2>
      <div className={styles.detailBoxContent}>
        <Tabs onChange={(tabId: string) => setTabId(tabId)} defaultSelectedTabId={RPCId}>
          <Tab id={RPCId} title={RPCId} />
          {(node.endpoints!['p2p-internal'] || node.endpoints!['p2p']) && <Tab title="P2P" id="P2P" />}
          <Tab id={'Metrics'} title={'Metrics'} />
        </Tabs>
        <SubstrateRpcTabContent
          wsEndpoint={node.endpoints?.ws}
          httpEndpoint={node.endpoints?.rpc}
          active={tabId === 'RPC'}
        />
        {(node.endpoints!['p2p-internal'] || node.endpoints!['p2p']) && (
          <div className={cx({ [styles.hide]: tabId !== 'P2P' })}>
            {node.endpoints!['p2p-internal'] && (
              <>
                <p className={styles.addressTitle}>{lang(t, 'detailTitle.p2p-internal')}</p>
                <Input
                  size={'large'}
                  disabled
                  className={styles.apiKeyInput}
                  suffix={
                    <Paragraph copyable className={styles.copyButton}>
                      {node.endpoints!['p2p-internal']}
                    </Paragraph>
                  }
                  defaultValue={node.endpoints!['p2p-internal']}
                />
              </>
            )}
            {node.endpoints!['p2p'] && (
              <>
                <p className={styles.addressTitle}>{lang(t, 'detailTitle.p2p')}</p>
                <Input
                  size={'large'}
                  disabled
                  className={styles.apiKeyInput}
                  suffix={
                    <Paragraph copyable className={styles.copyButton}>
                      {node.endpoints!['p2p']}
                    </Paragraph>
                  }
                  defaultValue={node.endpoints!['p2p']}
                />
              </>
            )}
          </div>
        )}
        <MetricsTabContent
          visible={tabId === 'Metrics'}
          metricsDetails={metricsDetails}
          endpoint={node.endpoints?.metrics}
          endpointType="metrics"
        />
      </div>
    </div>
  );
});

import { TFunction } from 'i18next';
import { Instance } from 'mobx-state-tree';
import { NodeSpec } from '../../store/info';
import { ReactComponent as AWSIcon } from './img/aws.svg';
import { ReactComponent as AzureIcon } from './img/azure.svg';
import { ReactComponent as GcpIcon } from './img/gcp.svg';

interface ProviderStyleMapType {
  [key: string]: {
    img: any;
    txt: string;
  };
}

export const providerStyleMap: ProviderStyleMapType = {
  aws: {
    img: AWSIcon,
    txt: 'Amazon Web Services',
  },
  gcp: {
    img: GcpIcon,
    txt: 'Google Cloud Platform',
  },
  ma: {
    img: AzureIcon,
    txt: 'Microsoft Azure',
  },
};

export const lang = (t: TFunction, key: string, options?: any) => t(`pages.joinNetwork.${key}`, options);

const unitPrice = 0.095;
const storagePrice = 0.0004;

export function GetHourlyPrice(
  spec: Instance<typeof NodeSpec> | undefined,
  storage = 10,
  multiplier = 1,
  isPartner = false,
) {
  if (!spec) {
    return 'N/A';
  }

  if (isPartner && spec.partnerNodePrice && spec.partnerStoragePrice) {
    return (multiplier * spec.partnerNodePrice + storage * spec.partnerStoragePrice).toFixed(2) + ' per hour';
  }
  return (multiplier * unitPrice + storagePrice * storage).toFixed(2) + ' per hour';
}

import { ErrorToast, SuccessToast } from '@/components';
import { ConfirmDialogProps } from '@/components/ConfirmDialog';
import { useStore } from '@/store';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface AppSettingHook {
  tryRegenApiKey: () => void;
  tryDeleteApp: () => void;
  confirmDialogProps: ConfirmDialogProps;
  apiKey?: string;
}

type DialogType = 'regenerate-key' | 'delete-app';

const useAppSettings = (wsId: string, appId: string, toastTimeout = 5000): AppSettingHook => {
  const { apiService } = useStore();
  const { t } = useTranslation();
  const { replace } = useHistory();
  const lang = (key: string, opt?: any) => t(`pages.apiServices.settingsPage.${key}`, opt);

  const [showDialog, setShowDialog] = useState(false);
  const [dialogBusy, setDialogBusy] = useState(false);
  const [dialogType, setDialogType] = useState<DialogType>('regenerate-key');
  const [newKey, setNewKey] = useState<string>();

  const appName = useMemo(() => apiService.app?.displayName, [apiService.app]);
  const apiKey = useMemo(() => newKey || apiService.app?.apiKey, [apiService.app, newKey]);

  const showConfirmDialog = (type: DialogType) => {
    setDialogType(type);
    setShowDialog(true);
  };

  const doRegeneratingKey = async () => {
    try {
      setDialogBusy(true);
      await apiService.regenerate(wsId, appId);
      if (apiService.state === 'error') {
        throw new Error();
      }
      SuccessToast(t('general.success'), toastTimeout);
      setShowDialog(false);
      setNewKey(apiService.key || undefined);
    } catch {
      ErrorToast(apiService.errorMessage || t('general.error'), toastTimeout);
    } finally {
      setDialogBusy(false);
    }
  };

  const doDeleteApp = async () => {
    try {
      setDialogBusy(true);
      await apiService.deleteApp(wsId, appId);
      if (apiService.state === 'error') {
        throw new Error();
      }
      SuccessToast(lang('dialog.delete-app.successMessage', { appName }), toastTimeout);
      // Redirect
      replace(`/workspaces/${wsId}/apiservices`);
    } catch {
      ErrorToast(apiService.errorMessage || t('general.error'), toastTimeout);
    } finally {
      setDialogBusy(false);
    }
  };

  const confirmDialogProps = useMemo<ConfirmDialogProps>(() => {
    const langPrefix = `dialog.${dialogType}`;
    const props: ConfirmDialogProps = {
      title: lang(`${langPrefix}.title`),
      content: lang(`${langPrefix}.content`, { appName }),
      confirm: lang(`${langPrefix}.confirm`),
      onConfirm: () => {},
      cancel: lang(`${langPrefix}.cancel`),
      onCancel: () => {
        setShowDialog(false);
      },
      isBusy: dialogBusy,
      isOpen: showDialog,
    };

    switch (dialogType) {
      case 'regenerate-key':
        props.onConfirm = doRegeneratingKey;
        break;
      case 'delete-app':
        props.isDanger = true;
        props.onConfirm = doDeleteApp;
        break;
    }
    return props;
  }, [dialogType, dialogBusy, showDialog, appName]);

  const tryRegenApiKey = () => showConfirmDialog('regenerate-key');
  const tryDeleteApp = () => showConfirmDialog('delete-app');

  return {
    tryDeleteApp,
    tryRegenApiKey,
    confirmDialogProps,
    apiKey,
  };
};

export { useAppSettings };

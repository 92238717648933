import React, { useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Carousel, Button } from 'antd';
import cx from 'classnames';
import styles from './style.module.scss';
import { NetworkListItem, NetworkSelectionProps } from './';
import { getPageItems, getPages } from './hook';
// import NoAnalyticsData from '../NoAnalyticsData';
const NetworkSelectionCarousel: React.FC<NetworkSelectionProps> = observer(
  ({ networkSelection, renderCardTile, noDataHeading, noDataSubheading, onToggleExpand, showExpanded }) => {
    const { activeNetworks, pageSize } = networkSelection;
    const pages = useMemo(
      () => getPages(activeNetworks, pageSize, showExpanded),
      [activeNetworks, pageSize, showExpanded],
    );
    const hasNetworks = useMemo(() => activeNetworks.length > 0, [activeNetworks]);
    return hasNetworks ? (
      <Carousel swipeToSlide draggable className={styles.networkSelectionCarousel}>
        {pages.map((pageIndex) => (
          <div
            className={cx({ 'network-page': true, 'carousel-page': true, 'single-page': pages.length <= 1 })}
            key={pageIndex}
          >
            {getPageItems(activeNetworks, pageSize, pageIndex).map((item: NetworkListItem, index: number) => {
              return renderCardTile(item, index);
            })}
          </div>
        ))}
      </Carousel>
    ) : null;
    // <NoAnalyticsData heading={noDataHeading} subHeading={noDataSubheading} />
  },
);

export default NetworkSelectionCarousel;

import styles from '../style.module.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../store';
import { useTranslation } from 'react-i18next';
import { lang, PanelProps } from '../helper';

export const EthPanel: React.FC<PanelProps> = observer(({ nodes }) => {
  const { t } = useTranslation();
  const sync_current = nodes?.getMetric('sync_current');
  return (
    <ul className={styles.capsule}>
      <li>
        <div className={styles.mainCap}>
          <div className={styles.th}>{lang(t, 'detailTitle.lastBlock')}</div>
          <div className={styles.para}>{nodes?.getMetric('blocknumber', false)?.toLocaleString() ?? '--'}</div>
        </div>
      </li>
      <li>
        <div className={styles.mainCap}>
          <div className={styles.th}>{lang(t, 'detailTitle.peerCount')}</div>
          <div className={styles.para}>{nodes?.getMetric('peer_count', false)?.toLocaleString() ?? '--'}</div>
        </div>
      </li>
      <li>
        <div className={styles.mainCap}>
          <div className={styles.th}>{lang(t, 'detailTitle.pendingTxCount')}</div>
          <div className={styles.para}>
            {nodes?.getMetric('pending_block_transactions', false)?.toLocaleString() ?? '--'}
          </div>
        </div>
      </li>
      <li>
        <div className={styles.mainCap}>
          <div className={styles.th}>{lang(t, 'detailTitle.syncing')}</div>
          <div className={styles.para}>{sync_current ? '--' : sync_current ? 'Syncing' : 'Synced'}</div>
        </div>
      </li>
    </ul>
  );
});

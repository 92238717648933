import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { lang } from './helpers';
import styles from './style.module.scss';
import cx from 'classnames';
import { boolean } from 'mobx-state-tree/dist/internal';

interface IProps {
  step?: number;
  actived: {
    [key: string]: boolean;
  };
  onStepChange: (step: number) => void;
  onGoback: () => void;
}

export const Steps: React.FC<IProps> = observer(({ step = 1, actived = {}, onStepChange, onGoback }) => {
  const { t } = useTranslation();
  const additional = Object.keys(actived);
  return (
    <div className={styles.steps}>
      <ol>
        <li
          className={styles.arrive}
          onClick={() => {
            onGoback();
          }}
        >
          <span className={styles.step}>1</span>
          <span className={styles.stepName}>{lang(t, `step${1}`)}</span>
        </li>
        {additional.map((i, index) => (
          <li
            key={i}
            onClick={() => {
              onStepChange(index);
            }}
            className={cx({
              [styles.current]: step === index + 1,
              [styles.arrive]: step >= index + 1,
              [styles.actived]: !!actived[additional[index]],
            })}
          >
            <span className={styles.step}>{index + 2}</span>
            <span className={styles.stepName}>{`${i} ${lang(t, `step2`)}`}</span>
          </li>
        ))}
        <li>
          <span className={styles.step}>{additional.length + 2}</span>
          <span>{lang(t, `step0`)}</span>
        </li>
      </ol>
    </div>
  );
});

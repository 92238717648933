import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import styles from './style.module.scss';

export const Paginate: React.FC<ReactPaginateProps> = (props) => {
  return (
    <div className={styles.paginate}>
      <ReactPaginate {...props} />
    </div>
  );
};

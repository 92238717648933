import React from 'react';
import { INetworkSpec } from '../../store/networks';
import acalaSmall from './img/acala-1.png';
import acalaLarge from './img/acala-2.png';
import acalaIcon from './img/acala.png';
import bifrostIcon from './img/bifrost-icon.png';
import bifrostLogo from './img/bifrost.png';
import centralitySmall from './img/centrality-1.png';
import centralityLarge from './img/centrality-2.png';
import centralityIcon from './img/centrality.png';
import centrifugeSmall from './img/centrifuge-1.png';
import centrifugeLarge from './img/centrifuge-2.png';
import centrifugeIcon from './img/centrifuge.png';
import darwiniaIcon from './img/darwinia-0.png';
import darwiniaWithName from './img/darwinia-1.png';
import edgewareSmall from './img/edgeware-1.png';
import edgewareLarge from './img/edgeware-2.png';
import edgewareIcon from './img/edgeware.png';
import ethereumSmall from './img/ethereum-1.png';
import ethereumLarge from './img/ethereum-2.png';
import ethereumIcon from './img/ethereum.png';
import kusamaSmall from './img/kusama-1.png';
import kusamaLarge from './img/kusama-2.png';
import kusamaIcon from './img/kusama.png';
import moonbeamLogo from './img/moonbeam-1.png';
import moonbeamLogo2 from './img/moonbeam-2.png';
import plasmaIcon from './img/plasm-grey-1.png';
import plasmaWithName from './img/plasm-grey.png';
import polkadotSmall from './img/polkadot-1.png';
import polkadotLarge from './img/polkadot-2.png';
import polkadotIcon from './img/polkadot.png';
import rococoText from './img/rococo-1.png';
import rococoIcon from './img/rococo.png';
import substrateIcon from './img/substrate.png';
import styles from './style.module.scss';

export enum NetworkImgType {
  Icon = 'icon',
  Small = 'small',
  Large = 'large',
}

interface iconStyle {
  [name: string]: {
    [name: string]: string;
  };
}

interface IProps {
  network?: INetworkSpec;
  name?: string;
  type: NetworkImgType;
}

const iconMap: iconStyle = {
  polkadot: {
    [NetworkImgType.Icon]: polkadotIcon,
    [NetworkImgType.Small]: polkadotSmall,
    [NetworkImgType.Large]: polkadotLarge,
  },
  kusama: {
    [NetworkImgType.Icon]: kusamaIcon,
    [NetworkImgType.Small]: kusamaSmall,
    [NetworkImgType.Large]: kusamaLarge,
  },
  ethereum: {
    [NetworkImgType.Icon]: ethereumIcon,
    [NetworkImgType.Small]: ethereumSmall,
    [NetworkImgType.Large]: ethereumLarge,
  },
  acala: {
    [NetworkImgType.Icon]: acalaIcon,
    [NetworkImgType.Small]: acalaSmall,
    [NetworkImgType.Large]: acalaLarge,
  },
  'acala-mandala': {
    [NetworkImgType.Icon]: acalaIcon,
    [NetworkImgType.Small]: acalaSmall,
    [NetworkImgType.Large]: acalaLarge,
  },
  cennznet: {
    [NetworkImgType.Icon]: centralityIcon,
    [NetworkImgType.Small]: centralitySmall,
    [NetworkImgType.Large]: centralityLarge,
  },
  substrate: {
    [NetworkImgType.Icon]: substrateIcon,
    [NetworkImgType.Small]: substrateIcon,
    [NetworkImgType.Large]: substrateIcon,
  },
  'darwania-crab': {
    [NetworkImgType.Icon]: darwiniaIcon,
    [NetworkImgType.Small]: darwiniaWithName,
    [NetworkImgType.Large]: darwiniaWithName,
  },
  'plasm-mainnet': {
    [NetworkImgType.Icon]: plasmaIcon,
    [NetworkImgType.Small]: plasmaIcon,
    [NetworkImgType.Large]: plasmaWithName,
  },
  'bifrost-asgard': {
    [NetworkImgType.Icon]: bifrostIcon,
    [NetworkImgType.Small]: bifrostLogo,
    [NetworkImgType.Large]: bifrostLogo,
  },
  edgeware: {
    [NetworkImgType.Icon]: edgewareIcon,
    [NetworkImgType.Small]: edgewareSmall,
    [NetworkImgType.Large]: edgewareLarge,
  },
  centrifuge: {
    [NetworkImgType.Icon]: centrifugeIcon,
    [NetworkImgType.Small]: centrifugeSmall,
    [NetworkImgType.Large]: centrifugeLarge,
  },
  'polkadot-rococo': {
    [NetworkImgType.Icon]: rococoIcon,
    [NetworkImgType.Small]: rococoText,
    [NetworkImgType.Large]: rococoText,
  },
  rococo: {
    [NetworkImgType.Icon]: rococoIcon,
    [NetworkImgType.Small]: rococoText,
    [NetworkImgType.Large]: rococoText,
  },
  moonbeam: {
    [NetworkImgType.Icon]: moonbeamLogo2,
    [NetworkImgType.Small]: moonbeamLogo,
    [NetworkImgType.Large]: moonbeamLogo,
  },
  'moonbeam-alpha': {
    [NetworkImgType.Icon]: moonbeamLogo2,
    [NetworkImgType.Small]: moonbeamLogo,
    [NetworkImgType.Large]: moonbeamLogo,
  },
};

function getNetworkIcon(network: INetworkSpec, type: NetworkImgType): string | undefined {
  if (iconMap[network.key]) {
    return iconMap[network.key][type];
  } else if (iconMap[network.protocolKey]) {
    return iconMap[network.protocolKey][type];
  }
}

function getClass(type: NetworkImgType) {
  switch (type) {
    case NetworkImgType.Icon:
      return styles.icon;
    case NetworkImgType.Small:
      return styles.small;
    case NetworkImgType.Large:
      return styles.large;
    default:
      return '';
  }
}

export const NetworkSpecIcon: React.FC<IProps> = ({ network, name, type }) => {
  if (name && iconMap[name]) return <img alt={name} className={getClass(type)} src={iconMap[name][type]} />;
  if (!network) return null;
  const iconSrc = getNetworkIcon(network, type);
  if (!iconSrc) return null;
  return <img alt={network.name} className={getClass(type)} src={iconSrc} />;
};

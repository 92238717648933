import { Alert, Classes, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { TFunction } from 'i18next';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteConfigComponentProps } from 'react-router-config';
import {
  FullscreenOutlined as ExpandIcon,
  FullscreenExitOutlined as CollapseIcon,
  FilterOutlined as FilterIcon,
  CopyOutlined,
  DownOutlined,
  UpOutlined,
  LoadingOutlined,
  LockOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import cx from 'classnames';
import {
  Back,
  PageLayout,
  FixedMainHeader,
  Loader,
  MainBox,
  DetailBox,
  AntdRow,
  AntdCol,
  NetworkSelection,
  DatePicker,
  SuccessToast,
  Logo,
} from 'components';
import { Button, Row, Col, Tag, Tabs, Select, Input, Tooltip } from 'antd';
import { useStore } from '../../store';
import styles from './style.module.scss';
import { Iservice } from '../../store/apiService';
import { Instance } from 'mobx-state-tree';
import { EnvironmentMap } from '../ApiServices/createApp';
import ApiKeyPane from './ApiKeyPane';
import cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router-dom';
import { DefaultOptionType } from 'antd/lib/select';
import { durationToHourCount } from '@/utils/tools';
import ResponseTimeChartNew from '@/components/Report/ResponseTimeChartNew';
import ApiRequestChartNew from '@/components/Report/ApiRequestChartNew';
import MethodStatsTable from '@/components/Report/MethodStatsTable';
import { SearchOutlined } from '@ant-design/icons';
import { RangeValue } from 'rc-picker/lib/interface';
import dayjs, { Dayjs } from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import { ReactComponent as NoData } from './imgs/nodataicon.svg';
import { ReactComponent as ExternalLink } from './imgs/externalLink.svg';
import { ReactComponent as InfoCircle } from './imgs/InfoCircle.svg';
import _ from 'lodash';
import { generateHttpsUrl } from './help';
import { GeolocationMap, MapColumn } from '@/modules';
import { sendEvent } from '@/utils/googleAnalytics';
import { INetworkSpec, NetworkSpecStatus } from '@/store/networks';
import { ApiService } from '@/store/apiService';

dayjs.extend(dayjsPluginUTC as any, { parseToLocal: true });
const { Option } = Select;
const { RangePicker } = DatePicker;

export interface Network extends Instance<typeof Iservice> {}

type IProps = RouteConfigComponentProps<{ wsId: string; appId: string }>;

const { TabPane } = Tabs;

const reportDuration = ['24h', '7d', '30d'];

const dateForm = 'YYYY-MM-DD';
const apiDataForm = 'YYYY-MM-DD';

interface resourcesLinkDetail {
  title: string;
  link: string;
}

export interface Urls {
  httpsUrl: string;
  wssUrl: string;
}

export const ApiServicesDetail: React.FC<IProps> = observer((props) => {
  const { match, history } = props;
  const { wsId, appId } = match.params;
  const { apiService, workspaces, networks, networkSpec } = useStore();
  const [loadingMethodStats, setLoadingMethodStats] = useState(false);
  const [loadingMethodSummary, setLoadingMethodSummary] = useState(false);
  const [loadingNetworkSummary, setLoadingNetworkSummary] = useState(false);
  const [country, setCountry] = useState('');
  const [showMap, setShowMap] = useState(false);

  const endDate = dayjs.utc().format(apiDataForm);
  const weekBefore = dayjs(endDate).subtract(8, 'day');
  const monthBefore = dayjs(endDate).subtract(30, 'day');
  const today = dayjs(endDate);
  const yesterday = dayjs(today).subtract(1, 'day');

  const endDayjs = dayjs.utc().startOf('h');
  const hoursAgo = endDayjs.subtract(24, 'hour');

  const [dataRange, setDataRange] = useState<[Dayjs, Dayjs]>([hoursAgo, endDayjs]);
  const [dates, setDates] = useState<RangeValue<dayjs.Dayjs>>(null);
  const [hackValue, setHackValue] = useState<any>(null);

  const twentyFourthHoursAgo: boolean =
    dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf();
  const [usageOpt, setUsageOpt] = useState<{
    duration: string;
    displayName: string;
    subDomain: string;
    icon: string | undefined;
    method?: string[];
    protocolKey: string;
  }>({
    duration: reportDuration[0],
    displayName: '',
    subDomain: '',
    icon: '',
    protocolKey: '',
  });
  const [showUpgrade, setShowUpgrade] = useState(false);
  const { t } = useTranslation();
  const lang = (t: TFunction, key: string, opt?: any) => t(`pages.apiServices.${key}`, opt);
  const [services, setServices] = useState<Array<Network>>([]);
  const [networkSelectionExpand, setNetworkSelectionExpand] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchName, setSearchName] = useState('');
  const searchInputRef = useRef(null);
  const [junoTabkey, setJunoTabKey] = useState<string>('Juno');
  const [linkList, setLinkList] = useState<resourcesLinkDetail[]>([]);
  const [apiKeyPaneOpen, setApiKeyPaneOpen] = useState(false);
  const [networkDetail, setNetworkDetail] = useState<INetworkSpec>();
  const [loadingTag, setLoadingTag] = useState<boolean>(true);
  const hasActiveNetworks = useMemo(
    () =>
      services.filter((x) => (x.count || 0) > 0).length > 0 || localStorage.getItem('selectedNetwork' + appId) !== null,
    [services],
  );

  const savedMethods = apiService.savedMethods();
  const methodSelectOptions = useMemo<DefaultOptionType[]>(
    () =>
      savedMethods.map((x) => ({
        label: x || 'UNKNOWN',
        value: x,
      })),
    [savedMethods],
  );

  const methodSelectOptionElements = useMemo(
    () =>
      methodSelectOptions.map((x) => (
        <Option key={x.value} className={x.value || styles.italicText}>
          {x.label}
        </Option>
      )),
    [methodSelectOptions],
  );

  const durationHours = useMemo(() => durationToHourCount(usageOpt.duration), [usageOpt.duration]);
  const hasMethodSelected = useMemo(
    () => usageOpt.method && usageOpt.method.filter((x) => x !== undefined).length > 0,
    [usageOpt.method],
  );
  const waitTime = 300;

  const changeSearch = _.debounce((e: string) => {
    setSearchName(e.trim());
  }, waitTime);

  const getApiTypeTags = (networkTypes: string[]) => {
    const traceLockTitle = (
      <div className={styles.nodeTypesTooltip}>
        <p className={styles.pText}>
          Trace API helps web3 teams trace and monitor the behaviour of their applications, enabling developers to trace
          the re-execution of a single block.{' '}
          <a target="_blank" href="https://documentation.onfinality.io/support/trace-api">
            Read More
          </a>
        </p>
        {workspaces.current?.plan === 'developer' ? (
          <p className={styles.pText}>
            To use the Trace API, you must be on the Growth or Ultimate plan. Upgrade your plan for access.
          </p>
        ) : (
          ''
        )}
        {workspaces.current?.plan === 'developer' ? (
          <div className={styles.upgradeBtn}>
            <Button
              onClick={() => {
                history.push(`/workspaces/${wsId}/settings/plan`);
              }}
              type="primary"
            >
              Upgrade
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
    );
    return (
      <div>
        {networkTypes.map((e) => {
          switch (e) {
            case 'archive':
              return <Tag>Archive</Tag>;
            case 'trace':
              return (
                <Tooltip
                  overlayStyle={{ color: '#262626', backgroundColor: '#fff' }}
                  title={traceLockTitle}
                  placement="topLeft"
                  className={styles.tabTooltip}
                >
                  <Tag>
                    Trace API {workspaces.current?.plan === 'developer' ? <LockOutlined /> : <InfoCircleOutlined />}
                  </Tag>
                </Tooltip>
              );
          }
        })}
      </div>
    );
  };

  const getApiStatusTag = (key: string) => {
    const currentApi = apiService.services.find((api) => api.key === key);
    if (currentApi && currentApi.status === NetworkSpecStatus.Hidden) {
      return <Tag style={{ color: 'blue' }}>Private</Tag>;
    }
    return null;
  };

  useEffect(() => {
    return () => {
      apiService.clearUsage();
    };
  }, []);

  // This effect is for initial page load,
  // We need to load all the services first, then the counts afterward
  useEffect(() => {
    (async () => {
      setLoading(true);
      // Must get all service first
      await apiService.getApiServices(wsId);
      // Update the local state
      setServices(cloneDeep(apiService.services));
      if (appId) {
        // Need to get App
        await apiService.getAppsById(wsId, appId);
        // Update local state with app with count
        setServices(apiService.servicesWithCount());
      } else {
        await apiService.getKey(wsId);
      }
      setLoading(false);
    })();
  }, [wsId, appId]);

  useEffect(() => {
    (async () => {
      if (apiService.key) {
        await apiService.getLimit(wsId);
      }
    })();
  }, [apiService.key]);

  useEffect(() => {
    (async () => {
      setShowMap(false);
      const { subDomain, duration } = usageOpt;
      if (!subDomain || !duration) return;
      setLoadingMethodSummary(true);
      setLoadingNetworkSummary(true);
      apiService
        .getMethodStatsSummary(
          wsId,
          appId,
          subDomain,
          '',
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[0]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[1]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
        )
        .finally(() => setLoadingMethodSummary(false));

      networkSpec
        .fetchRequestByCountry(
          subDomain,
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[0]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[1]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
          workspaces.current!.id,
          appId,
        )
        .finally(() => setShowMap(true));
      apiService
        .getNetworkStats(
          wsId,
          appId,
          subDomain,
          '',
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[0]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[1]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
        )
        .finally(() => setLoadingNetworkSummary(false));
    })();
  }, [usageOpt.subDomain, dataRange]);

  useEffect(() => {
    (async () => {
      const { subDomain, duration, method } = usageOpt;
      if (!subDomain || !duration) return;
      setLoadingMethodStats(true);
      const promises = [
        apiService.getMethodStatsHourly(
          wsId,
          appId,
          subDomain,
          '',
          method,
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[0]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
          dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
            ? `${dayjs(dataRange[1]).format('YYYY-MM-DD HH')}:00:00`
            : `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
        ),
      ];
      // Load network stats if no method selected
      if (!hasMethodSelected) {
        promises.push(
          apiService.getNetworkStats(
            wsId,
            appId,
            subDomain,
            '',
            dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
              ? `${dayjs(dataRange[0]).format('YYYY-MM-DD HH')}:00:00`
              : `${dayjs(dataRange[0]).format(apiDataForm)} 00:00:00`,
            dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
              ? `${dayjs(dataRange[1]).format('YYYY-MM-DD HH')}:00:00`
              : `${dayjs(dataRange[1]).format(apiDataForm)} 23:59:59`,
          ),
        );
      }
      Promise.all(promises).finally(() => setLoadingMethodStats(false));
    })();
  }, [usageOpt.subDomain, usageOpt.method, dataRange]);

  // Default expand the network selection if no active requests
  useEffect(() => {
    setNetworkSelectionExpand(!hasActiveNetworks);
  }, [hasActiveNetworks]);

  useEffect(() => {
    setSearchName('');
    searchInputRef.current &&
      //@ts-ignore
      searchInputRef.current!.focus({
        cursor: 'start',
      });
  }, [networkSelectionExpand]);

  useEffect(() => {
    (async () => {
      setLoadingTag(true);
      const { subDomain } = usageOpt;
      if (!subDomain) return;
      const networkDetail = await networks.fetchNetworkDetailForApiService(subDomain);
      setNetworkDetail(networkDetail);
      setLinkList(networkDetail?.links || []);
      setLoadingTag(false);
    })();
  }, [usageOpt.subDomain]);

  if (apiService.key === null) return <Loader isLoading={true} />;

  const handleNetworkSelected = (key: string) => {
    const currentValue = services.find((i) => i.subDomain === key) as Network;
    if (!currentValue) {
      return;
    }
    setUsageOpt({
      ...usageOpt,
      subDomain: currentValue.subDomain,
      displayName: currentValue.displayName,
      icon: currentValue.metadata?.pictures?.icon,
      protocolKey: currentValue.protocolKey,
    });
  };

  if (workspaces.current?.suspendTaskDone) {
    history.push(`/workspaces/${wsId}/apiservices`);
  }

  let urls: Urls = generateHttpsUrl(usageOpt.protocolKey, usageOpt.subDomain, apiService.key, false);

  const changeNetwork = (num: number) => {
    setApiKeyPaneOpen(!(num > 0));
  };

  let curlCommand: string;
  if (['polkadot', 'polkadot-parachain', 'substrate'].includes(usageOpt.protocolKey)) {
    curlCommand = `curl -H "Content-Type: application/json" -d '{"id":1, "jsonrpc":"2.0", "method": "chain_getBlock"}' '${urls.httpsUrl}'`;
  } else if (usageOpt.protocolKey == 'juno') {
    if (junoTabkey === 'Juno') {
      curlCommand = `curl -H "Content-Type: application/json" -d '{"id":1, "jsonrpc":"2.0", "method": "status"}' '${urls.httpsUrl}'`;
    } else if (junoTabkey === 'Cosmos') {
      curlCommand = `curl 'https://juno.api.onfinality.io/rpc/cosmos/auth/v1beta1/accounts?apikey=${apiService.key}'`;
    } else {
      curlCommand = 'Coming soon';
    }
  } else if (usageOpt.protocolKey == 'avalanche') {
    curlCommand = `curl -H 'content-type:application/json' -d '{"id": 1, "jsonrpc": "2.0", "method": "platform.getHeight"}' '${urls.httpsUrl}'`;
  } else if (usageOpt.protocolKey == 'ethereum' || usageOpt.protocolKey == 'cosmos') {
    curlCommand = `curl --location --request POST '${urls.httpsUrl}' --header 'content-type:application/json' --data-raw '{"id": 1, "jsonrpc": "2.0", "method": "eth_blockNumber", "params": []}'`;
  } else {
    curlCommand = 'Coming soon';
  }

  const titleText = `Get connected to ${usageOpt.displayName} and make your first requests`;

  return (
    <PageLayout>
      <FixedMainHeader>
        <AntdRow className={styles.row}>
          <AntdCol span={6}>
            <Back link={`/workspaces/${wsId}/apiservices`} text={lang(t, 'title')} />
          </AntdCol>
          <AntdCol span={18} alignRight>
            <Link to={`${appId}/settings`}>
              <Button type="primary">{lang(t, 'btnSettings')}</Button>
            </Link>
          </AntdCol>
        </AntdRow>
      </FixedMainHeader>
      <MainBox>
        <div className={cx({ [styles.main]: true, [styles.makeDistance]: true })}>
          {appId && apiService.app && (
            <div className={styles.mb16}>
              <div>
                <span className={styles.appName}>{apiService.app.displayName}</span>
                <span className={styles.mr8}>{EnvironmentMap[apiService.app.labels.environment].icon}</span>
                {EnvironmentMap[apiService.app.labels.environment].label}
              </div>
            </div>
          )}
          {/* Network selections */}
          <DetailBox className={styles.detailBox}>
            <Row>
              <Col span={12}>
                <h3 className={cx(styles.h3Width)}>
                  {networkSelectionExpand
                    ? lang(t, 'networkSelection.heading')
                    : lang(t, 'networkSelection.focusHeading')}
                </h3>
                {networkSelectionExpand && (
                  <Input
                    prefix={<SearchOutlined className={styles.searchPrefix} />}
                    placeholder={'Search'}
                    className={styles.inlineInput}
                    onChange={(e) => {
                      changeSearch(e.target.value);
                    }}
                    allowClear
                    ref={searchInputRef}
                  />
                )}
              </Col>
              <Col span={12} className={styles.alignRight}>
                <Button
                  className="hover link-button"
                  icon={networkSelectionExpand ? <CollapseIcon /> : <ExpandIcon />}
                  type="link"
                  size={'large'}
                  onClick={() => {
                    setNetworkSelectionExpand(!networkSelectionExpand);
                  }}
                >
                  {lang(t, networkSelectionExpand ? 'networkSelection.collapse' : 'networkSelection.expand')}
                </Button>
              </Col>
            </Row>
            {loading ? (
              <div className={styles.networkSelectionLoader}>
                <Loader isLoading={true} padding={false} />
              </div>
            ) : (
              <NetworkSelection
                allNetworks={services}
                networkPerPage={7}
                searchName={searchName}
                onSelected={handleNetworkSelected}
                expanded={networkSelectionExpand}
                onToggleExpand={(expand) => setNetworkSelectionExpand(expand)}
                appId={appId}
                setCount={changeNetwork}
              />
            )}
          </DetailBox>

          <DetailBox className={cx(styles.detailBox)}>
            <Row
              className={styles.pointer}
              onClick={() => {
                setApiKeyPaneOpen(!apiKeyPaneOpen);
              }}
            >
              <Col span={12}>
                <div className={styles.apiKeyPaneHeader}>
                  <Logo size={24} src={usageOpt.icon} />
                  <span className={styles.fontSize16}>{usageOpt.displayName} Endpoints</span>
                  {/* get them back later */}
                  {loadingTag ? (
                    <LoadingOutlined className={styles.loadingIcon} />
                  ) : (
                    <>
                      {networkDetail !== undefined && networkDetail.type && getApiTypeTags(networkDetail.type)}
                      {networkDetail !== undefined &&
                        networkDetail.status === NetworkSpecStatus.Hidden &&
                        getApiStatusTag(networkDetail.key)}
                    </>
                  )}
                </div>
              </Col>
              <Col span={12} className={styles.alignRight}>
                <div>{apiKeyPaneOpen ? <UpOutlined /> : <DownOutlined />}</div>
              </Col>
            </Row>
            {apiKeyPaneOpen ? (
              <ApiKeyPane
                hideHeader={true}
                subDomain={usageOpt.subDomain}
                apiKey={apiService.key}
                protocolKey={usageOpt.protocolKey}
                suffix={usageOpt.displayName}
              />
            ) : (
              <></>
            )}
          </DetailBox>

          <Tabs defaultActiveKey="overview" className={styles.tabs}>
            <TabPane tab={lang(t, 'tabInsights')} key="overview">
              <div className={styles.overviewTabHeader}>
                <h3 className={Classes.HEADING}>{usageOpt.displayName}</h3>
                <div>
                  <RangePicker
                    defaultValue={dataRange}
                    value={hackValue || dataRange}
                    format={dateForm}
                    onChange={(data: any) => {
                      data && setDataRange(data);
                    }}
                    disabledDate={(current) => {
                      if (!dates) {
                        return false;
                      }
                      const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
                      const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
                      return !!tooEarly || !!tooLate || current.diff(today.startOf('day'), 'day') > 0;
                    }}
                    onCalendarChange={(val) => setDates(val)}
                    onOpenChange={(open) => {
                      if (open) {
                        setHackValue([null, null]);
                        setDates([null, null]);
                      } else {
                        setHackValue(null);
                      }
                    }}
                    ranges={{
                      [lang(t, `dateRangeExtra.pass24Hours`)]: [hoursAgo, endDayjs],
                      [lang(t, `dateRangeExtra.pastWeek`)]: [weekBefore, yesterday],
                      [lang(t, `dateRangeExtra.pastMonth`)]: [monthBefore, yesterday],
                    }}
                  />
                  <div className={styles.utcTime}>
                    {twentyFourthHoursAgo ? lang(t, 'dateRangeExtra.utcTime24H') : lang(t, 'dateRangeExtra.utcTime')}
                  </div>
                </div>
              </div>
              {/* Daily count chart */}
              {!loading && (
                <DetailBox className={styles.detailBox}>
                  <Row className={styles.chartHeader}>
                    <Col span={16}>
                      <h3 className={Classes.HEADING}>
                        {lang(t, 'apiRequestsDiagram')}
                        {twentyFourthHoursAgo && (
                          <span className="duration">({lang(t, `dateRangeExtra.pass24Hours`)})</span>
                        )}
                      </h3>
                    </Col>
                    {dataRange[0].diff(dataRange[1], 'day') > -3 && (
                      <Col span={8}>
                        <Select
                          showSearch
                          allowClear
                          suffixIcon={<FilterIcon />}
                          value={usageOpt.method}
                          placeholder={lang(t, 'apiResponseFilter')}
                          onChange={(method) => {
                            setUsageOpt({
                              ...usageOpt,
                              method: Array.isArray(method) ? method : [method],
                            });
                          }}
                          className={usageOpt.method?.at(0) || styles.italicText}
                        >
                          {methodSelectOptionElements}
                        </Select>
                      </Col>
                    )}
                  </Row>
                  <ApiRequestChartNew
                    chartData={hasMethodSelected ? apiService.methodStatsMap() : apiService.networkStatsMap()}
                    errorData={hasMethodSelected ? apiService.methodErrorMap() : apiService.networkErrorsMap()}
                    loading={loadingNetworkSummary}
                    duration={
                      dataRange[0].valueOf() === dataRange[1].valueOf() ||
                      (dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf())
                    }
                    transFn={(key) => lang(t, key)}
                    startTime={dataRange[0]}
                    endTime={dataRange[1]}
                    twentyFourH={twentyFourthHoursAgo}
                  />
                </DetailBox>
              )}
              {/* Method stats chart */}
              {!loading && durationHours <= 24 && (
                <DetailBox className={styles.detailBox}>
                  <Row className={styles.chartHeader}>
                    <Col span={16}>
                      <h3 className={Classes.HEADING}>
                        {lang(t, 'apiResponseDiagram')}
                        {twentyFourthHoursAgo && (
                          <span className="duration">({lang(t, `dateRangeExtra.pass24Hours`)})</span>
                        )}
                      </h3>
                    </Col>
                    {dataRange[0].diff(dataRange[1], 'day') > -3 && (
                      <Col span={8}>
                        <Select
                          showSearch
                          allowClear
                          suffixIcon={<FilterIcon />}
                          value={usageOpt.method}
                          placeholder={lang(t, 'apiResponseFilter')}
                          onChange={(method) => {
                            setUsageOpt({
                              ...usageOpt,
                              method: Array.isArray(method) ? method : [method],
                            });
                          }}
                          className={usageOpt.method?.at(0) || styles.italicText}
                        >
                          {methodSelectOptionElements}
                        </Select>
                      </Col>
                    )}
                  </Row>
                  {dataRange[0].diff(dataRange[1], 'day') > -3 ? (
                    <ResponseTimeChartNew
                      chartData={hasMethodSelected ? apiService.methodStatsMap() : apiService.networkStatsMap()}
                      loading={loadingMethodStats}
                      duration={
                        dataRange[0].valueOf() === hoursAgo.valueOf() && dataRange[1].valueOf() === endDayjs.valueOf()
                      }
                      startTime={dataRange[0]}
                      endTime={dataRange[1]}
                      twentyFourH={twentyFourthHoursAgo}
                    />
                  ) : (
                    <div className={styles.noResponseTimes}>
                      <NoData className={styles.noDataPic} />
                      Filter to 3 days or fewer to view Response Times
                    </div>
                  )}
                </DetailBox>
              )}

              <DetailBox className={cx(styles.detailBox, styles.mapBox)}>
                <h4>
                  Requests by Country
                  {twentyFourthHoursAgo && <span className="duration">({lang(t, `dateRangeExtra.pass24Hours`)})</span>}
                </h4>
                <div className={styles.mapDiv}>
                  <div className={styles.mapColumn}>
                    <MapColumn />
                  </div>
                  {showMap && (
                    <GeolocationMap
                      className={styles.map}
                      setCountry={setCountry}
                      tooltipWord={
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span>{country}</span>
                          <span style={{ fontSize: 12 }}>
                            Total Responses:{' '}
                            {networkSpec.requestByCountry && networkSpec.requestByCountry.resultData[country]
                              ? (networkSpec.requestByCountry.resultData as any)[country].toLocaleString()
                              : 0}{' '}
                            (
                            {networkSpec.requestByCountry &&
                            networkSpec.requestByCountry.resultData[country] &&
                            networkSpec.requestByCountry.totalRequest
                              ? (
                                  ((networkSpec.requestByCountry.resultData as any)[country] /
                                    networkSpec.requestByCountry.totalRequest) *
                                  100
                                ).toFixed(2) + '%'
                              : '0%'}
                            )
                          </span>
                        </div>
                      }
                    />
                  )}
                </div>
              </DetailBox>

              {/* Method stats table */}
              {!loading && (
                <DetailBox className={styles.detailBox}>
                  <Row className={styles.chartHeader}>
                    <Col>
                      <h3 className={Classes.HEADING}>
                        {lang(t, 'methodStatsTable')}
                        {twentyFourthHoursAgo && (
                          <span className="duration">({lang(t, `dateRangeExtra.pass24Hours`)})</span>
                        )}
                      </h3>
                    </Col>
                  </Row>
                  <MethodStatsTable
                    data={apiService.methodSummaryMap()}
                    loading={loadingMethodSummary}
                    twentyFourH={twentyFourthHoursAgo}
                    duration={dataRange[0].valueOf() === dataRange[1].valueOf() ? '24h' : '7d'}
                  />
                </DetailBox>
              )}
            </TabPane>
            <TabPane
              tab={
                <div className={styles.integrationIconTab}>
                  {lang(t, 'tabIntegration')}{' '}
                  {Object.keys(apiService.networkStatsMap()).length === 0 && (
                    <Tooltip
                      overlayStyle={{ color: '#262626', backgroundColor: '#fff' }}
                      title={titleText}
                      placement="right"
                      className={styles.tabTooltip}
                    >
                      <InfoCircle />
                    </Tooltip>
                  )}
                </div>
              }
              key="integration"
            >
              <DetailBox className={styles.detailBox}>
                <Row className={styles.chartHeader}>
                  <Col>
                    <h3 className={Classes.HEADING}>{lang(t, 'resources')}</h3>
                  </Col>
                </Row>
                <div className={styles.resourcesBlock}>
                  <div className={styles.resourceLink}>
                    <a target={'_blank'} href={'https://documentation.onfinality.io/support/api-service'}>
                      {'OnFinality API Documentation'}
                    </a>
                    <ExternalLink />
                  </div>
                  {linkList.map((e: resourcesLinkDetail) => {
                    return (
                      <div className={styles.resourceLink}>
                        <a target={'_blank'} href={e.link}>
                          {e.title}
                        </a>
                        <ExternalLink />
                      </div>
                    );
                  })}
                </div>
              </DetailBox>
              <DetailBox className={styles.detailBox}>
                <Row className={styles.chartHeader}>
                  <Col span={20}>
                    <h3 className={Classes.HEADING}>Get Connected</h3>
                  </Col>
                  {['polkadot', 'polkadot-parachain', 'substrate'].includes(usageOpt.protocolKey) && (
                    <Col span={4}>
                      <Button type="link" className="hover link-button" size="large">
                        <div className={styles.resourceLink}>
                          <a
                            target={'_blank'}
                            href={`https://polkadot.js.org/apps/?rpc=${encodeURIComponent(urls.wssUrl)}`}
                            onClick={() => {
                              sendEvent('open_polkadot');
                            }}
                          >
                            Open In Polkadot.JS
                          </a>{' '}
                          <ExternalLink />
                        </div>
                      </Button>
                    </Col>
                  )}
                </Row>
                {usageOpt.protocolKey === 'juno' && (
                  <Row>
                    <Col span={24} className={styles.tabCol}>
                      <Tabs defaultActiveKey="Juno" type="card" onChange={(key) => setJunoTabKey(key)}>
                        <TabPane tab={'Juno'} key={'Juno'} />
                        <TabPane tab={'Cosmos'} key={'Cosmos'} />
                      </Tabs>
                    </Col>
                  </Row>
                )}
                You can connect using websocket or HTTP. Your API key can be added as a URL parameter{' '}
                <span className={styles.greySpan}>?api=${'{APIKEY}'}</span> or in the request header{' '}
                <span className={styles.greySpan}>-H 'apikey:${'{APIKEY}'}'</span>
                <DetailBox className={styles.greyBgc}>
                  <Row>
                    <Col span={21}>{curlCommand}</Col>
                    <Col span={3} className={styles.alignRight}>
                      <Button
                        type="link"
                        className="hover link-button"
                        size="large"
                        onClick={() => {
                          navigator.clipboard.writeText(curlCommand);
                          SuccessToast('Curl copied successfully');
                          sendEvent('copy_curl');
                        }}
                      >
                        Copy Curl
                        <CopyOutlined />
                      </Button>
                    </Col>
                  </Row>
                </DetailBox>
              </DetailBox>
            </TabPane>
          </Tabs>
        </div>
      </MainBox>
      <Alert
        isOpen={showUpgrade}
        intent={Intent.NONE}
        confirmButtonText={lang(t, 'regenerateCancel')}
        onConfirm={() => setShowUpgrade(false)}
      >
        <p className={styles.alertContent} dangerouslySetInnerHTML={{ __html: lang(t, 'upgradeMessage') }} />

        <p>
          Email: <a href="mailto:admin@onfinality.io">admin@onfinality.io</a>
        </p>
      </Alert>
    </PageLayout>
  );
});

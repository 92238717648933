import React from 'react';
import cx from 'classnames';
import styles from './style.module.scss';
import { useStore } from '@/store';

export const FixedMainHeader: React.FC = ({ children }) => {
  const { workspaces } = useStore();
  return (
    <div
      id={'flexHeader'}
      className={cx(styles.flexHeader, workspaces.current!.suspendTaskDone && styles.suspendTaskDone)}
    >
      {children}
    </div>
  );
};

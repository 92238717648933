import styles from './style.module.scss';
import cx from 'classnames';
import { Button, Intent } from '@blueprintjs/core';
import { Col, Row } from '../../components/Grid';
import { Popover } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { NodeSpecName, Preview } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { GetHourlyPrice, lang, providerStyleMap } from './helpers';
import { LaunchDataMapBaseNodeTypeType } from '@/components/Preview/help';
import { NodeFormData } from './index';
import { useStore } from '../../store';
import { mxConfig } from '@/config';
import { sendEvent } from '@/utils/googleAnalytics';

interface IProps {
  data: NodeFormData;
  displayName?: string | undefined;
  onBack: () => void;
  preview?: LaunchDataMapBaseNodeTypeType;
  onSubmit: () => void;
}

export const StepConfirm: React.FC<IProps> = observer((props) => {
  const { t } = useTranslation();
  const { info, workspaces, usageCenter } = useStore();
  const { onSubmit, onBack, data, preview, displayName } = props;
  const [progressing, setProgressing] = useState(false);
  const provider = providerStyleMap[data.provider!];
  const currentNodeSpec = info.getNodeSpec(data.nodeSpec!.key);
  return (
    <div className={styles.formContainer}>
      <div className={cx(styles.section, styles.confirmSection)}>
        <table className={styles.detailTable}>
          <tbody>
            <tr>
              <th>{lang(t, 'detail.nodeName')}</th>
              <td>{data.name}</td>
              <th>{lang(t, 'imageVersion')}</th>
              <td>{data.imageVersion}</td>
            </tr>
            <tr>
              <th>{lang(t, 'detail.nodeType')}</th>
              <td>{lang(t, `nodeType.${data.nodeType}`)}</td>
              <th>{lang(t, 'detail.network')}</th>
              <td>{displayName}</td>
            </tr>
            <tr>
              <th>{lang(t, 'detail.cloud')}</th>
              <td>
                <div className={styles.detailProviderBox}>
                  {React.createElement(provider.img)}
                  <span>{provider.txt}</span>
                </div>
              </td>
              <th>{lang(t, 'detail.region')}</th>
              <td>{data.region}</td>
            </tr>
            <tr>
              <th>{lang(t, 'detail.nodeSize')}</th>
              <td>
                <NodeSpecName name={data.nodeSpec!.key} />
                {usageCenter.limit.nodeUnitSize > 1 && ` × ${data.nodeSpec!.multiplier}`}
              </td>
              <th>{lang(t, 'detail.storageSize')}</th>
              <td>{data.storage!} GB</td>
            </tr>
            {workspaces.current?.billingType !== 'stripe' && (
              <tr>
                <th>
                  {lang(t, 'detail.lightningRestore')}
                  <Popover
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: lang(t, 'detail.lightningRestoreExplain', {
                            href: mxConfig.lightningRestoreDocument,
                          }),
                        }}
                      />
                    }
                  >
                    <ExclamationCircleOutlined className={styles.descriptionOfBackup} />
                  </Popover>
                </th>
                <td>{data.hasBackup ? 'Yes' : 'No'}</td>
                <th>
                  {lang(t, 'autoExpandNodeStorage')}
                  <Popover content={<div style={{ width: 200 }}>{lang(t, 'autoExpandDescriptionShort')}</div>}>
                    <ExclamationCircleOutlined className={styles.descriptionOfBackup} />
                  </Popover>
                </th>
                <td>{data.autoExpand ? lang(t, 'enabled') : lang(t, 'disabled')}</td>
              </tr>
            )}
            {workspaces.current?.billingType === 'stripe' && (
              <tr>
                <th>
                  {lang(t, 'detail.lightningRestore')}
                  <Popover
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: lang(t, 'detail.lightningRestoreExplain', {
                            href: mxConfig.lightningRestoreDocument,
                          }),
                        }}
                      />
                    }
                  >
                    <ExclamationCircleOutlined className={styles.descriptionOfBackup} />
                  </Popover>
                </th>
                <td>{data.hasBackup ? 'Yes' : 'No'}</td>
                <th>
                  {lang(t, 'autoExpandNodeStorage')}
                  <Popover content={<div>{lang(t, 'autoExpandDescription')}</div>}>
                    <ExclamationCircleOutlined className={styles.descriptionOfBackup} />
                  </Popover>
                </th>
                <td>{data.autoExpand ? lang(t, 'enabled') : lang(t, 'disabled')}</td>
              </tr>
            )}
            <tr>
              <th>{lang(t, 'detail.price')}</th>
              <td className={styles.price} colSpan={3}>
                USD $
                {GetHourlyPrice(
                  info.getNodeSpec(data.nodeSpec!.key),
                  data.storage!,
                  data.nodeSpec!.multiplier,
                  workspaces.current?.billingType !== 'stripe',
                )}
              </td>
            </tr>
            {preview && (
              <tr>
                <th>{lang(t, 'detail.launchCommands')}</th>
                <td colSpan={3} className={styles.launchCommandsPreview}>
                  <Preview
                    data={{
                      delimiter: preview.delimiter,
                      comArgRules: preview.comArgRules,
                      envRules: preview.envRules,
                      envExtra: preview.envExtra,
                    }}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Row>
        <Col unit={12}>
          <Button large onClick={onBack} className={styles.bottomBtn}>
            {lang(t, 'buttonBack')}
          </Button>
        </Col>
        <Col unit={12} style={{ textAlign: 'right' }}>
          <Button
            className={styles.bottomBtn}
            large
            intent={Intent.PRIMARY}
            loading={progressing}
            onClick={async () => {
              setProgressing(true);
              if (data.autoExpand) sendEvent('deploy_new_node_with_auto_expand');
              await onSubmit();
              setProgressing(false);
            }}
          >
            {lang(t, 'buttonCreate')}
          </Button>
        </Col>
      </Row>
    </div>
  );
});

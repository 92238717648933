import React from 'react';
import cx from 'classnames';
import { Button, IButtonProps } from '@blueprintjs/core';
import styles from './style.module.scss';

interface IProps extends IButtonProps {
  className?: string;
  // loading?: boolean;
}

// interface IProps {
//   text: string;
//   className?: string;
//   // onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
// }

export const ButtonOutline: React.FC<IProps> = (props) => {
  const { children, className, ...other } = props;
  return (
    <Button className={cx(styles.btn, className)} {...other}>
      {children}
    </Button>
  );
};

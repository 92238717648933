import { chartLoadingOptions, durationToHourCount, chartDateLabel } from '@/utils/tools';
import dayjs, { Dayjs } from 'dayjs';

export interface ChartProps {
  chartData: any;
  duration?: string;
  loading?: boolean;
  selectedMethod?: string[];
  transFn?: (key: string) => string;
  startTime?: Dayjs;
  endTime?: Dayjs;
}

export interface ChartPropsNew {
  chartData: any;
  duration?: boolean;
  loading?: boolean;
  errorData?: any;
  selectedMethod?: string[];
  transFn?: (key: string) => string;
  startTime?: Dayjs;
  endTime?: Dayjs;
  twentyFourH?: boolean;
}

export interface NetworkStats {
  key: string;
  count: number;
  weighted: number;
  size: number;
  average?: number;
  response50th?: number;
  response95th?: number;
  errorRate: number;
  timestamp: number;
}

export const colourBlue = '#6879F5';
export const colourRed = '#FF4756';
export const colourGreen = '#82CA9D';
export const colourYellow = '#FACB72';

export const defaultChartOptions = {
  notMerge: true,
  loadingOption: chartLoadingOptions,
  style: { height: 350, width: '100%' },
};

export const generateTimeSeriesTimestamp = (duration?: string): number[] => {
  if (!duration) return [];
  const result: number[] = [];
  const hourCount = durationToHourCount(duration);
  const endDate = hourCount > 24 ? dayjs().utc().subtract(1, 'day') : dayjs().utc().startOf('h').add(-1, 'h');
  result.push(endDate.unix());
  const loopRange = hourCount > 24 ? hourCount / 24 : hourCount;
  for (let i = 1; i < (hourCount !== 24 * 30 ? loopRange : loopRange - 1); i++) {
    // Days or hours increment
    const prevDate = hourCount > 24 ? endDate.add(-i, 'day') : endDate.add(-i, 'hour');
    result.unshift(prevDate.unix());
  }
  return result;
};

export const generateTimeSeriesTimestampCustom = (
  startTime?: Dayjs,
  endTime?: Dayjs,
  responseTime?: boolean,
  duration?: boolean,
): number[] => {
  if (!startTime || !endTime) return [];
  const result: number[] = [];
  if (responseTime) {
    if (!duration) {
      let startDate: Dayjs = dayjs(JSON.parse(JSON.stringify(startTime))).startOf('day');
      let endDate = dayjs(endTime).startOf('day').add(1, 'day');
      while (startDate.valueOf() !== endDate.valueOf()) {
        result.push(startDate.startOf('hour').valueOf() / 1000);
        startDate = startDate.add(1, 'hour');
      }
    } else if (duration) {
      let startDate: Dayjs = dayjs(JSON.parse(JSON.stringify(startTime))).startOf('day');
      let startDate2: Dayjs = startTime;
      let offset: number = startDate.utcOffset() / 60;
      startDate2 = dayjs(startDate2).subtract(offset, 'hour');
      let endDate = dayjs(startDate2).add(1, 'day');
      while (startDate2.valueOf() !== endDate.valueOf()) {
        result.push(startDate2.startOf('hour').valueOf() / 1000);
        startDate2 = startDate2.add(1, 'hour');
      }
    }

    return result;
  }
  if (startTime.valueOf() === endTime.valueOf()) {
    let startDate: Dayjs = dayjs(JSON.parse(JSON.stringify(startTime))).startOf('day');
    let endDate = dayjs(startDate).add(1, 'day');
    while (startDate.valueOf() !== endDate.valueOf()) {
      result.push(startDate.startOf('hour').valueOf() / 1000);
      startDate = startDate.add(1, 'hour');
    }
  } else if (duration) {
    let startDate: Dayjs = dayjs(JSON.parse(JSON.stringify(startTime))).startOf('day');
    let startDate2: Dayjs = startTime;
    let offset: number = startDate.utcOffset() / 60;
    startDate2 = dayjs(startDate2).subtract(offset, 'hour');
    let endDate = dayjs(startDate2).add(1, 'day');
    while (startDate2.valueOf() !== endDate.valueOf()) {
      result.push(startDate2.startOf('hour').valueOf() / 1000);
      startDate2 = startDate2.add(1, 'hour');
    }
  } else {
    let startDate: Dayjs = dayjs(JSON.parse(JSON.stringify(startTime))).startOf('day');
    let endDate = endTime.startOf('day').add(1, 'day');
    while (startDate.valueOf() !== endDate.valueOf()) {
      result.push(startDate.startOf('day').valueOf() / 1000);
      startDate = startDate.add(1, 'day');
    }
  }
  return result;
};

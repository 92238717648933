import React, { useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  ErrorToast,
  SuccessToast,
  DetailBox,
  Row,
  Col,
  MainBox,
  Font16,
  NetworkSpecCardNew,
  ButtonNoBorder,
  Loader,
} from 'components';
import { Modal, Form, Input, Select, Button, Space, Popover } from 'antd';
// import { CreateApp } from './createApp';
import { useStore } from '../../store';
import * as yup from 'yup';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as NonameImg } from './img/img.svg';
import { EnvironmentType } from '../ApiServices/createApp';
import { INetworkSpec } from '@/store/networks';
import styles from './welcome.module.scss';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

interface IProps {
  wsId: string;
  setStep?: any;
  onSelect: (network: INetworkSpec, isPublicNetowk: boolean) => void;
}

const schema = yup.object().shape({
  displayName: yup.string().required(),
  environment: yup.string().required(),
});

export const Welcome: React.FC<IProps> = observer(({ wsId, setStep, onSelect }) => {
  const { apiService, networks, workspaces } = useStore();
  const history = useHistory();
  const [popularNetworksLoading, setPopularNetworksLoading] = useState(true);
  const [popularNetworks, setPopularNetworks] = useState<INetworkSpec[]>([]);
  const { t } = useTranslation();
  const { handleSubmit, register, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      displayName: '',
      environment: EnvironmentType[0].value,
    },
  });
  const lang = (t: TFunction, key: string, opt?: any) => t(`pages.nodeList.${key}`, opt);

  useEffect(() => {
    (async () => {
      setPopularNetworksLoading(true);
      const a = await apiService.getPopularNetworkDetailForNodes();
      setPopularNetworks(a);
      setPopularNetworksLoading(false);
    })();
  }, []);

  return (
    <MainBox>
      <div className={styles.welcomeContainer}>
        <DetailBox>
          <Row>
            <Col unit={16}>
              <h2>{lang(t, 'welcome.title')}</h2>
              <Font16 className={styles.welcomeWords}>
                <span dangerouslySetInnerHTML={{ __html: lang(t, 'welcome.subTitle') }}></span>
              </Font16>
              {/* <CreateApp wsId={wsId} text={lang(t, 'welcome.createApp')} /> */}
              <Button
                type="primary"
                onClick={() => {
                  setStep(1);
                }}
                size="large"
              >
                {lang(t, 'welcome.button')}
              </Button>
            </Col>
            <Col unit={8} alignRight>
              <NonameImg width={300} height={160} />
            </Col>
          </Row>
        </DetailBox>
        <Loader isLoading={popularNetworksLoading}>
          <Row>
            <Col unit={24}>
              <div className={styles.popularTitle}>
                {lang(t, 'welcome.mostPopularNetworks')}
                <ButtonNoBorder
                  className={styles.showAllNerworksBtn}
                  size="large"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  {lang(t, 'welcome.exploreAllNetworks')}
                </ButtonNoBorder>
              </div>
            </Col>
          </Row>
          <Row>
            <Col unit={24}>
              <div className={styles.networkCardDiv}>
                {popularNetworks.map((network, index) => {
                  return (
                    <NetworkSpecCardNew
                      backup={networks.backupMap[network.key]}
                      key={`${network.displayName}_${index}`}
                      network={network}
                      onClick={() => {
                        onSelect(network, true);
                      }}
                      wsId={wsId}
                    />
                  );
                })}
              </div>
            </Col>
          </Row>
        </Loader>
      </div>
    </MainBox>
  );
});

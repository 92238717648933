import ReactGA from 'react-ga4';

export const sendEvent = (eventName: string, value?: string | number) => {
  if (!ReactGA.isInitialized) {
    return;
  }
  ReactGA.event(eventName, {
    value,
  });
};

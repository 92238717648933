import { Radio, RadioGroup } from '@blueprintjs/core';
import React from 'react';

interface IProps {
  value?: string;
  onChange?: (val: string) => void;
  options: Array<{ label: string; value: string }>;
}

export const RadioSelect: React.FC<IProps> = ({ value, onChange, options }) => {
  return (
    <RadioGroup onChange={e => onChange && onChange(e.currentTarget.value)} selectedValue={value}>
      {options.map((op, idx) => (
        <Radio key={idx} large label={op.label} value={op.value} />
      ))}
    </RadioGroup>
  );
};

import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Progress } from 'antd';
import { useStore } from '@/store';
import styles from '../style.module.scss';
import dayjs from 'dayjs';
import { numberAndUnit } from '@/utils/tools';
import { percentage, lang, Colors } from '../helpers';
import dayjsPluginUTC from 'dayjs-plugin-utc';
dayjs.extend(dayjsPluginUTC as any, { parseToLocal: true });

export const Dayly: React.FC = observer(() => {
  const { apiService, usageCenter } = useStore();
  const { t } = useTranslation();
  const progressValue = percentage(apiService.limit?.weightedUsage, usageCenter.limit?.apiServiceLimit);
  const lastHourToday =
    (dayjs(dayjs().utc().add(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')).diff(
      dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss')),
      'hours',
    ) %
      24) +
    1;

  const progressColor = () => {
    if (progressValue >= 100) return Colors.ERROR;
    else if (progressValue >= 90) return Colors.WARNING;
    else return Colors.PRIMARY;
  };

  return (
    <>
      <Progress
        className={styles.progressCycle}
        width={150}
        type="circle"
        strokeColor={progressColor()}
        percent={progressValue}
        format={() => (
          <>
            <div className={styles.usage}>{apiService.limit?.weightedUsage.toLocaleString()}</div>
            <div className={styles.title}>{lang(t, 'appList.daylyProgressSubtitle')}</div>
          </>
        )}
      />
      {usageCenter.limit?.apiServiceLimit === 0 && (
        <p className={styles.limitNumber}>{lang(t, 'appList.unlimitedRequests')}</p>
      )}
      {usageCenter.limit?.apiServiceLimit !== 0 && (
        <>
          <p className={styles.limitNumber}>{`${numberAndUnit(usageCenter.limit?.apiServiceLimit)} ${lang(
            t,
            'appList.requestLimit',
          )}`}</p>
          <p className={styles.limitNumberAdditional}>
            {lang(t, 'appList.requestLimitTimeUnitHr', {
              hour: lastHourToday,
            })}
          </p>
        </>
      )}
    </>
  );
});

import React, { useEffect } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import { useStore } from '../../store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

interface IProps extends RouteConfigComponentProps {}

export const Callback: React.FC<IProps> = observer(props => {
  const { t } = useTranslation();
  const { auth } = useStore();

  useEffect(() => {
    (async () => {
      try {
        await auth.handleCallback();
      }catch (e) {
        await props.history.push('/login');
        return;
      }
      await props.history.push(auth.isLoggedIn() ? '/' : '/login');
    })();
  }, []);

  return <div>{t('pages.callback.processing')} ....</div>;
});

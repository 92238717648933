import React, { useEffect } from 'react';
import { sendEvent } from '@/utils/googleAnalytics';

export const Layout: React.FC = () => {
  useEffect(() => {
    const urlParams = new URL(window.location.href);
    const { utm_source, utm_network } = {
      utm_source: urlParams.searchParams.get('utm_source'),
      utm_network: urlParams.searchParams.get('utm_network'),
    } as { utm_source?: string; utm_network?: string };
    if (utm_source) {
      if (utm_network) {
        sendEvent(`customer_from_${utm_source}`, utm_network);
      } else {
        sendEvent(`customer_from_${utm_source}`);
      }
    }
  }, []);

  return <></>;
};

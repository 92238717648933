import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import ClipboardButton from 'react-clipboard.js';
import { useTranslation } from 'react-i18next';
import { ErrorToast, SuccessToast, DetailBox, ButtonLink, ComponentHeader } from 'components';
import { useStore } from '../../store';
import { AccessKey } from '../../store/account';
import { Row, Col, Table, Button, Typography, Modal, Input } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { ReactComponent as TrashBin } from './img/bin.svg';
import cx from 'classnames';
import styles from './style.module.scss';

interface IProps {
  className?: string;
}

const { Paragraph } = Typography;

const lang = (t: TFunction, key: string, opt?: any) => t(`modules.accessKey.${key}`, opt);
const alertStateInit = {
  show: false,
  key: '',
};

export const AccountAccessKeyNew: React.FC<IProps> = observer(({ className }) => {
  const { t } = useTranslation();
  const { account, workspaces } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [access, setAccess] = useState<Array<Instance<typeof AccessKey>>>([]);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await account.getAccessKey();
      setAccess(account.accessKeys);
      setIsLoading(false);
    })();
  }, []);

  const [showAddAccessKeyDialog, setShowAddAccessKeyDialog] = useState(false);

  const [alertState, setAlertState] = useState(alertStateInit);

  const deleteButton = (accessKey: string) => {
    Modal.confirm({
      title: lang(t, 'deleteTitle'),
      content: lang(t, 'deleteAction'),
      className: 'deleteKey',
      centered: true,
      okText: 'Delete',
      okType: 'danger',
      onOk: () => {
        deleteAccessKey(accessKey);
        setAlertState(alertStateInit);
      },
      onCancel: () => {
        setAlertState(alertStateInit);
      },
    });
  };

  const addNewAccessKey = async () => {
    await account.addAccessKey();
    if (account.state === 'done') {
      setShowAddAccessKeyDialog(true);
      account.getAccessKey();
    } else if (account.state === 'error' && account?.errorMessage) {
      ErrorToast(account?.errorMessage);
    }
  };

  const deleteAccessKey = async (key: string) => {
    await account.deleteAccessKey(key);
    if (account.state === 'done') {
      SuccessToast(lang(t, 'deleteSuccess'));
      account.getAccessKey();
    } else if (account.state === 'error' && account?.errorMessage) {
      ErrorToast(account?.errorMessage);
    }
  };

  const columns = [
    {
      title: 'Access Key',
      key: 'accessKey',
      render: (item: any) => {
        return <Paragraph copyable>{item.accessKey}</Paragraph>;
      },
    },
    {
      width: 205,
      title: 'Create Date',
      key: 'createdAt',
      render: (item: any) => {
        return <>{dayjs(item.createdAt).format('DD/MM/YYYY')}</>;
      },
    },
    {
      width: 120,
      title: 'Action',
      key: 'createdAt',
      render: (item: any) => {
        return (
          <Button
            type="link"
            danger
            onClick={() => {
              setAlertState({
                show: true,
                key: item.accessKey,
              });
              deleteButton(item.accessKey);
            }}
          >
            <TrashBin />
          </Button>
        );
      },
    },
  ];

  return (
    <DetailBox className={cx(styles.container, className)}>
      <ComponentHeader>
        <h3>{lang(t, 'title')}</h3>
        <Button
          type="link"
          onClick={() => {
            addNewAccessKey();
          }}
        >
          <PlusCircleOutlined />
          {lang(t, 'addNew')}
        </Button>
      </ComponentHeader>

      {access.length ? (
        <Table loading={isLoading} columns={columns} dataSource={[...access]} pagination={false} scroll={{ y: 345 }} />
      ) : (
        <p className={styles.emptyText}>You have no active Access Key</p>
      )}

      <Modal
        visible={showAddAccessKeyDialog}
        title={lang(t, 'dialogTitle')}
        onOk={() => {
          navigator.clipboard.writeText(account.newAccessKey!.secretKey);
          SuccessToast('Secret Key ' + lang(t, 'copied'));
        }}
        onCancel={() => {
          setShowAddAccessKeyDialog(false);
        }}
        footer={[
          <Button
            key={'Copy Screct Key'}
            type="primary"
            onClick={() => {
              navigator.clipboard.writeText(account.newAccessKey!.secretKey);
              SuccessToast('Secret Key ' + lang(t, 'copied'));
            }}
          >
            {lang(t, 'copyButton')}
          </Button>,
        ]}
        width={572}
        centered
      >
        <p className={styles.fontSize14}>{lang(t, 'dialogContent')}</p>
        <p className={styles.fontSize16}>{lang(t, 'accessKey')}</p>
        <Input prefix={account.newAccessKey?.accessKey} disabled size="large" />
        <br />
        <br />
        <p className={styles.fontSize16}>{lang(t, 'secretKey')}</p>
        <Input prefix={account.newAccessKey?.secretKey} disabled size="large" />
      </Modal>
    </DetailBox>
  );
});

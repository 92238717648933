import React from 'react';
import styles from './style.module.scss';
import cx from 'classnames';

interface IProps {
  limit?: boolean;
  className?: string;
}

export const MainBox: React.FC<IProps> = ({ children, limit = false, className }) => {
  return <div className={cx(className, styles.mainBox, limit ? styles.limit : '')}>{children}</div>;
};

import React, { useState } from 'react';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DetailBox, Row, Col, MainBox, ButtonOutline, Font16 } from 'components';
import { Intent, Button } from '@blueprintjs/core';
import { useStore } from '../../store';
import { ReactComponent as NonameImg } from './imgs/img.svg';
import { ReactComponent as ConfigurableIcon } from './imgs/configurable.svg';
import { ReactComponent as HassleFreeIcon } from './imgs/hassleFree.svg';
import { ReactComponent as PublishIcon } from './imgs/publish.svg';
import styles from '../ApiServices/welcome.module.scss';

export const Welcome: React.FC = observer(() => {
  const { workspaces } = useStore();
  const [processing, setProcessing] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const lang = (t: TFunction, key: string, opt?: any) => t(`pages.networkList.${key}`, opt);

  return (
    <MainBox>
      <div className={styles.welcomeContainer}>
        <DetailBox>
          <Row>
            <Col unit={16}>
              <h2>{lang(t, 'welcome.title')}</h2>
              <Font16 className={styles.welcomeWords}>{lang(t, 'welcome.subTitle')}</Font16>
              <ButtonOutline
                disabled={processing || workspaces.current!.suspendTaskDone}
                large
                intent={Intent.PRIMARY}
                onClick={() => history.push(`/workspaces/${workspaces.current!.id}/networks/create`)}
              >
                {lang(t, 'menu.join-network')}
              </ButtonOutline>
            </Col>
            <Col unit={8} alignRight>
              <NonameImg width={300} height={160} />
            </Col>
          </Row>
        </DetailBox>
        <Row className={styles.subDetail}>
          <Col unit={8}>
            <div>
              <div className={styles.icon}>
                <ConfigurableIcon />
              </div>
              <h3>{lang(t, 'welcome.subWelcome.1.title')}</h3>
              <p>{lang(t, 'welcome.subWelcome.1.content')}</p>
            </div>
          </Col>
          <Col unit={8}>
            <div>
              <div className={styles.icon}>
                <HassleFreeIcon />
              </div>
              <h3>{lang(t, 'welcome.subWelcome.2.title')}</h3>
              <p>{lang(t, 'welcome.subWelcome.2.content')}</p>
            </div>
          </Col>
          <Col unit={8}>
            <div>
              <div className={styles.icon}>
                <PublishIcon />
              </div>
              <h3>{lang(t, 'welcome.subWelcome.3.title')}</h3>
              <p dangerouslySetInnerHTML={{ __html: lang(t, 'welcome.subWelcome.3.content') }}></p>
            </div>
          </Col>
        </Row>
      </div>
    </MainBox>
  );
});

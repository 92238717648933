import { HTMLSelect } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../store';
import Loading from 'react-loading';
import styles from './style.module.scss';

interface IProps {
  callback: (key: string | null) => void;
}

export const Networks: React.FC<IProps> = observer(({ callback }) => {
  const { workspaces, networkSpec } = useStore();
  const [current, setCurrent] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      await networkSpec.fetchNetworks(workspaces.current?.id);
      if (networkSpec.networks[0]) {
        setCurrent(networkSpec.networks[0]?.subDomain);
        callback(networkSpec.networks[0]?.subDomain);
      } else {
        callback(null);
      }
    })();
  }, [workspaces.current]);
  if (!networkSpec.networks.length && networkSpec.state === 'pending')
    return (
      <div className={styles.container}>
        <Loading color={'grey'} type={'spin'} width={30} height={30} />
      </div>
    );
  else
    return (
      <div className={styles.container}>
        <HTMLSelect
          onChange={(e) => {
            setCurrent(e.currentTarget.value);
            callback(e.currentTarget.value);
          }}
        >
          {!networkSpec.networks.length && <option value={''}>-- no network --</option>}
          {networkSpec.networks.map((i) => (
            <option key={i.subDomain} value={i.subDomain}>
              {i.displayName}
            </option>
          ))}
        </HTMLSelect>
      </div>
    );
});

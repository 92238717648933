import { Button, Classes, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Col, ErrorHint, ErrorToast, SuccessToast, SubmitButton, Row, FormSelect, Loader } from 'components';
import { useStore } from 'store';
import styles from './style.module.scss';
import { lang } from './helper';

interface IProps {
  isOpen: boolean;
  id: string;
  image?: string;
  availableVersions?: Array<string>;
  recommendedVersion?: any;
  onClose: () => void;
}
export type imageVersionOption = {
  label: string;
  value: string;
};

export const UpgradeImageDialog: React.FC<IProps> = ({
  isOpen,
  onClose,
  id,
  image,
  availableVersions,
  recommendedVersion,
}) => {
  const { t } = useTranslation();
  const { nodes, workspaces } = useStore();
  const schema = yup.object().shape({
    version: yup.string().required(),
  });
  const { control, errors, handleSubmit } = useForm({ validationSchema: schema });
  const [progressing, setProgressing] = useState(false);
  const [isLoading, setIsLoading] = useState(!availableVersions);
  const [selectData, setSelectData] = useState(
    availableVersions?.reduce<imageVersionOption[]>((res, i) => {
      if (i === recommendedVersion) res.unshift({ label: i, value: i });
      else res.push({ label: i, value: i });
      return res;
    }, []) || [],
  );

  nodes.current?.availableVersions?.map((i) => {
    return { label: i, value: i };
  }) || [];

  const [repo, version] = image!.split(':');
  const selectedValue = selectData.find((v) => v.value === version);

  useEffect(() => {
    (async () => {
      if (isOpen && !availableVersions) {
        setIsLoading(true);
        const data = await nodes.fetchNodeDetailForList(workspaces.current!.id, id);
        setSelectData(
          data.availableVersions.map((i: string) => {
            return { label: i, value: i };
          }) || [],
        );
        setIsLoading(false);
      }
    })();
  }, [isOpen]);

  return (
    <Dialog title={lang(t, 'upgradeNode.titleImage')} isOpen={isOpen} onClose={onClose}>
      <form
        onSubmit={handleSubmit(async (val) => {
          setProgressing(true);
          await nodes.updateNodeImage(workspaces.current!.id, id, { imageVersion: val.version });
          if (nodes.state === 'error') {
            ErrorToast(nodes.errorMessage!);
          } else {
            SuccessToast('Success');
            onClose();
          }
          setProgressing(false);
        })}
      >
        <div className={Classes.DIALOG_BODY}>
          <Loader isLoading={isLoading}>
            <FormGroup label={lang(t, 'upgradeNode.image')}>
              <Row gutter={true}>
                <Col unit={12}>
                  <InputGroup large fill defaultValue={repo} disabled />
                </Col>
                <Col unit={12}>
                  <Controller
                    name="version"
                    control={control}
                    defaultValue={selectData?.[0]?.value ?? selectedValue?.value}
                    as={
                      selectData?.length ? (
                        <FormSelect options={selectData} recommendedVersion={recommendedVersion} />
                      ) : (
                        <InputGroup large fill intent={errors.storage ? Intent.DANGER : Intent.NONE} />
                      )
                    }
                  />
                  <ErrorHint message={errors.version ? errors.version.message : ''} />
                </Col>
              </Row>
            </FormGroup>
            {nodes.current?.image && `${lang(t, 'upgradeNode.currentImageVersion')}: ${nodes.current?.image}`}
            <br />
            <br />
            {nodes?.current?.metadata?.labels?.nodePool !== undefined && (
              <p className={styles.rpcAndUserWarning}>
                This node is used for the OnFinality Public API. Changing these settings will restart the node, so
                please perform a rolling update of only one node at a time
              </p>
            )}
          </Loader>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <SubmitButton
              type="submit"
              intent={Intent.PRIMARY}
              disabled={nodes.state === 'pending'}
              loading={progressing}
            >
              {lang(t, 'upgradeNode.submit')}
            </SubmitButton>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

import React, { useState } from 'react';
import { Instance } from 'mobx-state-tree';
import styles from './style.module.scss';
import { useStore } from '@/store';
import { ErrorToast, SuccessToast, AntdRow, AntdCol, Font18, Font16, Font14, Font12, CreditCard } from 'components';
import { PaymentMethodDialog } from '@/modules';
import { useTranslation } from 'react-i18next';
import { showPriceValue, descriptionFormat } from './index';
import { PlanPrice } from '@/store/plan';
import { lang } from './helper';
import { Alert, Button, Modal, Radio } from 'antd';
interface IProps {
  planName: string;
  keyValue: string;
  description?: string;
  price: Instance<typeof PlanPrice>;
  onClose: () => void;
}

export const ConfirmModal: React.FC<IProps> = ({ onClose, planName, description, price, keyValue }) => {
  const { workspaces, plan } = useStore();
  const [step, setStep] = useState(planName === 'developer' ? 4 : 1);
  const [progressing, setProgressing] = useState(false);
  const [paymentId, setPaymentId] = useState(workspaces.current!.paymentMethodId);
  const { value, month, usage, kind } = price;
  const { t } = useTranslation();
  const upgrade = async () => {
    setProgressing(true);
    await workspaces.fetchPayment(workspaces.current!.id);
    setProgressing(false);
    if (planName === 'developer') {
      setStep(4);
    } else {
      if (workspaces.paymentList.length > 0) setStep(3);
      else setStep(2);
    }
  };
  const didConfirm = () => {
    confirmPlan();
  };
  const postSubmit = async () => {
    setProgressing(true);
    await workspaces.fetchPayment(workspaces.current!.id);
    if (workspaces.current?.paymentMethodId) {
      setPaymentId(workspaces.current.paymentMethodId);
    }
    setProgressing(false);
    setStep(3);
  };
  const confirmPlan = async () => {
    setProgressing(true);
    if (paymentId !== workspaces.current!.paymentMethodId) {
      await workspaces.updatePaymentMethod(workspaces.current!, { paymentMethodId: paymentId });
    }
    await plan.changePlan(workspaces.current!.id, keyValue);
    await workspaces.fetchList();
    setProgressing(false);
    if (plan.state === 'error') {
      if (plan.state.includes('you do not allow to')) {
        ErrorToast('Contact your workspace owner to change plans.');
      } else ErrorToast(plan.errorMessage!);
    } else {
      SuccessToast(lang(t, 'plan.confirmModal.updateSuccess', { planName }));
      onClose();
    }
  };

  return (
    <>
      {step === 1 && (
        <Modal
          className={styles.contactUsForm}
          title={lang(t, 'plan.confirmModal.title')}
          visible={true}
          centered
          maskClosable={false}
          onCancel={onClose}
          footer={[
            <Button loading={progressing} key="submit" type="primary" onClick={upgrade}>
              {lang(t, 'plan.confirmModal.upgrade')}
            </Button>,
          ]}
        >
          <Font16 className={styles.modaleSubTitle}>
            {lang(t, 'plan.confirmModal.currentPlan')}: <span>{workspaces.current!.plan}</span>
          </Font16>
          <div className={styles.selectPlanBlueBox}>
            <AntdRow>
              <AntdCol span={12}>
                <Font16 className={styles.modaleSubTitle}>
                  {lang(t, 'plan.confirmModal.upgradingTo')}: <span>{planName}</span>
                </Font16>
              </AntdCol>
              <AntdCol span={12} alignRight>
                <div className={styles.modalPrice}>
                  <span className={styles.priceValue}>{showPriceValue(kind, value)}</span>
                  {value !== 0 && month && <span className={styles.priceSub}>/{lang(t, 'plan.month')}</span>}
                </div>
              </AntdCol>
            </AntdRow>
            <ul className={styles.modalDescription}>{descriptionFormat(description)}</ul>
          </div>
        </Modal>
      )}
      {step === 4 && (
        <Modal
          title={lang(t, 'plan.confirmModal.paymentMethodTitle')}
          className={styles.contactUsForm}
          width={610}
          visible={true}
          onCancel={onClose}
          centered
          footer={[
            <Button key={'cancel'} onClick={onClose}>
              {lang(t, 'plan.confirmModal.cancel')}
            </Button>,
            <Button key={'confirm'} onClick={didConfirm} loading={progressing} type="primary">
              {lang(t, 'plan.confirmModal.confirm')}
            </Button>,
          ]}
        >
          <Alert type="warning" message={lang(t, 'plan.confirmModal.downgrade')} showIcon />
          <Font16 className={styles.mt16}>
            You will receive a final invoice for any remainder Growth plan usage and your billing cycle will reset.
          </Font16>
        </Modal>
      )}
      {step === 2 && <PaymentMethodDialog hasDialog={true} isOpen={true} postSubmit={postSubmit} onClose={onClose} />}
      {step === 3 && (
        <Modal
          className={styles.contactUsForm}
          title={lang(t, 'plan.confirmModal.paymentMethodTitle')}
          visible={true}
          centered
          width={610}
          maskClosable={false}
          onCancel={onClose}
          footer={[
            <Button loading={progressing} key="submit" type="primary" onClick={confirmPlan}>
              {lang(t, 'plan.confirmModal.confirmPlan')}
            </Button>,
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              setPaymentId(e.target.value);
            }}
            value={paymentId}
          >
            {workspaces.paymentList.map((method) => (
              <Radio className={styles.cardItem} key={method.id} value={method.id}>
                <CreditCard card={method.card} />
              </Radio>
            ))}
          </Radio.Group>
          <div className={styles.selectPlanBlueBox}>
            <Font18 className={styles.blackColor}>{planName} Plan</Font18>
            <AntdRow>
              <AntdCol span={12}>
                <Font14>{lang(t, 'plan.confirmModal.total')}</Font14>
              </AntdCol>
              <AntdCol span={12} alignRight>
                <div className={styles.modalPrice}>
                  <span className={styles.priceValue}>{showPriceValue(kind, value)}</span>
                  {value !== 0 && month && <span className={styles.priceSub}>/{lang(t, 'plan.month')}</span>}
                </div>
              </AntdCol>
            </AntdRow>
            <AntdRow>
              <AntdCol span={24} alignRight>
                <span className={styles.usageCost}>+Usage cost</span>
              </AntdCol>
            </AntdRow>
          </div>
          <p
            className={styles.unImportant}
            dangerouslySetInnerHTML={{ __html: lang(t, 'plan.confirmModal.someNotice') }}
          />
        </Modal>
      )}
    </>
  );
};

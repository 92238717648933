import React from 'react';
import { observer } from 'mobx-react';
import { Tabs } from 'antd';
import styles from './style.module.scss';
import cx from 'classnames';

interface IProps {
  itemList: string[];
  onChange: (key: any) => void;
  className?: string;
}

export const ButtonTab: React.FC<IProps> = observer(({ itemList, onChange, className }) => {
  return (
    <div className={cx(styles.container, className)}>
      <Tabs
        type="card"
        onChange={(key) => {
          onChange(key);
        }}
      >
        {itemList.map((e) => (
          <Tabs.TabPane tab={e} key={e} />
        ))}
      </Tabs>
    </div>
  );
});

import styles from '../style.module.scss';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'store';
import { useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';
import { lang, PanelProps } from '../helper';
import cx from 'classnames';

export const ParaChain: React.FC<PanelProps> = observer(({ nodes }) => {
  const [showState, setShowState] = useState(false);
  const { t } = useTranslation();
  const switchShow = () => {
    setShowState(!showState);
  };
  const is_syncing = nodes?.getMetric('is_syncing');
  const relaychain_is_syncing = nodes?.getMetric('relaychain_is_syncing');
  const relayChainTitle = nodes?.current?.metadata?.statefulInfo?.relayChainTitle;

  return (
    <>
      <ul className={cx(styles.capsule, styles.paraOutside, showState ? styles.showRelay : '')}>
        <li>
          <div className={styles.mainCap}>
            <div className={styles.th}>{lang(t, 'detailTitle.lastBlock')}</div>
            <div className={styles.para}>
              <div>{nodes?.getMetric('blocknumber', false)?.toLocaleString() ?? '--'}</div>
            </div>
          </div>
          <div className={styles.relay}>
            <span className={styles.subscript}>
              {lang(t, 'detailTitle.relayChain')} {relayChainTitle && `- ${relayChainTitle}`}
            </span>
            <div>{nodes?.getMetric('relaychain_blocknumber', false)?.toLocaleString() || '--'}</div>
          </div>
        </li>
        <li>
          <div className={styles.mainCap}>
            <div className={styles.th}>{lang(t, 'detailTitle.finalizedBlocks')}</div>
            <div className={styles.para}>
              {nodes?.getMetric('finalized_blocknumber', false)?.toLocaleString() ?? '--'}
            </div>
          </div>
          <div className={styles.relay}>
            <div>{nodes?.getMetric('relaychain_finalized_blocknumber', false)?.toLocaleString() ?? '--'}</div>
          </div>
        </li>
        <li>
          <div className={styles.mainCap}>
            <div className={styles.th}>{lang(t, 'detailTitle.peerCount')}</div>
            <div className={styles.para}>{nodes?.getMetric('peer_count', false)?.toLocaleString() ?? '--'}</div>
          </div>
          <div className={styles.relay}>
            <div>{nodes?.getMetric('relaychain_peer_count', false)?.toLocaleString() ?? '--'}</div>
          </div>
        </li>
        <li>
          <div className={styles.mainCap}>
            <div className={styles.th}>{lang(t, 'detailTitle.pendingExtrinsic')}</div>
            <div className={styles.para}>
              <div>{nodes?.getMetric('pending_extrinsic_count', false)?.toLocaleString() ?? '--'}</div>
            </div>
          </div>
          <div className={styles.relay}>
            <div>{nodes?.getMetric('relaychain_pending_extrinsic_count', false)?.toLocaleString() ?? '--'}</div>
          </div>
        </li>
        <li>
          <div className={styles.mainCap}>
            <div className={styles.th}>{lang(t, 'detailTitle.syncing')}</div>
            <div className={styles.para}>{is_syncing === undefined ? '--' : is_syncing ? 'Syncing' : 'Synced'}</div>
          </div>
          <div className={styles.relay}>
            <div>{relaychain_is_syncing === undefined ? '--' : relaychain_is_syncing ? 'Syncing' : 'Synced'}</div>
          </div>
        </li>
      </ul>
    </>
  );
});

import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteConfigComponentProps } from 'react-router-config';
import {
  Loader,
  FixedMainHeader,
  Row,
  Col,
  EditableField,
  ErrorToast,
  SuccessToast,
  Tabs,
  Back,
  NoData,
} from 'components';
import { showConfirm } from '@/components/Confirm';
import { PageLayout, DetailBox } from 'components';
import { NetworkIcon } from '@/components/NetworkCard/NetworkIcon';
import { ReactComponent as BackIcon } from '@/components/Back/img/back.svg';
import { Recommended } from './Recommended';
import { Argument } from './Argument';
import { Environment } from './Environment';
import { MoreDetail } from './moreDetail';
import queryString from 'query-string';
import { transformArg, transformVar, transformEnv } from '@/pages/NetworkCreate/helpers';
import {
  previewToVars,
  previewToExtraArgs,
  withoutContext,
  launchData,
  LaunchDataMapType,
  LaunchDataMapBaseNodeTypeType,
  mergeLaunchData,
} from '@/components/Preview/help';
import { useStore } from 'store';
import { lang } from './helper';
import { Button, Intent, Alert, Classes, Checkbox, Tab } from '@blueprintjs/core';
import { Tag } from 'antd';
import { ReactComponent as ProtocolIcon } from './img/protocol.svg';
import { ReactComponent as DockerIcon } from './img/docker.svg';
import { NetworkSpecStatus } from '@/store/networks';
import styles from './style.module.scss';
import dayjs from 'dayjs';
import { configure } from 'mobx';

interface IParams {
  wsId: string;
  id: string;
}

type IProps = RouteConfigComponentProps<IParams>;

export const PromoteNetworkDetail: React.FC<IProps> = observer((props) => {
  const { t } = useTranslation();
  const { match, history } = props;
  const { networks, workspaces, info } = useStore();
  const { wsId, id } = match.params;
  const [selectedTabId, setSelectedTabId] = useState<string>('full');
  const [makeSureDelete, setMakeSureDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showMoreDetail, setShowMoreDetail] = useState(false);
  const [networkDetail, setNetworkDetail] = useState<any>({});
  const [networkSpecExtraInfo, setNetworkSpecExtraInfo] = useState<any>({});
  const [protocolRules, setProtocolRules] = useState<any>({});
  const [networkSpec, setNetworkSpec] = useState<any>({});
  const [config, setConfig] = useState<any>({});
  const [previewDataMap, setPreviewDataMap] = useState<LaunchDataMapType>({});

  const reloadNetworkData = async () => {
    setIsLoading(true);
    const data = await networks.fetchNetworkPromoteDetail(wsId, match.params.id);
    const protocols = await networks.getProtocols(data.form.networkSpec.protocol);
    const protocolRules = protocols?.metadata?.rules;
    if (!protocolRules) return;
    setProtocolRules(protocolRules);
    let nodeTypes = [] as any;
    (data.form?.recommends || []).forEach((i: any) => {
      nodeTypes.push({
        key: i.nodeType,
        recommend: i,
      });
    });
    setNetworkSpec(data.form.networkSpec);
    setNetworkSpecExtraInfo(data.form.networkSpecExtraInfo);
    setConfig(data.form.networkSpec.config);
    setNetworkDetail({
      ...data.form.networkSpec,
      nodeTypes,
      protocolKey: data.form.networkSpec.protocol,
      status: data.status,
      recommends: data.form.recommends,
      createdAt: data.createdAt,
    });
    const { metadata, config } = data.form.networkSpec;
    let dataMap = launchData(metadata, config?.operations);
    setPreviewDataMap(dataMap);
    setIsLoading(false);
  };

  useEffect(() => {
    reloadNetworkData();
  }, []);
  const updataDetail = async (nodeTypeRules: LaunchDataMapBaseNodeTypeType) => {
    const operations = {} as any;
    Object.keys(previewDataMap).map((nodeType: string) => {
      if (nodeType === selectedTabId) {
        operations[nodeType] = {
          arg: transformArg(nodeTypeRules.comArgRules, protocolRules[nodeType].arg, true),
          var: transformVar(nodeTypeRules.comArgRules, nodeTypeRules.envRules, protocolRules[nodeType].var),
          env: transformEnv(nodeTypeRules.envRules, protocolRules[nodeType].env),
        };
      } else {
        operations[nodeType] = {
          arg: transformArg(previewDataMap[nodeType].comArgRules, protocolRules[nodeType].arg, true),
          var: transformVar(
            previewDataMap[nodeType].comArgRules,
            previewDataMap[nodeType].envRules,
            protocolRules[nodeType].var,
          ),
          env: transformEnv(previewDataMap[nodeType].envRules, protocolRules[nodeType].env),
        };
      }
    });
    await networks.publishNetwork(wsId, {
      id: +id,
      recommends: networkDetail.recommends,
      networkSpecExtraInfo: networkSpecExtraInfo,
      networkSpec: {
        ...networkSpec,
        config: {
          operations: operations,
        },
      },
    });
    if (networks.state === 'error') {
      ErrorToast(networks.errorMessage!);
      return false;
    }
    SuccessToast();
    reloadNetworkData();
  };

  const statusMap = {
    pending: 'processing',
    bootstrapRequired: 'warning',
    generateChainSpecFailure: 'error',
    enabled: 'success',
    disabled: 'error',
    deleted: 'error',
  };

  return (
    <PageLayout>
      <div className={styles.container}>
        <FixedMainHeader>
          <Row>
            <Col unit={12}>
              {!showMoreDetail && <Back link={`/workspaces/${wsId}/networks`} text={lang(t, 'backToList')} />}
              {showMoreDetail && (
                <div
                  className={styles.backBtn}
                  onClick={() => {
                    setShowMoreDetail(false);
                  }}
                >
                  <BackIcon />
                  <span>{lang(t, 'backToDetail')}</span>
                </div>
              )}
            </Col>
            <Col unit={12} className={styles.alignRight}>
              {!showMoreDetail && (
                <>
                  {workspaces.current?.plan === 'partner' && (
                    <Button
                      intent={Intent.NONE}
                      text={lang(t, 'moreDetail.title')}
                      onClick={() => setShowMoreDetail(true)}
                    />
                  )}
                  <Button
                    className={styles.createNew}
                    intent={Intent.DANGER}
                    text={lang(t, 'delete')}
                    onClick={() => setMakeSureDelete(true)}
                  />
                </>
              )}
            </Col>
          </Row>
        </FixedMainHeader>
        {showMoreDetail && (
          <Loader isLoading={isLoading}>
            {!isLoading && (
              <MoreDetail
                config={config}
                data={{
                  ...networkDetail,
                  extraInfo: networkSpecExtraInfo,
                }}
                isPublish={true}
                id={id}
                isApp={true}
                onCancal={() => {
                  setShowMoreDetail(false);
                  reloadNetworkData();
                }}
                showKey={true}
              />
            )}
          </Loader>
        )}
        {!showMoreDetail && (
          <Loader isLoading={isLoading}>
            {networkDetail.displayName && (
              <div className={styles.mainContent}>
                <DetailBox className={styles.networkDetail}>
                  <Row className={styles.summary}>
                    <div className={styles.summaryIcon}>
                      <NetworkIcon name={networkDetail?.displayName} icon={networkSpecExtraInfo?.pictures?.icon} />
                    </div>
                    <div className={styles.summaryName}>
                      <EditableField
                        disabled={workspaces.current!.suspendTaskDone}
                        value={networkDetail.displayName}
                        minLength={4}
                        maxLength={50}
                        onChange={async (val) => {
                          await networks.publishNetwork(wsId, {
                            id: +id,
                            networkSpecExtraInfo: networkSpecExtraInfo,
                            networkSpec: {
                              ...networkSpec,
                              displayName: val,
                            },
                            recommends: networkDetail.recommends,
                          });
                          if (networks.state === 'error') {
                            ErrorToast(networks.errorMessage!);
                            return false;
                          }
                          SuccessToast();
                          reloadNetworkData();
                          return true;
                        }}
                      />
                      <div className={styles.updateDate}>{`${lang(t, 'created')} ${dayjs(
                        networkDetail?.createdAt,
                      ).format('YYYY-MM-DD')}`}</div>
                    </div>
                    <div className={styles.summaryProtocal}>
                      <div className={styles.protocol}>
                        <ProtocolIcon />
                        <span>{networkDetail.protocolKey}</span>
                      </div>
                      <div className={styles.imageRepository}>{networkDetail?.imageRepository}</div>
                    </div>
                    <div className={styles.summaryProtocal}>
                      <div className={styles.protocol}>
                        <DockerIcon />
                        <span>Docker Image</span>
                      </div>
                      <div className={styles.imageRepository}>{networkSpec.metadata?.imageVersion}</div>
                    </div>
                    <div className={styles.summaryProtocal}>
                      <Tag
                        className={styles.state}
                        color={
                          // @ts-ignore
                          statusMap[networkDetail.status]
                        }
                      >
                        {networkDetail.status}
                      </Tag>
                    </div>
                  </Row>
                </DetailBox>
                <Tabs
                  selectedTabId={selectedTabId}
                  className={styles.tabs}
                  onChange={(key: string) => {
                    setSelectedTabId(key);
                  }}
                >
                  {Object.keys(previewDataMap).map((key: string) => {
                    return (
                      <Tab
                        key={key}
                        id={key}
                        title={`${key} ${lang(t, 'node')}`}
                        panel={
                          <>
                            <Argument
                              nodeType={key}
                              data={previewDataMap[key]}
                              onCallback={async (nodeTypeRules: LaunchDataMapBaseNodeTypeType) => {
                                await updataDetail(nodeTypeRules);
                              }}
                            />
                            <Recommended
                              nodeTypeKey={key}
                              nodeTypes={networkDetail.nodeTypes}
                              imageVersion={networkSpec.metadata?.imageVersion}
                              onCallback={async (data) => {
                                await networks.publishNetwork(wsId, {
                                  id: +id,
                                  recommends: data,
                                  networkSpecExtraInfo: networkSpecExtraInfo,
                                  networkSpec: networkSpec,
                                });
                                if (networks.state === 'error') {
                                  ErrorToast(networks.errorMessage!);
                                  return false;
                                }
                                SuccessToast();
                                reloadNetworkData();
                              }}
                            />
                          </>
                        }
                      />
                    );
                  })}
                </Tabs>
              </div>
            )}
          </Loader>
        )}
      </div>
      <Alert
        cancelButtonText={'Cancel'}
        confirmButtonText={'Delete'}
        icon="issue"
        intent={Intent.DANGER}
        isOpen={makeSureDelete}
        onCancel={() => setMakeSureDelete(false)}
        onConfirm={async () => {
          await networks.deleteApp(wsId, id);
          if (networks.state === 'error') {
            ErrorToast(networks.errorMessage!);
          } else {
            SuccessToast('success');
            history.replace(`/workspaces/${workspaces.current!.id}/networks`);
          }
        }}
      >
        <p
          className={styles.alertSubMessage}
          dangerouslySetInnerHTML={{ __html: lang(t, 'deleteNetwork', { networkSpecName: networkDetail.displayName }) }}
        />
      </Alert>
    </PageLayout>
  );
});

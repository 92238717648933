import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import defaultIcon from '@/themes/default/networkDefaultIcon.svg';
import cx from 'classnames';

interface IProps {
  src?: string;
  size?: number;
  className?: string;
}

export const Logo: React.FC<IProps> = ({ size = 58, src, className = '' }) => {
  const [logo, setLogo] = useState(src || defaultIcon);
  useEffect(() => {
    setLogo((src as string) ?? defaultIcon);
  }, [src]);
  return (
    <div
      className={cx(styles.logoOutsite, className)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${size / 2}px`,
      }}
    >
      <div
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <img
          onError={() => {
            setLogo(defaultIcon);
          }}
          style={{
            maxWidth: `${size}px`,
            maxHeight: `${size}px`,
            borderRadius: `${size / 2}px`,
          }}
          className={styles.logo}
          src={logo}
        />
      </div>
    </div>
  );
};

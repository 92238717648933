import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import styles from './style.module.scss';
import cx from 'classnames';
import { TabsId, LaunchDataMapBaseNodeTypeType, LaunchConfigurationItemFormData } from './help';
import { Item } from './item';
import { useTranslation } from 'react-i18next';
import { RuleAction } from '@/components/UpdateNetworkRules/helpers';
import { OperationalArgs } from './OperationalArgs';

interface IProps {
  showDelete?: boolean;
  notPreview?: boolean;
  data: LaunchDataMapBaseNodeTypeType;
  title?: string;
  currentId?: TabsId;
  onTabChange?: (id: TabsId) => void;
  showOperationalArgs?: boolean;
}

export const Preview: React.FC<IProps> = observer(
  ({
    showDelete = false,
    notPreview = true,
    data,
    title,
    currentId = TabsId.ARG,
    onTabChange,
    showOperationalArgs,
  }) => {
    const [tabId, setTabId] = useState<TabsId>(TabsId.ARG);
    const { t } = useTranslation();
    useEffect(() => {
      setTabId(currentId);
    }, [currentId]);

    const hasOperationalArgs = useMemo(
      () => Object.keys(data?.operationalArgs || {}).length > 0,
      [data.operationalArgs],
    );

    let sectionIndex = 0;
    const sectionArray = data.comArgRules && Object.keys(data.comArgRules);
    return (
      <div className={cx({ [styles.cliCode]: true, [styles.hasTitle]: !!title })}>
        <div className={styles.tabs}>
          <div
            className={cx({ [styles.current]: tabId === TabsId.ARG })}
            onClick={() => {
              setTabId(TabsId.ARG);
              onTabChange && onTabChange(TabsId.ARG);
            }}
          >
            {t('components.preview.argumentsRules')}
          </div>
          <div
            className={cx({ [styles.current]: tabId === TabsId.ENV })}
            onClick={() => {
              setTabId(TabsId.ENV);
              onTabChange && onTabChange(TabsId.ENV);
            }}
          >
            {t('components.preview.environmentRules')}
          </div>
          {showOperationalArgs && (
            <div
              className={cx({ [styles.current]: tabId === TabsId.OPS })}
              onClick={() => {
                setTabId(TabsId.OPS);
                onTabChange && onTabChange(TabsId.OPS);
              }}
            >
              Operational Args
            </div>
          )}
        </div>
        {title && <div className={styles.title}>{title}</div>}
        <div className={styles.body}>
          {tabId === TabsId.ARG && (
            <>
              {(!data.comArgRules || sectionArray.length <= 0) && <p className={styles.tc}>{t('components.noData')}</p>}
              {data.comArgRules &&
                sectionArray.map((section: string) => {
                  const findBasePath = data.comArgRules[section].data.find(
                    (pIten: LaunchConfigurationItemFormData) => pIten.humpKey === 'basePath',
                  );
                  const basePath = { payload: findBasePath ? findBasePath.payload : '' };
                  return (
                    <div key={section}>
                      {data.comArgRules[section].data.map((pIten: any, pItenIndex: number) => {
                        if (pIten.action === RuleAction.REMOVE) return;
                        return (
                          <div key={`${pIten.humpKey}_${pIten.payload}_${pItenIndex}`}>
                            {sectionArray[sectionIndex] !== section && (sectionIndex += 1) && <p>{data.delimiter}</p>}
                            <Item showDelete={showDelete} notPreview={notPreview} data={pIten} basePath={basePath} />
                          </div>
                        );
                      })}
                      {data.comArgRules[section].extraArgData.map((extraArg: string | undefined, index: number) => (
                        <p key={index}>
                          <span className={styles.overwritable}>{extraArg}</span>
                        </p>
                      ))}
                    </div>
                  );
                })}
            </>
          )}
          {tabId === TabsId.ENV && (
            <>
              {data.envRules?.length <= 0 && data.envExtra?.length <= 0 && (
                <p className={styles.tc}>{t('components.preview.nodeEnvironment')}</p>
              )}
              {data.envRules &&
                data.envRules.map((i: any) => {
                  if (!i.key) return;
                  return <Item showDelete={showDelete} notPreview={notPreview} key={i.key} data={i} />;
                })}

              {data.envExtra?.map((extraArg: string | undefined, index: number) => (
                <p key={index}>
                  <span className={styles.overwritable}>{extraArg}</span>
                </p>
              ))}
            </>
          )}
          {showOperationalArgs && tabId === TabsId.OPS && (
            <>
              {!hasOperationalArgs && <p className={styles.tc}>No Operational Args configured</p>}
              {hasOperationalArgs && <OperationalArgs args={data.operationalArgs} />}
            </>
          )}
        </div>
      </div>
    );
  },
);

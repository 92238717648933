import React from 'react';
import cx from 'classnames';
import { Button, IButtonProps } from '@blueprintjs/core';
import styles from './style.module.scss';
import Loading from 'react-loading';

interface IProps extends IButtonProps {
  className?: string;
  loading?: boolean;
}

export const SubmitButton: React.FC<IProps> = (props) => {
  const { loading, className, children, ...other } = props;
  return (
    <Button className={cx(styles.btn, className, loading ? styles.loading : '')} {...other}>
      {loading && (
        <span className={styles.loadingIcon}>
          <Loading color={'white'} type={'spin'} height={20} width={20} />
        </span>
      )}
      <span className={styles.text}>{children}</span>
    </Button>
  );
};

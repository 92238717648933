import { Button, Classes, FormGroup, HTMLSelect, InputGroup, Intent, Icon } from '@blueprintjs/core';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import { Col, Row, FormError, DetailBox, SubmitButton } from 'components';
import { ImageRepository, checkImageVersion } from '../';
import { useStore } from '../../store';
import styles from './style.module.scss';

const lang = (t: TFunction, key: string, options?: any) => t(`modules.createNetworkSpecForm.${key}`, options);

interface FormValues {
  networkDisplayName: string;
  protocol: string;
  imageRepository: string;
  imageVersion: string;
  tags: string | undefined;
}

interface IProps {
  initData: {
    networkDisplayName: string | undefined;
    protocol: string | undefined;
    imageRepository: string | undefined;
    tags: string | undefined;
    imageVersion: string | undefined;
  };
  onBack: () => void;
  onSubmit: (data: FormValues) => void;
}

const schema = yup.object().shape({
  networkDisplayName: yup.string().required('Display Name is a required field'),
  protocol: yup.string().required(),
  imageRepository: yup.string().required('Image Repository is a required field'),
  imageVersion: yup.string().required(),
  tags: yup.string().required(),
});

export const CreateNetworkSpecForm: React.FC<IProps> = observer((props) => {
  const { networkDisplayName, protocol, imageRepository, tags, imageVersion } = props.initData;

  const [checkImageVersionProcessing, setCheckImageVersionProcessing] = useState(false);
  const { t } = useTranslation();
  const { info, networks } = useStore();
  const { handleSubmit, errors, control, setValue, register, setError, clearError } = useForm({
    validationSchema: schema,
    defaultValues: {
      networkDisplayName,
      protocol: protocol || info.protocols[0]?.key,
      imageRepository,
      tags,
      imageVersion,
    },
  });

  const doSubmit = async (values: any) => {
    if (/[^\a-\z\A-\Z0-9\_\-\s]/.test(values.networkDisplayName)) {
      return setError('networkDisplayName', 'notMatch', lang(t, 'displayNameError'));
    }
    const { imageRepository, imageVersion, tags, protocol } = values;
    setCheckImageVersionProcessing(true);
    const hasError = await checkImageVersion(networks, t, imageRepository, imageVersion, protocol);
    setCheckImageVersionProcessing(false);
    if (hasError) setError('imageRepository', 'error', hasError);
    else
      props.onSubmit({
        ...values,
        imageRepository: values.imageRepository.trim(),
        imageVersion: values.imageVersion.trim(),
        networkDisplayName: values.networkDisplayName.trim(),
        tags: tags,
      });
  };
  return (
    <div className={styles.container}>
      <h2>{lang(t, 'title')}</h2>
      <form className={styles.createFormContainer} onSubmit={handleSubmit(doSubmit)}>
        <DetailBox>
          <div className={styles.formOutside}>
            <input type="hidden" ref={register} name="imageVersion" />
            <input type="hidden" ref={register} name="imageRepository" />
            <input type="hidden" ref={register} name="tags" />
            <FormGroup label={lang(t, 'displayName')}>
              <Controller
                name="networkDisplayName"
                large
                control={control}
                defaultValue={networkDisplayName || ''}
                as={
                  <InputGroup
                    placeholder={lang(t, 'displayName')}
                    intent={errors.networkDisplayName ? Intent.DANGER : Intent.NONE}
                  />
                }
              />
              <FormError>{errors.networkDisplayName && errors.networkDisplayName.message}</FormError>
            </FormGroup>
            <FormGroup label={lang(t, 'protocol')}>
              <Controller
                name="protocol"
                control={control}
                defaultValue={protocol || info.protocols[0]?.key}
                as={
                  <HTMLSelect large fill>
                    {info.protocols.map((protocol) => (
                      <option key={protocol.key} value={protocol.key}>
                        {protocol.name}
                      </option>
                    ))}
                  </HTMLSelect>
                }
              />
            </FormGroup>
            <ImageRepository
              initData={{
                imageRepository,
                tags,
                imageVersion,
              }}
              error={errors?.imageRepository?.message}
              onCallback={({ imageRepository, imageVersion, tags }) => {
                clearError('imageRepository');
                setValue('imageRepository', imageRepository || '');
                setValue('imageVersion', imageVersion || '');
                setValue('tags', tags || '');
              }}
            />
          </div>
        </DetailBox>
        <Row className={styles.footer}>
          <Col unit={12}>
            <Button large onClick={props.onBack}>
              {lang(t, 'back')}
            </Button>
          </Col>
          <Col unit={12} className={styles.alignRight}>
            <SubmitButton
              loading={checkImageVersionProcessing}
              type={'submit'}
              disabled={checkImageVersionProcessing}
              large
              intent={Intent.PRIMARY}
            >
              {lang(t, 'submit')}
            </SubmitButton>
          </Col>
        </Row>
      </form>
    </div>
  );
});

import { TFunction } from 'i18next';
import { LaunchConfigurationValueType, LaunchConfigurationItemFormData } from '@/components/Preview/help';

import { Instance } from 'mobx-state-tree';

export enum RuleAction {
  ADD = 'add',
  REPLACE = 'replace',
  REMOVE = 'remove',
  UPSERT = 'upsert',
  NULL = '',
}

export interface ArgDataFormat {
  action?: RuleAction;
  payload: {
    key: string;
    value: {
      payload: string;
      inputType: string;
    };
    options: {
      overwritable: boolean;
    };
    category: string;
  };
}

export enum ConfigErrorCode {
  EMPTY = 'empty',
  SAME = 'same',
}

export const checkHasError = (data: Array<any>) => {
  const hasEmpty = data.find((i: any) => !i.key && i.action !== RuleAction.REMOVE);
  if (hasEmpty) return ConfigErrorCode.EMPTY;
  let hasSame = false;
  data.forEach((i: any, index) => {
    const isExist = data.find(
      (item: any, itemIndex) =>
        i.key &&
        i.action !== RuleAction.REMOVE &&
        item.action !== RuleAction.REMOVE &&
        i.key === item.key &&
        index !== itemIndex,
    );
    if (isExist) hasSame = true;
  });
  if (hasSame) return ConfigErrorCode.SAME;
  return false;
};

export interface DataMapItem extends LaunchConfigurationItemFormData {
  originalOverwritable?: boolean;
}

export const lang = (t: TFunction, key: string, options?: any) => t(`modules.updateNetworkRules.${key}`, options);

import React, { FC, useState, useEffect } from 'react';
import { NodeCard } from '../NodeCard';
import { INodeSimple } from '@/store/nodes';
import { Space } from 'antd';
import styles from './style.module.scss';

interface IProps {
  workspaceId: string;
  nodes: INodeSimple[];
}

type RegionList = {
  [key: string]: INodeSimple[];
};

const regionName = (name: string | undefined | null) => {
  if (!name) return;
  const nameArray = name.split(' - ');
  nameArray[0] = nameArray[0].toUpperCase();
  return nameArray.join(' - ');
};

export const NodeRegion: React.FC<IProps> = ({ workspaceId, nodes }) => {
  const [regionList, setRegionList] = useState<RegionList>({});

  const filteRegion = (nodes: INodeSimple[]) => {
    const result: RegionList = {};
    nodes.forEach((e: INodeSimple) => {
      if (e.clusterDetail) {
        if (e.clusterDetail.name !== undefined) {
          result[e.clusterDetail.name] ? result[e.clusterDetail.name].push(e) : (result[e.clusterDetail.name] = [e]);
        }
      }
    });
    setRegionList(result);
  };

  useEffect(() => {
    filteRegion(nodes);
  }, []);

  return (
    <Space direction="vertical" size={'large'} className={styles.width100}>
      {Object.keys(regionList).map((e) => {
        return (
          <div key={e}>
            <div className={styles.regionName}>{regionName(e)}</div>
            <div className={styles.nodeList}>
              {regionList[e].map((node) => {
                return <NodeCard key={node.id} workspaceId={workspaceId} node={node} />;
              })}
              {regionList[e].length % 2 !== 0 ? (
                <NodeCard displayNone={true} key={123} workspaceId={workspaceId} node={regionList[e][0]} />
              ) : (
                ''
              )}
            </div>
          </div>
        );
      })}
    </Space>
  );
};

import { sortBy } from 'lodash';
// eslint-disable-next-line import/named
import { cast, flow, getParent, Instance, types, getSnapshot } from 'mobx-state-tree';
import { api, filterErrorMessage } from '../utils/api';
import { pruningModeNodeTypeMap } from '@/configuration';
import { Store } from './index';
import { pickOutError } from '@/utils/tools';
import { Iservice } from './apiService';
import { cloneDeep } from 'lodash';
import { mxConfig } from '@/config';

export enum NetworkSpecStatus {
  Pending = 'pending',
  BootstrapRequired = 'bootstrapRequired',
  GenerateChainSpecFailure = 'generateChainSpecFailure',
  Enabled = 'enabled',
  Disabled = 'disabled',
  Deleted = 'deleted',
  Hidden = 'hidden',
}

export const NetworStatsError = types.map(
  types.map(
    types.map(
      types.maybeNull(
        types.model({
          list: types.optional(
            types.array(
              types.model({
                cluster: types.string,
                count: types.number,
              }),
            ),
            [],
          ),
          count: types.number,
        }),
      ),
    ),
  ),
);

export const MethodStatsError = types.map(
  types.map(
    types.map(
      types.maybeNull(
        types.model({
          list: types.optional(
            types.array(
              types.model({
                cluster: types.string,
                count: types.number,
              }),
            ),
            [],
          ),
          count: types.number,
        }),
      ),
    ),
  ),
);

const NetworStats = types.model({
  weighted: types.map(types.maybeNull(types.number)),
  stats: types.map(types.map(types.map(types.maybeNull(types.number)))),
  errors: NetworStatsError,
});

export const Backup = types.model({
  networkSpec: types.string,
  protocol: types.string,
  clusterHash: types.string,
  id: types.string,
  storageSize: types.maybe(types.number),
  pruningMode: types.maybe(types.string),
});

export const Recommend = types.model({
  nodeSpec: types.string,
  storageSize: types.number,
  imageVersion: types.string,
  nodeSpecMultiplier: types.maybe(types.number),
});

export const NodeType = types.model({
  key: types.string,
  recommend: types.maybeNull(Recommend),
});

export const NetworkSpec = types.model({
  key: types.string,
  name: types.string,
  displayName: types.string,
  protocolKey: types.string,
  isPublic: types.maybe(types.boolean),
  imageRepository: types.maybeNull(types.string),
  workspaceId: types.maybeNull(types.string),
  status: types.enumeration(Object.values(NetworkSpecStatus)),
  versions: types.optional(types.array(types.string), []),
  metadata: types.frozen(),
  recommend: types.maybeNull(Recommend),
  nodeTypes: types.optional(types.array(types.frozen()), []),
  subtitle: types.maybeNull(types.string),
  isMainNet: types.maybeNull(types.boolean),
  pictures: types.maybeNull(types.frozen()),
  services: types.optional(types.array(types.string), []),
  links: types.array(types.frozen()),
  type: types.maybeNull(types.array(types.string)),
  cacheStatus: types.maybeNull(types.enumeration(Object.values(NetworkSpecStatus))),
  // teamName: types.optional(types.string, ''),
});

const COMMING_SOON_NETWORKS: INetworkSpec[] = [] as INetworkSpec[];

const BootnodeMetaData = types.model({
  nodeId: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
});

const RecommendMetaData = types.model({
  imageVersion: types.maybeNull(types.string),
  nodeSpec: types.maybeNull(types.string),
  storageSize: types.maybeNull(types.number),
});

export const BackupMapType = types.model({
  allowNodeType: types.optional(types.array(types.string), []),
  detail: types.optional(
    types.array(
      types.model({
        cluster: types.maybe(types.string),
        storageSize: types.maybe(types.number),
      }),
    ),
    [],
  ),
});

export const NetworkMetaData = types.model({
  versionList: types.maybe(types.array(types.string)),
  bootnodes: types.maybe(types.array(BootnodeMetaData)),
  recommend: types.maybe(RecommendMetaData),
});

const MethodStats = types.model({
  savedMethods: types.map(types.number),
  stats: types.map(types.map(types.map(types.maybeNull(types.number)))),
  errors: MethodStatsError,
});

export type INetworkMetaData = Instance<typeof NetworkMetaData>;

export type INetworkSpec = Instance<typeof NetworkSpec>;

export const allowNodeType = (data: Instance<typeof Backup>[]) => {
  let result: string[] = [];
  if (!data) return result;
  data.forEach((item: Instance<typeof Backup>) => {
    if (item.pruningMode && pruningModeNodeTypeMap[item.pruningMode]) {
      [...result, ...pruningModeNodeTypeMap[item.pruningMode]].forEach((nodeType: string) => {
        if (result.indexOf(nodeType) === -1) {
          result.push(nodeType);
        }
      });
    }
  });
  return result;
};

export const Networks = types
  .model({
    state: types.optional(types.enumeration(['pending', 'done', 'error']), 'pending'),
    error: types.maybeNull(types.string),
    list: types.optional(types.array(NetworkSpec), []),
    services: types.optional(types.array(Iservice), []),
    privateList: types.optional(types.array(NetworkSpec), []),
    backups: types.optional(types.array(Backup), []),
    methodSummary: types.maybeNull(MethodStats),
    methodStats: types.maybeNull(MethodStats),
    networkStats: types.maybeNull(NetworStats),
    errorMessage: types.maybe(types.string),
  })
  .views((self) => ({
    getPictures(key: string) {
      const result = self.list.find((i) => i.key === key);
      return result?.metadata?.pictures || {};
    },
    methodSummaryMap() {
      if (!self.methodSummary || self.methodSummary.stats.size === 0) {
        return {};
      }
      return getSnapshot(self.methodSummary.stats);
    },

    methodStatsMap() {
      if (!self.methodStats || self.methodStats.stats.size === 0) {
        return {};
      }
      return getSnapshot(self.methodStats.stats);
    },

    networkStatsMap() {
      if (!self.networkStats || self.networkStats.stats.size === 0) {
        return {};
      }
      return getSnapshot(self.networkStats.stats);
    },

    methodErrorsMap() {
      if (!self.methodStats || !self.methodStats.errors) {
        return {};
      }
      return cloneDeep(getSnapshot(self.methodStats.errors));
    },

    networkErrorsMap() {
      if (!self.networkStats || !self.networkStats.errors) {
        return {};
      }
      return cloneDeep(getSnapshot(self.networkStats.errors));
    },
    savedMethods() {
      if (!self.methodStats || self.methodStats.savedMethods.size === 0) {
        return [];
      }
      const snapshot = getSnapshot(self.methodStats.savedMethods);
      return Object.keys(snapshot);
    },

    get backupMap() {
      const result = {} as {
        [key: string]: Instance<typeof Backup>[];
      };
      self.backups.forEach((item: Instance<typeof Backup>) => {
        if (!result[item.networkSpec]) {
          result[item.networkSpec] = [item] as Instance<typeof Backup>[];
        } else {
          result[item.networkSpec].push(item);
        }
      });
      return result;
    },

    get enabledNetworks() {
      const networkList: INetworkSpec[] = [];
      self.list.forEach((item) => {
        const {
          featureFlags: { showUserNetworkSpec },
          networkWhiteList,
        } = mxConfig;
        if (
          item.status === 'enabled' &&
          (showUserNetworkSpec || item.isPublic) &&
          (!networkWhiteList || networkWhiteList.includes(item.key))
        ) {
          networkList.push(item);
        }
      });
      return networkList;
    },

    get userNetworks() {
      return self.list.filter((n) => !n.isPublic);
    },

    get comingSoonNetworks(): INetworkSpec[] {
      return COMMING_SOON_NETWORKS.filter(
        (n) => this.enabledNetworks.findIndex((enabled) => enabled.key === n.key) < 0,
      );
    },

    getByKey(key: string) {
      return self.list.find((i) => i.key === key);
    },

    getEnabledNetworks(isPublic = true) {
      const networkList: INetworkSpec[] = [];
      self.list.forEach((item) => {
        const { networkWhiteList } = mxConfig;
        if (
          ['enabled', 'hidden'].includes(item.status) &&
          item.isPublic === isPublic &&
          (!networkWhiteList || networkWhiteList.includes(item.key))
        ) {
          networkList.push(item);
        }
      });
      return networkList;
    },
  }))
  .actions((self) => ({
    fetchNetworkSpecDetail: flow(function* (isPublicNetowk: boolean, key?: string) {
      const wsId = getParent<Instance<typeof Store>>(self).workspaces.current!.id as string;
      try {
        const link = isPublicNetowk
          ? `${mxConfig.apiUrlV2}/network-specs/${key}`
          : `${mxConfig.apiUrlV2}/workspaces/${wsId}/network-specs/${key}`;
        return yield api.get(link);
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    }),
    fetchNetworkPromoteDetail: flow(function* (wsId?: string, id?: string) {
      try {
        return yield api.get(`${mxConfig.apiUrlV2}/partners/${wsId}/networks-promote/${id}`);
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    }),
    publishNetwork: flow(function* (pId, data) {
      self.state = 'pending';
      try {
        yield api.post(`${mxConfig.apiUrlV2}/partners/${pId}/networks-promote`, data);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        console.log(err);
        const errorMessage = pickOutError(err);
        Array.isArray(errorMessage);
        self.errorMessage = Array.isArray(errorMessage) ? errorMessage.join(',') : errorMessage;
      }
    }),
    uploadFile: flow(function* (data: any) {
      const wsId = getParent<Instance<typeof Store>>(self).workspaces.current!.id as string;
      return yield api.post(`/workspaces/${wsId}/private-file/upload`, data);
    }),
    clearList: () => {
      self.list = [] as any;
    },
    fetchPrivateList: flow(function* (wsIdInput?: string) {
      self.state = 'pending';
      let wsId = wsIdInput;
      if (!wsId) {
        wsId = getParent<Instance<typeof Store>>(self).workspaces.current!.id as string;
      }
      try {
        self.privateList = yield api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/network-specs`);
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    }),

    fetchBackup: flow(function* (wsIdInput?: string) {
      self.state = 'pending';
      let wsId = wsIdInput;
      if (!wsId) {
        wsId = getParent<Instance<typeof Store>>(self).workspaces.current!.id as string;
      }
      try {
        self.backups = [] as any;

        let publicBackup: Instance<typeof Backup>[] = [];
        let workspaceBackup: Instance<typeof Backup>[] = [];
        yield Promise.all([
          api.get(`${mxConfig.apiUrlV2}/backup`),
          api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/backup`),
        ]).then(([publicBackupValue, workspaceBackupValue]) => {
          publicBackup = publicBackupValue as any;
          workspaceBackup = workspaceBackupValue as any;
        });
        const combinedList: Instance<typeof Backup>[] = [...publicBackup];
        workspaceBackup.forEach((item: Instance<typeof Backup>) => {
          const isExistKey = publicBackup.findIndex((i: Instance<typeof Backup>) => i.networkSpec === item.networkSpec);
          if (isExistKey < 0) combinedList.push(item);
        });
        self.backups = cast(combinedList);
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    }),
    fetchAllNetowrks: flow(function* (wsIdInput?: string) {
      self.state = 'pending';
      let wsId = wsIdInput;
      if (!wsId) {
        wsId = getParent<Instance<typeof Store>>(self).workspaces.current!.id as string;
      }
      try {
        self.list = [] as any;
        let privateList: INetworkSpec[] = [];
        let publickList: INetworkSpec[] = [];
        yield Promise.all([
          api.get(`${mxConfig.apiUrlV2}/workspaces/${wsId}/network-specs`),
          api.get(`${mxConfig.apiUrlV2}/network-specs`),
        ]).then(([privateListValue, publickListValue]) => {
          privateList = privateListValue as any;
          publickList = publickListValue as any;
        });
        const combinedList: INetworkSpec[] = [...publickList];
        privateList.forEach((item: INetworkSpec) => {
          const isExistKey = publickList.findIndex((i: INetworkSpec) => i.key === item.key);
          if (isExistKey < 0) combinedList.push(item);
        });
        self.list = cast(
          sortBy(combinedList, [
            (n) => n.displayName.toLowerCase(),
            (n) => !n.isPublic,
            (n) => (n.isPublic ? '' : n.key),
          ]),
        );
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    }),
    fetchAllNetworkForApiService: flow(function* () {
      self.state = 'pending';
      try {
        self.list = [] as any;
        let publicList: INetworkSpec[] = yield api.get(`${mxConfig.apiUrl}/public/network-specs`);
        self.list = publicList as any;
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    }),
    fetchNetworkDetailForApiService: flow(function* (networkKey: string) {
      self.state = 'pending';
      try {
        let networkDetail: INetworkSpec = yield api.get(`${mxConfig.apiUrl}/public/network-specs/${networkKey}`);
        self.state = 'done';
        return networkDetail;
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    }),
    clearPrivateList: () => {
      self.privateList = [] as any;
    },
    fetchPublicList: flow(function* (wsIdInput?: string) {
      self.state = 'pending';
      let wsId = wsIdInput;
      if (!wsId) {
        wsId = getParent<Instance<typeof Store>>(self).workspaces.current!.id as string;
      }
      try {
        self.list = [] as any;
        // `${mxConfig.apiUrlV2}/network-specs`
        const publickList: INetworkSpec[] = yield api.get(`${mxConfig.apiUrlV2}/network-specs`);
        self.list = cast(sortBy(publickList, [(n) => !n.isPublic, (n) => (n.isPublic ? '' : n.key)]));
        self.state = 'done';
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    }),

    delete: flow(function* (wsId: string, id: string) {
      self.state = 'pending';
      self.error = null;
      try {
        yield api.delete(`${mxConfig.apiUrlV2}/workspaces/${wsId}/network-specs/${id}`);
      } catch (err) {
        console.log(err);
        self.state = 'error';
        self.errorMessage = filterErrorMessage(err);
      }
    }),
    deleteApp: flow(function* (wsId: string, id: string) {
      self.state = 'pending';
      self.error = null;
      try {
        yield api.delete(`${mxConfig.apiUrlV2}/partners/${wsId}/networks-promote/${id}`);
      } catch (err) {
        console.log(err);
        self.state = 'error';
        self.error = pickOutError(err);
      }
    }),

    // createPrivateNetwork: flow(function* (wsId: string, data: any) {
    //   self.state = 'pending';
    //   try {
    //     yield api.post(`/workspaces/${wsId}/private-chains/simple`, data);
    //     self.state = 'done';
    //   } catch (err) {
    //     self.state = 'error';
    //     const msg = err.response.data.message;
    //     if (Array.isArray(msg)) {
    //       self.errorMessage = msg[0];
    //     } else {
    //       self.errorMessage = msg;
    //     }
    //   }
    // }),

    createNetworkSpecs: flow(function* (wsId: string, data: any) {
      self.state = 'pending';
      try {
        yield api.post(`${mxConfig.apiUrlV2}/workspaces/${wsId}/network-specs`, data);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        const msg = pickOutError(err);
        if (Array.isArray(msg)) {
          self.errorMessage = msg[0];
        } else {
          self.errorMessage = msg;
        }
      }
    }),

    getProtocols: flow(function* (protocolKey: string, networkKey?: string) {
      self.state = 'pending';
      try {
        const url: string = networkKey
          ? `${mxConfig.apiUrlV2}/protocols/${protocolKey}/network/${networkKey}`
          : `${mxConfig.apiUrlV2}/protocols/${protocolKey}`;
        const data = yield api.get(url);
        self.state = 'done';
        return data;
      } catch (err) {
        self.state = 'error';
        const msg = pickOutError(err);
        if (Array.isArray(msg)) {
          self.errorMessage = msg[0];
        } else {
          self.errorMessage = msg;
        }
      }
    }),

    checkImage: flow(function* (imageRepository: string, version: string) {
      self.state = 'pending';
      try {
        const result = yield api.post(`/images/check`, {
          imageRepository,
          version,
        });
        self.state = 'done';
        return result;
      } catch (err) {
        self.state = 'error';
        console.log(err);
        self.errorMessage = pickOutError(err);
      }
    }),

    updateNetworkSpecs: flow(function* (wsId, id, data) {
      self.state = 'pending';
      try {
        yield api.put(`${mxConfig.apiUrlV2}/workspaces/${wsId}/network-specs/${id}`, data);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    updateNetworkSpecsRegions: flow(function* (wsId, id, body) {
      self.state = 'pending';
      try {
        yield api.put(`${mxConfig.apiUrlV2}/workspaces/${wsId}/network-specs/${id}/regions`, body);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    getApiServices: flow(function* (wsId: string, duration?: string) {
      self.state = 'pending';
      const queryParams = new URLSearchParams();
      if (duration) queryParams.append('duration', duration);
      try {
        self.services = yield api.get(`/partners/${wsId}/report/networks?${queryParams.toString()}`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    cleanApiServices: flow(function* (wsId: string) {
      self.state = 'pending';
      self.services = [] as any;
      self.state = 'done';
    }),

    getMethodStatsHourly: flow(function* (
      wsId: string,
      network: string,
      duration: string,
      method?: string[],
      startTime?: string,
      endTime?: string,
    ) {
      self.state = 'pending';
      try {
        const queryParams = new URLSearchParams();
        if (duration !== '') queryParams.append('duration', duration);
        if (startTime && endTime) {
          queryParams.append('startTime', startTime);
          queryParams.append('endTime', endTime);
        }
        if (method) {
          for (const m of method) {
            queryParams.append('method', m);
          }
        }
        self.methodStats = yield api.get(
          `/partners/${wsId}/report/networks/${network}/method-stats?${queryParams.toString()}`,
        );
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    getMethodStatsSummary: flow(function* (
      wsId: string,
      network: string,
      duration: string,
      startTime?: string,
      endTime?: string,
    ) {
      self.state = 'pending';
      try {
        const queryParams = new URLSearchParams();
        if (duration !== '') queryParams.append('duration', duration);
        if (startTime && endTime) {
          queryParams.append('startTime', startTime);
          queryParams.append('endTime', endTime);
        }

        // NOTE: TODO: This using the weighted version for now, until we have daily rolls on the aggregator
        queryParams.append('weighted', 'true');
        self.methodSummary = yield api.get(
          `/partners/${wsId}/report/networks/${network}/method-stats?${queryParams.toString()}`,
        );
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
    getNetworkStats: flow(function* (
      wsId: string,
      network: string,
      duration: string,
      startTime?: string,
      endTime?: string,
    ) {
      self.state = 'pending';
      try {
        const queryParams = new URLSearchParams();
        if (duration !== '') queryParams.append('duration', duration);
        if (startTime && endTime) {
          queryParams.append('startTime', startTime);
          queryParams.append('endTime', endTime);
        }
        self.networkStats = yield api.get(
          `/partners/${wsId}/report/networks/${network}/stats?${queryParams.toString()}`,
        );
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    // uploadFile: flow(function* (id: string, data: any) {
    //   return yield api.post(`/workspaces/${id}/private-file/upload`, data);
    // }),
  }));

import React from 'react';
import cx from 'classnames';
import { Tabs as BlueTabs } from '@blueprintjs/core';
import styles from './style.module.scss';

interface IProps {
  className?: string;
  selectedTabId?: string;
  onChange: (key: string) => void;
}

export const Tabs: React.FC<IProps> = (props) => {
  const { className, children, ...other } = props;
  return (
    <BlueTabs className={styles.tabs} {...other}>
      {children}
    </BlueTabs>
  );
};

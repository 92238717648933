import { Popover, Button, Menu, PopoverPosition, MenuItem } from '@blueprintjs/core';
import { Tag } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { INodeSimple } from '@/store/nodes';
import styles from './style.module.scss';
import { DetailBox, Logo } from 'components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BlockchainIcon } from './imgs/blockchainIcon.svg';
import { ReactComponent as UnionIcon } from './imgs/unionIcon.svg';
import { ReactComponent as StorageIcon } from './imgs/storageIcon.svg';
import { ReactComponent as MoreIcon } from './imgs/moreIcon.svg';
import { ReactComponent as NodeNameIcon } from './imgs/nodeNameIcon.svg';
import { ReactComponent as FlagIcon } from './imgs/nodeFlagIcon.svg';
import { UpgradeImageDialog } from './UpgradeImageDialog';
import { UpgradeStorageDialog } from './UpgradeStorageDialog';
import { UpgradeNodeSpecDialog } from './UpgradeNodeSpecDialog';
import { useStore } from 'store';
import { capitalize } from 'lodash';
import { StatusMap, NodeStatus } from '@/store/nodes';
import cx from 'classnames';

interface IProps {
  node: INodeSimple;
  workspaceId: string;
  displayNone?: boolean;
}

export const NodeCard: React.FC<IProps> = ({ workspaceId, node, displayNone }) => {
  const { t } = useTranslation();
  const { networks, workspaces, auth } = useStore();
  const { icon } = networks.getPictures(node.networkSpecKey);
  const [dialog, setDialog] = useState<string | undefined>();
  const nodeSize = (nodeSpec?: string, nodeSpecMultiplier?: number) => {
    if (nodeSpec === 'unit') return `${nodeSpecMultiplier} Units`;
    return nodeSpec;
  };
  const isSuperAdmin = auth.user?.isAdmin === true;

  return !displayNone ? (
    <div className={styles.cardOutside}>
      <Link className={styles.container} key={node.id} to={`/workspaces/${workspaceId}/nodes/${node.id}`}>
        <DetailBox hover>
          <div className={styles.logoOutside}>
            <Logo className={styles.logo} size={58} src={icon} />
            <div className={styles.status}>
              <Tag className={cx({ [styles.state]: true, [styles.topMargin]: true })} color={StatusMap(node.status)}>
                {t(`components.nodeStatus.${node.status}`)}
              </Tag>
            </div>
          </div>
          <div className={styles.nodeDetail}>
            <h5 className={styles.title}>
              <span>{node.name}</span>
              {isSuperAdmin && <span className={styles.nodeExtra}>{node.id}</span>}
            </h5>
            <div className={cx(styles.items, styles.image)}>
              <div>
                <span>Image</span>
                <span className={styles.itemWords}>{node.image}</span>
              </div>
            </div>
            <div className={styles.items}>
              <div>
                <BlockchainIcon />
                <span className={styles.itemWords}>{capitalize(node.type)}</span>
              </div>
              <div>
                {node.flag && (
                  <>
                    <FlagIcon />
                    <span className={styles.itemWords}>{node.flag}</span>
                  </>
                )}
              </div>
              <div>
                <UnionIcon />
                <span className={styles.itemWords}>{nodeSize(node.nodeSpec, node.nodeSpecMultiplier)}</span>
              </div>
              <div>
                <StorageIcon />
                <span className={styles.itemWords}>{node.storage}</span>
              </div>
              {node.metadata?.labels?.nodePool && (
                <div>
                  <NodeNameIcon />
                  <span className={styles.itemWords}>{node.metadata?.labels?.nodePool}</span>
                </div>
              )}
            </div>
          </div>
        </DetailBox>
      </Link>
      {![NodeStatus.Terminated, NodeStatus.Terminating].includes(node.status) && (
        <Popover
          disabled={workspaces.current!.suspendTaskDone}
          className={styles.popover}
          minimal
          position={PopoverPosition.BOTTOM_RIGHT}
          popoverClassName="network-actions"
        >
          <Button className={cx(workspaces.current!.suspendTaskDone && styles.disabled)} icon={<MoreIcon />} minimal />
          <Menu>
            <MenuItem
              text={t('pages.nodeDetail.buttonGroup.upgradeNodeSpec')}
              onClick={() => setDialog('upgradeNodeSpec')}
            />
            <MenuItem
              text={t('pages.nodeDetail.buttonGroup.upgradeStorage')}
              onClick={() => setDialog('upgradeStorage')}
            />
            <MenuItem text={t('pages.nodeDetail.buttonGroup.upgradeImage')} onClick={() => setDialog('upgradeImage')} />
          </Menu>
        </Popover>
      )}

      <UpgradeNodeSpecDialog
        id={node.id}
        nodeSpec={node.nodeSpec!}
        multiplier={node.nodeSpecMultiplier!}
        isOpen={dialog === 'upgradeNodeSpec'}
        onClose={() => setDialog(undefined)}
      />
      <UpgradeStorageDialog
        id={node.id}
        storage={node.storage}
        isOpen={dialog === 'upgradeStorage'}
        onClose={() => setDialog(undefined)}
      />
      <UpgradeImageDialog
        id={node.id}
        image={node.image}
        isOpen={dialog === 'upgradeImage'}
        onClose={() => setDialog(undefined)}
      />
    </div>
  ) : (
    <div className={styles.cardOutside}></div>
  );
};

import styles from '../style.module.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../store';
import { useTranslation } from 'react-i18next';
import { lang, PanelProps } from '../helper';

export const SubstratePanel: React.FC<PanelProps> = observer(({ nodes }) => {
  const { t } = useTranslation();
  const is_syncing = nodes?.getMetric('is_syncing');
  return (
    <ul className={styles.capsule}>
      <li>
        <div className={styles.mainCap}>
          <div className={styles.th}>{lang(t, 'detailTitle.lastBlock')}</div>
          <div className={styles.para}>{nodes?.getMetric('blocknumber', false)?.toLocaleString() ?? '--'}</div>
        </div>
      </li>
      <li>
        <div className={styles.mainCap}>
          <div className={styles.th}>{lang(t, 'detailTitle.finalizedBlocks')}</div>
          <div className={styles.para}>
            {nodes?.getMetric('finalized_blocknumber', false)?.toLocaleString() ?? '--'}
          </div>
        </div>
      </li>
      <li>
        <div className={styles.mainCap}>
          <div className={styles.th}>{lang(t, 'detailTitle.peerCount')}</div>
          <div className={styles.para}>{nodes?.getMetric('peer_count', false)?.toLocaleString() ?? '--'}</div>
        </div>
      </li>
      <li>
        <div className={styles.mainCap}>
          <div className={styles.th}>{lang(t, 'detailTitle.pendingExtrinsic')}</div>
          <div className={styles.para}>
            {nodes?.getMetric('pending_extrinsic_count', false)?.toLocaleString() ?? '--'}
          </div>
        </div>
      </li>
      <li>
        <div className={styles.mainCap}>
          <div className={styles.th}>{lang(t, 'detailTitle.syncing')}</div>
          <div className={styles.para}>{is_syncing === undefined ? '--' : is_syncing ? 'Syncing' : 'Synced'}</div>
        </div>
      </li>
    </ul>
  );
});

import {
  FormGroup,
  InputGroup,
  ButtonGroup,
  Button,
  Popover,
  Menu,
  MenuItem,
  PopoverPosition,
  Icon,
  Classes,
  Position,
  Intent,
} from '@blueprintjs/core';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { FormError, SearchSelect } from 'components';
import { Message } from 'react-hook-form';
import { useStore } from 'store';
import { imageVersionTags } from '@/configuration';
import { IconName, IconNames } from '@blueprintjs/icons';
import { Col, Row, Popover as PopoverAntd } from 'antd';
import styles from './style.module.scss';

const reCheckTimes = 18;

const lang = (t: TFunction, key: string, options?: any) => t(`modules.createNetworkSpecForm.${key}`, options);

interface FormValues {
  imageRepository?: string;
  imageVersion?: string;
  tags?: string | undefined;
}

interface IProps {
  initData: {
    imageRepository: string | undefined;
    tags: string | undefined;
    imageVersion: string | undefined;
  };
  error?: Message | null;
  onCallback: ({ imageRepository, imageVersion }: FormValues) => void;
}

export const checkImageVersion = async (
  networks: any,
  t: any,
  imageRepository: string,
  imageVersion: string,
  protocolKey: string,
  reCheckTime = 1,
): Promise<any> => {
  const data = await networks.checkImage(imageRepository, imageVersion, protocolKey);
  if (networks.state === 'error') {
    return networks?.errorMessage || 'Request error';
  }
  const reason: string = data?.config?.checkStatus?.reason || 'Internal error';
  const { status } = data;
  if (status === 'fail') {
    return lang(t, 'checkFail') + ': ' + reason;
  } else if ((reCheckTime >= reCheckTimes && status !== 'success') || status === 'timeout') {
    return lang(t, 'checkTimeout');
  } else if (status === 'success') {
    return null;
  } else {
    let check = false;
    let promise = new Promise((resolve) => {
      setTimeout(async () => {
        check = await checkImageVersion(networks, t, imageRepository, imageVersion, protocolKey, ++reCheckTime);
        resolve(check);
      }, 5000);
    });
    return promise;
  }
};

export const ImageRepository: React.FC<IProps> = observer(({ error, onCallback, initData }) => {
  const [formValue, setFormValue] = useState<FormValues>(initData);
  const [imageVersions, setImageVersions] = useState([]);
  const [errors, setErrors] = useState<string | undefined>();
  const [currentTag, setCurrentTag] = useState<string | undefined>(initData.tags || imageVersionTags[0]);
  const [imageRepositoryOldValue, setImageRepositoryOldValue] = useState();
  const { info } = useStore();
  const { t } = useTranslation();

  const synceValue = (values: FormValues) => {
    setFormValue(values);
    onCallback(values);
  };
  const onImageRepositoryChange = async (e: any) => {
    const value = e.target.value.replace(/(^\s*)|(\s*$)/g, '');
    if (imageRepositoryOldValue === value) return;
    setImageRepositoryOldValue(value);

    if (value) {
      synceValue({
        imageVersion: undefined,
        imageRepository: value,
        tags: currentTag,
      });
      setImageVersions([]);
      setErrors(undefined);
      const result = await info.fetchImageVersions(value);
      if (result) {
        setImageVersions(result);
        synceValue({
          imageRepository: value,
          imageVersion: result[0],
          tags: currentTag,
        });
      } else {
        setErrors(lang(t, 'imageRepositoryUnvalid'));
      }
    } else {
      synceValue({
        imageVersion: undefined,
        imageRepository: undefined,
        tags: currentTag,
      });
      setErrors(undefined);
      setImageVersions([]);
    }
  };

  return (
    <div className={styles.container}>
      <FormGroup
        label={
          <div className={styles.imageRepositoryLabel}>
            <span>{lang(t, 'imageRepository')}</span>
            &nbsp;
            <PopoverAntd
              placement="right"
              content={
                <div className={styles.imageRepositoryIssue}>
                  <p>{lang(t, 'imageRepositoryIssue.line1')}</p>
                  <p>
                    {lang(t, 'imageRepositoryIssue.line2')}
                    <br />
                    <span className={styles.urlLink}>parity/polkadot</span>
                  </p>
                  <p>
                    {lang(t, 'imageRepositoryIssue.line3')}
                    <br />
                    <span className={styles.urlLink}>ghcr.io/phanatic/app</span>
                  </p>
                  <p>{lang(t, 'imageRepositoryIssue.line4')}</p>
                  <a
                    target={'_blank'}
                    href={'https://documentation.onfinality.io/support/create-a-network-on-onfinality'}
                  >
                    {lang(t, 'imageRepositoryIssue.docTextLink')}
                  </a>
                </div>
              }
            >
              <Icon icon={IconNames.ISSUE} iconSize={12} />
            </PopoverAntd>
          </div>
        }
      >
        <Row gutter={30} className={styles.imageRepository}>
          <Col span={12}>
            <InputGroup
              defaultValue={initData.imageRepository}
              name="imageRepositoryInCom"
              large
              onBlur={onImageRepositoryChange}
              placeholder={'e.g. parity/polkadot or ghcr.io/phanatic/app'}
            />
            <FormError>{error || errors}</FormError>
          </Col>
          <Col className={styles.versionsSelect} span={8}>
            <SearchSelect
              defaultValue={initData.imageVersion}
              state={info.state}
              items={imageVersions}
              onChange={(v: string) => {
                synceValue({
                  ...formValue,
                  imageVersion: v,
                });
              }}
            />
          </Col>
          <Col>
            <ButtonGroup>
              <Button text={currentTag} />
              <Popover
                className={styles.popover}
                minimal
                position={PopoverPosition.BOTTOM}
                popoverClassName="network-actions"
              >
                <Button icon={'caret-down'} large />
                <Menu>
                  {imageVersionTags.map((i) => (
                    <MenuItem
                      key={i}
                      text={i}
                      onClick={() => {
                        setCurrentTag(i);
                        synceValue({
                          ...formValue,
                          tags: i,
                        });
                      }}
                    />
                  ))}
                </Menu>
              </Popover>
            </ButtonGroup>
          </Col>
        </Row>
      </FormGroup>
    </div>
  );
});

import { lang } from './helper';
import { Button, Classes, Dialog, FormGroup, HTMLSelect, InputGroup, Intent } from '@blueprintjs/core';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { useStore } from '../../store';
import { ErrorToast, SuccessToast } from '../../components/Toast';
import { ErrorHint } from '../../components/ErrorHint';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  afterSubmit: (val: any) => void;
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

export const InviteDialog: React.FC<IProps> = (props) => {
  const { isOpen, onClose, afterSubmit } = props;
  const { workspaces } = useStore();
  const { control, handleSubmit, errors } = useForm({ validationSchema: schema });
  const { t } = useTranslation();
  const [progressing, setProgressing] = useState(false);

  return (
    <Dialog
      title={lang(t, 'dialog.title')}
      isCloseButtonShown={true}
      isOpen={isOpen}
      onClose={onClose}
      canOutsideClickClose={true}
    >
      <form
        onSubmit={handleSubmit(async (val) => {
          const hasMember = workspaces.members.find((i) => i.email === val.email);
          if (hasMember) return ErrorToast(lang(t, 'dialog.hasMembe'));
          const hasInviting = workspaces.invitations.find((i) => i.email === val.email);
          if (hasInviting) return ErrorToast(lang(t, 'dialog.hasInviting'));
          setProgressing(true);
          await workspaces.inviteMember(workspaces.current!.id, val.email, val.role);
          setProgressing(false);
          if (workspaces.state === 'error') {
            ErrorToast(workspaces.errorMessage!);
            return;
          }
          SuccessToast('Invitation sent out');
          afterSubmit(val);
        })}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup className={styles.formGroup} label={lang(t, 'dialog.email')}>
            <Controller
              name="email"
              large
              control={control}
              defaultValue={''}
              as={
                <InputGroup
                  intent={errors.email ? Intent.DANGER : Intent.NONE}
                  placeholder={lang(t, 'dialog.emailPlaceholder')}
                />
              }
            />
            <ErrorHint message={errors.email?.message} />
          </FormGroup>

          <FormGroup className={styles.formGroup} label={lang(t, 'dialog.selectRole')}>
            <Controller
              name="role"
              control={control}
              defaultValue={'member'}
              as={
                <HTMLSelect>
                  <option value="admin">Admin</option>
                  <option value="member">Member</option>
                </HTMLSelect>
              }
            />
          </FormGroup>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button type="submit" intent={Intent.PRIMARY} loading={progressing}>
              {lang(t, 'dialog.submit')}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

import { flow, types, Instance, getParent } from 'mobx-state-tree';
import { api } from '../utils/api';
import { Store } from './index';
import { pickOutError } from '@/utils/tools';
import { planNames } from '@/configuration';
import { sendEvent } from '@/utils/googleAnalytics';
import { mxConfig } from '@/config';

export const enum PlanType {
  API = 'api',
  NETWORK = 'network',
}

const ChargeOutcome = types.model({
  status: types.string,
  failureCode: types.maybeNull(types.string),
  failureMessage: types.maybeNull(types.string),
});

export const PlanPrice = types.model({
  value: types.maybe(types.number),
  month: types.maybe(types.boolean),
  usage: types.maybe(types.boolean),
  kind: types.maybe(types.string),
  level: types.maybe(types.number),
});

export const PlanItem = types.model({
  key: types.string,
  displayName: types.maybe(types.string),
  available: types.boolean,
  price: types.optional(PlanPrice, {}),
  description: types.maybe(types.string),
  type: types.optional(types.enumeration([PlanType.API, PlanType.NETWORK]), PlanType.API),
  selfSelect: types.boolean,
});

export const Plan = types
  .model({
    state: types.optional(types.enumeration(['pending', 'progressing', 'done', 'error']), 'pending'),
    planList: types.optional(types.array(PlanItem), []),
    errorMessage: types.maybe(types.string),
  })
  .views((self) => ({
    listFilter(type: string) {
      const result = [] as Instance<typeof PlanItem>[];
      planNames.forEach((planName) => {
        const c = self.planList.find((i: Instance<typeof PlanItem>) => i.key === planName && i.type === type);
        if (c) result.push(c);
      });
      return result;
    },
    get currentPlan() {
      const planKey = getParent<Instance<typeof Store>>(self).workspaces.current!.plan as string;
      const plan = self.planList.find((i: Instance<typeof PlanItem>) => i.key === planKey);
      return plan;
    },
  }))
  .actions((self) => ({
    fetch: flow(function* () {
      self.state = 'pending';
      try {
        self.planList = yield api.get('/plan');
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),
    changePlan: flow(function* (wsId: string, planKey: string) {
      self.state = 'pending';
      try {
        yield api.put(`/workspaces/${wsId}/change-plan/${planKey}`);
        if (mxConfig.twitterId?.length) {
          //@ts-ignore
          window.twq('event', `tw-${mxConfig.twitterId}-oe2na`);
        }
        sendEvent('change_plan');
        if (planKey === 'growth') {
          sendEvent('change_plan_growth_upgrade');
          //@ts-ignore
          window.twq('event', 'tw-o4cmd-oe2nb', {});
          if (mxConfig.twitterId?.length) {
            //@ts-ignore
            window.twq('event', `tw-${mxConfig.twitterId}-oe2nb`);
          }
        }

        let outcome = yield api.get(`/accounts/invoices/status/${wsId}`);
        let outcomeData = ChargeOutcome.create(outcome);

        while (outcomeData.status === 'pending') {
          yield new Promise((resolve) => setTimeout(resolve, 2000));
          outcome = yield api.get(`/accounts/invoices/status/${wsId}`);
          outcomeData = ChargeOutcome.create(outcome);
        }

        if (outcomeData.status === 'paid') {
          self.state = 'done';
        } else if (outcomeData.status === 'failed') {
          self.state = 'error';
          self.errorMessage = outcomeData.failureMessage || 'Payment failed.';
        }
      } catch (err) {
        console.error(err);
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),
    sendEmailToChangePlan: flow(function* (wsId: string, data: any) {
      self.state = 'pending';
      try {
        yield api.post(`/plan/contactUs`, {
          ...data,
          workspaceId: wsId,
        });
        if (mxConfig.twitterId?.length) {
          //@ts-ignore
          window.twq('event', `tw-${mxConfig.twitterId}-oe2nf`);
        }
        sendEvent('change_plan_custom_upgrade');
        self.state = 'done';
      } catch (err) {
        console.error(err);
        self.errorMessage = pickOutError(err);
        self.state = 'error';
      }
    }),
  }));

import React, { useState } from 'react';
import { Button, Row, Col, Input, Typography, Tabs } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { observer } from 'mobx-react';
import styles from '../style.module.scss';
import { generateHttpsUrl } from '../help';
import { mxConfig } from '@/config';
const { Paragraph } = Typography;
const { TabPane } = Tabs;

interface Props {
  apiKey: string;
  subDomain: string;
  protocolKey: string;
  transFn?: (key: string) => string;
  onRegenerateClicked?: () => void;
  hideHeader?: boolean;
  suffix: string;
}

export interface Urls {
  httpsUrl: string;
  wssUrl: string;
}

export interface AvalancheUrls {
  rpcc: string;
  wsc: string;
  rpcAVAX: string;
  rpcx: string;
  rpcp: string;
}

const ApiKeyPane: React.FC<Props> = observer(
  ({ transFn, subDomain, apiKey, onRegenerateClicked, hideHeader, protocolKey, suffix }) => {
    const [avalancheTabKey, setAvalancheTabKey] = useState<string>('C-Chain');
    const [junoTabkey, setJunoTabKey] = useState<string>('Juno');

    let urls: AvalancheUrls | Urls;
    urls = generateHttpsUrl(protocolKey, subDomain, apiKey, true);
    let valueString: string = '';
    if (protocolKey === 'avalanche') {
      switch (avalancheTabKey) {
        case 'C-Chain':
          valueString = (urls as AvalancheUrls).rpcc;
          break;
        case 'X-Chain':
          valueString = (urls as AvalancheUrls).rpcx;
          break;
        case 'P-Chain':
          valueString = (urls as AvalancheUrls).rpcp;
          break;
      }
    }
    return (
      <div>
        {protocolKey !== 'avalanche' && protocolKey !== 'juno' && (
          <Row>
            <Col span={12} className={styles.col}>
              <p className={styles.addressTitle}>HTTPS</p>
              <Input
                size={'large'}
                disabled
                className={styles.apiKeyInput}
                suffix={
                  <Paragraph copyable className={styles.copyButton}>
                    {(urls as Urls).httpsUrl}
                  </Paragraph>
                }
                value={(urls as Urls).httpsUrl}
              />
            </Col>
            {!mxConfig!.httpOnlyNetworks?.includes(subDomain) && (
              <Col span={12}>
                <p className={styles.addressTitle}>Websocket</p>
                <Input
                  size={'large'}
                  disabled
                  className={styles.apiKeyInput}
                  suffix={
                    <Paragraph copyable className={styles.copyButton}>
                      {(urls as Urls).wssUrl}
                    </Paragraph>
                  }
                  value={(urls as Urls).wssUrl}
                />
              </Col>
            )}
          </Row>
        )}{' '}
        {protocolKey === 'juno' && (
          <>
            <Row>
              <Col span={24} className={styles.tabCol}>
                <Tabs defaultActiveKey="Juno" type="card" onChange={(key) => setJunoTabKey(key)}>
                  <TabPane tab={'Juno'} key={'Juno'} />
                  <TabPane tab={'Cosmos'} key={'Cosmos'} />
                </Tabs>
              </Col>
            </Row>
            <Row>
              <Col span={12} className={styles.col}>
                <p className={styles.addressTitle}>HTTPS {junoTabkey}</p>
                <Input
                  size={'large'}
                  disabled
                  className={styles.apiKeyInput}
                  suffix={
                    <Paragraph copyable className={styles.copyButton}>
                      {(urls as Urls).httpsUrl}
                    </Paragraph>
                  }
                  value={(urls as Urls).httpsUrl}
                />
              </Col>
              {junoTabkey === 'Juno' && (
                <Col span={12}>
                  <p className={styles.addressTitle}>Websocket {junoTabkey}</p>
                  <Input
                    size={'large'}
                    disabled
                    className={styles.apiKeyInput}
                    suffix={
                      <Paragraph copyable className={styles.copyButton}>
                        {(urls as Urls).wssUrl}
                      </Paragraph>
                    }
                    value={(urls as Urls).wssUrl}
                  />
                </Col>
              )}
            </Row>
          </>
        )}{' '}
        {protocolKey === 'avalanche' && (
          <>
            <Row>
              <Col span={24} className={styles.tabCol}>
                <Tabs defaultActiveKey="C-Chain" type="card" onChange={(key) => setAvalancheTabKey(key)}>
                  <TabPane tab={'C-Chain'} key={'C-Chain'} />
                  <TabPane tab={'X-Chain'} key={'X-Chain'} />
                  <TabPane tab={'P-Chain'} key={'P-Chain'} />
                </Tabs>
              </Col>
            </Row>
            <Row>
              <Col span={12} className={styles.col}>
                <p className={styles.addressTitle}>RPC Endpoint ETH</p>
                <Input
                  size={'large'}
                  disabled
                  className={styles.apiKeyInput}
                  suffix={
                    <Paragraph copyable className={styles.copyButton}>
                      {avalancheTabKey === 'C-Chain' && (urls as AvalancheUrls).rpcc}
                    </Paragraph>
                  }
                  value={valueString}
                />
              </Col>
              {avalancheTabKey === 'C-Chain' && (
                <Col span={12} className={styles.col}>
                  <p className={styles.addressTitle}>RPC Endpoint AVAX</p>
                  <Input
                    size={'large'}
                    disabled
                    className={styles.apiKeyInput}
                    suffix={
                      <Paragraph copyable className={styles.copyButton}>
                        {(urls as AvalancheUrls).rpcAVAX}
                      </Paragraph>
                    }
                    value={(urls as AvalancheUrls).rpcAVAX}
                  />
                </Col>
              )}
            </Row>
            {avalancheTabKey === 'C-Chain' && (
              <Row>
                <Col span={12} className={styles.col}>
                  <p className={styles.addressTitle}>Websocket ETH</p>
                  <Input
                    size={'large'}
                    disabled
                    className={styles.apiKeyInput}
                    suffix={
                      <Paragraph copyable className={styles.copyButton}>
                        {(urls as AvalancheUrls).wsc}
                      </Paragraph>
                    }
                    value={(urls as AvalancheUrls).wsc}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    );
  },
);

export default ApiKeyPane;

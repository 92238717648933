import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../store';
import { Redirect } from 'react-router-dom';
import { RouteConfigComponentProps } from 'react-router-config';
import { defaultPage } from '@/configuration';

interface IProps extends RouteConfigComponentProps {}

export const Home: React.FC<IProps> = observer(() => {
  const { workspaces } = useStore();
  return (
    <Redirect to={workspaces.lastSelected?.id ? `/workspaces/${workspaces.lastSelected!.id}/${defaultPage}` : '/'} />
  );
});

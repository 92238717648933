import React, { useEffect, useState } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import { useStore } from '@/store';
import { observer } from 'mobx-react';
import { AnonymousLayout, ErrorToast } from '@/components';
import { ForgetPasswordForm } from './forms/ForgetPasswordForm';
import { LoginForm } from './forms/LoginForm';
import styles from './style.module.scss';

interface IProps extends RouteConfigComponentProps {}

export const Login: React.FC<IProps> = observer(() => {
  const { auth } = useStore();
  const [view, setView] = useState('login');

  useEffect(() => {
    if (auth.state === 'error' && auth.errorMessage) {
      ErrorToast(auth.errorMessage!);
    }
  }, [auth.state]);

  return (
    <AnonymousLayout>
      <div className={styles.loginBox}>
        {view === 'login' && <LoginForm gotoForget={() => setView('forget')} />}
        {view === 'forget' && <ForgetPasswordForm onBack={() => setView('login')} />}
      </div>
    </AnonymousLayout>
  );
});

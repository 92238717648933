import { flow, types } from 'mobx-state-tree';
import { authorize, changePassword, handleLoginCallback, login, logout, extractToken } from '../utils/auth0';
import { getItem, removeItem, setItem } from '../utils/session';
import { api, anonymousApi } from '../utils/api';
import dayjs from 'dayjs';
import { resetStore } from './index';
import { pickOutError } from '@/utils/tools';
import { sendEvent } from '@/utils/googleAnalytics';
import { mxConfig } from '@/config';

export const User = types.model({
  id: types.identifier,
  email: types.string,
  name: types.string,
  createdAt: types.string,
  isAdmin: types.maybe(types.boolean),
  updatedAt: types.string,
  role: types.maybe(types.string),
  passwordUser: types.boolean,
  acceptedTosVer: types.maybeNull(types.string),
  acceptedPpVer: types.maybeNull(types.string),
});

export enum IdentityProvider {
  Google = 'google-oauth2',
  Github = 'github',
}

const postProviderLogin = async (result: any, state: any) => {
  try {
    state.errorMessage = '';
    const { accessToken, expiredAt } = extractToken(result);
    setItem('token', accessToken);
    setItem('expiredAt', expiredAt);
    // Send the session to the server
    sendEvent('sign_in');
    state.state = 'done';
  } catch (err) {
    state.state = 'error';
    state.errorMessage = pickOutError(err);
    return err as any;
  }
};

export const Auth = types
  .model({
    state: types.optional(types.enumeration(['pending', 'done', 'error']), 'done'),
    user: types.maybe(User),
    registerEmail: types.maybe(types.string),
    inviteData: types.maybe(types.frozen()),
    errorMessage: types.maybe(types.string),
    errorCode: types.maybe(types.string),
  })
  .views(() => ({
    isLoggedIn() {
      const token = getItem('token');
      const expired = getItem('expiredAt');
      return !!token && dayjs().unix() < Number(expired);
    },
  }))
  .actions((self: any) => ({
    authorize(provider: IdentityProvider) {
      authorize(provider);
    },

    setInviteData(data?: any) {
      self.inviteData = data;
    },

    handleInvite: flow(function* (token: string) {
      self.state = 'pending';
      try {
        yield api.post(`/workspaces/join?token=${token}`);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    handleCallback: flow(function* () {
      self.state = 'pending';
      const result = yield handleLoginCallback();
      yield postProviderLogin(result, self);
    }),

    userLogin: flow(function* (email: string, password: string) {
      self.state = 'pending';
      try {
        const result = yield login(email, password);
        const err = yield postProviderLogin(result, self);
        if (err) {
          throw err;
        }
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
        //@ts-ignore
        if (err.code && err.code === 'unauthorized') self.registerEmail = email;
      }
    }),

    userSignUp: flow(function* (name: string, email: string, password: string, captchaToken: string) {
      self.state = 'pending';
      try {
        const { currentTosVer, currentPpVer } = mxConfig;
        yield anonymousApi.post('/accounts/register', {
          name,
          email,
          password,
          inviteToken: self.inviteData ? self.inviteData.token : undefined,
          acceptedTosVer: currentTosVer,
          acceptedPpVer: currentPpVer,
          caToken: captchaToken,
        });
        self.registerEmail = email;
        sendEvent('sign_up');
        if (mxConfig.twitterId?.length) {
          //@ts-ignore
          window.twq('event', `tw-${mxConfig.twitterId}-oe29z`);
        }
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    sendEmailVerification: flow(function* (email: string) {
      self.state = 'pending';
      try {
        yield api.post('/accounts/send-email-verification', { email });
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    info: flow(function* () {
      self.state = 'pending';
      try {
        self.user = yield api.get('accounts/me');
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    changeName: flow(function* (name: string) {
      self.state = 'pending';
      try {
        yield api.put('accounts/me', { name });
        self.user!.name = name;
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    changePasswordByMail: flow(function* (email: string) {
      self.state = 'pending';
      try {
        yield changePassword(email);
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    changePasswordByApi: flow(function* (password: string, newPassword: string) {
      self.state = 'pending';
      try {
        yield api.post(`/accounts/change-password`, {
          password,
          newPassword,
        });
        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),

    logout: flow(function* () {
      yield logout();
      yield api.get('accounts/logout');
      removeItem('token');
      removeItem('expiredAt');
      resetStore();
    }),

    updateAcceptedTermsVer: flow(function* (acceptedTosVer: string | null, acceptedPpVer: string | null) {
      self.state = 'pending';
      try {
        const { currentTosVer, currentPpVer } = mxConfig;
        const tosComparison = new Date(acceptedTosVer as string) < new Date(currentTosVer as string);
        const ppComparison = new Date(acceptedPpVer as string) < new Date(currentPpVer as string);

        const tosVer = tosComparison ? currentTosVer : acceptedTosVer;
        const ppVer = ppComparison ? currentPpVer : acceptedPpVer;

        yield api.put('accounts/accepted-terms-version', { acceptedTosVer: tosVer, acceptedPpVer: ppVer });
        self.user!.acceptedTosVer = tosVer;
        self.user!.acceptedPpVer = ppVer;

        self.state = 'done';
      } catch (err) {
        self.state = 'error';
        self.errorMessage = pickOutError(err);
      }
    }),
  }));

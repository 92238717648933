import { FormGroup, NumericInput, Slider, Intent } from '@blueprintjs/core';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, ErrorHint } from 'components';
import { useStore } from 'store';
import { createNodeMinStorage } from '@/configuration';
import { GetHourlyPrice, lang } from './helpers';
import styles from './style.module.scss';

interface IProps {
  showPrice?: boolean;
  nodeSpecKey?: string;
  multiplier: number;
  storageSize: number;
  onSubmit?: (multiplier: number, storageSize: number) => void;
}

const labelStepSize = (multiplier: number) => {
  if (multiplier <= 6) return 1;
  if ([7, 10, 13].includes(multiplier)) return 3;
  else if ([9, 13].includes(multiplier)) return 4;
  else if ([11, 15].includes(multiplier)) return 2;
  else if (multiplier > 16) return 2;
  return 1;
};

export const NodeSizeStorageSize: React.FC<IProps> = observer(
  ({ showPrice = false, nodeSpecKey = 'unit', onSubmit, ...props }) => {
    const { t } = useTranslation();
    const { info, workspaces, usageCenter } = useStore();
    const currentNodeSpec = info.getNodeSpec(nodeSpecKey);
    const [multiplier, setMultiplier] = useState(props.multiplier || 1);
    const defaultStorageSize = props.storageSize || createNodeMinStorage;
    const [storageSize, setStorageSize] = useState(props.storageSize || createNodeMinStorage);
    const minStorageSize = props.storageSize ?? createNodeMinStorage;

    if (!onSubmit)
      return (
        <div>
          <Row className={styles.sizeOutside}>
            <Col unit={12}>
              <div className={styles.subLabel}>
                <label>{lang(t, 'nodeSize')}</label>
              </div>
              <div className={styles.defaultSetting}>
                <span className={styles.nodeSizeIcon}></span>
                <p className={styles.nodeSizeDetailStyle}>
                  <span>{nodeSpecKey === 'unit' && multiplier}</span>
                  {`${t(`modules.nodeSpecSelector.${nodeSpecKey}`)}`}
                </p>
                <p className={cx(styles.nodeSizeDetail, styles.tc)}>
                  {`${
                    multiplier && currentNodeSpec?.cpuSize && (multiplier * currentNodeSpec?.cpuSize).toFixed(1)
                  }CPUs & ${
                    multiplier && currentNodeSpec?.memorySize && (multiplier * currentNodeSpec?.memorySize) / 1024
                  }GB Memory`}
                </p>
              </div>
            </Col>
            <Col unit={12}>
              <div className={styles.subLabel}>
                <label>{lang(t, 'storageSize')}</label>
              </div>
              <div className={styles.defaultSetting}>
                <span className={styles.storageSizeIcon}></span>
                <p className={styles.nodeSizeDetailStyle}>
                  <span>{storageSize}</span> GB
                </p>
              </div>
            </Col>
          </Row>
        </div>
      );

    return (
      <>
        <FormGroup>
          <Row className={styles.sizeOutside}>
            <Col unit={14}>
              <div className={styles.subLabel}>
                <label>{lang(t, 'nodeSize')}</label>
              </div>
              <div className={styles.defaultSetting}>
                <span className={styles.nodeSizeIcon}></span>
                {usageCenter.limit.nodeUnitSize > 1 && (
                  <Slider
                    min={usageCenter.limit.nodeUnitSize % 2}
                    max={usageCenter.limit.nodeUnitSize}
                    labelStepSize={labelStepSize(usageCenter.limit.nodeUnitSize)}
                    onChange={(multiplier: number) => {
                      if (multiplier < 1) multiplier = 1;
                      setMultiplier(multiplier);
                      onSubmit(multiplier, storageSize);
                    }}
                    value={multiplier}
                  />
                )}
                <div className={styles.nodeSizeDetail}>
                  <span>Unit: {multiplier}</span>
                  <span>CPU: {(multiplier * (currentNodeSpec?.cpuSize || 1)).toFixed(1)}</span>
                  <span>Memory: {(multiplier / 1024) * (currentNodeSpec?.memorySize || 1)}G</span>
                </div>
              </div>
            </Col>
            <Col unit={10}>
              <div className={styles.subLabel}>
                <label>{lang(t, 'storageSize')}</label>
              </div>
              <div className={cx(styles.defaultSetting, styles.split)}>
                <span className={styles.storageSizeIcon}></span>
                <div>
                  <div className={styles.storageSizeOutside}>
                    <NumericInput
                      name="storage"
                      stepSize={10}
                      intent={storageSize < minStorageSize ? Intent.DANGER : Intent.NONE}
                      defaultValue={defaultStorageSize}
                      value={storageSize}
                      onBlur={(e) => {
                        if (+e.target.value < minStorageSize) return setStorageSize(minStorageSize);
                      }}
                      fill
                      className={styles.storageSize}
                      onValueChange={(v) => {
                        if (v > usageCenter.limit.createNodeMaxStorage)
                          return setStorageSize(usageCenter.limit.createNodeMaxStorage);
                        setStorageSize(v);
                        onSubmit(multiplier, v);
                      }}
                    />
                  </div>
                  <span>GB</span>
                </div>
                {storageSize < minStorageSize && (
                  <ErrorHint message={lang(t, 'storageMinLimit', { size: minStorageSize })} />
                )}
              </div>
            </Col>
          </Row>
        </FormGroup>
        {workspaces.current?.billingType === 'stripe' && showPrice && (
          <>
            <hr className={styles.hr} />
            <div className={styles.estimatedPrice}>
              {lang(t, 'detail.price')} <span>USD ${GetHourlyPrice(currentNodeSpec, storageSize, multiplier)}</span>
            </div>
          </>
        )}
      </>
    );
  },
);

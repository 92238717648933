import React from 'react';
import styles from './style.module.scss';
import { Icon } from '@blueprintjs/core';
import { Radio } from 'antd';
import cx from 'classnames';

interface IProps {
  className?: string;
  hasIcon?: boolean;
  disable?: boolean;
  selected: boolean;
  onClick?: () => void;
}

export const SelectCard: React.FC<IProps> = ({
  className,
  disable = false,
  selected,
  onClick,
  children,
  hasIcon = true,
}) => {
  return (
    <div
      className={cx(styles.card, {
        [className || '']: !!className,
        [styles.cardSelected]: !disable && selected,
        [styles.cardDisable]: disable,
        [styles.hasIcon]: hasIcon,
      })}
      onClick={() => {
        !disable && onClick && onClick();
      }}
    >
      <Radio checked={selected} className={!hasIcon ? styles.noIcon : ''}></Radio>
      {children}
    </div>
  );
};

import { mxConfig } from '@/config';
import { Urls, AvalancheUrls } from './ApiKeyPane';

export const generateHttpsUrl: (
  protocolKey: string,
  subDomain: string,
  apiKey: string,
  apiKeyPane: boolean,
) => Urls | any = (protocolKey: string, subDomain: string, apiKey: string, apiKeyPane: boolean) => {
  let urls: AvalancheUrls | Urls;
  switch (protocolKey) {
    case 'avalanche':
      if (apiKeyPane) {
        urls = {
          rpcc: `https://${subDomain}.api.${mxConfig.domain}/ext/bc/C/rpc?apikey=${apiKey}`,
          wsc: `wss://${subDomain}.api.${mxConfig.domain}/ext/bc/C/ws?apikey=${apiKey}`,
          rpcAVAX: `https://${subDomain}.api.${mxConfig.domain}/ext/bc/C/avax?apikey=${apiKey}`,
          rpcx: `https://${subDomain}.api.${mxConfig.domain}/ext/bc/X?apikey=${apiKey}`,
          rpcp: `https://${subDomain}.api.${mxConfig.domain}/ext/bc/P?apikey=${apiKey}`,
        };
      } else {
        urls = {
          httpsUrl: `https://${subDomain}.api.${mxConfig.domain}/ext/bc/P?apikey=${apiKey}`,
          wssUrl: `wss://${subDomain}.api.${mxConfig.domain}/ext/bc/C/ws?apikey=${apiKey}`,
        };
      }
      break;
    case 'juno':
      urls = {
        httpsUrl: `https://${subDomain}.api.${mxConfig.domain}/rpc?apikey=${apiKey}`,
        wssUrl: `wss://${subDomain}.api.${mxConfig.domain}/ws/websocket?apikey=${apiKey}`,
      };
      break;
    default:
      urls = {
        httpsUrl: `https://${subDomain}.api.${mxConfig.domain}/rpc?apikey=${apiKey}`,
        wssUrl: `wss://${subDomain}.api.${mxConfig.domain}/ws?apikey=${apiKey}`,
      };
      break;
  }
  return urls;
};

import { Intent } from '@blueprintjs/core/lib/esm/common/intent';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Dropdown, Menu, Button, Row, Col, Layout, Divider, Input } from 'antd';
import {
  DownOutlined,
  LogoutOutlined,
  CheckOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Language, Toast, SuccessToast } from 'components';
import { useStore } from '../../store';
import styles from './style.module.scss';
import { CreateWorkspaceModal } from '../CreateWorkspaceModal';
import { defaultPage } from '@/configuration';
import { ReactComponent as AvatarIcon } from './imgs/avatar.svg';
import { ReactComponent as VectorIcon } from './imgs/vector.svg';
import { ReactComponent as BillingIcon } from './imgs/billingHistory.svg';
import cx from 'classnames';
import { mxConfig } from '@/config';
import _ from 'lodash';

const { Header: AntdHeader } = Layout;
const waitTime = 300;

let reloadLogs: any;

export const Header: React.FC = observer(({ children }) => {
  const history = useHistory();
  const match = useRouteMatch<{ wsId: string }>();
  const { workspaces } = useStore();
  const { t } = useTranslation();
  const store = useStore();
  const [workspaceModal, openWorkspaceModal] = useState(false);
  const [filterWord, setFilterWord] = useState<string | undefined>('');

  useEffect(() => {
    (async () => {
      await workspaces.fetchMembers(match.params.wsId);
    })();
  }, [workspaces.current]);

  useEffect(() => {
    return () => {
      clearTimeout(reloadLogs);
    };
  }, []);

  const filterObj = (obj: string, query?: string) => {
    if (!query) return true;
    return `${obj.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
  };

  const changeValue = _.debounce((v: string | undefined) => {
    setFilterWord(v);
  }, waitTime);

  const checkAuth = async () => {
    clearTimeout(reloadLogs);
    reloadLogs = setTimeout(async () => {
      if (!store.auth?.user) {
        history.push('/login');
      } else {
        checkAuth();
      }
    }, 500);
  };

  return (
    <AntdHeader className={styles.header}>
      <Row>
        <Col flex="none">{children}</Col>
        <Col flex="auto">
          <Divider type={'vertical'} />
          <Dropdown
            arrow
            trigger={['click']}
            overlay={
              <Menu key={store.workspaces.current?.id} className={styles.workspaceMenu}>
                {store.workspaces.list.length >= 10 && (
                  <Input
                    className={styles.searchMargin}
                    placeholder={t('topNav.searchBarPleaseHoder')}
                    onChange={(e: any) => {
                      changeValue(e.target.value);
                    }}
                    suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                  />
                )}
                <div className={styles.workspaceMenuLimit}>
                  {store.workspaces.list
                    .filter((i) => filterObj(i.name, filterWord))
                    .sort(function (a, b) {
                      if (a.name < b.name) {
                        return -1;
                      }
                      if (a.name > b.name) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((ws) => (
                      <Menu.Item
                        className={cx({ [`op-dropdown-menu-item-selected`]: store.workspaces.current?.id === ws?.id })}
                        key={ws.id}
                        onClick={() => {
                          changeValue(undefined);
                          history.push(`/workspaces/${ws.id}/${defaultPage}`);
                        }}
                      >
                        <>
                          {ws.name}
                          {store.workspaces.current?.id === ws?.id && (
                            <>
                              {' '}
                              <CheckOutlined />
                            </>
                          )}
                        </>
                      </Menu.Item>
                    ))}
                </div>
                {!mxConfig.featureFlags.isWhiteLabelSite && (
                  <>
                    <Menu.Divider />
                    <Menu.Item icon={<PlusCircleOutlined />} onClick={() => openWorkspaceModal(true)}>
                      {t('topNav.newWorkspace')}
                    </Menu.Item>
                  </>
                )}
              </Menu>
            }
          >
            <Button
              type="primary"
              className={styles.workspaceButton}
              icon={
                <span className="anticon">
                  <VectorIcon />
                </span>
              }
            >
              {store.workspaces.current?.name}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
        <Col className={styles.alignRight}>
          {workspaces.current?.plan === 'developer' && (
            <div
              className={styles.upgradePlan}
              onClick={() => {
                history.push(`/workspaces/${workspaces.current!.id}/settings/plan`);
              }}
            >
              {t('topNav.upgradePlan')}
            </div>
          )}
          <Language />
          <Dropdown
            arrow
            placement="bottomRight"
            trigger={['click']}
            overlay={
              <Menu key={store.workspaces.current?.id} className={styles.workspaceMenu}>
                <Menu.Item
                  icon={<SettingOutlined className={styles.w16} />}
                  onClick={() => {
                    const ws = workspaces.current;
                    history.push({
                      pathname: `/workspaces/${ws!.id}/accountPage`,
                      state: { detail: '1' },
                    });
                  }}
                >
                  {t('topNav.account')}
                </Menu.Item>
                <Menu.Item
                  icon={<BillingIcon className={styles.w16} />}
                  onClick={() => {
                    const ws = workspaces.current;
                    history.push({
                      pathname: `/workspaces/${ws!.id}/accountPage`,
                      state: { detail: '2' },
                    });
                  }}
                >
                  {t('topNav.billingHistory')}
                </Menu.Item>
                <Menu.Item
                  icon={<LogoutOutlined className={styles.w16} />}
                  onClick={async () => {
                    await store.auth.logout();
                    localStorage.removeItem('selectedNetwork');
                    checkAuth();
                  }}
                >
                  {t('topNav.logout')}
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="primary"
              icon={
                <span className={cx({ ['anticon']: true, [styles.avatrIcon]: true })}>
                  <AvatarIcon />
                </span>
              }
            >
              {store.auth.user?.name}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>
      {workspaceModal && (
        <CreateWorkspaceModal
          isOpen={workspaceModal}
          onClose={() => openWorkspaceModal(false)}
          onSubmit={async (data) => {
            const ws = await store.workspaces.create(data);
            if (!ws) {
              Toast.show({ message: store.workspaces.errorMessage, intent: Intent.DANGER, timeout: 2000 });
              return;
            } else {
              SuccessToast('Success');
            }
            await store.workspaces.fetchList();
            openWorkspaceModal(false);
            history.push(`/workspaces/${ws.id}/${defaultPage}`);
          }}
        />
      )}
    </AntdHeader>
  );
});

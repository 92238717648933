import React from 'react';
import { Col, ErrorHint } from 'components';
import { Controller } from 'react-hook-form';
import { InputGroup, FormGroup } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

interface IProps {
  media: string;
  data: any;
  control: any;
  errors: any;
  lang: any;
}

export const SocialMedia: React.FC<IProps> = ({ media, data, control, errors, lang }) => {
  const { t } = useTranslation();

  return (
    <Col unit={12}>
      <FormGroup label={`${lang(t, `moreDetail.${media}`)}`}>
        <Controller
          name={`socialMedia.${media}`}
          control={control}
          defaultValue={data.extraInfo?.socialMedia?.[media]}
          as={
            <InputGroup
              large
              fill
              //
            />
          }
        />
      </FormGroup>
      <ErrorHint message={errors.socialMedia?.[media] ? errors.socialMedia?.[media].message : ''} />
    </Col>
  );
};

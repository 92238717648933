import React from 'react';
import { Intent, Alert } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { ErrorToast, SuccessToast } from 'components';
import { IconName, IconNames } from '@blueprintjs/icons';
import { lang } from './helper';
import { useStore } from '../../store';
import styles from './style.module.scss';
import sanitizeHtml from 'sanitize-html';

interface IProps {
  nodeId: string;
  nodeName: string;
  onClose: () => void;
  actionKind: string | undefined;
}

interface alertMapType {
  [key: string]: {
    icon: IconName;
    action: (wsId: string, nodeId: string) => void;
    submitLabel: string;
    intent: Intent;
    title: string;
    alertMsg: string;
    successMsg: string;
  };
}

export const NodeActionsAlert: React.FC<IProps> = ({ nodeId, nodeName, onClose, actionKind }) => {
  const { t } = useTranslation();
  const { nodes, workspaces, auth } = useStore();
  const nonMember = workspaces.members.filter((e) => e.id === auth.user?.id && e.role !== 'member');
  const accessToAction = () => {
    if (nodes?.current?.metadata?.labels?.nodePool !== undefined) {
      return auth.user?.isAdmin || nonMember.length !== 0;
    }
    return true;
  };
  const alertKindMap: alertMapType = {
    needUpgrade: {
      action: () => {},
      icon: IconNames.ISSUE,
      submitLabel: t('dict.cancel'),
      intent: Intent.DANGER,
      title: lang(t, 'buttonGroup.update'),
      alertMsg: lang(t, 'updateFirst'),
      successMsg: '',
    },
    resume: {
      action: nodes.resumeNode,
      icon: IconNames.ISSUE,
      submitLabel: t('dict.resume'),
      intent: Intent.PRIMARY,
      title: lang(t, 'buttonGroup.resume'),
      alertMsg: lang(t, 'buttonGroup.actions.resume', { nodeName }),
      successMsg: lang(t, 'buttonGroup.success.resume', { nodeName }),
    },
    stop: {
      action: accessToAction() ? nodes.stopNode : () => {},
      icon: IconNames.ISSUE,
      submitLabel: t('dict.stop'),
      intent: Intent.PRIMARY,
      title: lang(t, 'buttonGroup.stop'),
      alertMsg: accessToAction()
        ? lang(t, 'buttonGroup.actions.stop', { nodeName })
        : 'You can not stop the node. Contact workspace owner or admin for support.',
      successMsg: lang(t, 'buttonGroup.success.stop', { nodeName }),
    },
    delete: {
      action: accessToAction() ? nodes.deleteNode : () => {},
      icon: IconNames.ISSUE,
      submitLabel: t('dict.terminate'),
      intent: Intent.DANGER,
      title: lang(t, 'buttonGroup.terminate'),
      alertMsg: accessToAction()
        ? lang(t, 'buttonGroup.actions.delete', { nodeName })
        : 'You can not terminate the node. Contact workspace owner or admin for support.',
      successMsg: lang(t, 'buttonGroup.success.delete', { nodeName }),
    },
    restart: {
      action: accessToAction() ? nodes.restartNode : () => {},
      icon: IconNames.ISSUE,
      submitLabel: t('dict.restart'),
      intent: Intent.PRIMARY,
      title: lang(t, 'buttonGroup.restart'),
      alertMsg: accessToAction()
        ? lang(t, 'buttonGroup.actions.restart', { nodeName })
        : 'You can not restart the node. Contact workspace owner or admin for support.',
      successMsg: lang(t, 'buttonGroup.success.restart', { nodeName }),
    },
  };

  const onSubmit = async () => {
    if (actionKind === 'needUpgrade') {
      onClose();
      return;
    }
    await alertKindMap[actionKind!].action(workspaces.current!.id, nodeId);
    if (nodes.state === 'error') {
      ErrorToast(nodes.errorMessage!);
    } else if (!accessToAction()) {
      onClose();
      if (alertKindMap[actionKind!].alertMsg) {
        ErrorToast(alertKindMap[actionKind!].alertMsg, 4000);
      }
    } else {
      onClose();
      if (alertKindMap[actionKind!].successMsg) {
        SuccessToast(alertKindMap[actionKind!].successMsg, 4000);
        await nodes.fetchNode(workspaces.current!.id, nodeId);
        await nodes.getNodeStatus(workspaces.current!.id, nodeId);
      }
    }
  };

  return (
    <Alert
      cancelButtonText={actionKind !== 'needUpgrade' ? t('dict.cancel') : undefined}
      confirmButtonText={actionKind ? alertKindMap[actionKind].submitLabel : t('dict.confirm')}
      icon={actionKind ? alertKindMap[actionKind].icon : null}
      intent={actionKind ? alertKindMap[actionKind].intent : Intent.PRIMARY}
      isOpen={alertKindMap.hasOwnProperty(actionKind as string)}
      onCancel={onClose}
      onConfirm={onSubmit}
    >
      <p>{actionKind ? alertKindMap[actionKind!].title : ''}</p>
      <p
        className={styles.alertSubMessage}
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(actionKind ? alertKindMap[actionKind!].alertMsg : '') }}
      ></p>
      {actionKind && actionKind === 'restart' && nodes?.current?.metadata?.labels?.nodePool !== undefined && (
        <p className={styles.rpcAndUserWarning}>
          This node is used for the OnFinality Public API. Changing these settings will restart the node, so please
          perform a rolling update of only one node at a time
        </p>
      )}
      {((actionKind && actionKind === 'delete') || (actionKind && actionKind === 'stop')) &&
        nodes?.current?.metadata?.labels?.nodePool !== undefined && (
          <p className={styles.rpcAndUserWarning}>
            This node is used for the OnFinality Public API. By continuing with this action you may take the Public API
            offline, so please confirm with the OnFinality team first so they can ensure that this will not impact your
            customers.
          </p>
        )}
    </Alert>
  );
};

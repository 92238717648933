import { observer } from 'mobx-react';
import React from 'react';
import { ErrorPage } from './ErrorPage';
import { RouteConfigComponentProps } from 'react-router-config';

interface IProps extends RouteConfigComponentProps {}

export const Error: React.FC<IProps> = observer((props) => {
  return (
    <>
      <ErrorPage {...props} noLogo={true} />
    </>
  );
});

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import styles from './style.module.scss';
import { Row, Col } from 'components';
import { CountrySelect } from '../CountrySelect';
import { ErrorToast } from '../Toast';
import { Form, Input, Button } from 'antd';

interface IProps {
  onSubmit: (data: any) => void;
}

const lang = (t: TFunction, key: string) => t(`components.paymentMethod.${key}`);

const errorMessage = (str: string | null | undefined) => {
  if (!str) return '';
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
};

function cleanupObject(data: any): any {
  return Object.keys(data).reduce((val, key) => {
    if (typeof data[key] === 'object') {
      val[key] = cleanupObject(data[key]);
    }
    if (data[key] && data[key] !== '') {
      val[key] = data[key];
    }
    return val;
  }, {} as any);
}
const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required(),
  city: yup.string().required(),
  line1: yup.string().required(),
  line2: yup.string(),
  state: yup.string(),
  country: yup.string().required(),
  postalCode: yup.string().required(),
});

export const PaymentMethodForm: React.FC<IProps> = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const { handleSubmit, register, errors, control } = useForm({ validationSchema: schema });
  const [progressing, setProgressing] = useState(false);
  const { t } = useTranslation();

  const isNotEmail = (v: string) => {
    return !v ? t('pages.login.error.email') : undefined;
  };
  if (!stripe || !elements) {
    return <div>cant load stripe</div>;
  }

  const onHandleSubmit = async (vals: any) => {
    setProgressing(true);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setProgressing(false);
      return console.log('cant get cardElement');
    }

    const { token, error } = await stripe.createToken(cardElement);
    if (error) {
      ErrorToast(error.message!);
      setProgressing(false);
      return;
    }
    vals = cleanupObject(vals);
    vals.cardToken = token!.id;

    await props.onSubmit({
      name: vals.name,
      email: vals.email,
      phone: vals.phone,
      cardToken: vals.cardToken,
      address: {
        city: vals.city,
        line1: vals.line1,
        line2: vals.line2,
        state: vals.state,
        country: vals.country,
        postalCode: vals.postalCode,
      },
    });
    setProgressing(false);
  };

  return (
    <Form name="basic" layout={'vertical'} className={styles.form} onFinish={() => handleSubmit(onHandleSubmit)()}>
      <Form.Item
        label={lang(t, 'name')}
        help={errorMessage(errors?.name?.message)}
        validateStatus={errors?.email?.message ? 'error' : ''}
      >
        <Controller
          name="name"
          control={control}
          ref={register}
          as={<Input type="text" size={'large'} placeholder={lang(t, 'name')} />}
        />
      </Form.Item>
      <Form.Item label={lang(t, 'cardTitle')}>
        <div className={styles.cardBox}>
          <CardElement />
        </div>
      </Form.Item>
      {errors.card && <p className={styles.errorMessage}>{errors.card}</p>}

      <h3>Billing Detail</h3>
      <Row gutter>
        <Col unit={12}>
          <Form.Item
            label={lang(t, 'email')}
            help={errorMessage(errors?.email?.message)}
            validateStatus={errors?.email?.message ? 'error' : ''}
          >
            <Controller
              name="email"
              control={control}
              rules={{ validate: { inputTelRequired: isNotEmail } }}
              ref={register}
              as={<Input type="email" size={'large'} placeholder={lang(t, 'email')} />}
            />
          </Form.Item>
        </Col>
        <Col unit={12}>
          <Form.Item
            label={lang(t, 'phone')}
            help={errorMessage(errors?.phone?.message)}
            validateStatus={errors?.phone?.message ? 'error' : ''}
          >
            <Controller
              name="phone"
              control={control}
              ref={register}
              as={<Input type="text" size={'large'} placeholder={lang(t, 'phone')} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter>
        <Col unit={12}>
          <Form.Item label={lang(t, 'country')}>
            <Controller name="country" control={control} defaultValue={'NZ'} as={CountrySelect} />
          </Form.Item>
        </Col>
        <Col unit={12}>
          <Form.Item label={lang(t, 'state')}>
            <Controller
              name="state"
              control={control}
              ref={register}
              as={<Input type="text" size={'large'} placeholder={lang(t, 'state')} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter>
        <Col unit={12}>
          <Form.Item
            label={lang(t, 'city')}
            help={errorMessage(errors?.city?.message)}
            validateStatus={errors?.city?.message ? 'error' : ''}
          >
            <Controller
              name="city"
              control={control}
              ref={register}
              as={<Input type="text" size={'large'} placeholder={lang(t, 'city')} />}
            />
          </Form.Item>
        </Col>
        <Col unit={12}>
          <Form.Item
            label={lang(t, 'postalCode')}
            help={errors?.postalCode?.message && 'Postal Code Line is a required field'}
            validateStatus={errors?.postalCode?.message ? 'error' : ''}
          >
            <Controller
              name="postalCode"
              control={control}
              ref={register}
              as={<Input type="text" size={'large'} placeholder={lang(t, 'postalCode')} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter>
        <Col unit={12}>
          <Form.Item
            label={lang(t, 'line1')}
            help={errors?.line1?.message && 'Address Line is a required field'}
            validateStatus={errors?.line1?.message ? 'error' : ''}
          >
            <Controller
              name="line1"
              control={control}
              ref={register}
              as={<Input type="text" size={'large'} placeholder={lang(t, 'line1')} />}
            />
          </Form.Item>
        </Col>
        <Col unit={12}>
          <Form.Item label={lang(t, 'line2')}>
            <Controller
              name="line2"
              control={control}
              ref={register}
              as={<Input type="text" size={'large'} placeholder={lang(t, 'line2')} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <div className={styles.footer}>
        <Button htmlType="submit" size={'middle'} type={'primary'} loading={progressing}>
          {lang(t, 'create')}
        </Button>
      </div>
    </Form>
  );
};

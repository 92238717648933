import axios, { AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import { history } from '../store';
import { getItem, removeItem, setItem } from './session';
import { checkSession, IAuthPayload, extractToken } from './auth0';
import { loadMxConfig } from '@/config';
import { ErrorToast } from '@/components';
import { pickOutError } from './tools';

let api: AxiosInstance;
let anonymousApi: AxiosInstance;

const serverLogout = async (token: string | null, baseURL?: string) => {
  // Cannot logout if no token involved
  if (!token) {
    return;
  }
  if (!baseURL) {
    baseURL = (await loadMxConfig()).apiUrl;
  }
  await axios.get(`${baseURL}/accounts/logout`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

loadMxConfig().then(({ apiUrl: baseURL }) => {
  api = axios.create({
    baseURL,
  });
  api.interceptors.request.use(async (config) => {
    const exp = getItem('expiredAt');
    if (!exp) {
      goToLogin();
      return config;
    }
    let token = getItem('token');

    // renew the token if it is expired
    if (dayjs().unix() >= Number(exp)) {
      try {
        // Just logout no matter what
        await serverLogout(token, baseURL);
      } catch {
        /* do nothing here because it's not significant as sessions will get cleared after expire anyways */
      }

      // Now try to renew the token
      try {
        // checkSession() will also call Auth0 post login hook,
        // so it will create new session on server side
        const result: IAuthPayload = await checkSession({});
        const { accessToken, expiredAt } = extractToken(result);
        setItem('token', accessToken);
        setItem('expiredAt', expiredAt);
        token = accessToken;
      } catch (err) {
        const { message } = err as any;
        ErrorToast(message);
        removeItem('token');
        removeItem('expiredAt');
        goToLogin();
      }
    }

    config.headers['Authorization'] = 'Bearer ' + token;
    return config;
  });

  api.interceptors.response.use(
    (result) => result.data,
    (error) => {
      if ([401].indexOf(error?.response?.status) >= 0) {
        // Server logout
        serverLogout(getItem('token'), baseURL).finally(() => {
          removeItem('token');
          removeItem('expiredAt');
          goToLogin();
          ErrorToast(pickOutError(error));
        });
      } else {
        throw error;
      }
    },
  );

  anonymousApi = axios.create({
    baseURL,
  });
});

const filterErrorMessage = (err: any) => err.response?.data?.message ?? err.message;

const goToLogin = () => {
  const { pathname, search } = history.location;
  if (pathname !== '/login') {
    const redirectUrl = pathname !== '/' && encodeURIComponent(`${pathname}${search}`);
    history.push(`/login${redirectUrl ? '?redirectUrl=' + redirectUrl : ''}`);
  }
};

export { api, anonymousApi, filterErrorMessage, goToLogin };

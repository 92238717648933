import React from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
const lang = (t: TFunction, key: string) => t(`modules.nodeSpecSelector.${key}`);
const NodeSpecNameTranslateAvailable = ['small', 'medium', 'large', 'standard', 'unit'];
interface IProps {
  name: string;
}

export const NodeSpecName: React.FC<IProps> = ({ name }) => {
  const { t } = useTranslation();
  return <>{NodeSpecNameTranslateAvailable.includes(name) ? lang(t, name) : name}</>;
};

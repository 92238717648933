import { Classes, Dialog, FormGroup, InputGroup, Intent, Tag } from '@blueprintjs/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ErrorHint, ErrorToast, SuccessToast, SubmitButton } from 'components';
import { useStore } from 'store';
import { lang } from './helper';
import styles from './style.module.scss';

interface IProps {
  isOpen: boolean;
  id: string;
  storage?: string;
  onClose: () => void;
}

const stepSize = 20;

export const UpgradeStorageDialog: React.FC<IProps> = ({ isOpen, onClose, id, storage }) => {
  const { t } = useTranslation();
  const { nodes, workspaces, usageCenter } = useStore();
  const size = Number(storage!.replace(/Gi?$/, ''));
  const schema = yup.object().shape({
    storage: yup
      .number()
      .required()
      .min(size + stepSize)
      .max(usageCenter.limit.createNodeMaxStorage),
  });
  const { control, errors, handleSubmit } = useForm({ validationSchema: schema });
  const [progressing, setProgressing] = useState(false);

  const disableSubmit = () => {
    return undefined; // uncomment to enable
    // return <DisabledFeature />;
  };
  return (
    <Dialog title={lang(t, 'upgradeNode.titleStorage')} isOpen={isOpen} onClose={onClose}>
      <form
        onSubmit={handleSubmit(async (val) => {
          setProgressing(true);
          await nodes.expandStorage(workspaces.current!.id, id, { storage: val.storage + 'Gi' });
          if (nodes.state === 'error') {
            ErrorToast(nodes.errorMessage!);
          } else {
            SuccessToast('Success');
            onClose();
          }
          setProgressing(false);
        })}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={lang(t, 'upgradeNode.storage')}>
            <Controller
              name="storage"
              control={control}
              defaultValue={size}
              as={
                <InputGroup
                  large
                  fill
                  intent={errors.storage ? Intent.DANGER : Intent.NONE}
                  rightElement={
                    <Tag minimal large>
                      Gb
                    </Tag>
                  }
                />
              }
            />
            <ErrorHint message={errors.storage ? errors.storage.message : ''} />
          </FormGroup>

          {nodes?.current?.metadata?.labels?.nodePool !== undefined && (
            <p className={styles.rpcAndUserWarning}>
              This node is used for the OnFinality Public API. Changing these settings will restart the node, so please
              perform a rolling update of only one node at a time
            </p>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <SubmitButton
              // disabled={nodes.current?.clusterHash === 'jm' || nodes.current?.clusterHash === 'lh'}
              intent={Intent.PRIMARY}
              loading={progressing}
              type={'submit'}
            >
              {lang(t, 'upgradeNode.submit')}
            </SubmitButton>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

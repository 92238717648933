import React, { useEffect, useState } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { title } from './helpers';
import { useStore } from 'store';
import { Spin } from 'components';
import { observer } from 'mobx-react';
import './theme.scss';
import styles from './style.module.scss';
import { goToLogin } from '@/utils/api';
// import ReactGA from 'react-ga';

interface IProps extends RouteConfigComponentProps {}

export const App: React.FC<IProps> = observer((props) => {
  const { route, history } = props;
  const { auth, info, workspaces } = useStore();
  const [init, setInit] = useState(false);

  useEffect(() => {
    (async () => {
      if (!auth.isLoggedIn()) {
        goToLogin();
        return;
      }
      await auth.info();
      await Promise.all([workspaces.fetchList(), info.fetchData()]);
      setInit(true);
    })();
  }, []);

  useEffect(() => {
    const docTitle = title(history.location.pathname, workspaces.current?.id);
    document.title = `OnFinality${docTitle ? ` - ${docTitle}` : ''}`;
    // if (process.env.REACT_APP_ENV !== 'prod') return;
    // ReactGA.pageview(history.location.pathname + history.location.search);
  }, [history.location.pathname, workspaces.current?.id]);

  if (!init) {
    if (workspaces.state === 'pending' || info.state === 'pending') {
      return (
        <div className={styles.rootLoading}>
          <Spin isLoading />
        </div>
      );
    }
    if (workspaces.state === 'error' || info.state === 'error' || auth.state === 'error') {
      return <div>failed to load workspace data</div>;
    }
  }

  return <>{renderRoutes(route!.routes)}</>;
});

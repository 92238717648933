import React from 'react';
import styles from './style.module.scss';
import { Card, Elevation } from '@blueprintjs/core';
import { NetworkImgType, NetworkSpecIcon } from '../../components';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store';
import { INetworkSpec } from '../../store/networks';

enum NetworkSpecSize {
  Small = 'small',
  Large = 'large',
}

interface IProps {
  networkName: string;
  size: NetworkSpecSize;
  disable?: boolean;
}

function getClassName(type: NetworkSpecSize) {
  switch (type) {
    case NetworkSpecSize.Small:
      return styles.cardStyle1;
    case NetworkSpecSize.Large:
      return styles.cardStyle2;
    default:
      return '';
  }
}

function getImageSize(type: NetworkSpecSize) {
  switch (type) {
    case NetworkSpecSize.Large:
      return NetworkImgType.Large;
    case NetworkSpecSize.Small:
    default:
      return NetworkImgType.Small;
  }
}

export const NetworkSingleCard: React.FC<IProps> = ({ networkName, size, disable }) => {
  const { networks } = useStore();
  const currentNetwork = networks.getPictures(networkName);
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Card className={getClassName(size)} interactive elevation={Elevation.ONE} data-disable={disable && 'disable'}>
        <div className={styles.imgbox}>
          <img src={currentNetwork.large} className={styles.logo} />
        </div>
      </Card>
    </div>
  );
};
